import React, { FC } from 'react'
import styles from './FilterPillButton.module.scss'
interface Props {
  label: string
  id: string
  onClick: () => void
  children?: React.ReactNode
}

export const FilterPillButton: FC<Props> = ({
  label,
  id,
  onClick,
  children
}) => {
  return (
    <div
      role="button"
      className={styles.Container}
      data-testid={`${id}-btn-container`}
      onClick={onClick}
    >
      {label}
      {children}
    </div>
  )
}
