import { GameScheduleItemStatus } from '../../features/sportData/GameScheduleListContainer/ListItem/gameScheduleItem'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface GameScheduleListResponseApi {
  name: string
  slug: string
  start_at: number
  end_at: number
  status: GameScheduleItemStatus
  team_name: string
  team_id: number
  teams_count: number
  games_count: number
  district_id: number
  district_name: string
  id: number
}

export enum CompetitionsDataFilters {
  Future = 'future',
  Active = 'active',
  FututreAndActive = 'future_and_active',
  Past = 'past'
}
export interface GameSchedule {
  title: string
  startDate: number
  endDate: number
  status: GameScheduleItemStatus
  id: string
  teamName: string
  teamId: string
  totalGames: number
}

export interface GameScheduleRequest {
  channelSlug: string
  page: number
  perPageCount: number
}

export const getGameScheduleList = async ({
  request,
  pastGames
}: {
  request: GameScheduleRequest
  pastGames: boolean
}): Promise<GameSchedule[]> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.channelSlug}/competitions`,
      method: ApiEndpoint.METHODS.GET,
      params: {
        date_filter: pastGames
          ? CompetitionsDataFilters.Past
          : CompetitionsDataFilters.FututreAndActive,
        page: request.page,
        per_page: request.perPageCount
      },
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped: GameSchedule[] = mapGameScheduleList(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapGameScheduleList = (
  gameScheduleList: GameScheduleListResponseApi[]
) => {
  try {
    return gameScheduleList.map(
      (gameSchedule: GameScheduleListResponseApi) => ({
        title: gameSchedule.name,
        startDate: gameSchedule.start_at,
        endDate: gameSchedule.end_at,
        status: gameSchedule.status,
        id: gameSchedule?.id.toString(),
        teamId: gameSchedule.team_id ? gameSchedule.team_id.toString() : '',
        teamName: gameSchedule.team_name,
        totalGames: gameSchedule.games_count
      })
    )
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
