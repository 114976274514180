import React, { FC } from 'react'
import { t } from '@lingui/macro'
import styles from './DisplayLogo.module.scss'
import Button, {
  ButtonType
} from '../../../../../../../components/buttons/button/Button'
import classNames from 'classnames'

interface DisplayLogoProps {
  imageSrc: string
  setImageSrc: (src: string | undefined) => void
  isThemed?: boolean
}

export const DisplayLogo: FC<DisplayLogoProps> = ({
  imageSrc,
  setImageSrc,
  isThemed
}) => (
  <div
    data-testid="display-logo-container"
    className={styles.DisplayLogoContainer}
  >
    <div
      className={classNames(styles.DisplayLogoBackdrop, {
        [styles.WithTheme]: isThemed
      })}
      style={{ backgroundImage: `url(${imageSrc})` }}
    />
    <div className={styles.DisplayLogoOverlay}>
      <Button
        type={ButtonType.Secondary}
        buttonSize="Small"
        label={t`Replace`}
        onClick={() => setImageSrc(undefined)}
      />
      <Button
        type={ButtonType.Secondary}
        buttonSize="Small"
        label={t`Remove`}
        onClick={() => setImageSrc(undefined)}
      />
    </div>
  </div>
)
