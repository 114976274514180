import React, { FC } from 'react'
import { t } from '@lingui/macro'
import HighlightIcon from '../../../../assets/icons/HighlightIcon'
import ReplayIcon from '../../../../assets/icons/ReplayIcon'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'
import styles from './GameScheduleButtons.module.scss'

interface Props {
  id: string
  channelSlug: string
  highlightSlug: string
  displayChannelSlug: string
  rootDomain?: string
}
export const HighlightAndReplayButtons: FC<Props> = ({
  id,
  channelSlug,
  highlightSlug,
  displayChannelSlug,
  rootDomain
}) => (
  <>
    <div className={styles.FirstButton}>
      <LinkIconButton
        type={ButtonType.Primary}
        buttonSize="Small"
        label={t`Highlights`}
        id={id}
        href={`/${displayChannelSlug}/watch/${highlightSlug}`}
        rootDomain={rootDomain}
      >
        <HighlightIcon />
      </LinkIconButton>
    </div>
    <LinkIconButton
      type={ButtonType.Secondary}
      buttonSize="Small"
      label={t`Replay`}
      id={id}
      href={`/${channelSlug}/games/g/${id}`}
      rootDomain={rootDomain}
    >
      <ReplayIcon />
    </LinkIconButton>
  </>
)
