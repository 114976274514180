import React, { FC } from 'react'
import classNames from 'classnames'
import { Trans } from '@lingui/macro'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

import styles from './GameScheduleListRow.module.scss'

interface Props {
  showStatus: boolean
  showRemoveButton: boolean
  channelType: ChannelTypes
}

export const GameScheduleListHeader: FC<Props> = ({
  showStatus,
  showRemoveButton,
  channelType
}) => (
  <div className={classNames(styles.GameScheduleRow, styles.Header)}>
    <div className={styles.Name}>
      <Trans>Competition name</Trans>
    </div>
    {showStatus && (
      <div className={styles.Status}>
        <Trans>Status</Trans>
      </div>
    )}
    {[ChannelTypes.league].includes(channelType) && (
      <div className={styles.TotalGames}>
        <Trans>Games</Trans>
      </div>
    )}
    <div className={styles.StartDate}>
      <Trans>Start date</Trans>
    </div>
    <div className={styles.EndDate}>
      <Trans>End date</Trans>
    </div>
    {showRemoveButton && <div className={styles.RemoveButton}>&nbsp;</div>}
  </div>
)
