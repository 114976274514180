import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import React, { FC } from 'react'
import { ReceiptContent } from './ReceiptContet'

interface Props {
  description: string
  referenceNumber: string
  createdAt: number
  price: number
  currency: string
  vat: number
  isOpen: boolean
  onClose: () => void
}

export const ReceiptModal: FC<Props> = ({
  description,
  referenceNumber,
  createdAt,
  price,
  currency,
  vat,
  isOpen,
  onClose
}) => {
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={() => onClose()}
      firstContainerContent={
        <ReceiptContent
          description={description}
          referenceNumber={referenceNumber}
          createdAt={createdAt}
          price={price}
          currency={currency}
          vat={vat}
          onCloseClick={() => onClose()}
        />
      }
      firstContainerTheme={ModalTheme.Primary}
    />
  )
}
