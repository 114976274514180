import { Middleware } from 'redux'
import USER from './user.constants'
import ApiEndpoint, { ApiError, ApiResponse } from '../../network/ApiEndpoint'
import authenticationActions from '../authentication/authentication.actions'
import userActions from './user.actions'

export const updateUser: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === USER.UPDATE_USER) {
      ApiEndpoint.call({
        path: 'users/session_user',
        method: ApiEndpoint.METHODS.GET,
        authenticatable: true
      })
        .then((response: ApiResponse) => {
          storeAPI.dispatch(userActions.userUpdatedFromServer(response.data))
        })
        .catch((error: ApiError) => {
          console.error(error)
          storeAPI.dispatch(authenticationActions.openLoginLayer())
          storeAPI.dispatch(userActions.userUpdateFailed())
        })
    }
    return next(action)
  }
