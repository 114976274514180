import { t } from '@lingui/macro'

export const getTranslatedStandingsHeading = (label: string) => {
  switch (label) {
    case 'Team':
      return t({ id: 'Team in standings heading', message: 'Team' })
    case 'GP':
      return t({ id: 'GP game played in standings heading', message: 'GP' })
    case 'W':
      return t({ id: 'W game Won in standings heading', message: 'W' })
    case 'L':
      return t({ id: 'L game lossed in standings heading', message: 'L' })
    case 'Pts':
      return t({ id: 'Pts points in standings heading', message: 'Pts' })
    case 'GF':
      return t({ id: 'GF goals for in standings heading', message: 'GF' })
    case 'GA':
      return t({ id: 'GA goals against in standings heading', message: 'GA' })
    case 'Form':
      return t({
        id: 'Form, latest five games form in standings heading',
        message: 'Form'
      })
    default:
      return label
  }
}
