/* eslint-disable react/require-default-props */
import React, { FC } from 'react'

import classNames from 'classnames'
import { Team } from '../../../../../../interfaces'
import styles from './ScoreboardTeamBadge.module.scss'

interface ScoreboardTeamBadgeProps {
  team: Team
  isAwayTeam?: boolean
}

export const ScoreboardTeamBadge: FC<ScoreboardTeamBadgeProps> = ({
  team,
  isAwayTeam = false
}) => (
  <div
    className={classNames(styles.ScoreboardTeamBadge, {
      [styles.IsAwayTeam]: isAwayTeam
    })}
  >
    <img
      src={team.badge_url}
      alt={team.name}
      className={styles.ScoreboardTeamBadgeLogo}
    />
    <div className={styles.ScoreboardTeamBadgeName}>{team.name}</div>
  </div>
)
