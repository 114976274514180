import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { Company } from '../interfaces/Company.interface'
import { getRichBreadcrumbMetaData } from './getRichBreadcrumbMetaData'
import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'

interface Props {
  channel: Company
  breadcrumbs: BreadCrumbLink[]
}

export const WebpageMeta: FC<Props> = ({ channel, breadcrumbs }) => {
  const channelLogoDefinition = `"logo":  {
    "@type": "ImageObject",
    "url": "${channel.logo_image_url}",
    "width": "150",
    "height": "150"
  }`
  return (
    <Helmet prioritizeSeoTags>
      <meta
        property="fb:app_id"
        content={process.env.REACT_APP_FACEBOOK_API_ID}
      />
      <meta property="og:title" content={channel.name} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${window.location.origin}${window.location.pathname}`}
      />
      <meta property="og:image" content={channel.logo_image_url} />
      <meta property="og:image:secure_url" content={channel.logo_image_url} />
      <meta property="og:site_name" content={channel.name} />
      <meta property="og:description" content={channel.name} />
      <meta property="og:locale" content={navigator.language} />
      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "WebPage",
            "@id": "${channel.subdomain}",
            "name": "${channel.name}",
            ${channelLogoDefinition}
          }`}
      </script>
      {
        // @ts-ignore
        channel.isRootChannel ? (
          <script type="application/ld+json">
            {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "@id": "${channel.subdomain}",
            "url": "${window.location.origin}${window.location.pathname}",
            "name": "${channel.name}",
            "logo":  {
              "@type": "ImageObject",
              "url": "${channel.logo_image_url}",
              "width": "175",
              "height": "40"
            }
          }`}
          </script>
        ) : (
          <script type="application/ld+json">
            {`{
          "@context": "http://schema.org",
          "@type": "ProfilePage",
          "@id": "${channel.subdomain}",
          "url": "${window.location.origin}${window.location.pathname}",
          "mainEntity": {
            "@type": "Organization",
            "name": "${channel.name}",
            ${channelLogoDefinition}            
          }
        }`}
          </script>
        )
      }
      {breadcrumbs.length > 0 && (
        <script type="application/ld+json">
          {getRichBreadcrumbMetaData(breadcrumbs)}
        </script>
      )}
    </Helmet>
  )
}
