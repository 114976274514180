import React, { FC } from 'react'

import { useCurrentBreakpoint, Breakpoint } from '../../hooks/CurrentBreakpoint'
import {
  ArrowDirection,
  PaginationArrowButton
} from '../buttons/PaginationButton/PaginationArrowButton'
import { PageLink } from './PageLink'
import { getPagesToDisplay } from './getPagesToDisplay'

import styles from './Pagination.module.scss'

interface Props {
  onChangePage: (newPage: number) => void
  totalPages: number
  currentPage: number
}

export const Pagination: FC<Props> = ({
  onChangePage,
  totalPages,
  currentPage
}) => {
  const currentBreakpoint = useCurrentBreakpoint()

  if (totalPages <= 1) return null

  const decrementCurrentPage = () => {
    onChangePage(currentPage - 1)
  }

  const incrementCurrentPage = () => {
    onChangePage(currentPage + 1)
  }
  const maxToDisplay =
    currentBreakpoint <= Breakpoint.sm
      ? currentBreakpoint <= Breakpoint.xs
        ? 5
        : 7
      : 9

  const pages = getPagesToDisplay(currentPage, totalPages, maxToDisplay)

  const pageLinks = pages.map(({ page, isEllipses }) => (
    <PageLink
      key={page}
      isEllipses={isEllipses}
      pageNumber={page.toString()}
      isCurrentPage={page === currentPage}
      onPageSelected={() => onChangePage(page)}
    />
  ))

  return (
    <div className={styles.PaginationContainer}>
      <PaginationArrowButton
        onClick={decrementCurrentPage}
        disabled={currentPage === 1}
        direction={ArrowDirection.left}
      />

      <div className={styles.PageLinksContainer}>{pageLinks}</div>

      <PaginationArrowButton
        onClick={incrementCurrentPage}
        disabled={currentPage === totalPages}
        direction={ArrowDirection.right}
      />
    </div>
  )
}
