import React, { FC } from 'react'
import ContentSlider from '../../../components/sliders/ContentSlider'
import TeamCardComponent from './TeamsCard/TeamCard'
import { TeamsSection } from './TeamsSection.interface'
import styles from './TeamsSection.module.scss'

interface FrontPageHeroProps {
  sectionData: TeamsSection
}
const OurTeamsComponent: FC<FrontPageHeroProps> = ({ sectionData }) => {
  const { content } = sectionData

  return (
    <div className={styles.SectionContainer}>
      <div className={styles.TitleArrowContainer}>
        <div className={styles.SectionTitle} data-testid="our-teams-title">
          {sectionData.sectionTitle}
        </div>
        <div className={styles.ArrowContainer} />
      </div>
      <div
        className={styles.ContentSliderContainer}
        data-testid="our-teams-component-slider-container"
      >
        <ContentSlider leftPadding>
          {content.map((card) => (
            <TeamCardComponent
              cardData={card}
              key={card.id}
              data-id="our-teams-card"
            />
          ))}
        </ContentSlider>
      </div>
    </div>
  )
}
export default OurTeamsComponent
