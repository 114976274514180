import React, { FC, ReactNode } from 'react'
import { Subheading } from './Subheading'

import styles from './Heading.module.scss'

interface Props {
  heading: string
  subheading: ReactNode
  onAddSchedule: () => void
  showAddButton: boolean
}

export const Heading: FC<Props> = ({
  heading,
  subheading,
  onAddSchedule,
  showAddButton
}) => (
  <div className={styles.HeadingContainer}>
    <h4>{heading}</h4>
    {subheading && (
      <div className={styles.Subheading}>
        <Subheading showAddButton={showAddButton} onAddClick={onAddSchedule}>
          {subheading}
        </Subheading>
      </div>
    )}
  </div>
)
