import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../../../redux-store/channelTheme/channelTheme.actions'
import {
  channelThemeHeaderFontIsLoadedSelector,
  channelThemeIsLoadingSelector,
  channelThemeSelector,
  channelThemeSubmenuFontIsLoadedSelector
} from '../../../redux-store/channelTheme/channelTheme.selectors'

export const useChannelTheme = () => {
  const theme = useSelector(channelThemeSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setIsLoading)
  }, [theme, dispatch])
  return {
    channelTheme: theme,
    channelThemeIsLoading: useSelector(channelThemeIsLoadingSelector),
    channelThemeHeaderFontIsLoaded: useSelector(
      channelThemeHeaderFontIsLoadedSelector
    ),
    channelThemeSubmenuFontIsLoaded: useSelector(
      channelThemeSubmenuFontIsLoadedSelector
    )
  }
}
