import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'

export const getCancelSubscription = async (id: number) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/cancel_subscription',
      params: { id },
      method: ApiEndpoint.METHODS.GET,
      authenticatable: true
    })

    await resultResponse

    return { isSuccess: true }
  } catch (e) {
    // @ts-ignore
    return { isSuccess: false }
  }
}
