import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'

import { getDistrictsForAssociation } from '../../../api/channel/getDistrictsForAssociation'
import { ChannelListItem } from '../../../components/lists/ChannelListItem/ChannelListItem'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { useChannelPage } from '../useChannelPage'
import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { getChannelLinkMapper } from '../../../components/lists/ChannelListItem/getChannelLink'

interface DistrictsProps {
  channelSlug: string
  isAdmin: boolean
}

const Districts: FC<DistrictsProps> = ({ channelSlug, isAdmin }) => {
  const [districts, setDistricts] = useState<AssociationClubEventDistrict[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const PER_PAGE_COUNT = 10

  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getDistrictsForAssociation,
    onData: (data: any) => setDistricts((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) => {
      setTotalPages(total / PER_PAGE_COUNT)
    }
  })

  const isEmpty = !districts?.length
  const emptyMessage = t`There are no districts currently registered for this association`
  const channelLinkMapper = getChannelLinkMapper('districts')

  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }

  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {districts?.map((district: AssociationClubEventDistrict) => (
        <ChannelListItem
          key={district.subdomain}
          channelLink={channelLinkMapper(district)}
          showAdmin={isAdmin}
        />
      ))}
    </ChannelPageList>
  )
}

export default Districts
