import React, { FC, useEffect } from 'react'
import styles from './DesktopMenu.module.scss'
import { SignedInContent } from 'components/MobileNavigationMenu/SignedInContent'
import { SignedOutContent } from 'components/MobileNavigationMenu/SignedOutContent'
import { SidebarMenuLink } from 'components/SidebarNavigation/MenuItem/MenuItem'
import { User } from 'interfaces/User.interface'
import { useComponentVisible } from 'helpers/useComponentVisible'

interface Props {
  onClose: () => void
  onHelpClick: () => void
  currentUser?: User
  menuLinks: SidebarMenuLink[]
  onLanguageSelectorClick: () => void
  showLanguageSelector: boolean
}

export const DesktopMenu: FC<Props> = ({
  onClose,
  menuLinks,
  currentUser,
  onHelpClick,
  onLanguageSelectorClick,
  showLanguageSelector
}) => {
  const { ref, isComponentVisible } = useComponentVisible(true)
  useEffect(() => {
    if (!isComponentVisible) {
      onClose()
    }
  }, [isComponentVisible])
  return (
    <div ref={ref} className={styles.DesktopMenu}>
      {currentUser ? (
        <SignedInContent
          menuLinks={menuLinks}
          currentUser={currentUser}
          onHelpClick={onHelpClick}
          onMenuItemClick={() => onClose()}
          showLanguageSelector={showLanguageSelector}
          onLanguageSelectorClick={onLanguageSelectorClick}
          onSignOutClick={onClose}
        />
      ) : (
        <SignedOutContent
          onHelpClick={onHelpClick}
          showLanguageSelector={showLanguageSelector}
          onLanguageSelectorClick={onLanguageSelectorClick}
        />
      )}
    </div>
  )
}
