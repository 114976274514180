import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface Props {
  productVariantID: number
  channelSlug: string
}
interface ProductTypeResponse {
  hasError: boolean
  errors?: any
}
export const postProductType = async (
  params: Props
): Promise<ProductTypeResponse> => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `session/companies/${params.channelSlug}/productVariant`,
      method: ApiEndpoint.METHODS.POST,
      params: params,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
