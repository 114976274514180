import React, { FC } from 'react'
import { plural, t, Trans } from '@lingui/macro'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import styles from '../ActivateLivestreamModal.module.scss'
import { GreenCheckmark } from '../../GreenCheckmark/GreenCheckmark'
import { StoreLinks } from '../../StoreLinks/StoreLinks'

interface SuccessfulActivationProps {
  numberOfStreams: number
  onClose: () => void
}

export const SuccessfulActivation: FC<SuccessfulActivationProps> = ({
  numberOfStreams,
  onClose
}) => {
  const contentTitle = plural(numberOfStreams, {
    one: '# Stream published',
    other: '# Streams published'
  })
  const contentDescription = plural(numberOfStreams, {
    one: 'The Stream is now published and will be visible for your supporters. Note that it can take a few minutes before it updates in the list.',
    other:
      'The Streams are now published and will be visible for your supporters. Note that it can take a few minutes before it updates in the list.'
  })
  return (
    <div className={styles.ModalContent}>
      <div className={styles.TopContainer}>
        <div
          className={styles.ContentTitle}
          data-testid="activate-livestream-content-title"
        >
          <span>{contentTitle}</span>
          <GreenCheckmark />
        </div>
        <div className={styles.ContentDescription}>{contentDescription}</div>
      </div>
      <div className={styles.TopContainer}>
        <div className={styles.SecondaryTitle}>
          <Trans>Solidsport Broadcast</Trans>
        </div>
        <div className={styles.ContentDescription}>
          <Trans>
            If you are planing to stream the game with a phone or tablet. Then
            you need to download the Broadcaster app for your device. Read more
            about our streaming school
          </Trans>{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.solidsport.com/sv/streamingskolan/"
          >
            <Trans>here</Trans>
          </a>
        </div>
      </div>
      <StoreLinks />
      <div className={styles.ButtonsContainer}>
        <Button
          type={ButtonType.GhostWithBoarder}
          buttonSize="Medium"
          label={t`Close`}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
