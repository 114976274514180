import React from 'react'
export const MyPurchasesIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.01758 10.1628C1.01758 9.20617 1.79308 8.43066 2.74972 8.43066H19.2854C20.2421 8.43066 21.0176 9.20617 21.0176 10.1628V10.1628C21.0176 10.6518 20.8109 11.118 20.4486 11.4463L20.4053 11.4856C19.2556 12.5275 19.2556 14.3339 20.4053 15.3758L20.4486 15.415C20.8109 15.7434 21.0176 16.2096 21.0176 16.6985V16.6985C21.0176 17.6552 20.2421 18.4307 19.2854 18.4307H2.74972C1.79308 18.4307 1.01758 17.6552 1.01758 16.6985V16.6985C1.01758 16.2096 1.22424 15.7434 1.58655 15.415L1.62989 15.3758C2.77957 14.3339 2.77957 12.5275 1.62989 11.4856L1.58655 11.4463C1.22424 11.118 1.01758 10.6518 1.01758 10.1628V10.1628Z"
      stroke="#2F3135"
      strokeWidth="1.5"
    />
    <path
      d="M19.2046 9.11427C19.5443 9.35137 20.0118 9.26825 20.2489 8.92861C20.486 8.58898 20.4029 8.12143 20.0633 7.88433L19.2046 9.11427ZM18.8997 5.43743L19.5612 5.79081L18.8997 5.43743ZM2.80102 7.14763L17.4273 2.78669L16.9987 1.34922L2.37243 5.71016L2.80102 7.14763ZM18.6714 3.45927L18.7096 3.58744L20.147 3.15885L20.1088 3.03068L18.6714 3.45927ZM18.6333 4.34433L18.2382 5.08406L19.5612 5.79081L19.9564 5.05108L18.6333 4.34433ZM18.7096 3.58744C18.7849 3.83993 18.7574 4.11193 18.6333 4.34433L19.9564 5.05108C20.2667 4.47009 20.3352 3.79008 20.147 3.15885L18.7096 3.58744ZM18.2382 5.08406C17.4933 6.47838 17.9085 8.20939 19.2046 9.11427L20.0633 7.88433C19.39 7.41428 19.1743 6.5151 19.5612 5.79081L18.2382 5.08406ZM17.4273 2.78669C17.9566 2.62888 18.5136 2.93001 18.6714 3.45927L20.1088 3.03068C19.7143 1.70753 18.3219 0.954712 16.9987 1.34922L17.4273 2.78669ZM2.37243 5.71016C1.08899 6.09283 0.372682 7.45752 0.786745 8.73118L2.21326 8.26742C2.05978 7.79532 2.32529 7.28947 2.80102 7.14763L2.37243 5.71016Z"
      fill="#2F3135"
    />
    <path
      d="M14.9658 10.2002V16.7174"
      stroke="#2F3135"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M14.0518 4.80176L14.8952 8.31142"
      stroke="#2F3135"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M7 10.2002V16.7174"
      stroke="#2F3135"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
  </svg>
)
