import { PromotedChannelApiResponse } from './promotedChannelApi.interface'

export const mapPromotedChannel = (
  promotedChannels: PromotedChannelApiResponse[]
) =>
  promotedChannels.map((channel: PromotedChannelApiResponse) => ({
    name: channel.title,
    logoUrl: channel.logo_image_url,
    sportName: channel.sport.name,
    subdomain: channel.slug,
    rootDomain: channel.root_level_domain
  }))
