import React, { FC } from 'react'
import { StreamInteraction } from './StreamInteraction'
import { GameResult } from './components/GameResult/GameResult'
import { Lineups } from './components/Lineups/Lineups'
import { AboutStream } from './components/AboutStream/AboutStream'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'
import { useSearchParam } from 'hooks/useSearchParam'

export const StreamPageTabContent: FC = () => {
  const tab = useSearchParam('tab')
  const stream = useSelector(streamSelector)
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const showGameLineups = isSwedishHandball
  const { hasGame, canComment } = stream

  switch (tab) {
    case 'chat':
    case 'comments':
      return canComment ? <StreamInteraction /> : <AboutStream />
    case 'result':
      return hasGame ? <GameResult /> : null
    case 'lineup':
      return showGameLineups ? <Lineups /> : null
    case 'about':
    default:
      return <AboutStream />
  }
}
