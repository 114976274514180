import { useEffect } from 'react'
import { VideoAdsType } from 'hooks/VideoAds/videoAdsType'

interface Props {
  setFullscreen: (value: boolean) => void
  videoAds: VideoAdsType
  getVideoElement: any
}

export const useFullscreen = ({
  setFullscreen,
  videoAds,
  getVideoElement
}: Props) =>
  useEffect(() => {
    // Handle fullscreen events
    const onFullscreenChanged = (e: any) => {
      const element = e.target
      if (element) {
        if (element.className?.includes('fullscreen')) {
          // Exiting fullscreen
          setFullscreen(false)
          videoAds.setFullscreen(false)
          element.className = element.className.replace('fullscreen', '').trim()
          try {
            ;(window as any).FreshworksWidget('show')
            // eslint-disable-next-line no-empty
          } catch (_) {}
        } else {
          // Entering fullscreen
          setFullscreen(true)
          videoAds.setFullscreen(true)
          element.className = [...element.className.split(' '), 'fullscreen']
            .join(' ')
            .trim()
          try {
            ;(window as any).FreshworksWidget('hide')
            // eslint-disable-next-line no-empty
          } catch (_) {}
        }
      }
    }
    document.addEventListener('fullscreenchange', onFullscreenChanged)
    /* Firefox */
    document.addEventListener('mozfullscreenchange', onFullscreenChanged)
    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', onFullscreenChanged)
    /* IE / Edge */
    document.addEventListener('msfullscreenchange', onFullscreenChanged)

    // iOS
    if (getVideoElement()) {
      getVideoElement().addEventListener(
        'webkitendfullscreen',
        onFullscreenChanged
      )
      getVideoElement().addEventListener(
        'webkitbeginfullscreen',
        onFullscreenChanged
      )
    }

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChanged)
      document.removeEventListener('mozfullscreenchange', onFullscreenChanged)
      document.removeEventListener(
        'webkitfullscreenchange',
        onFullscreenChanged
      )
      document.removeEventListener('msfullscreenchange', onFullscreenChanged)
      if (getVideoElement()) {
        getVideoElement().removeEventListener(
          'webkitendfullscreen',
          onFullscreenChanged
        )
        getVideoElement().removeEventListener(
          'webkitbeginfullscreen',
          onFullscreenChanged
        )
      }
    }
  }, [])
