import { t } from '@lingui/macro'
import HighlightIcon from 'assets/icons/HighlightIcon'
import { LinkIconButton } from 'components/buttons/LinkIconButton/LinkIconButton'
import { ButtonType } from 'components/buttons/TextIconButton'
import React, { FC } from 'react'

interface Props {
  id: string
  channelSlug: string
  highlightSlug: string
}
export const HighlightsButton: FC<Props> = ({
  id,
  channelSlug,
  highlightSlug
}) => (
  <LinkIconButton
    type={ButtonType.Ghost}
    buttonSize="Small"
    label={t`Highlights`}
    id={id}
    href={`/${channelSlug}/watch/${highlightSlug}`}
  >
    <HighlightIcon />
  </LinkIconButton>
)
