import React, { FC } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'
import { t } from '@lingui/macro'
import { DropdownList } from 'components/Forms/DropdownListField/DropdownList'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { EventDate } from 'api/multistageTournament/getEventDates'
import styles from './DateFilterForm.module.scss'
import { getDateFromDateSlug } from './getDateFromDateSlug'

interface SelectDate {
  dateSlug?: string
  dateName?: string
}
interface Props {
  dropdownList: EventDate[]
  preSelectedDate: EventDate | undefined
  onSelectedDate: (selectedDate: EventDate) => void
  onCloseForm: () => void
}

export const DateFilterForm: FC<Props> = ({
  dropdownList,
  preSelectedDate,

  onSelectedDate,
  onCloseForm
}) => {
  const initialValues = {
    dateSlug: preSelectedDate?.slug || '',
    dateName: preSelectedDate?.name || ''
  }
  const channelAccountSchema = yup.object({
    dateSlug: yup.string().required(t`please select a date`)
  })
  const onSubmit = async (
    values: SelectDate,
    formikHelpers: FormikHelpers<any>
  ) => {
    const dateFromSlug = getDateFromDateSlug(values.dateSlug!, dropdownList)

    const selectedEventDate: EventDate = {
      name: dateFromSlug ? dateFromSlug.name : '',
      slug: values.dateSlug!
    }
    onSelectedDate(selectedEventDate)
    formikHelpers.setSubmitting(true)
  }

  return !dropdownList ? null : (
    <Formik
      initialValues={initialValues}
      validationSchema={channelAccountSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        const dateList = dropdownList.map((date: EventDate) => ({
          id: date.slug,
          label: date.name,
          disabled: false,
          icon: false
        }))
        return (
          <Form>
            <div className={styles.FormContainer}>
              <Field
                component={DropdownList}
                placeholder={t`Select date`}
                dropdownListData={dateList}
                id="dateSlug"
                name="dateSlug"
                value={initialValues.dateSlug}
                type="dark"
                onChange={(id: string) => props.setFieldValue('dateSlug', id)}
              />
              <FormActionButtons
                cancelButtonLabel={t`Cancel`}
                submitButtonLabel={t`Select date `}
                onCancel={onCloseForm}
                formik={props}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
