import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import styles from './ProvidedByLogo.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
interface Props {
  link: string
  imageUrl: string
  integrationName: string
}

export const ProvidedByLogo: FC<Props> = ({
  link,
  imageUrl,
  integrationName
}) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Text}>
        <Trans>Data provided by</Trans>
      </div>
      {link ? (
        <a
          className={styles.Link}
          href={link}
          target={LinkTarget.NewWindow}
          data-testid="provided-by-link"
        >
          <img className={styles.Image} src={imageUrl} alt={integrationName} />
        </a>
      ) : (
        <img className={styles.Image} src={imageUrl} alt={integrationName} />
      )}
    </div>
  )
}
