import React, { FC } from 'react'
import styles from './SponsorItem.module.scss'

interface Props {
  imgPath: string
  urlPath: string
  sponsorName: string
}
const SponsorItem: FC<Props> = ({ imgPath, urlPath, sponsorName }) => {
  const handleClick = () => {
    window.open(urlPath, '_blank')
  }
  return (
    <div
      className={styles.SponsorItem}
      role="button"
      onClick={() => handleClick()}
      tabIndex={0}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleClick()
        }
      }}
    >
      <img src={imgPath} alt={sponsorName} />
    </div>
  )
}
export default SponsorItem
