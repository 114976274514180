import React, { FC } from 'react'

export const MapPinIcon: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49 23.3333C49 39.6667 28 53.6667 28 53.6667C28 53.6667 7 39.6667 7 23.3333C7 17.7638 9.21249 12.4224 13.1508 8.48409C17.089 4.54583 22.4305 2.33333 28 2.33333C33.5695 2.33333 38.911 4.54583 42.8492 8.48409C46.7875 12.4224 49 17.7638 49 23.3333Z"
      stroke="white"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 30.3333C31.866 30.3333 35 27.1993 35 23.3333C35 19.4673 31.866 16.3333 28 16.3333C24.134 16.3333 21 19.4673 21 23.3333C21 27.1993 24.134 30.3333 28 30.3333Z"
      stroke="white"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
