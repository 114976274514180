import React from 'react'
export const ResetIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.53562 5.27564L2.90255 6.20481L1.97338 2.57175"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.84132 5.97755C3.82762 4.39461 5.32833 3.19904 7.09166 2.59145C8.85499 1.98387 10.7736 2.00122 12.5257 2.64062C14.2777 3.28001 15.7566 4.50253 16.7141 6.10306C17.6716 7.70359 18.0495 9.58474 17.7843 11.4309"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.84435 5.97224C3.90373 4.27151 5.55588 3.02378 7.48157 2.47015C9.40726 1.91652 11.4698 2.09629 13.2707 2.97471C15.0716 3.85314 16.483 5.36787 17.2321 7.22624C17.9813 9.0846 18.0151 11.1547 17.327 13.0365C16.6389 14.9184 15.2777 16.4784 13.5065 17.4151C11.7352 18.3519 9.67965 18.5989 7.73691 18.1084C5.79417 17.6179 4.10216 16.4248 2.98783 14.7596"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
