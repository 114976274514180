import React, { FC } from 'react'
import { Plural, t } from '@lingui/macro'
import classNames from 'classnames'

import TextIconButton, {
  ButtonType
} from '../../../../components/buttons/TextIconButton'
import styles from './CompetitionItem.module.scss'
import { AddIcon } from '../../GameScheduleListContainer/AddIcon'
import { CompetitionListItem } from './competitionListItem'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import { AddButtonForLeague } from './AddButtonForLeague'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../../hooks/CurrentBreakpoint'

interface Props {
  competitonListItem: CompetitionListItem
  onAddCompetition: () => void
  channelType: ChannelTypes
  competitionIsAlreadyClaimed: boolean
}
export const CompetitionItem: FC<Props> = ({
  competitonListItem,
  onAddCompetition,
  channelType,
  competitionIsAlreadyClaimed
}) => {
  const isLeagueChannel = [ChannelTypes.league].includes(channelType)
  const addButonForLeague = isLeagueChannel
  const disabledTitle = isLeagueChannel && competitionIsAlreadyClaimed
  const breakpoint = useCurrentBreakpoint()
  const showTotalGamesForMobile = isLeagueChannel && breakpoint <= Breakpoint.sm
  const showTotalGamesForDesktop = isLeagueChannel && breakpoint > Breakpoint.sm

  return (
    <div className={styles.CompetitionItemContainer}>
      <div className={styles.LeftContainer}>
        {showTotalGamesForMobile && (
          <div className={styles.TotalGames}>
            <Plural
              value={competitonListItem.totalGames}
              one="# game"
              other="# games"
            />
          </div>
        )}
        <div
          className={classNames(styles.Title, {
            [styles.TitleIsDisabled]: disabledTitle
          })}
        >
          {competitonListItem.title}
        </div>
        <div className={styles.District}>{competitonListItem.groupName}</div>
        {showTotalGamesForDesktop && (
          <div className={styles.TotalGames}>
            <Plural
              value={competitonListItem.totalGames}
              one="# game"
              other="# games"
            />
          </div>
        )}
      </div>
      {addButonForLeague ? (
        <AddButtonForLeague
          onAddCompetition={onAddCompetition}
          showLockIcon={competitionIsAlreadyClaimed}
        />
      ) : (
        <TextIconButton
          type={ButtonType.Ghost}
          buttonSize="Medium"
          label={t`Add`}
          id="add-competition"
          onClick={() => onAddCompetition()}
        >
          <AddIcon />
        </TextIconButton>
      )}
    </div>
  )
}
