import { Trans } from '@lingui/macro'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { channelSelector } from '../../../../../redux-store/channel/channel.selectors'
import { ArrowLeft2 } from './ArrowLeft2'
import styles from './BackButton.module.scss'

interface BackButtonProps {
  onBack?: () => void
  label?: string
}

export const BackButton: FC<BackButtonProps> = ({ onBack, label }) => {
  const history = useHistory()
  const channel = useSelector(channelSelector)

  const channelSlug = channel?.data?.subdomain

  const goBackToChannel = () => {
    history.push(`/${channelSlug}`)
  }

  const handleClick = onBack || goBackToChannel

  return (
    <div
      className={styles.BackButton}
      role="button"
      onClick={handleClick}
      tabIndex={0}
      onKeyPress={handleClick}
    >
      <ArrowLeft2 />
      {label || <Trans>Back</Trans>}
    </div>
  )
}

BackButton.defaultProps = {
  onBack: undefined,
  label: undefined
}
