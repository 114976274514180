import { useState } from 'react'

const LOADING = {
  INITIALIZING: 'INITIALIZING',
  LOADING: 'LOADING',
  LOADING_MORE: 'LOADING_MORE',
  SUCCESS: 'SUCCESS',
  EMPTY: 'EMPTY',
  ERROR: 'ERROR'
}

const useLoadingStatus = (initialStatus: string = LOADING.INITIALIZING) => {
  const [currentStatus, setCurrentStatus] = useState(initialStatus)
  const loading = () => {
    setCurrentStatus(LOADING.LOADING)
  }
  const loadingMore = () => {
    setCurrentStatus(LOADING.LOADING_MORE)
  }
  const success = () => {
    setCurrentStatus(LOADING.SUCCESS)
  }
  const error = () => {
    setCurrentStatus(LOADING.ERROR)
  }
  const empty = () => {
    setCurrentStatus(LOADING.EMPTY)
  }
  const reset = () => {
    setCurrentStatus(LOADING.INITIALIZING)
  }
  const isInitializing = () => currentStatus === LOADING.INITIALIZING
  const isLoading = () => currentStatus === LOADING.LOADING
  const isLoadingMore = () => currentStatus === LOADING.LOADING_MORE
  const isSuccess = () => currentStatus === LOADING.SUCCESS
  const isError = () => currentStatus === LOADING.ERROR
  const isEmpty = () => currentStatus === LOADING.EMPTY

  return {
    loading,
    loadingMore,
    success,
    error,
    empty,
    reset,
    isInitializing,
    isLoading,
    isLoadingMore,
    isSuccess,
    isError,
    isEmpty,
    currentStatus
  }
}
export default useLoadingStatus
