import TagManager from 'react-gtm-module'
export enum SportsDataModalClose {
  inviteAdmin = 'invite_admin',
  createTeam = 'create_team',
  importGames = 'import_games',
  onCrossClick = 'cross_click'
}
export const triggerGTMAddTeamSuccess = (
  channelSlug: string,
  createdChannelName: string,
  createdChannelSlug: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_add_team_success',
      channel_slug: channelSlug,
      created_channel_name: createdChannelName,
      created_channel_slug: createdChannelSlug
    }
  })
}
export const triggerGTMOnModalClose = (
  channelSlug: string,
  stepInProgress: SportsDataModalClose
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_close_add_team_modal_click',
      channel_slug: channelSlug,
      closed_modal_from_step: stepInProgress
    }
  })
}

export const triggerGTMOnInviteAdminSuccess = (channelSlug: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_invite_admin_success',
      created_channel_slug: channelSlug
    }
  })
}

export const triggerGTMOnImportGameClick = (
  channelSlug: string,
  createdChannelName: string,
  createdChannelSlug: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_import_games_after_invitation_sent_clicked',
      channel_slug: channelSlug,
      created_channel_name: createdChannelName,
      created_channel_slug: createdChannelSlug
    }
  })
}
