import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { Toggle } from 'components/Toggle/Toggle'
import styles from './ContentTypeToggle.module.scss'
import { StartBannerContentType } from 'features/customContent/StartPageBanner/startPageBannerTypes'

interface Props {
  contentType: StartBannerContentType
  onChangeContentType: (newValue: string) => void
}

export const ContentTypeToggle: FC<Props> = ({
  contentType,
  onChangeContentType
}) => {
  const newValue =
    contentType === StartBannerContentType.InfoBox
      ? StartBannerContentType.ImageLink
      : StartBannerContentType.InfoBox

  return (
    <div className={styles.ContentFormContainer}>
      <Toggle
        firstLabel={t`Info Box`}
        secondLabel={t`Image Link`}
        firstButtonSelected={contentType === StartBannerContentType.InfoBox}
        onClick={() => onChangeContentType(newValue)}
      />
    </div>
  )
}
