import React, { FC } from 'react'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { AppleIcon } from './AppleIcon'
import useAppleLogin from '../useAppleLogin'
import styles from './AppleLoginButton.module.scss'

export const AppleLoginButton: FC = () => {
  const appleLogin = useAppleLogin()
  if (appleLogin) {
    return (
      <IconTextButton
        type={ButtonType.Ghost}
        buttonSize="Medium"
        label={'Apple'}
        onClick={() => appleLogin()}
        id="apple-login-button"
        externalStyle={styles.AppleButton}
      >
        <AppleIcon />
      </IconTextButton>
    )
  }
  return null
}
