import {
  ProductTypeID,
  ProductTypeLabel
} from '../../pages/ChannelPage/ChannelSettings/Branding/BrandingHeader/ProductTypes'

export const getChannelProductType = (productLevelId: number) => {
  switch (productLevelId) {
    case 0:
      return { label: ProductTypeLabel.Default, id: ProductTypeID.Default }
    case 1:
      return { label: ProductTypeLabel.Default, id: ProductTypeID.Default }
    case 2:
      return {
        label: ProductTypeLabel.PoweredBy,
        id: ProductTypeID.PoweredBy
      }
    default:
      return { label: ProductTypeLabel.Default, id: ProductTypeID.Default }
  }
}
