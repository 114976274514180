import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface ActivateLivestreamApiResponse {
  successfully_activated: string[]
  failed_activation: string[]
  already_activated_game_idents: string[]
  access_denied_game_idents: string[]
  not_found_game_idents: string[]
}

export const putActivateLivestream = async ({
  mediaObjectIdent,
  channelSlug
}: {
  mediaObjectIdent: string
  channelSlug: string
}) => {
  const formData = new FormData()
  formData.append('ident_list[]', mediaObjectIdent)
  formData.append('channel_slug', channelSlug)
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'games/activate_livestreams',
      method: ApiEndpoint.METHODS.PUT,
      file: formData,
      authenticatable: true
    })
    await response
    const activationResponse: ActivateLivestreamApiResponse = response.data
    const hasError =
      activationResponse.access_denied_game_idents.length > 0 ||
      activationResponse.failed_activation.length > 0 ||
      activationResponse.not_found_game_idents.length > 0 ||
      activationResponse.already_activated_game_idents.length > 0
    return { hasError }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
