import React, { FC } from 'react'

import { GameScheduleListRow } from '../ListItem/GameScheduleListRow'
import { GameScheduleItem } from '../ListItem/gameScheduleItem'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import { GameScheduleListCard } from '../ListItem/GameScheduleListCard'

import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import styles from './GameScheduleList.module.scss'

interface Props {
  listData: GameScheduleItem[]
  onRemoveSchedule: (id: string) => void
  showRemoveButton: boolean
  channelType: ChannelTypes
}
export const GameScheduleList: FC<Props> = ({
  listData,
  onRemoveSchedule,
  showRemoveButton,
  channelType
}) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const showColumnList = !(currentBreakpoint <= Breakpoint.sm)
  return (
    <div className={styles.GameScheduleList} data-testid="game-schedule-list">
      {listData.map((gameList: GameScheduleItem) =>
        showColumnList ? (
          <GameScheduleListRow
            key={gameList.id + gameList.teamId}
            gameListItem={gameList}
            onRemoveSchedule={onRemoveSchedule}
            showRemoveButton={showRemoveButton}
            channelType={channelType}
          />
        ) : (
          <GameScheduleListCard
            key={gameList.id + gameList.teamId}
            gameListItem={gameList}
            onRemoveSchedule={onRemoveSchedule}
            showRemoveButton={showRemoveButton}
            channelType={channelType}
          />
        )
      )}
    </div>
  )
}
