import React, { FC } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Trans } from '@lingui/macro'

import { GameScheduleItemStatus } from './gameScheduleItem'
import { RemoveIcon } from './RemoveIcon'
import { getGameScheduleStatusText } from './getGameScheduleStatusText'
import { GameScheduleListItemProps } from './gameScheduleListItemProps'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

import styles from './GameScheduleListCard.module.scss'

export const GameScheduleListCard: FC<GameScheduleListItemProps> = ({
  gameListItem,
  onRemoveSchedule,
  showRemoveButton,
  channelType
}) => {
  const statusText = getGameScheduleStatusText(gameListItem)
  return (
    <div className={classNames(styles.GameScheduleCard)}>
      <div className={styles.Item}>
        <div className={styles.Name}>{gameListItem.title}</div>
        {statusText && (
          <div
            className={classNames(styles.Status, {
              [styles.StatusActive]:
                gameListItem.status === GameScheduleItemStatus.Active,
              [styles.StatusUpcoming]:
                gameListItem.status === GameScheduleItemStatus.Future
            })}
          >
            {statusText}
          </div>
        )}
        {[ChannelTypes.league].includes(channelType) && (
          <div className={styles.TotalGames}>
            <div className={styles.Label}>
              <Trans>Games</Trans>
            </div>
            {gameListItem.totalGames}
          </div>
        )}
        <div className={styles.Date}>
          <div className={styles.Label}>
            <Trans>Start date</Trans>:
          </div>
          {dayjs.unix(gameListItem.startDate).format('MMM DD, YYYY')}
        </div>
        <div className={styles.Date}>
          <div className={styles.Label}>
            <Trans>End date</Trans>:
          </div>
          {dayjs.unix(gameListItem.endDate).format('MMM DD, YYYY')}
        </div>
      </div>
      <div className={styles.RemoveButton}>
        {showRemoveButton && (
          <div
            className={styles.RemoveButtonIcon}
            role="button"
            onClick={() =>
              onRemoveSchedule(gameListItem.id, gameListItem.teamId)
            }
            onKeyDown={() => null}
            tabIndex={0}
          >
            <RemoveIcon />
          </div>
        )}
      </div>
    </div>
  )
}
