import { Location } from 'interfaces'
import { FilterItem } from 'features/multistageTournaments/HierarchicalFilter/FilterList/FilterList'

export const mapLocationsResponse = (apiResponse: Location[]): FilterItem[] =>
  apiResponse.map((group: Location) => ({
    label: group.name,
    shortLabel: '',
    slug: group.slug,
    children: group.children ? mapLocationsResponse(group.children) : undefined
  }))
