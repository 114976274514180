import React, { FC, ReactNode, useEffect, useState } from 'react'
import styles from './MenuItem.module.scss'
import PageLink, { MenuLink } from 'components/page/menuLink/MenuLink'
import classNames from 'classnames'
import { OpenCloseArrow } from 'components/OpenCloseArrow/OpenCloseArrow'
import { SubmenuLink } from './SubmenuItem/SubmenuItem'
import { Trans } from '@lingui/macro'

export interface SidebarMenuLink extends MenuLink {
  icon?: ReactNode
  subMenuLinks?: MenuLink[]
  onClick?: () => void
}

export const MenuItem: FC<SidebarMenuLink> = ({
  translationText,
  link,
  linkTarget,
  type,
  icon,
  subMenuLinks,
  onClick
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const isActive = location.pathname === link
  const isSubmenuActive = subMenuLinks?.find(
    (menuLink: MenuLink) => location.pathname === menuLink.link
  )
  useEffect(() => {
    if (isSubmenuActive) {
      setIsSubmenuOpen(true)
    }
  }, [isSubmenuActive])
  return !subMenuLinks ? (
    <div
      data-testid="menu-item"
      className={classNames(styles.MenuItem, { [styles.Active]: isActive })}
    >
      {icon && (
        <div className={styles.Icon} data-testid="menu-item-icon">
          {icon}
        </div>
      )}
      <PageLink
        type={type}
        link={link}
        translationText={translationText}
        linkTarget={linkTarget}
        onClick={onClick}
      />
    </div>
  ) : (
    <div className={styles.Container}>
      <div
        onKeyDown={() => setIsSubmenuOpen(!isSubmenuOpen)}
        onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
        role="button"
        tabIndex={0}
        className={classNames(styles.MenuItem, {
          [styles.Active]: isActive
        })}
        data-testid="menu-item"
      >
        <div className={styles.LinkContent}>
          {icon && (
            <div className={styles.Icon} data-testid="menu-item-icon">
              {icon}
            </div>
          )}
          <div className={styles.Label}>{<Trans id={translationText} />}</div>
        </div>
        <OpenCloseArrow isOpen={isSubmenuOpen} />
      </div>
      {isSubmenuOpen && (
        <div className={styles.SubmenuLinks}>
          {subMenuLinks.map((item: MenuLink) => {
            return (
              <SubmenuLink
                key={item.translationText}
                submenu={item}
                onClick={onClick}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
