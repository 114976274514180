import React, { FC } from 'react'
import classNames from 'classnames'

import { ArrowDown } from './ArrowDown'
import styles from './OpenCloseArrow.module.scss'

interface Props {
  isOpen: boolean
}

export const OpenCloseArrow: FC<Props> = ({ isOpen }) => (
  <div
    className={classNames(styles.Arrow, {
      [styles.ArrowRotated]: isOpen
    })}
    data-testid="chevron-icon"
  >
    <ArrowDown />
  </div>
)
