import React, { FC, useState } from 'react'
import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import { AccountInfo } from './AccountInfo/AccountInfo'
import { SignupContent, SignupStep } from './SignupContent'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

interface Props {
  isOpen: boolean
  onClose: () => void
  onLoginClick: () => void
  onAllFormSuccess: () => void
}

export const SignupModal: FC<Props> = ({
  isOpen,
  onClose,
  onLoginClick,
  onAllFormSuccess
}) => {
  const breakpoint = useCurrentBreakpoint()
  const [isFirstStep, setIsFirstStep] = useState(true)
  const showAccountInfo = breakpoint > Breakpoint.sm || isFirstStep
  const handleSwitchStep = (step: SignupStep) => {
    if (step === SignupStep.FirstStep) {
      setIsFirstStep(true)
    } else {
      setIsFirstStep(false)
    }
  }
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={onClose}
      firstContainerContent={
        <SignupContent
          onSwitchStep={(step: SignupStep) => handleSwitchStep(step)}
          onLoginClick={() => onLoginClick()}
          onAllFormSuccess={() => onAllFormSuccess()}
        />
      }
      firstContainerTheme={ModalTheme.Primary}
      secondContainerContent={showAccountInfo && <AccountInfo />}
      secondaryContainerTheme={ModalTheme.Secondary}
    />
  )
}
