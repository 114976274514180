import React, { FC, ReactNode } from 'react'

import { Trans } from '@lingui/macro'
import { RoundButton } from 'components/buttons/RoundButton/RoundButton'
import EditIcon from 'assets/icons/EditIcon'
import { BinIcon } from 'assets/icons/BinIcon'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { ViewOnStartPageButton } from './ViewOnStartPageButton'
import styles from './CustomContentAdminItem.module.scss'

interface Props {
  channelSlug: string
  children: ReactNode
  onEdit: () => void
  onRemove: () => void
}

export const CustomContentAdminItem: FC<Props> = ({
  channelSlug,
  children,
  onEdit,
  onRemove
}) => {
  const breakpoint = useCurrentBreakpoint()
  const isMobileView = breakpoint < Breakpoint.sm
  return (
    <div className={styles.Container} data-testid="start-page-banner-content">
      <div className={styles.TopWrapper}>
        <div className={styles.Title}>
          <Trans>Preview</Trans>
        </div>

        <div className={styles.ButtonContainer}>
          <RoundButton id={'edit'} onClick={onEdit}>
            <EditIcon />
          </RoundButton>
          <RoundButton id={'remove'} onClick={onRemove}>
            <BinIcon />
          </RoundButton>
          {!isMobileView && (
            <ViewOnStartPageButton
              isMobileView={isMobileView}
              channelSlug={channelSlug}
            />
          )}
        </div>
      </div>
      <div className={styles.ItemOverview}>{children}</div>
      {isMobileView && (
        <div className={styles.BottomViewOnStartPageBtn}>
          <ViewOnStartPageButton
            isMobileView={isMobileView}
            channelSlug={channelSlug}
          />
        </div>
      )}
    </div>
  )
}
