import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { mapLocationsResponse } from './mapLocationsResponse'

export const getEventLocations = async ({
  eventSlug,
  slug
}: {
  eventSlug: string
  slug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `events/event/${eventSlug}/locations`,
      params: {
        company: slug
      }
    })
    const responseData = await resultResponse
    const map = mapLocationsResponse(responseData.data)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
