import { ChannelMenuLink } from '../menuLinks'
import { t } from '@lingui/macro'

export interface CustomContent {
  header?: string
  footer?: string
}

export const parseHTMLContent = (content: string | undefined) => {
  if (!content) return null
  const element = document.createElement('div')
  element.innerHTML = content.trim()

  const result = Array.from(element.childNodes)
    .map((node) => node.textContent)
    .filter(Boolean)
    .filter((textContent) => typeof textContent === 'string')
    .map((textContent) => textContent?.trim())
    .join(' ')

  element.remove()

  return result
}

export const getMetaDescription = (
  submenuItem: ChannelMenuLink,
  domain: string,
  sport: string
) => {
  const sportName = sport.toLowerCase()
  switch (submenuItem.name) {
    case 'videos':
      return t`All videos and highlights on ${domain} are gathered here. See matches, highlights and videos from ${sportName} matches - at all levels.`
    case 'games':
      return t`Find your live broadcast at ${domain}. All live ${sportName} matches from hundreds of ${sportName} teams and associations are gathered here.`
    case 'leagues':
      return t`Watch ${sportName} leagues and much more at ${domain}. All ${sportName} leagues and ${sportName} series that are broadcast live via ${domain} are gathered here.`
    case 'clubs':
      return t`Follow your favorite club on ${domain}, the joint streaming service for ${sportName}. Watch all ${sportName} matches, live and whenever you want.`
    case 'districts':
      return t`Find your district on ${domain}, the common streaming service for ${sportName}. Here you will find all live matches from all ${sportName} districts.`
    case 'news':
      return t`Take part in the latest news from ${domain}, the joint streaming service for ${sportName}. Here you will find all ${sportName} news from ${sportName}.`
    default:
    case 'start':
      return t`${domain} enables all ${sportName} teams and ${sportName} cups to broadcast live matches while allowing supporters to share in all the excitement. Live and in replay.`
  }
}
