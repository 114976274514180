import React, { FC, useState } from 'react'

import { t } from '@lingui/macro'

import { deleteDisconnectGamesFromTeam } from '../../../api/sportData/deleteRemoveGamesFromTeam'
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal/ConfirmationModal'
import {
  useStatusMessageContext,
  useStatusMessageDispatchContext
} from '../../../pages/ChannelPage/ContentManager/StatusMessageContext'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { disconnectGamesFromLeague } from '../../../api/sportData/deleteRemoveGamesFromLeague'
import TagManager from 'react-gtm-module'

interface Props {
  competitionId: string
  competitionName: string
  competitionTeamId: string
  modalIsOpen: boolean
  channelType: ChannelTypes
  onClose: () => void
}
export const RemoveCompetitionModal: FC<Props> = ({
  competitionId,
  competitionName,
  competitionTeamId,
  modalIsOpen,
  channelType,
  onClose
}) => {
  const messageState = useStatusMessageContext()

  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(modalIsOpen)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useStatusMessageDispatchContext()
  const channelName = messageState.selectedTeam?.name

  const handleGTMEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_removed_competition_from_channel',
        channel_name: channelName,
        competition_name: competitionName,
        competion_team_id: competitionTeamId,
        channel_type: channelType
      }
    })
  }
  const removeCompetitionFromChannel = async () => {
    setIsLoading(true)
    const params = {
      teamId: competitionTeamId,
      competitionId,
      channelSlug: messageState.selectedTeam.slug
    }
    const response = await ([ChannelTypes.league].includes(channelType)
      ? disconnectGamesFromLeague(params)
      : deleteDisconnectGamesFromTeam(params))
    if (response.hasError) {
      setIsLoading(false)
      setIsError(true)
      return null
    }
    setRemoveModalIsOpen(false)
    if (channelType === ChannelTypes.league) {
      dispatch({ type: 'toggle_show_competition_removed' })
    } else {
      dispatch({ type: 'show_remove_team_from_competition' })
    }
    handleGTMEvent()
    onClose()
    return response
  }
  if (!messageState.selectedTeam) return null

  const defaultText = t`You are removing ${competitionName} from ${channelName}, removing the competition will remove all games on your channel that relates to the competition. `
  const errorText = t`Something went wrong when trying to remove ${competitionName} from ${channelName}, please try again.`

  const closeModal = () => {
    setRemoveModalIsOpen(false)
    onClose()
  }

  return (
    <ConfirmationModal
      modalHeader={t`Are you sure?`}
      title={t`You are removing ${competitionName}`}
      subText={defaultText}
      isError={isError}
      isLoading={isLoading}
      errorMessage={errorText}
      defaultOpen={removeModalIsOpen}
      confirmButtonLabel={t`Remove competition`}
      cancelButtonLabel={t`Cancel`}
      onClose={closeModal}
      onConfirm={() => removeCompetitionFromChannel()}
    />
  )
}
