import React, { FC, ReactNode } from 'react'
import styles from './ProsItem.module.scss'
import { Trans } from '@lingui/macro'
export interface ProsListItem {
  text: string
  icon: ReactNode
  id?: string
}

export const ProsItem: FC<ProsListItem> = ({ text, icon }) => {
  return (
    <div className={styles.ProsItemContainer}>
      <div className={styles.Icon}>{icon}</div>
      <div className={styles.Text}>
        <Trans id={text} />
      </div>
    </div>
  )
}
