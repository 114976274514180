import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { MenuItem } from '../menuItem/MenuItem'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { LinkType } from '../../../menuLink/MenuLink'
import { getIsSpecialDomain } from '../../../../../getCustomChannelUrl'
import { getChannel } from '../../../../../api/channel/getChannel'
import { ChannelTypes } from '../../../../../pages/ChannelPage/channelType'
import { showTopNavigationScheduleLink } from './showMenuItems'
import {
  CustomLink,
  getCustomTopNavigationLink
} from 'api/channel/getCustomTopNavigationLink'
import styles from './MenuItems.module.scss'
import { HomeMenuItem } from './HomeMenuItem'

interface MenuItemsProps {
  hasBackground: boolean
  themed: boolean
  isRootPageorChild: boolean
  rootChannelSubdomain?: string
  rootChannelType: ChannelTypes
}
export const MenuItems: FC<MenuItemsProps> = ({
  hasBackground,
  themed,
  isRootPageorChild,
  rootChannelSubdomain,
  rootChannelType
}) => {
  const [rootChannel, setRootChannel] = useState<any>()
  const location = useLocation()

  useEffect(() => {
    const getRootChannel = async (domain: string) => {
      try {
        const result = await getChannel(domain)
        setRootChannel(result)
      } catch (e) {
        console.log(e)
      }
    }
    const domain = window.location.hostname
    const isSpecialDomain = getIsSpecialDomain(domain)
    if (!isSpecialDomain) {
      getRootChannel(domain)
    }
  }, [])

  const {
    data,
    isLoading,
    isError
  }: {
    data: CustomLink | undefined | never
    isLoading: boolean
    isError: boolean
  } = useQuery({
    queryKey: ['get-custom-link-for-root-channel'],
    // @ts-ignore
    queryFn: () =>
      rootChannelSubdomain &&
      getCustomTopNavigationLink(rootChannelSubdomain, false),
    ...{ enabled: !!rootChannelSubdomain }
  })
  const customLink = data

  const returnToUrl = rootChannel && rootChannel.subdomain

  const themedRoot = rootChannelSubdomain || returnToUrl

  const isUserPage = location.pathname.includes('user')
  const displayHomeMenuItem = isUserPage || !isRootPageorChild

  const showCustomLink =
    !isLoading && !isError && customLink && customLink.link && customLink.text

  const showScheduleLink = showTopNavigationScheduleLink(rootChannelType)

  return (
    <div data-testid="menu-items" className={styles.MenuItemsContainer}>
      {displayHomeMenuItem && (
        <HomeMenuItem
          hasBackground={hasBackground}
          themed={themed}
          rootChannel={rootChannel}
          isUserPage={isUserPage}
        />
      )}
      {themed || returnToUrl ? (
        <>
          {showScheduleLink && (
            <MenuItem
              linkType={LinkType.Route}
              label={t`Schedule`}
              link={`/${themedRoot}/calendar`}
              dark={hasBackground}
              themed={themed}
            />
          )}
          {showCustomLink && customLink && (
            <MenuItem
              linkType={LinkType.AnchorTag}
              label={customLink.text}
              link={customLink.link}
              dark={hasBackground}
              themed={themed}
              linkTarget={customLink.target}
            />
          )}
        </>
      ) : (
        <MenuItem
          linkType={LinkType.Route}
          label={t`Schedule`}
          link="/calendar"
          dark={hasBackground}
          themed={themed}
        />
      )}
    </div>
  )
}
MenuItems.defaultProps = {
  rootChannelSubdomain: undefined
}
