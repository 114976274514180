import React from 'react'

export const FontSettingsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7086 17.3843L9.57407 17.8319L10.0216 14.6973L18.0827 6.63621C18.439 6.27989 18.9223 6.07971 19.4262 6.07971C19.9301 6.07971 20.4134 6.27989 20.7697 6.63621C21.126 6.99253 21.3262 7.47581 21.3262 7.97972C21.3262 8.48364 21.126 8.96691 20.7697 9.32323L12.7086 17.3843Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.12619 2.27972H12.4595V4.81306H6.12619V2.27972Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4595 3.54639H14.9928C15.3287 3.54639 15.6509 3.67984 15.8885 3.91738C16.126 4.15493 16.2595 4.47711 16.2595 4.81305"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2595 17.4797V20.0131C16.2595 20.349 16.1261 20.6712 15.8885 20.9087C15.651 21.1463 15.3288 21.2797 14.9928 21.2797H3.59284C3.2569 21.2797 2.93472 21.1463 2.69717 20.9087C2.45962 20.6712 2.32617 20.349 2.32617 20.0131V4.81305C2.32617 4.47711 2.45962 4.15493 2.69717 3.91738C2.93472 3.67984 3.2569 3.54639 3.59284 3.54639H6.12617"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.12619 8.61304H12.4595"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.12619 12.4131H8.65952"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
