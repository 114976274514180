import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'

import IconTextButton, {
  ButtonType
} from '../../../components/buttons/TextIconButton/index'
import HeartIcon from '../../../components/buttons/TextIconButton/GlobeHeartIcon'
import { FilledHeartIcon } from './Images/FilledHeartIcon'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'

import styles from './Header.module.scss'
import classNames from 'classnames'

interface Props {
  id: string
  followed: boolean
  onClick: (id: string) => void
  hasTheme: boolean
}

export const FollowButton: FC<Props> = ({
  id,
  followed,
  onClick,
  hasTheme
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const following = t`Following`
  const follow = t`Follow`
  const unfollow = t`Unfollow`

  const label = followed ? following : follow

  const [textToShow, setTextToShow] = useState(label)
  const size = currentBreakpoint > Breakpoint.sm ? 'Medium' : 'Small'
  const followClick = (channelId: string) => {
    onClick(channelId)
    switch (textToShow) {
      case follow:
        setTextToShow(following)
        break
      case unfollow:
        setTextToShow(follow)
        break
      case following:
        setTextToShow(follow)
        break
    }
  }

  const onMouseEnter = () => {
    if (label === following) setTextToShow(unfollow)
  }
  const onMouseLeave = () => {
    setTextToShow(label)
  }
  return (
    <div data-testid="channel-follow-button" className={styles.FollowButton}>
      <IconTextButton
        id={id}
        type={hasTheme ? ButtonType.ThemedInverted : ButtonType.Ghost}
        buttonSize={size}
        label={textToShow}
        onClick={() => followClick(id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {followed ? (
          <div
            className={classNames({
              [styles.FilledHeartIcon]: !hasTheme,
              [styles.ThemedFilledHeart]: hasTheme,
              [styles.ThemedFilledHeartOnHover]:
                textToShow === unfollow && hasTheme
            })}
          >
            <FilledHeartIcon />
          </div>
        ) : (
          <HeartIcon />
        )}
      </IconTextButton>
    </div>
  )
}
