import React, { FC } from 'react'

import { Trans } from '@lingui/macro'
import VideoCameraIcon from 'assets/icons/VideoCameraIcon'

import styles from './ProducedBy.module.scss'
interface Props {
  producer?: string
}

export const ProducedBy: FC<Props> = ({ producer }) =>
  producer ? (
    <div className={styles.ProducedByContainer}>
      <div className={styles.VideoCameraIcon}>
        <VideoCameraIcon />
      </div>
      <div className={styles.Text}>
        <Trans>Produced by</Trans>
      </div>
      <div className={styles.Producer}>{producer}</div>
    </div>
  ) : null
