import React, { FC, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik } from 'formik'
import * as yup from 'yup'
import { t, Trans } from '@lingui/macro'

import { useDataApi } from '../../../../api/useDataApi'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import {
  ChannelOverviewSettings,
  getChannelSettings
} from '../../../../api/channel/settings/getChannelSettings'
import { Loader } from '../../../../components/Loader/Loader'
import {
  StatusMessage,
  StatusMessageType
} from '../StatusMessage/StatusMessage'
import { OverviewFormFields } from './OverviewFormFields'
import { patchAdminDetails } from 'api/channel/settings/patchAdminDetails'

interface Props {
  onSubmitSuccess: () => void
  channelSlug: string
}

export const OverviewForm: FC<Props> = ({ onSubmitSuccess, channelSlug }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { data, isLoading, isError } = useDataApi({
    apiCall: getChannelSettings,
    initialData: {},
    parameters: channelSlug
  })

  if ((!data || Object.keys(data).length === 0) && !isLoading) return null

  if (isLoading) return <Loader />

  const channelAccountSchema = yup.object({
    title: yup.string().required(t`Please enter the title of your channel`),
    timezone: yup
      .string()
      .required(t`Please select the timezone your channel broadcasts in`)
  })

  const initialValues: ChannelOverviewSettings = {
    url: data.overview.url || '',
    title: data.overview.title || '',
    timezone: data.overview.timezone || ''
  }

  const onSubmit = async (
    values: ChannelOverviewSettings,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const adminSettingValues = { ...values, channelSlug }
    const response = await patchAdminDetails({
      channelSlug: adminSettingValues.channelSlug,
      channelTimeZone: adminSettingValues.timezone,
      channelTitle: adminSettingValues.title,
      channelUrl: adminSettingValues.url
    })
    if (response.hasError) {
      // @ts-ignore
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
      onSubmitSuccess()
    }
  }

  return (
    <div>
      {isError && (
        <div>
          <Trans>
            There was a problem retriving your settings data. Please try again
          </Trans>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={channelAccountSchema}
        validateOnBlur
        validateOnChange
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => (
          <Form>
            <OverviewFormFields formik={props} />
            <SubmitButton
              type="secondary"
              disabled={!props.dirty || props.isSubmitting}
              label={t`Save changes`}
            />
            {showSuccessMessage && (
              <StatusMessage
                onClose={() => setShowSuccessMessage(false)}
                message={t`your channel overview settings have been successfully updated - there may be a delay before some of the changes take effect`}
                messageType={StatusMessageType.Success}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
