import React from 'react'

const SearchIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.84546 14.9998C7.52435 16.5972 8.81 17.8595 10.4196 18.509C12.0292 19.1585 13.8309 19.142 15.4283 18.4631C17.0257 17.7842 18.288 16.4986 18.9375 14.889C19.587 13.2794 19.5705 11.4777 18.8916 9.88026C18.2127 8.28283 16.9271 7.02052 15.3175 6.37102C13.7079 5.72152 11.9062 5.73803 10.3088 6.41692C8.71137 7.09581 7.44906 8.38146 6.79956 9.99106C6.15006 11.6007 6.16657 13.4023 6.84546 14.9998V14.9998Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4957 17.0672L22.5714 22.1436"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SearchIcon
