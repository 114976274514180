import { t } from '@lingui/macro'
import { formatCurrency, formatUnixDate } from 'helpers/formatHelper'
import { Subscription } from 'interfaces'
import { getPeriod } from './getPeriod'

export type BulletPoint = {
  key: string
  content: string
}
export const getBulletPointList = (
  subscription: Subscription,
  currentPayment: any
) => {
  const bulletPointList: BulletPoint[] = []
  if (subscription?.expires_at !== undefined) {
    if (subscription?.free_days > 0) {
      bulletPointList.push({
        key: 'freeDays',
        content: t`Try free for ${
          subscription.free_days
        } days. Only ${formatCurrency(
          currentPayment.value,
          currentPayment.currency
        )}/${getPeriod(subscription.timeframe)} afterwards.`
      })
    }
    if (!subscription.all_access && !subscription?.is_gift_card) {
      bulletPointList.push({
        key: 'noObligation',
        content: t`No obligation, cancel anytime`
      })
    }
    if (subscription?.expires_at !== undefined && subscription.expires_at > 0) {
      bulletPointList.push({
        key: 'UnlimitedStreamingUntill',
        content: t`Unlimited streaming until ${formatUnixDate(
          subscription.expires_at
        )}`
      })
    } else {
      bulletPointList.push({ key: t``, content: t`Unlimited streaming` })
    }
  }
  return bulletPointList
}
