import React from 'react'

export const TicketIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 58 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.15 21.8333C1.15 19.7991 2.79908 18.15 4.83334 18.15H53.1667C55.2009 18.15 56.85 19.7991 56.85 21.8333V22.406C56.85 23.4242 56.4286 24.3969 55.6859 25.0932L54.8553 25.8719C51.3154 29.1905 51.3154 34.8095 54.8553 38.1281L55.6859 38.9068C56.4286 39.6032 56.85 40.5759 56.85 41.594V42.1667C56.85 44.2009 55.2009 45.85 53.1667 45.85H4.83334C2.79909 45.85 1.15 44.2009 1.15 42.1667V41.594C1.15 40.5759 1.5714 39.6032 2.31415 38.9068L1.52762 38.0679L2.31415 38.9068L3.14478 38.1281C6.68464 34.8095 6.68464 29.1905 3.14478 25.8719L2.35825 26.7109L3.14478 25.8719L2.31415 25.0932C1.5714 24.3969 1.15 23.4242 1.15 22.406V21.8333Z"
      stroke="#2F3135"
      strokeWidth="2.29999"
    />
    <path
      d="M51.6185 9.13778L50.6251 8.55853L50.6251 8.55853L51.6185 9.13778ZM50.5546 10.9625L51.5481 11.5417L50.5546 10.9625ZM4.79494 15.8947L46.594 3.432L45.9369 1.22789L4.13776 13.6906L4.79494 15.8947ZM50.6251 8.55853L49.5612 10.3832L51.5481 11.5417L52.612 9.71704L50.6251 8.55853ZM50.9562 5.7904C51.2332 6.71922 51.1133 7.72124 50.6251 8.55853L52.612 9.71704C53.4204 8.33055 53.6189 6.67128 53.1603 5.13323L50.9562 5.7904ZM49.5612 10.3832C47.68 13.6095 48.813 17.7516 52.0744 19.5712L53.195 17.5627C51.0578 16.3703 50.3153 13.656 51.5481 11.5417L49.5612 10.3832ZM46.594 3.432C48.4499 2.87867 50.4029 3.93456 50.9562 5.7904L53.1603 5.13323C52.2441 2.06009 49.01 0.31161 45.9369 1.22789L46.594 3.432ZM4.13776 13.6906C1.76302 14.3986 0.4119 16.8977 1.11995 19.2725L3.32406 18.6153C2.97895 17.4579 3.63749 16.2398 4.79494 15.8947L4.13776 13.6906Z"
      fill="#2F3135"
    />
    <path
      d="M40 23V41"
      stroke="#2F3135"
      strokeWidth="1.91666"
      strokeLinecap="round"
      strokeDasharray="4.83 4.83"
    />
    <path
      d="M37.6697 9.30664L39.9993 19"
      stroke="#2F3135"
      strokeWidth="1.91666"
      strokeLinecap="round"
      strokeDasharray="4.83 4.83"
    />
    <path
      d="M18 23V41"
      stroke="#2F3135"
      strokeWidth="1.91666"
      strokeLinecap="round"
      strokeDasharray="4.83 4.83"
    />
  </svg>
)
