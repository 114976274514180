import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './TabListContainer.module.scss'

interface Props {
  id: string
  slug: string
  text: string
  onTabSelected: (selected: string) => void
  isSelected: boolean
}

export const TabHeader: FC<Props> = ({
  id,
  text,
  onTabSelected,
  isSelected
}) => {
  return (
    <div
      role="button"
      onClick={() => onTabSelected(id)}
      className={classNames(styles.TabHeader, {
        [styles.Selected]: isSelected
      })}
    >
      {text}
    </div>
  )
}
