import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface MessageContentProps {
  text: string
  file: string
  mediaObjectIdent: string
}
export const postChatMessage = async (messageContent: MessageContentProps) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `chat/${messageContent.mediaObjectIdent}/message`,
      method: ApiEndpoint.METHODS.POST,
      params: {
        content: messageContent.text
      },
      file: messageContent.file,
      authenticatable: true
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    return error
  }
}
