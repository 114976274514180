import React, { FC } from 'react'

import { ChannelTypes } from '../channelType'
import { GameSchedule } from './GameSchedule'
import { MultistageTournaments } from './MultistageTournaments'
import { BackgroundTheme, BannerAd } from 'advertising/BannerAd/BannerAd'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { getChannelAdTargeting } from '../Start/getChannelAdTargeting'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

interface Props {
  showMultistageTournaments: boolean
  isScheduleAdmin: boolean
  channelSlug: string
  channelType: ChannelTypes
}

export const ChannelGamesRoute: FC<Props> = ({
  showMultistageTournaments,
  isScheduleAdmin,
  channelSlug,
  channelType
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const channel = useSelector(channelSelector)

  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channel.data)
  const targetingOptions = {
    partnerDomain: partnerDomain,
    parentChannelDomain: parentChannelDomain,
    sportCategory: sportCategory,
    countryCode: countryCode
  }
  const isMobile = currentBreakpoint <= Breakpoint.sm
  return (
    <>
      <BannerAd
        adId={'div-gpt-ad-1721742764219-0'}
        slotPath={'/21792873324/game_schedule_banner'}
        multiSize={
          isMobile
            ? [
                [320, 100],
                [320, 240],
                [320, 320],
                [320, 50]
              ]
            : [
                [970, 90],
                [980, 240],
                [728, 90],
                [970, 250],
                [980, 90],
                [980, 120]
              ]
        }
        targetingOptions={targetingOptions}
        backgroundTheme={BackgroundTheme.Grey}
      />
      {showMultistageTournaments ? (
        <MultistageTournaments
          channelSlug={channelSlug}
          isScheduleAdmin={isScheduleAdmin}
          channelType={channelType}
        />
      ) : (
        <GameSchedule
          channelSlug={channelSlug}
          isScheduleAdmin={isScheduleAdmin}
          channelType={channelType}
        />
      )}
    </>
  )
}
