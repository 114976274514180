export enum IntegrationName {
  Profixio = 'profixio',
  Default = ''
}
export const getLogoForIntegration = (integration: IntegrationName) => {
  switch (integration) {
    case IntegrationName.Profixio:
      return 'http://www.solidsport.com/wp-content/uploads/2024/03/ProfixioLogo-300x77-1.png'
    default:
      return ''
  }
}
