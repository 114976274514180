import { t } from '@lingui/macro'
import { SubscriptionType } from '../../SubscriptionType'

export interface BulletPointContent {
  id: number
  text: string
}
const clubBulletPoints = (clubChannelName: string) => [
  {
    id: 1,
    text: t`Access to all games from the teams associated with the club channel`
  },
  {
    id: 2,
    text: t`Access to all content from the teams in the club`
  },
  {
    id: 3,
    text: t`Access to all content from ${clubChannelName}`
  },
  {
    id: 4,
    text: t`Support your club financially`
  }
]
const teamBulletPoints = (teamChannelName: string) => [
  { id: 1, text: t`Access to all games on the team channel` },
  {
    id: 2,
    text: t`Access to all content from ${teamChannelName}`
  },
  {
    id: 3,
    text: t`Support your team financially`
  }
]
const leagueBulletPoints = (teamChannelName: string) => [
  { id: 1, text: t`Access to all games in the league` },
  {
    id: 2,
    text: t`Access to all content from ${teamChannelName}`
  }
]
export const getPreDefinedBulletPoints = (
  subscriptionType: SubscriptionType,
  subscriptionChannelName: string
) => {
  switch (subscriptionType) {
    case SubscriptionType.Club:
      return clubBulletPoints(subscriptionChannelName)
    case SubscriptionType.Team:
      return teamBulletPoints(subscriptionChannelName)
    case SubscriptionType.League:
      return leagueBulletPoints(subscriptionChannelName)
    default:
      return []
  }
}
