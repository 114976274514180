import { t } from '@lingui/macro'
import { ChannelTypes } from 'pages/ChannelPage/channelType'

export const getTranslatedChannelType = (channelType: ChannelTypes) => {
  switch (channelType) {
    case ChannelTypes.association:
      return t`association`
    case ChannelTypes.district:
      return t`district`
    case ChannelTypes.event:
      return t`event`
    case ChannelTypes.club:
      return t`club`
    case ChannelTypes.league:
      return t`league`
    case ChannelTypes.team:
      return t`team`
    default:
      return ''
  }
}
