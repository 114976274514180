import React, { FC, useEffect, useState } from 'react'

import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { MobileSearch } from './MobileSearch/MobileSearch'
import { ColorType, SearchField } from 'components/Searchfield/SearchField'
import { useHistory } from 'react-router-dom'
import styles from './Search.module.scss'
import { t } from '@lingui/macro'

interface Props {
  hasBackground: boolean
  backgroundIsTransparentAtStart: boolean
  rootChannelSlug: string
  isRootOrChildChannel: boolean
}
export const Search: FC<Props> = ({
  backgroundIsTransparentAtStart,
  hasBackground,
  rootChannelSlug,
  isRootOrChildChannel
}) => {
  const [searchFieldColor, setSearchFieldColor] = useState(ColorType.Light)
  const history = useHistory()
  const breakPoint = useCurrentBreakpoint()
  const isDesktop = breakPoint > Breakpoint.sm
  const onSearch = (result: string) => {
    if (isRootOrChildChannel) {
      history.push(
        `/${[rootChannelSlug, '/search']
          .filter(Boolean)
          .join('')}?query=${result}`
      )
    } else {
      history.push(`/search/?query=${result}`)
    }
  }
  const searchPlaceholder = isRootOrChildChannel
    ? t`Search`
    : t`Search within Solidsport`
  useEffect(() => {
    if (backgroundIsTransparentAtStart) {
      setSearchFieldColor(ColorType.Dark)
    }
    if (hasBackground) {
      setSearchFieldColor(ColorType.Light)
    }
  }, [hasBackground, backgroundIsTransparentAtStart])

  return isDesktop ? (
    <div className={styles.SearchBar}>
      <SearchField
        type={searchFieldColor}
        onSearch={(searchInput: string) => onSearch(searchInput)}
        showBackArrow={false}
        onBackArrowClick={() => null}
        placeholder={searchPlaceholder}
      />
    </div>
  ) : (
    <MobileSearch
      onSearch={(searchInput: string) => onSearch(searchInput)}
      placeholder={searchPlaceholder}
    />
  )
}
