import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { useScrollToTop } from '../../../helpers/useScrollToTop'

import ContentContainer from '../../../components/ContentContainer'
import { GameSchedules } from '../joinLeagueAsTeam/GameSchedules'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { SelectedTeamHeader } from './SelectedTeamHeader'
import styles from './Competitions.module.scss'
import {
  ContentManagerUrlParams,
  getChannelSlugToUseForCompetitions
} from './getChannelSlugToUseForCompetitions'

export const Competitions = () => {
  useScrollToTop()

  const channel = useSelector(channelSelector)
  const channelType = channel.data?.type
  const channelSlug = channel.data?.subdomain
  const channelName = channel.data?.name
  const urlParams: ContentManagerUrlParams = useParams()

  const channelSlugToUse = getChannelSlugToUseForCompetitions(
    channelSlug,
    urlParams,
    channelType
  )
  const isClubChannel = channelType === ChannelTypes.club
  return (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding={isClubChannel}
      hasLightBackground={isClubChannel}
    >
      <div className={styles.CompetitionsContainer}>
        {urlParams.selectedTeam && (
          <SelectedTeamHeader
            channelSlug={channelSlug}
            teamChannelSlug={urlParams.selectedTeam}
          />
        )}
        <div
          className={classNames({
            [styles.GameScheduleContainer]: isClubChannel
          })}
        >
          <GameSchedules
            channelSlug={channelSlug}
            selectedTeamChannelSlug={channelSlugToUse}
            channelType={channelType}
            channelName={channelName}
          />
        </div>
      </div>
    </ContentContainer>
  )
}
