import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ChannelListItem } from './ChannelListItem'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import styles from './ChannelListItem.module.scss'

interface Props {
  channelSubdomain: string
  channelName: string
  channelLogo: string
  sportLabel: string
  rootDomain?: string
  channelType?: ChannelTypes
  showBorder?: boolean
}

export const ChannelListItemLink: FC<Props> = ({
  channelSubdomain,
  channelName,
  channelLogo,
  sportLabel,
  rootDomain,
  channelType,
  showBorder = false
}) => {
  if (rootDomain) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        target="_blank"
        href={`https://${rootDomain}/${channelSubdomain}`}
        rel="noopener noreferrer"
        data-testid="channel-item-link"
        className={styles.LinkContainer}
      >
        <ChannelListItem
          logo={channelLogo}
          name={channelName}
          sportLabel={sportLabel}
          rootDomain={rootDomain}
          channelType={channelType}
          showBorder={showBorder}
        />
      </a>
    )
  }
  return (
    <Link
      to={`/${channelSubdomain}`}
      data-testid="channel-item-link"
      className={styles.LinkContainer}
    >
      <ChannelListItem
        logo={channelLogo}
        name={channelName}
        sportLabel={sportLabel}
        channelType={channelType}
        showBorder={showBorder}
      />
    </Link>
  )
}
