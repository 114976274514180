import React, { useCallback, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import styles from './PaymentContainerCloseButton.module.scss'
import Button, { ButtonType } from 'components/buttons/button/Button'

type ButtonProps = {
  closePaymentContainer: any
}
export const PaymentContainerCloseButton: React.FC<ButtonProps> = ({
  closePaymentContainer
}) => {
  const currentPayment = useSelector(
    (state: any) => state.payments.currentPayment
  )
  const paymentFailed = useSelector(
    (state: any) => state.payments.paymentFailed
  )
  const unlockedId = useSelector((state: any) => state.payments.unlockedId)
  const shouldBeVisible = useCallback(() => {
    return (
      currentPayment &&
      (unlockedId === currentPayment.mediaObjectId ||
        unlockedId === currentPayment.subscription?.id)
    )
  }, [currentPayment, unlockedId])
  const [show, setShow] = useState(shouldBeVisible())
  const [showErrorState, setShowErrorState] = useState(paymentFailed)

  useEffect(() => {
    setShow(shouldBeVisible())
  }, [currentPayment, unlockedId, shouldBeVisible])

  useEffect(() => {
    setShowErrorState(paymentFailed)
  }, [paymentFailed])

  if (show || showErrorState) {
    return (
      <Button
        customStyle={styles.Button}
        label={t`Close`}
        onClick={() => closePaymentContainer()}
        type={ButtonType.GhostWithBoarder}
        buttonSize="Medium"
      />
    )
  }

  return null
}
