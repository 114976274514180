import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { TicketsIcon } from './TicketsIcon'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../hooks/CurrentBreakpoint'

import styles from '../Banner.module.scss'

interface Props {
  isSupporter: boolean
}
export const EventPass: FC<Props> = ({ isSupporter }) => {
  const breakPoint = useCurrentBreakpoint()
  const title = isSupporter
    ? t`Thanks for supporting us!`
    : t`Get your pass here`

  const longDescription = isSupporter
    ? t`You are our best supporter 👊🏻`
    : t`We have a pass which gives access to more than one livestream - click here to read more and buy your pass`

  const shortDescription = isSupporter
    ? t`You are our best supporter 👊🏻`
    : t`Get full access to our streams!`

  const description = !(breakPoint <= Breakpoint.mobile)
    ? longDescription
    : shortDescription
  return (
    <div>
      <div className={styles.TopContainer}>
        <div className={styles.Icon}>
          <TicketsIcon />
        </div>
        <div className={styles.TitleAndPriceText}>
          <div className={styles.Title}>{title}</div>
        </div>
      </div>
      <div className={styles.Description}>{description}</div>
    </div>
  )
}
