/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'

import { t } from '@lingui/macro'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import { DomainRegistrationStatus } from '../DomainStatus/DomainRegistrationStatus'
import styles from './DomainActions.module.scss'

interface DomainActionsProps {
  domainStatus: DomainRegistrationStatus
  onCheckDomain: () => void
  onCancelUpdate: () => void
}

export const DomainActions: FC<DomainActionsProps> = ({
  domainStatus,
  onCheckDomain,
  onCancelUpdate
}) => {
  switch (domainStatus) {
    case DomainRegistrationStatus.NotSet:
    case DomainRegistrationStatus.ChangingDomain:
      return (
        <div className={styles.UpdateButtons}>
          <Button
            buttonSize="Medium"
            type={ButtonType.Secondary}
            customStyle={styles.DomainActionsButton}
            roleType="submit"
            label={
              domainStatus === DomainRegistrationStatus.NotSet
                ? t`Add`
                : t`Confirm`
            }
          />
          {domainStatus !== DomainRegistrationStatus.NotSet && (
            <Button
              buttonSize="Medium"
              type={ButtonType.GhostWithBoarder}
              customStyle={styles.DomainActionsButton}
              label="Cancel"
              onClick={onCancelUpdate}
            />
          )}
        </div>
      )

    case DomainRegistrationStatus.WaitingForPartnerConfiguration:
    case DomainRegistrationStatus.WaitingForSslGeneration:
    case DomainRegistrationStatus.ValidatingDomain:
      return (
        <Button
          buttonSize="Medium"
          type={ButtonType.GhostWithBoarder}
          label="Update Status"
          onClick={onCheckDomain}
        />
      )
    default:
      return null
  }
}
