/* eslint-disable no-case-declarations */
/* eslint-disable no-undef */
import USER from './user.constants'
import { loadStateFromStorage } from '../../helpers/localStorageHelper'
import { Company } from '../../interfaces'

export interface User {
  user: any
}

// #TODO: Validate user from localStorage. Also check company and user
// when we send and error report and rescue from there.
export const createInitialState = () => {
  return loadStateFromStorage('user') || null
}

const initialState = createInitialState()

function user(state = initialState, action: any) {
  switch (action.type) {
    case USER.COMPANY_FOLLOW_ADD: {
      if (state.followed_companies?.indexOf(action.company.subdomain) > -1) {
        // Do not follow a team that is already followed
        return state
      }

      // eslint-disable-next-line no-case-declarations
      const newChannel = {
        follows_company: true,
        subdomain: action.company?.subdomain,
        is_monthly_supporter: false,
        image: undefined,
        name: action.company.name
      }
      const followedCompanies = state.followed_companies || []
      return {
        ...state,
        followed_companies: [...followedCompanies, action.company.subdomain],
        followed_companies_expanded: [
          ...state.followed_companies_expanded,
          newChannel
        ]
      }
    }
    case USER.COMPANY_FOLLOW_REMOVE: {
      const companyIndex: number = state.followed_companies?.indexOf(
        action.company
      )
      const extendedCompanyIndex: number = state.followed_companies_expanded
        ?.map((company: Company) => company.subdomain)
        ?.indexOf(action.company)

      if (
        companyIndex === -1 ||
        extendedCompanyIndex === -1 ||
        !state.followed_companies?.length ||
        !state.followed_companies_expanded?.length
      ) {
        return state
      }

      const newCompaniesList = state.followed_companies
        .slice(0, companyIndex)
        .concat(state.followed_companies.slice(companyIndex + 1))

      const newExtendedCompaniesList = state.followed_companies_expanded
        .slice(0, extendedCompanyIndex)
        .concat(
          state.followed_companies_expanded.slice(extendedCompanyIndex + 1)
        )

      return {
        ...state,
        followed_companies: newCompaniesList,
        followed_companies_expanded: newExtendedCompaniesList
      }
    }
    case USER.TEAM_FOLLOW_ADD:
      if (state.followed_teams?.some((team: any) => team === action.team)) {
        return state
      }
      if (!state.followed_teams?.length) {
        return {
          ...state,
          followed_teams: [action.team]
        }
      }
      return {
        ...state,
        followed_teams: [...state.followed_teams, action.team]
      }

    case USER.TEAM_FOLLOW_REMOVE:
      if (!state?.followed_teams?.length) {
        return state
      }
      return {
        ...state,
        followed_teams: state.followed_teams.filter(
          (team: any) => team !== action.team
        )
      }
    case USER.USER_ADDED:
      return {
        ...action.user
      }
    case USER.USER_REMOVED:
      // Remove the user
      return null
    case USER.UPDATE_USER:
      return {
        ...state,
        updatingUser: true
      }
    case USER.USER_UPDATED_FROM_SERVER:
      if (JSON.stringify(state) !== JSON.stringify(action.user)) {
        return {
          ...action.user,
          updatingUser: false
        }
      }
      return state
    case USER.UPDATE_USER_FAILED:
      return {
        ...state,
        updatingUser: false
      }
    case USER.SET_LANGUAGE:
      return {
        ...state,
        locale: action.language
      }
    case USER.RESET_FROM_LOCAL_STATE:
      return {
        ...createInitialState()
      }
    default:
      return state
  }
}

export default user
