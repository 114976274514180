import React, { FC, useCallback, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { Link, useHistory } from 'react-router-dom'
import { useDataApi } from '../../../api/useDataApi'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

import { GameScheduleListContainer } from '../GameScheduleListContainer/GameScheduleListContainer'
import {
  useStatusMessageDispatchContext,
  useStatusMessageContext
} from '../../../pages/ChannelPage/ContentManager/StatusMessageContext'
import {
  StatusMessage,
  StatusMessageType
} from '../../../pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'
import {
  GameSchedule,
  getGameScheduleList
} from '../../../api/sportData/getGameScheduleList'
import TagManager from 'react-gtm-module'
import styles from './GameSchedules.module.scss'
interface Props {
  channelSlug: string
  selectedTeamChannelSlug: string
  channelType: ChannelTypes
  channelName: string
}
export const GameSchedules: FC<Props> = ({
  channelSlug,
  selectedTeamChannelSlug,
  channelType,
  channelName
}) => {
  const history = useHistory()

  const [refreshComponent, setRefreshComponent] = useState(false)
  const {
    data: upcomingData,
    isError: upcomingIsError,
    isLoading: upcomingIsLoading
  } = useDataApi({
    apiCall: useCallback(getGameScheduleList, []),
    parameters: useMemo(
      () => ({
        request: {
          channelSlug: selectedTeamChannelSlug,
          page: 1,
          perPageCount: 10
        },
        pastGames: false,
        refreshComponent
      }),
      [selectedTeamChannelSlug, refreshComponent]
    )
  })

  const {
    data: pastData,
    isError: pastIsError,
    isLoading: pastIsLoading
  } = useDataApi({
    apiCall: useCallback(getGameScheduleList, []),
    parameters: useMemo(
      () => ({
        request: {
          channelSlug: selectedTeamChannelSlug,
          page: 1,
          perPageCount: 10
        },
        pastGames: true
      }),
      [selectedTeamChannelSlug]
    )
  })

  const upcomingGameSchedules: GameSchedule[] = upcomingData
  const dispatch = useStatusMessageDispatchContext()
  const messageState = useStatusMessageContext()

  const closeRemoveStatus = () => {
    if (channelType === ChannelTypes.league) {
      dispatch({ type: 'toggle_show_competition_removed' })
    } else {
      dispatch({ type: 'hide_remove_team_from_competition' })
    }
    setRefreshComponent(false)
  }

  const closeAddStatus = () => {
    if (channelType === ChannelTypes.league) {
      dispatch({ type: 'toggle_show_competition_added' })
    } else {
      dispatch({ type: 'hide_competition_added' })
    }
    setRefreshComponent(false)
  }

  if (
    messageState.showRemoveTeamFromCompetitionStatus ||
    messageState.showRemoveLeagueStatus ||
    messageState.showAddLeagueStatus ||
    messageState.showAddCompetitionSuccess
  ) {
    if (!refreshComponent) {
      setRefreshComponent(!refreshComponent)
    }
  }

  const upcomingSubheading =
    upcomingGameSchedules && upcomingGameSchedules.length <= 0 ? (
      <span>{t`No ongoing or upcoming competitions on this channel right now. Add a competition to this channel to display all your games.`}</span>
    ) : (
      <>
        <span>
          {t`You are now ready to start adding the games that you are going to stream. It is easy to do from the`}
          &nbsp;
        </span>
        <Link to={`/${channelSlug}/games`}>{t`game schedule`}</Link>
      </>
    )

  const upcomingSubheadingLeague =
    upcomingGameSchedules && upcomingGameSchedules.length <= 0 ? (
      <span>{t`No ongoing or upcoming competitions on this channel right now. Add a competition to this channel to display all the games here.`}</span>
    ) : (
      <span>{t`Here you can see the ongoing or upcoming competitions for this channel`}</span>
    )
  const subHeadingToDisplay = [ChannelTypes.league].includes(channelType)
    ? upcomingSubheadingLeague
    : upcomingSubheading

  const pastGameSchedules: GameSchedule[] = pastData

  const triggerGTMEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_add_games_to_compettion_clicked',
        channel_slug: channelSlug,
        channel_name: channelName,
        selected_team_slug: selectedTeamChannelSlug,
        channel_type: channelType
      }
    })
  }
  const handleOnAddSchedule = () => {
    triggerGTMEvent()
    switch (channelType) {
      case ChannelTypes.club:
        history.push(
          `/${channelSlug}/contentmanager/teams/${selectedTeamChannelSlug}/competitions/select`
        )
        break
      default:
        history.push(`/${channelSlug}/contentmanager/competitions/select`)
        break
    }
  }
  return (
    <div className={styles.GameSchedulesContainer}>
      {upcomingGameSchedules && (
        <GameScheduleListContainer
          listData={upcomingGameSchedules}
          heading={t`Ongoing/upcoming competitions`}
          subheading={subHeadingToDisplay}
          hasError={upcomingIsError}
          errorMessage={t`Something went wrong when trying to get the upcoming/ongoing competitions, please try again.`}
          isLoading={upcomingIsLoading}
          onAddSchedule={handleOnAddSchedule}
          showAddButton
          showRemoveButton
        />
      )}
      {pastGameSchedules && pastGameSchedules.length > 0 && (
        <GameScheduleListContainer
          listData={pastGameSchedules}
          heading={t`Past competitions`}
          hasError={pastIsError}
          errorMessage={t`Something went wrong when trying to get the past competitions, please try again.`}
          isLoading={pastIsLoading}
          onAddSchedule={() => null}
          showAddButton={false}
          showRemoveButton={false}
        />
      )}
      {(messageState.showAddCompetitionSuccess ||
        messageState.showAddLeagueStatus) && (
        <StatusMessage
          onClose={closeAddStatus}
          message={t`1 new competition was successfully added to this channel
              `}
          messageType={StatusMessageType.Success}
        />
      )}
      {(messageState.showRemoveTeamFromCompetitionStatus ||
        messageState.showRemoveLeagueStatus) && (
        <StatusMessage
          onClose={closeRemoveStatus}
          message={
            messageState.showRemoveLeagueStatus
              ? t`you have successfully removed the competition from the channel`
              : t`you have successfully removed your team from the competition`
          }
          messageType={StatusMessageType.Success}
        />
      )}
    </div>
  )
}
