import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

export interface ContentManagerUrlParams {
  company: string
  selectedTeam: string
}

export const getChannelSlugToUseForCompetitions = (
  channelSlug: string,
  urlParams: ContentManagerUrlParams,
  channelType: ChannelTypes
) => {
  switch (channelType) {
    case ChannelTypes.club:
      return urlParams.selectedTeam
    case ChannelTypes.league:
    case ChannelTypes.team:
    default:
      return channelSlug
  }
}
