import { useState, useEffect } from 'react'

interface Props {
  apiCall: (parameters?: any) => any
  parameters?: any
  initialData?: any
  triggerCall?: boolean
}
export interface ReturnValues {
  data: any
  isLoading: boolean
  isError: boolean
}
export const useDataApi = ({
  apiCall,
  initialData = [],
  parameters,
  triggerCall
}: Props) => {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const result: any = await apiCall(parameters)
        if (result?.statusCode === 500) {
          setIsError(true)
        } else {
          setData(result)
        }
      } catch (error) {
        setIsError(true)
      }
      setIsLoading(false)
    }
    fetchData()
    return () => {
      setData(null)
    }
  }, [parameters, apiCall, triggerCall])

  const returnValues: ReturnValues = {
    data,
    isLoading,
    isError
  }
  return returnValues
}
