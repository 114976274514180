import React, { FC } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import PageLink, { LinkType } from '../../../menuLink/MenuLink'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import styles from './MenuItem.module.scss'

interface MenuItemProps {
  label: string
  dark: boolean
  linkType: LinkType
  link: string
  themed: boolean
  linkTarget?: LinkTarget
}
export const MenuItem: FC<MenuItemProps> = ({
  label,
  dark,
  link,
  linkType,
  themed,
  linkTarget
}) => {
  const location = useLocation()
  const isActive = location.pathname === link

  const className = classNames(styles.MenuItem, {
    [styles.DarkBackground]: dark && !themed,
    [styles.LightBackground]: !dark && !themed,
    [styles.Active]: isActive,
    [styles.ThemedBackground]: themed
  })

  const dataTestId = `menu-link-${label.toLowerCase().replace(' ', '-')}`
  return (
    <div className={className} data-testid={dataTestId}>
      <PageLink
        type={linkType}
        link={link}
        translationText={label}
        linkTarget={linkTarget}
      />
    </div>
  )
}
