import React, { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { t } from '@lingui/macro'

import { getPreviousGames } from 'api/multistageTournament/getPreviousGames'
import { getUpcomingGames } from 'api/multistageTournament/getUpcomingGames'
import { getCurrentGames } from 'api/multistageTournament/getCurrentGames'
import { useDataApi } from 'api/useDataApi'
import { EventCardList } from '../EventCardList/EventCardList'
import { Loader } from 'components/Loader/Loader'
import { TimeFilter, TimeFilterType } from '../TimeFilter/TimeFilter'
import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import { ErrorMessage } from 'features/multistageTournaments/ErrorMessage'

import styles from './FilteredGamesList.module.scss'
import { DateSelectorButtons } from '../DateSelectorButtons/DateSelectorButtons'

export enum FilteredGamesListType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}
interface Props {
  type: FilteredGamesListType
  channelSlug: string
  eventSlug: string
  now: number
  onCalendarIconClick: () => void
  isHistoricData: boolean
}

const PER_PAGE_COUNT = 25

export const FilteredGamesList: FC<Props> = ({
  type,
  channelSlug,
  eventSlug,
  now,
  onCalendarIconClick,
  isHistoricData
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataToDisplay, setDataToDisplay] = useState<
    GameScheduleEventCardType[]
  >([])

  const [isSelectingFilter, setIsSelectingFilter] = useState(false)
  const [currentEventSlug, setCurrentEventSlug] = useState(eventSlug)
  const [pageCount, setPageCount] = useState<number>(1)
  const [numberOfEmptyFilters, setNumberOfEmptyFilters] = useState(0)

  const [selectedTimeFilter, setSelectedTimeFilter] = useState(
    TimeFilterType.Live
  )
  const { data, isLoading, isError } = useDataApi({
    apiCall: useMemo(() => {
      switch (selectedTimeFilter) {
        case TimeFilterType.Live:
          return getCurrentGames
        case TimeFilterType.Previous:
          return getPreviousGames
        case TimeFilterType.Upcoming:
          return getUpcomingGames
      }
    }, [selectedTimeFilter]),
    parameters: useMemo(
      () => ({
        eventSlug,
        channelSlug,
        currentPage: currentPage,
        perPage: PER_PAGE_COUNT
      }),
      [channelSlug, currentPage, eventSlug]
    )
  })
  useEffect(() => {
    setNumberOfEmptyFilters(0)
    setIsSelectingFilter(false)
  }, [eventSlug])

  const newValue = {
    live: TimeFilterType.Upcoming,
    upcoming: TimeFilterType.Previous,
    previous: TimeFilterType.Live
  }

  useEffect(() => {
    if (!isSelectingFilter) {
      if (!isLoading && !isError && data.data) {
        if (data.data.length < 1 && numberOfEmptyFilters < 3) {
          setNumberOfEmptyFilters(numberOfEmptyFilters + 1)
          setSelectedTimeFilter(newValue[selectedTimeFilter])
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      if (currentEventSlug !== eventSlug) {
        setCurrentEventSlug(eventSlug)
        setDataToDisplay(data.data)
      } else {
        setDataToDisplay((prev) => [...prev, ...data.data])
      }
      setPageCount(data.totalCount / PER_PAGE_COUNT)
    }
  }, [isLoading, isError, data])

  const handleSelectTime = (filter: TimeFilterType) => {
    setDataToDisplay([])
    setIsSelectingFilter(true)
    setSelectedTimeFilter(filter)
  }

  if (isError)
    return (
      <ErrorMessage
        message={t`There was a problem fetching your game schedule, please
      refresh the page to try again`}
      />
    )
  return (
    <div className={styles.FilteredGamesListContainer}>
      {!isHistoricData && (
        <div className={classNames(styles.FilterSection, styles[type])}>
          <div className={classNames(styles.TimeFilterContainer, styles[type])}>
            <TimeFilter
              onSelectFilter={handleSelectTime}
              selectedFilter={selectedTimeFilter}
            />
          </div>
          <div className={styles.DateSelector}>
            <DateSelectorButtons
              dateSelected={''}
              onCalendarClick={onCalendarIconClick}
              onResetFiltersClick={() => null}
            />
          </div>
        </div>
      )}
      {isLoading && dataToDisplay.length < 1 ? (
        <Loader />
      ) : (
        <EventCardList
          type={type}
          now={now}
          eventList={dataToDisplay}
          totalPages={pageCount}
          currentPage={currentPage}
          isLoading={isLoading}
          increasePageNumber={() =>
            setCurrentPage((prevValue) => prevValue + 1)
          }
        />
      )}
    </div>
  )
}
