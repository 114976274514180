import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { FilteredGamesListType } from './FilteredGamesList/FilteredGamesList'
import { EventClassesFilter } from './HierarchicalFilter/EventClassesFilter'
import { CategoryFilterType } from './CategoryFilter/CategoryFilter'
import { LocationsFilter } from './HierarchicalFilter/LocationsFilter'
import { ChannelEvent } from './EventScheduleTabs'
import { ScheduleContainer } from './ScheduleContainer/ScheduleContainer'

interface Props {
  channelSlug: string
  eventData: ChannelEvent
  isHistoricData: boolean
}

export const EventScheduleRoutes: FC<Props> = ({
  channelSlug,
  eventData,
  isHistoricData
}) => {
  const now = Date.now()

  return (
    <Switch>
      <Route path="/:company/games/event/:event/schedule/:date">
        <ScheduleContainer
          channelSlug={channelSlug}
          eventData={eventData}
          now={now}
          isHistoricData={isHistoricData}
        />
      </Route>
      <Route path="/:company/games/event/:event/schedule">
        <ScheduleContainer
          channelSlug={channelSlug}
          eventData={eventData}
          now={now}
          isHistoricData={isHistoricData}
        />
      </Route>
      <Route path="/:company/games/event/:event/classes/:eventClass/groups/:group">
        <EventClassesFilter
          filterType={CategoryFilterType.Classes}
          channelSlug={channelSlug}
          eventData={eventData}
          backgroundType={FilteredGamesListType.Secondary}
        />
      </Route>
      <Route path="/:company/games/event/:event/classes/:eventClass">
        <EventClassesFilter
          filterType={CategoryFilterType.Classes}
          channelSlug={channelSlug}
          eventData={eventData}
          backgroundType={FilteredGamesListType.Secondary}
        />
      </Route>
      <Route path="/:company/games/event/:event/classes">
        <EventClassesFilter
          filterType={CategoryFilterType.Classes}
          channelSlug={channelSlug}
          eventData={eventData}
          backgroundType={FilteredGamesListType.Secondary}
        />
      </Route>
      <Route path="/:company/games/event/:event/locations/:location/fields/:field">
        <LocationsFilter
          channelSlug={channelSlug}
          filterType={CategoryFilterType.Locations}
          backgroundType={FilteredGamesListType.Secondary}
          eventData={eventData}
        />
      </Route>
      <Route path="/:company/games/event/:event/locations/:location">
        <LocationsFilter
          channelSlug={channelSlug}
          filterType={CategoryFilterType.Locations}
          backgroundType={FilteredGamesListType.Secondary}
          eventData={eventData}
        />
      </Route>
      <Route path="/:company/games/event/:event/locations">
        <LocationsFilter
          channelSlug={channelSlug}
          filterType={CategoryFilterType.Locations}
          backgroundType={FilteredGamesListType.Secondary}
          eventData={eventData}
        />
      </Route>
    </Switch>
  )
}
