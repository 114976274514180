import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { User } from '../interfaces/User.interface'

export default function useCurrentUser(initialValue = undefined) {
  const user = useSelector((state: any) => state.user)
  const [currentUser, setCurrentUser] = useState<User | undefined>(user)
  const authenticationState = useSelector((state: any) => state.authentication)
  const isUserLoggedIn = authenticationState?.loggedIn

  useEffect(() => {
    if (isUserLoggedIn) {
      setCurrentUser(user)
    } else {
      setCurrentUser(undefined)
    }
    return () => {
      setCurrentUser(undefined)
    }
  }, [isUserLoggedIn, user])

  useEffect(() => {
    if (JSON.stringify(user) !== JSON.stringify(currentUser)) {
      setCurrentUser(user)
    }
  }, [user, currentUser])

  return currentUser || initialValue
}
