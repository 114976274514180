import React, { FC } from 'react'

import ContentContainer from 'components/ContentContainer'
import styles from './SettingsTabContainer.module.scss'

interface Props {
  showLightBackground: boolean
  children?: React.ReactNode
  channelSlug?: string
  channelName?: string
}

export const SettingsTabContainer: FC<Props> = ({
  showLightBackground,
  children
}) => {
  return (
    <ContentContainer
      hasLightBackground={showLightBackground}
      hasTopBottomPadding
      hasLeftRightPadding
    >
      <div className={styles.SettingsTabContainer}>{children}</div>
    </ContentContainer>
  )
}
