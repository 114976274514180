import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { FormikProps } from 'formik'

import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import Button, { ButtonType } from 'components/buttons/button/Button'

import styles from './FormButtons.module.scss'

interface Props {
  formik: FormikProps<any>
  onCancelClick: () => void
}

export const FormButtons: FC<Props> = ({ formik, onCancelClick }) => (
  <div className={styles.FormButtons}>
    <Button
      type={ButtonType.GhostWithBoarder}
      label={t`Cancel`}
      roleType="button"
      onClick={onCancelClick}
      buttonSize="Medium"
    />
    <SubmitButton
      type="secondary"
      disabled={!formik.dirty || formik.isSubmitting}
      customStyle={styles.SubmitButton}
      label={t`Save and Publish`}
    />
  </div>
)
