import React, { FC, useState } from 'react'
import Modal from '../Modal/Modal'
import { ModalError } from './ModalError/ModalError'
import { Loader } from '../../Loader/Loader'
import styles from './ConfirmationModal.module.scss'
import { ModalActionButtons } from '../ModalActionButtons/ModalActionButtons'

interface Props {
  modalHeader: string
  title: string
  subText: string
  defaultOpen: boolean
  isError: boolean
  isLoading: boolean
  errorMessage: string
  confirmButtonLabel: string
  cancelButtonLabel: string
  onClose: () => void
  onConfirm: () => void
}
export const ConfirmationModal: FC<Props> = ({
  modalHeader,
  title,
  subText,
  defaultOpen,
  isError,
  isLoading,
  errorMessage,
  confirmButtonLabel,
  cancelButtonLabel,
  onClose,
  onConfirm
}) => {
  const [showError, setShowError] = useState(isError)
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div
        className={styles.ConfirmationModalContainer}
        data-testid="confirmation-modal"
      >
        {showError ? (
          <ModalError
            errorMessage={errorMessage}
            onCancel={handleClose}
            onTryAgain={() => setShowError(false)}
          />
        ) : (
          <div className={styles.InsideContainer}>
            <div className={styles.ModalHeader}>{modalHeader}</div>
            <div className={styles.ModalInfoContainer}>
              <div className={styles.Title}>{title} </div>
              <div className={styles.Information}>{subText}</div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <ModalActionButtons
                cancelLabel={cancelButtonLabel}
                confirmButtonLabel={confirmButtonLabel}
                onCancel={handleClose}
                onConfirm={onConfirm}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
