import React from 'react'

const PlayFilledIcon = () => (
  <svg
    data-testid="play-filled-icon"
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0932 11.9134L7.1782 5.03418C7.11153 4.99568 7.0282 5.0438 7.0282 5.12078V18.8792C7.0282 18.9562 7.11153 19.0043 7.1782 18.9658L19.0932 12.0866C19.1598 12.0481 19.1598 11.9519 19.0932 11.9134Z"
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth="1.5"
    />
  </svg>
)
export default PlayFilledIcon
