import React from 'react'

export const MasterCardIcon = () => (
  <svg
    width="48"
    height="24"
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="24" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8068 21C34.8843 21 39.0004 16.9706 39.0004 12C39.0004 7.02944 34.8843 3 29.8068 3C24.7294 3 20.6133 7.02944 20.6133 12C20.6133 16.9706 24.7294 21 29.8068 21Z"
      fill="#F79E1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1935 21C23.271 21 27.3871 16.9706 27.3871 12C27.3871 7.02944 23.271 3 18.1935 3C13.1161 3 9 7.02944 9 12C9 16.9706 13.1161 21 18.1935 21Z"
      fill="#EB001B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0002 5.02195C21.9332 6.67228 20.6133 9.18523 20.6133 12C20.6133 14.8147 21.9332 17.3276 24.0002 18.978C26.0672 17.3276 27.3871 14.8147 27.3871 12C27.3871 9.18523 26.0672 6.67228 24.0002 5.02195Z"
      fill="#FF5F00"
    />
  </svg>
)
