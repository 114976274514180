import { HeroImageCallToAction } from 'features/customContent/HeroImageCallToAction/NewHeroImagePromotionModal/Form/HeroImageCallToActionForm'
import ApiEndpoint from 'network/ApiEndpoint'

export interface HeroImageCallToActionApiResponse {
  title: string
  content: string
  button_text: string
  button_link: string
  button_link_target: string
}
export const getHeroImageCallToAction = async ({
  channelSlug,
  disableCache
}: {
  channelSlug: string
  disableCache: boolean
}): Promise<HeroImageCallToAction> => {
  try {
    const resultResponse = await ApiEndpoint.call({
      path: `channels/${channelSlug}/call_to_action/hero_image`,
      method: ApiEndpoint.METHODS.GET,
      authenticatable: disableCache
    })

    const responseData = await resultResponse
    const mapped =
      responseData &&
      responseData.data &&
      mapHeroImageCallToAction(responseData.data)
    return mapped || null
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapHeroImageCallToAction = (
  heroImageCTA: HeroImageCallToActionApiResponse
) => ({
  title: heroImageCTA.title,
  text: heroImageCTA.content,
  hasButton: heroImageCTA.button_link && heroImageCTA.button_text,
  textField: heroImageCTA.button_text,
  linkField: heroImageCTA.button_link,
  target: heroImageCTA.button_link_target
})
