import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearStream } from 'redux-store/stream/stream.actions'
import {
  streamIsLoadingSelector,
  streamSelector
} from 'redux-store/stream/stream.selectors'

import useCompanyParam from 'hooks/CompanyParam'
import classNames from 'classnames'
import useCurrentUser from 'hooks/CurrentUser'

import { RootState } from 'redux-store/store'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { getCurrentPayment } from 'redux-store/payments/payment.selector'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import subscriberActions from 'redux-store/NchanSubscriber/subscriber.actions'

import { Loader } from 'components/Loader/Loader'
import { StreamPageLayout } from './StreamPageLayout'
import { StreamModalContent } from './StreamModalContent'
import { useChannelDataStatus } from 'pages/ChannelPage/useChannelDataStatus'

import { StreamPlayingView } from './StreamPlayingView/StreamPlayingView'
import { StreamError } from './components/StreamError/StreamError'
import { useScrollToTop } from 'helpers/useScrollToTop'

import { VideoPlayerData, ViewerParameters } from './viewerPage.interfaces'
import { getViewerParams } from './helpFunctions/getViewerParams'
import { createGetStreamActions } from './helpFunctions/createGetStreamActions'

import { VideoPageMeta } from './VideoPageMeta'
import { ViewerPageTitle } from './components/ViewerPageTitle/ViewerPageTitle'

import styles from './StreamPage.module.scss'
import { getViewerPageUrl } from './helpFunctions/getViewerPageUrl'

export const StreamPage: FC = () => {
  const viewParamsCallback = useCallback(getViewerParams, [])
  const location = useLocation()
  const dispatch = useDispatch()
  const streamIsLoading = useSelector(streamIsLoadingSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const currentUser = useCurrentUser()
  const currentPayment = useSelector(getCurrentPayment)
  const stream = useSelector(streamSelector)
  const mediaPlayerData: VideoPlayerData = stream.data
  const company = useCompanyParam()
  const channel = useSelector(channelSelector)
  useScrollToTop()
  const roles = useSelector((state: RootState) => state.roles)
  const isViewingAsAdmin = roles.isViewingAsAdmin || roles.isViewer
  const mediaObject = stream?.data?.mediaObject
  const { channelDataIsReady } = useChannelDataStatus(channel, company)
  const hasChannelAccess = useSelector(
    (state: RootState) => state.permissions.hasChannelAccess
  )
  const title = mediaObject?.title
  const rootDomain =
    channel?.data?.root_domain || channel?.data?.root_channel?.root_domain
  const viewerPageUrl = getViewerPageUrl(mediaObject, rootDomain)
  const { ident } = useParams<{ ident?: string }>()
  const [parameters, setParameters] = useState<ViewerParameters | undefined>(
    undefined
  )
  const [isGamePath, setIsGamePath] = useState(
    location.pathname.includes('/games/g/')
  )
  const streamIsReady =
    mediaPlayerData &&
    mediaPlayerData.streamUrl !== null &&
    mediaPlayerData.streamUrl.urls &&
    mediaPlayerData.streamUrl.urls.hls &&
    mediaPlayerData.streamUrl.urls.dash

  const getCurrentUrl = useCallback(() => {
    return window.origin + location.pathname
  }, [ident])

  useEffect(() => {
    dispatch(clearStream())
  }, [getCurrentUrl])

  useEffect(() => {
    dispatch(userInterface.closeViewStream())
  }, [])

  useEffect(() => {
    if (location) {
      setIsGamePath(location.pathname.includes('/games/g/'))
    }
  }, [location])

  useEffect(() => {
    if (channelDataIsReady) {
      setParameters({
        ...viewParamsCallback({
          isGamePath,
          company,
          ident,
          currentUrl: getCurrentUrl()
        }),
        hasAdminAccess: isViewingAsAdmin
      })
    }
  }, [
    company,
    ident,
    hasChannelAccess,
    viewParamsCallback,
    channelDataIsReady,
    isGamePath,
    isViewingAsAdmin,
    isViewingStream,
    getCurrentUrl
  ])

  const getVideoPlayerDataObject = useCallback(
    async (mediaObjectParams: ViewerParameters) => {
      if (!streamIsLoading && !mediaPlayerData?.streamUrl?.urls) {
        await createGetStreamActions(dispatch, mediaObjectParams)
      }
    },
    [dispatch, streamIsLoading, isViewingAsAdmin, mediaPlayerData]
  )

  useEffect(() => {
    if (parameters) {
      getVideoPlayerDataObject(parameters)
    } else {
      return () => {
        dispatch(clearStream())
      }
    }
  }, [parameters, dispatch, currentPayment, currentUser, isViewingStream])

  useEffect(() => {
    if (mediaPlayerData?.mediaObject?.event_source_uri) {
      dispatch(
        subscriberActions.connect(mediaPlayerData.mediaObject.event_source_uri)
      )
    }
    return () => {
      dispatch(subscriberActions.disconnect())
    }
  }, [mediaPlayerData?.mediaObject?.event_source_uri, isViewingAsAdmin])

  if (stream.error !== undefined) {
    return (
      <div className={styles.ViewerPageContainer}>
        <StreamError homePage={`/${channel?.data.subdomain}` || '/'} />
      </div>
    )
  }
  return (
    <div
      className={classNames(styles.StreamPage, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      {streamIsLoading && (
        <div className={styles.LoadingState}>
          <Loader />
        </div>
      )}
      {stream.data && mediaObject && (
        <>
          <VideoPageMeta />
          <ViewerPageTitle
            title={title}
            rootDomain={rootDomain}
            viewerPageUrl={viewerPageUrl}
          />
          {isViewingStream && streamIsReady ? (
            <StreamPlayingView />
          ) : (
            <StreamPageLayout />
          )}
          <StreamModalContent />
        </>
      )}
    </div>
  )
}
