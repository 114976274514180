import React, { FC } from 'react'

import ChannelLogo from '../../../header/components/ChannelLogo'
import { RootChannelSocialMedia } from '../ThemedFooter'

import { CompanyInfo } from './CompanyInfo'

import styles from './IconRow.module.scss'
import { SocialMedia } from './socialMedia/SocialMedia'

interface Props {
  logoPath: string
  channelName: string
  rootChannelSubdomain: string
  hasSocialMedia: boolean
  socialMedia?: RootChannelSocialMedia
}

export const IconRow: FC<Props> = ({
  logoPath,
  channelName,
  rootChannelSubdomain,
  hasSocialMedia,
  socialMedia
}) => {
  return (
    <div className={styles.IconRowContainer}>
      <div className={styles.IconRow}>
        <ChannelLogo
          logoPath={logoPath}
          channelName={channelName}
          rootChannelSubdomain={rootChannelSubdomain}
          isHeader={false}
        />
        <div className={styles.VerticalDivider} />
        <CompanyInfo />
      </div>
      {hasSocialMedia && <SocialMedia socialMedia={socialMedia} />}
    </div>
  )
}
