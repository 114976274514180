import React, { FC } from 'react'
import { AccessRestrictionType } from 'interfaces'

import styles from './AccessRestrictionText.module.scss'
import { t } from '@lingui/macro'
import { getLabelForAccessRestrictionType } from './accessRestrictionList'
interface Props {
  accessRestrictionType: AccessRestrictionType
}

export const AccessRestrictionText: FC<Props> = ({ accessRestrictionType }) => {
  const title = getLabelForAccessRestrictionType(accessRestrictionType)
  const getDescriptionText = () => {
    switch (accessRestrictionType) {
      case AccessRestrictionType.LoginRequired:
        return t`To be able to watch this stream, the users has to sign in with an account.`
      case AccessRestrictionType.PaymentAndSubscriptionRequired:
        return t`To be able to watch this stream, the users has to have an active subscription and pay the ppv price.`
      case AccessRestrictionType.PaymentRequired:
        return t`To be able to watch this stream, the users has to pay for this stream or view it with a subscription or access`
      case AccessRestrictionType.SubscriptionRequired:
        return t`To be able to watch this stream, the users has to have a subscription to this channel.`
      case AccessRestrictionType.None:
        return t`The stream will be available to everyone for free.`
    }
  }
  return (
    <div className={styles.AccessRestrictionText}>
      <div className={styles.Label} data-testid="access-restriction-info-label">
        {title}:
      </div>
      <div data-testid="access-restriction-info-text">
        {getDescriptionText()}
      </div>
    </div>
  )
}
