import { t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './PaymentContainerRetryButton.module.scss'
import Button, { ButtonType } from 'components/buttons/button/Button'

type ButtonProps = {
  retryPaymentContainer: () => void
}
export const PaymentContainerRetryButton: React.FC<ButtonProps> = ({
  retryPaymentContainer
}) => {
  const paymentFailed = useSelector(
    (state: any) => state.payments.paymentFailed
  )

  const [showErrorState, setErrorState] = useState(paymentFailed)

  useEffect(() => {
    setErrorState(paymentFailed)
  }, [paymentFailed])

  if (showErrorState) {
    return (
      <Button
        customStyle={styles.Button}
        label={t`Retry`}
        onClick={() => retryPaymentContainer()}
        type={ButtonType.Secondary}
        buttonSize="Medium"
      />
    )
  }
  return null
}
