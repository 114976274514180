import React, { FC, useEffect } from 'react'
import { useQuill } from 'react-quilljs'
import Delta from 'quill-delta'

import styles from './TextEditor.module.scss'

import 'quill/dist/quill.snow.css'

interface Props {
  content: Delta
  onChange: (text: Delta) => void
  placeholder: string
}

export const TextEditor: FC<Props> = ({ content, onChange, placeholder }) => {
  const theme = 'snow'

  const modules = {
    toolbar: [['bold', 'italic', 'underline'], ['link']]
  }
  const formats = ['bold', 'italic', 'underline', 'link']

  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder })

  useEffect(() => {
    if (quill && content) {
      quill.setContents(content)
    }
  }, [quill])

  useEffect(() => {
    if (quill) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      quill.on('text-change', (delta, oldDelta, source) => {
        const contents = quill.getContents()
        onChange(contents)
      })
    }
  }, [quill])

  return (
    <div className={styles.TextEditorContainer}>
      <div className={styles.TextEditorContainer} ref={quillRef} />
    </div>
  )
}
