import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { Price } from 'interfaces/Price.interface'

interface QuickEditApiResponse {
  ident: string
  slug: string
  title: string
  start_time: number
  end_time: number
  description: string
  manual_edit: string
  show_manual_edit: boolean
  thumbnail: string
  access_restriction: string
  price: Price
}
export const getQuickEdit = async ({
  mediaObjectIdent
}: {
  mediaObjectIdent: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'stream/quickedit',
      method: ApiEndpoint.METHODS.GET,
      params: {
        ident: mediaObjectIdent
      },
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped = mapResponse(responseData.data)
    return { data: mapped }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
const mapResponse = (apiResponse: QuickEditApiResponse) => ({
  ident: apiResponse.ident,
  title: apiResponse.title,
  description: apiResponse.description,
  endTime: apiResponse.end_time,
  startTime: apiResponse.start_time,
  manualEdit: apiResponse.manual_edit,
  showManualEdit: apiResponse.show_manual_edit,
  thumbnailImageUrl: apiResponse.thumbnail,
  price: apiResponse.price
})
