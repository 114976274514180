import ModoBackground from './Images/ModoBackground.png'
import ModoLogo from './Images/ModoLogo.png'
import ESKBackground from './Images/ESKBackground.png'
import ESKLogo from './Images/ESK.png'

export const TeamsSectionMockData = {
  sectionTitle: 'We broadcast live on SolidSport',
  content: [
    {
      id: 'modo-1',
      channelName: 'Modo',
      imagePath: ModoBackground,
      logoImagePath: ModoLogo,
      channelSlug: 'modochannel'
    },
    {
      id: 'esk-1',
      channelName: 'ESK Fotboll',
      imagePath: ESKBackground,
      logoImagePath: ESKLogo,
      channelSlug: 'esk-fotboll'
    },
    {
      id: 'esk-2',
      channelName: 'ESK Fotboll',
      imagePath: ESKBackground,
      logoImagePath: ESKLogo,
      channelSlug: 'esk-fotboll'
    },
    {
      id: 'modo-2',
      channelName: 'Modo',
      imagePath: ModoBackground,
      logoImagePath: ModoLogo,
      channelSlug: 'modochannel'
    },
    {
      id: 'esk-3',
      channelName: 'ESK Fotboll',
      imagePath: ESKBackground,
      logoImagePath: ESKLogo,
      channelSlug: 'esk-fotboll'
    },
    {
      id: 'modo-3',
      channelName: 'Modo',
      imagePath: ModoBackground,
      logoImagePath: ModoLogo,
      channelSlug: 'modochannel'
    },
    {
      id: 'esk-4',
      channelName: 'ESK Fotboll',
      imagePath: ESKBackground,
      logoImagePath: ESKLogo,
      channelSlug: 'esk-fotboll'
    },
    {
      id: 'modo-4',
      channelName: 'Modo',
      imagePath: ModoBackground,
      logoImagePath: ModoLogo,
      channelSlug: 'modochannel'
    }
  ]
}
