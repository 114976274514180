import React, { FC } from 'react'
import styles from './AccountInfo.module.scss'
import { ProsList } from './ProsList/ProsList'
import { accountProsList } from './ProsList/AccountProsList'
import { SolidsportAccountLogo } from 'assets/brandLogos/SolidsportAccount'
import { PrivacyTextSection } from './PrivacyTextSection'
import { t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

export const AccountInfo: FC = () => {
  const tagLine = 'On All Levels. All Sports. Everywhere.'
  const breakpoint = useCurrentBreakpoint()
  const isMobile = breakpoint <= Breakpoint.sm
  const mobileText = t`Create new`

  return (
    <div className={styles.AccountInfoContainer}>
      <div className={styles.TopContainer}>
        <div className={styles.AccountLogo}>
          {isMobile && <div className={styles.MobileTitle}>{mobileText}</div>}
          <SolidsportAccountLogo />
        </div>
        <ProsList prosList={accountProsList} />
      </div>
      <div className={styles.PrivacyPolicySection}>
        <div className={styles.TextContainer}>
          <PrivacyTextSection />
        </div>
        <div className={styles.TagLine}>{tagLine}</div>
      </div>
    </div>
  )
}
