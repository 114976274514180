import { Trans, t } from '@lingui/macro'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import Button, { ButtonType } from 'components/buttons/button/Button'
import React, { FC, useState } from 'react'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal'
import styles from './DeleteAccountSection.module.scss'
import { postDeleteAccount } from 'api/user/postDeleteAccount'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { SuccessModal } from 'components/Modals/SuccessModal/SuccessModal'

export const DeleteAccountSection: FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()

  const onConfirmDeleteAccountClick = async () => {
    const response = await postDeleteAccount()
    if (!response.isSuccess) {
      setErrorMessage(t`Could not delete account. Please try again later.`)
    } else {
      setModalIsOpen(false)
      setSuccessModalIsOpen(true)
    }
  }
  const onCloseSuccessMessage = () => {
    dispatch(authenticationActions.logout())
    setSuccessModalIsOpen(false)
  }
  return (
    <div className={styles.DeleteAccountSection}>
      <div className={styles.TextContainer}>
        <FormSectionTitle title={t`Delete your account`} />
        <div className={styles.SubText}>
          <Trans>
            If you choose to delete your account, please be aware that your
            account will no longer be accessible on any of our platforms, and
            all associated data will be permanently deleted and cannot be
            recovered. Please ensure that you have saved any important
            information before proceeding.
          </Trans>
        </div>
      </div>
      <Button
        type={ButtonType.GhostWithBoarder}
        label={t`Delete my account`}
        buttonSize={'Medium'}
        onClick={() => setModalIsOpen(true)}
      />
      {modalIsOpen && (
        <ConfirmationModal
          modalHeader={t`Delete your account`}
          title={t`We’re sorry to see you go`}
          subText={t`Once your account is deleted, all of your data will be permanently gone, this also means that your Solidsport account can not be used on any of our platforms. `}
          defaultOpen={modalIsOpen}
          isError={false}
          isLoading={false}
          errorMessage={errorMessage}
          confirmButtonLabel={t`Delete my account`}
          cancelButtonLabel={t`Cancel`}
          onClose={() => setModalIsOpen(false)}
          onConfirm={onConfirmDeleteAccountClick}
        />
      )}
      {successModalIsOpen && (
        <SuccessModal
          title={t`Your account was succesfully deleted`}
          subText={''}
          isOpen={successModalIsOpen}
          buttonLabel={t`Got it`}
          handleClose={onCloseSuccessMessage}
          onButtonClick={onCloseSuccessMessage}
        />
      )}
    </div>
  )
}
