import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { useHistory } from 'react-router-dom'

import ContentContainer from '../../../../components/ContentContainer'
import { TeamsHeading } from './TeamsHeading/TeamsHeading'
import { TeamsList } from './TeamsList/TeamsList'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'
import IconTextButton, {
  ButtonType
} from '../../../../components/buttons/TextIconButton'
import { AddIcon } from '../../GameScheduleListContainer/AddIcon'
import { useDataApi } from '../../../../api/useDataApi'
import { getTeamsInClub } from '../../../../api/sportData/GetTeamsInClub'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import styles from './Teams.module.scss'
import { AddTeamModal } from '../../AddTeamModal/AddTeamModal'

import {
  triggerGTMClickTeamItem,
  triggerGTMImportGames,
  triggerGTMOpenAddTeamModal
} from './gtmForTeams'

export const Teams = () => {
  const [showAddTeamModal, setShowAddTeamModal] = useState(false)

  const channel = useSelector(channelSelector)
  const channelSlug = channel.data?.subdomain
  const channelName = channel.data?.name
  const rootChannel = channel.data?.root_channel
  const rootChannelDomain = rootChannel?.root_domain

  const breakPoint = useCurrentBreakpoint()
  const showButtonInHeader = breakPoint >= Breakpoint.mobile

  const handleOnAddTeam = () => {
    triggerGTMOpenAddTeamModal(channelName, channelSlug, rootChannelDomain)
    setShowAddTeamModal(true)
  }
  const { data, isError, isLoading } = useDataApi({
    apiCall: useCallback(getTeamsInClub, []),
    parameters: useMemo(
      () => ({
        channelSlug
      }),
      [channelSlug]
    )
  })
  const history = useHistory()

  const handleOnClickTeamItem = (selectedTeamSlug: string) => {
    triggerGTMClickTeamItem(
      selectedTeamSlug,
      channelName,
      channelSlug,
      rootChannelDomain
    )
    history.push(
      `/${channelSlug}/contentmanager/teams/${selectedTeamSlug}/competitions`
    )
  }
  const handleOnClickImport = (selectedTeamSlug: string) => {
    ;[channelSlug]
    triggerGTMImportGames(
      selectedTeamSlug,
      channelName,
      channelSlug,
      rootChannelDomain
    )
    history.push(
      `/${channelSlug}/contentmanager/teams/${selectedTeamSlug}/competitions/select`
    )
  }
  return (
    <ContentContainer
      hasLeftRightPadding
      hasLightBackground={false}
      hasTopBottomPadding={false}
    >
      <div className={styles.TeamsContainer}>
        <AddTeamModal
          clubName={channelName}
          clubSlug={channelSlug}
          defaultOpen={showAddTeamModal}
          isSendingInvite={false}
          isInvitingAdmin={false}
          onClose={() => setShowAddTeamModal(false)}
        />
        <TeamsHeading
          heading={t`Teams in your club`}
          subheading={t`Here you can manage the teams in your club. Start by making sure the teams games are connected`}
          showAddButton={showButtonInHeader}
          onAddTeam={handleOnAddTeam}
        />
        <TeamsList
          listData={data}
          hasError={isError}
          errorMessage={t`Something went wrong when trying to get the teams list, please try again`}
          isLoading={isLoading}
          onTeamItemClick={(selectedTeamChannelSlug: string) =>
            handleOnClickTeamItem(selectedTeamChannelSlug)
          }
          onImportButtonClick={(selectedTeamChannelSlug: string) =>
            handleOnClickImport(selectedTeamChannelSlug)
          }
        />
        {!showButtonInHeader && (
          <IconTextButton
            label={t`Add team`}
            type={ButtonType.Ghost}
            buttonSize="Medium"
            id="add-team"
            isFluid
            onClick={handleOnAddTeam}
          >
            <AddIcon />
          </IconTextButton>
        )}
      </div>
    </ContentContainer>
  )
}
