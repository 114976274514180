import React, { FC } from 'react'
import DropdownItem from '../DropdownItem/DropdownItem'
import styles from './DropdownList.module.scss'

export interface DropDownListItem {
  label: string
  id: string
}
interface Props {
  list: DropDownListItem[]
  selectedItemId: string
  onItemSelected: (label: string, id: string) => void
}
export const DropdownList: FC<Props> = ({
  list,
  selectedItemId,
  onItemSelected
}) => (
  <div className={styles.DropdownList} data-testid="dropdown-list">
    {list.map((item: DropDownListItem) => (
      <DropdownItem
        key={item.id}
        label={item.label}
        id={item.id}
        selectedId={selectedItemId}
        onItemSelected={(label, id) => onItemSelected(label, id)}
      />
    ))}
  </div>
)
