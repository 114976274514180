import { ColumnVariants } from 'components/content/Standings/Standings.interface'

const BASE_URL = process.env.REACT_APP_STANDINGS_ENDPOINT
export interface StandingsColumns {
  index: number
  label: string
  field: string
  variant?: ColumnVariants
}
export interface StandingsRowApi {
  id: number
  MatchClassId: string
  MatchGroupId: string
  DisplayOrder: string
  Position: string
  UpdateTimeStamp: string
  a: string
  b: string
  c: string
  d: string
  e: string
  f: string
  g: string
  h: string
  i: string
  j: string
  isLastBeforeDivider: boolean
  image: string
  subdomain: string
  selectedTeam?: string
}
export interface StandingApiResponse {
  columns: StandingsColumns[]
  rows: StandingsRowApi[]
}
export const getStandingsForLeague = async ({
  slug,
  name,
  parent,
  externalReference
}: {
  slug: string
  name: string
  parent: string
  externalReference?: string
}): Promise<StandingApiResponse[]> => {
  const query = `slug=${slug}&name=${name}&parent=${parent}&externalReference=${externalReference}`
  const url = `${BASE_URL}/getFromLeagueName?${query}`

  const response = await fetch(url)
  const data = await response.json()
  return data
}
