import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
interface TransactionApiResponse {
  created_at: number
  currency: string
  price: number
  purchased_item: {
    auto_renew: boolean
    company: { name: string; subdomain: string }
    expires_at: number
    title: string
    slug: string
    type: string
  }
  reference: string
  type: string
  vat: number
}
export const getTransactionForUser = async ({
  currentPage,
  perPageCount
}: {
  currentPage: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/transactions',
      params: {
        page: currentPage,
        per_page: perPageCount
      },
      authenticatable: true
    })

    const responseData = await resultResponse
    const mappedResponse = mapTransactionResponse(responseData.data)
    return {
      data: mappedResponse,
      totalCount: responseData.totalCount
    }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
const mapTransactionResponse = (apiResonse: TransactionApiResponse[]) =>
  apiResonse.map((transaction: TransactionApiResponse) => ({
    createdAt: transaction.created_at,
    channelName: transaction.purchased_item.company.name,
    channelSlug: transaction.purchased_item.company.subdomain,
    purchasedObjectName: transaction.purchased_item.title,
    purchasedObjectSlug: getPurchasedObjectSlug(transaction),
    price: transaction.price,
    currency: transaction.currency,
    refNumber: transaction.reference,
    vat: transaction.vat
  }))

const getPurchasedObjectSlug = (transaction: TransactionApiResponse) => {
  switch (transaction.purchased_item.type) {
    case 'game':
      return `${transaction.purchased_item.company.subdomain}/games/g/${transaction.purchased_item.slug}`
    case 'media_object':
    case 'livestream':
      return `${transaction.purchased_item.company.subdomain}/watch/${transaction.purchased_item.slug}`
    case 'subscription':
      return `${transaction.purchased_item.company.subdomain}/pass`
    case 'all_access':
      return `${transaction.purchased_item.company.subdomain}/access`
    default:
      return `${transaction.purchased_item.company.subdomain}`
  }
}
