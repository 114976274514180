/* eslint-disable @typescript-eslint/indent */
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { FontApi } from './fontApi'

export const getFontsForChannel = async (slug: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/fonts`
    })

    const responseData = await resultResponse
    return mapChannelFont(responseData.data)
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapChannelFont = (response: FontApi[]) =>
  response
    ? response.map((font: FontApi) => ({
        name: font.name,
        identifier: font.identifier,
        filename: font.filename,
        companySlug: font.company_subdomain,
        fontUrl: font.cache_file_url
      }))
    : []
