import React, { FC } from 'react'
import { t } from '@lingui/macro'
import EditIcon from '../../../../assets/icons/EditIcon'
import IconTextTag from '../../../Tags/IconTextTag'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'
import { getAdminPath } from 'helpers/getAdminPath'
import styles from './GameScheduleButtons.module.scss'

interface Props {
  id: string
  channelSlug: string
}
export const AdminPastGameNoBroadcastButton: FC<Props> = ({
  id,
  channelSlug
}) => {
  const adminPath = getAdminPath()
  return (
    <>
      <div className={styles.FirstButton}>
        <IconTextTag tagText={t`No broadcast`} />
      </div>
      <LinkIconButton
        type={ButtonType.Ghost}
        buttonSize="Small"
        label={t`Edit`}
        id="edit"
        href={`${adminPath}/admin/games/${id}?select_company=${channelSlug}`}
      >
        <EditIcon />
      </LinkIconButton>
    </>
  )
}
