import React, { FC } from 'react'
import styles from './TimeField.module.scss'
import classNames from 'classnames'
import { FormValidationError } from '../FormValidationError/FormValidationError'

interface Props {
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  timeValue: string
  onChange: () => void
  isRequired: boolean
  readonly?: boolean
  customStyle?: string
}

export const TimeField: FC<Props> = ({
  id,
  label,
  placeholder,
  isValid,
  validationMessage,
  timeValue,
  isRequired,
  onChange,
  readonly,
  customStyle
}) => {
  return (
    <div
      data-testid={id}
      className={classNames(styles.TimeFieldContainer, {
        [customStyle || '']: customStyle
      })}
    >
      <label htmlFor={id}>
        {isRequired && '* '}
        {label}
      </label>
      <input
        type="time"
        className={classNames(styles.TimeField, {
          [styles.Error]: !isValid
        })}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        value={timeValue}
        disabled={readonly}
      />
      {!isValid && <FormValidationError errorMessage={validationMessage} />}
    </div>
  )
}
