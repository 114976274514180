import React, { FC } from 'react'
import PlayFilledIcon from '../PlayFilledIcon'
import getDateText from '../getDateText'
import styles from '../ThumbnailLabel.module.scss'

interface Props {
  startAt: number
}
const Upcoming: FC<Props> = ({ startAt }) => {
  const { getDateString } = getDateText(startAt)

  return (
    <div className={styles.Coming} data-testid="upcoming-thumbnail-label">
      <div className={styles.ComingContainer}>
        <div className={styles.Icon}>
          <PlayFilledIcon />
        </div>
        {getDateString()}
      </div>
    </div>
  )
}
export default Upcoming
