export enum DomainRegistrationStatus {
  NotSet = 99999,
  WaitingForPartnerConfiguration = 0,
  WaitingForSslGeneration = 2,
  ValidatingDomain = 1091,
  DomainAlreadyInUse = 1009,
  SslGenerated = 3,
  ChangingDomain = 1010,
  Expired = 4,
  ConfigurationError = 1015,
  SslInactive = 1090,
  Aborted = 1,
  RootDomainPointsIncorrectly = 5
}
