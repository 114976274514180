import React, { FC } from 'react'

import styles from './RadioButton.module.scss'

interface Props {
  id: string
  isChecked: boolean
  onChange: () => void
  label: string
}

export const RadioButton: FC<Props> = ({ id, onChange, isChecked, label }) => {
  return (
    <div className={styles.RadioButton} onClick={onChange}>
      <input id={id} type="radio" checked={isChecked} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
