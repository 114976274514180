export const regex = /[^-\w\s]/gi

export const sanitiseString = (input: string) => {
  let sanitised = input.toLocaleLowerCase().replace(/å/g, 'a')
  sanitised = sanitised.replace(/ä/g, 'a')
  sanitised = sanitised.replace(/ö/g, 'o')
  sanitised = sanitised.replace(regex, '')

  return sanitised.trim().replace(/ {2}/g, ' ').replace(/ /g, '-').toLowerCase()
}
