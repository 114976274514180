import { EventGroup } from 'interfaces'
import { FilterItem } from 'features/multistageTournaments/HierarchicalFilter/FilterList/FilterList'

export const mapGroupResponse = (apiResponse: EventGroup[]): FilterItem[] =>
  apiResponse.map((group: EventGroup) => ({
    label: group.name,
    shortLabel: group?.short_name,
    slug: group.slug,
    children: group.children ? mapGroupResponse(group.children) : undefined
  }))
