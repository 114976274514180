import { RootState } from '../store'

export const userIsSupporterSelector = (state: RootState) => {
  const channelSubdomain = state.channel?.data?.subdomain
  if (!channelSubdomain) return false

  const subdomainIsinlist = state.user?.supported_and_followed_companies?.find(
    (company: any) => company.subdomain === channelSubdomain
  )

  return subdomainIsinlist?.is_monthly_supporter
}

export const userHasAllAccessPassSelector = (state: RootState) => {
  const channelSubdomain = state.channel?.data?.subdomain
  if (!channelSubdomain) return false

  const subdomainIsinlist = state.user?.supported_and_followed_companies?.find(
    (company: any) => company.subdomain === channelSubdomain
  )

  return subdomainIsinlist?.has_all_access_pass
}

export const userIsUpdatingSelector = (state: RootState) => {
  return state.user?.updatingUser
}

export const isUserChannelAdminSelector = (state: RootState) =>
  state.channel?.data &&
  state.user?.companies &&
  state.user.companies.length > 0 &&
  state.user.companies
    .map((c: any) => c.subdomain)
    .includes(state.channel?.data?.subdomain)

export const preferredCurrencySelector = (state: RootState) =>
  state.user?.preferred_currency
