import React, { FC, useState } from 'react'
import classNames from 'classnames'

import styles from './InputSearch.module.scss'
import { SearchIcon } from './SearchIcon'

interface Props {
  label: string
  placeHolder: string
  hasError: boolean
  errorMessage: string
  onSearch: (searchInput: string) => void
}
export const InputSearch: FC<Props> = ({
  label,
  placeHolder,
  hasError,
  errorMessage,
  onSearch
}) => {
  const [searchInput, setSearchInput] = useState('')

  return (
    <div className={styles.InputSearchContainer}>
      <div className={styles.Label}>{label}</div>
      <div
        className={classNames(styles.InputContainer, {
          [styles.HasError]: hasError
        })}
      >
        <input
          className={styles.SearchInput}
          type="text"
          placeholder={placeHolder}
          onChange={(event) => {
            setSearchInput(event.target.value)
            if (event.target.value === '') {
              onSearch(event.target.value)
            }
          }}
          tabIndex={0}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSearch(searchInput)
            }
          }}
          value={searchInput}
        />
        <button
          onClick={() => onSearch(searchInput)}
          data-testid={`btn-${label}`}
          type="button"
          disabled={searchInput === '' || hasError}
          className={styles.SearchIconButton}
        >
          <SearchIcon />
        </button>
      </div>
      {hasError && errorMessage && (
        <div className={styles.ErrorMessage}>{errorMessage}</div>
      )}
    </div>
  )
}
