import { Accept } from 'react-dropzone'
import { UPLOAD_TYPE } from './UploadType'

export const getAcceptType = (type: UPLOAD_TYPE): Accept => {
  switch (type) {
    case UPLOAD_TYPE.HIGHLIGHT:
    case UPLOAD_TYPE.VIDEO:
      return {
        'video/*': []
      }
    case UPLOAD_TYPE.IMAGE:
      return {
        'image/*': []
      }
    case UPLOAD_TYPE.ICON:
      return {
        'image/ico': ['.ico']
      }
    default:
      return {
        '*/*': []
      }
  }
}
