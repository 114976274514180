import React, { FC } from 'react'
import { Field, Form, Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { t } from '@lingui/macro'

import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'
import {
  AddAdminRequest,
  addAdminToChannel
} from '../../../../api/sportData/addAdminToChannel'
import { FormActionButtons } from '../../../../components/Forms/FormActionButtons/FormActionButtons'
import styles from './InviteAdminForm.module.scss'
import { triggerGTMOnInviteAdminSuccess } from '../gtmForAddTeamModal'

interface InviteAdmin {
  email?: string
}
interface Props {
  onInviteSuccess: () => void
  onCloseForm: () => void
  createdChannelSlug: string
}
export const InviteAdminForm: FC<Props> = ({
  onInviteSuccess,
  onCloseForm,
  createdChannelSlug
}) => {
  const initialValues: InviteAdmin = {
    email: ''
  }
  const inviteAdminSchema = yup.object({
    email: yup.string().email(t`Please enter a valid email`)
  })
  const onSubmit = async (
    values: InviteAdmin,
    formikHelpers: FormikHelpers<any>
  ) => {
    const addAdminToChannelValues: AddAdminRequest = {
      email: values.email!.toString(),
      channelSlug: createdChannelSlug
    }
    formikHelpers.setSubmitting(true)
    const response = await addAdminToChannel(addAdminToChannelValues)
    if (response.hasError) {
      // @ts-ignore
      formikHelpers.setErrors(response.errors)
    } else {
      triggerGTMOnInviteAdminSuccess(createdChannelSlug)
      formikHelpers.setSubmitting(false)
      onInviteSuccess()
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inviteAdminSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.InviteAdminContainer}>
              <Field
                component={TextInput}
                id="email"
                data-testid="email-input"
                name="email"
                label={t`Invite admin`}
                placeholder={t`email@gmail.com`}
                onChange={formik.handleChange}
                textValue={formik.values.email}
                isValid={!formik.errors.email}
                validationMessage={formik.errors.email}
              />
              <FormActionButtons
                formik={formik}
                cancelButtonLabel={t`Not now`}
                submitButtonLabel={t`Invite`}
                onCancel={onCloseForm}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
