import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const getAllAccess = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page?: number
  perPageCount?: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'session/subscriptions/all_access',
      params: {
        company: slug,
        page: page || 1,
        per_page: perPageCount || 10
      }
    })
    const responseData = await resultResponse

    return { data: responseData.data, totalCount: responseData.totalCount }
  } catch (e) {
    console.log('error', e)
    // @ts-ignore
    throw new Error(e.message)
  }
}
