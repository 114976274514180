import React, { FC } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { FormikProps } from 'formik'
import { Checkbox } from 'components/checkbox/Checkbox'
import styles from './ButtonContent.module.scss'
import { CustomLinkFormFields } from 'components/Forms/CustomLinkFormFields/CustomLinkFormFields'

interface Props {
  formik: FormikProps<any>
  hasButton: boolean
  onHasButtonChange: (newValue: string | undefined) => void
}

export const ButtonContent: FC<Props> = ({
  onHasButtonChange,
  hasButton,
  formik
}) => {
  const handleHasButtonChange = (newValue: string | undefined) => {
    onHasButtonChange(newValue)
  }

  return (
    <div className={styles.ButtonContentContainer}>
      <div className={styles.CheckBox}>
        <Checkbox
          id="hasButton"
          label={t`Button`}
          checked={hasButton}
          onChange={handleHasButtonChange}
        />
      </div>
      <div
        className={classNames(styles.ButtonFields, {
          [styles.Hidden]: !hasButton
        })}
      >
        <CustomLinkFormFields
          formik={formik}
          textFieldLabel={t`Button text`}
          textFieldPlaceHolder={t`Button text`}
          linkFieldLabel={t`Button link`}
          textFieldName="textField"
          linkFieldName={'linkField'}
          showAlwaysAsColumn={false}
        />
      </div>
    </div>
  )
}
