import React from 'react'

export const VoucherCodeIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0622 13.2895L22.4469 13.6999L22.0622 13.2895L21.7614 13.5715C20.4182 14.8308 20.4182 16.963 21.7614 18.2223L22.0622 18.5042C22.3016 18.7287 22.4375 19.0423 22.4375 19.3705V19.5779C22.4375 20.2337 21.9058 20.7654 21.25 20.7654H3.75C3.09416 20.7654 2.5625 20.2337 2.5625 19.5779V19.3705C2.5625 19.0423 2.69836 18.7287 2.93782 18.5042L3.23857 18.2223C4.58182 16.963 4.58182 14.8308 3.23857 13.5715L2.93782 13.2895C2.69836 13.065 2.5625 12.7514 2.5625 12.4232V12.2158C2.5625 11.56 3.09416 11.0283 3.75 11.0283H21.25C21.9058 11.0283 22.4375 11.56 22.4375 12.2158V12.4232C22.4375 12.7514 22.3016 13.065 22.0622 13.2895Z"
      stroke="#2F3135"
      strokeWidth="1.125"
    />
    <path
      d="M20.6896 7.61906L21.1756 7.90239L21.1756 7.90239L20.6896 7.61906ZM20.3044 8.27972L20.7903 8.56305L20.3044 8.27972ZM3.77802 10.2056L18.9122 5.6932L18.5907 4.6151L3.45657 9.12746L3.77802 10.2056ZM20.2037 7.33572L19.8185 7.99639L20.7903 8.56305L21.1756 7.90239L20.2037 7.33572ZM20.3098 6.44882C20.3985 6.74642 20.3601 7.06746 20.2037 7.33572L21.1756 7.90239C21.4886 7.36549 21.5655 6.72297 21.3879 6.12738L20.3098 6.44882ZM19.8185 7.99639C19.0962 9.23519 19.5312 10.8256 20.7835 11.5243L21.3316 10.5418C20.6292 10.15 20.3852 9.2579 20.7903 8.56305L19.8185 7.99639ZM18.9122 5.6932C19.5068 5.51591 20.1325 5.85422 20.3098 6.44882L21.3879 6.12738C21.0331 4.93736 19.7807 4.26028 18.5907 4.6151L18.9122 5.6932ZM3.45657 9.12746C2.51942 9.40688 1.98622 10.3931 2.26564 11.3303L3.34374 11.0088C3.24184 10.6671 3.43628 10.3074 3.77802 10.2056L3.45657 9.12746Z"
      fill="#2F3135"
    />
    <path
      d="M15.6392 7.68066L16.4826 11.1903"
      stroke="#2F3135"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M8.51758 12.6387V19.1559"
      stroke="#2F3135"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M13 17.6729L16.9451 13.1522"
      stroke="#2F3135"
      strokeWidth="0.875"
      strokeLinecap="round"
    />
    <path
      d="M17.71 16.6729C17.71 17.3929 17.2329 17.8829 16.75 17.8829C16.2671 17.8829 15.79 17.3929 15.79 16.6729C15.79 15.9528 16.2671 15.4629 16.75 15.4629C17.2329 15.4629 17.71 15.9528 17.71 16.6729Z"
      stroke="#2F3135"
      strokeWidth="0.58"
    />
    <path
      d="M14.21 14.1729C14.21 14.8929 13.7329 15.3829 13.25 15.3829C12.7671 15.3829 12.29 14.8929 12.29 14.1729C12.29 13.4528 12.7671 12.9629 13.25 12.9629C13.7329 12.9629 14.21 13.4528 14.21 14.1729Z"
      stroke="#2F3135"
      strokeWidth="0.58"
    />
  </svg>
)
