import { Company } from '../interfaces'

export const secondsToFormattedTime = (
  seconds: number,
  reduceTimer: boolean
): string => {
  try {
    if (reduceTimer && seconds < 60) {
      return `${seconds.toString()}s`
    }

    const split = seconds < 3600 ? [14, 5] : [11, 8]
    return new Date(seconds * 1000).toISOString().substr(split[0], split[1])
  } catch (e) {
    return '0'
  }
}

export const queryString = (params: any = {}) => {
  return new URLSearchParams(params).toString()
}

export const validateEmail = (value: string): boolean => {
  return (
    value.length === 0 ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  )
}

export const isValidCompany = (company: Company | undefined) => {
  return company && company.type !== 'solidtango'
}

export const metaFriendlyString = (s: string | undefined): string => {
  if (s) {
    try {
      return s
        .replace(/(?:\r\n)/g, '. ')
        .replace(/(?:\n)/g, '. ')
        .replace(/[^a-ö0-9-_:+.,/()?!&#@\s]/gi, '')
        .replace(/\s+/g, ' ')
        .trim()
    } catch (e) {
      return ''
    }
  }
  return ''
}
