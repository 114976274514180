import React, { FC } from 'react'
import styles from './PhoneNumberField.module.scss'
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel'

import classNames from 'classnames'
import { useField } from 'formik'
import { CountryCodeSelector } from './CountryCodeSelector/CountryCodeSelector'
import { Country } from 'api/countries/getCountriesList'
import { FormValidationError } from '../FormValidationError/FormValidationError'
import { User } from 'interfaces'

interface Props {
  label: string
  id: string
  placeholder: string
  onCountrySelected: (country: Country) => void
  currentUser?: User
}
export const PhoneNumberField: FC<Props> = ({
  label,
  id,
  placeholder,
  onCountrySelected,
  currentUser
}) => {
  const [field, meta] = useField(id)
  return (
    <div className={styles.PhoneNumberField}>
      <FormFieldLabel label={label} />
      <div
        className={classNames(styles.FieldContainer, {
          [styles.Error]: meta.error && meta.touched
        })}
      >
        <CountryCodeSelector
          onCountrySelected={(country: Country) => onCountrySelected(country)}
          currentUser={currentUser}
        />
        <input
          className={styles.PhoneNumberField}
          placeholder={placeholder}
          data-testid="phone-number-field"
          id={id}
          {...field}
        />
      </div>
      {meta.touched && meta.error && (
        <FormValidationError errorMessage={meta.error} />
      )}
    </div>
  )
}
