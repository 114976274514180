/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import { t } from '@lingui/macro'
import {
  TextFormModal,
  TextFormModalSize
} from '../../../../../components/Modals/TextFormModal/TextFormModal'
import { Game } from '../../../../../interfaces'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../../hooks/CurrentBreakpoint'
import { DefaultPrices } from './defaultPrices'
import { ActivationContent } from './ActivationContent'

import styles from './ActivateLivestreamModal.module.scss'

interface Props {
  game: Game
  isAlreadyActivated: boolean
  isLoading: boolean
  isError: boolean
  isSuccessfullyActivated: boolean
  hasActivationValidationError: boolean
  numberOfStreams: number
  defaultPrices?: DefaultPrices
  onActivateLivestream: () => void
  onClose: () => void
}

export const ActivateLivestreamModal: FC<Props> = ({
  game,
  isAlreadyActivated,
  isLoading,
  isError,
  hasActivationValidationError,
  isSuccessfullyActivated,
  numberOfStreams,
  defaultPrices,
  onActivateLivestream,
  onClose
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint < Breakpoint.md

  const title = t`Prepare stream`
  const description =
    isError || isLoading || isAlreadyActivated
      ? ''
      : t`When the stream is published, the supporters will be able to find it and buy it.`
  const subTitle = t`livestream a game`

  return (
    <TextFormModal
      title={title}
      description={description}
      subTitle={subTitle}
      defaultOpen
      onClose={onClose}
      modalSize={TextFormModalSize.Large}
      parentStyle={styles.ModalWrapper}
    >
      <ActivationContent
        isMobile={isMobile}
        game={game}
        isAlreadyActivated={isAlreadyActivated}
        isLoading={isLoading}
        isError={isError}
        hasActivationValidationError={hasActivationValidationError}
        isSuccessfullyActivated={isSuccessfullyActivated}
        numberOfStreams={numberOfStreams}
        defaultPrices={defaultPrices}
        onActivateLivestream={onActivateLivestream}
        onClose={onClose}
      />
    </TextFormModal>
  )
}
