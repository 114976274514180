import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { mapGroupResponse } from './mapGroupsResponse'

export const getEventGroupsChildren = async ({
  eventSlug,
  eventClassSlug,
  slug
}: {
  eventSlug: string
  eventClassSlug: string
  slug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `events/event/${eventSlug}/groups`,
      params: {
        company: slug,
        parent: eventClassSlug
      }
    })
    const responseData = await resultResponse
    const mapedData = mapGroupResponse(responseData.data)
    return { data: mapedData, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
