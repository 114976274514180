import React, { FC } from 'react'

import { UPLOAD_TYPE } from 'components/DropFileZone/UploadType'
import { FormikProps } from 'formik'
import { FormDropFileZone } from 'components/DropFileZone/FormDropZone'

import styles from './FileSelector.module.scss'

interface Props {
  formik: FormikProps<any>
  formFieldName: string
  fileUrlFieldName: string
  recommendedImageHeight?: string
  recommendedImageWidth?: string
}

export const FileSelector: FC<Props> = ({
  formik,
  formFieldName,
  fileUrlFieldName,
  recommendedImageHeight,
  recommendedImageWidth
}) => (
  <div className={styles.LogosItem}>
    <FormDropFileZone
      formik={formik}
      type={UPLOAD_TYPE.IMAGE}
      formFieldName={formFieldName}
      fileUrlFieldName={fileUrlFieldName}
      recommendedImageHeight={recommendedImageHeight}
      recommendedImageWidth={recommendedImageWidth}
    />
  </div>
)
