import React, { FC, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { t } from '@lingui/macro'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useAuthentication from '../../../hooks/Authentication'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import { TopTextSection } from 'components/Modals/Sections/TopTextSection/TopTextSection'
import { Loader } from 'components/Loader/Loader'
import styles from './LoginCallback.module.scss'

export const LoginCallbackView: FC = () => {
  const { loggedIn, loggingIn, error } = useAuthentication()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const redirect =
    new URLSearchParams(location.search).get('redirect') || '/calendar'

  useEffect(() => {
    dispatch(authenticationActions.clearState())
    dispatch(authenticationActions.loginCallback())
  }, [])

  useEffect(() => {
    if (!loggingIn && loggedIn) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'account_login',
          method: 'apple',
          powered_by: window.location.hostname
        }
      })
      history.push(redirect)
    } else if (error) {
      history.push('/login')
    }
  }, [loggingIn, loggedIn, error])

  return (
    <div className={styles.Container}>
      <TopTextSection title={t`Authenticating with Apple ID`} subText={''} />
      {error && error.message && (
        <div className={styles.Error}>{error?.message}</div>
      )}
      <Loader />
    </div>
  )
}
