/* eslint-disable no-unused-expressions */
import { Fullscreen } from '@mui/icons-material'
import { Icon } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { isIOS } from 'react-device-detect'
import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'
import './index.scss'

// /**
//  * @typedef {object} FullscreenButton'
// */
type FullscreenButtonProps = {
  videoElement: HTMLVideoElement
}

// /**
//  * FullscreenButton
//  * @desc fullscreen for video player
//  * @category video components controls
//  *
//  * @example
//  * return (
//  *   <FullscreenButton videoElement={HTMLVideoElement} />
//  * )
//  */

const FullscreenButton: React.FC<FullscreenButtonProps> = ({
  videoElement
}) => {
  const { videoWrapper } = useContext(VideoPlayerContext)

  useEffect(() => {}, [videoElement, videoWrapper])

  const toggleFullscreen = () => {
    const element = videoWrapper.current
    if (element && element?.className.includes('fullscreen')) {
      const cancelFullscreen =
        document.exitFullscreen ||
        document.exitFullscreen ||
        (document as any).webkitCancelFullScreen ||
        (document as any).mozCancelFullScreen
      if (cancelFullscreen) {
        cancelFullscreen.call(document)
      }
    } else {
      if (isIOS && videoElement) {
        try {
          ;(videoElement as any).webkitEnterFullscreen()
        } catch (e) {
          console.log(e)
        }
      }
      if (!isIOS && element) {
        const requestFullscreen =
          element.requestFullscreen ||
          (element as any).webkitRequestFullscreen ||
          (element as any).mozRequestFullscreen ||
          (element as any).requestFullScreen ||
          (element as any).webkitRequestFullScreen ||
          (element as any).mozRequestFullScreen ||
          (element as any).webkitEnterFullscreen
        if (requestFullscreen) {
          try {
            requestFullscreen.call(element)
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
  }

  return (
    <button
      type="button"
      className="FullscreenButton PlayerButton"
      onClick={() => toggleFullscreen()}
    >
      <Icon
        component={Fullscreen}
        className="FullscreenIcon PlayerButtonIcon"
      />
    </button>
  )
}

export default FullscreenButton
