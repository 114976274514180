import React, { FC } from 'react'
import { t } from '@lingui/macro'
import Button, { ButtonType } from '../../../buttons/button/Button'

interface Props {
  id: string
  channelSlug: string
  onClick: (id: string, admin: boolean) => void
}
export const AdminNotPlannedButton: FC<Props> = ({
  onClick,
  channelSlug,
  id
}) => (
  <Button
    label={t`Prepare livestream`}
    buttonSize="Small"
    type={ButtonType.Primary}
    onClick={() =>
      onClick(
        `admin/games/${id}/livestream?select_company=${channelSlug}`,
        true
      )
    }
  />
)
