import { AnyAction } from 'redux'
import STREAM from './stream.constants'
import { VideoPlayerData } from 'features/viewerPage/viewerPage.interfaces'

export interface Stream {
  isLoading: boolean
  error: any
  voucherPaymentIsLoading: boolean
  voucherPaymentIsError: boolean
  data: VideoPlayerData | undefined
}

export const initialState = {
  isLoading: false,
  error: undefined,
  voucherPaymentIsLoading: false,
  voucherPaymentIsError: false,
  data: undefined
}

const stream = (state: Stream = initialState, action: AnyAction) => {
  const now = Math.floor(Date.now() / 1000)
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case STREAM.STREAM_FETCH_START:
      return {
        ...state,
        data: undefined,
        isLoading: true,
        isError: false
      }
    case STREAM.CLEAR_STREAM:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: undefined
      }
    case STREAM.STREAM_FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: undefined
      }
    case STREAM.STREAM_FETCH_ERROR:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: action.error
      }
    case STREAM.STREAM_MAKE_VOUCHER_PAYMENT_START:
      return {
        ...state,
        voucherPaymentIsLoading: true,
        voucherPaymentIsError: false
      }
    case STREAM.STREAM_MAKE_VOUCHER_PAYMENT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        },
        voucherPaymentIsLoading: false,
        voucherPaymentIsError: false
      }
    case STREAM.STREAM_MAKE_VOUCHER_PAYMENT_ERROR:
      return {
        ...state,
        voucherPaymentIsLoading: false,
        voucherPaymentIsError: true
      }
    case STREAM.INCREMENT_STREAM_USER_LIKE_COUNT:
      return {
        ...state,
        data: {
          ...state.data,
          mediaObject: {
            ...state.data?.mediaObject,
            user_like_counts: state.data!.mediaObject.user_like_counts + 1
          }
        }
      }

    case STREAM.DECREMENT_STREAM_USER_LIKE_COUNT:
      // eslint-disable-next-line no-case-declarations
      const newCount =
        !state?.data?.mediaObject ||
        state.data.mediaObject.user_like_counts === 0
          ? 0
          : state.data.mediaObject.user_like_counts - 1
      return {
        ...state,
        data: {
          ...state.data,
          mediaObject: {
            ...state.data?.mediaObject,
            user_like_counts: newCount
          }
        }
      }
    case STREAM.UPDATE_START_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          mediaObject: {
            ...state.data?.mediaObject,
            live_start_at: action.newStartTime
          }
        }
      }

    case STREAM.STREAM_IS_LIVE:
      return {
        ...state,
        data: {
          ...state.data,
          mediaObject: {
            ...state.data?.mediaObject,
            live_start_at: now
          }
        }
      }

    default:
      return state
  }
}

export default stream
