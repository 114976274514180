import { useEffect } from 'react'

interface Props {
  getVideoElement: any
  setVideoPlaying: (value: boolean) => void
  setVideoSeeking: (value: boolean) => void
  setCurrentVolume: (value: any) => void
  setDuration: (value: any) => void
  chromecast: any
  adsDone: boolean
}

export const usePlayerControl = ({
  getVideoElement,
  setVideoPlaying,
  setVideoSeeking,
  setCurrentVolume,
  setDuration,
  chromecast,
  adsDone
}: Props) => (
  useEffect(() => {
    const onPlay = () => {
      setVideoPlaying(true)
      setVideoSeeking(false)
    }
    const onSeek = () => {
      setVideoSeeking(true)
    }
    const onSeeked = () => {
      setVideoSeeking(false)
    }
    const onPause = () => {
      setVideoPlaying(false)
    }
    const onEnded = () => {
      setVideoPlaying(false)
    }
    const onVolumeChange = (e: any) => {
      setCurrentVolume(e.currentTarget.volume)
    }
    const onDurationChange = (e: any) => {
      setDuration(e.currentTarget.duration)
    }
    const element = getVideoElement()
    element.addEventListener('volumechange', onVolumeChange)
    if (adsDone && !chromecast.connected) {
      element.addEventListener('playing', onPlay)
      element.addEventListener('seeking', onSeek)
      element.addEventListener('seeked', onSeeked)
      element.addEventListener('pause', onPause)
      element.addEventListener('ended', onEnded)
      element.addEventListener('durationchange', onDurationChange)
      setDuration(getVideoElement().duration)
    }
    return () => {
      element.removeEventListener('volumechange', onVolumeChange)
      if (adsDone && !chromecast.connected) {
        if (element) {
          element.removeEventListener('play', onPlay)
          element.removeEventListener('seeking', onSeek)
          element.removeEventListener('seeked', onSeeked)
          element.removeEventListener('pause', onPause)
          element.removeEventListener('ended', onEnded)
          element.removeEventListener('durationchange', onDurationChange)
        }
      }
    }
  }, [chromecast.connected, adsDone]),
  []
)
