import React, { FC } from 'react'
import Button, { ButtonType } from 'components/buttons/button/Button'
import animationData from './succes-animation.json'
import styles from './SuccessModal.module.scss'
import Lottie from 'lottie-react'
import classNames from 'classnames'
interface Props {
  title: string
  subText: string
  buttonLabel: string
  onButtonClick: () => void
  customStyle?: string | null
}

export const SuccessContent: FC<Props> = ({
  title,
  subText,
  buttonLabel,
  onButtonClick,
  customStyle
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData
  }
  return (
    <div
      className={classNames(styles.ModalContent, {
        [customStyle || '']: customStyle
      })}
      data-testid="success-modal"
    >
      <div className={styles.SuccessAnimationContainer}>
        <Lottie {...defaultOptions} />
      </div>
      <div className={styles.TextContent}>
        <h1 data-testid="success-modal-title" className={styles.Title}>
          {title}
        </h1>
        <div data-testid="success-modal-sub-text" className={styles.SubText}>
          {subText}
        </div>
      </div>
      <div className={styles.ButtonContainer}>
        <Button
          type={ButtonType.Secondary}
          label={buttonLabel}
          roleType="button"
          onClick={onButtonClick}
          buttonSize="Medium"
        />
      </div>
    </div>
  )
}
