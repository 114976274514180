import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'
import { useDataApi } from '../../../../api/useDataApi'
import { getChannel } from '../../../../api/channel/getChannel'
import { BackButton } from '../../../../components/page/header/SettingsContentHeader/BackButton/BackButton'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import styles from './CompetitionSelectorHeader.module.scss'
import { getHeaderDescription } from './getHeaderDescription'
import { getHeaderTitle } from './getHeaderTitle'

interface Props {
  channelType: ChannelTypes
  channelSlug: string
  selectedTeamSlug: string
}
export const CompetitionSelectorHeader: FC<Props> = ({
  channelType,
  channelSlug,
  selectedTeamSlug
}) => {
  const history = useHistory()

  const selectedChannel = useDataApi({
    apiCall: useCallback(getChannel, []),
    parameters: selectedTeamSlug
  })
  const teamName = selectedChannel?.data?.name

  const handleOnBack = () => {
    if ([ChannelTypes.team, ChannelTypes.league].includes(channelType)) {
      history.push(`/${channelSlug}/contentmanager/competitions`)
    } else
      history.push(
        `/${channelSlug}/contentmanager/teams/${selectedTeamSlug}/competitions`
      )
  }
  const useSelectedTeamFromClub =
    window.location.pathname.indexOf('teams') !== -1

  const headerTitle = getHeaderTitle(
    channelType,
    useSelectedTeamFromClub,
    teamName
  )
  const headerDescription = getHeaderDescription(
    channelType,
    useSelectedTeamFromClub,
    teamName
  )

  return (
    <div className={styles.CompetitionSelectorHeader}>
      <BackButton onBack={handleOnBack} label={t`Competitions`} />
      <h4 className={styles.CompetitionSelectorHeaderTitle}>{headerTitle}</h4>
      <div className={styles.CompetitionSelectorHeaderDescription}>
        {headerDescription}
      </div>
    </div>
  )
}
