import { getRedirect as getRedirectDetails } from './app/getRedirect'
import store from './redux-store/store'
import { redirectActionCreator } from './redux-store/redirect/redirect.action'
import { FeatureFlagsAvailable } from './featureFlags/FeatureFlagsAvailable'

export const getIsSpecialDomain = (hostname: string) => {
  const specialCaseDomains = [
    'solidsport.com',
    'sportstage.se',
    'local.sportstage.se'
  ]
  return specialCaseDomains.includes(hostname)
}

export const getCustomChannelUrl = async (
  location: {
    href: string
    hostname: string
    pathname: string
    port: string
  },

  now: number,
  getFlag: (flag: FeatureFlagsAvailable) => boolean,
  setFlag: (flag: FeatureFlagsAvailable, value: boolean) => void
): Promise<string | any> => {
  const { hostname, href, port } = location

  const domain = port !== '' ? `${hostname}:${port}` : hostname
  await store.dispatch(
    // @ts-ignore
    await redirectActionCreator(domain, href)
  )
  // @ts-ignore
  const redirectData = store.getState().redirect.data

  const redirectDetails = getRedirectDetails(
    redirectData,
    now,
    hostname,
    setFlag,
    location.port === '3000' && location.hostname !== 'prby.sportstage.se'
  )

  const noRedirectIfNotFound = location.href.includes('404')
  if (noRedirectIfNotFound) {
    return null
  }

  return redirectDetails
}
