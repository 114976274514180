import React, { FC } from 'react'

import styles from '../ThemedFooter.module.scss'
import { t } from '@lingui/macro'

interface Props {
  contactInfo?: { address?: string; email: string; phone: string }
}

export const ContactInfo: FC<Props> = ({ contactInfo }) => {
  const contacts = contactInfo || { address: undefined, phone: undefined }

  if (Object.values(contacts).length === 0) return null
  const { address, phone /*, email */ } = contacts
  const contactInfoArray = [
    { contact: address, tag: 'span' },
    { contact: phone, tag: 'span' }
    //  { contact: email, tag: 'a' }
  ]

  const contactData = contactInfoArray
    .filter((info: any) => info.contact && info.contact !== '')
    .map((contact: any) =>
      contact.tag === 'span' ? (
        <span key={contact.contact}>{t`(tel) ${contact.contact}`}</span>
      ) : (
        <a key={contact.contact} href={`mailto:${contact.contact}`}>
          {contact.contact}
        </a>
      )
    )

  return (
    <div className={styles.ChannelContactInfo}>
      {contactData.length > 0 &&
        contactData
          .map<React.ReactNode>((t) => <span key={t.key}>{t}</span>)
          .reduce((prev, curr) => [prev, ' | ', curr])}
    </div>
  )
}
