import { t } from '@lingui/macro'
import { AccessRestrictionType } from 'interfaces'

export const getLabelForAccessRestrictionType = (
  accessType: AccessRestrictionType
) => {
  switch (accessType) {
    case AccessRestrictionType.LoginRequired:
      return t`Login required`
    case AccessRestrictionType.PaymentAndSubscriptionRequired:
      return t`Payment and subscription`
    case AccessRestrictionType.SubscriptionRequired:
      return t`Subscription required`
    case AccessRestrictionType.PaymentRequired:
      return t`Payment required`
  }
}
export const getListOfAccessRestrictionsAvailable = (
  accessAvailables: AccessRestrictionType[]
) =>
  accessAvailables?.map((access: AccessRestrictionType) => ({
    label: getLabelForAccessRestrictionType(access),
    id: access
  }))
