import React, { FC } from 'react'
import { Locale, ThemedFooter } from './themedFooter/ThemedFooter'
import { DefaultFooter } from './defaultFooter/DefaultFooter'

interface Props {
  channelDataIsReady: boolean
  isRootOrChild: boolean
  companyName: string
  locales: Locale[]
}

export const Footer: FC<Props> = ({
  channelDataIsReady,
  isRootOrChild,
  companyName,
  locales
}) => {
  return (
    <>
      {isRootOrChild && channelDataIsReady && (
        <ThemedFooter languages={locales} companyName={companyName} />
      )}
      {!isRootOrChild && (
        <DefaultFooter companyName={companyName} languages={locales} />
      )}
    </>
  )
}
