export const ResizeHeroImageUrl = (
  url: string,
  innerWidth: number,
  devicePixelRatio: number
) => {
  let newUrl = url
  const pattern = 'resize1280x720'
  const pixelRatio = devicePixelRatio || 1
  const sixteenNineRatio = 1.7777777778
  const width = Number(innerWidth * pixelRatio).toFixed(0)
  const height = Number((innerWidth / sixteenNineRatio) * pixelRatio).toFixed(0)
  const size = `resize${width}x${height}`
  newUrl = url.replace(pattern, size)
  return newUrl
}
