import React, { FC } from 'react'
import googlePlay from '../../../Schedule/ActivateLivestream/StoreLinks/google-play.svg'
import appStore from '../../../Schedule/ActivateLivestream/StoreLinks/app-store.svg'
import styles from './CompanionAppLinks.module.scss'

interface Props {
  appStoreLink: string
  playStoreLink: string
}
export const CompanionAppLinks: FC<Props> = ({
  appStoreLink,
  playStoreLink
}) => {
  return (
    <div className={styles.CompanionAppLinks}>
      {playStoreLink && (
        <a target="_blank" rel="noopener noreferrer" href={playStoreLink}>
          <img src={googlePlay} alt="Google Play" />
        </a>
      )}
      {appStoreLink && (
        <a target="_blank" rel="noopener noreferrer" href={appStoreLink}>
          <img src={appStore} alt="App Store" />
        </a>
      )}
    </div>
  )
}
