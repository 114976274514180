import { LoginLayerType } from 'components/session/ModalContent/ModalContent'
import AUTHENTICATION from './authentication.constants'

const authenticationActions = {
  login,
  loginCallback,
  facebookLogin,
  logout,
  register,
  sendEmail,
  openLoginLayer,
  closeLoginLayer,
  resetLoginState,
  clearState,
  logoutWithoutMiddleware,
  resetFromLocalState
}
export default authenticationActions

function login(email: string, password: string) {
  return {
    type: AUTHENTICATION.LOGIN_REQUEST,
    user: { email, password }
  }
}

function loginCallback() {
  return {
    type: AUTHENTICATION.LOGIN_CALLBACK
  }
}

function facebookLogin(
  id: string,
  email: string,
  first_name: string = '',
  last_name: string = '',
  access_token: string,
  company?: string | undefined
) {
  return {
    type: AUTHENTICATION.LOGIN_REQUEST,
    user: { id, email, first_name, last_name, access_token, company }
  }
}

function resetLoginState() {
  return {
    type: AUTHENTICATION.RESET_LOGIN_STATE
  }
}

function logout() {
  // Moved to middleware to end dependancy cycle
  return { type: AUTHENTICATION.LOGOUT }
}

function logoutWithoutMiddleware() {
  return { type: AUTHENTICATION.LOGOUT_WITHOUT_MIDDLEWARE }
}

function register(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  signupToken?: string | undefined,
  emailConsent?: boolean
) {
  return {
    type: AUTHENTICATION.REGISTER_REQUEST,
    user: { firstName, lastName, email, password, signupToken, emailConsent }
  }
}

function sendEmail(email: string) {
  return {
    type: AUTHENTICATION.SEND_EMAIL_REQUEST,
    user: { email }
  }
}

function openLoginLayer(page?: string, mode?: LoginLayerType) {
  return {
    type: AUTHENTICATION.LOGIN_LAYER_OPEN,
    page: page,
    mode: mode
  }
}

function closeLoginLayer() {
  return {
    type: AUTHENTICATION.LOGIN_LAYER_CLOSE
  }
}

function clearState() {
  return {
    type: AUTHENTICATION.CLEAR_STATE
  }
}

function resetFromLocalState() {
  return {
    type: AUTHENTICATION.RESET_FROM_LOCAL_STATE
  }
}
