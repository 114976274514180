import React, { FC } from 'react'
import { FacebookLoginButton } from 'components/buttons/Facebook/FacebookLoginButton/FacebookLoginButton'
import {
  getIsAppleLoginAvailable,
  getIsFacebookLoginAvailable
} from 'components/session/getIsLoginAvailable'
import { AppleLoginButton } from 'components/buttons/Apple/AppleLoginButton/AppleLoginButton'
import { Divider } from './Divider'
import styles from './OtherLoginOptions.module.scss'

interface Props {
  showDivider: boolean
}
export const OtherLoginOptions: FC<Props> = ({ showDivider }) => {
  const isFacebookLoginAvailable = getIsFacebookLoginAvailable()
  const isAppleLoginAvailable = getIsAppleLoginAvailable()
  if (!isFacebookLoginAvailable && !isAppleLoginAvailable) {
    return null
  }

  return (
    <div className={styles.OtherLoginOptionsContainer}>
      {showDivider && <Divider />}
      <div className={styles.Buttons}>
        {isAppleLoginAvailable && <AppleLoginButton />}
        {isFacebookLoginAvailable && <FacebookLoginButton />}
      </div>
    </div>
  )
}
