import React, { FC } from 'react'

export const SearchIcon: FC = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.44019 13.9167C7.0625 15.381 8.24102 16.5381 9.71648 17.1335C11.1919 17.7288 12.8435 17.7137 14.3078 17.0914C15.7721 16.4691 16.9292 15.2906 17.5246 13.8151C18.12 12.3396 18.1048 10.6881 17.4825 9.22379C16.8602 7.75949 15.6817 6.60237 14.2062 6.007C12.7308 5.41162 11.0792 5.42675 9.61491 6.04907C8.15061 6.67138 6.99349 7.8499 6.39812 9.32536C5.80274 10.8008 5.81787 12.4524 6.44019 13.9167V13.9167Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2031 15.8115L20.8558 20.4649"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
