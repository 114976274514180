import React, { FC } from 'react'
import styles from './SearchResultCard.module.scss'

interface Props {
  list: string[]
}

const SpacedDivider: FC<Props> = ({ list }) => {
  return (
    <div data-testid="space-divider-container">
      {list.map((text, index) =>
        text && text !== '' ? (
          <span
            data-testid="spaced-list-text"
            key={text}
            className={styles.SpacedDivider}
          >
            {text}
          </span>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} />
        )
      )}
    </div>
  )
}

export default SpacedDivider
