import React, { FC } from 'react'
import styles from './ReceiptContent.module.scss'
interface Props {
  descriptionLabel: string
  content: string | number
}

export const ReceiptItem: FC<Props> = ({ descriptionLabel, content }) => (
  <div className={styles.ReceiptItem}>
    <div className={styles.DescriptionLabel}>{descriptionLabel}</div>
    <div className={styles.Content}>{content}</div>
  </div>
)
