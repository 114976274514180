import { LinkTarget } from 'interfaces/LinkTarget.interface'
import TagManager from 'react-gtm-module'

const triggerGTMClickHelpButton = (
  channelName?: string,
  streamName?: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'viewer_page_help_button_clicked',
      channel_name: channelName,
      stream_name: streamName
    }
  })
}

export const onHelpClick = (channelName?: string, streamName?: string) => {
  const helpUrl = process.env.REACT_APP_SUPPORT_URL
  if (streamName) {
    triggerGTMClickHelpButton(channelName, streamName)
  }
  if (helpUrl) {
    window.open(helpUrl, LinkTarget.NewWindow)
  }
}
