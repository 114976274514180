import React, { FC, useContext } from 'react'
import IconTextButton, { ButtonType } from '../TextIconButton'
import { t } from '@lingui/macro'
import { FastForwardIcon } from 'assets/icons/FastForwardIcon'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { VideoPlayerContext } from 'providers/VideoPlayerProvider/VideoPlayerProvider'

export const GoLiveButton: FC = () => {
  const { seek, playerState, chromecast } = useContext(VideoPlayerContext)
  const { duration, isLive, currentTime, isDvr } = playerState
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm

  const showGoLiveButton =
    !chromecast.connected && isLive && isDvr && currentTime < duration - 180

  if (!showGoLiveButton) {
    return <></>
  }

  const setProgress = () => {
    seek(duration - 20)
  }

  return (
    <div className="GoLiveButton">
      <IconTextButton
        id={'go-live-button'}
        type={ButtonType.Ghost}
        buttonSize={isMobile ? 'Small' : 'Medium'}
        label={t`Go to live`}
        onClick={setProgress}
      >
        <FastForwardIcon />
      </IconTextButton>
    </div>
  )
}
