import React, { FC } from 'react'
import Button, { ButtonType } from '../../buttons/button/Button'
import { SubmitButton } from '../../buttons/SubmitButton/SubmitButton'
import styles from './FormActionButtons.module.scss'

interface Props {
  formik: any
  cancelButtonLabel: string
  submitButtonLabel: string

  onCancel: () => void
}
export const FormActionButtons: FC<Props> = ({
  formik,
  cancelButtonLabel,
  submitButtonLabel,

  onCancel
}) => {
  return (
    <div className={styles.ButtonContainer}>
      <Button
        buttonSize="Medium"
        type={ButtonType.GhostWithBoarder}
        label={cancelButtonLabel}
        onClick={onCancel}
      />
      <SubmitButton
        type="secondary"
        customStyle={styles.SubmitButton}
        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
        label={submitButtonLabel}
      />
    </div>
  )
}
