/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import { Loader } from '../Loader/Loader'
import styles from './LoggingInLoadingOverlay.module.scss'

interface LoggingInLoadingOverlayProps {
  loggingIn?: boolean
}

export const LoggingInLoadingOverlay: FC<LoggingInLoadingOverlayProps> = ({
  loggingIn
}) => {
  return loggingIn ? (
    <div className={styles.LoggingInLoadingOverlay}>
      <Loader />
    </div>
  ) : null
}
