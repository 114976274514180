import React, { FC, useEffect, useState } from 'react'
import { DateSelectorButtons } from 'features/multistageTournaments/DateSelectorButtons/DateSelectorButtons'
import styles from './DateFilteredContent.module.scss'
import { EventDate } from 'api/multistageTournament/getEventDates'
import { getEventGamesFromDates } from 'api/multistageTournament/getEventGamesFromDate'
import { EventCardList } from 'features/multistageTournaments/EventCardList/EventCardList'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'
import { Loader } from 'components/Loader/Loader'
import { useQuery } from '@tanstack/react-query'
import { Trans, t } from '@lingui/macro'
import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'

interface Props {
  dateSelected: EventDate
  now: number
  onCalendarClick: () => void
  onResetFilterClick: () => void
  eventSlug: string
  channelSlug: string
}
const PER_PAGE_COUNT = 25

export const DateFilteredContent: FC<Props> = ({
  dateSelected,
  now,
  onCalendarClick,
  onResetFilterClick,
  eventSlug,
  channelSlug
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [dataToDisplay, setDataToDisplay] = useState<
    GameScheduleEventCardType[]
  >([])
  const {
    data,
    isLoading,
    isError
  }: {
    data: any | undefined
    isLoading: boolean
    isError: boolean
  } = useQuery({
    // @ts-ignore
    queryKey: [`get-event-games-from-date-${dateSelected.slug}`, currentPage],

    queryFn: () =>
      getEventGamesFromDates({
        channelSlug: channelSlug,
        eventSlug: eventSlug,
        selectedDate: dateSelected.slug,
        currentPage: currentPage,
        perPage: PER_PAGE_COUNT
      })
  })
  useEffect(() => {
    setDataToDisplay([])
  }, [dateSelected.slug])

  useEffect(() => {
    if (!isLoading && !isError && data && data.data) {
      if (data.data?.length > 0 && currentPage < 2) {
        setDataToDisplay(data.data)
      } else {
        setDataToDisplay((prev) => [...prev, ...data.data])
      }
      setPageCount(Math.ceil(data.totalCount / PER_PAGE_COUNT))
    }
  }, [data, currentPage])
  return (
    <div className={styles.Container}>
      <div className={styles.DateButtonsSection}>
        <DateSelectorButtons
          dateSelected={dateSelected.name}
          onCalendarClick={onCalendarClick}
          onResetFiltersClick={onResetFilterClick}
        />
      </div>
      {dateSelected && isError && (
        <Trans>
          The data for the selected date could not be retrived, please reload
          the page and try again
        </Trans>
      )}

      {dateSelected && dataToDisplay.length < 1 && isLoading ? (
        <Loader />
      ) : (
        <div className={styles.GameListContainer}>
          <EventCardList
            type={FilteredGamesListType.Secondary}
            now={now}
            eventList={dataToDisplay}
            totalPages={pageCount}
            currentPage={currentPage}
            isLoading={isLoading}
            increasePageNumber={() =>
              setCurrentPage((prevValue) => prevValue + 1)
            }
            emptyListMessageOverride={t`There are no games available to show for the selected date right now, please check back soon to see an updated schedule`}
          />
        </div>
      )}
    </div>
  )
}
