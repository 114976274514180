import PAYMENT from './payments.constants'

const paymentActions = {
  openPayment: (payload: {}) => {
    return {
      type: PAYMENT.OPEN_PAYMENT,
      regards: payload
    }
  },
  paymentSuccess: (payload: any) => {
    return {
      type: PAYMENT.PAYMENT_SUCCESS,
      regards: payload
    }
  },
  alreadyPurchased: (payload: any) => {
    return {
      type: PAYMENT.ALREADY_PURCHASED,
      regards: payload
    }
  },
  hasGiftCard: (giftCardReferenceId: any) => {
    return {
      type: PAYMENT.HAS_GIFT_CARD,
      giftCardReferenceId
    }
  },
  paymentFailed: () => {
    return {
      type: PAYMENT.PAYMENT_FAILED
    }
  },
  paymentOngoing: () => {
    return {
      type: PAYMENT.PAYMENT_ONGOING
    }
  },
  closePayment: () => {
    return {
      type: PAYMENT.CLOSE_PAYMENT
    }
  },
  retryPayment: () => {
    return {
      type: PAYMENT.RETRY_PAYMENT
    }
  },
  toggleVoucherPayment: (open: boolean) => {
    return {
      type: PAYMENT.VOUCHER_OPEN,
      open
    }
  }
}

export default paymentActions
