import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getSubdomainSearch = async (searchTerm: string): Promise<any> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `search/subdomains/?query=${searchTerm}&page=1&per_page=10`
    })
    const responseData = await resultResponse
    return responseData.data
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
