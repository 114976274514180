import { t } from '@lingui/macro'

export const getPeriod = (timeframe: string) => {
  switch (timeframe) {
    case 'monthly':
      return t`month`
    case 'yearly':
      return t`year`
    default:
      return ''
  }
}
