import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from '../../app/App'
import SolidsportRedirect from './SolidsportRedirect'
import { HandleUtmSource } from './HandleUtmSource'

export const BaseRoutes = () => {
  HandleUtmSource()
  return (
    <Switch>
      <Route exact path={['/']}>
        <SolidsportRedirect />
      </Route>
      <Route
        path={[
          '/comingsoon',
          '/livestreams',
          '/landing',
          '/broadcast',
          '/browse',
          '/calendar',
          '/search',
          '/login',
          '/profile',
          '/access_invite',
          '/user',
          '/404',
          '/:company?'
        ]}
      >
        <App />
      </Route>
    </Switch>
  )
}
