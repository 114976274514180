import { AnyAction } from 'redux'
import REDIRECT from './redirect.constants'

export interface Redirect {
  isLoading: boolean
  isError: boolean
  data: any[]
}

export const initialState = {
  isLoading: false,
  isError: false,
  data: []
}

const redirect = (state: Redirect = initialState, action: AnyAction) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case REDIRECT.REDIRECT_FETCH_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      }

    case REDIRECT.REDIRECT_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const withDate = {
        ...action.data,
        unixReleaseDate:
          action.data?.releaseDate &&
          new Date(action.data?.releaseDate).getTime() / 1000
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: withDate
      }
    case REDIRECT.REDIRECT_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: []
      }
    default:
      return state
  }
}

export default redirect
