/* eslint-disable  */
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useChannelTheme } from './useChannelTheme'
import useCompanyParam from '../../../hooks/CompanyParam'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { ThemeOptions } from '../../../redux-store/channelTheme/channelTheme.interface'
import { applyTheme } from './applyTheme'

import cssTheme from '../channel.module.scss'

let lastTheme: ThemeOptions | undefined = undefined

export const withTheme =
  (WrappedComponent: FC<any>) =>
  ({ ...props }) => {
    const { channelTheme, channelThemeIsLoading } = useChannelTheme()
    const channel = useSelector(channelSelector)
    const company = useCompanyParam()
    useEffect(() => {
      const channelWithThemeReady =
        channel.data &&
        channelTheme &&
        channelTheme?.channelPrimaryColor! &&
        !channelThemeIsLoading
      const noChannelWithDefaultThemeReady =
        channelTheme?.channelPrimaryColor! && !channelThemeIsLoading && !company
      let themeChanged = true
      if (channelTheme && lastTheme) {
        try {
          themeChanged =
            JSON.stringify(channelTheme) !== JSON.stringify(lastTheme)
        } catch (e) {
          console.log(e)
        }
      }
      if (
        (channelWithThemeReady || noChannelWithDefaultThemeReady) &&
        themeChanged
      ) {
        applyTheme(channelTheme)
      }
      if (channelTheme && channelTheme?.channelPrimaryColor!) {
        lastTheme = channelTheme
      }
    }, [channelTheme, channelThemeIsLoading, channel, company])

    return (
      <div className={cssTheme.Theme}>
        <WrappedComponent {...props} />
      </div>
    )
  }
