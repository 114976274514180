import React from 'react'

export const SearchIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.23043 10.6665C5.68302 11.7315 6.54012 12.573 7.61319 13.006C8.68625 13.439 9.88737 13.428 10.9523 12.9754C12.0173 12.5228 12.8588 11.6657 13.2918 10.5926C13.7248 9.51958 13.7138 8.31846 13.2612 7.25351C12.8086 6.18857 11.9515 5.34703 10.8785 4.91402C9.80539 4.48102 8.60427 4.49203 7.53932 4.94462C6.47437 5.39721 5.63283 6.25432 5.19983 7.32738C4.76683 8.40044 4.77783 9.60157 5.23043 10.6665V10.6665Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3306 12.0448L15.7144 15.4291"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
