import React from 'react'

const SheildIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25003 3.92301V11.537C2.25005 13.9437 2.97935 16.2938 4.34179 18.2777C5.70423 20.2615 7.63585 21.7859 9.88203 22.65L10.923 23.05C11.6163 23.3167 12.3838 23.3167 13.077 23.05L14.118 22.65C16.3642 21.7859 18.2958 20.2615 19.6583 18.2777C21.0207 16.2938 21.75 13.9437 21.75 11.537V3.92301C21.7519 3.63649 21.671 3.35552 21.5171 3.1139C21.3631 2.87227 21.1426 2.68028 20.882 2.56101C18.0819 1.3388 15.0552 0.72167 12 0.750011C8.94489 0.72167 5.9182 1.3388 3.11803 2.56101C2.85751 2.68028 2.63699 2.87227 2.48301 3.1139C2.32902 3.35552 2.24812 3.63649 2.25003 3.92301V3.92301Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SheildIcon
