import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { BackButton } from './BackButton/BackButton'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'

import SettingsIcon from '../../../../pages/ChannelPage/Header/Images/SettingsIcon'
import styles from './SettingsContentHeader.module.scss'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'
import { Tooltip, TooltipTheme } from 'components/Tooltip/Tooltip'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

interface Props {
  title: string
  buttonLabel: string
  href: string
}
export const SettingsContentHeader: FC<Props> = ({
  title,
  buttonLabel,
  href
}) => {
  const hideAdvancedSettingTooltip = 'hideAdvancedSettingTooltip'
  const [showToolTip, setShowToolTip] = useState(false)
  const currentBreakpoint = useCurrentBreakpoint()
  const buttonSize = currentBreakpoint <= Breakpoint.sm ? 'Small' : 'Medium'
  const channel = useSelector(channelSelector)
  const isRootChannel = channel?.data?.is_root_channel
  const isChildOfRootChannel = channel?.data?.is_child_of_root_channel
  const isRootOrChild = isRootChannel | isChildOfRootChannel

  useEffect(() => {
    if (!localStorage.getItem(hideAdvancedSettingTooltip)) {
      setShowToolTip(true)
    }
  }, [])

  const handleOnConfirmTooltipClick = () => {
    localStorage.setItem(hideAdvancedSettingTooltip, 'true')
    setShowToolTip(false)
  }

  return (
    <div className={styles.SettingsContentHeader}>
      <div className={styles.TopContainer}>
        <BackButton label={t`Back to channel`} />
        <div className={styles.SettingsButtonWrapper}>
          <LinkIconButton
            id="settings"
            label={buttonLabel}
            type={ButtonType.Ghost}
            buttonSize={buttonSize}
            href={href}
          >
            <SettingsIcon />
          </LinkIconButton>
          {showToolTip && !isRootOrChild && (
            <div className={styles.TooltipWrapper}>
              <Tooltip
                title={t`Advanced settings`}
                text={t`Here you can find the old admin page.`}
                position="Bottom"
                pointerPosition="Right"
                confirmButtonText={t`Got it`}
                onConfirmButtonClick={handleOnConfirmTooltipClick}
                theme={TooltipTheme.Light}
              />
            </div>
          )}
        </div>
      </div>
      <h1 className={styles.Title}>{title}</h1>
    </div>
  )
}
