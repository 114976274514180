import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface ConnectLeagueRequest {
  competitionId: string
  channelSlug: string
}
interface Response {
  hasError: boolean
  errors?: any
}
export const putConnectedCompetitionGamesToLeague = async (
  request: ConnectLeagueRequest
): Promise<Response> => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.channelSlug}/competitions/${request.competitionId}/connect_channel`,
      method: ApiEndpoint.METHODS.PUT,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
