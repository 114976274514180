import React, { FC } from 'react'
import classNames from 'classnames'
import ArrowDown from './ArrowDown'
import styles from './Field.module.scss'

interface Props {
  selected: string
  onClick: () => void
  rotateArrow: boolean
  className?: string
}
const Field: FC<Props> = ({ selected, onClick, rotateArrow, className }) => (
  <div
    role="button"
    onClick={() => onClick()}
    className={classNames(styles.Field, className)}
    onKeyPress={() => onClick()}
    tabIndex={0}
  >
    <div className={styles.Label}>{selected}</div>
    <div
      className={classNames(styles.Arrow, {
        [styles.ArrowUp]: rotateArrow,
        [styles.ArrowDown]: !rotateArrow
      })}
    >
      <ArrowDown />
    </div>
  </div>
)

export default Field
