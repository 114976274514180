import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { IconButton } from '@mui/material'
import CloseIcon from './CloseIcon'
import RoundIconButton, { Scheme } from '../buttons/RoundIconButton/index'

import { useCurrentBreakpoint, Breakpoint } from '../../hooks/CurrentBreakpoint'
import { Subscription } from './SubscriptionBanner/Subscription'
import { EventPass } from './EventPassBanner/EventPass'
import styles from './Banner.module.scss'

export enum BannerType {
  SubscriptionBanner,
  EventPassBanner
}

interface Props {
  isSupporter: boolean
  onBannerClose: () => void
  onBannerClick: (destination: string) => void
  channelName: string
  channelSlug: string
  bannerType: BannerType
}

export const Banner: FC<Props> = ({
  isSupporter,
  onBannerClose,
  onBannerClick,
  channelName,
  channelSlug,
  bannerType
}) => {
  const breakPoint = useCurrentBreakpoint()
  const history = useHistory()

  const submenuPath =
    bannerType !== BannerType.SubscriptionBanner ? 'access' : 'subscriptions'

  const path = `/${channelSlug}/${submenuPath}`
  const handleBannerClick = () => {
    onBannerClick(path)
    history.push(path)
  }

  return (
    <div className={styles.Banner}>
      <Link to={path}>
        <div className={styles.IconAndTitleAndPriceTextAndDescription}>
          {bannerType !== BannerType.SubscriptionBanner ? (
            <EventPass isSupporter={isSupporter} />
          ) : (
            <Subscription isSupporter={isSupporter} channelName={channelName} />
          )}
        </div>
      </Link>
      <div className={styles.Button}>
        {isSupporter ? (
          <IconButton className={styles.CloseButton} onClick={onBannerClose}>
            <CloseIcon
              height={breakPoint <= Breakpoint.mobile ? '16px' : '24px'}
              width={breakPoint <= Breakpoint.mobile ? '16px' : '24px'}
            />
          </IconButton>
        ) : (
          <RoundIconButton
            onClick={handleBannerClick}
            iconSize={breakPoint <= Breakpoint.mobile ? 'small' : 'medium'}
            newClassName={Scheme.Black}
          />
        )}
      </div>
    </div>
  )
}
