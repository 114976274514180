import { LinkTarget } from 'interfaces/LinkTarget.interface'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface Props {
  customLinkText: string
  customLinkHref: string
  customLinkTarget: LinkTarget
  channelSlug: string
}
interface ProductTypeResponse {
  hasError: boolean
  errors?: any
}
export const putCustomTopNavigationLink = async (
  params: Props
): Promise<ProductTypeResponse> => {
  const formData = new FormData()
  formData.append('custom_link_text', params.customLinkText)
  formData.append('custom_link_href', params.customLinkHref)
  formData.append('custom_link_target', params.customLinkTarget)

  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${params.channelSlug}/custom_link`,
      method: ApiEndpoint.METHODS.PUT,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
