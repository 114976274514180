import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface CreateTeamRequest {
  name: string
  clubChannelSlug: string
  teamChannelSlug: string
}
interface Response {
  hasError: boolean
  errors?: any
}
export const postCreateClubTeamChannel = async (
  request: CreateTeamRequest
): Promise<Response> => {
  try {
    const params = {
      channel_name: request.name,
      channel_slug: request.teamChannelSlug
    }

    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.clubChannelSlug}/channels/create_club_team_channel`,
      method: ApiEndpoint.METHODS.POST,
      params
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
