import { MutableRefObject, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import useInfiniteScroll from '../../../hooks/InfiniteScroll'

import { channelSelector } from 'redux-store/channel/channel.selectors'

export const useFetchChannelSliderData = (
  sliderName: string,
  channelSlug: string,
  sliderBottomRef: null | MutableRefObject<Element | null | undefined>,
  apiCall: any,
  inView: boolean
) => {
  const PER_PAGE_DATA = 10
  const [page, setPage] = useState(1)
  const channel = useSelector(channelSelector)
  const isBottomReached = useInfiniteScroll(sliderBottomRef)

  const [dataToReturn, setDataToReturn] = useState<any[]>([])

  const {
    data,
    isLoading,
    error,
    isFetching
  }: {
    data: any | undefined
    isLoading: boolean
    error: any
    isFetching: boolean
  } = useQuery({
    queryKey: [`get-sliders-start-page-${sliderName}`, page, inView],

    queryFn: () =>
      apiCall({
        slug: channelSlug,
        perPageCount: PER_PAGE_DATA,
        page: page,
        currentChannel: channel.data
      }),

    enabled: inView,
    initialData: []
  })

  useEffect(() => {
    if (inView) {
      if (isBottomReached && data && data?.totalCount > page * PER_PAGE_DATA) {
        setPage(page + 1)
      }
    }
  }, [inView, isBottomReached, data?.data, data?.totalCount, page])
  useEffect(() => {
    if (!isLoading && data?.data) {
      setDataToReturn((prev: any[]) => [...prev, ...data.data])
    }
  }, [data, isLoading])
  return {
    data: dataToReturn,
    isLoading: isLoading || isFetching,
    isError: error
  }
}
