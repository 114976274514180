import React, { FC, useState } from 'react'
import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import { ForgotPasswordContent } from './ForgotPasswordContent'
import { SendConfirmationContent } from './SendConfirmationContent'

interface Props {
  isOpen: boolean
  onLoginClick: () => void
  onSignupClick: () => void
  onSubmitFormSuccess: () => void
  onClose: () => void
}

export const ForgotPasswordModal: FC<Props> = ({
  isOpen,
  onLoginClick,
  onSignupClick,
  onSubmitFormSuccess,
  onClose
}) => {
  const [showSuccessContent, setShowSuccessContent] = useState(false)
  const contentToShow = showSuccessContent ? (
    <SendConfirmationContent onAnimationEnd={onSubmitFormSuccess} />
  ) : (
    <ForgotPasswordContent
      onLoginClick={onLoginClick}
      onSignupClick={onSignupClick}
      onSubmitFormSuccess={() => setShowSuccessContent(true)}
    />
  )
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={onClose}
      firstContainerContent={contentToShow}
      firstContainerTheme={ModalTheme.Primary}
    />
  )
}
