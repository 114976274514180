import React, { FC } from 'react'
import { getIsStreamLive } from '../../../helpers/getIsStreamLive'
import BroadcastContent from './Broadcast/Broadcast'
import {
  BroadcastThumbnailInterface,
  BroadcastThumbnailType
} from './broadcastCardTypes'
import GameContent from './Game'

interface Props {
  content: BroadcastThumbnailInterface
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
  showDetails: boolean
  showRootDomainLabel?: boolean
}

const BroadcastContentTypeSelector: FC<Props> = ({
  content,
  size,
  showDetails,
  showRootDomainLabel
}) => {
  const timeNow = Date.now() / 1000

  const isLiveNow = getIsStreamLive(
    content?.startTime,
    content?.endTime,
    timeNow
  )
  switch (content.type) {
    case BroadcastThumbnailType.Game:
      return (
        <GameContent
          content={content}
          size={size}
          showDetails={showDetails}
          isLive={isLiveNow}
          showRootDomainLabel={showRootDomainLabel}
        />
      )

    case BroadcastThumbnailType.Broadcast:
    case BroadcastThumbnailType.Video:
      return (
        <BroadcastContent
          content={content}
          size={size}
          isLive={isLiveNow}
          showDetails={showDetails}
          showRootDomainLabel={showRootDomainLabel}
        />
      )

    default:
      return (
        <BroadcastContent
          content={content}
          size={size}
          isLive={isLiveNow}
          showDetails={showDetails}
          showRootDomainLabel={showRootDomainLabel}
        />
      )
  }
}

export default BroadcastContentTypeSelector
