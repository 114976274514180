import { ArrowType } from './ExpandedToggle/ExpandedToggle'

export const getArrowToShow = (
  standingsTableList: any,
  isExpanded: boolean
) => {
  const firstItemdisplayOrder = parseInt(standingsTableList[0].DisplayOrder)
  if (isExpanded) {
    return ArrowType.Default
  }
  if (firstItemdisplayOrder > 1) {
    return ArrowType.UpAndDown
  }
  return ArrowType.Default
}
