import React from 'react'

export const InstagramIcon = () => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1321_1409)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0522 4.0724C12.3322 4.01381 12.7409 4 16 4C19.2591 4 19.6678 4.01381 20.9478 4.0724C22.2248 4.13052 23.097 4.33344 23.8611 4.62972C24.6504 4.93649 25.3192 5.34662 25.9865 6.01397C26.6539 6.68085 27.064 7.35011 27.3708 8.13941C27.6675 8.90251 27.8704 9.77517 27.9286 11.0522C27.9871 12.3322 28.001 12.7409 28.001 16C28.001 19.2591 27.9871 19.6678 27.9286 20.9478C27.8704 22.2248 27.6675 23.0975 27.3708 23.8606C27.064 24.6499 26.6539 25.3187 25.9865 25.986C25.3196 26.6534 24.6504 27.0635 23.8611 27.3703C23.098 27.6671 22.2253 27.87 20.9482 27.9281C19.6683 27.9867 19.2596 28.0005 16.0005 28.0005C12.7413 28.0005 12.3326 27.9867 11.0527 27.9281C9.77564 27.87 8.90299 27.6671 8.13989 27.3703C7.35059 27.0635 6.6818 26.6534 6.01445 25.986C5.34709 25.3192 4.93696 24.6499 4.6302 23.8606C4.33344 23.097 4.13052 22.2248 4.0724 20.9478C4.01381 19.6678 4 19.2591 4 16C4 12.7409 4.01381 12.3322 4.0724 11.0522C4.13052 9.77517 4.33344 8.90299 4.6302 8.13941C4.93649 7.35059 5.34709 6.68133 6.01397 6.01445C6.68085 5.3471 7.35011 4.93696 8.13941 4.6302C8.90299 4.33344 9.77517 4.13052 11.0522 4.0724ZM22.4058 11.034C23.2011 11.034 23.8458 10.3893 23.8458 9.59406C23.8458 8.79878 23.2011 8.15408 22.4058 8.15408C21.6105 8.15408 20.9658 8.79878 20.9658 9.59406C20.9658 10.3893 21.6105 11.034 22.4058 11.034ZM16.0003 9.83798C12.5969 9.83798 9.83838 12.597 9.83838 15.9999C9.83838 19.4029 12.5973 22.1619 16.0003 22.1619C19.4033 22.1619 22.1623 19.4029 22.1623 15.9999C22.1623 12.597 19.4038 9.83798 16.0003 9.83798ZM16.0003 19.9998C13.7911 19.9998 12.0005 18.2087 12.0005 15.9999C12.0005 13.7907 13.7915 12.0001 16.0003 12.0001C18.2091 12.0001 20.0002 13.7911 20.0002 15.9999C20.0002 18.2092 18.2096 19.9998 16.0003 19.9998Z"
        fill="#2F3135"
      />
    </g>
    <defs>
      <clipPath id="clip0_1321_1409">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
)
