import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface CommentsContentProps {
  text: string
  mediaObjectIdent: string
}
export const postCommentMessage = async (
  commentContent: CommentsContentProps
) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: '/comment',
      method: ApiEndpoint.METHODS.POST,
      params: {
        media_object: commentContent.mediaObjectIdent,
        content: commentContent.text
      },
      authenticatable: true
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    throw new Error('post comment message')
  }
}
