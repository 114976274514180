import React, { FC } from 'react'
import styles from './AppInfoSection.module.scss'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { t, Trans } from '@lingui/macro'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { StoreLinks } from 'pages/ChannelPage/Schedule/ActivateLivestream/StoreLinks/StoreLinks'

export const AppInfoSection: FC = () => {
  return (
    <div className={styles.AppInfoSection}>
      <FormSectionTitle title={t`Solidsport broadcaster app`} />
      <p>
        <Trans>
          If you plan to stream with a phone or tablet, you need to download the
          Broadcaster app for your device. Learn more about our streaming
          school&nbsp;
          <a
            href="https://www.solidsport.com/en/streamingacademy/"
            target={LinkTarget.NewWindow}
          >
            here
          </a>
        </Trans>
      </p>
      <StoreLinks />
    </div>
  )
}
