import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { SearchChannelsResultsApi } from './searchChannels.interface'

export const mapChannelSearchResults = (
  channelResults: SearchChannelsResultsApi[]
) => {
  return channelResults.map((channelResult) => ({
    id: channelResult.slug,
    subdomain: channelResult.slug,
    name: channelResult.title,
    sportLabel: channelResult?.sport?.name,
    logoUrl: channelResult.logo_image_url,
    rootDomain: channelResult?.root_level_domain,
    // @ts-ignore
    channelType: ChannelTypes[channelResult?.partner_type],
    sportName: channelResult?.sport?.name
  }))
}
