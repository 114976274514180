import { AnyAction } from 'redux'
import SPORTS from './sports.constants'

export interface Sports {
  isLoading: boolean
  isError: boolean
  data: any[]
}

export const initialState = {
  isLoading: false,
  isError: false,
  data: []
}

const sports = (state: Sports = initialState, action: AnyAction) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case SPORTS.SPORTS_FETCH_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      }

    case SPORTS.SPORTS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data
      }
    case SPORTS.SPORTS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: []
      }
    default:
      return state
  }
}

export default sports
