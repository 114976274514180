import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { DropdownList } from '../../../../components/Forms/DropdownListField/DropdownList'
import { InputSearch } from '../../../../components/InputSearch/InputSearch'
import { DropDownListItem } from '../../../../components/Forms/DropdownListField/DropdownItemList/DropdownItemList'

import styles from './Filters.module.scss'

interface Props {
  dropDownData: DropDownListItem[]
  onSelectGroup: (id: string) => void
  groupDataIsValid: boolean
  onCompetitionSearch: (searchInput: string) => void
  searchPlaceholder: string
}

export const Filters: FC<Props> = ({
  dropDownData,
  onSelectGroup,
  onCompetitionSearch,
  groupDataIsValid,
  searchPlaceholder
}) => {
  return (
    <div className={styles.FiltersContainer}>
      <div className={styles.Filter}>
        <DropdownList
          dropdownListData={dropDownData}
          onChange={onSelectGroup}
          value={dropDownData[0].id}
          id="districts-dropdown"
          label={t`Districts`}
          placeholder=""
          isValid={groupDataIsValid}
          validationMessage={t`Something went wrong when trying to get the list of districts`}
          type="dark"
        />
      </div>
      <div className={styles.Filter}>
        <InputSearch
          label={t`Competition`}
          onSearch={onCompetitionSearch}
          placeHolder={searchPlaceholder}
          hasError={false}
          errorMessage={t`Something went wrong when trying to search`}
        />
      </div>
    </div>
  )
}
