import { countries, Country } from 'countries-list'

// TODO: Make this a bit smarter. We want the list to be dynamic and adjust it self depending on the user's country/language.
// TODO: Make this run once or create static data.
export type FormattedCountry = {
  country_code: string
} & Country
const formatedCountryArray = (): FormattedCountry[] => {
  let formated = []
  const { SE, FI, DE, ...rest } = countries as any
  formated.push({ country_code: 'SE', ...SE })
  formated.push({ country_code: 'FI', ...FI })
  formated.push({ country_code: 'DE', ...DE })
  formated = formated.concat(
    Object.keys(rest).map((key) => {
      return {
        country_code: key,
        ...rest[key]
      }
    })
  )
  return formated
}

export default formatedCountryArray()
