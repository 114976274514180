import React, { FC, useCallback } from 'react'
import { useDataApi } from 'api/useDataApi'
import { getFollowingChannels } from 'api/user/getFollowingChannels'
import { Loader } from 'components/Loader/Loader'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { Trans, t } from '@lingui/macro'
import { ChannelList } from 'components/ChannelList/ChannelList'
import styles from './Following.module.scss'

export const Following: FC = () => {
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getFollowingChannels, [])
  })
  const hasData = ![0, undefined].includes(data?.data?.length)

  return (
    <div className={styles.Following}>
      <FormSectionTitle title={t`Following`} />
      {!isLoading && hasData && <ChannelList list={data?.data} showBorder />}
      {!isLoading && !hasData && (
        <div>
          <Trans>You are not following any channels right now.</Trans>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  )
}
