import { Checkbox } from 'components/checkbox/Checkbox'
import { Field, FormikProps } from 'formik'
import React, { FC } from 'react'
import styles from './Form.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { Trans, t } from '@lingui/macro'
import { SignupStep2Values } from './SignupStep2Form'
interface Props {
  formik: FormikProps<SignupStep2Values>
}

export const CheckBoxSection: FC<Props> = ({ formik }) => {
  const onPrivacyPolicyChecked = () => {
    if (formik.values.privacyPolicyChecked) {
      formik.setFieldValue('privacyPolicyChecked', false)
    } else {
      formik.setFieldValue('privacyPolicyChecked', true)
    }
    formik.setFieldTouched('privacyPolicyChecked', true, false)
  }
  const onRecieveEmailChecked = () => {
    if (formik.values.recieveEmailChecked) {
      formik.setFieldValue('recieveEmailChecked', false)
    } else {
      formik.setFieldValue('recieveEmailChecked', true)
    }
    formik.setFieldTouched('recieveEmailChecked', true, false)
  }
  return (
    <div className={styles.CheckboxContainer}>
      <Field
        component={Checkbox}
        id="privacyPolicyChecked"
        checked={formik.values.privacyPolicyChecked}
        onChange={onPrivacyPolicyChecked}
        data-testid={'privacy-check-box'}
        label={
          <div className={styles.PrivacyPolicyTextContainer}>
            <Trans>
              I have read and accept&nbsp;
              <a
                href="https://www.solidsport.com/privacy-policy/"
                target={LinkTarget.NewWindow}
                rel="noreferrer"
              >
                Solidsport&apos;s terms of use
              </a>
            </Trans>
          </div>
        }
      />
      <Field
        component={Checkbox}
        checked={formik.values.recieveEmailChecked}
        id="recieveEmailChecked"
        onChange={onRecieveEmailChecked}
        label={t`I want to receive information about events and offers from Solidsport.`}
      />
    </div>
  )
}
