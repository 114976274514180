import { LineupApiResponse, Player, Coach, Referee } from './getTeamLineups'

export const mapLineupResponse = (apiResponse: LineupApiResponse) => {
  return {
    homeTeam: {
      playerList: apiResponse.homeTeam.players.map((player: Player) =>
        mapPlayerFromresponse(player)
      ),
      coachList: apiResponse.homeTeam.coaches
        ? apiResponse.homeTeam.coaches.map((coach: Coach) =>
            mapCoachesFromResponse(coach)
          )
        : []
    },
    awayTeam: {
      playerList: apiResponse.awayTeam.players.map((player: Player) =>
        mapPlayerFromresponse(player)
      ),
      coachList: apiResponse.awayTeam.coaches
        ? apiResponse.awayTeam.coaches.map((coach: Coach) =>
            mapCoachesFromResponse(coach)
          )
        : []
    },
    referees: apiResponse.referees
      ? apiResponse.referees.map((referee: Referee) => ({
          id: referee.id,
          name: referee.name
        }))
      : []
  }
}

const mapPlayerFromresponse = (player: Player) => ({
  number: player.number,
  name: player.name,
  isCaptain: player.isCaptain,
  isHighlightedPosition: player.isHighlightedPosition,
  positionAbbreviation: player.position,
  isCoach: false
})

const mapCoachesFromResponse = (coach: Coach) => ({
  number: 0,
  name: coach.name,
  isCaptain: false,
  isHighlightedPosition: false,
  positionAbbreviation: '',
  isCoach: true
})
