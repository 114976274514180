import { AssociationClubEventDistrict } from '../../interfaces/AssociationClubEventDistrict.interface'
import { DistrictResponse } from '../../interfaces/DistrictResponse.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getDistrictsForAssociation = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/channels/all/related_district_channles_for_association`,
      params: {
        page: page,
        per_page: perPageCount
      }
    })

    const responseData = await resultResponse
    const mappedData = mapDistricts(responseData.data)

    return {
      data: mappedData,
      totalCount: responseData.totalCount
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapDistricts = (
  response: DistrictResponse[]
): AssociationClubEventDistrict[] => {
  const initValue: AssociationClubEventDistrict[] = []
  if (response) {
    return response.reduce(
      (prev: AssociationClubEventDistrict[], curr: DistrictResponse) => {
        const { companies } = curr
        let districtArray = prev
        if (prev && prev.length > 0) {
          districtArray = [...prev, ...companies]
        } else {
          districtArray.push(...companies)
        }

        return districtArray
      },
      initValue
    )
  }
  return initValue
}
