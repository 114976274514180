import React, { FC } from 'react'
import { Trans } from '@lingui/react'

import { useHistory } from 'react-router-dom'
import styles from './ReplaceLink.module.scss'
interface Props {
  link: string
  translationText: string
}

export const ReplaceLink: FC<Props> = ({ link, translationText }) => {
  const history = useHistory()
  const handleClick = () => history.replace(link)
  return (
    <div role="button" onClick={handleClick} className={styles.ReplaceLink}>
      <Trans id={translationText} />
    </div>
  )
}
