import React, { FC } from 'react'

import {
  ColumnVariants,
  StandingsColumnInterface
} from '../Standings.interface'

import styles from './StandingsHeader.module.scss'
import classNames from 'classnames'

interface StandingsHeaderProps {
  columns: StandingsColumnInterface[]
  gridTemplateColumns: string
}

export const StandingsHeader: FC<StandingsHeaderProps> = ({
  columns,
  gridTemplateColumns
}) => {
  return (
    <div className={styles.StandingdHeader} style={{ gridTemplateColumns }}>
      {columns.map((column, index) => {
        return (
          <div
            className={classNames({
              [styles.Bold]: column.variant === ColumnVariants.bold
            })}
            key={`StandingsColumn__${index}`}
          >
            {column.label}
          </div>
        )
      })}
    </div>
  )
}
