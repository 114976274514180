import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { RefereeAccessContent } from './RefereeAccessContent/RefereeAccessContent'
import ContentContainer from 'components/ContentContainer'
import { useDataApi } from 'api/useDataApi'
import { getRefereeAccessContent } from 'api/refereeAccess/getRefereeAccessContent'
import { useHistory, useParams } from 'react-router-dom'
import { Loader } from 'components/Loader/Loader'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import styles from './RefereeAccess.module.scss'
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage'
import { SuccessModal } from 'components/Modals/SuccessModal/SuccessModal'
import { NotSignedIn } from './NotSignedInSection'
export interface RefereeAccess {
  accessType: string
  title: string
  description: string
  alreadyInUse: boolean
}
export const RefereeAccess: FC = () => {
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false)
  const { integration_id, ident } = useParams<{
    integration_id: string
    ident: string
  }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
    }
  }, [currentUser])

  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getRefereeAccessContent, []),
    parameters: useMemo(
      () => ({
        ident: ident,
        integrationId: integration_id
      }),
      [currentUser]
    )
  })

  if (!currentUser) {
    return <NotSignedIn />
  }

  if (currentUser && isLoading) {
    return (
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={true}
        hasLightBackground={true}
      >
        <div className={styles.LoaderContainer}>
          <Loader />
        </div>
      </ContentContainer>
    )
  }
  if (currentUser && isError) {
    return (
      <ErrorMessage
        title={t`Sorry, we could not find that page.`}
        subText={t` Please reload the page and double check that you have the correct url.`}
      />
    )
  }

  if (!isError && currentUser && data) {
    const integrationId = parseInt(integration_id)
    const acces: RefereeAccess = data
    const onSuccessModalButtonClick = () => {
      history.push('/calendar')
    }
    const successModalSubText = t`You now have access to all the games you referee in ${acces.title}`
    return (
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={true}
        hasLightBackground={true}
      >
        <RefereeAccessContent
          pageHeaderText={acces.accessType}
          accessTitle={acces.title}
          accessDescription={acces.description}
          integrationId={integrationId}
          ident={ident}
          isAlreadyActivated={acces.alreadyInUse}
          onGetAccessSuccess={() => setSuccessModalIsOpen(true)}
        />
        <SuccessModal
          title={t`Congratulations!`}
          subText={successModalSubText}
          isOpen={successModalIsOpen}
          buttonLabel={t`Continue`}
          handleClose={() => setSuccessModalIsOpen(false)}
          onButtonClick={onSuccessModalButtonClick}
        />
      </ContentContainer>
    )
  }
  return null
}
