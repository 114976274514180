import React, { FC } from 'react'
import styles from './ReporterMessage.module.scss'
import DefaultTeamBadge from '../../../../../../assets/images/shield_2_256x256.png'
import { getMessageTimeStamp } from '../getMessageTimeStamp'

interface ReporterMessageProps {
  reporterName: string
  reporterImgPath: string
  text: string
  publishedTime: number
}
const ReporterMessage: FC<ReporterMessageProps> = ({
  reporterName,
  reporterImgPath,
  text,
  publishedTime
}) => {
  const timeStamp = getMessageTimeStamp(publishedTime)
  const altTextForImage = reporterImgPath ? reporterName : 'default team badge'
  return (
    <div
      className={styles.ReporterMessageContainer}
      data-testid="reporter-message"
    >
      <div className={styles.ReporterNameAndTextBox}>
        <div className={styles.ReporterName}>{reporterName}</div>
        <div className={styles.ReporterBoxContainer}>
          <div className={styles.Triangle} />
          <div className={styles.ReporterMessageText}>
            <div data-testid="reporter-text">{text}</div>
            <div className={styles.TimeStamp}>{timeStamp}</div>
          </div>
        </div>
      </div>
      <img
        className={styles.ReporterImage}
        src={reporterImgPath || DefaultTeamBadge}
        alt={altTextForImage}
      />
    </div>
  )
}
export default ReporterMessage
