import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import {
  FormikHelpers,
  FormikProps,
  Form,
  Formik,
  Field,
  ErrorMessage
} from 'formik'
import { CustomDomainField } from './CustomDomainField'
import { FormValidationError } from '../../../../../components/Forms/FormValidationError/FormValidationError'
import { postBrandingSettings } from '../../../../../api/channel/settings/postBrandingSettings'

import { CustomDomainStatus } from '../../../../../api/channel/settings/getCustomDomainStatus'
import { DomainRegistrationStatus } from './DomainStatus/DomainRegistrationStatus'
import { getRegistrationStatusText } from './DomainStatus/getRegistrationStatusText'
import { DomainActions } from './DomainActions/DomainActions'
import { customDomainSchema } from './customDomainSchema'

import styles from './CustomDomain.module.scss'

interface Props {
  onCheckDomain: () => void
  domainStatus: CustomDomainStatus
  channelSlug: string
}

export const CustomDomain: FC<Props> = ({
  onCheckDomain,
  domainStatus,
  channelSlug
}) => {
  const [currentDomainStatus, setCurrentDomainStatus] = useState(domainStatus)

  useEffect(() => {
    setCurrentDomainStatus(domainStatus)
  }, [domainStatus])

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)
    const brandingSettingValues = { ...values, channelSlug }

    const response = await postBrandingSettings(brandingSettingValues)

    if (response.hasError) {
      formikHelpers.setFieldError(
        'domainName',
        t`There has been an error trying to set the domain name`
      )
    } else {
      onCheckDomain()
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <div className={styles.CustomDomainContainer}>
      <div className={styles.CustomDomainForm}>
        <Formik
          initialValues={{ domainName: domainStatus.customDomain }}
          validationSchema={customDomainSchema}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {(formik: FormikProps<any>) => {
            if (
              formik.dirty &&
              currentDomainStatus.status !==
                DomainRegistrationStatus.ChangingDomain &&
              currentDomainStatus.status !== DomainRegistrationStatus.NotSet
            ) {
              setTimeout(
                () =>
                  setCurrentDomainStatus({
                    status: DomainRegistrationStatus.ChangingDomain,
                    customDomain: domainStatus.customDomain
                  }),
                0
              )
            }
            const cancelUpdate = () => {
              formik.resetForm()
            }
            return (
              <Form>
                <div className={styles.CustomDomainField}>
                  <Field
                    component={CustomDomainField}
                    domainStatusDescription={getRegistrationStatusText(
                      currentDomainStatus.status,
                      domainStatus.customDomain
                    )}
                    domainStatus={currentDomainStatus.status}
                    id="domainName"
                    name="domainName"
                    label={t`URL`}
                    placeholder={t`name.domain.com`}
                    onChange={formik.handleChange}
                    textValue={formik.values.domainName}
                    isValid={
                      !formik.errors.domainName &&
                      ![
                        DomainRegistrationStatus.DomainAlreadyInUse,
                        DomainRegistrationStatus.SslInactive,
                        DomainRegistrationStatus.Expired
                      ].includes(currentDomainStatus.status)
                    }
                    isRequired={false}
                  />
                  <div className={styles.ButtonsContainer}>
                    <DomainActions
                      onCheckDomain={onCheckDomain}
                      domainStatus={currentDomainStatus.status}
                      onCancelUpdate={cancelUpdate}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="domainName"
                  render={(msg) => <FormValidationError errorMessage={msg} />}
                />
                <br />
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
