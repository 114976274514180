import React, { FC } from 'react'

import { FilterType } from '../filterTypes'
import { NoContent } from '../NoContent/NoContent'

import { ChannelResult } from '../SearchResultTypes/ChannelResult/ChannelResult'
import { StreamResults } from '../SearchResultTypes/StreamResults/StreamResults'

import styles from '../SearchResults.module.scss'
import classNames from 'classnames'

interface Props {
  filter: FilterType
  data: any[]
  isRootOrChildChannel: boolean
}

export const ResultContent: FC<Props> = ({
  filter,
  data,
  isRootOrChildChannel
}) => {
  if (!data) return null
  const filterContent = () => {
    switch (filter) {
      case FilterType.Channels: {
        // @ts-ignore
        const channels = data
        return channels && channels.length > 0 ? (
          <ChannelResult
            isRootOrChildChannel={isRootOrChildChannel}
            channelList={channels}
          />
        ) : (
          <NoContent />
        )
      }
      case FilterType.UpcomingStreams: {
        // @ts-ignore

        const upcomingStreams = data
        return upcomingStreams && upcomingStreams.length > 0 ? (
          <StreamResults streamList={upcomingStreams} />
        ) : (
          <NoContent />
        )
      }
      case FilterType.PastStreams: {
        // @ts-ignore
        const pastStreams = data
        return pastStreams && pastStreams.length > 0 ? (
          <StreamResults streamList={pastStreams} />
        ) : (
          <NoContent />
        )
      }
      case FilterType.Videos: {
        // @ts-ignore
        const videos = data
        return videos && videos.length > 0 ? (
          <StreamResults streamList={videos} />
        ) : (
          <NoContent />
        )
      }
      default:
        return <NoContent />
    }
  }
  return (
    <div
      className={classNames({
        [styles.VideoContainer]: filter !== FilterType.Channels,
        [styles.ChannelContainer]: filter === FilterType.Channels
      })}
      data-testid="result-contents"
    >
      {filterContent()}
    </div>
  )
}
