import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const downloadStream = async (company: string, ident: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${company}/download/video`,
      method: ApiEndpoint.METHODS.GET,
      params: { media_object: ident }
    })
    const result = resultResponse.data.download_url

    return result
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
