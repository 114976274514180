import React, { FC } from 'react'
import styles from './RefereeAccessContent.module.scss'
import { RefereeAccessForm } from '../AccessForm/RefereeAccessForm'
import { Trans } from '@lingui/macro'

interface Props {
  pageHeaderText: string
  accessTitle: string
  accessDescription: string
  integrationId: number
  ident: string
  isAlreadyActivated: boolean
  onGetAccessSuccess: () => void
}

export const RefereeAccessContent: FC<Props> = ({
  pageHeaderText,
  accessTitle,
  accessDescription,
  integrationId,
  ident,
  isAlreadyActivated,
  onGetAccessSuccess
}) => {
  const headerImgUrl =
    'http://www.solidsport.com/wp-content/uploads/2022/09/background-1.png'
  return (
    <div className={styles.AccessContainer}>
      <div className={styles.Header}>
        <img src={headerImgUrl} alt="team standing together in sun" />
        <h3 className={styles.HeaderText}>{pageHeaderText}</h3>
      </div>
      <div className={styles.Content}>
        <h4 data-testid="access-title">{accessTitle}</h4>
        <p data-testid="access-description" className={styles.Description}>
          {accessDescription}
        </p>
        <p>
          <Trans>Submit the code which you recieved in the email</Trans>:
        </p>
        <div className={styles.FormContainer}>
          <RefereeAccessForm
            integrationId={integrationId}
            ident={ident}
            onGetAccessSuccess={onGetAccessSuccess}
            isAlreadyActivated={isAlreadyActivated}
          />
        </div>
      </div>
    </div>
  )
}
