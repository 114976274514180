import { t } from '@lingui/macro'
import * as yup from 'yup'

export const contactDetailsValidationSchema = () => {
  const phoneRegExp = /^(?!0)\d{7,15}$/
  const firstCharNoZeroRegExp = /^(?!0)/
  return yup.object({
    firstName: yup
      .string()
      .required(t`First name is a required field`)
      .max(40, t`First name is to long`),
    lastName: yup
      .string()
      .required(t`Last name is a required field`)
      .max(40, t`Last name is to long`),
    email: yup
      .string()
      .required(t`Email is a required field`)
      .email(t`Please enter a valid email`),
    phoneNumber: yup
      .string()
      .matches(firstCharNoZeroRegExp, t`First number can not be a zero`)
      .matches(phoneRegExp, t`Phone number is not valid`),
    countryCode: yup.string()
  })
}
