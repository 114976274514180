import React, { FC, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik } from 'formik'
import * as yup from 'yup'
import { t, Trans } from '@lingui/macro'

import { useDataApi } from '../../../../api/useDataApi'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import { AccountFormFields } from './AccountFormFields'
import {
  ChannelAccountSettings,
  getChannelSettings
} from '../../../../api/channel/settings/getChannelSettings'
import { Loader } from '../../../../components/Loader/Loader'
import { patchAdminAccount } from '../../../../api/channel/settings/patchAdminAccount'
import {
  StatusMessage,
  StatusMessageType
} from '../StatusMessage/StatusMessage'
import { phoneRegex } from '../regex'

interface AccountFormProps {
  onSubmitSuccess: () => void
  channelName: string
  channelSlug: string
}

export const AccountForm: FC<AccountFormProps> = ({
  onSubmitSuccess,
  channelSlug
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { data, isLoading, isError } = useDataApi({
    apiCall: getChannelSettings,
    initialData: {},
    parameters: channelSlug
  })

  if ((!data || Object.keys(data).length === 0) && !isLoading) return null

  if (isLoading) return <Loader />

  const channelAccountSchema = yup.object({
    firstName: yup.string().required(t`Please enter the contact's first name`),
    lastName: yup.string().required(t`Please enter the contact's last name`),
    phoneNumber: yup
      .string()
      .matches(phoneRegex, t`Please enter a valid phone number`)
      .required(t`Please enter a phone number`),
    email: yup
      .string()
      .email(t`Please enter a valid email`)
      .required(t`Please enter an email`),
    accountName: yup
      .string()
      .required(t`Please enter the account name for your channel`),
    address: yup.string(),
    postCode: yup.string(),
    city: yup.string(),
    country: yup.string()
  })

  const initialValues: ChannelAccountSettings = {
    firstName: data.account.firstName || '',
    lastName: data.account.lastName || '',
    phoneNumber: data.account.phoneNumber || '',
    email: data.account.email || '',
    accountName: data.account.accountName || '',
    address: data.account.address || '',
    postCode: data.account.postCode || '',
    city: data.account.city || '',
    country: data.account.country || ''
  }

  const onSubmit = async (
    values: ChannelAccountSettings,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const adminSettingValues = { ...values, channelSlug }

    const response = await patchAdminAccount(adminSettingValues)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
      onSubmitSuccess()
    }
  }

  return (
    <div>
      {isError && (
        <div>
          <Trans>
            There was a problem retriving your settings data. Please try again
          </Trans>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={channelAccountSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          return (
            <Form>
              <AccountFormFields formik={props} />
              <SubmitButton
                type="secondary"
                disabled={!props.dirty || props.isSubmitting}
                label={t`Save changes`}
              />
              {showSuccessMessage && (
                <StatusMessage
                  onClose={() => setShowSuccessMessage(false)}
                  message={t`your channel account settings have been successfully updated`}
                  messageType={StatusMessageType.Success}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
