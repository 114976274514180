import React, { FC, useCallback, useEffect, useMemo } from 'react'
import {
  PurchaseListItem,
  TransactionInformation
} from '../PurchaseListItem/PurchaseListItem'
import { Pagination } from 'components/Pagination/Pagination'
import { useDataApi } from 'api/useDataApi'
import { getTransactionForUser } from 'api/user/getTransactionsForUser'
import { Loader } from 'components/Loader/Loader'
import { scrollToTop } from 'helpers/scrollToTop'
import { usePagination } from 'hooks/Pagination'
import styles from './PurchaseList.module.scss'
import { Trans } from '@lingui/macro'

export const PurchaseList: FC = () => {
  const PER_PAGE_COUNT = 10
  const pagination = usePagination()

  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getTransactionForUser, []),
    parameters: useMemo(
      () => ({
        currentPage: pagination.currentPage > 0 ? pagination.currentPage : 1,
        perPageCount: PER_PAGE_COUNT
      }),
      [pagination.currentPage]
    )
  })

  useEffect(() => {
    if (!isLoading && !isError && data.totalCount > 0) {
      pagination.calculateMaxPages(data.totalCount, PER_PAGE_COUNT)
    }
  }, [data?.totalCount])

  return (
    <div className={styles.PurchaseList}>
      <div className={styles.List}>
        {!isLoading &&
          data?.data?.length > 0 &&
          data?.data?.map((transfer: TransactionInformation) => {
            return (
              <PurchaseListItem
                key={transfer.refNumber}
                createdAt={transfer.createdAt}
                channelName={transfer.channelName}
                channelSlug={transfer.channelSlug}
                purchasedObjectName={transfer.purchasedObjectName}
                purchasedObjectSlug={transfer.purchasedObjectSlug}
                price={transfer.price}
                currency={transfer.currency}
                refNumber={transfer.refNumber}
                vat={transfer.vat}
              />
            )
          })}
        {!isLoading && !isError && data?.data?.length < 1 && (
          <Trans>You have no completed purchases</Trans>
        )}
        {isLoading && (
          <div className={styles.LoadingContainer}>
            <Loader />
          </div>
        )}
      </div>
      {pagination.maxPages > 0 && (
        <Pagination
          totalPages={pagination.maxPages}
          currentPage={pagination.currentPage}
          onChangePage={(newPage: number) => {
            pagination.jump(newPage)
            scrollToTop()
          }}
        />
      )}
    </div>
  )
}
