import React, { FC } from 'react'
import { SidebarMenuLink } from 'components/SidebarNavigation/MenuItem/MenuItem'
import styles from './MobileNavigationMenu.module.scss'

import { User } from 'interfaces'
import { SignedOutContent } from './SignedOutContent'

import { SignedInContent } from './SignedInContent'
import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
interface Props {
  menuLinks: SidebarMenuLink[]
  currentUser?: User
  isOpen: boolean
  onCloseModal: () => void
  onHelpClick: () => void
  onLanguageSelectorClick?: () => void
  showLanguageSelector?: boolean
}

export const MobileNavigationMenu: FC<Props> = ({
  menuLinks,
  currentUser,
  isOpen,
  onCloseModal,
  onHelpClick,
  onLanguageSelectorClick,
  showLanguageSelector
}) => {
  const contentToShow = currentUser ? (
    <SignedInContent
      menuLinks={menuLinks}
      currentUser={currentUser}
      onHelpClick={onHelpClick}
      onMenuItemClick={() => onCloseModal()}
      onLanguageSelectorClick={onLanguageSelectorClick}
      onSignOutClick={onCloseModal}
      showLanguageSelector={showLanguageSelector}
    />
  ) : (
    <SignedOutContent
      onHelpClick={onHelpClick}
      onLanguageSelectorClick={onLanguageSelectorClick}
      showLanguageSelector={showLanguageSelector}
    />
  )
  return (
    <BasicModal
      isOpen={isOpen}
      firstContainerContent={contentToShow}
      firstContainerTheme={ModalTheme.Primary}
      handleClose={onCloseModal}
      customStyle={styles.MobileMenuModal}
    />
  )
}
