import React, { FC, ReactNode } from 'react'

import styles from './checkbox.module.scss'

interface Props {
  id: string
  label: string | ReactNode
  checked: boolean
  onChange: (newValue: string | undefined) => void
}

export const Checkbox: FC<Props> = ({
  id,
  label,
  checked,
  onChange,
  ...props
}) => {
  const isChecked = checked || false
  return (
    <div className={styles.CheckboxWrapper}>
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={() => {
          onChange(isChecked ? undefined : id)
        }}
        className={isChecked ? styles.checked : ''}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
