import React from 'react'

const HighlightIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14_1365)">
        <g clipPath="url(#clip1_14_1365)">
          <path
            d="M6.58276 22.8961L11.0828 15.3961"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5828 22.8961L11.0828 15.3961"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.0828 15.3961V22.8961"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.15653 10.5305L5.47116 9.58577L6.60482 12.3633L4.2902 13.308C4.16742 13.3582 4.02977 13.3574 3.90752 13.3061C3.78527 13.2547 3.68844 13.1568 3.63833 13.0341L2.88255 11.1824C2.83244 11.0596 2.83315 10.9219 2.88453 10.7997C2.93591 10.6774 3.03375 10.5806 3.15653 10.5305Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.36649 8.00834L10.4587 5.92994L12.726 11.4851L7.63383 13.5634C7.51105 13.6136 7.3734 13.6128 7.25115 13.5615C7.1289 13.5101 7.03207 13.4122 6.98196 13.2895L5.09251 8.66021C5.0424 8.53743 5.04311 8.39978 5.09449 8.27753C5.14587 8.15528 5.24371 8.05845 5.36649 8.00834Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2549 1.21314C18.2153 1.11608 18.1557 1.02841 18.0801 0.955777C18.0045 0.883146 17.9146 0.827169 17.816 0.791458C17.7174 0.755746 17.6125 0.741093 17.5079 0.748444C17.4033 0.755794 17.3015 0.784986 17.2089 0.834135L10.4919 4.22314C10.3276 4.31025 10.2016 4.45528 10.1382 4.63008C10.0748 4.80488 10.0786 4.99699 10.1489 5.16914L13.0369 12.2451C13.1071 12.4173 13.2388 12.5573 13.4064 12.6378C13.574 12.7184 13.7655 12.7338 13.9439 12.6811L21.1149 10.4021C21.2151 10.3724 21.308 10.3221 21.3875 10.2543C21.4671 10.1866 21.5316 10.1029 21.5769 10.0087C21.6222 9.91452 21.6473 9.81189 21.6506 9.70742C21.6538 9.60296 21.6352 9.49897 21.5959 9.40214L18.2549 1.21314Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.0818 14.6461V19.1461"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.8318 16.8961H22.3318"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.58179 1.14613V5.64613"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.33179 3.39613H5.83179"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14_1365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_14_1365">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(-0.168213 -0.103867)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default HighlightIcon
