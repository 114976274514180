import { t } from '@lingui/macro'
import classNames from 'classnames'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import Modal from '../Modal/Modal'
import ContactSupportIcon from './Icons/ContactSupportIcon'
import styles from './TextFormModal.module.scss'

export enum TextFormModalSize {
  Medium = 'Medium',
  Large = 'Large'
}
interface Props {
  subTitle: string | null
  title: string
  description: string
  defaultOpen: boolean
  showSupportSection?: boolean
  styledSubTitle?: () => ReactElement
  onClose: () => void
  parentStyle?: string | null
  childStyle?: string | null
  modalSize: TextFormModalSize
  children?: React.ReactNode
}
export const TextFormModal: FC<Props> = ({
  subTitle,
  title,
  description,
  defaultOpen,
  showSupportSection,
  styledSubTitle,
  onClose,
  parentStyle,
  childStyle,
  modalSize,
  children
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }
  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <div
        className={classNames(styles.TextFormModal, styles[modalSize], {
          [parentStyle || 'parentStyle']: !!parentStyle
        })}
      >
        <div
          className={styles.ModalLeftContent}
          data-testid="modal-left-section-container"
        >
          <div className={styles.TextContent}>
            {styledSubTitle ? (
              styledSubTitle()
            ) : (
              <div className={styles.SubTitle} data-testid="modal-subtitle">
                {subTitle}
              </div>
            )}
            <div className={styles.Title} data-testid="modal-title">
              {title}
            </div>
            <div className={styles.Description} data-testid="modal-description">
              {description}
            </div>
          </div>
          {showSupportSection && (
            <div className={styles.SupportContent}>
              <ContactSupportIcon />
              <div data-testid="modal-contact-support">
                {t`Problems with the purchase? Contact our`} &nbsp;
                <a
                  href="https://solidsport.freshdesk.com/en/support/home"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t`support.`}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className={classNames(childStyle || styles.Children)}>
          {children}
        </div>
      </div>
    </Modal>
  )
}

TextFormModal.defaultProps = {
  showSupportSection: false,
  styledSubTitle: undefined,
  parentStyle: null,
  childStyle: null
}
