import React, { FC, useState } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { resetPasswordFormValidationSchema } from './ResetPasswordFormValidationSchema'
import { PasswordField } from 'components/Forms/PasswordField/PasswordField'
import { postResetPassword } from 'api/user/postResetPassword'
import styles from './ResetPasswordForm.module.scss'

interface ResetPasswordFormValues {
  newPassword: string
  confirmNewPassword: string
  resetPasswordToken: string
}
interface Props {
  onSubmitForm: () => void
}
export const ResetPasswordForm: FC<Props> = ({ onSubmitForm }) => {
  const params = new URLSearchParams(location.search)
  const [resetPasswordToken] = useState<string>(
    params.get('reset_password_token') || ''
  )
  const initialValues: ResetPasswordFormValues = {
    newPassword: '',
    confirmNewPassword: '',
    resetPasswordToken: resetPasswordToken
  }
  const onSubmit = async (
    values: ResetPasswordFormValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    const submitValues = { ...values }
    formikHelpers.setSubmitting(true)
    const response = await postResetPassword({
      newPassword: submitValues.newPassword,
      confirmNewPassword: submitValues.confirmNewPassword,
      resetPasswordToken: submitValues.resetPasswordToken
    })
    if (response.isSuccess) {
      onSubmitForm()
    } else {
      formikHelpers.setFieldError(
        'newPassword',
        t`Something went wrong setting your new password, please try again.`
      )
    }

    formikHelpers.setSubmitting(false)
  }
  return (
    <div className={styles.ResetPasswordFormContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordFormValidationSchema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <Form>
              <div className={styles.Form}>
                <Field
                  component={PasswordField}
                  id="newPassword"
                  data-testid="new-password-input"
                  name="newPassword"
                  placeholder={t`New Password`}
                  onChange={formik.handleChange}
                  textValue={formik.values.newPassword}
                  isValid={!formik.errors.newPassword}
                  validationMessage={formik.errors.newPassword}
                />
                <Field
                  component={PasswordField}
                  id="confirmNewPassword"
                  data-testid="confirm-new-password-input"
                  name="confirmNewPassword"
                  placeholder={t`Confirm new password`}
                  onChange={formik.handleChange}
                  textValue={formik.values.confirmNewPassword}
                  isValid={!formik.errors.confirmNewPassword}
                  validationMessage={formik.errors.confirmNewPassword}
                />
                <Field
                  type="hidden"
                  id="resetPasswordToken"
                  data-testid="reset-password-token"
                  name="resetPasswordToken"
                  value={resetPasswordToken}
                />
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SendButton}
                  disabled={!formik.dirty || formik.isSubmitting}
                  label={t`Send `}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
