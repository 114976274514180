import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import styles from './VoucherSection.module.scss'

import { putVoucherCode } from '../../../../../api/payments/putVoucherCode'
import BackArrow from './BackArrow'
import { streamSelector } from '../../../../../redux-store/stream/stream.selectors'
import { createUpdateVoucherPaymentActions } from './createUpdateVoucherPaymentActions'
import { Loader } from '../../../../../components/Loader/Loader'

interface VoucherProps {
  channelSlug: string
  mediaObjectIdent: string
  onGoBackClick: () => void
  onClose: () => void
}
interface FormValues {
  voucherCode: string
}
const VoucherSection: FC<VoucherProps> = ({
  channelSlug,
  mediaObjectIdent,
  onGoBackClick,
  onClose
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const stream = useSelector(streamSelector)
  const { adminAccess } = stream.data.streamUrl
  const { mediaObject } = stream.data

  const updatingStateForStream = useCallback(async () => {
    await createUpdateVoucherPaymentActions(
      dispatch,
      channelSlug,
      mediaObject,
      adminAccess
    )
  }, [dispatch, channelSlug, mediaObject, adminAccess])

  const initialValues: FormValues = {
    voucherCode: ''
  }

  const voucherCodeValidation = Yup.object().shape({
    voucherCode: Yup.string()
      .min(8, t`Voucher code should be at least 8 characters`)
      .required(t`Please type a voucher code in the field`)
  })

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const submitResponse = await putVoucherCode({
      slug: channelSlug,
      voucherToken: values.voucherCode,
      mediaObjectIdent: mediaObjectIdent
    })
    if (submitResponse.valid) {
      updatingStateForStream()
      if (stream.voucherPaymentIsError) {
        setErrorMessage(t`Something went wrong, try reload the page`)
      } else if (!stream.voucherPaymentIsLoading) onClose()
    } else {
      setErrorMessage(submitResponse.message)
    }
  }

  return (
    <div className={styles.VoucherContainer}>
      <div
        className={styles.GoBackContainer}
        role="button"
        onClick={onGoBackClick}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onGoBackClick()
          }
        }}
      >
        <BackArrow />
        <Trans>Back</Trans>
      </div>
      <div className={styles.Description}>
        <Trans>
          This voucher can be redeemed by entering the code in the field below.
        </Trans>
      </div>
      <div className={styles.VoucherInputSection}>
        <div className={styles.InputTitle}>
          <Trans>Voucher code</Trans>
          {stream.voucherPaymentIsLoading ? (
            <Loader />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={voucherCodeValidation}
            >
              {({ errors }) => (
                <Form className={styles.FormContainer}>
                  <Field
                    id="voucherCode"
                    name="voucherCode"
                    placeholder="Ex. c2e870771"
                    className={styles.InputField}
                  />
                  {errorMessage && (
                    <div className={styles.InputError}>{errorMessage}</div>
                  )}
                  {errors.voucherCode && (
                    <div className={styles.InputError}>
                      {errors.voucherCode}
                    </div>
                  )}
                  <button className={styles.SubmitButton} type="submit">
                    <Trans>Use voucher</Trans>
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}
export default VoucherSection
