import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import styles from './RelatedChannelItem.module.scss'
import { RelatedChannelItemLogo } from './RelatedChannelItemLogo'
import { doCapitalizeStart } from './doCapitalizeStart'
import { getTranslatedChannelTypeIndefinite } from './getTranslatedChannelTypeIndefinite'

interface RelatedChannelItemProps {
  channelType: ChannelTypes
  channelName: string
  logoUrl?: string
  isCurrent?: boolean
  subdomain: string
}

export const RelatedChannelItem: FC<RelatedChannelItemProps> = ({
  channelType,
  channelName,
  logoUrl,
  isCurrent,
  subdomain
}) => {
  const translatedChannelType = isCurrent
    ? t`current ${getTranslatedChannelTypeIndefinite(channelType)}`
    : getTranslatedChannelTypeIndefinite(channelType)

  return (
    <Link to={`/${subdomain}`} className={styles.RelatedChannelItem}>
      <div className={styles.RelatedChannelItemLogo}>
        <RelatedChannelItemLogo channelType={channelType} logoUrl={logoUrl} />
      </div>
      <div className={styles.RelatedChannelItemDetails}>
        <div className={styles.RelatedChannelItemType}>
          {doCapitalizeStart(translatedChannelType)}
        </div>
        <div className={styles.RelatedChannelItemName}>{channelName}</div>
      </div>
    </Link>
  )
}

RelatedChannelItem.defaultProps = {
  isCurrent: false,
  logoUrl: undefined
}
