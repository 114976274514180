import * as yup from 'yup'
import { t } from '@lingui/macro'
export const loginFormValidationSchema = () =>
  yup.object({
    userEmail: yup
      .string()
      .email(t`Please enter a valid email`)
      .required(t`Please enter your accounts email`),
    password: yup
      .string()
      .min(6, 'Must be a valid password')
      .required(t`Password is required`)
  })
