import React, { FC } from 'react'
import styles from './TimeContainer.module.scss'
import dayjs from 'dayjs'
import { t } from '@lingui/macro'

interface Props {
  startTime: number
  isLive: boolean
}

export const TimeContainer: FC<Props> = ({ startTime, isLive }) => {
  const time = dayjs.unix(startTime).format('HH:mm')
  return <div className={styles.TimeContainer}>{isLive ? t`live` : time}</div>
}
