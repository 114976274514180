import React, { FC } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import styles from './LabelAndDate.module.scss'
import { ExternalLinkIcon } from 'components/ChannelListItem/ExternalLinkIcon'

interface Props {
  label: string
  labelSlug: string
  startTime: number
  rootDomain?: string
}

export const LabelAndDate: FC<Props> = ({
  label,
  labelSlug,
  startTime,
  rootDomain
}) => {
  const dateString = dayjs.unix(startTime).format('dddd D MMMM, H:mm')
  return (
    <div className={styles.LabelAndDateContainer}>
      <div className={styles.Label} data-testid="display-label">
        {labelSlug ? (
          <Link to={`/${labelSlug}`}>{label}</Link>
        ) : (
          <span>{label}</span>
        )}
      </div>
      {startTime && (
        <div className={styles.Date} data-testid="display-date">
          {dateString}
        </div>
      )}
      {rootDomain && (
        <div className={styles.RootDomainLabel} data-testid="root-domain">
          {rootDomain}
          <ExternalLinkIcon />
        </div>
      )}
    </div>
  )
}
