import { RefereeAccess } from 'features/refereeAccess/RefereeAccess'
import ApiEndpoint from 'network/ApiEndpoint'

export interface RefereeAccessApiResponse {
  access_type_category_name: string
  access_type_integration_description: string
  access_type_integration_id: number
  access_type_integration_name: string
  ident: string
  user_id: string
}
export const getRefereeAccessContent = async ({
  integrationId,
  ident
}: {
  integrationId: string
  ident: string
}): Promise<RefereeAccess> => {
  try {
    const resultResponse = await ApiEndpoint.call({
      path: `access_invite/${integrationId}/${ident}`,
      method: ApiEndpoint.METHODS.GET,
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped =
      responseData && responseData.data && mapRefereeAccess(responseData.data)
    return mapped || null
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapRefereeAccess = (
  refereeAccessData: RefereeAccessApiResponse
): RefereeAccess => ({
  accessType: refereeAccessData.access_type_category_name,
  title: refereeAccessData.access_type_integration_name,
  description: refereeAccessData.access_type_integration_description,
  alreadyInUse: !!refereeAccessData.user_id
})
