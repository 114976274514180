import { getRedirect } from '../../api/redirect/getRedirect'
import REDIRECT from './redirect.constants'

export const fetchRedirectStart = () => ({
  type: REDIRECT.REDIRECT_FETCH_START
})
export const fetchRedirectSuccess = (data: any) => ({
  type: REDIRECT.REDIRECT_FETCH_SUCCESS,
  data
})
export const fetchRedirectError = () => ({
  type: REDIRECT.REDIRECT_FETCH_ERROR
})

export const redirectActionCreator = (domain: string, currentUri: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchRedirectStart())
      const result = await getRedirect(domain, currentUri)
      if (result && result.status?.code === 404) {
        location.href = '/404'
      }

      dispatch(fetchRedirectSuccess(result))
    } catch (error) {
      dispatch(fetchRedirectError())
    }
  }
}
