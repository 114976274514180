import { TimelineObjectPath } from '../../helpers/PathHelper'
import { Company, TimelineObject } from '../../interfaces'
import { mapTeamDataFromResponse } from './mapTeamFromResponse'

export const mapBroadcastThumbnailResponse = (
  apiResponse: TimelineObject[],
  currentChannel?: Company
) =>
  apiResponse.map((stream: TimelineObject) => ({
    id: stream.ident,
    channelName: stream.display_company && stream.display_company.name,
    channelImg: '',
    duration: stream.duration,
    channelUrl: stream.display_company && stream.display_company.subdomain,
    targetUrl: TimelineObjectPath(stream, currentChannel),
    country: stream.country,
    type: stream.type,
    startTime: stream.live_start_at,
    endTime: stream.live_end_at,
    highlightFromDate: stream.highlight_from_date,
    hasHighlight: stream.has_highlight,
    publishedAt: stream.published_at,
    isReplay: stream.is_replay,
    viewers: stream.number_of_viewers || 0,
    showViewers: false,
    views: stream.number_of_views || 0,
    showViews: false, // stream.show_views_or_viewers,
    title: stream.title,
    thumbnailImageUrl: stream.thumbnail,
    typeOfGame:
      stream.categories && stream.categories.length > 0
        ? stream.categories[0].name
        : '',
    homeTeam: mapTeamDataFromResponse(stream?.home_team, 1),
    awayTeam: mapTeamDataFromResponse(stream?.away_team, 2),
    event: stream.event
      ? { name: stream.event.name, slug: stream.event.slug }
      : undefined,
    location: stream.location && {
      name: stream.location.name,
      slug: stream.location.slug
    },
    categories: stream.categories,
    rootDomain: stream.root_level_domain
  }))
