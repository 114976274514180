import { getIsStreamLive } from '../../helpers/getIsStreamLive'
import { BroadcastThumbnailInterface } from '../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import { Company } from '../../interfaces'

import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'

const now = Date.now() / 1000

export const getIsLive = async ({
  slug,
  page,
  perPageCount,
  channel
}: {
  slug: string
  page: number
  perPageCount: number
  channel: Company
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/livestreams',
      params: {
        company: slug,
        page: page,
        per_page: perPageCount,
        include_games_and_livestreams: true
      }
    })
    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data, channel)
    return {
      data: mapped.filter((livestream: BroadcastThumbnailInterface) =>
        getIsStreamLive(livestream.startTime, livestream.endTime, now)
      ),
      totalCount: responseData.totalCount
    }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const getIsLiveforTeam = async ({
  slug,
  page,
  perPageCount,
  channel
}: {
  slug: string
  page: number
  perPageCount: number
  channel: Company
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/upcoming_games_and_broadcasts_for_team',
      params: {
        company: slug,
        page: page,
        per_page: perPageCount
      }
    })

    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data, channel)
    return {
      data: mapped.filter((livestream: BroadcastThumbnailInterface) =>
        getIsStreamLive(livestream.startTime, livestream.endTime, now)
      ),
      totalCount: responseData.totalCount
    }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
