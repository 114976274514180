import React, { FC } from 'react'

export const CalendarIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.55176 6.19469C1.55176 5.16867 2.38351 4.33691 3.40954 4.33691H20.594C21.62 4.33691 22.4518 5.16867 22.4518 6.19469V20.5925C22.4518 21.6185 21.62 22.4503 20.594 22.4503H3.40953C2.38351 22.4503 1.55176 21.6185 1.55176 20.5925V6.19469Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.55176 9.91016H22.4518"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.12549 6.42647V1.5498"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8784 6.42647V1.5498"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
