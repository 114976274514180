import { useEffect } from 'react'

interface Props {
  log: (message: string, params: any) => void
  readyToPlay: boolean
  onFirstInteraction: any
  bigPlay: any
  adContainer: any
}

export const useCreateListeners = ({
  log,
  readyToPlay,
  onFirstInteraction,
  bigPlay,
  adContainer
}: Props) =>
  useEffect(() => {
    log('readyToPlay', readyToPlay)
    if (bigPlay.current) {
      bigPlay.current.addEventListener('click', onFirstInteraction)
      bigPlay.current.addEventListener('touchstart', onFirstInteraction)
    }
    if (adContainer.current) {
      adContainer.current.addEventListener('click', onFirstInteraction)
      adContainer.current.addEventListener('touchstart', onFirstInteraction)
    }
    return () => {
      if (bigPlay.current) {
        bigPlay.current.removeEventListener('click', onFirstInteraction)
        bigPlay.current.removeEventListener('touchstart', onFirstInteraction)
      }
      if (adContainer.current) {
        adContainer.current.removeEventListener('click', onFirstInteraction)
        adContainer.current.removeEventListener(
          'touchstart',
          onFirstInteraction
        )
      }
    }
  }, [readyToPlay])
