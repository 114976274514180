import { t } from '@lingui/macro'

interface ReportType {
  id: string
  description: string
}

export const getReportTypes = async () => {
  try {
    const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_API_ENDPOINT}`
    const resultResponse = await fetch(`${baseUrl}reports/ticket_types`)

    if (resultResponse.status === 200) {
      const res = await resultResponse.json()
      if (res === null) throw new Error('no types available')
      const types = translateReportTypes(res)
      // @ts-ignore
      return filterUnusedReportTypes(types)
    }
    throw new Error(`unexpected result: ${resultResponse.status}`)
  } catch (e: any) {
    return []
  }
}
const filterUnusedReportTypes = (list: ReportType[]) =>
  list
    .filter((listItem) => includedTypes.includes(listItem.id))
    // always sort other last
    .sort((a, b) => {
      if (a.id === 'OTHER_ISSUE') {
        return 1
      }
      if (b.id === 'OTHER_ISSUE') {
        return -1
      }
      return a < b ? -1 : 1
    })

const includedTypes = [
  'OTHER_ISSUE',
  'INFRINGEMENT_OF_RIGHTS',
  'OFFENSIVE_CONTENT',
  'DOES_NOT_START',
  'CONTAINS_ERROR'
]

const translateReportTypes = (list: string[]) => {
  const typesList = list.map((reportItemKey: string) => {
    const id = reportItemKey
    switch (reportItemKey) {
      case 'UNSPECIFIED':
        return { id, description: t`Unspecified` }
      case 'LOGIN_ISSUE':
        return { id, description: t`Issue with login` }
      case 'PAYMENT_ISSUE':
        return { id, description: t`Issue with payment` }
      case 'TECHNICAL_ISSUE':
        return { id, description: t`Technical issues` }
      case 'QUALITY_ISSUE':
        return { id, description: t`Quality issues` }
      case 'BELOW_EXPECTATION':
        return {
          id,
          description: t`Livestream did not meet expectations`
        }
      case 'OTHER_ISSUE':
        return { id, description: t`Other` }
      case 'INFRINGEMENT_OF_RIGHTS':
        return { id, description: t`Copyright infringement` }
      case 'OFFENSIVE_CONTENT':
        return { id, description: t`Offensive or innappropriate content` }
      case 'DOES_NOT_START':
        return { id, description: t`Livestream did not start` }
      case 'CONTAINS_ERROR':
        return { id, description: t`Livestream contains an error` }
      default:
        return null
    }
  })
  return typesList
}
