import { StartBannerContent } from 'features/customContent/StartPageBanner/startPageBannerTypes'
import ApiEndpoint from 'network/ApiEndpoint'

export interface StartBannerApiResponse {
  slug: string
  title: string
  content: any
  image_url: string
  type: string
  button_text: string
  button_link: string
  published: boolean
  publish_date: string
  image_link: string
  image_target: string
  button_target: string
  content_type: string
}
export const getStartPageBanner = async ({
  channelSlug
}: {
  channelSlug: string
}): Promise<StartBannerContent> => {
  try {
    const resultResponse = await ApiEndpoint.call({
      path: 'banners/frontpage',
      method: ApiEndpoint.METHODS.GET,
      params: {
        company: channelSlug
      },
      authenticatable: true
    })

    const responseData = await resultResponse
    const mapped =
      responseData && responseData.data && mapStartBanner(responseData.data)
    return mapped || null
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapStartBanner = (banner: StartBannerApiResponse) => ({
  title: banner.title,
  content: banner.content && JSON.parse(banner.content),
  imageUrl: banner.image_url,
  buttonLabel: banner.button_text,
  buttonLink: banner.button_link,
  buttonLinkTarget: banner.button_target,
  bannerType: banner.type,
  imageLink: banner.image_link,
  imageTarget: banner.image_target,
  published: banner.published
})
