import React, { FC } from 'react'

import ContentSlider from '../../../components/sliders/ContentSlider'
import TallThumbnail from '../../../components/cards/TallThumbnail/TallThumbnail'
import { Featured } from '../../../components/cards/TallThumbnail/cardTypes'
import TallImagePreloader from '../../../components/preloaders/TallImagePreloader'

import styles from './index.module.scss'

interface FrontPageHeroProps {
  backgroundImg: string
  featuredSectionData: Featured[]
  isLoading: boolean
  isError: boolean
}
const FrontPageHero: FC<FrontPageHeroProps> = ({
  isLoading,
  isError,
  backgroundImg,
  featuredSectionData
}) => {
  return (
    <div
      data-testid="front-page-hero-container"
      className={styles.FrontPageHero}
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <div className={styles.SloganText} data-testid="front-page-hero-slogan">
        <span
          className={styles.FirstRow}
          data-testid="front-page-hero-slogan-first-row"
        >
          All sports.
        </span>
        <span
          className={styles.SecondRow}
          data-testid="front-page-hero-slogan-second-row"
        >
          Everywhere.
        </span>
      </div>
      {isLoading ? (
        <TallImagePreloader />
      ) : (
        featuredSectionData &&
        !isError && (
          <div
            className={styles.featuredSection}
            data-testid="front-page-thumbnail-container"
          >
            <ContentSlider leftPadding>
              {featuredSectionData.map((card) => (
                <TallThumbnail content={card as Featured} key={card.id} />
              ))}
            </ContentSlider>
          </div>
        )
      )}
    </div>
  )
}
export default FrontPageHero
