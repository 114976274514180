import React, { FC } from 'react'

export const FacebookIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2223_148)">
      <path
        d="M24.1449 12.0724C24.1449 5.40309 18.7364 0 12.0724 0C5.40309 0 0 5.40309 0 12.0724C0 18.0979 4.41583 23.0932 10.1838 23.9946V15.56H7.12005V12.0724H10.1838V9.41113C10.1838 6.38498 11.9812 4.7163 14.7445 4.7163C16.0644 4.7163 17.4487 4.95238 17.4487 4.95238V7.92488H15.9249C14.4279 7.92488 13.9611 8.85848 13.9611 9.81355V12.0778H17.3092L16.7726 15.5654H13.9611V24C19.729 23.0879 24.1449 18.0926 24.1449 12.0724Z"
        fill="#1877F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2223_148">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
