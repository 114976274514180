import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { CustomPageMeta } from './pageList/PageListAccordion'
import ContentContainer from 'components/ContentContainer'
import NotFoundPage from 'pages/NotFoundPage'
import { BrokenLinkIcon } from './BrokenLinkIcon'

import styles from './CustomPageNotFound.module.scss'

interface Props {
  channelSlug: string
  pageList: CustomPageMeta[]
}

export const CustomPageNotFound: FC<Props> = ({ pageList, channelSlug }) => {
  const message =
    pageList.length > 0 ? (
      <>
        <div className={styles.BrokenLink}>
          <div>
            <BrokenLinkIcon />
          </div>
          <div className={styles.InfoText}>
            <Trans>
              The page you are looking for has moved. You may have to update any
              bookmarks in your browser
            </Trans>
            <Link to={`/${channelSlug}/menu/${pageList[0].slug}`}>
              <Trans>
                Click here to find the new home of the available content
              </Trans>
            </Link>
          </div>
        </div>
      </>
    ) : (
      <NotFoundPage />
    )

  return (
    <ContentContainer
      hasLightBackground
      hasLeftRightPadding
      hasTopBottomPadding
    >
      {message}
    </ContentContainer>
  )
}
