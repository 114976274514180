import { MediaCategory } from '../../interfaces/MediaCategory.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getListOfCustomCategories = async ({
  slug
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/media_categories`
    })
    const responseData = await resultResponse
    const mapped = mapMediaCategory(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapMediaCategory = (categoryList: MediaCategory[]) =>
  categoryList.map((category: MediaCategory) => ({
    label: category.name,
    id: category.slug
  }))
