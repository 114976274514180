import React from 'react'
import { Trans } from '@lingui/macro'

import styles from './NoContent.module.scss'

export const NoContent = () => (
  <div className={styles.SearchMessage}>
    <Trans>No content found for this filter</Trans>
  </div>
)
