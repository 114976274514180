import React, { FC } from 'react'
import styles from './formValidationError.module.scss'

interface Props {
  errorMessage: string
}

export const FormValidationError: FC<Props> = ({ errorMessage }) => (
  <div data-testid="form-validation-error" className={styles.ValidationError}>
    {errorMessage}
  </div>
)
