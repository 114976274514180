import React, { FC } from 'react'
import styles from './TabListContainer.module.scss'
import { TabHeader } from './TabHeader'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'

interface Props {
  tabListData: any[]
  onTabSelected: (itemSelected: string) => void
  selectedTabId: string
}

export const TabHeaders: FC<Props> = ({
  tabListData,
  onTabSelected,
  selectedTabId
}) => (
  <div data-testid="tab-header" className={styles.TabHeaderList}>
    {tabListData.map((tabHeader: ChannelEvent) => (
      <TabHeader
        key={tabHeader.id}
        id={tabHeader.id}
        slug={tabHeader.eventSlug}
        text={tabHeader.name}
        onTabSelected={onTabSelected}
        isSelected={tabHeader.id === selectedTabId}
      />
    ))}
  </div>
)
