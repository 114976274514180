import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import styles from './CommentsInput.module.scss'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { postCommentMessage } from 'api/viewer/Comments/postComment'
import { MessageInput } from '../../Chat/MessageInput/MessageInput'
import classNames from 'classnames'

interface CommentsInputProps {
  mediaObjectId: string
  onPostedComment: (comment: Comment) => void
  isViewingStream?: boolean
}
export const CommentsInput: FC<CommentsInputProps> = ({
  mediaObjectId,
  onPostedComment,
  isViewingStream
}) => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  const sendMessage = async (textMessage: string) => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (textMessage !== '') {
      const newComment = await postCommentMessage({
        text: textMessage,
        mediaObjectIdent: mediaObjectId
      })
      onPostedComment(newComment.data)
    }
    return false
  }

  return (
    <div
      className={classNames(styles.CommentsInput, {
        [styles.IsViewingStream]: isViewingStream
      })}
      data-testid="comments-input-container"
    >
      <MessageInput
        onSuccess={(text) => sendMessage(text)}
        userIsBlocked={false}
        isViewingStream={isViewingStream}
      />
    </div>
  )
}
