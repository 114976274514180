import { t } from '@lingui/macro'

export const getTimeFrame = (timeframe: string | undefined) => {
  const monthTimeframes = ['Monthly', 'Month', 'month', 'monthly']
  const dayTimeframes = ['Daily', 'Day', 'day', 'daily']
  const weeklyTimeframes = ['Weekly', 'Week', 'week', 'weekly']
  if (timeframe) {
    if (monthTimeframes.indexOf(timeframe) > -1) {
      return t`Month`
    }
    if (dayTimeframes.indexOf(timeframe) > -1) {
      return t`Day`
    }
    if (weeklyTimeframes.indexOf(timeframe) > -1) {
      return t`Week`
    }
  }
  return timeframe
}
