import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Team.module.scss'
import SheildIcon from './ShieldIcon'

interface Props {
  teamLogo: string
  teamName: string
  result: 'Win' | 'Loss' | 'Equal' | 'Default'
}

export const Team: FC<Props> = ({ teamLogo, teamName, result }) => (
  <div
    className={classNames(
      styles.TeamContainer,
      styles.LogoFirst,
      styles[result]
    )}
    data-testid="team-logo"
  >
    <div className={styles.LogoContainer}>
      {teamLogo && teamLogo !== '' ? (
        <img src={teamLogo} alt={teamName} />
      ) : (
        <div className={styles.Empty}>
          <SheildIcon />
        </div>
      )}
    </div>
    <div className={styles.TeamName} data-testid="team-name">
      {teamName}
    </div>
  </div>
)
