import React, { FC, useState } from 'react'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'

import { StandingsInterface } from './Standings.interface'
import { ExpandedToggle } from './ExpandedToggle/ExpandedToggle'
import { StandingsRow } from './StandingsRow/StandingsRow'
import { StandingsHeader } from './StandingsHeader/StandingsHeader'
import { createGridTemplateColumns } from './createGridTemplateColumns'
import ContentSlider from 'components/sliders/ContentSlider'
import { getTableBasedOnTeamSlug } from './getTableBasedOnTeam'
import { getArrowToShow } from './getArrowToShow'
import classNames from 'classnames'
import styles from './Standings.module.scss'

interface StandingsTableProps {
  standings: StandingsInterface
  showExpandedToggle: boolean
  teamSlug?: string
}

export const StandingsTable: FC<StandingsTableProps> = ({
  standings,
  showExpandedToggle,
  teamSlug
}) => {
  const [expanded, setExpanded] = useState(!showExpandedToggle)

  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const { columns, rows } = standings

  const listBasedOnTeam =
    teamSlug && rows && getTableBasedOnTeamSlug(rows, teamSlug)

  const rowsToUse =
    listBasedOnTeam && listBasedOnTeam?.length > 0
      ? listBasedOnTeam
      : rows.slice(0, 3)

  const showEvenRowsColor = !listBasedOnTeam || expanded
  const rowsShown = !showExpandedToggle || expanded ? rows : rowsToUse
  const gridTemplateColumns = createGridTemplateColumns(columns, isMobile)
  const arrowToShow = getArrowToShow(rowsShown, expanded)

  return (
    <div className={styles.StandingsTable}>
      <ContentSlider leftPadding={true}>
        <div className={styles.Table} data-testid="standings-table">
          <StandingsHeader
            columns={columns}
            gridTemplateColumns={gridTemplateColumns}
          />
          {rowsShown.length > 0 &&
            rowsShown.map((row: any, rowIndex: number) => {
              const name: string = row[columns[0].field].toString()
              const channel = row?.subdomain?.toString()
              const placement = listBasedOnTeam
                ? row.DisplayOrder
                : rowIndex + 1
              const isLastBeforeDivider = !!row.isLastBeforeDivider
              const isLastPlacement = placement === rowsShown.length
              const isPreSelected = row.selectedTeam
              return (
                <div
                  className={classNames({
                    [styles.Even]: showEvenRowsColor && rowIndex % 2
                  })}
                  key={`StandingsRow__${name}__${rowIndex}`}
                >
                  <StandingsRow
                    key={`StandingsRow__${name}__${rowIndex}`}
                    row={row}
                    name={name}
                    channel={channel}
                    columns={columns}
                    placement={placement}
                    isLastBeforeDivider={isLastBeforeDivider}
                    isLastPlacement={isLastPlacement}
                    isPreSelected={isPreSelected}
                    gridTemplateColumns={gridTemplateColumns}
                  />
                </div>
              )
            })}
        </div>
      </ContentSlider>
      {showExpandedToggle && rows.length > 3 && (
        <ExpandedToggle
          expanded={expanded}
          onToggleExpanded={() => setExpanded((prev) => !prev)}
          arrowIconToShow={arrowToShow}
        />
      )}
    </div>
  )
}
