import React, { FC } from 'react'
import { Honeybadger } from '@honeybadger-io/react'

import SponsorSectionPreloader from '../../preloaders/SponsorSectionPreloader'
import SponsorSlider from '../../sliders/SponsorSlider'

interface Props {
  isLoading: boolean
  isError: boolean
  sponsors: any[]
}

const SponsorContent: FC<Props> = ({ isLoading, isError, sponsors }) => {
  if (isLoading) {
    return <SponsorSectionPreloader />
  }
  if (isError) {
    Honeybadger.notify('Sponsor Content Rendering Error', {
      name: 'SponsorContentError',
      message: 'Sponsor data error'
    })
    return null
  }
  return sponsors !== null && sponsors.length > 0 ? (
    <SponsorSlider sponsorContent={sponsors} />
  ) : null
}
export default SponsorContent
