import { t } from '@lingui/macro'
import { DropDownListItem } from './DropdownList/DropdownList'

export const getDefaultCategoriesList = (hasHighlights: boolean) => {
  const categoryList: DropDownListItem[] = [{ label: t`All`, id: 'defaultAll' }]
  if (hasHighlights) {
    categoryList.push({ label: t`Highlights`, id: 'defaultHighlights' })
  }
  return categoryList
}
