/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react'
import STATUS_CODES from '../../constants/status_codes.constants'
import useExternalScript from '../ExternalScript'

import { VideoAdsType } from './videoAdsType'
import { noVideoAds } from './noVideoAds'
import { codes } from './imaEventCodes'

const useVideoAds = (
  src: string | undefined,
  videoElement: any,
  adContainer: HTMLDivElement,
  displayAds: boolean
): VideoAdsType => {
  if (!displayAds) {
    return noVideoAds
  }

  const imaScriptStatus = useExternalScript(
    '//imasdk.googleapis.com/js/sdkloader/ima3.js'
  )
  const [google, setGoogle] = useState((window as any).google)
  const [adsManager, setAdsManager] = useState<any>()
  const [statusCode, setStatusCode] = useState('')
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [adDisplayContainer, setAdDisplayContainer] = useState<any>()
  const [adsLoader, setAdsLoader] = useState<any>()
  const [remainingTime, setRemainingTime] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(1)
  const [totalAds, setTotalAds] = useState(1)
  const [maxDuration, setMaxDuration] = useState(0)
  const [fullscreen, setFullscreen] = useState(false)
  const [nonLinearAd, setNonLinearAd] = useState(false)
  const [adsPlaying, setAdsPlaying] = useState(false)
  const [adsReady, setAdsReady] = useState(false)

  useEffect(() => {}, [remainingTime, currentPosition, totalAds, adsPlaying])

  const onAdsManagerLoaded = (adsManagerLoadedEvent: {
    getAdsManager: (arg0: HTMLVideoElement | undefined) => any
  }) => {
    if (videoElement) {
      setAdsManager(adsManagerLoadedEvent.getAdsManager(videoElement))
    }
  }

  const onVideoPlay = (event: any) => {
    // prevent this function from running on every play event
    if (adsLoaded || !src) {
      return
    }
    setAdsLoaded(true)

    // prevent triggering immediate playback when ads are loading
    event.preventDefault()
    // Initialize the container. Must be done via a user action on mobile devices.
    if (videoElement) {
      videoElement.load()
      adDisplayContainer.initialize()
      const width = videoElement.clientWidth
      const height = videoElement.clientHeight
      try {
        adsManager.init(width, height, google.ima.ViewMode.NORMAL)
        const settings = new google.ima.AdsRenderingSettings()
        settings.useStyledNonLinearAds = true
        settings.uiElements = [google.ima.UiElements.AD_ATTRIBUTION]
        adsManager.updateAdsRenderingSettings(settings)
        adsManager.start()
      } catch (adError) {
        setStatusCode(google?.ima?.AdErrorEvent?.Type?.AD_ERROR)
      }
      videoElement.removeEventListener('play', onVideoPlay)
    }
  }

  useEffect(() => {
    if (adDisplayContainer) {
      setAdsLoader(new google.ima.AdsLoader(adDisplayContainer))
    }
    return () => {
      setAdsLoader(undefined)
    }
  }, [adDisplayContainer])

  useEffect(() => {
    if (adsLoader && videoElement) {
      adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        onAdsManagerLoaded,
        false
      )
      adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        () => {
          if (adsManager) {
            adsManager.destroy()
          }
          setStatusCode(google.ima.AdErrorEvent.Type.AD_ERROR)
        },
        false
      )
      videoElement.addEventListener('ended', () => {
        adsLoader.contentComplete()
      })
      const adsRequest = new google.ima.AdsRequest()
      adsRequest.adTagUrl = src
      adsRequest.linearAdSlotWidth = videoElement.clientWidth
      adsRequest.linearAdSlotHeight = videoElement.clientHeight
      adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth
      adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3
      adsLoader.requestAds(adsRequest)
    }
    return () => {
      if (adsLoader) {
        adsLoader.removeEventListener(
          google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
          onAdsManagerLoaded
        )
        adsLoader.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, () =>
          setStatusCode(google.ima.AdErrorEvent.Type.AD_ERROR)
        )
      }
    }
  }, [adsLoader, videoElement])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [fullscreen, adsManager, nonLinearAd])

  const onResize = () => {
    const doResize = () => {
      if (adsManager) {
        const height = nonLinearAd
          ? videoElement.clientHeight - 55
          : videoElement.clientHeight
        adsManager.resize(
          videoElement.clientWidth,
          height,
          fullscreen ? google.ima.ViewMode.NORMAL : google.ima.ViewMode.NORMAL
        )
      }
    }
    doResize()
    setTimeout(() => {
      doResize()
    }, 500)
  }

  useEffect(() => {
    if (google && videoElement && adContainer) {
      setAdDisplayContainer(
        new google.ima.AdDisplayContainer(adContainer, videoElement)
      )
    }
    return () => {
      setAdDisplayContainer(undefined)
      setAdsLoader(undefined)
      setAdsManager(undefined)
    }
  }, [google, videoElement, adContainer, src])

  useEffect(() => {
    let remainingTimeTimer: any
    const onError = (e: any) => {
      setStatusCode(google.ima.AdErrorEvent.Type.AD_ERROR)
      setAdsPlaying(false)
    }
    const onContentPauseRequested = (e: any) => {
      setStatusCode(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED)
    }
    const onContentResumeRequested = (e: any) => {
      setStatusCode(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED)
      setAdsPlaying(false)
    }
    const onLoaded = (e: any) => {
      setStatusCode(google.ima.AdEvent.Type.LOADED)
      const ad = e.getAd()
      if (!ad.isLinear()) {
        setNonLinearAd(true)
        setStatusCode('non_linear_ad')
        setAdsPlaying(false)
      } else {
        setNonLinearAd(false)
      }
    }
    const onComplete = (e: any) => {
      setAdsPlaying(false)
      setStatusCode(google.ima.AdEvent.Type.COMPLETE)
      clearInterval(remainingTimeTimer)
    }
    const onStarted = (e: any) => {
      if (videoElement.muted) {
        adsManager.setVolume(0)
      } else {
        adsManager.setVolume(videoElement.volume)
      }
      setStatusCode(google.ima.AdEvent.Type.STARTED)
      setCurrentPosition(
        adsManager?.getCurrentAd()?.getAdPodInfo()?.getAdPosition()
      )
      setTotalAds(adsManager?.getCurrentAd()?.getAdPodInfo()?.getTotalAds())
      setMaxDuration(
        adsManager?.getCurrentAd()?.getAdPodInfo()?.getMaxDuration()
      )
      setAdsPlaying(true)
      remainingTimeTimer = setInterval(() => {
        setRemainingTime(adsManager?.getRemainingTime())
      }, 1000)
    }
    const onPaused = (e: any) => {
      setAdsPlaying(false)
      setStatusCode(google.ima.AdEvent.Type.PAUSED)
    }
    const onResumed = (e: any) => {
      setAdsPlaying(true)
      setStatusCode(google.ima.AdEvent.Type.RESUMED)
    }
    const onClick = (e: any) => {
      setAdsPlaying(false)
      setStatusCode(google.ima.AdEvent.Type.CLICK)
      adsManager.pause()
    }
    const onVolumeChange = (e: any) => {
      if (adsManager) {
        adsManager.setVolume(videoElement.volume)
      }
    }
    const onMuted = (e: any) => {
      if (adsManager) {
        if (videoElement.muted) {
          adsManager.setVolume(0)
        } else {
          adsManager.setVolume(videoElement.volume)
        }
      }
    }
    if (adsManager) {
      adsManager.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        onError
      )
      adsManager.addEventListener(
        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        onContentPauseRequested
      )
      adsManager.addEventListener(
        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
        onContentResumeRequested
      )
      adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, onLoaded)
      adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, onComplete)
      adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, onStarted)
      adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, onPaused)
      adsManager.addEventListener(google.ima.AdEvent.Type.RESUMED, onResumed)
      adsManager.addEventListener(google.ima.AdEvent.Type.CLICK, onClick)
      setStatusCode('ready_to_play')
      setAdsReady(true)
      videoElement.addEventListener('play', onVideoPlay)
      videoElement.addEventListener('volumechange', onVolumeChange)
      videoElement.addEventListener('muted', onMuted)
    }
    return () => {
      if (adsManager) {
        adsManager.removeEventListener(
          google.ima.AdErrorEvent.Type.AD_ERROR,
          onError
        )
        adsManager.removeEventListener(
          google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
          onContentPauseRequested
        )
        adsManager.removeEventListener(
          google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
          onContentResumeRequested
        )
        adsManager.removeEventListener(google.ima.AdEvent.Type.LOADED, onLoaded)
        adsManager.removeEventListener(
          google.ima.AdEvent.Type.COMPLETE,
          onComplete
        )
        adsManager.removeEventListener(
          google.ima.AdEvent.Type.STARTED,
          onStarted
        )
        adsManager.removeEventListener(google.ima.AdEvent.Type.PAUSED, onPaused)
        adsManager.removeEventListener(
          google.ima.AdEvent.Type.RESUMED,
          onResumed
        )
        videoElement.removeEventListener('play', onVideoPlay)
        videoElement.removeEventListener('volumechange', onVolumeChange)
        videoElement.removeEventListener('muted', onMuted)
      }
    }
  }, [adsManager])

  useEffect(() => {
    if (imaScriptStatus.code === STATUS_CODES.OK) {
      const g = (window as any).google
      if (g) {
        setGoogle(g)
      } else {
        setStatusCode('adError')
      }
    }
    if (imaScriptStatus.code === STATUS_CODES.ERROR) {
      setStatusCode('adError')
    }
    return () => {
      setGoogle(undefined)
    }
  }, [imaScriptStatus.code])

  useEffect(() => {}, [statusCode])

  const resume = useCallback(() => {
    if (adsManager) {
      adsManager.resume()
    }
  }, [adsManager])

  const pause = useCallback(() => {
    if (adsManager) {
      adsManager.pause()
    }
  }, [adsManager])

  const setIsFullscreen = (val: boolean) => {
    setFullscreen(val)
  }

  return {
    pause: pause,
    resume: resume,
    statusCode: statusCode,
    adsLoaded: adsLoaded,
    remainingTime: remainingTime,
    currentPosition: currentPosition,
    totalAds: totalAds,
    maxDuration: maxDuration,
    setFullscreen: setIsFullscreen,
    playing: adsPlaying,
    adsReady: adsReady,
    nonLinear: nonLinearAd,
    codes
  }
}
export default useVideoAds
