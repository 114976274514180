import React, { FC } from 'react'

import { t } from '@lingui/macro'
import {
  ButtonType,
  LinkIconButton
} from 'components/buttons/LinkIconButton/LinkIconButton'
import EyeIcon from 'pages/ChannelPage/Header/Images/EyeIcon'
interface Props {
  channelSlug: string
  isMobileView: boolean
}
export const ViewOnStartPageButton: FC<Props> = ({
  channelSlug,
  isMobileView
}) => {
  const startPageLink = `/${channelSlug}`

  return (
    <LinkIconButton
      label={t`View on start page`}
      type={ButtonType.Ghost}
      id="view-on-start-page-button"
      buttonSize="Medium"
      isFluid={isMobileView}
      href={startPageLink}
    >
      <EyeIcon />
    </LinkIconButton>
  )
}
