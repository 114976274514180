import { Company } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'

export const getPastGamesForChannel = async ({
  slug,
  page,
  perPageCount,
  currentChannel
}: {
  slug: string
  page: number
  perPageCount: number
  currentChannel: Company
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/videos_and_replays',
      params: {
        company: slug,
        page: page,
        per_page: perPageCount
      }
    })
    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(
      responseData.data,
      currentChannel
    )

    return { data: mapped, totalCount: responseData.totalCount }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
