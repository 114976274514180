import React from 'react'

const SolidsportIcon = () => (
  <svg
    width="131"
    height="22"
    viewBox="0 0 131 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_30_174)">
      <path
        d="M15.7762 1.15967C15.124 1.40272 14.4916 1.6945 13.8847 2.03244C13.2444 2.37249 12.6725 2.82465 12.1974 3.36669C11.5009 4.18663 11.2112 4.94103 11.3282 5.62989C11.4832 6.55818 12.2191 7.28984 13.5358 7.82488C14.0255 8.01749 14.9933 8.30574 16.4393 8.68963C17.5218 8.95893 18.5879 9.28851 19.6325 9.67677C20.4106 9.95365 21.118 10.3939 21.7055 10.9669C21.7055 10.8686 21.7055 10.7662 21.7055 10.6739C21.7059 8.70454 21.1523 6.7734 20.1058 5.09423C19.0594 3.41505 17.5609 2.0533 15.7762 1.15967Z"
        fill="white"
      />
      <path
        d="M2.79937 14.3216C3.37068 12.9104 4.18683 11.8457 5.24782 11.1274C5.39997 11.0178 5.58173 10.955 5.77015 10.9468C5.89462 10.9589 5.81912 11.1234 5.54571 11.4404C4.98284 12.0368 4.52682 12.7226 4.19703 13.4688C3.54187 14.946 3.33908 16.579 3.61348 18.1678C3.64603 18.3572 3.68894 18.5447 3.74203 18.7296C5.04365 19.8437 6.5967 20.6365 8.27229 21.0423C9.94789 21.4481 11.6977 21.4551 13.3766 21.0628C15.0556 20.6704 16.6151 19.8901 17.9259 18.7864C19.2367 17.6828 20.261 16.2877 20.9138 14.7168C20.9583 14.3525 20.9515 13.9838 20.8934 13.6213C20.703 12.558 20.0568 11.6959 18.955 11.0351C18.3606 10.7141 17.1996 10.3442 15.4721 9.92558C13.7446 9.50691 12.5714 9.1665 11.9525 8.90433C10.5922 8.29037 9.81757 7.42763 9.6285 6.31609C9.46663 5.34768 9.85634 4.31238 10.7976 3.21021C11.7389 2.10804 13.0645 1.27739 14.7743 0.718282C12.5782 -0.115199 10.1661 -0.224348 7.90142 0.407277C5.63674 1.0389 3.64211 2.37709 2.21802 4.22026C0.793924 6.06343 0.0174938 8.31174 0.00569078 10.6265C-0.00611223 12.9413 0.747351 15.1971 2.15258 17.0543C2.20704 16.1136 2.42584 15.1892 2.79937 14.3216Z"
        fill="white"
      />
      <path
        d="M35.2596 18.7135C33.1186 18.7135 31.4659 18.2701 30.3015 17.3833C29.1372 16.4965 28.5251 15.2318 28.4652 13.5892H31.587C31.7026 14.5255 32.0427 15.1943 32.6072 15.5956C33.1717 15.9969 34.0143 16.1975 35.1352 16.1975C35.5262 16.1993 35.9165 16.163 36.3002 16.0892C36.6403 16.0269 36.9677 15.9103 37.2694 15.7441C37.5385 15.5932 37.7678 15.3822 37.9387 15.1281C38.1138 14.8533 38.2019 14.5334 38.1917 14.2092C38.205 13.8777 38.1083 13.5509 37.9162 13.2782C37.7122 13.0064 37.444 12.7874 37.1347 12.6402C36.7422 12.4564 36.3321 12.3113 35.9105 12.2068C35.4317 12.0945 34.8924 11.9714 34.2925 11.8376C33.5893 11.6785 32.9091 11.5019 32.2521 11.308C31.642 11.1334 31.0631 10.8667 30.5362 10.5174C30.0464 10.1877 29.6401 9.75186 29.3487 9.24338C29.0257 8.6295 28.8704 7.94369 28.8978 7.25305C28.8831 6.55845 29.0377 5.87048 29.3487 5.24666C29.6457 4.67206 30.0763 4.17439 30.6056 3.79404C31.1786 3.38704 31.8244 3.08961 32.5092 2.91725C33.2986 2.71598 34.1116 2.61815 34.9271 2.62633C36.6954 2.62633 38.1236 3.03697 39.2118 3.85825C40.3 4.67953 40.8979 5.876 41.0053 7.44767H37.9509C37.93 7.10365 37.8323 6.76838 37.6648 6.46566C37.4973 6.16294 37.2641 5.9002 36.9817 5.69609C36.3731 5.29107 35.6493 5.08662 34.9148 5.11224C34.0497 5.11224 33.3607 5.2781 32.8479 5.60982C32.613 5.74343 32.4181 5.93571 32.283 6.16719C32.148 6.39867 32.0775 6.66114 32.0787 6.92801C32.0663 7.2055 32.1373 7.48044 32.2827 7.71853C32.4482 7.94955 32.6671 8.13865 32.9214 8.27029C33.2591 8.44628 33.6175 8.58104 33.9885 8.67156C34.4129 8.78793 34.9182 8.91099 35.5045 9.04074C36.3098 9.21329 37.0688 9.40389 37.7815 9.61256C38.4423 9.79947 39.0719 10.08 39.6505 10.4452C40.1735 10.7795 40.6073 11.2322 40.9156 11.7654C41.2462 12.3992 41.4051 13.1061 41.3767 13.8179C41.389 14.5392 41.2311 15.2534 40.9156 15.9046C40.6171 16.5126 40.1783 17.0437 39.6342 17.4555C39.0549 17.8848 38.3988 18.2033 37.6999 18.3945C36.9063 18.6154 36.0844 18.7229 35.2596 18.7135Z"
        fill="white"
      />
      <path
        d="M48.3874 18.6915C47.5651 18.7028 46.7492 18.5485 45.99 18.238C45.2928 17.9511 44.6622 17.5281 44.1361 16.9946C43.61 16.4611 43.1995 15.8282 42.9294 15.1341C42.3555 13.6477 42.3555 12.0059 42.9294 10.5195C43.2011 9.8261 43.6119 9.19358 44.1373 8.65954C44.6657 8.13116 45.2954 7.71108 45.99 7.42361C47.5291 6.81892 49.2457 6.81892 50.7848 7.42361C51.4814 7.71332 52.1125 8.13615 52.6416 8.66757C53.1676 9.20149 53.5791 9.83401 53.8515 10.5275C54.4254 12.014 54.4254 13.6557 53.8515 15.1422C53.5805 15.8357 53.1697 16.4682 52.6438 17.0016C52.1178 17.535 51.4876 17.9583 50.7909 18.246C50.0291 18.5544 49.2111 18.706 48.3874 18.6915ZM48.3874 16.4423C49.3096 16.4423 50.017 16.1079 50.5094 15.4391C51.0018 14.7703 51.2473 13.9009 51.2459 12.8308C51.2459 11.7487 51.0004 10.8726 50.5094 10.2025C50.0183 9.53232 49.311 9.19792 48.3874 9.19926C47.4638 9.19926 46.7565 9.53366 46.2654 10.2025C45.7744 10.8712 45.5288 11.7507 45.5288 12.8408C45.5288 13.9203 45.7744 14.7897 46.2654 15.4491C46.7565 16.1086 47.4638 16.443 48.3874 16.4523V16.4423Z"
        fill="white"
      />
      <path
        d="M55.7021 2.90723H58.6913V18.3684H55.7021V2.90723Z"
        fill="white"
      />
      <path
        d="M60.6786 2.90723H63.6698V5.54562H60.6786V2.90723ZM60.6786 7.27512H63.6698V18.3684H60.6786V7.27512Z"
        fill="white"
      />
      <path
        d="M69.8256 18.6914C69.1583 18.7024 68.4972 18.5652 67.8913 18.2902C67.3067 18.0177 66.792 17.6193 66.3855 17.1245C65.9432 16.5742 65.6109 15.9465 65.4061 15.2746C65.1627 14.4859 65.0444 13.665 65.0552 12.8408C65.0458 12.0345 65.1642 11.2317 65.4061 10.4613C65.6157 9.78752 65.9559 9.16003 66.4079 8.61338C66.8249 8.11021 67.3458 7.69987 67.9362 7.40955C68.5372 7.11714 69.2 6.96864 69.8705 6.97617C70.5529 6.94912 71.2291 7.11259 71.821 7.44767C72.3104 7.74506 72.7281 8.1435 73.0453 8.61539H73.1106V2.90723H76.1017V18.3684H73.235V16.9178H73.1901C72.8425 17.4829 72.3468 17.9457 71.7548 18.2577C71.1629 18.5697 70.4966 18.7195 69.8256 18.6914ZM70.4866 16.2276C71.3803 16.2276 72.0543 15.928 72.5087 15.3287C72.963 14.7295 73.1901 13.9189 73.1901 12.897C73.1953 12.4105 73.1474 11.9249 73.0473 11.4484C72.9686 11.0529 72.8157 10.6753 72.5964 10.3349C72.3976 10.029 72.1213 9.77938 71.7945 9.61056C71.4145 9.42585 70.994 9.33625 70.5703 9.34972C70.2001 9.33259 69.8321 9.41393 69.505 9.58518C69.1779 9.75643 68.9038 10.0113 68.7115 10.3228C68.3035 10.9729 68.0851 11.8075 68.0851 12.8328C68.0851 13.9122 68.2892 14.7482 68.6972 15.3408C68.8917 15.6285 69.1589 15.8615 69.4726 16.017C69.7864 16.1724 70.1358 16.245 70.4866 16.2276Z"
        fill="white"
      />
      <path
        d="M84.3836 18.7135C82.2426 18.7135 80.5899 18.2701 79.4255 17.3833C78.2611 16.4965 77.649 15.2318 77.5892 13.5892H80.7109C80.8293 14.5255 81.1693 15.1943 81.7311 15.5956C82.2929 15.9969 83.1356 16.1975 84.2591 16.1975C84.6502 16.1993 85.0404 16.1631 85.4242 16.0892C85.7643 16.0269 86.0916 15.9103 86.3934 15.7441C86.6625 15.5932 86.8918 15.3822 87.0626 15.1281C87.2378 14.8533 87.3258 14.5334 87.3156 14.2092C87.3287 13.878 87.2328 13.5516 87.0422 13.2782C86.8352 13.006 86.5643 12.7871 86.2526 12.6402C85.8602 12.4561 85.4501 12.3109 85.0284 12.2068C84.5536 12.0945 84.0163 11.9714 83.4165 11.8376C82.7119 11.6785 82.0317 11.5019 81.3761 11.308C80.766 11.1334 80.1871 10.8667 79.6602 10.5174C79.1707 10.1874 78.7645 9.75157 78.4727 9.24339C78.1497 8.62951 77.9943 7.9437 78.0217 7.25306C78.007 6.55845 78.1616 5.87048 78.4727 5.24667C78.7692 4.67344 79.1991 4.17712 79.7275 3.79806C80.2996 3.39071 80.9448 3.09323 81.6291 2.92127C82.4236 2.71723 83.2422 2.61805 84.0633 2.62633C85.8384 2.62633 87.2667 3.03697 88.3481 3.85825C89.4294 4.67953 90.0266 5.87601 90.1395 7.44767H87.0789C87.0575 7.10313 86.9589 6.7675 86.7903 6.46472C86.6217 6.16195 86.3873 5.8995 86.1036 5.6961C85.4946 5.29196 84.7711 5.0876 84.0367 5.11224C83.1716 5.11224 82.4827 5.2781 81.9698 5.60983C81.7344 5.74303 81.539 5.93516 81.4035 6.1667C81.2681 6.39823 81.1974 6.6609 81.1986 6.92802C81.1856 7.20557 81.2566 7.48068 81.4026 7.71854C81.5668 7.94999 81.7851 8.13923 82.0392 8.27029C82.377 8.44629 82.7353 8.58105 83.1063 8.67157C83.5307 8.78794 84.0367 8.911 84.6244 9.04074C85.4296 9.21329 86.188 9.4039 86.8994 9.61256C87.5602 9.79948 88.1898 10.08 88.7684 10.4452C89.2913 10.7795 89.7251 11.2322 90.0334 11.7654C90.365 12.3989 90.5239 13.1061 90.4945 13.8179C90.5078 14.5393 90.3499 15.2537 90.0334 15.9046C89.7356 16.5119 89.2983 17.0428 88.7561 17.4555C88.1758 17.8844 87.5191 18.2028 86.8198 18.3945C86.0275 18.615 85.207 18.7225 84.3836 18.7135Z"
        fill="white"
      />
      <path
        d="M92.1676 7.27514H95.0486V8.65955H95.1139C95.479 8.17386 95.9389 7.76461 96.4667 7.45572C97.0662 7.12245 97.7474 6.95794 98.4357 6.98021C99.1346 6.96988 99.8265 7.11818 100.458 7.41358C101.057 7.70014 101.586 8.11082 102.008 8.61741C102.459 9.16328 102.795 9.79126 102.998 10.4653C103.232 11.2359 103.347 12.0367 103.339 12.8409C103.348 13.6643 103.23 14.4843 102.988 15.2726C102.781 15.9482 102.441 16.577 101.986 17.1225C101.563 17.6192 101.034 18.0174 100.435 18.2882C99.8136 18.5636 99.1378 18.7006 98.4561 18.6895C97.7698 18.7129 97.0888 18.5644 96.4769 18.2581C95.9643 17.9826 95.5269 17.5896 95.2017 17.1124H95.1588V21.998H92.1676V7.27514ZM97.8195 16.2517C98.1715 16.263 98.521 16.1891 98.8373 16.0366C99.1535 15.884 99.4269 15.6575 99.6334 15.3769C100.081 14.7924 100.305 13.9745 100.305 12.9231C100.305 11.8718 100.101 11.0137 99.6925 10.3489C99.2845 9.68013 98.6213 9.34573 97.7032 9.34573C97.2866 9.33451 96.8745 9.43286 96.5095 9.63064C96.1803 9.81547 95.9005 10.0748 95.6934 10.387C95.4684 10.7337 95.312 11.119 95.2323 11.5227C95.134 11.9845 95.0862 12.4553 95.0894 12.9271C95.0894 13.937 95.32 14.7442 95.7811 15.3488C96.2423 15.9534 96.9217 16.2544 97.8195 16.2517Z"
        fill="white"
      />
      <path
        d="M110.068 18.6915C109.246 18.7029 108.43 18.5485 107.67 18.238C106.973 17.9511 106.343 17.5281 105.817 16.9946C105.29 16.4611 104.88 15.8282 104.61 15.1342C104.036 13.6477 104.036 12.006 104.61 10.5195C104.882 9.82613 105.292 9.19361 105.818 8.65957C106.346 8.13119 106.976 7.71111 107.67 7.42364C109.209 6.81907 110.925 6.81907 112.463 7.42364C113.161 7.71279 113.793 8.13567 114.322 8.6676C114.848 9.20152 115.26 9.83404 115.532 10.5275C116.106 12.014 116.106 13.6557 115.532 15.1422C115.261 15.8359 114.85 16.4685 114.324 17.0019C113.798 17.5353 113.168 17.9586 112.471 18.2461C111.71 18.5549 110.892 18.7065 110.068 18.6915ZM110.068 16.4423C110.99 16.4423 111.697 16.1079 112.19 15.4391C112.682 14.7703 112.928 13.9009 112.926 12.8308C112.926 11.7487 112.681 10.8726 112.19 10.2025C111.699 9.53235 110.991 9.19795 110.068 9.19929C109.143 9.19929 108.436 9.53369 107.946 10.2025C107.456 10.8713 107.211 11.7507 107.209 12.8409C107.209 13.9203 107.455 14.7897 107.946 15.4492C108.437 16.1086 109.144 16.443 110.068 16.4524V16.4423Z"
        fill="white"
      />
      <path
        d="M120.227 9.0488H120.292C120.563 8.49715 120.954 8.01081 121.437 7.62427C121.912 7.29108 122.486 7.12369 123.069 7.14876C123.307 7.14002 123.545 7.16915 123.773 7.23503V9.80521H123.708C122.715 9.70489 121.906 9.91422 121.282 10.4332C120.657 10.9522 120.342 11.7949 120.337 12.9612V18.3785H117.346V7.27516H120.227V9.0488Z"
        fill="white"
      />
      <path
        d="M124.514 7.27515H126.075V3.81213H129V7.27515H130.935V9.22937H129V15.1021C129 15.5214 129.102 15.8083 129.309 15.9668C129.545 16.136 129.833 16.2203 130.125 16.2056H130.698C130.8 16.2052 130.903 16.1978 131.004 16.1835V18.3464C130.859 18.3604 130.698 18.3684 130.521 18.3684C130.343 18.3684 130.161 18.3885 129.927 18.3885H129.135C128.769 18.3891 128.404 18.3494 128.048 18.2701C127.696 18.1951 127.363 18.0518 127.068 17.8488C126.766 17.6354 126.521 17.3531 126.354 17.0262C126.157 16.6222 126.063 16.1776 126.079 15.7301V9.22937H124.518L124.514 7.27515Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_30_174">
        <rect width="131" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SolidsportIcon
