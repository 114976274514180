import { CastSharp, CastConnectedSharp } from '@mui/icons-material'
import { Icon } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'

type CastButtonProps = {}

export const ChromeCastButton: React.FC<CastButtonProps> = () => {
  const { chromecast } = useContext(VideoPlayerContext)

  useEffect(() => {}, [chromecast.available])

  const load = async () => {
    chromecast.openContextMenu()
  }

  if (!chromecast.available) {
    return <></>
  }
  return (
    <button type="button" className="PlayerButton Chromecast" onClick={load}>
      {chromecast.connected ? (
        <Icon component={CastConnectedSharp} className="PlayerButtonIcon" />
      ) : (
        <Icon component={CastSharp} className="PlayerButtonIcon" />
      )}
    </button>
  )
}

export default ChromeCastButton
