import React from 'react'

export const DiamondIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77989 16.8993H45.2201M19.5326 2L15.0652 16.8993L24 45.4562L32.9348 16.8993L28.4674 2M25.3728 44.8665L45.3836 18.1711C45.7228 17.7186 45.8924 17.4924 45.9572 17.2399C46.0143 17.0173 46.0143 16.7813 45.9572 16.5587C45.8924 16.3062 45.7228 16.0799 45.3836 15.6275L35.7043 2.7148C35.5073 2.45205 35.4089 2.32068 35.2881 2.22624C35.1812 2.14256 35.0618 2.08041 34.9355 2.04263C34.793 2 34.6392 2 34.3315 2H13.6685C13.3608 2 13.207 2 13.0645 2.04263C12.9382 2.08041 12.8188 2.14255 12.7119 2.22624C12.5911 2.32068 12.4927 2.45205 12.2957 2.7148L2.61636 15.6275C2.27722 16.0799 2.10765 16.3061 2.04285 16.5587C1.98572 16.7813 1.98572 17.0173 2.04285 17.2399C2.10765 17.4924 2.27722 17.7186 2.61636 18.171L22.6272 44.8665C23.0987 45.4954 23.3344 45.8099 23.6166 45.9245C23.8642 46.0252 24.1358 46.0252 24.3834 45.9245C24.6656 45.8099 24.9013 45.4954 25.3728 44.8665Z"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
