import { AirplaySharp } from '@mui/icons-material'
import { Icon } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'
import './index.scss'

type AirplayButtonProps = {}

export const AirplayButton: React.FC<AirplayButtonProps> = () => {
  const { videoElement } = useContext(VideoPlayerContext)
  const [available, setAvailable] = useState(false)
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    if ((window as any).WebKitPlaybackTargetAvailabilityEvent) {
      videoElement.addEventListener(
        'webkitplaybacktargetavailabilitychanged',
        onAirplayAvailable
      )
    }
  }, [])

  const onAirplayAvailable = (event: any) => {
    switch (event.availability) {
      case 'available':
        setAvailable(true)
        break
      case 'not-available':
        setAvailable(false)
        break
      default:
        break
    }
    videoElement.removeEventListener(
      'webkitplaybacktargetavailabilitychanged',
      onAirplayAvailable
    )
  }

  useEffect(() => {
    if (available) {
      videoElement.addEventListener(
        'webkitcurrentplaybacktargetiswirelesschanged',
        onAirplayChanged
      )
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          'webkitcurrentplaybacktargetiswirelesschanged',
          onAirplayChanged
        )
      }
    }
  }, [available])

  const onAirplayChanged = () => {
    const onPause = () => {
      setTimeout(() => {
        videoElement.play()
        if (videoElement.playbackRate === 1) {
          videoElement.removeEventListener('pause', onPause)
        }
      }, 1000)
    }
    if (videoElement.webkitCurrentPlaybackTargetIsWireless) {
      setConnected(true)
      // Airplay calls pause when connecting for some reason,
      // without setting the video state to paused or playbackRate to 1.
      // Listen to the pause event and call play again after a delay.
      // If you call play too fast the fake pause event will beat you.
      videoElement.addEventListener('pause', onPause)
    } else {
      setConnected(false)
    }
  }

  const load = async () => {
    videoElement.webkitShowPlaybackTargetPicker()
  }

  const buttonClassNames = useCallback((): string => {
    const names = ['PlayerButton', 'Airplay']
    if (connected) {
      names.push('connected')
    }
    return names.join(' ')
  }, [connected])

  if (!available) {
    return <></>
  }
  return (
    <button type="button" className={buttonClassNames()} onClick={load}>
      <Icon component={AirplaySharp} className="PlayerButtonIcon AirplayIcon" />
    </button>
  )
}

export default AirplayButton
