import React, { FC } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux-store/store'

interface Props extends RouteProps {}

export const PrivateRoute: FC<Props> = ({ ...props }) => {
  const isChannelAdmin = useSelector(
    (state: RootState) => state.permissions.hasChannelAccess
  )

  return isChannelAdmin ? <Route {...props}>{props.children}</Route> : null
}
