import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
interface AdministratingChannelApiResponse {
  slug: string
  root_level_domain: string
  title: string
  sport: {
    id: number
    name: string
    slug: string
  }
  logo_image_url: string
  location: string
  partner_type: string
}
export const getAdministratingChannels = async () => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/administrating_channels',
      method: ApiEndpoint.METHODS.GET,
      authenticatable: true
    })

    const responseData = await resultResponse

    const mappedResponse = mapAdministratingChannelsResponse(responseData?.data)
    return { data: mappedResponse }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapAdministratingChannelsResponse = (
  apiResonse: AdministratingChannelApiResponse[]
) =>
  apiResonse.map((channel: AdministratingChannelApiResponse) => ({
    name: channel.title,
    logoUrl: channel.logo_image_url,
    sportName: channel.sport?.name,
    subdomain: channel.slug,
    rootDomain: channel.root_level_domain
  }))
