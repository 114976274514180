import * as yup from 'yup'
import { t } from '@lingui/macro'

export const imageLinkSchema = yup.object({
  openInNewTab: yup.boolean(),
  imageLinkUrl: yup
    .string()
    .required(t`Please enter a link for the image`)
    .url(t`Please enter web link in the format https://domain.com/path`)
})
