import React, { FC } from 'react'

export const ShareIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2471 5.42538H11.3721C10.709 5.42538 10.0731 5.68878 9.6043 6.15762C9.13546 6.62646 8.87207 7.26234 8.87207 7.92538V11.0504"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4971 9.17538L18.2471 5.42538L14.4971 1.67538"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7471 12.9254V19.1754C15.7471 19.5069 15.6154 19.8248 15.381 20.0593C15.1465 20.2937 14.8286 20.4254 14.4971 20.4254H3.24707C2.91555 20.4254 2.59761 20.2937 2.36319 20.0593C2.12877 19.8248 1.99707 19.5069 1.99707 19.1754V9.17538C1.99707 8.84386 2.12877 8.52592 2.36319 8.2915C2.59761 8.05708 2.91555 7.92538 3.24707 7.92538H5.12207"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
