import SPORTS from './sports.constants'

const fetchSportsStart = () => ({
  type: SPORTS.SPORTS_FETCH_START
})
const fetchSportsSuccess = (data: any) => ({
  type: SPORTS.SPORTS_FETCH_SUCCESS,
  data
})
const fetchSportsError = () => ({
  type: SPORTS.SPORTS_FETCH_ERROR
})

export default {
  fetchSportsStart,
  fetchSportsSuccess,
  fetchSportsError
}
