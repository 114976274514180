import React from 'react'

const BackwardIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.20786 0.660156L10.3309 1.78318L3.86131 8.25276H20.668V9.81522H3.86131L10.3309 16.2848L9.20786 17.4078L1.39554 9.5955L0.858446 9.03399L1.39554 8.47248L9.20786 0.660156Z" />
    </svg>
  )
}
export default BackwardIcon
