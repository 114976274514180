import React, { useEffect, useState } from 'react'
import { useFeatureFlags } from '../../../featureFlags/useFeatureFlags'
import App from '../../../app/App'
import { determineClientUrlAndRedirectIfNeeded } from '../../../determineClientUrlAndRedirectIfNeeded'

const SolidsportRedirect: React.FC = () => {
  const { getFlag, setFlag } = useFeatureFlags()
  type RedirectUrl = {
    [key: string]: string
  }
  const redirectUrls: RedirectUrl = {
    'https://solidsport.com': 'https://www.solidsport.com',
    'https://solidsport.com/': 'https://www.solidsport.com',
    'https://sportstage.se': 'https://www.sportstage.se',
    'https://sportstage.se/': 'https://www.sportstage.se'
    // Add the lines below to test from local environment
    // 'https://local.sportstage.se:3000/': 'https://www.sportstage.se',
    // 'https://local.sportstage.se:3000': 'https://www.sportstage.se'
  }

  const [redirectCheckDone, setRedirectCheckDone] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [thisIsTheWrongLocation, setThisIsTheWrongLocation] = useState(false)

  useEffect(() => {
    Object.keys(redirectUrls).forEach((k: string) => {
      if (k === window.location.href) {
        if (window.location.href !== redirectUrls[k]) {
          setRedirectUrl(redirectUrls[k])
        }
      }
    })
    if (
      !Object.keys(redirectUrls).includes(window.location.href) &&
      window.location.pathname === '/'
    ) {
      setThisIsTheWrongLocation(true)
    }
    setRedirectCheckDone(true)
  }, [])

  useEffect(() => {
    if (redirectCheckDone) {
      if (redirectUrl) {
        if (window.location.href !== redirectUrl) {
          window.location.href = redirectUrl
        }
      }
    }
  }, [redirectCheckDone, redirectUrl])

  useEffect(() => {
    if (thisIsTheWrongLocation) {
      determineClientUrlAndRedirectIfNeeded(getFlag, setFlag)
    }
  }, [thisIsTheWrongLocation])

  if (redirectCheckDone && !redirectUrl && !thisIsTheWrongLocation) {
    return <App />
  }

  return <></>
}

export default SolidsportRedirect
