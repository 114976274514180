import React from 'react'
import { GenericIcon } from 'assets/icons/CreditCards/GenricCardIcon'
import { MaestroIcon } from 'assets/icons/CreditCards/MaestroIcon'
import { MasterCardIcon } from 'assets/icons/CreditCards/MasterCardIcon'
import { VisaIcon } from 'assets/icons/CreditCards/VisaIcon'
import { CreditCardType } from './CreditCard'

export const getIconForCardType = (type: CreditCardType) => {
  switch (type) {
    case CreditCardType.MC:
      return <MasterCardIcon />
    case CreditCardType.Maestro:
      return <MaestroIcon />
    case CreditCardType.Visa:
      return <VisaIcon />
    case CreditCardType.Generic:
    default:
      return <GenericIcon />
  }
}
