import React, { FC } from 'react'

import { Video } from '../cardTypes'
import VideoThumbnail from '../../BroadcastThumbnail/index'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'

interface Props {
  content: Video
}

const SearchResultCardVideo: FC<Props> = ({ content }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = !(currentBreakpoint > Breakpoint.xs)
  const thumbnailSize = isMobile ? 'small' : 'medium'
  return (
    <div data-testid="video-search-result">
      <VideoThumbnail
        content={{
          duration: content.duration,
          channelImg: content.isReplay ? content.channelThumbnail : '',
          channelName: content.channelName,
          channelUrl: content.channelUrl,
          country: content.country,
          endTime: content.liveEndTime,
          hasHighlight: false,
          publishedAt: content.publishedAt,
          highlightFromDate: null,
          title: content.title,
          awayTeam: {
            badge_url: content.awayTeam.badgeUrl,
            country_code: content.awayTeam.country,
            id: content.awayTeam.id,
            name: content.awayTeam.name,
            slug: content.awayTeam.slug,
            shirt_colors: []
          },
          homeTeam: {
            badge_url: content.homeTeam.badgeUrl,
            country_code: content.homeTeam.country,
            id: content.homeTeam.id,
            name: content.homeTeam.name,
            slug: content.homeTeam.slug,
            shirt_colors: []
          },
          id: content.id,
          isReplay: content.isReplay,
          showViewers: false,
          showViews: false,
          startTime: content.liveStartTime,
          targetUrl: content.targetUrl,
          // @ts-ignore
          thumbnailImageUrl: content.thumbnailImageUrl,
          type: content.awayTeam.name === null ? 'video' : 'game',
          viewers: content.viewers,
          typeOfGame: content.typeOfGame,
          views: content.views
        }}
        shared={false}
        size={thumbnailSize}
        showDetails
        disableLink={false}
      />
    </div>
  )
}

export default SearchResultCardVideo
