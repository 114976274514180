import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link } from 'react-router-dom'
import { News } from '../../../interfaces/News.interface'
import NewsDate from './NewsDate'
import { NewsTitle } from './NewsTitle/NewsTitle'
import styles from './NewsThumbnail.module.scss'
import NewspaperFold from '../ArticleThumbnail/Icons/NewspaperFold'

interface NewsThumbnailProps {
  news: News
}

export const NewsThumbnail: FC<NewsThumbnailProps> = ({ news }) => {
  const [hover, setHover] = useState(false)
  const svgString = encodeURIComponent(renderToStaticMarkup(<NewspaperFold />))
  const { imageUrl, title, published, preamble, targetUrl } = news

  const imageStyle = {
    hover: {
      backgroundImage: imageUrl
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),url(${imageUrl})`
        : `linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),url("data:image/svg+xml,${svgString}")`
    }
  }
  return (
    <Link className={styles.NewsThumbnailLink} to={targetUrl}>
      <div
        data-testid="news-thumbnail-container"
        className={classNames(styles.NewsThumbnailContainer)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={classNames(
            imageUrl ? styles.ImageContainer : styles.NoImageContainer
          )}
          data-testid={
            imageUrl
              ? 'news-thumbnail-image-container'
              : 'news-thumbnail-no-image-container'
          }
        >
          <div
            className={styles.NewsImage}
            style={{
              backgroundImage: imageUrl
                ? `url(${imageUrl})`
                : `url("data:image/svg+xml;utf8,${svgString}")`,
              ...(hover ? imageStyle.hover : null)
            }}
          />
        </div>

        <div className={classNames(styles.NewsTextContainer)}>
          <NewsDate updatedDate={published} />
          <NewsTitle title={title} extraContent={preamble} />
        </div>
      </div>
    </Link>
  )
}
