import React, { FC } from 'react'
import classNames from 'classnames'
import PageLink from '../menuLink/MenuLink'
import { SubMenuLink } from '../../../pages/ChannelPage/ChannelSettings/settingsSubmenuLinks'

import styles from './Submenu.module.scss'

interface SubmenuProps {
  onItemSelect: (id: number) => void
  selectedItemId: number
  menuItems: SubMenuLink[]
  currentPage: string
}
export const Submenu: FC<SubmenuProps> = ({
  onItemSelect,
  selectedItemId,
  menuItems,
  currentPage
}) => {
  const filteredMenuItems = menuItems.filter(
    (item: SubMenuLink) => !item.hidden
  )

  const handleSubmenuSelected = (id: number) => (event: any) => {
    if (event?.type === 'click' || event?.key === ' ') {
      onItemSelect(id)
    }
  }
  return (
    <div
      className={styles.SubmenuContainer}
      data-testid="settings-submenu-container"
    >
      <ul data-testid="settings-submenu-list">
        {filteredMenuItems.map((link) => (
          <li
            key={link.id}
            className={classNames({
              [styles.Selected]: link.id === selectedItemId
            })}
            onClick={handleSubmenuSelected(link.id)}
            onKeyUp={handleSubmenuSelected(link.id)}
          >
            <PageLink {...link} link={`/${currentPage}${link.link}`} />
            <br />
          </li>
        ))}
      </ul>
    </div>
  )
}
