import { t } from '@lingui/macro'
import * as yup from 'yup'

export const newPasswordValidationSchema = () => {
  return yup.object({
    currentPassword: yup
      .string()
      .min(6, 'Must be at least 6 characters long')
      .required(t`Password is required`),
    newPassword: yup
      .string()
      .min(6, 'Must be at least 6 characters long')
      .required(t`New password is required`),
    confirmNewPassword: yup
      .string()
      .min(6, 'Type your password again')
      .required(t`Confirm password is required`)
      .oneOf(
        [yup.ref('newPassword'), null],
        'Confirm password must match the new password'
      )
  })
}
