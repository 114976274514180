import React, { FC } from 'react'

import { t } from '@lingui/macro'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './AuthButtons.module.scss'
import { Breakpoint, getWidth } from 'hooks/CurrentBreakpoint'
interface Props {
  isThemed?: boolean
}
export const AuthButtons: FC<Props> = ({ isThemed = false }) => {
  const dispatch = useDispatch()
  const screenWidth = getWidth()
  const showCreateAccountWitdh = isThemed ? Breakpoint.md : 1040
  return (
    <div className={styles.AuthButtons}>
      <Button
        data-testid="login-button"
        label={t`Login`}
        type={isThemed ? ButtonType.ThemedInverted : ButtonType.Secondary}
        buttonSize="Medium"
        onClick={() =>
          dispatch(authenticationActions.openLoginLayer(undefined, 'login'))
        }
      />
      {screenWidth >= showCreateAccountWitdh && (
        <Button
          data-testid="create-account-button"
          label={t`Create account`}
          type={isThemed ? ButtonType.ThemedPrimary : ButtonType.Primary}
          buttonSize="Medium"
          onClick={() =>
            dispatch(authenticationActions.openLoginLayer(undefined, 'signup'))
          }
        />
      )}
    </div>
  )
}
