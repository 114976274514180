import BroadcastImage from './Images/BroadcastImage.png'
import BroadcastImage2 from './Images/BroadcastImage2.png'

export const AsideImageSectionMockData = {
  id: 'AsideImageSection',
  sectionTitle: 'You broadcast live quickly and easily with the Broadcast app.',
  text: 'To be able to broadcast live with our broadcast app, you first need to create an account and a channel to broadcast from.',
  buttonLabel: 'Start broadcasting live',
  imagePath: BroadcastImage,
  videoPath: '',
  link: '/broadcast',
  backgroundColor: 'PrimaryBackground'
}
export const AsideImageSectionMockData2 = {
  id: 'AsideImageSection',
  sectionTitle: 'Everything you need is already in your pocket.',
  text: 'With the help of a smartphone, all sports at all levels can stream sports for free - and at the same time make money. From grassroots to tall. The revenue comes from the viewers who buy the broadcasts. At least half of the revenue always goes to the team or association that broadcasts.',
  buttonLabel: 'Start broadcasting live',
  imagePath: BroadcastImage2,
  videoPath: '',
  link: '/broadcast',
  backgroundColor: 'PrimaryBackground'
}

export const AsideVideoMockDataEng = {
  id: 'AsideVideoSection',
  sectionTitle: 'Everything you need is already in your pocket.',
  text: 'With the help of a smartphone, all sports at all levels can stream sports for free - and at the same time make money. From grassroots to tall. The revenue comes from the viewers who buy the broadcasts. At least half of the revenue always goes to the team or association that broadcasts.',
  buttonLabel: 'Start broadcasting live',
  imagePath: '',
  videoPath:
    'https://edg02-stg-se-ec2.ststage.se/cache/media_file/8b3e9a6b-402b-4e39-a21a-33b24104df60.mp4?cb=ea7e3ab0ddecc5a994f16b8b003980d0',
  link: '/broadcast',
  backgroundColor: 'SecondaryBackground'
}
