import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import RoundIconButton, {
  Scheme
} from '../../../components/buttons/RoundIconButton'
import styles from './LandingPageHeroSection.module.scss'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../hooks/CurrentBreakpoint'

import { LandingPageType } from '../LandingPageHero'

export enum heroColor {
  Dark = '#EC1E24',
  Light = 'F5F5F5'
}
export enum heroTextColor {
  White = '#FFFFFF',
  Black = '#000000'
}

interface Props {
  type: LandingPageType
  smallHeroText: string
  heroText: string
  backgroundImgPath: string
  urlPath: string
}

const LandingPageHeroSection: FC<Props> = ({
  type,
  smallHeroText,
  heroText,
  backgroundImgPath,
  urlPath
}) => {
  const breakPoint = useCurrentBreakpoint()
  const textStyle =
    type === LandingPageType.consume ? styles.Light : styles.Dark
  const sectionColorClass =
    type === LandingPageType.create
      ? styles.LightBackground
      : styles.BoldBackground
  const sectionStyle = [styles.LandingPageHeroSection, sectionColorClass].join(
    ' '
  )

  return (
    <Link className={styles.LandingPageHeroLink} to={urlPath}>
      <div className={sectionStyle}>
        <img src={backgroundImgPath} alt={smallHeroText} />
        <div className={styles.InnerContainer}>
          <div className={styles.TextContainer}>
            <h3 className={textStyle}>{smallHeroText}</h3>
            <h2 className={textStyle}>{heroText}</h2>
          </div>
          {type === LandingPageType.consume ? (
            <RoundIconButton
              iconSize={breakPoint <= Breakpoint.mobile ? 'medium' : 'large'}
              newClassName={Scheme.Light}
              onClick={() => null}
            />
          ) : (
            <RoundIconButton
              iconSize={breakPoint <= Breakpoint.mobile ? 'medium' : 'large'}
              newClassName={Scheme.Red}
              onClick={() => null}
            />
          )}
        </div>
      </div>
    </Link>
  )
}
export default LandingPageHeroSection
