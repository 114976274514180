import { ThemeOptions } from '../../../redux-store/channelTheme/channelTheme.interface'

const setDocumentProperty = (variable: string, value: string) => {
  document.documentElement.style.setProperty(variable, value)
}

export const applyTheme = (theme: ThemeOptions) => {
  setDocumentProperty(
    '--channel-theme--text-transform',
    theme?.channelTextTransformation || 'none'
  )
  setDocumentProperty(
    '--channel-theme--primary-color',
    theme.channelPrimaryColor
  )
  setDocumentProperty(
    '--channel-theme--primary-background-color',
    theme.channelPrimaryBackgroundColor
  )
  setDocumentProperty(
    '--channel-theme--primary-color-inverted',
    theme.channelPrimaryColorInverted
  )
  setDocumentProperty(
    '--channel-theme--primary-background-color-inverted',
    theme.channelPrimaryBackgroundColorInverted
  )
  if (theme.channelHeadingFontFamily) {
    setDocumentProperty(
      '--channel-theme--heading-font-family',
      theme.channelHeadingFontFamily
    )
  }
  if (theme.channelHeadingFontWeight) {
    setDocumentProperty(
      '--channel-theme--heading-font-weight',
      theme.channelHeadingFontWeight
    )
  }
  if (theme.channelSubmenuFontFamily) {
    setDocumentProperty(
      '--channel-theme--submenu-font-family',
      theme.channelSubmenuFontFamily
    )
  }
  if (theme.channelSubmenuFontWeight) {
    setDocumentProperty(
      '--channel-theme--submenu-font-weight',
      theme.channelSubmenuFontWeight
    )
  }
  if (theme.channelPrimaryHighlightColor) {
    setDocumentProperty(
      '--channel-theme--primary-highlight-color',
      theme.channelPrimaryHighlightColor
    )
  }
  if (theme.channelCallToActionColor) {
    setDocumentProperty(
      '--channel-theme--call-to-action-color',
      theme.channelCallToActionColor
    )
  }
  if (theme.channelCallToActionBackgroundColor) {
    setDocumentProperty(
      '--channel-theme--call-to-action-background-color',
      theme.channelCallToActionBackgroundColor
    )
  }
}
