import React, { FC } from 'react'
import { PlayerStatsVariantsIdentifiers } from '../PlayerStatisticsTable.interface'
import styles from './HeaderCell.module.scss'
import classNames from 'classnames'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { SortableHeaderItem } from './SortableHeader/SortableHeaderItem'
interface Props {
  name: string
  abbreviation: string
  identifier: string
  showArrow: boolean
  descending: boolean
  hasThemedHeader: boolean
  onClick: () => void
}

export const HeaderCell: FC<Props> = ({
  name,
  abbreviation,
  identifier,
  showArrow,
  descending,
  hasThemedHeader,
  onClick
}) => {
  const breakPoint = useCurrentBreakpoint()
  const TextToDisplay = breakPoint > Breakpoint.sm ? name : abbreviation
  switch (identifier) {
    case PlayerStatsVariantsIdentifiers.Player:
      return (
        <div className={classNames(styles.HeaderCell, styles.PlayerHeaderCell)}>
          <SortableHeaderItem
            label={TextToDisplay}
            onClick={onClick}
            showArrow={showArrow}
            hasThemedHeader={hasThemedHeader}
            descending={descending}
          />
        </div>
      )
    case PlayerStatsVariantsIdentifiers.TeamLogoUrl:
      return (
        <div
          className={classNames(styles.HeaderCell, styles.TeamHeaderCell, {
            [styles.Selected]: showArrow
          })}
        >
          <SortableHeaderItem
            label={TextToDisplay}
            onClick={onClick}
            showArrow={showArrow}
            hasThemedHeader={hasThemedHeader}
            descending={descending}
          />
        </div>
      )
    case PlayerStatsVariantsIdentifiers.Rank:
      return (
        <div className={classNames(styles.HeaderCell, styles.RankHeaderCell)}>
          {TextToDisplay}
        </div>
      )
    default:
      return (
        <div
          className={classNames(styles.HeaderCell, styles.DefaultHeaderCell, {
            [styles.Selected]: showArrow
          })}
        >
          <SortableHeaderItem
            label={TextToDisplay}
            onClick={onClick}
            showArrow={showArrow}
            hasThemedHeader={hasThemedHeader}
            descending={descending}
          />
        </div>
      )
  }
}
