import React, { FC } from 'react'
import { LinkType } from 'components/page/menuLink/MenuLink'
import { MenuItem } from '../menuItem/MenuItem'
import { t } from '@lingui/macro'
import { Channel } from 'api/channel/getChannel'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface Props {
  hasBackground: boolean
  themed: boolean
  rootChannel: Channel
  isUserPage: boolean
}

export const HomeMenuItem: FC<Props> = ({
  hasBackground,
  themed,
  rootChannel,
  isUserPage
}) => {
  const homeUrl = process.env.REACT_APP_HOME_URL
  const homeLinkType = process.env.REACT_APP_HOME_URL_LINK_TYPE
  const isUserPageAndHasRootChannelSubdomain =
    isUserPage && rootChannel && rootChannel.subdomain
  const rootChannelSubmdomain =
    isUserPageAndHasRootChannelSubdomain && rootChannel?.subdomain

  const link = isUserPageAndHasRootChannelSubdomain
    ? `/${rootChannelSubmdomain}`
    : `${homeUrl}`

  const defaultHomeLinkType =
    homeLinkType === LinkType.Route ? LinkType.Route : LinkType.AnchorTag

  const linkType = isUserPageAndHasRootChannelSubdomain
    ? LinkType.Route
    : defaultHomeLinkType

  return (
    <MenuItem
      linkType={linkType}
      label={t`Home`}
      link={link}
      dark={hasBackground}
      themed={themed}
      linkTarget={LinkTarget.NewWindow}
    />
  )
}
