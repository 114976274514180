import React, { FC } from 'react'

import {
  AccordionItem,
  AccordionTheme
} from 'components/Accordion/AccordionItem/AccordionItem'
import { Accordion } from 'components/Accordion/Accordion'

import styles from './PageListAccordion.module.scss'
import { ChildPageContent } from './ChildPageContent'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

export interface CustomPageMeta {
  slug: string
  title: string
  children: CustomPageMeta[]
  href?: string | null
  href_target?: string
}

interface Props {
  pageList: CustomPageMeta[]
  openLeaf?: string
  channelSlug: string
}

export const PageListAccordion: FC<Props> = ({
  pageList,
  channelSlug,
  openLeaf
}) => {
  const customMenuItem = pageList?.length > 0 && pageList[0]
  const accordionHeaders: AccordionItem[] = customMenuItem
    ? customMenuItem.children.map((page: CustomPageMeta) => ({
        headerText: page.title,
        icon: undefined,
        id: page.slug,
        href: page.href ? page.href : '',
        hrefTarget: page.href_target
          ? (page.href_target as LinkTarget)
          : LinkTarget.CurrentWindow,
        headerLink:
          page.children?.length === 0
            ? `/${channelSlug}/menu/${customMenuItem.slug}/${page.slug}`
            : undefined,
        children:
          page.children?.length > 0 ? (
            <ChildPageContent
              menuSlug={customMenuItem.slug}
              childPages={page.children}
              pageSlug={page.slug}
              channelSlug={channelSlug}
            />
          ) : undefined
      }))
    : []

  return (
    <div
      className={styles.PageListAccordionContainer}
      data-testid="page-list-accordion"
    >
      <Accordion
        initialOpenItems={openLeaf ? [openLeaf] : []}
        items={accordionHeaders}
        theme={AccordionTheme.White}
      />
    </div>
  )
}
