import React, { FC } from 'react'
import classNames from 'classnames'
import { Icon, IconButton } from '@mui/material'
import { Replay5Sharp } from '@mui/icons-material'

import styles from './Button.module.scss'

interface Props {
  onClick: () => void
}

export const RewindButton: FC<Props> = ({ onClick }) => (
  <IconButton
    aria-label="rewind"
    className={styles.OverlayButton}
    onClick={onClick}
  >
    <Icon
      component={Replay5Sharp}
      className={classNames(styles.OverlayIcon, styles.MediumOverlayIcon)}
    />
  </IconButton>
)
