import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface DeleteCommentProps {
  messageId: string
}
export const deleteComment = async (comment: DeleteCommentProps) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: '/comment',
      method: ApiEndpoint.METHODS.DELETE,
      params: {
        id: comment.messageId
      },
      authenticatable: true
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    throw new Error('delete comment message')
  }
}
