import React, { FC, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './breadcrumb.module.scss'
import { BreadCrumbLink } from './breadcrumbLink'

interface Props {
  pathList: BreadCrumbLink[]
}

export const Breadcrumb: FC<Props> = ({ pathList }) => (
  <div
    className={styles.BreadcrumbContainer}
    data-testid="breadcrumbs-container"
  >
    {pathList.map((path, i) => {
      const isLastCrumb = i === pathList.length - 1
      return (
        <Fragment key={`${path.link}-${i}`}>
          <div
            className={classNames(styles.Breadcrumb, {
              [styles.CurrentPage]: isLastCrumb
            })}
          >
            {isLastCrumb ? (
              path.name
            ) : (
              <Link to={`/${path.link}`}>{path.name}</Link>
            )}
          </div>
          {!isLastCrumb && <div className={styles.Breadcrumb}>/</div>}
        </Fragment>
      )
    })}
  </div>
)
