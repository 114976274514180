import React, { FC } from 'react'
import styles from './RefereeAccess.module.scss'
import ContentContainer from 'components/ContentContainer'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { Trans, t } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'

export const NotSignedIn: FC = () => {
  const dispatch = useDispatch()
  return (
    <ContentContainer
      hasLeftRightPadding={true}
      hasTopBottomPadding={true}
      hasLightBackground={true}
    >
      <div className={styles.NotSignedIn}>
        <div className={styles.TextContent}>
          <h4 className={styles.Title}>
            <Trans>Sign in to continue</Trans>
          </h4>
          <div className={styles.SubText}>
            <Trans>
              To be able to see this page, you need to create a Solidsport
              account or sign in to your existing account.
            </Trans>
          </div>
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            data-testid="create-account-button"
            label={t`Create account`}
            type={ButtonType.CreateAccount}
            buttonSize="Medium"
            onClick={() =>
              dispatch(
                authenticationActions.openLoginLayer(undefined, 'signup')
              )
            }
          />
          <Button
            data-testid="login-button"
            label={t`Login`}
            type={ButtonType.Primary}
            buttonSize="Medium"
            onClick={() =>
              dispatch(authenticationActions.openLoginLayer(undefined, 'login'))
            }
          />
        </div>
      </div>
    </ContentContainer>
  )
}
