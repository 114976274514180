import { NativeAd } from 'advertising/NativeAd/NativeAd'
import { Channel } from 'api/channel/getChannel'
import ContentContainer from 'components/ContentContainer'
import React, { FC } from 'react'
import { getChannelAdTargeting } from './getChannelAdTargeting'

interface Props {
  channelDetails?: Channel
}

export const ChannelStartAdSlot: FC<Props> = ({ channelDetails }) =>
  channelDetails ? (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding={false}
      hasLightBackground
    >
      <NativeAd
        size={['fluid']}
        slotPath="/21792873324/Channel_Start_First_Slot"
        adId="div-gpt-ad-1679482506410-0"
        targetingOptions={getChannelAdTargeting(channelDetails)}
      />
    </ContentContainer>
  ) : null
