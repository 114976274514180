import { t } from '@lingui/macro'
import React, { FC } from 'react'
import { FormModal } from 'features/sportData/FormModal/FormModal'
import { DateFilterForm } from './DateFilterForm'
import { EventDate } from 'api/multistageTournament/getEventDates'

interface Props {
  dropdownList: EventDate[]
  preSelectedDate: EventDate | undefined
  onClose: () => void
  onSelectedDate: (selectedDate: EventDate) => void
}

export const DateFilterModal: FC<Props> = ({
  dropdownList,
  preSelectedDate,
  onClose,
  onSelectedDate
}) => {
  return (
    <FormModal
      header={t`Select date`}
      modalHeader={t`filter by date`}
      preHeader=""
      modalInformation={t`Select the date for the games you want to see`}
      onClose={onClose}
      defaultOpen={true}
    >
      <DateFilterForm
        dropdownList={dropdownList}
        preSelectedDate={preSelectedDate}
        onCloseForm={onClose}
        onSelectedDate={onSelectedDate}
      />
    </FormModal>
  )
}
