import React, { FC } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Ghost = 'Ghost',
  GhostInverted = 'GhostInverted',
  CreateAccount = 'CreateAccount',
  ThemedPrimary = 'ThemedPrimary',
  ThemedInverted = 'ThemedInverted',
  CTA = 'CTA'
}

export interface IconButtonProps {
  id: string
  type: ButtonType
  buttonSize: 'Small' | 'Medium' | 'Large'
  label: string | null
  externalStyle?: string | null
  isFluid?: boolean
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  children?: React.ReactNode
  buttonRef?: any
  isRounded?: boolean
}
const IconTextButton: FC<IconButtonProps> = ({
  id,
  type,
  buttonSize,
  label,
  children,
  externalStyle,
  isFluid,
  onClick,
  onMouseEnter,
  onMouseLeave,
  buttonRef,
  isRounded = false,
  ...props
}) => {
  return (
    <div
      ref={buttonRef}
      data-testid="icon-text-button"
      tabIndex={0}
      role="button"
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(
        externalStyle,
        styles.IconTextButton,
        styles[type],
        styles[buttonSize],
        { [styles.WithoutLabel]: !label },
        { [styles.IsFluid]: isFluid },
        { [styles.IsRounded]: isRounded }
      )}
      {...props}
    >
      <div
        role="button"
        data-testid="button-icon"
        className={classNames(styles.Icon, { [styles.IconWithLabel]: label })}
      >
        {children}
      </div>
      {label && <div className={styles.Label}>{label}</div>}
    </div>
  )
}

IconTextButton.defaultProps = {
  externalStyle: null,
  isFluid: false
}
export default IconTextButton
