import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
interface FollowingChannelApiResponse {
  slug: string
  root_level_domain: string
  title: string
  sport: {
    id: number
    name: string
    slug: string
  }
  logo_image_url: string
  location: string
  partner_type: string
}
export const getFollowingChannels = async () => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/following_channels',
      method: ApiEndpoint.METHODS.GET,
      authenticatable: true
    })

    const responseData = await resultResponse
    const mappedResponse = mapFollowingChannelsResponse(responseData?.data)
    return { data: mappedResponse }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapFollowingChannelsResponse = (
  apiResonse: FollowingChannelApiResponse[]
) =>
  apiResonse.map((channel: FollowingChannelApiResponse) => ({
    name: channel.title,
    logoUrl: channel.logo_image_url,
    sportName: channel.sport?.name,
    subdomain: channel.slug,
    rootDomain: channel.root_level_domain
  }))
