import { VideoMeta } from 'meta/VideoMeta'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { streamSelector } from 'redux-store/stream/stream.selectors'

export const VideoPageMeta: FC = () => {
  const channel = useSelector(channelSelector)
  const stream = useSelector(streamSelector)
  const mediaObject = stream.data.mediaObject

  let PageMetaData = <></>
  try {
    PageMetaData =
      channel?.data && mediaObject ? (
        <VideoMeta channel={channel.data} mediaObject={mediaObject} />
      ) : (
        <></>
      )
  } catch (e) {
    console.log('Could not set headerData in Viewer Page', e)
  }
  return <>{PageMetaData}</>
}
