import React, { FC, useEffect } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { t } from '@lingui/macro'
import { PasswordField } from 'components/Forms/PasswordField/PasswordField'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { loginFormValidationSchema } from './LoginFormValidationSchema'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './LoginForm.module.scss'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
import useAuthentication from 'hooks/Authentication'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'

interface LoginFormValues {
  userEmail: string
  password: string
}
interface Props {
  onFormSuccess: () => void
  onForgotPasswordClick: () => void
}
export const LoginForm: FC<Props> = ({
  onFormSuccess,
  onForgotPasswordClick
}) => {
  const { loggedIn, loggingIn, error } = useAuthentication()
  const dispatch = useDispatch()

  const initialValues: LoginFormValues = {
    userEmail: '',
    password: ''
  }
  useEffect(() => {
    dispatch(authenticationActions.clearState())
    dispatch(authenticationActions.loginCallback())
  }, [])

  const onSubmit = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    const submitValues = { ...values }
    formikHelpers.setSubmitting(true)
    dispatch(authenticationActions.clearState())
    dispatch(
      authenticationActions.login(
        submitValues.userEmail.toLowerCase(),
        submitValues.password
      )
    )
    formikHelpers.setSubmitting(false)
  }

  useEffect(() => {
    if (!loggingIn && loggedIn) {
      onFormSuccess()
    }
  }, [loggingIn, loggedIn])

  return (
    <div className={styles.LoginFormContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={loginFormValidationSchema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <Form>
              <div className={styles.Form}>
                <Field
                  component={TextInput}
                  id="userEmail"
                  data-testid="email-input"
                  name="userEmail"
                  placeholder={t`Email`}
                  onChange={formik.handleChange}
                  textValue={formik.values.userEmail}
                  isValid={!formik.errors.userEmail}
                  validationMessage={formik.errors.userEmail}
                />
                {error?.message && (
                  <FormValidationError errorMessage={error.message} />
                )}

                <Field
                  component={PasswordField}
                  id="password"
                  data-testid="password-input"
                  name="password"
                  placeholder={t`Password`}
                  onChange={formik.handleChange}
                  textValue={formik.values.password}
                  isValid={!formik.errors.password}
                  validationMessage={formik.errors.password}
                />

                <ClickableTextButton
                  onClick={onForgotPasswordClick}
                  clickableText={t`Forgot your password?`}
                />
                <SubmitButton
                  type="secondary"
                  customStyle={styles.LoginButton}
                  disabled={!formik.dirty || formik.isSubmitting}
                  label={t`Login`}
                  data-testid="login-form-button"
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
