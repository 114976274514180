import React, { FC } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { step2ValidationSchema } from './SignupValidationSchemas'
import { PasswordField } from 'components/Forms/PasswordField/PasswordField'
import { CheckBoxSection } from './CheckBoxSection'
import classNames from 'classnames'
import styles from './Form.module.scss'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'

export interface SignupStep2Values {
  newPassword: string
  confirmNewPassword: string
  privacyPolicyChecked: boolean
  recieveEmailChecked: boolean
}
interface Props {
  onSubmitForm: (password: string, recieveEmailChecked: boolean) => void
  onBackClicked: () => void
  errorMessage?: string
}

export const SignupStep2Form: FC<Props> = ({
  onSubmitForm,
  onBackClicked,
  errorMessage
}) => {
  const initialValues: SignupStep2Values = {
    newPassword: '',
    confirmNewPassword: '',
    privacyPolicyChecked: false,
    recieveEmailChecked: false
  }
  const onSubmit = async (
    values: SignupStep2Values,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    onSubmitForm(values.confirmNewPassword, values.recieveEmailChecked)
    formikHelpers.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={step2ValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.FormContainer}>
              <div
                className={classNames(styles.FormFields, styles.PasswordFields)}
              >
                {errorMessage && (
                  <FormValidationError errorMessage={errorMessage} />
                )}
                <Field
                  component={PasswordField}
                  id="newPassword"
                  data-testid="password-input"
                  name="password"
                  placeholder={t`Password`}
                  onChange={formik.handleChange}
                  textValue={formik.values.newPassword}
                  isValid={!formik.errors.newPassword}
                  validationMessage={formik.errors.newPassword}
                  defaultFocus={true}
                />
                <Field
                  component={PasswordField}
                  id="confirmNewPassword"
                  data-testid="confirm-password-input"
                  name="confirmNewPassword"
                  placeholder={t`Confirm password`}
                  onChange={formik.handleChange}
                  textValue={formik.values.confirmNewPassword}
                  isValid={!formik.errors.confirmNewPassword}
                  validationMessage={formik.errors.confirmNewPassword}
                />
              </div>
              <CheckBoxSection formik={formik} />
              <div className={styles.ButtonContainer}>
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SubmitButton}
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                  label={t`Create account`}
                />
                <Button
                  buttonSize="Medium"
                  customStyle={styles.BackButton}
                  type={ButtonType.GhostWithBoarder}
                  label={t`Back`}
                  onClick={onBackClicked}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
