import React from 'react'
export const CreateNewAccountIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 12.5C7.85831 12.5 5.50904 13.7755 4.01337 15.755C3.69146 16.181 3.5305 16.394 3.53577 16.6819C3.53984 16.9043 3.67951 17.1849 3.85451 17.3222C4.08103 17.5 4.39493 17.5 5.02273 17.5H15.9773C16.6051 17.5 16.9191 17.5 17.1456 17.3222C17.3206 17.1849 17.4602 16.9043 17.4643 16.6819C17.4696 16.394 17.3086 16.181 16.9867 15.755C15.491 13.7755 13.1418 12.5 10.5 12.5Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 10C12.5711 10 14.25 8.32107 14.25 6.25C14.25 4.17893 12.5711 2.5 10.5 2.5C8.42897 2.5 6.75004 4.17893 6.75004 6.25C6.75004 8.32107 8.42897 10 10.5 10Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="11.3334"
      y="10.8333"
      width="7.5"
      height="7.5"
      rx="3.75"
      fill="#2F3135"
    />
    <path
      d="M13.2835 14.5833H16.8835"
      stroke="white"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0835 16.3833V12.7833"
      stroke="white"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
