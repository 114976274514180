import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChannelAbout } from './channelAbout.interface'

export interface ChannelAboutState {
  slug: string
  isLoading: boolean
  isError: boolean
  data?: ChannelAbout
  error?: any
}

export const initialState = {
  slug: '',
  isLoading: false,
  isError: false,
  data: undefined,
  error: undefined
} as ChannelAboutState

const channelAboutSlice = createSlice({
  name: 'channelAbout',
  initialState,
  reducers: {
    getChannelAboutRequest(state, action: PayloadAction<string>) {
      state.slug = action.payload
      state.data = undefined
      state.isLoading = true
      state.isError = false
    },
    getChannelAboutSuccess(state, action: PayloadAction<ChannelAbout>) {
      state.data = action.payload
      state.isLoading = false
      state.isError = false
    },
    getChannelAboutError(state, action: PayloadAction<any>) {
      state.error = action.payload
      state.data = undefined
      state.isLoading = false
      state.isError = true
    }
  }
})

export const {
  getChannelAboutRequest,
  getChannelAboutSuccess,
  getChannelAboutError
} = channelAboutSlice.actions

export default channelAboutSlice.reducer
