import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './ChecklistItem.module.scss'
import RightArrow from './RightArrow'
import TickIcon from './TickIcon'

interface Props {
  itemNumber: number
  title: string
  subTitle: string
  isActive: boolean
  isComplete: boolean
  onClick: () => void
}

const ChecklistItem: FC<Props> = ({
  itemNumber,
  title,
  subTitle,
  isActive,
  isComplete,
  onClick
}) => (
  <div
    role="button"
    className={classNames(styles.ChecklistItem, {
      [styles.Default]: !isComplete || !isActive,
      [styles.Completed]: isComplete,
      [styles.Active]: isActive
    })}
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
  >
    <div className={styles.TextContainer}>
      <h5 className={styles.Title}>{`${itemNumber}. ${title}`}</h5>
      <div className={styles.SubTitle}>{subTitle}</div>
    </div>
    <div className={styles.Icon}>
      {isComplete ? <TickIcon /> : <RightArrow />}
    </div>
  </div>
)

export default ChecklistItem
