/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'

import LoadMoreSection from '../../../components/LoadMoreSection/LoadMoreSection'
import { CHANNEL_SUB_MENU_ID } from '../Header/Submenu/Submenu'
import { ChannelListItemPreloader } from 'components/lists/ChannelListItem/ChannelListItemPreloader'
import { Loader } from 'components/Loader/Loader'

import styles from './ChannelPageList.module.scss'

interface ChannelPageListProps {
  isError: boolean
  isLoading: boolean
  isEmpty: boolean
  onLoadMoreClick: () => void
  currentPage: number
  totalPages: number
  emptyMessage: string
  children?: React.ReactNode
}

export const ChannelPageList: FC<ChannelPageListProps> = ({
  isError,
  isLoading,
  isEmpty,
  onLoadMoreClick,
  currentPage,
  totalPages,
  emptyMessage,
  children
}) => {
  if (isError) {
    return (
      <div className={styles.EmptyListMessage}>
        <Trans>
          An error occurred while fetching the list of channels. Please reload
          the page to try again
        </Trans>
      </div>
    )
  }
  return !isEmpty ? (
    <div
      className={styles.ListContainer}
      data-testid="channel-page-list-container"
    >
      {children}
      {isLoading && <Loader />}
      <LoadMoreSection
        onClick={onLoadMoreClick}
        currentPage={currentPage}
        totalPages={totalPages}
        scrollId={CHANNEL_SUB_MENU_ID}
      />
    </div>
  ) : isLoading ? (
    <div
      className={classNames(styles.ListContainer, styles.Loading)}
      data-testid="channel-page-list-container-loading"
    >
      {[...Array(5)].map((e, i) => (
        <ChannelListItemPreloader key={i} />
      ))}
    </div>
  ) : (
    <div
      className={styles.EmptyListMessage}
      data-testid="empty-channel-page-list-text-container"
    >
      {emptyMessage}
    </div>
  )
}
