import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { useComponentVisible } from '../../../helpers/useComponentVisible'
import {
  DropdownItemList as List,
  DropDownListItem
} from './DropdownItemList/DropdownItemList'

import styles from './DropdownItemList/dropdownItemList.module.scss'
import listStyle from './dropdownList.module.scss'
import { DropdownField } from './DropdownField/Field'
import { FormValidationError } from '../FormValidationError/FormValidationError'

interface Props {
  dropdownListData: DropDownListItem[] | null
  onChange: (id: string) => void
  value: string | undefined
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  type: 'dark' | 'light'
}
export const DropdownList: FC<Props> = ({
  dropdownListData,
  onChange,
  value,
  id,
  label,
  placeholder,
  isValid,
  validationMessage,
  type
}) => {
  const initialItemLabel: string | undefined = value
    ? dropdownListData?.find((item) => item.id === value)?.label
    : placeholder
  const [selectedItemLabel, setSelectedItemLabel] = useState(initialItemLabel)
  const [selectedItemId, setSelectedItemId] = useState<string>(value || '')
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const toggleListIsOpen = () => {
    if (isComponentVisible) {
      setIsComponentVisible(false)
    } else {
      setIsComponentVisible(true)
    }
  }

  const onItemSelected = (selectedLabel: string, selectedId: string) => {
    setSelectedItemLabel(selectedLabel)
    setSelectedItemId(selectedId)
    onChange(selectedId)
    setIsComponentVisible(false)
  }

  const isPlaceholder = !!(
    placeholder &&
    placeholder !== '' &&
    !value &&
    selectedItemLabel === placeholder
  )

  return (
    <div className={listStyle.DropdownListContainer}>
      {!!label && <div className={listStyle.Label}>{label}</div>}
      <div
        className={classNames(styles.FieldDropdown, {
          [styles.Open]: isComponentVisible
        })}
        ref={ref}
      >
        <DropdownField
          selected={selectedItemLabel}
          onClick={toggleListIsOpen}
          rotateArrow={isComponentVisible}
          type={type}
          isPlaceholder={isPlaceholder}
        />
        {isComponentVisible && (
          <List
            id={id}
            list={dropdownListData}
            onItemSelected={(name, ident) => onItemSelected(name, ident)}
            value={selectedItemId!}
          />
        )}
      </div>
      {!isValid && <FormValidationError errorMessage={validationMessage} />}
    </div>
  )
}
