type DateUTC = number
type DateUTCOffset = number
export default class AdjustingInterval {
  // eslint-disable-next-line no-undef
  timeout: NodeJS.Timeout

  expected: DateUTC

  interval: DateUTCOffset

  threshold: DateUTCOffset

  stoped: boolean = false

  work: () => void

  error: () => void

  constructor(
    work: () => void,
    interval: number,
    threshold: number = 0,
    error: () => void = () => {}
  ) {
    this.work = work
    this.error = error
    this.interval = interval
    this.threshold = threshold

    this.expected = Date.now() + this.interval

    this.timeout = setTimeout(this.step, this.interval)
  }

  stop = () => {
    this.stoped = true
    if (this.timeout) clearTimeout(this.timeout)
  }

  step = () => {
    const drift = Date.now() - this.expected
    if (drift > this.interval + this.threshold) {
      this.error()
    }
    this.work()
    this.expected += this.interval
    if (!this.stoped) {
      this.timeout = setTimeout(this.step, Math.max(0, this.interval - drift))
    }
  }
}
