import React from 'react'
export const FastForwardIcon = () => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 9.5L11 6L6.5 2.5V9.5Z"
      fill="#2F3135"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 9.5L5.5 6L1 2.5V9.5Z"
      fill="#2F3135"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
