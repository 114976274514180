import { t } from '@lingui/macro'
import { MenuLink } from '../../../../components/page/menuLink/MenuLink'
import { settingsSubmenuLinks } from '../../ChannelSettings/settingsSubmenuLinks'
import { contentManagerSubmenuLinks } from '../../ContentManager/SubMenu/contentManagerSubmenuLinks'
import { links as channelMenuLinks } from '../menuLinks'

export const breadcrumbLinks = [
  ...contentManagerSubmenuLinks,
  ...channelMenuLinks,
  ...settingsSubmenuLinks,
  {
    link: '/impressum',
    translationText: 'Impressum',
    showInBreadcrumb: true
  },
  {
    link: '/contentmanager',
    translationText: t`Content manager`,
    showInBreadcrumb: true
  }
] as MenuLink[]
