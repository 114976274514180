import React, { FC } from 'react'
import { t } from '@lingui/macro'

import styles from './TeamsHeading.module.scss'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import { AddIcon } from '../../../GameScheduleListContainer/AddIcon'

interface Props {
  heading: string
  subheading: string
  onAddTeam: () => void
  showAddButton: boolean
}
export const TeamsHeading: FC<Props> = ({
  heading,
  subheading,
  onAddTeam,
  showAddButton
}) => {
  return (
    <div className={styles.HeadingContainer}>
      <div className={styles.TopContainer}>
        <h4 className={styles.Heading}>{heading}</h4>
        {showAddButton && (
          <IconTextButton
            label={t`Add team`}
            type={ButtonType.Ghost}
            buttonSize="Medium"
            id="add-team"
            onClick={onAddTeam}
          >
            <AddIcon />
          </IconTextButton>
        )}
      </div>
      <div className={styles.Subheading}>{subheading}</div>
    </div>
  )
}
