import React from 'react'
import cssTheme from '../channel.module.scss'
import { useChannelTheme } from './useChannelTheme'
import { applyTheme } from './applyTheme'

export const useTheme = () => {
  const { channelTheme } = useChannelTheme()
  if (channelTheme) {
    applyTheme(channelTheme)
  }
  return {
    Theme: ({ children }: any) => (
      <div className={cssTheme.Theme}>{children}</div>
    )
  }
}
