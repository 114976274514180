import React, { FC, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import ContentContainer from 'components/ContentContainer'

import { AccordionTheme } from 'components/Accordion/AccordionItem/AccordionItem'
import { Accordion } from 'components/Accordion/Accordion'
import { CustomContentAccordionListId } from './customContentAccordionList'
import { MegagphoneIcon } from './HeroImageCallToAction/MegafoneIcon'
import { HeroImageCallToActionSection } from './HeroImageCallToAction/HeroImageCallToActionSection'
import { FontSettingsIcon } from 'pages/ChannelPage/ChannelSettings/Branding/Fonts/FontSettingsIcon'
import { StartPageBannerList } from './StartPageBannerList/StartPageBannerList'

import styles from './CustomContent.module.scss'
import { InformationModal } from './InformationModal/InformationModal'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

export const CustomContent: FC = () => {
  const [showInformationModal, setShowInformationModal] = useState(false)
  const [selectedInformationModal, setSelectedInformationModal] = useState(
    CustomContentAccordionListId.StartPageBanner
  )

  const channel = useSelector(channelSelector)
  const channelSlug = channel.data.subdomain

  const accordionList = [
    {
      headerText: t`Start page banner`,
      icon: FontSettingsIcon,
      id: CustomContentAccordionListId.StartPageBanner,
      children: <StartPageBannerList channelSlug={channelSlug} />
    },
    {
      headerText: t`Hero image promotion`,
      icon: MegagphoneIcon,
      id: CustomContentAccordionListId.HeroImagePromotion,
      children: <HeroImageCallToActionSection channelSlug={channelSlug} />
    }
  ]

  const handleOnInformationClick = (id: CustomContentAccordionListId) => {
    setSelectedInformationModal(id)
    setShowInformationModal(true)
  }
  return (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding={false}
      hasLightBackground={false}
    >
      <div className={styles.Container}>
        <div className={styles.TopInfo}>
          <h4>
            <Trans>Add custom content to the channel</Trans>
          </h4>
          <div className={styles.Text}>
            <Trans>
              To get the most out of your channel you have the option to add a
              start banner
            </Trans>
          </div>
        </div>
        <Accordion
          theme={AccordionTheme.Grey}
          items={accordionList}
          initialOpenItems={[CustomContentAccordionListId.StartPageBanner]}
          showInformationButton
          onInformationClick={(id: any) => handleOnInformationClick(id)}
        />
        {showInformationModal && (
          <InformationModal
            onClose={() => setShowInformationModal(false)}
            defaultOpen={showInformationModal}
            customContenttype={selectedInformationModal}
          />
        )}
      </div>
    </ContentContainer>
  )
}
