import React, { FC } from 'react'
import { DownArrow } from './DownArrow'
import styles from './SortableHeaderItem.module.scss'
import classNames from 'classnames'
interface Props {
  label: string
  onClick: () => void
  showArrow: boolean
  descending: boolean
  hasThemedHeader: boolean
}

export const SortableHeaderItem: FC<Props> = ({
  label,
  onClick,
  showArrow,
  descending,
  hasThemedHeader
}) => (
  <div
    role="button"
    className={classNames(styles.Container, {
      [styles.ThemedHeader]: hasThemedHeader
    })}
    onClick={onClick}
    onKeyUp={onClick}
    tabIndex={0}
  >
    <div className={styles.Label}>{label}</div>
    {showArrow && (
      <div
        className={classNames(styles.Icon, {
          [styles.ArrowDown]: descending,
          [styles.ArrowUp]: !descending
        })}
      >
        <DownArrow />
      </div>
    )}
  </div>
)
