import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { GreenTickIcon } from './GreenTickIcon'
import styles from './GreenTickBullet.module.scss'

interface Props {
  bulletText: string
}
export const GreenTickBullet: FC<Props> = ({ bulletText }) => (
  <div className={styles.GreenTickBulletContainer}>
    <div className={styles.GreenTick}>
      <GreenTickIcon />
    </div>
    <Trans id={bulletText} />
  </div>
)
