import { Article } from '../../interfaces'
import { ArticleResponse } from '../../interfaces/ArticleResponse'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getArticleData = async ({
  channelSlug,
  articleSlug
}: {
  channelSlug: string
  articleSlug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `article/${articleSlug}`,
      params: { company: channelSlug }
    })
    const responseData = await resultResponse
    return mapNewsArticleResponse(responseData.data)
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapNewsArticleResponse = (
  apiResponse: ArticleResponse
): Article => ({
  id: apiResponse.slug,
  title: apiResponse.title,
  preamble: apiResponse.preamble_text,
  imageUrl: apiResponse.image_url,
  published: apiResponse.published_at,
  content: apiResponse.content,
  targetUrl: ''
})
