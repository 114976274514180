import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'

import { isIOS, isSafari, isChrome } from 'react-device-detect'

import VideoElement from '../../components/video/VideoPlayer/VideoElement'
import useHLS, { HLSState, HLS_STATES } from '../../hooks/HLS'
import useVideoAds from '../../hooks/VideoAds/VideoAds'
import { useVideoStatistics } from '../../hooks/VideoStatistics'
import { DebugWindow } from '../../components/video/VideoPlayer/DebugWindow'
import useChromecast from '../../hooks/Chromecast'
import useExternalScript from '../../hooks/ExternalScript'
import { useFeatureFlags } from 'featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from 'featureFlags/FeatureFlagsAvailable'
import { VideoAdsType } from 'hooks/VideoAds/videoAdsType'

import { useFullscreen } from './useFullscreen'
import { useVideoAdsStatusCode } from './useVideoAdsStatusCode'
import { usePlayerControl } from './usePlayerControl'
import { useCreateListeners } from './useCreateListeners'
import { useChromecastEvents } from './useChromecastEvents'
import { togglePlayCallback } from './togglePlayCallback'
import { adStatisticsCallback } from './adStatisticsCallback'

import { BigPlayButton } from './BigPlayButton/BigPlayButton'
import { AdContainer } from './AdContainer'
import { MediaObject } from 'interfaces'
import { ViewerStreamUrls } from 'features/viewerPage/viewerPage.interfaces'

export const VideoPlayerContext = React.createContext<any>([])

interface VideoPlayerProviderProps {
  children: React.ReactNode
  streamUrls: ViewerStreamUrls | any
  adsURL: string | undefined
  thumbnail: string | undefined
  ident: string | undefined
  subtitles: any
  autoPlay: boolean
  seekTo?: number
  company: string | undefined
  videoWrapper: RefObject<HTMLDivElement> | null
  mediaObject: MediaObject | undefined
}
export const VideoPlayerProvider: React.FC<VideoPlayerProviderProps> = ({
  children,
  streamUrls = { hls: undefined, dvr: undefined, dash: undefined },
  adsURL = undefined,
  thumbnail,
  ident = undefined,
  subtitles,
  company,
  seekTo = undefined,
  videoWrapper = null,
  mediaObject = undefined
}) => {
  useExternalScript(
    'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1',
    isChrome
  )
  useExternalScript(
    'https://www.gstatic.com/cast/sdk/libs/receiver/2.0.0/cast_receiver.js',
    isChrome
  )

  const isDvr = !!streamUrls?.dvr
  const streamSrc = isDvr ? streamUrls.dvr : streamUrls?.hls

  const { getFlag } = useFeatureFlags()
  const videoElement = useRef<any>()
  const getVideoElement = () => {
    return videoElement?.current?.element()
  }
  const [fullscreen, setFullscreen] = useState(false)
  const adContainer = useRef<any>()
  const bigPlay = useRef<any>()
  const [adsDone, setAdsDone] = useState(false)
  const hlsState: HLSState = useHLS({
    src: streamSrc,
    videoElement: getVideoElement(),
    adsDone: adsDone
  })
  const videoAds: VideoAdsType = useVideoAds(
    adsURL,
    getVideoElement(),
    adContainer.current,
    true
  )
  const [adsError, setAdsError] = useState(false)
  const [firstInteractionDone, setFirstInteractionDone] = useState(false)
  const [readyToPlay, setReadyToPlay] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)
  const videoStatistics = useVideoStatistics({
    videoPlayer: videoElement.current?.element(),
    media_object: ident,
    domain: company
  })
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoSeeking, setVideoSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [currentVolume, setCurrentVolume] = useState(1)
  const [duration, setDuration] = useState(0)
  const chromecast = useChromecast({ url: streamSrc })

  const [seekToOnPlay, setSeekToOnPlay] = useState<number | undefined>(seekTo)

  useFullscreen({ setFullscreen, videoAds, getVideoElement })

  useEffect(() => {}, [chromecast])

  useEffect(() => {
    if (videoStatistics && getVideoElement() && ident && company) {
      if (!videoStatistics.started) {
        videoStatistics.startSession()
      }
    }
  }, [videoStatistics, videoElement.current, ident, company])

  const debug = getFlag(FeatureFlagsAvailable.VideoDebug)

  const log = (message: string, ...params: any) => {
    if (debug) {
      console.log('> provider: ' + message, ...params)
    }
  }

  useEffect(() => {}, [firstInteractionDone])

  useEffect(() => {
    if (debug && hlsState.hls) {
      hlsState.hls.config.debug = true
    }
    setAdsDone(false)
    setFirstInteractionDone(false)
    setReadyToPlay(false)
    setVideoEnded(false)
  }, [ident])

  useEffect(() => {
    if (readyToPlay) {
      onFirstInteraction(null)
    }
  }, [readyToPlay])

  const onFirstInteraction = useCallback(
    (e: any) => {
      log('onFirstInteraction')
      // if (!hlsState.hls.config.autoStartLoad) {
      //   hlsState.hls.startLoad()
      // }
      if (videoStatistics) {
        videoStatistics.setInitialPlayLatency()
      }
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (readyToPlay) {
        if (bigPlay.current) {
          bigPlay.current.removeEventListener('click', onFirstInteraction)
          bigPlay.current.removeEventListener('touchstart', onFirstInteraction)
        }
        if (adContainer.current) {
          adContainer.current.removeEventListener('click', onFirstInteraction)
          adContainer.current.removeEventListener(
            'touchstart',
            onFirstInteraction
          )
        }
        if (isIOS || isSafari) {
          getVideoElement().poster = ''
        }
        setFirstInteractionDone(true)
        doPlay()
      }
    },
    [readyToPlay, videoStatistics]
  )

  useCreateListeners({
    log,
    readyToPlay,
    onFirstInteraction,
    bigPlay,
    adContainer
  })

  const doPlay = useCallback(
    async (retry: boolean = false) => {
      log('doPlay: [adsDone, playing]', adsDone, playing)
      if (!playing) {
        try {
          const playPromise = await getVideoElement().play()
          if (playPromise) {
            log('doPlay success')
            if (chromecast.connected) {
              getVideoElement().pause()
              setVideoPlaying(false)
              chromecast.play()
            }
          } else {
            log('doPlay failed')
          }
          log('doPlay done')
        } catch (error: any) {
          log('doPlay error 1', error)
          if (!retry && !isIOS && adsDone) {
            log('doPlay error 2')
            setVolume(0)
            doPlay(true)
          }
        }
      }
    },
    [chromecast.connected, adsDone, playing]
  )

  useEffect(() => {
    log('playing changed', playing)
    if (playing && adsDone && seekToOnPlay !== undefined) {
      log('Should call seek with value', seekToOnPlay)
      seek(seekToOnPlay)
      setSeekToOnPlay(undefined)
    }
  }, [playing, seekToOnPlay, adsDone])

  useEffect(() => {}, [streamSrc])

  useEffect(() => {
    const onEnded = () => {
      if (adsDone) {
        setVideoEnded(true)
        setVideoPlaying(false)
      }
    }
    if (getVideoElement()) {
      getVideoElement().addEventListener('ended', onEnded)
    }
    return () => {
      if (getVideoElement()) {
        getVideoElement().removeEventListener('ended', onEnded)
      }
    }
  }, [videoElement.current, adsDone])

  useEffect(() => {
    setAdsError(false)
  }, [adsURL])

  const resume = useCallback(() => {
    log('resume, hlsState.status', hlsState.status)
    if (hlsState.status === HLS_STATES.readyToPlay && !videoEnded) {
      play()
    }
  }, [hlsState.status, videoEnded])

  const pause = useCallback(() => {
    if (adsDone) {
      if (chromecast.connected) {
        chromecast.pause()
      } else {
        getVideoElement().pause()
      }
    } else {
      videoAds.pause()
    }
  }, [videoAds, chromecast.connected, adsDone])

  useVideoAdsStatusCode({
    getVideoElement,
    videoAds,
    setAdsError,
    setAdsDone,
    setReadyToPlay,
    pause,
    log,
    resume
  })

  useEffect(() => {
    log(
      '[hlsState.status, firstInteractionDone]',
      hlsState.status,
      firstInteractionDone
    )
    if (hlsState.status === HLS_STATES.readyToPlay) {
      setReadyToPlay(true)
      if (firstInteractionDone) {
        doPlay()
      }
    }
  }, [hlsState.status, firstInteractionDone])

  useEffect(() => {
    if (adsDone) {
      setPlaying(chromecast.playing || videoPlaying)
    } else {
      setPlaying(videoAds.playing)
      if (videoAds.playing) {
        setStatisticsAdsPlaying()
      }
    }
  }, [videoPlaying, chromecast.playing, videoAds.playing, adsDone])

  const setStatisticsAdsPlaying = adStatisticsCallback(videoStatistics, adsDone)

  usePlayerControl({
    getVideoElement,
    setVideoPlaying,
    setVideoSeeking,
    setCurrentVolume,
    setDuration,
    chromecast,
    adsDone
  })

  const videoElementPlay = async () => {
    try {
      log('videoElementPlay')
      const playPromise = await getVideoElement().play()
      if (!playPromise) {
        log('videoElementPlay no playPromise')
        setPlaying(false)
      }
    } catch {
      log('videoElementPlay error')
    }
  }

  useChromecastEvents({
    chromecast,
    getVideoElement,
    videoElementPlay,
    videoPlaying,
    setVideoPlaying,
    setDuration
  })

  const play = useCallback(() => {
    log('play')
    if (adsDone) {
      if (chromecast.connected) {
        chromecast.play()
      } else {
        videoElementPlay()
      }
    } else {
      videoAds.resume()
    }
  }, [videoAds, chromecast.connected, adsDone])

  const togglePlay = togglePlayCallback(
    adsDone,
    chromecast,
    playing,
    getVideoElement,
    videoElementPlay,
    videoAds
  )

  const seek = useCallback(
    (value: number) => {
      if (adsDone) {
        if (chromecast.connected) {
          chromecast.seek(value)
        } else {
          getVideoElement().currentTime = value
        }
        log('Seeking to', value)
      }
    },
    [chromecast.connected, adsDone]
  )

  const setVolume = useCallback(
    (value: number) => {
      if (chromecast.connected) {
        chromecast.setVolume(value)
        setCurrentVolume(value)
      } else {
        try {
          getVideoElement().volume = value
          if (value <= 0) {
            getVideoElement().muted = true
          } else {
            getVideoElement().muted = false
          }
        } catch {
          log('set volume error')
        }
      }
    },
    [chromecast.connected, adsDone]
  )

  const getCurrentTime = useCallback((): number => {
    if (chromecast.connected) {
      return chromecast.getCurrentTime()
    }
    if (getVideoElement()) {
      return getVideoElement().currentTime
    }
    return 0
  }, [chromecast.connected])

  const getDuration = useCallback(() => {
    if (duration === Infinity) {
      const video = getVideoElement()
      if (video.seekable?.length > 0) {
        const _duration =
          video.seekable.end(video.seekable.length - 1) -
          video.seekable.start(0)
        return _duration
      }
    }
    return duration
  }, [duration])
  const viewedStreamPercentage = (getCurrentTime() / getDuration()) * 100
  return (
    <VideoPlayerContext.Provider
      value={{
        videoElement: getVideoElement(),
        adContainer: adContainer.current,
        videoAds: videoAds,
        ident: ident,
        streamSrc: streamSrc,
        adsDone: adsDone,
        adsStatus: videoAds.statusCode,
        hls: hlsState.hls,
        firstInteractionDone: firstInteractionDone,
        readyToPlay: readyToPlay,
        videoWrapper: videoWrapper,
        fullscreen: fullscreen,
        chromecast: chromecast,
        isLivestream: mediaObject?.type === 'livestream',
        playerState: {
          playing: playing,
          seeking: videoSeeking,
          volume: currentVolume,
          duration: getDuration(),
          currentTime: getCurrentTime(),
          isLive: isSafari
            ? mediaObject?.type === 'livestream'
            : hlsState.isLive,
          isDvr: isDvr
        },
        viewedStreamPercentage: viewedStreamPercentage,
        getDuration: getDuration,
        getCurrentTime: getCurrentTime,
        togglePlay: togglePlay,
        play: play,
        pause: pause,
        seek: seek,
        setVolume: setVolume
      }}
    >
      <>
        <VideoElement
          poster={thumbnail}
          ref={videoElement}
          subtitles={subtitles}
          ident={ident}
        />
        {!adsError && <AdContainer adContainer={adContainer} />}
        {children}
        {!firstInteractionDone && (
          <BigPlayButton bigPlay={bigPlay} readyToPlay={readyToPlay} />
        )}
        {hlsState.hls && ident && debug && (
          <DebugWindow hls={hlsState.hls} media_object={ident} />
        )}
      </>
    </VideoPlayerContext.Provider>
  )
}
