import { CustomPage } from 'interfaces'

export const getCustomSubmenuItem = (
  customPages: CustomPage[],
  impressumSlug: string | undefined
) => {
  if (customPages.length > 0) {
    if (impressumSlug) {
      if (customPages[0].slug === impressumSlug) {
        return null
      }
    }
    if (!customPages[0].href) {
      return customPages[0]
    }
  }
  return null
}
