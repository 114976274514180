import React, { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import PageLink from '../../../../components/page/menuLink/MenuLink'
import { ChannelMenuLink } from '../menuLinks'
import { getFilteredMenuLinks } from '../getFilteredMenuLinks'
import { Company } from '../../../../interfaces'
import {
  channelSelector,
  isSwedishHandballSelector
} from '../../../../redux-store/channel/channel.selectors'
import { channelFontsSelector } from '../../../../redux-store/channelFonts/channelFonts.selectors'
import { useThemeFont } from '../../Theme/useThemeFont'
import { setSubmenuFontLoaded } from '../../../../redux-store/channelTheme/channelTheme.actions'
import { useChannelTheme } from '../../Theme/useChannelTheme'

import styles from './submenu.module.scss'
import TagManager from 'react-gtm-module'

export const CHANNEL_SUB_MENU_ID = 'channel-sub-menu-id'
interface Props {
  channelSlug: string
  onSelected: (id: string) => void
  selected: string
  channelType: string
  isRootPage: boolean
  menuLinks: ChannelMenuLink[]
}

const Submenu: FC<Props> = ({
  selected,
  channelSlug,
  onSelected,
  channelType,
  isRootPage,
  menuLinks
}) => {
  const channel = useSelector(channelSelector)
  const channelDetails: Company = channel?.data

  const {
    meta_data: { content, store, misc }
  } = channelDetails
  const isSwedishHandball = useSelector(isSwedishHandballSelector)

  const showStatsSubmenu = isSwedishHandball
  const menuItems = useMemo(
    () =>
      getFilteredMenuLinks(menuLinks, {
        isRootPage,
        content,
        channelType,
        store,
        misc,
        showStatsSubmenu
      }),
    [content, channelType, store, misc, isRootPage]
  )

  const [loadedFont, setLoadedFont] = useState<string>()
  const { channelTheme, channelThemeSubmenuFontIsLoaded } = useChannelTheme()
  const customFontTheme = channelTheme && channelTheme.channelSubmenuFontFamily
  const channelFontList = useSelector(channelFontsSelector)
  const font = channelFontList.find((f) => f.identifier === customFontTheme)

  useThemeFont({
    channelName: channel.data.name,
    customFontTheme,
    isFontLoaded: channelThemeSubmenuFontIsLoaded,
    customFont: font,
    setLoadedAction: setSubmenuFontLoaded,
    setLoadedFont
  })

  useEffect(() => {}, [loadedFont])

  const applyHighlightTheme =
    channelTheme?.channelPrimaryHighlightColor && isRootPage

  const triggerGTMOnSubmenuClick = (submenuLink: ChannelMenuLink) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'on_channel_submenu_click',
        menuName: submenuLink.name,
        menuLink: submenuLink.link,
        channel_slug: channelSlug,
        is_root_page: isRootPage,
        channel_type: channelType
      }
    })
  }
  const handleOnSubmenuClick = (submenuLink: ChannelMenuLink) => {
    onSelected(submenuLink.id)
    triggerGTMOnSubmenuClick(submenuLink)
  }
  return (
    <div
      id={CHANNEL_SUB_MENU_ID}
      data-testid="channel-submenu"
      className={classNames(styles.SubmenuContainer, {
        [styles.Themed]: isRootPage,
        [styles.NotThemed]: !isRootPage
      })}
    >
      <ul data-testid="channel-menu">
        {menuItems.map((menuLink: ChannelMenuLink) => (
          <li
            key={menuLink.id}
            className={classNames({
              [styles.NotSelected]: menuLink.id !== selected,
              [styles.HoverTheme]: applyHighlightTheme,
              [styles.SelectedTheme]:
                menuLink.id === selected && applyHighlightTheme
            })}
            onClick={() => handleOnSubmenuClick(menuLink)}
            onKeyUp={(event) => {
              if (event.key === ' ') {
                handleOnSubmenuClick(menuLink)
              }
            }}
          >
            <PageLink
              {...{
                ...menuLink,
                link: `/${channelSlug}${menuLink.link}`
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Submenu
