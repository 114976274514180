import React, { FC } from 'react'
import styles from './IconTitleText.module.scss'

interface Props {
  iconPath: string
  title: string
  text: string
}

const IconTitleText: FC<Props> = ({ iconPath, title, text }) => {
  return (
    <div className={styles.IconTitleTextContainer}>
      <div className={styles.IconContainer}>
        <img
          className={styles.Icon}
          data-testid="icon-title-text-image"
          src={iconPath}
          alt={`icon for ${title}`}
        />
      </div>
      <div className={styles.Title} data-testid="icon-title-text-title">
        {title}
      </div>
      <div className={styles.Text} data-testid="icon-title-text-text">
        {text}
      </div>
    </div>
  )
}
export default IconTitleText
