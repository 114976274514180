import React, { FC } from 'react'

import { withTheme } from 'pages/ChannelPage/Theme/withTheme'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

import { HeaderMenuItems } from '../components/HeaderMenuItems'

import styles from './ThemedHeader.module.scss'
import { AuthButtons } from '../DefaultHeader/AuthButtons'
import { Search } from '../Search/Search'
import useCurrentUser from 'hooks/CurrentUser'
import { UserImageButton } from '../components/UserImgButton/UserImageButton'
import { useComponentVisible } from 'helpers/useComponentVisible'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import HamburgerMenuIcon from '../components/HamburgerMenuIcon'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { HamburgerMenu } from '../components/HamburgerMenu/HamburgerMenu'
import ChannelLogo from '../components/ChannelLogo'
import classNames from 'classnames'

const ThemedHeader: FC = () => {
  const channel = useSelector(channelSelector)
  const currentBreakpoint = useCurrentBreakpoint()
  const currentUser = useCurrentUser()
  const logo = channel?.data?.parent_logo_url
  const rootChannelSubdomain = channel?.data?.parentChannelSubdomain
  const isRootChannel = channel?.data?.isRootChannel
  const isRootClubChannel = channel?.data?.isRootClubChannel
  const isRootTeamChannel = channel?.data?.isRootTeamChannel
  const rootChannelType = isRootChannel
    ? channel.data.type
    : channel.data?.root_channel.type
  const isMobile = currentBreakpoint < Breakpoint.md
  const rootChannelSlug = isRootChannel
    ? channel.data.subdomain
    : channel?.data?.root_channel?.subdomain

  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  return (
    <div
      className={classNames(styles.ThemedHeader, {
        [styles.NavigationHeaderWithoutMenuItems]:
          isRootClubChannel | isRootTeamChannel
      })}
      data-testid="navigation-menu-container"
    >
      <ChannelLogo
        logoPath={logo}
        channelName={channel.data?.name}
        rootChannelSubdomain={rootChannelSubdomain}
      />
      <div className={styles.HeaderContent}>
        <div className={styles.LeftContainer}>
          <HeaderMenuItems
            rootChannelType={rootChannelType}
            isRootPageorChild={true}
            hasBackground={true}
            useTheme={true}
            backgroundIsTransparentAtStart={false}
            rootChannelSubdomain={rootChannelSubdomain}
          />
        </div>
        <div className={styles.RightContainer}>
          <Search
            hasBackground={true}
            backgroundIsTransparentAtStart={false}
            rootChannelSlug={rootChannelSlug}
            isRootOrChildChannel={true}
          />
          {!isMobile ? (
            currentUser ? (
              <UserImageButton onClick={toggleMenu} currentUser={currentUser} />
            ) : (
              <AuthButtons isThemed />
            )
          ) : (
            <IconButton
              id="hamburger-menu-button"
              onClick={toggleMenu}
              customStyle={styles.HamburgerMenuIcon}
            >
              <HamburgerMenuIcon />
            </IconButton>
          )}
        </div>
      </div>
      {isComponentVisible && (
        <HamburgerMenu isOpen={isComponentVisible} onClose={toggleMenu} />
      )}
    </div>
  )
}
export default withTheme(ThemedHeader)
