import * as yup from 'yup'
import { t } from '@lingui/macro'

const titleLength = 30
const textLength = 100
const buttonTextLength = 20
export const heroImageCallToActionSchema = () =>
  yup.object({
    title: yup
      .string()
      .required(t`Please enter the title`)
      .max(titleLength, t`The title can be at most 30 characters`),
    text: yup
      .string()
      .max(textLength, t`The text can be at most 100 characters`)
      .required(t`Please enter a text`),
    textField: yup
      .string()
      .max(buttonTextLength, t`The Button text can be at most 20 characters`)
      .required(t`Please enter a button text`),
    linkField: yup
      .string()
      .url(t`Please enter web link in the format https://domain.com/path`)
      .required(t`Please enter a button link`),
    target: yup.string()
  })
