import React, { FC } from 'react'
import { Loader } from '../../../../../../../components/Loader/Loader'
import styles from './LoadingUpload.module.scss'

interface LoadingUploadProps {
  imageSrc: string
}

export const LoadingUpload: FC<LoadingUploadProps> = ({ imageSrc }) => (
  <div className={styles.LoadingUploadContainer}>
    <div
      className={styles.LoadingUploadBackdrop}
      style={{ backgroundImage: `url(${imageSrc})` }}
    />
    <div className={styles.LoadingUploadOverlay}>
      <Loader variant="small" />
    </div>
  </div>
)
