import React, { FC } from 'react'

import { t } from '@lingui/macro'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { PlusIcon } from 'features/viewerPage/components/Chat/MessageInput/UploadButton/PlusIcon'

interface Props {
  onClick: () => void
}

export const CreateNewHeroImagePromotionButton: FC<Props> = ({ onClick }) => {
  return (
    <IconTextButton
      id="create-hero-image-promotion"
      type={ButtonType.Ghost}
      label={t`Create new promotion on hero image`}
      buttonSize="Medium"
      onClick={onClick}
    >
      <PlusIcon />
    </IconTextButton>
  )
}
