import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface ResetPassword {
  newPassword: string
  confirmNewPassword: string
  resetPasswordToken: string
}

export const postResetPassword = async ({
  newPassword,
  confirmNewPassword,
  resetPasswordToken
}: ResetPassword) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'users/reset_password',
      method: ApiEndpoint.METHODS.POST,
      params: {
        password: newPassword,
        password_confirmation: confirmNewPassword,
        reset_password_token: resetPasswordToken
      },
      authenticatable: false
    })
    if (response.status === 200) {
      return { isSuccess: true }
    }
    return { isSuccess: false }
  } catch (error: any) {
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
