import React from 'react'
import styles from './HoneybadgerError.module.scss'

export const HoneybadgerError = () => {
  return (
    <div className={styles.ErrorContainer}>
      <h2>Oops! There was an error in the application.</h2>
      <p>
        We&apos;ve been notified about this issue and we&apos;ll take a look at
        it as soon as we can.
      </p>
      <p>Please try reloading the page in a moment.</p>
    </div>
  )
}
