import React, { FC, useState } from 'react'

import { t } from '@lingui/macro'

import { IconButton } from 'components/buttons/IconButton/IconButton'
import { Tooltip, TooltipTheme } from 'components/Tooltip/Tooltip'

import styles from './InfoTooltip.module.scss'
import { InfoIcon } from 'assets/icons/InfoIcon'
interface Props {
  title: string
  text: string
}

export const InfoTooltip: FC<Props> = ({ title, text }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div className={styles.InfoTooltipContainer}>
      <IconButton onClick={() => setShowTooltip(!showTooltip)} id={'info-icon'}>
        <InfoIcon />
      </IconButton>

      {showTooltip && (
        <div className={styles.Tooltip}>
          <Tooltip
            text={text}
            title={title}
            confirmButtonText={t`Got it`}
            onConfirmButtonClick={() => setShowTooltip(false)}
            position="Bottom"
            pointerPosition="Center"
            theme={TooltipTheme.Dark}
          />
        </div>
      )}
    </div>
  )
}
