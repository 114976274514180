import { useCallback } from 'react'
import { VideoStatisticsClass } from '../../hooks/VideoStatistics'

export const adStatisticsCallback = (
  videoStatistics: VideoStatisticsClass | undefined,
  adsDone: boolean
) => {
  return useCallback(() => {
    if (videoStatistics && !adsDone) {
      if (!videoStatistics.data.play_latency) {
        videoStatistics.setPlayLatency()
      }
      if (!videoStatistics.data.start_latency) {
        videoStatistics.setStartLatency()
      }
    }
  }, [videoStatistics, adsDone])
}
