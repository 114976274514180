import React, { FC } from 'react'

export const GlobeIcon: FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.48047 12.2656C2.48047 14.7852 3.48136 17.2015 5.26295 18.9831C7.04455 20.7647 9.46091 21.7656 11.9805 21.7656C14.5 21.7656 16.9164 20.7647 18.698 18.9831C20.4796 17.2015 21.4805 14.7852 21.4805 12.2656C21.4805 9.74607 20.4796 7.32971 18.698 5.54811C16.9164 3.76651 14.5 2.76563 11.9805 2.76562C9.46091 2.76563 7.04455 3.76651 5.26295 5.54811C3.48136 7.32971 2.48047 9.74607 2.48047 12.2656V12.2656Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.69092 21.4873C8.40567 19.6025 7.54688 16.1775 7.54688 12.2651C7.54688 8.35284 8.40567 4.92777 9.69092 3.04297"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.48047 12.2656H21.4805"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.94141 17.332H20.0163"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.94141 7.19922H20.0163"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2695 3.04297C15.5548 4.92777 16.4136 8.35284 16.4136 12.2651C16.4136 16.1775 15.5548 19.6025 14.2695 21.4873"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
