import { ProductTypeInterface } from '../../pages/ChannelPage/ChannelSettings/Branding/BrandingHeader/ProductTypes'
import { Company, Country } from '../../interfaces/Company.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { ImpressumPage } from '../../interfaces/ImpressumPage.interface'

export interface Channel extends Company {
  isRootChannel: boolean
  isRootClubChannel?: boolean
  isChildOfRootChannel: boolean
  parentChannelSubdomain: string
  parentLogoUrl: string
  productVariant: ProductTypeInterface
  countryData: Country
  impressumPage: ImpressumPage
}

export const getChannel = async (slug: string) => {
  if (!slug) return null
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}`
    })
    const responseData = await resultResponse
    const mapped = mapChannel(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore

    throw new Error(e.message)
  }
}

const mapChannel = (channelResponse: any): Channel => {
  const newResponse = {
    ...channelResponse,
    channelTheme: channelResponse.channel_theme,
    isRootChannel: channelResponse.is_root_channel,
    isChildOfRootChannel: channelResponse.is_child_of_root_channel,
    parentChannelSubdomain: channelResponse.parent_channel_subdomain,
    parentLogoUrl: channelResponse.parent_logo_url,
    countryData: {
      code: channelResponse?.country?.code,
      name: channelResponse?.country?.name,
      countryNumber: channelResponse?.country?.number
    },
    impressumPage: channelResponse.impressum_page
  }
  return newResponse
}
