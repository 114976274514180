import React, { FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import TextIconButton, {
  ButtonType
} from '../../../components/buttons/TextIconButton'
import { AddIcon } from './AddIcon'
import { GameScheduleListHeader } from './ListItem/GameScheduleListHeader'
import { Loader } from '../../../components/Loader/Loader'

import { useStatusMessageDispatchContext } from '../../../pages/ChannelPage/ContentManager/StatusMessageContext'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { GameScheduleItem } from './ListItem/gameScheduleItem'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import { GameScheduleList } from './GameScheduleList/GameScheduleList'
import { Heading } from './Heading/Heading'
import { RemoveCompetitionModal } from '../RemoveCompetitionModal/RemoveCompetitionModal'

import styles from './GameScheduleList.module.scss'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

interface Props {
  listData: GameScheduleItem[]
  heading: string
  subheading?: ReactNode
  showAddButton: boolean
  hasError: boolean
  errorMessage: string
  isLoading: boolean
  onAddSchedule: () => void
  showRemoveButton: boolean
}

export const GameScheduleListContainer: FC<Props> = ({
  listData,
  heading,
  subheading,
  showAddButton,
  hasError,
  errorMessage,
  isLoading,
  onAddSchedule,
  showRemoveButton
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const channel = useSelector(channelSelector)
  const type = channel?.data?.type

  const channelType =
    // @ts-ignore
    channel && channel.data && type ? ChannelTypes[type] : ChannelTypes.unknown
  const dispatch = useStatusMessageDispatchContext()

  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [competitionToRemove, setCompetitionToRemove] =
    useState<GameScheduleItem>()

  const getCompetitionToRemove = (id: string) => {
    const useSelectedTeamFromClub =
      window.location.pathname.indexOf('teams') !== -1

    if (channel?.data && !useSelectedTeamFromClub) {
      dispatch({
        type: 'team_selected',
        selectedTeam: {
          name: channel.data.name,
          slug: channel.data.subdomain
        }
      })
    }
    const competition = listData.find((comp: any) => comp.id === id)
    setCompetitionToRemove(competition)
    setShowRemoveModal(true)
  }

  const showColumnList = !(currentBreakpoint <= Breakpoint.sm)

  const showLargeAddButton =
    showAddButton && (!showColumnList || listData.length === 0)

  const addButtonLabel = showColumnList
    ? t`Add games`
    : t`Add competitions to channel`

  return (
    <>
      <div className={styles.GameScheduleListContainer}>
        <Heading
          heading={heading}
          subheading={subheading}
          showAddButton={showColumnList && showAddButton && listData.length > 0}
          onAddSchedule={onAddSchedule}
        />

        {hasError && <p>{errorMessage}</p>}
        {!hasError && isLoading ? (
          <Loader />
        ) : (
          listData?.length > 0 &&
          !hasError && (
            <div>
              {showColumnList && (
                <GameScheduleListHeader
                  showStatus={showAddButton}
                  showRemoveButton={showAddButton}
                  channelType={channelType}
                />
              )}
              <div className={styles.GameScheduleList}>
                <GameScheduleList
                  listData={listData}
                  onRemoveSchedule={getCompetitionToRemove}
                  showRemoveButton={showRemoveButton}
                  channelType={channelType}
                />
              </div>
            </div>
          )
        )}
        {showLargeAddButton && (
          <div
            className={styles.ButtonContainer}
            data-testid="add-schedule-button"
          >
            <TextIconButton
              id="addScheduledGamesButton"
              type={ButtonType.Ghost}
              buttonSize="Medium"
              onClick={onAddSchedule}
              label={addButtonLabel}
              externalStyle={styles.AddButton}
            >
              <AddIcon />
            </TextIconButton>
          </div>
        )}
        {showRemoveModal && (
          <RemoveCompetitionModal
            competitionId={competitionToRemove!.id}
            competitionName={competitionToRemove!.title}
            competitionTeamId={competitionToRemove!.teamId}
            modalIsOpen={showRemoveModal}
            channelType={channelType}
            onClose={() => setShowRemoveModal(false)}
          />
        )}
      </div>
    </>
  )
}
GameScheduleListContainer.defaultProps = {
  subheading: undefined
}
