import React, { FC } from 'react'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import FilterRow from '../../../components/filters/FilterRow/index'
import { FilterType } from '../filterTypes'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { getFilterButtons } from './getFilterButtons'

interface Props {
  selectedFilter: string
  onFilterChanged: (id: FilterType) => void
  rootChannelType?: ChannelTypes
  hasTeams: boolean
}

export interface FilterButton {
  id: FilterType
  text: string
  filterClicked: (id: FilterType) => void
}

export const FilterSection: FC<Props> = ({
  selectedFilter,
  onFilterChanged,
  rootChannelType,
  hasTeams
}) => {
  const filterClicked = (selected: string) =>
    // @ts-ignore
    onFilterChanged(FilterType[selected])

  const currentBreakpoint = useCurrentBreakpoint()
  const topFilterSize = currentBreakpoint > Breakpoint.sm ? 'medium' : 'small'

  const filterButtons: any = getFilterButtons(
    filterClicked,
    hasTeams,
    rootChannelType
  )
  if (
    !filterButtons.find((button: FilterButton) => button.id === selectedFilter)
  ) {
    filterClicked(filterButtons[0].id)
  }

  return (
    <FilterRow
      filterItemButtonList={filterButtons}
      size={topFilterSize}
      selectedValue={selectedFilter}
    />
  )
}
