import React from 'react'

export const MegagphoneIcon = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1501_12210)">
      <rect
        x="1.23438"
        y="17.9043"
        width="5"
        height="12"
        rx="2"
        transform="rotate(-6.0136 1.23438 17.9043)"
        stroke="black"
        strokeWidth="3"
      />
      <rect
        x="28"
        y="7.72754"
        width="6"
        height="26"
        rx="2"
        transform="rotate(-6.0136 28 7.72754)"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M38.6562 19.9932L45.6177 19.2598"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M38.25 14.0029L44.5199 10.8903"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M39.5078 25.9365L46.2886 27.6746"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M28.6602 10.9921C22.4029 14.757 17.0135 16.6645 7.40984 19.2639"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M30.7539 30.8817C23.8495 28.5033 18.1809 27.7607 8.24641 27.2195"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M12.3281 27.7959L17.0881 38.9468C17.4328 39.7543 18.2639 40.2425 19.1371 40.1505L19.9475 40.0652C21.2857 39.9242 22.1057 38.5286 21.5774 37.291L17.3006 27.2721"
        stroke="black"
        strokeWidth="3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1501_12210">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
