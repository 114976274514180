import React from 'react'

export const HomeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11689_15590)">
      <g clipPath="url(#clip1_11689_15590)">
        <path
          d="M2.4668 7.5L5.28346 18.5733C5.33234 18.7955 5.45397 18.995 5.62908 19.1402C5.80419 19.2854 6.02275 19.3681 6.25013 19.375H13.7501C13.977 19.3675 14.1948 19.2846 14.3693 19.1394C14.5438 18.9942 14.6649 18.795 14.7135 18.5733L17.5276 7.5"
          stroke="#2F3135"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.62207 9.18417L9.99707 0.625L19.3721 9.18417"
          stroke="#2F3135"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.49707 11.875C7.49707 12.538 7.76046 13.1739 8.2293 13.6428C8.69814 14.1116 9.33403 14.375 9.99707 14.375C10.6601 14.375 11.296 14.1116 11.7648 13.6428C12.2337 13.1739 12.4971 12.538 12.4971 11.875C12.4971 11.212 12.2337 10.5761 11.7648 10.1072C11.296 9.63839 10.6601 9.375 9.99707 9.375C9.33403 9.375 8.69814 9.63839 8.2293 10.1072C7.76046 10.5761 7.49707 11.212 7.49707 11.875Z"
          stroke="#2F3135"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11689_15590">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_11689_15590">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
