import React, { FC } from 'react'
import { LastUpdated } from '../../LastUpdated/LastUpdated'
import { PaginationButtons } from '../Pagination/PaginationButtons'
import { ProvidedByLogo } from '../ProvidedByLogo/ProvidedByLogo'

import styles from './TableFooter.module.scss'

interface Props {
  integrationLink: string
  integrationLogo: string
  integrationName: string
  lastUpdatedTimestamp: number
  onIncrementPage: () => void
  onDecrementPage: () => void
  currentPage: number
  totalPages: number
}

export const TableFooter: FC<Props> = ({
  integrationLink,
  integrationLogo,
  integrationName,
  lastUpdatedTimestamp,
  onIncrementPage,
  onDecrementPage,
  currentPage,
  totalPages
}) => (
  <div className={styles.BottomContainer}>
    <div className={styles.InnerContainer}>
      {lastUpdatedTimestamp && <LastUpdated timestamp={lastUpdatedTimestamp} />}

      <PaginationButtons
        onIncrementPage={onIncrementPage}
        onDecrementPage={onDecrementPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
    {integrationLogo && (
      <div className={styles.DataProviderInfo}>
        <ProvidedByLogo
          link={integrationLink}
          imageUrl={integrationLogo}
          integrationName={integrationName}
        />
      </div>
    )}
  </div>
)
