import React from 'react'

const SolidSportLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 131 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22_15743)">
      <path
        d="M15.776 1.6597C15.1238 1.90275 14.4915 2.19453 13.8846 2.53248C13.2442 2.87252 12.6724 3.32468 12.1972 3.86672C11.5007 4.68666 11.211 5.44106 11.328 6.12992C11.483 7.05821 12.2189 7.78987 13.5357 8.3249C14.0253 8.51752 14.9932 8.80577 16.4391 9.18966C17.5216 9.45896 18.5878 9.78854 19.6323 10.1768C20.4104 10.4537 21.1178 10.8939 21.7053 11.4669C21.7053 11.3686 21.7053 11.2663 21.7053 11.174C21.7058 9.20457 21.1521 7.27343 20.1057 5.59426C19.0592 3.91508 17.5607 2.55333 15.776 1.6597V1.6597Z"
        fill="#231F20"
      />
      <path
        d="M2.79919 14.8216C3.3705 13.4104 4.18664 12.3457 5.24764 11.6274C5.39979 11.5178 5.58155 11.455 5.76997 11.4468C5.89443 11.4589 5.81894 11.6234 5.54553 11.9404C4.98266 12.5368 4.52664 13.2226 4.19685 13.9688C3.54169 15.446 3.33889 17.079 3.6133 18.6678C3.64585 18.8572 3.68876 19.0447 3.74184 19.2296C5.04347 20.3437 6.59651 21.1365 8.27211 21.5423C9.94771 21.9481 11.6976 21.9551 13.3765 21.5628C15.0554 21.1704 16.6149 20.3901 17.9257 19.2864C19.2366 18.1828 20.2608 16.7876 20.9136 15.2168C20.9582 14.8525 20.9513 14.4838 20.8932 14.1213C20.7028 13.0579 20.0567 12.1959 18.9549 11.5351C18.3604 11.2141 17.1995 10.8442 15.4719 10.4256C13.7444 10.0069 12.5712 9.66649 11.9523 9.40433C10.592 8.79037 9.81739 7.92763 9.62831 6.81609C9.46644 5.84768 9.85615 4.81238 10.7974 3.71021C11.7387 2.60804 13.0643 1.77739 14.7741 1.21828C12.578 0.384801 10.1659 0.275652 7.90124 0.907277C5.63655 1.5389 3.64193 2.87709 2.21783 4.72026C0.793741 6.56342 0.0173107 8.81174 0.00550768 11.1265C-0.00629533 13.4413 0.747168 15.6971 2.15239 17.5543C2.20686 16.6136 2.42566 15.6892 2.79919 14.8216V14.8216Z"
        fill="#231F20"
      />
      <path
        d="M35.2593 19.2135C33.1183 19.2135 31.4656 18.7701 30.3012 17.8833C29.1368 16.9965 28.5247 15.7318 28.4648 14.0892H31.5866C31.7022 15.0256 32.0423 15.6943 32.6068 16.0956C33.1713 16.4969 34.014 16.6975 35.1348 16.6975C35.5259 16.6994 35.9161 16.6631 36.2999 16.5892C36.6399 16.5269 36.9673 16.4104 37.2691 16.2441C37.5382 16.0932 37.7674 15.8822 37.9383 15.6281C38.1135 15.3533 38.2015 15.0334 38.1913 14.7092C38.2046 14.3777 38.108 14.051 37.9159 13.7782C37.7119 13.5064 37.4437 13.2874 37.1344 13.1402C36.7418 12.9565 36.3318 12.8113 35.9102 12.7068C35.4314 12.5945 34.892 12.4714 34.2922 12.3377C33.5889 12.1785 32.9088 12.0019 32.2518 11.808C31.6416 11.6334 31.0628 11.3667 30.5358 11.0175C30.046 10.6878 29.6398 10.2519 29.3483 9.74341C29.0254 9.12953 28.87 8.44372 28.8974 7.75308C28.8827 7.05848 29.0373 6.37051 29.3483 5.7467C29.6454 5.17209 30.076 4.67442 30.6052 4.29407C31.1783 3.88707 31.824 3.58964 32.5089 3.41728C33.2983 3.21601 34.1112 3.11818 34.9267 3.12636C36.695 3.12636 38.1233 3.537 39.2115 4.35828C40.2997 5.17956 40.8975 6.37603 41.005 7.9477H37.9505C37.9297 7.60368 37.832 7.26841 37.6645 6.96569C37.497 6.66297 37.2638 6.40023 36.9814 6.19612C36.3727 5.7911 35.6489 5.58665 34.9145 5.61227C34.0494 5.61227 33.3604 5.77813 32.8476 6.10985C32.6126 6.24347 32.4178 6.43574 32.2827 6.66722C32.1476 6.8987 32.0771 7.16117 32.0783 7.42804C32.066 7.70553 32.1369 7.98047 32.2824 8.21856C32.4479 8.44958 32.6667 8.63868 32.921 8.77032C33.2588 8.94631 33.6171 9.08107 33.9881 9.17159C34.4125 9.28796 34.9179 9.41102 35.5041 9.54077C36.3094 9.71332 37.0684 9.90392 37.7812 10.1126C38.442 10.2995 39.0716 10.58 39.6502 10.9452C40.1731 11.2795 40.6069 11.7323 40.9152 12.2654C41.2459 12.8992 41.4047 13.6062 41.3763 14.318C41.3886 15.0392 41.2308 15.7534 40.9152 16.4046C40.6167 17.0126 40.178 17.5437 39.6339 17.9555C39.0545 18.3848 38.3984 18.7033 37.6996 18.8945C36.9059 19.1154 36.0841 19.2229 35.2593 19.2135V19.2135Z"
        fill="#231F20"
      />
      <path
        d="M48.3874 19.1915C47.5652 19.2028 46.7493 19.0485 45.99 18.738C45.2929 18.4511 44.6622 18.0281 44.1362 17.4946C43.6101 16.9611 43.1996 16.3282 42.9295 15.6341C42.3555 14.1477 42.3555 12.5059 42.9295 11.0195C43.2012 10.3261 43.6119 9.69358 44.1374 9.15954C44.6657 8.63116 45.2954 8.21108 45.99 7.92361C47.5292 7.31892 49.2457 7.31892 50.7849 7.92361C51.4814 8.21332 52.1125 8.63615 52.6416 9.16757C53.1677 9.70149 53.5792 10.334 53.8516 11.0275C54.4255 12.5139 54.4255 14.1557 53.8516 15.6422C53.5806 16.3357 53.1698 16.9682 52.6438 17.5016C52.1179 18.035 51.4876 18.4583 50.791 18.746C50.0292 19.0544 49.2112 19.206 48.3874 19.1915V19.1915ZM48.3874 16.9423C49.3097 16.9423 50.017 16.6079 50.5094 15.9391C51.0018 15.2703 51.2474 14.4009 51.246 13.3308C51.246 12.2487 51.0005 11.3726 50.5094 10.7024C50.0184 10.0323 49.3111 9.69792 48.3874 9.69926C47.4638 9.69926 46.7565 10.0337 46.2655 10.7024C45.7744 11.3712 45.5289 12.2507 45.5289 13.3408C45.5289 14.4203 45.7744 15.2897 46.2655 15.9491C46.7565 16.6086 47.4638 16.943 48.3874 16.9523V16.9423Z"
        fill="#231F20"
      />
      <path
        d="M55.7021 3.40726H58.6913V18.8685H55.7021V3.40726Z"
        fill="#231F20"
      />
      <path
        d="M60.6782 3.40726H63.6694V6.04565H60.6782V3.40726ZM60.6782 7.77515H63.6694V18.8685H60.6782V7.77515Z"
        fill="#231F20"
      />
      <path
        d="M69.8253 19.1915C69.158 19.2024 68.4969 19.0653 67.891 18.7902C67.3064 18.5178 66.7917 18.1193 66.3852 17.6245C65.9429 17.0742 65.6106 16.4465 65.4058 15.7746C65.1624 14.9859 65.0441 14.165 65.0549 13.3409C65.0455 12.5346 65.1639 11.7318 65.4058 10.9613C65.6154 10.2876 65.9556 9.66006 66.4076 9.11341C66.8246 8.61024 67.3455 8.1999 67.9359 7.90958C68.5369 7.61717 69.1997 7.46867 69.8702 7.4762C70.5526 7.44916 71.2289 7.61262 71.8208 7.9477C72.3101 8.24509 72.7278 8.64353 73.045 9.11542H73.1103V3.40726H76.1015V18.8685H73.2347V17.4178H73.1899C72.8422 17.9829 72.3465 18.4457 71.7546 18.7577C71.1626 19.0698 70.4963 19.2196 69.8253 19.1915V19.1915ZM70.4864 16.7276C71.38 16.7276 72.054 16.428 72.5084 15.8288C72.9627 15.2295 73.1899 14.419 73.1899 13.397C73.195 12.9105 73.1471 12.4249 73.047 11.9484C72.9684 11.5529 72.8154 11.1753 72.5961 10.8349C72.3974 10.529 72.121 10.2794 71.7942 10.1106C71.4142 9.92588 70.9937 9.83628 70.57 9.84976C70.1998 9.83262 69.8318 9.91396 69.5047 10.0852C69.1776 10.2565 68.9035 10.5113 68.7112 10.8229C68.3032 11.4729 68.0848 12.3076 68.0848 13.3328C68.0848 14.4123 68.2889 15.2483 68.6969 15.8408C68.8914 16.1285 69.1586 16.3615 69.4724 16.517C69.7861 16.6725 70.1355 16.7451 70.4864 16.7276Z"
        fill="#231F20"
      />
      <path
        d="M84.3833 19.2136C82.2423 19.2136 80.5896 18.7701 79.4252 17.8833C78.2608 16.9965 77.6487 15.7318 77.5889 14.0893H80.7106C80.829 15.0256 81.169 15.6944 81.7308 16.0956C82.2926 16.4969 83.1353 16.6976 84.2588 16.6976C84.6499 16.6994 85.0401 16.6631 85.4239 16.5892C85.7639 16.527 86.0913 16.4104 86.3931 16.2441C86.6622 16.0932 86.8914 15.8822 87.0623 15.6281C87.2375 15.3533 87.3255 15.0334 87.3153 14.7092C87.3284 14.3781 87.2325 14.0516 87.0419 13.7783C86.8349 13.5061 86.564 13.2872 86.2523 13.1402C85.8599 12.9561 85.4498 12.811 85.0281 12.7069C84.5533 12.5945 84.016 12.4714 83.4162 12.3377C82.7116 12.1785 82.0314 12.0019 81.3758 11.808C80.7657 11.6334 80.1868 11.3667 79.6598 11.0175C79.1704 10.6874 78.7642 10.2516 78.4724 9.74342C78.1494 9.12954 77.994 8.44373 78.0214 7.75309C78.0067 7.05849 78.1613 6.37051 78.4724 5.7467C78.7689 5.17347 79.1988 4.67715 79.7272 4.29809C80.2993 3.89074 80.9445 3.59326 81.6288 3.4213C82.4233 3.21726 83.2419 3.11808 84.063 3.12636C85.8381 3.12636 87.2664 3.537 88.3477 4.35828C89.4291 5.17956 90.0263 6.37604 90.1392 7.94771H87.0786C87.0572 7.60316 86.9586 7.26753 86.79 6.96475C86.6214 6.66198 86.387 6.39953 86.1033 6.19613C85.4943 5.792 84.7708 5.58763 84.0364 5.61227C83.1713 5.61227 82.4824 5.77814 81.9695 6.10986C81.7341 6.24306 81.5387 6.43519 81.4032 6.66673C81.2678 6.89826 81.197 7.16094 81.1983 7.42805C81.1853 7.7056 81.2563 7.98071 81.4023 8.21857C81.5665 8.45002 81.7848 8.63926 82.0389 8.77032C82.3767 8.94632 82.735 9.08108 83.106 9.1716C83.5304 9.28797 84.0364 9.41103 84.6241 9.54078C85.4293 9.71333 86.1877 9.90393 86.8991 10.1126C87.5599 10.2995 88.1894 10.58 88.7681 10.9452C89.291 11.2795 89.7248 11.7323 90.0331 12.2654C90.3647 12.8989 90.5236 13.6061 90.4942 14.318C90.5075 15.0393 90.3496 15.7537 90.0331 16.4046C89.7353 17.0119 89.298 17.5428 88.7558 17.9556C88.1755 18.3844 87.5188 18.7029 86.8195 18.8945C86.0272 19.1151 85.2067 19.2225 84.3833 19.2136V19.2136Z"
        fill="#231F20"
      />
      <path
        d="M92.1675 7.77514H95.0485V9.15955H95.1138C95.4789 8.67386 95.9388 8.26461 96.4665 7.95572C97.066 7.62245 97.7472 7.45794 98.4355 7.48021C99.1344 7.46988 99.8264 7.61818 100.458 7.91358C101.057 8.20014 101.586 8.61082 102.008 9.11741C102.459 9.66328 102.795 10.2913 102.998 10.9653C103.232 11.7359 103.347 12.5367 103.339 13.3409C103.348 14.1643 103.23 14.9843 102.988 15.7726C102.781 16.4482 102.441 17.077 101.986 17.6225C101.563 18.1192 101.033 18.5174 100.435 18.7882C99.8134 19.0636 99.1376 19.2006 98.4559 19.1895C97.7697 19.2129 97.0886 19.0644 96.4767 18.7581C95.9642 18.4826 95.5267 18.0896 95.2015 17.6124H95.1587V22.498H92.1675V7.77514ZM97.8193 16.7517C98.1714 16.763 98.5208 16.6891 98.8371 16.5366C99.1534 16.384 99.4267 16.1575 99.6332 15.8769C100.081 15.2924 100.304 14.4745 100.304 13.4231C100.304 12.3718 100.1 11.5137 99.6924 10.8489C99.2843 10.1801 98.6212 9.84573 97.703 9.84573C97.2864 9.83451 96.8744 9.93286 96.5094 10.1306C96.1801 10.3155 95.9003 10.5748 95.6932 10.887C95.4683 11.2337 95.3119 11.619 95.2321 12.0227C95.1339 12.4845 95.086 12.9553 95.0893 13.4271C95.0893 14.437 95.3199 15.2442 95.781 15.8488C96.2421 16.4534 96.9215 16.7544 97.8193 16.7517Z"
        fill="#231F20"
      />
      <path
        d="M110.068 19.1915C109.245 19.2028 108.429 19.0485 107.67 18.738C106.973 18.4511 106.342 18.0281 105.816 17.4946C105.29 16.9611 104.88 16.3282 104.61 15.6341C104.036 14.1477 104.036 12.5059 104.61 11.0195C104.881 10.3261 105.292 9.69358 105.818 9.15954C106.346 8.63116 106.976 8.21108 107.67 7.92361C109.209 7.31904 110.925 7.31904 112.463 7.92361C113.16 8.21276 113.792 8.63564 114.322 9.16757C114.848 9.70149 115.259 10.334 115.532 11.0275C116.106 12.514 116.106 14.1557 115.532 15.6422C115.261 16.3358 114.85 16.9685 114.324 17.5019C113.798 18.0353 113.168 18.4585 112.471 18.746C111.709 19.0549 110.891 19.2065 110.068 19.1915V19.1915ZM110.068 16.9423C110.99 16.9423 111.697 16.6079 112.19 15.9391C112.682 15.2703 112.928 14.4009 112.926 13.3308C112.926 12.2487 112.681 11.3726 112.19 10.7025C111.699 10.0323 110.991 9.69792 110.068 9.69926C109.143 9.69926 108.435 10.0337 107.946 10.7025C107.456 11.3712 107.21 12.2507 107.209 13.3408C107.209 14.4203 107.455 15.2897 107.946 15.9491C108.437 16.6086 109.144 16.943 110.068 16.9523V16.9423Z"
        fill="#231F20"
      />
      <path
        d="M120.227 9.5488H120.292C120.563 8.99715 120.953 8.51081 121.437 8.12427C121.912 7.79108 122.486 7.62369 123.069 7.64876C123.307 7.64002 123.544 7.66915 123.773 7.73503V10.3052H123.708C122.715 10.2049 121.906 10.4142 121.282 10.9332C120.657 11.4522 120.342 12.2949 120.337 13.4613V18.8785H117.346V7.77516H120.227V9.5488Z"
        fill="#231F20"
      />
      <path
        d="M124.514 7.77515H126.075V4.31213H129V7.77515H130.935V9.72937H129V15.6021C129 16.0214 129.103 16.3083 129.309 16.4668C129.545 16.636 129.833 16.7203 130.125 16.7056H130.698C130.801 16.7052 130.903 16.6978 131.004 16.6835V18.8464C130.859 18.8604 130.698 18.8684 130.521 18.8684C130.343 18.8684 130.161 18.8885 129.927 18.8885H129.135C128.769 18.8891 128.404 18.8494 128.048 18.7701C127.696 18.6951 127.363 18.5518 127.068 18.3488C126.766 18.1354 126.521 17.8531 126.354 17.5262C126.157 17.1222 126.063 16.6776 126.079 16.2301V9.72937H124.518L124.514 7.77515Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <clipPath id="clip0_22_15743">
        <rect
          width="131"
          height="22"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default SolidSportLogo
