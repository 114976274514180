import { Sponsor } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

// https://sportstage.se/api/play_v1//sponsors?company=slug

export const getSponsors = async (slug: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'sponsors',
      params: { company: slug }
    })
    const responseData = await resultResponse
    return responseData.data.length > 0
      ? mapSponsorsRespons(responseData.data[0].children)
      : []
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapSponsorsRespons = (apiResponse: Sponsor[]) =>
  apiResponse.map((sponsors: Sponsor) => ({
    imagePath: sponsors.image_url,
    urlPath: sponsors.settings_store.href,
    sponsorName: sponsors.title
  }))
