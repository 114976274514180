const USER = {
  COMPANY_FOLLOW_ADD: 'COMPANY_FOLLOW_ADD',
  COMPANY_FOLLOW_REMOVE: 'COMPANY_FOLLOW_REMOVE',
  TEAM_FOLLOW_ADD: 'TEAM_FOLLOW_ADD',
  TEAM_FOLLOW_REMOVE: 'TEAM_FOLLOW_REMOVE',
  USER_ADDED: 'USER_ADDED',
  USER_REMOVED: 'USER_REMOVED',
  USER_UPDATED_FROM_SERVER: 'USER_UPDATED_FROM_SERVER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
  SET_LANGUAGE: 'SET_LANGUAGE',
  COMPANY_FOLLOW_ADD_SUCCESS: 'COMPANY_FOLLOW_ADD_SUCCESS',
  COMPANY_FOLLOW_REMOVE_SUCCESS: 'COMPANY_FOLLOW_REMOVE_SUCCESS',
  COMPANY_FOLLOW_ERROR: 'COMPANY_FOLLOW_ERROR',
  RESET_FROM_LOCAL_STATE: 'RESET_FROM_LOCAL_STATE'
}

export default USER
