import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { GameScheduleEventCardType } from '../../../components/cards/GameScheduleCard/gameScheduleType'
import { GameCard, GameCardBackground } from '../GameCard/GameCard'
import { permissionsSelector } from 'redux-store/permissions/permissions.reducer'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'

interface Props {
  type: FilteredGamesListType
  events: GameScheduleEventCardType[]
}

export const DayEvents: Function = ({ type, events }: Props) => {
  const channel = useSelector(channelSelector)
  const isAdmin = useSelector(permissionsSelector).hasAdminAccess
  const channelSlug = channel.data.subdomain
  const channelType = channel.data.type
  const backgroundColor =
    type === FilteredGamesListType.Primary
      ? GameCardBackground.Primary
      : GameCardBackground.Secondary
  if (channelSlug) {
    return events.map((eventCard: GameScheduleEventCardType) => (
      <Fragment key={eventCard.id}>
        <GameCard
          channelSlug={channelSlug}
          game={eventCard}
          isAdmin={isAdmin}
          backgroundColor={backgroundColor}
          channelType={channelType}
        />
      </Fragment>
    ))
  }
  return null
}
