import CHANNEL_THEME from './channelTheme.constants'
import { ThemeOptions } from './channelTheme.interface'

export const setChannelTheme = (theme: ThemeOptions) => ({
  type: CHANNEL_THEME.SET_THEME,
  data: theme
})

export const setDefaultTheme = () => ({
  type: CHANNEL_THEME.SET_DEFAULT_THEME
})

export const setHeaderFontLoaded = () => ({
  type: CHANNEL_THEME.SET_HEADER_FONT_LOADED
})

export const setSubmenuFontLoaded = () => ({
  type: CHANNEL_THEME.SET_SUBMENU_FONT_LOADED
})

export const setIsLoading = () => ({
  type: CHANNEL_THEME.SET_IS_LOADING
})

// custom theme action creator
export const setCustomTheme = (dispatch: any, theme: ThemeOptions) => {
  return dispatch(setChannelTheme(theme))
}
