import { RootState } from '../store'
import { ThemeOptions } from './channelTheme.interface'

export const channelThemeSelector = (state: RootState): ThemeOptions =>
  state.channelTheme?.data

export const channelThemeHeaderFontIsLoadedSelector = (
  state: RootState
): boolean => state.channelTheme?.isHeaderFontLoaded

export const channelThemeSubmenuFontIsLoadedSelector = (
  state: RootState
): boolean => state.channelTheme?.isSubmenuFontLoaded

export const channelThemeIsLoadingSelector = (state: RootState): boolean =>
  state.channelTheme?.isLoading
