import React, { FC } from 'react'
import { ChangingStatusIcon } from './ChangingStatusIcon'
import { DomainRegistrationStatus } from './DomainRegistrationStatus'
import { InformationIcon } from './InformationIcon'
import { WaitingIcon } from './WaitingIcon'
import { SuccessIcon } from './SuccessIcon'

interface Props {
  domainStatus: DomainRegistrationStatus
}

export const DomainStatusIconSelector: FC<Props> = ({ domainStatus }) => {
  switch (domainStatus) {
    case DomainRegistrationStatus.ChangingDomain:
      return <ChangingStatusIcon />
    case DomainRegistrationStatus.DomainAlreadyInUse:
    case DomainRegistrationStatus.Aborted:
    case DomainRegistrationStatus.SslInactive:
    case DomainRegistrationStatus.Expired:
    case DomainRegistrationStatus.ConfigurationError:
      return <InformationIcon />
    case DomainRegistrationStatus.WaitingForPartnerConfiguration:
    case DomainRegistrationStatus.WaitingForSslGeneration:
    case DomainRegistrationStatus.ValidatingDomain:
      return <WaitingIcon />
    case DomainRegistrationStatus.SslGenerated:
      return <SuccessIcon />
    default:
      return null
  }
}
