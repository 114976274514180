import React, { FC, useEffect } from 'react'
import styles from './StreamCountdown.module.scss'
import { useCountdownTime } from 'components/live/Countdown/useCountdownTime'
import { useCountdown } from 'hooks/Countdown'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'

interface Props {
  streamStartAt: number
  hasReachedZero: () => void
  showOnlyDays?: boolean
}
export const StreamCountdown: FC<Props> = ({
  streamStartAt,
  hasReachedZero,
  showOnlyDays
}) => {
  const countdownTime = useCountdownTime(streamStartAt)
  const { remainingTime } = useCountdown(countdownTime, 'DD:HH:mm:ss')
  const timeSplit = remainingTime.split(':')
  const remainginDays = timeSplit[0]
  const remainingHours = timeSplit[1]
  const remainingMinutes = timeSplit[2]
  const remainingSeconds = timeSplit[3]
  useEffect(() => {
    if (remainingTime === '00:00:00:00') {
      hasReachedZero()
    }
  }, [remainingTime])
  if (showOnlyDays) {
    const diffDays = dayjs.unix(streamStartAt).diff(dayjs(), 'day')

    return (
      <div className={styles.StreamCountdown}>
        <div className={styles.OnlyDaysText}>
          <Trans>Starts in {diffDays} days</Trans>
        </div>
      </div>
    )
  }
  return remainingTime ? (
    <div data-testid="stream-countdown" className={styles.StreamCountdown}>
      {remainginDays !== '00' && (
        <>
          <div className={styles.CountdownItem}>
            {remainginDays}
            <div className={styles.Text}>
              <Trans id="stream coundown days">Days</Trans>
            </div>
          </div>
          :
        </>
      )}
      <div className={styles.CountdownItem}>
        {remainingHours}
        <div className={styles.Text}>
          <Trans id="stream coundown hrs">Hrs</Trans>
        </div>
      </div>
      :
      <div className={styles.CountdownItem}>
        {remainingMinutes}
        <div className={styles.Text}>
          <Trans id="stream coundown min">Min</Trans>
        </div>
      </div>
      :
      <div className={styles.CountdownItem}>
        {remainingSeconds}
        <div className={styles.Text}>
          <Trans id="stream coundown sec">Sec</Trans>
        </div>
      </div>
    </div>
  ) : null
}
