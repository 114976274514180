import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { TitleThumbnailSection } from 'components/content/TitleThumbnailSection/TitleThumbnailSection'
import styles from './Start.module.scss'

interface Props {
  highlightsInView: boolean
  showHighlightsSection: boolean
  highlightRef: any
  getHighlightsScrollRef: any
  highlightsData: any
  thumbnailSize: 'large' | 'small'
  isLoading: boolean
}

export const HighlightsSection: FC<Props> = ({
  highlightsInView,
  showHighlightsSection,
  highlightRef,
  getHighlightsScrollRef,
  highlightsData,
  thumbnailSize,
  isLoading
}) =>
  showHighlightsSection ? (
    <div
      ref={highlightRef}
      className={styles.HighlightsContainer}
      data-testid="highlights-container"
    >
      {highlightsInView && (
        <TitleThumbnailSection
          getInfiniteScrollRef={getHighlightsScrollRef}
          thumbnailContent={highlightsData}
          sectionTitle={t`Highlights`}
          showAllLinkPath={undefined}
          linkLabel={t`All`}
          thumbnailsCardIsShared={false}
          size={thumbnailSize}
          isLoading={isLoading}
          useLeftPadding
        />
      )}
    </div>
  ) : null
