import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'

import { TwoColumModal } from './TwoColumnModal'
import { InfoBox, InfoBoxForm } from './InfoBoxForm/InfoBoxForm'
import { ContentTypeToggle } from './ContentTypeToggle/ContentTypeToggle'
import { ImageLink, ImageLinkForm } from './ImageLinkForm/ImageLinkForm'

import { StartBannerContentType } from '../StartPageBanner/startPageBannerTypes'
import styles from './NewContentModal.module.scss'

interface Props {
  onClose: (showStatusMessage: boolean) => void
  defaultOpen: boolean
  channelSlug: string
  existingForm?: InfoBox | ImageLink
  selectedContentType: StartBannerContentType
}

export const NewContentModal: FC<Props> = ({
  onClose,
  defaultOpen,
  channelSlug,
  existingForm,
  selectedContentType
}) => {
  const [contentType, setContentType] = useState<StartBannerContentType>(
    selectedContentType || StartBannerContentType.InfoBox
  )

  const handleChangeContentType = () =>
    contentType === StartBannerContentType.InfoBox
      ? setContentType(StartBannerContentType.ImageLink)
      : setContentType(StartBannerContentType.InfoBox)

  const onSubmitSuccess = () => {
    onClose(true)
  }

  return (
    <TwoColumModal
      modalHeader={t`Custom content`}
      defaultOpen={defaultOpen}
      onClose={() => onClose(false)}
    >
      <div className={styles.Container}>
        <div className={styles.ToggleButtonContainer}>
          <ContentTypeToggle
            contentType={contentType}
            onChangeContentType={handleChangeContentType}
          />
        </div>
        {contentType === StartBannerContentType.InfoBox ? (
          <InfoBoxForm
            onSubmitSuccess={onSubmitSuccess}
            channelSlug={channelSlug}
            onCancel={() => onClose(false)}
            // @ts-ignore
            existingForm={existingForm}
          />
        ) : (
          <ImageLinkForm
            onSubmitSuccess={onSubmitSuccess}
            channelSlug={channelSlug}
            onCancel={() => onClose(false)}
            // @ts-ignore
            existingForm={existingForm}
          />
        )}
      </div>
    </TwoColumModal>
  )
}
