import React, { FC } from 'react'
import styles from './TopTextSection.module.scss'

interface Props {
  title: string
  subText?: string
}

export const TopTextSection: FC<Props> = ({ title, subText }) => {
  return (
    <div className={styles.TextContainer}>
      <div className={styles.Title} data-testid="top-text-title">
        {title}
      </div>
      {subText && (
        <div className={styles.SubText} data-testid="top-text-sub-text">
          {subText}
        </div>
      )}
    </div>
  )
}
