import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { RelatedChannelType } from '../../components/content/RelatedChannel/RelatedChannel.interface'
import { Channel } from '../../interfaces/Channel.interface'

export const getParentClubs = async ({
  slug,
  page = 1,
  perPageCount = 10
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/channels/parent_clubs`,
      params: { page: page, per_page: perPageCount }
    })
    const responseData = await resultResponse
    const mapped = mapClubsResponse(responseData.data)
    return { data: mapped, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapClubsResponse = (apiResponse: Channel[]): RelatedChannelType[] => {
  return apiResponse.map(
    (club) =>
      ({
        subdomain: club.subdomain,
        logo_url: club.profile_image_url,
        name: club.playpage_title
      } as RelatedChannelType)
  )
}
