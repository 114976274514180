import React, { FC } from 'react'

import styles from './TeamsList.module.scss'
import { Loader } from '../../../../../components/Loader/Loader'
import { TeamItemInterface } from '../TeamItem/TeamItemProps'
import { TeamItem } from '../TeamItem/TeamItem'
import { TeamListHeader } from './TeamsListHeader/TeamsListHeader'

interface Props {
  listData: TeamItemInterface[]
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  onTeamItemClick: (selectedChannnel: string) => void
  onImportButtonClick: (selectedChannel: string) => void
}
export const TeamsList: FC<Props> = ({
  listData,
  isLoading,
  hasError,
  errorMessage,
  onTeamItemClick,
  onImportButtonClick
}) => {
  if (hasError) {
    return <div className={styles.ErrorText}>{errorMessage}</div>
  }
  if (listData) {
    return (
      <div className={styles.TeamsListContainer}>
        <TeamListHeader />
        {listData.map((item: TeamItemInterface, i: number) => (
          <TeamItem
            // eslint-disable-next-line react/no-array-index-key
            key={item.teamSlug + i}
            teamName={item.teamName}
            teamSlug={item.teamSlug}
            ongoingCompetitions={item.ongoingCompetitions}
            status={item.status}
            onClickTeamItem={() => onTeamItemClick(item.teamSlug)}
            onAddCompetitionToTeam={() => onImportButtonClick(item.teamSlug)}
          />
        ))}
        {isLoading && <Loader />}
      </div>
    )
  }
  return null
}
