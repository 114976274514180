import React, { FC } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'
import styles from './TextArea.module.scss'
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel'

interface Props {
  id: string
  label: string
  placeholder: string
  readonly?: boolean
}
export const TextArea: FC<Props> = ({ id, label, placeholder, readonly }) => {
  const [field, meta] = useField(id)
  return (
    <div
      className={classNames(styles.TextAreaContainer, {
        [styles.Error]: meta.error
      })}
    >
      <FormFieldLabel label={label} />
      <textarea
        data-testid="text-area"
        id={id}
        placeholder={placeholder}
        className={styles.TextArea}
        disabled={readonly}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div data-testid="text-area-error" className={styles.ErrorText}>
          {meta.error}
        </div>
      ) : null}
    </div>
  )
}
