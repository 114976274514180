import React, { FC } from 'react'
import Lottie from 'lottie-react'
import animationData from './send-confirmation.json'
import styles from './SendConfirmation.module.scss'

interface Props {
  onAnimationEnd: () => void
}
export const SendConfirmation: FC<Props> = ({ onAnimationEnd }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData
  }
  return (
    <div className={styles.AnimationContainer}>
      <Lottie {...defaultOptions} onComplete={onAnimationEnd} />
    </div>
  )
}
