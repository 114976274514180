import React, { FC } from 'react'
import {
  ColumnVariants,
  StandingsColumnInterface,
  StandingsRowInterface
} from '../Standings.interface'
import { StandingsCell } from '../StandingsCell'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import styles from './MobileColumns.module.scss'

interface Props {
  rows: StandingsRowInterface[]
  columns: StandingsColumnInterface[]
  columnVariant?: ColumnVariants
  teamIsHighlighted: boolean
}

export const MobileColumns: FC<Props> = ({
  rows,
  columns,
  columnVariant,
  teamIsHighlighted
}) => {
  const history = useHistory()
  const showEvenRowsColor = !teamIsHighlighted || rows.length > 3

  return (
    <>
      {rows.map((row, rowIndex) => {
        const displayOrder = parseInt(row.DisplayOrder as string)
        const placement = teamIsHighlighted ? displayOrder : rowIndex + 1
        const channelSlug = row?.subdomain?.toString()
        const name: string = row[columns[0].field].toString()
        const isLastBeforeDivider = !!row.isLastBeforeDivider
        const isLastPlacement = placement === row.length
        const teamIsPreSelected = row.selectedTeam
        const columnClick = (channelSlug: string) =>
          !!channelSlug && history.push(`/${channelSlug}`)

        return (
          <div
            className={classNames(styles.Row, {
              [styles.IsPreSelected]: teamIsPreSelected,
              [styles.Even]: showEvenRowsColor && rowIndex % 2
            })}
            key={`row-${placement}`}
          >
            {columns.map((column, columnIndex) => {
              const filteredContent =
                columnVariant === ColumnVariants.team
                  ? column.variant === ColumnVariants.team
                  : column.variant !== ColumnVariants.team

              return filteredContent ? (
                <div
                  key={`StandingsCell__${name}__${columnIndex}`}
                  onClick={() => columnClick(channelSlug)}
                  className={classNames({
                    [styles.Team]: columnVariant === ColumnVariants.team,
                    [styles.Stats]: columnVariant !== ColumnVariants.team,
                    [styles.IsClickable]: !!channelSlug,
                    [styles.IsLastPlacement]: !!isLastPlacement,
                    [styles.IsLastBeforeDivider]: !!isLastBeforeDivider
                  })}
                >
                  <StandingsCell
                    column={column}
                    row={row}
                    placement={placement}
                    key={`StandingsCell__${name}__${columnIndex}`}
                  />
                </div>
              ) : null
            })}
          </div>
        )
      })}
    </>
  )
}
