import {
  ChildChannel,
  ChildChannelResponse
} from 'interfaces/ChildChannel.interface'

export const mapChildChannelResponse = (
  response: ChildChannelResponse[]
): ChildChannel[] =>
  response.map((channel: ChildChannelResponse) => ({
    slug: channel.channel_slug,
    logoUrl: channel.logo_image_url,
    name: channel.name
  }))
