import React, { FC } from 'react'
import { ChannelTypes } from '../channelType'
import { Schedule } from '../Schedule/Schedule'
import ContentContainer from 'components/ContentContainer'

interface Props {
  isScheduleAdmin: boolean
  channelSlug: string
  channelType: ChannelTypes
}

export const GameSchedule: FC<Props> = ({
  isScheduleAdmin,
  channelSlug,
  channelType
}) => (
  <ContentContainer
    hasLightBackground
    hasLeftRightPadding
    hasTopBottomPadding={false}
  >
    {channelSlug && channelSlug !== '' && (
      <Schedule
        isAdmin={isScheduleAdmin}
        channelSlug={channelSlug}
        channelType={channelType}
      />
    )}
  </ContentContainer>
)
