import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import React, { FC } from 'react'
import GlobalHeaderLogo from '../topHeader/GlobalHeaderLogo'
import styles from './DefaultHeader.module.scss'
import { Link } from 'react-router-dom'
import { Search } from '../Search/Search'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { useSelector } from 'react-redux'
import { AuthButtons } from './AuthButtons'
import useCurrentUser from 'hooks/CurrentUser'
import { UserImageButton } from '../components/UserImgButton/UserImageButton'
import { useComponentVisible } from 'helpers/useComponentVisible'
import { HamburgerMenu } from '../components/HamburgerMenu/HamburgerMenu'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import HamburgerMenuIcon from '../components/HamburgerMenuIcon'

export const DefaultHeader: FC = () => {
  const currentBreakpoint = useCurrentBreakpoint()
  const currentUser = useCurrentUser()
  const channel = useSelector(channelSelector)
  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const isChildOfThemedRoot = channel?.data?.isChildOfRootChannel
  const rootChannelSlug = isChildOfThemedRoot
  const isMobile = currentBreakpoint < Breakpoint.md

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible)
  }
  return (
    <div
      className={styles.DefaultHeader}
      data-testid="navigation-menu-container"
    >
      <div className={styles.TopHeaderLogo}>
        <Link to="/calendar">
          <GlobalHeaderLogo data-testid="top-header-logo" />
        </Link>
      </div>
      <div className={styles.RightSection}>
        <div className={styles.SearchContainer}>
          <Search
            hasBackground={true}
            backgroundIsTransparentAtStart={false}
            rootChannelSlug={rootChannelSlug}
            isRootOrChildChannel={false}
          />
        </div>
        {!isMobile ? (
          currentUser ? (
            <UserImageButton onClick={toggleMenu} currentUser={currentUser} />
          ) : (
            <AuthButtons />
          )
        ) : (
          <IconButton
            id="hamburger-menu-button"
            onClick={toggleMenu}
            customStyle={styles.HamburgerMenuIcon}
          >
            <HamburgerMenuIcon />
          </IconButton>
        )}
      </div>
      {isComponentVisible && (
        <HamburgerMenu isOpen={isComponentVisible} onClose={toggleMenu} />
      )}
    </div>
  )
}
