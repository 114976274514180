import React from 'react'

import 'core-js/proposals/string-match-all'
import { createRoot } from 'react-dom/client'

import './index.css'
import reportWebVitals from './reportWebVitals'

import './fonts/NHaasGroteskDSPro-75Bd.otf'
import './fonts/NHaasGroteskTXPro-55Rg.otf'
import './fonts/NHaasGroteskTXPro-56It.otf'
import './fonts/NHaasGroteskTXPro-65Md.otf'
import './fonts/NHaasGroteskTXPro-66MdIt.otf'
import './fonts/NHaasGroteskTXPro-75Bd.otf'
import './fonts/NHaasGroteskTXPro-76BdIt.otf'

import { GTMInitialize } from './components/app/GoogleTagManager'
import { AppIndex } from './AppIndex'

GTMInitialize()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<AppIndex />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
