import { Team } from '../../../interfaces'

export enum BroadcastThumbnailType {
  Video = 'video',
  Broadcast = 'broadcast',
  Game = 'game',
  Livestream = 'livestream'
}
export interface BroadcastThumbnailInterface {
  id: string
  channelName: string
  channelImg: string
  channelUrl: string
  targetUrl: string
  country: string
  type: BroadcastThumbnailType | string
  startTime: number
  endTime: number
  highlightFromDate: number | null
  publishedAt: number
  hasHighlight: boolean
  isReplay: boolean
  viewers: number
  showViewers: boolean
  views: number
  showViews: boolean
  title: string
  thumbnailImageUrl?: string
  typeOfGame?: string
  homeTeam?: Team | null
  awayTeam?: Team | null
  duration: number
  rootDomain?: string
  event?: { name: string; slug: string }
  location?: { name: string; slug: string }
  categories?: { name: string; slug: string }[]
}
