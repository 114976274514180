/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { Icon } from '@mui/material'
import {
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp
} from '@mui/icons-material'
import './index.scss'
import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'

// /**
//  * @typedef {object} VolumeControl'
// */
type VolumeControlProps = {
  value?: number
  //changed?: (volume:number) => void
}

// /**
//  * VolumeControl
//  * @desc Volume control with slider
//  * @category video components controls
//  *
//  * @example
//  * return (
//  *   <VolumeControl changed={(volume) => setVolume(volume)} />
//  * )
//  */

const VolumeControl: React.FC<VolumeControlProps> = ({
  value = 1
  // changed = null
}) => {
  const { setVolume, playerState } = useContext(VideoPlayerContext)
  const { volume } = playerState
  const [lastVolume, setLastVolume] = useState<number>(value)

  const volumeIcon = (): any => {
    if (volume >= 0.5) {
      return VolumeUp
    }
    if (volume >= 0.3) {
      return VolumeDown
    }
    if (volume >= 0.01 && volume) {
      return VolumeMute
    }
    return VolumeOff
  }

  const leftOffset = (): string => {
    if (isSafari) {
      if (volume >= 0.5) {
        return '0'
      }
      if (volume >= 0.3) {
        return '-2px'
      }
      if (volume >= 0.01) {
        return '-4px'
      }
      return '0'
    }
    if (volume >= 0.5) {
      return '0'
    }
    if (volume >= 0.3) {
      return '-3px'
    }
    if (volume >= 0.01) {
      return '-7px'
    }
    return '0'
  }

  const onChangeSlider = (e: any) => {
    setVolume(Number(e.target.value))
  }

  const toggleMute = () => {
    if (volume > 0) {
      setLastVolume(volume)
      setVolume(0)
    } else {
      setVolume(lastVolume)
    }
  }

  useEffect(() => {
    setVolume(value)
  }, [value, setVolume])

  return (
    <div className="VolumeControl">
      <button
        type="button"
        onClick={() => toggleMute()}
        className="PlayerButton"
      >
        <Icon
          component={volumeIcon()}
          style={{ marginLeft: leftOffset() }}
          className="PlayerButtonIcon"
        />
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        onChange={(e) => onChangeSlider(e)}
        value={volume}
      />
    </div>
  )
}

export default VolumeControl
