import { useState, useEffect } from 'react'

export const Breakpoint = {
  xl: 1920,
  lg: 1535,
  md: 1259,
  sm: 899,
  mobile: 767,
  xs: 599
}

function getBreakpointValue(width: number): number {
  if (width <= Breakpoint.xs) {
    return Breakpoint.xs // xs
  }
  if (width <= Breakpoint.mobile) {
    return Breakpoint.mobile // mobile
  }
  if (width <= Breakpoint.sm) {
    return Breakpoint.sm // sm
  }
  if (width <= Breakpoint.md) {
    return Breakpoint.md // md
  }
  if (width <= Breakpoint.lg) {
    return Breakpoint.lg // lg
  }
  return Breakpoint.xl // xl
}

let wWidth = window.innerWidth
export function getWidth(): number {
  return wWidth
}

export function useCurrentBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(
    getBreakpointValue(window.innerWidth)
  )
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    wWidth = windowWidth
  }, [windowWidth])
  useEffect(() => {
    const isMounted = true
    // eslint-disable-next-line no-undef
    let timeoutId: NodeJS.Timeout
    const resizeListener = () => {
      if (isMounted) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          setBreakpoint(getBreakpointValue(window.innerWidth))
          setWindowWidth(window.innerWidth)
        }, 150)
      }
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])
  return breakpoint
}
