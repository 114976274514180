import React, { FC, useCallback, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'

import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { SettingsContentHeader } from '../../../components/page/header/SettingsContentHeader/SettingsContentHeader'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { ContentManagerSubmenu } from './SubMenu/ContentManagerSubmenu'
import { Competitions } from '../../../features/sportData/Competitions/Competitions'
import { CompetitionSelector } from '../../../features/sportData/CompetitionSelector/CompetitionSelector'
import Breadcrumb from '../Header/Breadcrumb/Breadcrumb'
import { StatusMessageProvider } from './StatusMessageContext'
import { Teams } from '../../../features/sportData/joinLeagueAsClub/Teams/Teams'
import { ChannelTypes } from '../channelType'
import { getAdminPath } from 'helpers/getAdminPath'
import { useDataApi } from 'api/useDataApi'
import { getIsAnyGameScheduleContent } from '../Header/AdminPanel/getIsAnyGameScheduleContent'
import { CustomContent } from 'features/customContent/CustomContent'
import styles from './ContentManager.module.scss'

export const ContentManager: FC = () => {
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data?.subdomain
  const channelType = channel.data?.type

  const adminPath = getAdminPath()
  const advancedSettingsHref = `${adminPath}/admin/settings/playpage/playpage_setting/edit?select_company=${channelSlug}`
  const { data: isAnyGameScheduleContentAvailable } = useDataApi({
    apiCall: useCallback(getIsAnyGameScheduleContent, []),
    parameters: useMemo(
      () => ({
        channelSlug,
        channelType
      }),
      [channelSlug, channelType]
    )
  })
  const hasActiveIntegration = isAnyGameScheduleContentAvailable

  const getDefaultContentToDisplay = () => {
    if (hasActiveIntegration) {
      if ([ChannelTypes.team, ChannelTypes.league].includes(channelType)) {
        return <Competitions />
      }
      if ([ChannelTypes.club].includes(channelType)) {
        return <Teams />
      }
    }
    return <CustomContent />
  }
  return (
    <div>
      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb />
      </div>
      <SettingsContentHeader
        title={t`Content manager`}
        buttonLabel={t`Advanced settings`}
        href={advancedSettingsHref}
      />
      <ContentManagerSubmenu hasActiveIntegration={hasActiveIntegration} />
      <StatusMessageProvider>
        <Switch>
          <Route exact path="/:company/contentmanager/competitions">
            <Competitions />
          </Route>
          <Route exact path="/:company/contentmanager/competitions/select">
            <CompetitionSelector
              channelSlug={channelSlug}
              channelType={channelType}
            />
          </Route>
          <Route exact path="/:company/contentmanager/teams">
            <Teams />
          </Route>
          <Route
            exact
            path="/:company/contentmanager/teams/:selectedTeam/competitions"
          >
            <Competitions />
          </Route>
          <Route
            exact
            path="/:company/contentmanager/teams/:selectedTeam/competitions/select"
          >
            <>
              <CompetitionSelector
                channelSlug={channelSlug}
                channelType={channelType}
              />
            </>
          </Route>
          <Route path="/:company/contentmanager/customcontent">
            <CustomContent />
          </Route>
          <Route exact path="/:company/contentmanager">
            {getDefaultContentToDisplay()}
          </Route>
        </Switch>
      </StatusMessageProvider>
    </div>
  )
}
