import React, { FC } from 'react'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows,
  PlayerStatsVariantsIdentifiers
} from '../../Table/PlayerStatisticsTable.interface'
import { TeamImageRowCell } from '../../Table/Row/RowCell/CellTypes/TeamImageRowCell'
import { PlayerRowCell } from '../../Table/Row/RowCell/CellTypes/PlayerRowCell'
import {
  CellType,
  DefaultRowCell
} from '../../Table/Row/RowCell/CellTypes/DefaultRowCell'

interface Props {
  row: PlayerStatisticsTableRows
  column: PlayerStatisticsTableColumns
  isSelected: boolean
}

export const CategoryRowCell: FC<Props> = ({ row, column, isSelected }) => {
  switch (column.identifier) {
    case PlayerStatsVariantsIdentifiers.TeamLogoUrl:
      return (
        <TeamImageRowCell
          imageUrl={row[column.identifier].toString()}
          isSelected={isSelected}
          type={CellType.CategoryLeader}
        />
      )
    case PlayerStatsVariantsIdentifiers.Player:
      return typeof row.player === 'object' ? (
        <PlayerRowCell
          playerName={row.player.name}
          teamName={row.player.team}
          isEmpty={false}
          type={CellType.CategoryLeader}
        />
      ) : (
        <PlayerRowCell
          playerName={''}
          teamName={''}
          isEmpty={true}
          type={CellType.CategoryLeader}
        />
      )
    default:
      if (isSelected) {
        return typeof row[column.identifier] !== 'object' ? (
          <DefaultRowCell
            cellText={row[column.identifier].toString()}
            isEmpty={false}
            isSelected={isSelected}
            type={CellType.CategoryLeader}
          />
        ) : (
          <DefaultRowCell
            cellText="-"
            isEmpty={false}
            isSelected={isSelected}
            type={CellType.CategoryLeader}
          />
        )
      }
      return null
  }
}
