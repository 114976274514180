import * as yup from 'yup'
import { t } from '@lingui/macro'

export const topNavigationLinkSchema = yup.object({
  textField: yup.string().when('linkField', {
    is: (val: string) => Boolean(val),
    then: yup.string().required(t`You need to add a label`),
    otherwise: yup.string().notRequired()
  }),
  linkField: yup
    .string()
    .url(t`Please enter web link in the format https://domain.com/path`)
})
