import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { DropdownList, DropDownListItem } from './DropdownList/DropdownList'
import Field from './Field/Field'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { getDefaultCategoriesList } from './getDefaultCategoryList'
import { useComponentVisible } from '../../../../helpers/useComponentVisible'

import styles from './CategoryFieldDropdown.module.scss'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../../hooks/CurrentBreakpoint'
import MobileSelectList from './MobileSelectList/MobileSelectList'
import ReactPortal from '../../../../components/ReactPortal'

interface Props {
  dropdownList: DropDownListItem[] | null
  handleSelectedItem: (id: string) => void
}
const CategoryFieldDropdown: FC<Props> = ({
  dropdownList,
  handleSelectedItem
}) => {
  const channel = useSelector(channelSelector)
  const breakpoint = useCurrentBreakpoint()

  const categoriesList = getDefaultCategoriesList(
    channel?.data?.meta_data?.content?.has_highlights
  )

  const [selectedItemLabel, setSelectedItemLabel] = useState(
    categoriesList[0].label
  )
  const [selectedItemId, setSelectedItemId] = useState(categoriesList[0].id)

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const openDropdownList = () => {
    if (!isComponentVisible) {
      setIsComponentVisible(true)
    } else {
      setIsComponentVisible(false)
    }
  }
  const onItemSelected = (label: string, id: string) => {
    setSelectedItemLabel(label)
    setSelectedItemId(id)
    handleSelectedItem(id)
    setIsComponentVisible(false)
  }

  const listWithDefaultCategories = dropdownList
    ? categoriesList.concat(dropdownList)
    : categoriesList

  return (
    <div
      ref={ref}
      className={classNames(styles.FieldDropdown, {
        [styles.Open]: isComponentVisible
      })}
    >
      <Field
        selected={selectedItemLabel}
        onClick={() => openDropdownList()}
        rotateArrow={isComponentVisible}
      />
      {isComponentVisible &&
        (breakpoint > Breakpoint.sm ? (
          <DropdownList
            list={listWithDefaultCategories}
            onItemSelected={(name, id) => onItemSelected(name, id)}
            selectedItemId={selectedItemId}
          />
        ) : (
          <ReactPortal wrapperId="react-portal-modal-container">
            <MobileSelectList
              list={listWithDefaultCategories}
              onItemSelected={(name, id) => onItemSelected(name, id)}
              selectedItemId={selectedItemId}
              reference={ref}
              onCloseClick={() => setIsComponentVisible(false)}
            />
          </ReactPortal>
        ))}
    </div>
  )
}
export default CategoryFieldDropdown
