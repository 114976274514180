import React, { FC } from 'react'
import { t } from '@lingui/macro'
import {
  LinkButton,
  ButtonType
} from 'components/buttons/LinkButton/LinkButton'

interface Props {
  id: string
  channelSlug: string
  rootDomain?: string
}
export const UpcomingLivestreamButton: FC<Props> = ({
  id,
  channelSlug,
  rootDomain
}) => (
  <LinkButton
    type={ButtonType.Secondary}
    buttonSize="Small"
    label={t`Watch game`}
    href={`/${channelSlug}/games/g/${id}`}
    rootDomain={rootDomain}
  />
)
