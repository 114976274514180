const SUBSCRIBER = {
  CONNECTING: 'SUBSCRIBER_CONNECTING',
  CONNECTED: 'SUBSCRIBER_CONNECTED',
  DISCONNECTING: 'SUBSCRIBER_DISCONNECTING',
  DISCONNECTED: 'SUBSCRIBER_DISCONNECTED',
  ERROR: 'SUBSCRIBER_ERROR',
  NEW_CHAT_MESSAGE: 'SUBSCRIBER_NEW_CHAT_MESSAGE',
  REMOVE_NEW_CHAT_MESSAGE: 'SUBSCRIBER_REMOVE_CHAT_MESSAGE',
  HIDE_CHAT_MESSAGE: 'SUBSCRIBER_HIDE_CHAT_MESSAGE',
  NEW_BROADCAST_MESSAGE: 'SUBSCRIBER_NEW_BROADCAST_MESSAGE',
  NEW_COUNTDOWN_MESSAGE: 'SUBSCRIBER_COUNTDOWN_MESSAGE',
  CONTEXT: {
    CHAT: 'chat',
    BROADCAST_MESSAGE: 'broadcast_message',
    COUNTDOWN_MESSAGE: 'countdown'
  }
}

export default SUBSCRIBER
