import React, { FC, useState } from 'react'
import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { t } from '@lingui/macro'
import { useChannelPage } from '../useChannelPage'
import { getGroupsForEvent } from 'api/channel/getGroupsForEvent'
import { ChannelListItem } from 'components/lists/ChannelListItem/ChannelListItem'
import { ChildChannel } from 'interfaces/ChildChannel.interface'

interface Props {
  channelSlug: string
}

export const EventGroups: FC<Props> = ({ channelSlug }) => {
  const emptyMessage = t`There are no groups currently registered for this event`
  const [groups, setGroups] = useState<ChildChannel[]>([])

  const PER_PAGE_COUNT = 30
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getGroupsForEvent,
    onData: (data: any) => setGroups((prev) => [...prev, ...data]),
    setTotalPageCount: (total: number) => setTotalPages(total / PER_PAGE_COUNT)
  })

  const isEmpty = !groups.length
  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }
  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {groups.map((group: ChildChannel) => (
        <ChannelListItem
          key={group.slug}
          channelLink={{
            name: group.name,
            slug: group.slug,
            logo: group.logoUrl
          }}
        />
      ))}
    </ChannelPageList>
  )
}
