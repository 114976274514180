import React, { FC, useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'

import { useDataApi } from '../../../api/useDataApi'
import { getNewsForChannel } from '../../../api/channel/getNews'

import ArticleThumbnailSlider from '../../../components/sliders/ArticleThumbnailSlider/ArticleThumbnailSlider'

interface Props {
  channelSlug: string
  thumbnailSize: 'small' | 'large'
}

const NewsThumbnailSection: FC<Props> = ({ channelSlug, thumbnailSize }) => {
  const { isLoading, isError, data } = useDataApi({
    apiCall: useCallback(getNewsForChannel, []),
    parameters: useMemo(
      () => ({
        slug: channelSlug,
        perPageCount: 10,
        page: 1
      }),
      [channelSlug]
    )
  })

  const articlesData = {
    isLoading,
    isError,
    data: data?.data
  }
  return (
    <ArticleThumbnailSlider
      title={t`News`}
      relatedArticles={articlesData}
      size={thumbnailSize}
      strongHeading={false}
    />
  )
}
export default NewsThumbnailSection
