import { t } from '@lingui/macro'
import { LinkType, MenuLink } from '../../menuLink/MenuLink'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

export const links: MenuLink[] = [
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', 'about_us'],
    translationText: t`About us`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.Route,
    link: '/calendar',
    translationText: t`Schedule`
  },
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', 'products'],
    translationText: t`Our products`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: 'https://solidsport.freshdesk.com/en/support/home',
    translationText: t`Support`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', 'contact-us'],
    translationText: t`Contact us`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: ['https://www.solidsport.com', 'privacy-policy'],
    translationText: t`Terms`,
    linkTarget: LinkTarget.NewWindow
  },
  {
    type: LinkType.AnchorTag,
    link: 'https://jobs.solidsport.com/',
    translationText: t`Jobs`,
    linkTarget: LinkTarget.NewWindow
  }
]
