import React, { FC } from 'react'

import styles from './ChannelListItemPreloader.module.scss'

export const ChannelListItemPreloader: FC = () => (
  <div className={styles.ChannelListItemPreloader}>
    <div className={styles.IconLoaderImage} />
    <div className={styles.TextLoaderImage} />
  </div>
)
