import React from 'react'
export const ErrorIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="19.25"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="9.25"
      transform="rotate(90 19.25 0.75)"
      stroke="#F44336"
      strokeWidth="1.5"
    />
    <path
      d="M7 13L13 7"
      stroke="#F44336"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 13L7 7"
      stroke="#F44336"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
