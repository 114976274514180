import React, { FC } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import RightArrow from '../../../assets/icons/RightArrow'
import styles from './index.module.scss'

interface Props {
  label: string
  linkPath: string | undefined
  linkLabel: string
  useLeftPadding?: boolean
}

const ThumbnailSectionHeader: FC<Props> = ({
  label,
  linkPath,
  linkLabel,
  useLeftPadding
}) => (
  <div
    className={classNames(styles.SectionHeader, {
      [styles.LeftPadding]: useLeftPadding,
      [styles.NoPadding]: !useLeftPadding
    })}
  >
    <h4 data-testid="thumbnail-slider-title" className={styles.Label}>
      {label}
    </h4>
    {linkPath && (
      <Link className={styles.ShowAllLink} to={linkPath}>
        {linkLabel}
        <div className={styles.Icon}>
          <RightArrow height="12px" width="12px" />
        </div>
      </Link>
    )}
  </div>
)

ThumbnailSectionHeader.defaultProps = {
  useLeftPadding: true
}
export default ThumbnailSectionHeader
