import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { PlayPauseIcon } from './PlayPauseIcon'

import styles from './Button.module.scss'

interface Props {
  isPlaying: boolean
  bigPlayButton: any
}

export const PlayPauseButton: FC<Props> = ({ isPlaying, bigPlayButton }) => (
  <IconButton
    aria-label="play"
    className={styles.OverlayButton}
    ref={bigPlayButton}
  >
    <PlayPauseIcon isPlaying={isPlaying} isLarge={true} />
  </IconButton>
)
