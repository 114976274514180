import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const usePagination = (withHistory: boolean = true) => {
  const history = useHistory()
  const location = useLocation()
  const [lastPage, setLastPage] = useState(0)
  const [maxPages, setMaxPages] = useState(0)
  const [limit, setLimit] = useState<number>(0)
  const [reachedEnd, setReachedEnd] = useState(false)
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
  const page = new URLSearchParams(history.location.search).get('page')
  const [currentPage, setCurrentPage] = useState(Number(page) || -1)

  const calculateMaxPages = (total: number, per_page: number) => {
    setTotalCount(total)
    setLimit(per_page)
    setMaxPages(Math.ceil(total / per_page))
  }

  useEffect(() => {
    setCurrentPage(Number(page) || 1)
  }, [page])

  const next = () => {
    const nextPage = currentPage + 1
    setLastPage(currentPage)
    setCurrentPage(nextPage)
  }
  const prev = () => {
    const nextPage = currentPage - 1
    setLastPage(currentPage)
    setCurrentPage(nextPage)
  }
  const jump = (newPage: number) => {
    if (totalCount && currentPage * limit < totalCount) {
      setReachedEnd(false)
    }
    setLastPage(currentPage)
    setCurrentPage(Math.max(1, newPage))
  }

  const reset = () => {
    setCurrentPage(0)
    setLastPage(0)
    setMaxPages(0)
    setTotalCount(undefined)
    setLimit(0)
    setReachedEnd(false)
  }

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1)
      return
    }
    const params = new URLSearchParams(location.search)
    const oldParams = new URLSearchParams(location.search)
    if (currentPage > 1) {
      params.set('page', currentPage.toString())
    } else {
      params.delete('page')
    }
    if (withHistory && params.toString() !== oldParams.toString()) {
      history.push(`${location.pathname}?${params.toString()}`)
    }
  }, [currentPage])

  useEffect(() => {
    if (totalCount && currentPage * limit >= totalCount) {
      setReachedEnd(true)
    }
  }, [currentPage, totalCount, limit])

  return {
    next,
    prev,
    jump,
    calculateMaxPages,
    reset,
    lastPage,
    currentPage,
    maxPages,
    totalCount,
    reachedEnd
  }
}
