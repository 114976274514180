import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { PurchaseContainer } from './PurchaseContainer/PurchaseContainer'

import { getSubscriptionsAndAllAccess } from 'api/channel/purchases/getSubscriptionsAndAllAccess'
import styles from './PurchaseContainer/purchaseContainer.module.scss'
import ContentContainer from 'components/ContentContainer'

interface Props {
  channelSlug: string
}

export const BuyAccess: FC<Props> = ({ channelSlug }) => (
  <ContentContainer
    hasLightBackground
    hasLeftRightPadding={false}
    hasTopBottomPadding={false}
  >
    <PurchaseContainer
      channelSlug={channelSlug}
      apiCall={getSubscriptionsAndAllAccess}
    >
      <div className={styles.PurchaseHeading}>
        <h3>
          <Trans>Get access</Trans>
        </h3>
        <div className={styles.PurchaseTagLine}>
          <Trans>
            Get the most out of our channel by choosing one of the options
            below!
          </Trans>
        </div>
      </div>
    </PurchaseContainer>
  </ContentContainer>
)
