import { t } from '@lingui/macro'
import { DomainRegistrationStatus } from './DomainRegistrationStatus'

export const getRegistrationStatusText = (
  domainStatus: DomainRegistrationStatus,
  domainName: string
) => {
  switch (domainStatus) {
    case DomainRegistrationStatus.ChangingDomain:
      return t`Changing from ${domainName}`
    case DomainRegistrationStatus.DomainAlreadyInUse:
      return t`This domain is already in use`
    case DomainRegistrationStatus.NotSet:
      return ''
    case DomainRegistrationStatus.SslGenerated:
      return t`SSL Generated`
    case DomainRegistrationStatus.ValidatingDomain:
      return t`Validating domain...`
    case DomainRegistrationStatus.WaitingForPartnerConfiguration:
      return t`Waiting for partner to configure domain...`
    case DomainRegistrationStatus.WaitingForSslGeneration:
      return t`Domain configured. Waiting for SSL generation...`
    case DomainRegistrationStatus.Expired:
      return t`Domain registration has expired`
    case DomainRegistrationStatus.ConfigurationError:
      return t`Domain registration configuration error`
    case DomainRegistrationStatus.SslInactive:
      return t`The SSL certificate is inactive`
    case DomainRegistrationStatus.Aborted:
      return t`Registration has been aborted`
    default:
      return ''
  }
}
