import { Subscription } from '../../../../interfaces'
import { ProductType } from '../ProductType'

export const getCardSubscriptionType = (card: Subscription) => {
  if (!card.all_access) {
    if (card.subscription_type === 'monthly_supporter') {
      return ProductType.MonthlySupporter
    }
    return ProductType.Subscription
  }
  return ProductType.Tournament
}
