import React, { FC } from 'react'
import styles from './Toggle.module.scss'
import { ToggleItem, ItemType } from './ToggleItem/ToggleItem'

interface Props {
  firstLabel: string
  firstButtonSelected: boolean
  secondLabel: string
  onClick: () => void
}
export const Toggle: FC<Props> = ({
  firstLabel,
  secondLabel,
  firstButtonSelected,
  onClick
}) => (
  <div className={styles.Toggle} data-testid="toggle-container">
    <div
      className={styles.FirstButton}
      data-testid="toggle-first-btn-container"
    >
      <ToggleItem
        itemType={firstButtonSelected ? ItemType.Active : ItemType.Default}
        label={firstLabel}
        onClick={() => {
          onClick()
        }}
      />
    </div>
    <div
      className={styles.SecondButton}
      data-testid="toggle-second-btn-container"
    >
      <ToggleItem
        itemType={firstButtonSelected ? ItemType.Default : ItemType.Active}
        label={secondLabel}
        onClick={() => {
          onClick()
        }}
      />
    </div>
  </div>
)
