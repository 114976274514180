import { getFilteredGameScheduleList } from '../../../../api/sportData/getFilteredGameScheduleLists'
import { getGameScheduleList } from '../../../../api/sportData/getGameScheduleList'
import { ChannelTypes } from '../../channelType'

interface Request {
  channelSlug: string
  channelType: ChannelTypes
}

export const getIsAnyGameScheduleContent = async ({
  channelSlug,
  channelType
}: Request) => {
  try {
    if (
      [ChannelTypes.league, ChannelTypes.club, ChannelTypes.team].includes(
        channelType
      )
    ) {
      const content = await getGameScheduleList({
        request: { channelSlug, page: 1, perPageCount: 1 },
        pastGames: true
      })
      const availableCompetitions = await getFilteredGameScheduleList({
        searchText: '',
        groupId: '',
        channelSlug,
        page: 1,
        perPageCount: 1
      })
      return content.length > 0 || availableCompetitions?.data?.length > 0
    }
    return false
  } catch (err: any) {
    return false
  }
}
