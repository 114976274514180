import { AnyAction } from 'redux'
import CHANNEL_THEME from './channelTheme.constants'
import { ThemeOptions } from './channelTheme.interface'

export interface ChannelTheme {
  isLoading: boolean
  isError: boolean
  data: ThemeOptions | undefined
  isHeaderFontLoaded: boolean
  isSubmenuFontLoaded: boolean
}

export const defaultTheme = {
  channelPrimaryColor: '#000000',
  channelPrimaryBackgroundColor: '#FFFFFF',
  channelPrimaryColorInverted: '#000000',
  channelPrimaryBackgroundColorInverted: '#FFFFFF',
  channelTextTransformation: 'none'
}

export const initialState = {
  isLoading: true,
  isError: false,
  data: undefined,
  isHeaderFontLoaded: false,
  isSubmenuFontLoaded: false
}

const channelTheme = (
  state: ChannelTheme = initialState,
  action: AnyAction
) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case CHANNEL_THEME.SET_IS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case CHANNEL_THEME.SET_DEFAULT_THEME:
      return {
        ...state,
        isLoading: false,
        data: defaultTheme
      }
    case CHANNEL_THEME.SET_THEME:
      return {
        ...state,
        isLoading: false,
        data: action.data
      }
    case CHANNEL_THEME.SET_HEADER_FONT_LOADED:
      return {
        ...state,
        isLoading: false,
        isHeaderFontLoaded: true
      }
    case CHANNEL_THEME.SET_SUBMENU_FONT_LOADED:
      return {
        ...state,
        isLoading: false,
        isSubmenuFontLoaded: true
      }
    default:
      return state
  }
}

export default channelTheme
