import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { mapEventResponse } from './mapEventResponse'

export const getPreviousGames = async ({
  channelSlug,
  eventSlug,
  currentPage,
  perPage
}: // teamSlug
{
  channelSlug: string
  eventSlug: string
  currentPage: number
  perPage: number
  // teamSlug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `events/event/${eventSlug}/games/previous`,
      params: {
        company: channelSlug,
        page: currentPage,
        per_page: perPage
      }
    })
    const responseData = await resultResponse
    const map = mapEventResponse(responseData.data, false)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
