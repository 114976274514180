import React, { FC, useContext } from 'react'
import dayjs from 'dayjs'
import { RewindButton } from './controls/RewindButton'
import { FastforwardButton } from './controls/FastforwardButton'
import { PlayPauseButton } from './controls/PlayPauseButton'
import { VideoPlayerContext } from 'providers/VideoPlayerProvider/VideoPlayerProvider'

interface Props {
  adsDone: boolean
  addToProgressBuffer: (value: number) => void
  progressBuffer: any
  isLive: boolean
  isPlaying: boolean
  bigPlayButton: any
  getCurrentTime: any
  getDuration: any
}

export const OverlayControls: FC<Props> = ({
  adsDone,
  addToProgressBuffer,
  progressBuffer,
  isLive,
  isPlaying,
  bigPlayButton,
  getCurrentTime,
  getDuration
}) => {
  const { playerState, viewedStreamPercentage, chromecast } =
    useContext(VideoPlayerContext)
  const { isDvr } = playerState
  const formatProgressText = (value: number): string => {
    let formattedValue: string
    let negative = false
    if (value < 0) {
      // Register if the value is negative so we can convert it to positive and treat it as such
      // to make formatting easier with dayjs. Formatting a negative value goes around like a clock,
      // so a value of -1 formats to 59:59.
      negative = true
    }
    // Make sure the value is within bounds of what's possible to seek from the current position.
    // If the video is 01:00 long, we should never get a contained value higher than 60.
    // Likewise if we have played for 01:00 we should never get a contained value higher
    // than 60. A negative value is converted to positive.
    const containedValue = Math.max(
      0, // Since we convert the value to positive further down we only have to handle minimun 0 value.
      Math.min(
        // If the value is negative limit it to how much we have currently played.
        // If it's positive, limit it to the duration we have left to play.
        negative ? getCurrentTime() : getDuration() - getCurrentTime(),
        // If the value is negative, convert it to positive so we can format it correctly with dayjs.
        negative ? 0 - value : value
      )
    )
    if (containedValue > 60 * 60) {
      formattedValue = dayjs.unix(containedValue).format('HH:mm:ss')
    } else {
      formattedValue = dayjs.unix(containedValue).format('mm:ss')
    }
    if (negative) {
      return `-${formattedValue}`
    }
    return `+${formattedValue}`
  }

  const formatNewProgressText = (value: number): string => {
    let formattedValue: string
    const containedValue = Math.max(
      0,
      Math.min(getDuration(), getCurrentTime() + value)
    )
    if (containedValue > 60 * 60) {
      formattedValue = dayjs.unix(containedValue).format('HH:mm:ss')
    } else {
      formattedValue = dayjs.unix(containedValue).format('mm:ss')
    }
    return `(${formattedValue})`
  }
  const showRewindButton = !isLive || (isLive && isDvr && !chromecast.connected)
  const showFastForwardButton =
    !isLive ||
    (isLive && isDvr && !chromecast.connected && viewedStreamPercentage < 99.5)
  return (
    <div
      className="OverlayControls"
      style={{ display: adsDone ? 'block' : 'none' }}
    >
      <div className="OverlayControlsInner">
        <div className={'RewindButtonContainer'}>
          {showRewindButton && (
            <RewindButton onClick={() => addToProgressBuffer(-5)} />
          )}
        </div>
        <div className="OverlayCenter">
          {progressBuffer ? (
            <div className="OverlayText">
              <div className="OverlayTextTop">
                {formatProgressText(progressBuffer)}
              </div>
              <div className="OverlayTextBottom">
                {formatNewProgressText(progressBuffer)}
              </div>
            </div>
          ) : (
            <PlayPauseButton
              bigPlayButton={bigPlayButton}
              isPlaying={isPlaying}
            />
          )}
        </div>
        <div className="ForwardButtonContainer">
          {showFastForwardButton && (
            <FastforwardButton onClick={() => addToProgressBuffer(5)} />
          )}
        </div>
      </div>
    </div>
  )
}
