import React, { FC, useCallback } from 'react'

import { getSponsors } from '../../../api/channel/getSponsors'
import { useDataApi } from '../../../api/useDataApi'
import SponsorContent from './SponsorContent'
import ContentContainer from '../../ContentContainer'

interface Props {
  channelSlug: string
}

const SponsorSection: FC<Props> = ({ channelSlug }) => {
  const getSponsorsWrapper = useCallback(getSponsors, [])
  const { isLoading, isError, data } = useDataApi({
    apiCall: getSponsorsWrapper,
    parameters: channelSlug
  })

  return !data || (data && data.length !== 0) ? (
    <ContentContainer
      hasLightBackground
      hasLeftRightPadding={false}
      hasTopBottomPadding
    >
      <SponsorContent isLoading={isLoading} isError={isError} sponsors={data} />
    </ContentContainer>
  ) : null
}
export default SponsorSection
