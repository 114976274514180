import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getEventLocations } from 'api/multistageTournament/getEventLocations'
import { FilterItem, FilterList } from './FilterList/FilterList'
import { FilterType } from './FilterItem/FilterItem'
import { FilterTitle } from './FilterTitle/FilterTitle'
import { FilterHeader } from './FilterHeader/FilterHeader'
import { CategoryFilterType } from 'features/multistageTournaments/CategoryFilter/CategoryFilter'
import { getFilterItemFromData } from './getFilterItemFromData'
import { FilteredContent } from 'features/multistageTournaments/FilteredContent/FilteredContent'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'
import styles from './HierarchicalFilter.module.scss'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'
interface Props {
  channelSlug: string
  backgroundType: FilteredGamesListType
  filterType: CategoryFilterType
  eventData: ChannelEvent
}

export const LocationsFilter: FC<Props> = ({
  channelSlug,
  backgroundType,
  filterType,
  eventData
}) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [locationChildren, setLocationChildren] = useState<FilterItem[]>([])
  const [displayLocationChildren, setDisplayLocationChildren] = useState(false)
  const eventSlug = eventData.eventSlug
  const { location, field } = useParams<{
    location?: string
    field?: string
  }>()
  const history = useHistory()

  const clearFiltersSelected = () => {
    setLocationChildren([])
    setSelectedFilters([])
  }

  const {
    data,
    isLoading,
    isError,
    refetch
  }: {
    data: any | undefined
    isLoading: boolean
    isError: boolean
    refetch: () => void
  } = useQuery({
    // @ts-ignore
    queryKey: [`get-event-locations-${eventSlug}`],

    queryFn: () =>
      getEventLocations({
        eventSlug: eventSlug,
        slug: channelSlug
      })
  })

  useEffect(() => {
    if (location) {
      const selectedLocation = data?.data.find(
        (loc: FilterItem) => loc.slug === location
      )
      if (selectedLocation !== undefined) {
        setSelectedFilters([...selectedFilters, selectedLocation])
      }
    }
  }, [data])

  useEffect(() => {
    if (field && locationChildren.length > 0) {
      const selectedField = getFilterItemFromData(locationChildren, field)
      if (selectedField !== undefined) {
        setSelectedFilters([...selectedFilters, selectedField])
      }
    }
  }, [locationChildren])

  useEffect(() => {
    clearFiltersSelected()
  }, [eventSlug])

  const handleOnFilterClick = (
    filterSlug: string,
    useFilterChildren: boolean
  ) => {
    const selectedFilterData = useFilterChildren
      ? getFilterItemFromData(locationChildren, filterSlug)
      : getFilterItemFromData(data?.data!, filterSlug)

    if (selectedFilterData !== undefined) {
      setSelectedFilters((arr) => [...arr, selectedFilterData])
      history.push(
        useFilterChildren
          ? `/${channelSlug}/games/event/${eventSlug}/locations/${location}/fields/${selectedFilterData.slug}`
          : `/${channelSlug}/games/event/${eventSlug}/locations/${selectedFilterData?.slug}`
      )
    }
  }

  const handleNextLevel = (selectedFilterItem: FilterItem) => {
    if (
      selectedFilterItem?.children &&
      selectedFilterItem?.children?.length > 0
    ) {
      setLocationChildren(selectedFilterItem.children)
      setDisplayLocationChildren(true)
    } else {
      setDisplayLocationChildren(false)
    }
  }
  const handleOnBackClick = () => {
    history.push(`/${channelSlug}/games/event/${eventSlug}/locations`)
    clearFiltersSelected()
  }

  const removeSelectedFilter = () => {
    setSelectedFilters((arr) => arr.slice(0, -1))
    if (field) {
      history.push(
        `/${channelSlug}/games/event/${eventSlug}/locations/${location}`
      )
    } else {
      history.push(`/${channelSlug}/games/event/${eventSlug}/locations`)
      clearFiltersSelected()
    }
    refetch()
  }
  useEffect(() => {
    handleNextLevel(selectedFilters[selectedFilters.length - 1])
  }, [selectedFilters])

  return (
    <div data-testid="event-locations-filter">
      <FilterTitle title={t`Filter ${eventData.name} games by locations`} />
      <FilterHeader
        selectedFilters={selectedFilters}
        filterType={filterType}
        onBackClick={handleOnBackClick}
        onRemoveFilter={removeSelectedFilter}
      />
      {selectedFilters?.length < 1 && (
        <FilterList
          isError={isError}
          isLoading={isLoading}
          list={data?.data!}
          filterItemType={FilterType.Primary}
          onClick={(filterSlug) => handleOnFilterClick(filterSlug, false)}
        />
      )}
      {locationChildren?.length > 0 && displayLocationChildren && (
        <div className={styles.FilterList}>
          <FilterList
            isError={false}
            isLoading={false}
            list={locationChildren}
            filterItemType={FilterType.Primary}
            onClick={(filterSlug) => handleOnFilterClick(filterSlug, true)}
          />
        </div>
      )}
      {selectedFilters?.length > 0 && (
        <div className={styles.ContentContainer}>
          <FilteredContent
            filterType={filterType}
            selectedFilter={selectedFilters[selectedFilters.length - 1].slug}
            channelSlug={channelSlug}
            type={backgroundType}
            eventSlug={eventSlug}
          />
        </div>
      )}
    </div>
  )
}
