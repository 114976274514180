import React, { FC } from 'react'
import { BulletPoint } from '../TextSection/getBulletPointList'
import styles from './BulletPointList.module.scss'

interface Props {
  bulletPoints: BulletPoint[]
}

export const BulletPointList: FC<Props> = ({ bulletPoints }) => {
  if (bulletPoints && bulletPoints.length > 0) {
    return (
      <ul className={styles.List}>
        {bulletPoints.map((bulletPoint: BulletPoint) => (
          <li key={bulletPoint.key}>{bulletPoint.content}</li>
        ))}
      </ul>
    )
  }
  return null
}
