import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows
} from 'features/statistics/PlayerStatistics/Table/PlayerStatisticsTable.interface'
import { mapPlayerStatisticsResponse } from './mapPlayerStatisticsResponse'

const BASE_URL = process.env.REACT_APP_SERVICE_API_BASE_URL
export interface IntegrationApiResponse {
  name: string
  link: string
  lastUpdated: number
}
export interface PlayerStatisticsApiResponse {
  name: string
  id: string
  sortedBy: string
  integration: IntegrationApiResponse
  lastUpdatedTimestamp: number
  columns: PlayerStatisticsTableColumns[]
  rows: PlayerStatisticsTableRows[]
}

export const getPlayerStatisticsForLeague = async ({
  leagueId
}: {
  leagueId: string
}) => {
  try {
    const url = `${BASE_URL}event-bff/api/v1/${leagueId}/playerstats`
    const response = await fetch(url)
    const data = await response.json()
    const mapped = mapPlayerStatisticsResponse(data[0])

    return mapped
  } catch (e: any) {
    console.log('e', e)
    throw new Error(e.message)
  }
}
