import React, { FC, useState } from 'react'
import { Sport } from '../cardTypes'
import Test from '../smallhandball.jpeg'

import styles from './sport.module.scss'

interface Props {
  content: Sport
}

const SportContent: FC<Props> = ({ content }) => {
  const { name, imageUrl } = content
  const image = imageUrl && imageUrl !== '' ? imageUrl : Test
  const color1 = '#000000'
  const color2 = 'rgba(0, 0, 0, 0) 58.21%'
  const [hover, setHover] = useState(false)

  const style = {
    normal: {
      backgroundImage: `linear-gradient(to bottom, ${color1} 0%, ${color2} 100%) `
    },
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  return (
    <>
      <div
        data-testid="sport-container"
        className={styles.SportContainer}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div />
        <div
          className={styles.SportContent}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            ...style.normal,
            ...(hover ? style.hover : null)
          }}
        >
          <h3>{name}</h3>
        </div>
      </div>
    </>
  )
}
export default SportContent
