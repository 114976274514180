import React from 'react'

const CheckIcon = () => {
  return (
    <svg
      width="50"
      height="36"
      viewBox="0 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.7 2.19531L16.9 32.9953L2.90002 18.9953"
        stroke="white"
        strokeWidth="4.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default CheckIcon
