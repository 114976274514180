import React from 'react'
export const SolidsportAccountLightLogo = () => (
  <svg
    width="231"
    height="26"
    viewBox="0 0 231 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11446_15761)">
      <path
        d="M17.3952 1.81787C16.6761 2.09407 15.9789 2.42563 15.3097 2.80966C14.6036 3.19608 13.9731 3.7099 13.4491 4.32585C12.6812 5.2576 12.3617 6.11488 12.4907 6.89767C12.6617 7.95254 13.4731 8.78398 14.925 9.39197C15.4649 9.61085 16.5321 9.93841 18.1264 10.3746C19.32 10.6807 20.4956 11.0552 21.6473 11.4964C22.5052 11.811 23.2852 12.3113 23.9331 12.9624C23.9331 12.8507 23.9331 12.7344 23.9331 12.6295C23.9336 10.3916 23.3231 8.19712 22.1693 6.28896C21.0154 4.38081 19.3631 2.83337 17.3952 1.81787Z"
        fill="#EC1E24"
      />
      <path
        d="M3.08665 16.7746C3.71659 15.171 4.6165 13.9611 5.78638 13.1449C5.95415 13.0203 6.15456 12.9489 6.36232 12.9397C6.49956 12.9533 6.41632 13.1403 6.11485 13.5005C5.49421 14.1782 4.99139 14.9576 4.62775 15.8056C3.90535 17.4842 3.68174 19.3399 3.98431 21.1453C4.0202 21.3605 4.06751 21.5736 4.12605 21.7837C5.56126 23.0498 7.27369 23.9507 9.12125 24.4118C10.9688 24.8729 12.8983 24.8809 14.7495 24.4351C16.6007 23.9892 18.3203 23.1024 19.7656 21.8483C21.211 20.5941 22.3404 19.0088 23.0601 17.2237C23.1093 16.8097 23.1017 16.3908 23.0376 15.9789C22.8277 14.7705 22.1152 13.7909 20.9004 13.04C20.2449 12.6752 18.9648 12.2549 17.06 11.7791C15.1552 11.3034 13.8616 10.9166 13.1791 10.6186C11.6793 9.92096 10.8251 8.94057 10.6166 7.67746C10.4382 6.57699 10.8679 5.40052 11.9058 4.14805C12.9437 2.89558 14.4053 1.95167 16.2906 1.31631C13.8691 0.369176 11.2094 0.245143 8.71232 0.962898C6.21521 1.68065 4.01588 3.20132 2.44563 5.29583C0.875383 7.39034 0.0192685 9.94524 0.00625412 12.5757C-0.00676023 15.2061 0.824031 17.7696 2.37347 19.8799C2.43353 18.811 2.67478 17.7605 3.08665 16.7746Z"
        fill="#EC1E24"
      />
      <path
        d="M38.8782 21.7656C36.5175 21.7656 34.6951 21.2617 33.4113 20.2539C32.1274 19.2462 31.4525 17.809 31.3865 15.9425H34.8286C34.9561 17.0065 35.3311 17.7665 35.9535 18.2225C36.576 18.6785 37.5051 18.9065 38.741 18.9065C39.1722 18.9085 39.6025 18.8673 40.0256 18.7833C40.4006 18.7126 40.7615 18.5801 41.0943 18.3912C41.391 18.2197 41.6438 17.98 41.8322 17.6912C42.0253 17.3789 42.1224 17.0154 42.1111 16.647C42.1258 16.2703 42.0192 15.899 41.8074 15.5891C41.5825 15.2802 41.2868 15.0313 40.9458 14.8641C40.5129 14.6552 40.0608 14.4903 39.5959 14.3716C39.068 14.2439 38.4733 14.1041 37.8118 13.9521C37.0364 13.7712 36.2865 13.5705 35.5621 13.3502C34.8893 13.1517 34.251 12.8487 33.67 12.4518C33.1299 12.0772 32.682 11.5819 32.3606 11.004C32.0045 10.3065 31.8332 9.52712 31.8634 8.74231C31.8472 7.95299 32.0177 7.1712 32.3606 6.46232C32.6882 5.80936 33.1629 5.24383 33.7465 4.81162C34.3784 4.34912 35.0904 4.01113 35.8455 3.81527C36.7159 3.58654 37.6124 3.47538 38.5115 3.48467C40.4613 3.48467 42.0362 3.9513 43.236 4.88458C44.4359 5.81785 45.0951 7.17748 45.2136 8.96346H41.8457C41.8226 8.57254 41.715 8.19154 41.5303 7.84754C41.3456 7.50354 41.0884 7.20497 40.777 6.97304C40.1059 6.51279 39.3079 6.28046 38.498 6.30957C37.5441 6.30957 36.7844 6.49804 36.219 6.875C35.9599 7.02684 35.7451 7.24533 35.5961 7.50838C35.4472 7.77142 35.3695 8.06968 35.3708 8.37295C35.3572 8.68828 35.4354 9.0007 35.5958 9.27126C35.7783 9.53378 36.0196 9.74867 36.3 9.89826C36.6724 10.0983 37.0675 10.2514 37.4766 10.3543C37.9446 10.4865 38.5018 10.6263 39.1482 10.7738C40.0361 10.9698 40.873 11.1864 41.6589 11.4236C42.3875 11.636 43.0817 11.9547 43.7197 12.3698C44.2963 12.7496 44.7747 13.2641 45.1146 13.87C45.4792 14.5902 45.6543 15.3936 45.623 16.2024C45.6366 17.022 45.4625 17.8336 45.1146 18.5736C44.7855 19.2645 44.3017 19.868 43.7017 20.336C43.0629 20.8238 42.3395 21.1857 41.569 21.403C40.6939 21.6541 39.7876 21.7762 38.8782 21.7656Z"
        fill="#EC1E24"
      />
      <path
        d="M53.3533 21.7404C52.4467 21.7533 51.5471 21.5779 50.7099 21.2251C49.9412 20.8991 49.2458 20.4184 48.6658 19.8121C48.0857 19.2059 47.6331 18.4866 47.3352 17.698C46.7024 16.0088 46.7024 14.1432 47.3352 12.454C47.6348 11.6661 48.0877 10.9473 48.6671 10.3405C49.2497 9.74005 49.944 9.26268 50.7099 8.93601C52.407 8.24887 54.2997 8.24887 55.9968 8.93601C56.7649 9.26524 57.4607 9.74572 58.0441 10.3496C58.6242 10.9563 59.0779 11.6751 59.3782 12.4631C60.011 14.1523 60.011 16.0179 59.3782 17.7071C59.0795 18.4952 58.6265 19.214 58.0466 19.8201C57.4666 20.4263 56.7717 20.9073 56.0036 21.2342C55.1635 21.5846 54.2616 21.7569 53.3533 21.7404ZM53.3533 19.1845C54.3702 19.1845 55.1502 18.8045 55.6931 18.0445C56.2361 17.2845 56.5068 16.2966 56.5053 15.0806C56.5053 13.8509 56.2346 12.8553 55.6931 12.0938C55.1517 11.3323 54.3717 10.9523 53.3533 10.9538C52.3349 10.9538 51.555 11.3338 51.0136 12.0938C50.4721 12.8538 50.2014 13.8532 50.2014 15.092C50.2014 16.3186 50.4721 17.3066 51.0136 18.0559C51.555 18.8053 52.3349 19.1853 53.3533 19.1959V19.1845Z"
        fill="#EC1E24"
      />
      <path
        d="M61.4187 3.80371H64.7146V21.3732H61.4187V3.80371Z"
        fill="#EC1E24"
      />
      <path
        d="M66.906 3.80371H70.2042V6.80189H66.906V3.80371ZM66.906 8.76723H70.2042V21.3732H66.906V8.76723Z"
        fill="#EC1E24"
      />
      <path
        d="M76.9917 21.7403C76.256 21.7528 75.5269 21.5969 74.8589 21.2843C74.2143 20.9747 73.6468 20.522 73.1986 19.9597C72.7108 19.3343 72.3444 18.6211 72.1187 17.8575C71.8503 16.9613 71.7198 16.0284 71.7317 15.0919C71.7214 14.1757 71.8519 13.2634 72.1187 12.3878C72.3498 11.6222 72.7249 10.9092 73.2233 10.288C73.6831 9.71619 74.2574 9.2499 74.9084 8.91999C75.571 8.58771 76.3019 8.41896 77.0412 8.42751C77.7936 8.39678 78.5393 8.58254 79.1919 8.96331C79.7315 9.30125 80.1921 9.75402 80.5418 10.2903H80.6138V3.80371H83.912V21.3732H80.751V19.7248H80.7015C80.3182 20.3669 79.7716 20.8928 79.119 21.2474C78.4663 21.602 77.7315 21.7722 76.9917 21.7403ZM77.7206 18.9405C78.706 18.9405 79.4492 18.6 79.9501 17.9191C80.4511 17.2381 80.7015 16.317 80.7015 15.1557C80.7072 14.6029 80.6544 14.051 80.5441 13.5096C80.4573 13.0602 80.2887 12.631 80.0469 12.2442C79.8277 11.8966 79.523 11.613 79.1627 11.4211C78.7437 11.2112 78.28 11.1094 77.8128 11.1247C77.4047 11.1053 76.9989 11.1977 76.6382 11.3923C76.2775 11.5869 75.9752 11.8765 75.7633 12.2305C75.3133 12.9692 75.0726 13.9177 75.0726 15.0828C75.0726 16.3094 75.2976 17.2594 75.7475 17.9328C75.962 18.2597 76.2566 18.5245 76.6025 18.7011C76.9484 18.8778 77.3337 18.9603 77.7206 18.9405Z"
        fill="#EC1E24"
      />
      <path
        d="M93.0438 21.7656C90.683 21.7656 88.8607 21.2617 87.5768 20.2539C86.2929 19.2462 85.618 17.809 85.552 15.9425H88.9942C89.1246 17.0065 89.4996 17.7665 90.119 18.2225C90.7385 18.6785 91.6676 18.9065 92.9065 18.9065C93.3377 18.9086 93.768 18.8673 94.1911 18.7834C94.5661 18.7126 94.9271 18.5801 95.2598 18.3912C95.5565 18.2198 95.8093 17.98 95.9977 17.6912C96.1908 17.3789 96.2879 17.0154 96.2767 16.647C96.2911 16.2707 96.1853 15.8997 95.9752 15.5891C95.7469 15.2798 95.4482 15.031 95.1045 14.8641C94.6718 14.6549 94.2197 14.4899 93.7547 14.3716C93.2312 14.2439 92.6388 14.1041 91.9774 13.9521C91.2004 13.7712 90.4505 13.5706 89.7276 13.3502C89.0548 13.1518 88.4165 12.8487 87.8355 12.4518C87.2958 12.0768 86.8479 11.5815 86.5262 11.0041C86.17 10.3065 85.9987 9.52713 86.029 8.74231C86.0127 7.95299 86.1832 7.17121 86.5262 6.46233C86.8531 5.81093 87.3272 5.24693 87.9098 4.81618C88.5406 4.35328 89.252 4.01524 90.0066 3.81983C90.8826 3.58797 91.7852 3.47526 92.6905 3.48468C94.6478 3.48468 96.2227 3.95131 97.4151 4.88458C98.6074 5.81786 99.2659 7.17749 99.3904 8.96347H96.0157C95.992 8.57195 95.8834 8.19054 95.6975 7.84648C95.5116 7.50242 95.253 7.20418 94.9403 6.97305C94.2687 6.5138 93.471 6.28157 92.6613 6.30957C91.7074 6.30957 90.9477 6.49805 90.3823 6.87501C90.1226 7.02637 89.9072 7.24471 89.7578 7.50781C89.6085 7.77092 89.5305 8.06941 89.5319 8.37296C89.5175 8.68835 89.5958 9.00098 89.7568 9.27127C89.9378 9.53428 90.1786 9.74933 90.4588 9.89826C90.8312 10.0983 91.2263 10.2514 91.6354 10.3543C92.1033 10.4865 92.6613 10.6263 93.3092 10.7738C94.1971 10.9699 95.0333 11.1865 95.8177 11.4236C96.5463 11.636 97.2405 11.9547 97.8785 12.3698C98.4551 12.7496 98.9334 13.2641 99.2734 13.87C99.639 14.5898 99.8142 15.3935 99.7818 16.2024C99.7964 17.0221 99.6223 17.834 99.2734 18.5736C98.945 19.2637 98.4628 19.867 97.865 20.336C97.2251 20.8234 96.501 21.1852 95.73 21.4031C94.8563 21.6537 93.9517 21.7757 93.0438 21.7656Z"
        fill="#EC1E24"
      />
      <path
        d="M101.627 8.76736H104.803V10.3405H104.875C105.278 9.78863 105.785 9.32357 106.367 8.97255C107.028 8.59384 107.779 8.40689 108.538 8.4322C109.309 8.42046 110.072 8.58899 110.768 8.92467C111.428 9.25031 112.011 9.71699 112.477 10.2927C112.974 10.913 113.345 11.6266 113.569 12.3925C113.826 13.2682 113.953 14.1782 113.944 15.092C113.955 16.0277 113.824 16.9596 113.557 17.8554C113.33 18.6231 112.954 19.3376 112.453 19.9575C111.987 20.522 111.403 20.9745 110.743 21.2822C110.057 21.5951 109.312 21.7508 108.561 21.7382C107.804 21.7648 107.053 21.5961 106.378 21.248C105.813 20.9349 105.331 20.4883 104.972 19.9461H104.925V25.4979H101.627V8.76736ZM107.859 18.968C108.247 18.9808 108.632 18.8968 108.981 18.7235C109.33 18.5502 109.631 18.2928 109.859 17.9739C110.352 17.3097 110.599 16.3802 110.599 15.1855C110.599 13.9908 110.374 13.0157 109.924 12.2603C109.474 11.5003 108.743 11.1203 107.73 11.1203C107.271 11.1075 106.817 11.2193 106.414 11.4441C106.051 11.6541 105.743 11.9487 105.514 12.3036C105.266 12.6975 105.094 13.1354 105.006 13.5941C104.898 14.1189 104.845 14.6539 104.848 15.1901C104.848 16.3377 105.103 17.255 105.611 17.942C106.12 18.629 106.869 18.971 107.859 18.968Z"
        fill="#EC1E24"
      />
      <path
        d="M121.364 21.7403C120.457 21.7532 119.558 21.5778 118.721 21.225C117.952 20.899 117.257 20.4183 116.677 19.812C116.096 19.2058 115.644 18.4865 115.346 17.6979C114.713 16.0087 114.713 14.1431 115.346 12.4539C115.646 11.666 116.098 10.9472 116.678 10.3404C117.26 9.73995 117.955 9.26258 118.721 8.93591C120.417 8.2489 122.309 8.2489 124.005 8.93591C124.774 9.26449 125.471 9.74504 126.055 10.3495C126.635 10.9562 127.089 11.675 127.389 12.463C128.022 14.1522 128.022 16.0178 127.389 17.707C127.09 18.4953 126.638 19.2141 126.058 19.8203C125.478 20.4265 124.783 20.9074 124.014 21.2341C123.174 21.5851 122.272 21.7574 121.364 21.7403ZM121.364 19.1844C122.381 19.1844 123.161 18.8044 123.704 18.0444C124.247 17.2844 124.518 16.2965 124.516 15.0805C124.516 13.8508 124.245 12.8552 123.704 12.0937C123.162 11.3322 122.382 10.9522 121.364 10.9537C120.344 10.9537 119.564 11.3337 119.024 12.0937C118.484 12.8537 118.214 13.8531 118.212 15.0919C118.212 16.3185 118.483 17.3065 119.024 18.0558C119.566 18.8052 120.346 19.1852 121.364 19.1958V19.1844Z"
        fill="#EC1E24"
      />
      <path
        d="M132.566 10.7828H132.638C132.936 10.1559 133.367 9.60324 133.9 9.164C134.423 8.78537 135.057 8.59516 135.7 8.62364C135.962 8.61371 136.224 8.64681 136.476 8.72168V11.6423H136.404C135.309 11.5283 134.417 11.7662 133.729 12.356C133.04 12.9457 132.693 13.9033 132.687 15.2287V21.3847H129.389V8.76728H132.566V10.7828Z"
        fill="#EC1E24"
      />
      <path
        d="M137.292 8.76728H139.014V4.83203H142.24V8.76728H144.373V10.988H142.24V17.6615C142.24 18.138 142.352 18.464 142.579 18.6442C142.84 18.8364 143.158 18.9322 143.479 18.9155H144.112C144.224 18.9151 144.337 18.9067 144.449 18.8904V21.3482C144.289 21.3642 144.112 21.3733 143.916 21.3733C143.72 21.3733 143.52 21.3961 143.261 21.3961H142.388C141.985 21.3968 141.583 21.3517 141.189 21.2616C140.801 21.1763 140.434 21.0135 140.109 20.7828C139.776 20.5403 139.506 20.2195 139.322 19.848C139.105 19.389 139.001 18.8837 139.018 18.3751V10.988H137.297L137.292 8.76728Z"
        fill="#EC1E24"
      />
    </g>
    <path
      d="M150.424 21L155.564 6.7H159.204L164.364 21H160.644L159.804 18.42H154.804L153.984 21H150.424ZM155.624 15.88H158.984L157.904 12.42C157.664 11.68 157.344 10.4 157.344 10.4H157.304C157.304 10.4 156.964 11.68 156.724 12.42L155.624 15.88ZM169.642 21.32C166.342 21.32 164.202 18.98 164.202 15.9C164.202 12.82 166.322 10.48 169.562 10.48C172.342 10.48 174.122 12.1 174.422 14.4H171.282C171.082 13.48 170.522 12.88 169.602 12.88C168.202 12.88 167.502 14.04 167.502 15.9C167.502 17.72 168.202 18.88 169.602 18.88C170.622 18.88 171.242 18.28 171.382 17.12H174.502C174.422 19.48 172.542 21.32 169.642 21.32ZM180.521 21.32C177.221 21.32 175.081 18.98 175.081 15.9C175.081 12.82 177.201 10.48 180.441 10.48C183.221 10.48 185.001 12.1 185.301 14.4H182.161C181.961 13.48 181.401 12.88 180.481 12.88C179.081 12.88 178.381 14.04 178.381 15.9C178.381 17.72 179.081 18.88 180.481 18.88C181.501 18.88 182.121 18.28 182.261 17.12H185.381C185.301 19.48 183.421 21.32 180.521 21.32ZM191.42 19C192.84 19 193.6 17.76 193.6 15.9C193.6 14.04 192.84 12.78 191.42 12.78C190 12.78 189.26 14.04 189.26 15.9C189.26 17.76 190 19 191.42 19ZM191.44 21.32C188.16 21.32 185.96 18.98 185.96 15.9C185.96 12.82 188.16 10.48 191.44 10.48C194.74 10.48 196.9 12.82 196.9 15.9C196.9 18.98 194.74 21.32 191.44 21.32ZM201.522 21.3C199.162 21.3 197.922 19.76 197.922 17.48V10.76H201.162V16.8C201.162 17.92 201.662 18.54 202.702 18.54C203.822 18.54 204.502 17.7 204.502 16.48V10.76H207.762V21H204.642V19.74H204.582C203.842 20.74 202.942 21.3 201.522 21.3ZM209.33 21V10.76H212.47V12.16H212.53C213.29 11.06 214.27 10.46 215.69 10.46C217.93 10.46 219.25 12.06 219.25 14.34V21H215.99V15C215.99 13.92 215.45 13.18 214.39 13.18C213.31 13.18 212.59 14.08 212.59 15.34V21H209.33ZM224.601 21.12C222.701 21.12 221.341 20.5 221.341 18.3V12.8H219.981V10.76H221.341V7.52H224.521V10.76H226.381V12.8H224.521V17.6C224.521 18.4 224.961 18.6 225.661 18.6C225.941 18.6 226.261 18.58 226.381 18.58V20.96C226.101 21.04 225.481 21.12 224.601 21.12Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_11446_15761">
        <rect
          width="144.444"
          height="25"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
