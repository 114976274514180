import React from 'react'

export const CheckmarkIcon = () => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5927 0.918579L6.18531 12.326L1.00012 7.1408"
      stroke="white"
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
