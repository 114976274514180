import React from 'react'

export const ShieldIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75006 6.53842V19.2284C3.75009 23.2395 4.96559 27.1564 7.23632 30.4628C9.50705 33.7693 12.7264 36.31 16.4701 37.7501L18.2051 38.4168C19.3605 38.8612 20.6396 38.8612 21.7951 38.4168L23.5301 37.7501C27.2737 36.31 30.4931 33.7693 32.7638 30.4628C35.0345 27.1564 36.25 23.2395 36.2501 19.2284V6.53842C36.2532 6.0609 36.1184 5.59261 35.8618 5.1899C35.6051 4.78719 35.2376 4.4672 34.8034 4.26842C30.1364 2.23141 25.092 1.20286 20.0001 1.25009C14.9081 1.20286 9.86366 2.23141 5.19672 4.26842C4.76252 4.4672 4.39499 4.78719 4.13834 5.1899C3.8817 5.59261 3.74686 6.0609 3.75006 6.53842V6.53842Z"
      fill="white"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
