import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import RoundIconButton, { Scheme } from '../../buttons/RoundIconButton'
import styles from './index.module.scss'

interface Props {
  leftPadding: boolean
  getEndOfContentRef?: null | ((endOfContent: any) => void)
  showArrows?: boolean
  children?: React.ReactNode
  getIsScrolling?: (isScrolling: boolean) => void
}
const ContentSlider: FC<Props> = ({
  leftPadding,
  getEndOfContentRef,
  showArrows = true,
  children,
  getIsScrolling
}) => {
  const contentSlider = useRef<any>(null)
  const endOfContent = useRef<any>(null)
  const [scrollX, setScrollX] = useState(0)
  const [endReached, setEndReached] = useState(false)

  const currentSliderscrollWidth = contentSlider?.current?.scrollWidth
  const currentSliderScrollOffsetWidth = contentSlider?.current?.offsetWidth

  const scrollContent = (direction: string) => {
    let scrollAmount = 0

    const slideTimer = setInterval(() => {
      if (contentSlider && contentSlider.current) {
        if (direction === 'left') {
          setScrollX((prev) => prev + 102)
          contentSlider.current.scrollLeft -= 102
        } else {
          setScrollX((prev) => prev - 102)
          contentSlider.current.scrollLeft += 102
        }
        scrollAmount += 102

        const scrollWidth = leftPadding
          ? contentSlider.current.scrollWidth - 110
          : contentSlider.current.scrollWidth
        if (
          Math.floor(scrollWidth - contentSlider.current.scrollLeft) <=
          contentSlider.current.offsetWidth
        ) {
          setEndReached(true)
        } else {
          setEndReached(false)
        }
        if (scrollAmount >= 500) {
          window.clearInterval(slideTimer)
        }
      }
    }, 25)
  }

  const scrollCheck = () => {
    if (contentSlider && contentSlider.current) {
      setScrollX(contentSlider.current.scrollLeft)
      const scrollWidth = leftPadding
        ? contentSlider.current.scrollWidth - 110
        : contentSlider.current.scrollWidth
      if (
        Math.floor(scrollWidth - contentSlider?.current?.scrollLeft) <=
        contentSlider?.current?.offsetWidth
      ) {
        setEndReached(true)
      } else {
        setEndReached(false)
      }
    }
  }

  useEffect(() => {
    if (getEndOfContentRef) {
      getEndOfContentRef(endOfContent)
    }
  }, [])

  useEffect(() => {
    // Check width of the scollings
    if (
      contentSlider.current &&
      contentSlider?.current?.scrollWidth ===
        contentSlider?.current?.offsetWidth
    ) {
      setEndReached(true)
    } else {
      setEndReached(false)
    }
    return () => {}
  }, [currentSliderscrollWidth, currentSliderScrollOffsetWidth])

  return (
    <div className={styles.RelativeContentSliderParent}>
      <div
        ref={contentSlider}
        data-testid="content-slider"
        className={classNames(styles.ListContainer, {
          [styles.LeftPadding]: leftPadding
        })}
        onScroll={() => {
          scrollCheck
          getIsScrolling && getIsScrolling(true)
        }}
      >
        {showArrows && scrollX !== 0 && (
          <div className={classNames(styles.arrowContainer, styles.arrowLeft)}>
            <RoundIconButton
              iconSize="medium"
              newClassName={Scheme.Light}
              onClick={() => {
                scrollContent('left')
              }}
              arrowDirection="left"
            />
          </div>
        )}
        {children}
        <div ref={endOfContent} />
        {showArrows && !endReached && (
          <div className={classNames(styles.arrowContainer, styles.arrowRight)}>
            <RoundIconButton
              iconSize="medium"
              newClassName={Scheme.Light}
              onClick={() => {
                scrollContent('right')
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ContentSlider.defaultProps = {
  getEndOfContentRef: null,
  showArrows: true
}

export default ContentSlider
