import { BroadcastThumbnailInterface } from '../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import { getIsStreamLive } from '../../helpers/getIsStreamLive'
import { Company } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'

export const getUpcomingBroadcastsForChannel = async ({
  slug,
  page,
  perPageCount,
  channel
}: {
  slug: string
  page: number
  perPageCount: number
  channel: Company
}) => {
  // https://solidsport.com/api/play_v1/timeline_objects/collections/livestreams?company={slug}}&page=1&per_page=12$include_games_and_livestream=true
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/livestreams',
      params: {
        company: slug,
        page: page,
        per_page: perPageCount,
        include_games_and_livestreams: true
      }
    })
    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data, channel)
    const now = Date.now() / 1000

    return {
      data: mapped.filter(
        (item: BroadcastThumbnailInterface) =>
          !getIsStreamLive(item.startTime, item.endTime, now)
      ),
      totalCount: responseData.totalCount
    }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
