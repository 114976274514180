import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const postFontsForChannel = async (
  channelSlug: string,
  formData: any
) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `session/companies/${channelSlug}/font`,
      method: ApiEndpoint.METHODS.POST,
      file: formData
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    console.log(error)
    throw new Error('post fonts')
  }
}
