import React, { FC } from 'react'
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

interface Props {
  selectedTimezone: string
  onSelectedTimezone: (zone: string) => void
}

export const timezones = {
  ...allTimezones,
  'Africa/Casablanca': 'Casablanca',
  'Africa/Monrovia': 'Monrovia',
  'America/Bogota': 'Bogota',
  'America/Lima': 'Lima',
  'America/Caracas': 'Caracas',
  'America/La_Paz': 'La Paz',
  'America/Dawson': 'Dawson',
  'Europe/Amsterdam': 'Amsterdam',
  'Europe/Berlin': 'Berlin',
  'Europe/Rome': 'Rome',
  'Europe/Stockholm': 'Stockholm',
  'Europe/Vienna': 'Vienna',
  'Europe/Brussels': 'Brussels',
  'Europe/Copenhagen': 'Copenhagen',
  'Europe/Madrid': 'Madrid',
  'Europe/Paris': 'Paris',
  'Europe/Helsinki': 'Helsinki',
  'Europe/Kiev': 'Kiev',
  'Europe/Riga': 'Riga',
  'Europe/Sofia': 'Sofia',
  'Europe/Tallinn': 'Tallinn',
  'Europe/Vilnius': 'Vilnius'
}

export const TimezoneSelector: FC<Props> = ({
  selectedTimezone,
  onSelectedTimezone
}) => (
  <div className="select-wrapper">
    <TimezoneSelect
      value={selectedTimezone}
      onChange={(event: any) => {
        onSelectedTimezone(event.value)
      }}
      timezones={timezones}
    />
  </div>
)
