/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { t } from '@lingui/macro'
import { Subtitles } from '@mui/icons-material'
import { Icon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './index.scss'

// /**
//  * @typedef {object} SubtitlePicker'
// */
type SubtitlePickerProps = {
  videoElement: HTMLVideoElement
}

// /**
//  * SubtitlePicker
//  * @desc quality picker for video player
//  * @category video components controls
//  *
//  * @example
//  * return (
//  *   <SubtitlePicker videoElement={HTMLVideoElement} hls={Hls} />
//  * )
//  */

type Track = {
  language: string
  label: string
  active: boolean
}

const SubtitlePicker: React.FC<SubtitlePickerProps> = ({ videoElement }) => {
  const [open, setOpen] = useState(false)
  const [hasTracks, setHasTracks] = useState(false)
  const [currentTrack, setCurrentTrack] = useState()

  useEffect(() => {
    const onMetadata = () => {
      setHasTracks(true)
    }
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', onMetadata)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', onMetadata)
      }
    }
  }, [videoElement])

  useEffect(() => {}, [hasTracks, currentTrack])

  const getTracks = (): Track[] => {
    const tracks: Track[] = []
    let foundActiveTrack = false
    if (hasTracks) {
      for (let i: number = 0; i < videoElement.textTracks.length; i += 1) {
        const track: any = videoElement.textTracks[i]
        // activeCues: null
        // cues: null
        // id: ""
        // kind: "subtitles"
        // label: "Svenska"
        // language: "sv"
        // mode: "disabled"
        // oncuechange: null
        foundActiveTrack = track.mode === 'showing'
        tracks.push({
          language: track.language,
          label: track.label,
          active: foundActiveTrack
        })
      }
      tracks.push({
        language: 'none',
        label: t`No subtitles`,
        active: !foundActiveTrack
      })
    }
    return tracks
  }

  const selectTrack = (nextTrack: string) => {
    if (videoElement.textTracks) {
      for (let i: number = 0; i < videoElement.textTracks.length; i += 1) {
        const track: any = videoElement.textTracks[i]
        if (track.language === nextTrack) {
          track.mode = 'showing'
          setCurrentTrack(track.language)
        } else {
          track.mode = 'disabled'
        }
      }
    }
    setOpen(false)
  }

  const getSubtitleClassName = (track: Track): string => {
    if (track.active) {
      return 'Subtitle current'
    }
    return 'Subtitle'
  }

  return (
    <>
      {getTracks() && getTracks().length > 0 && (
        <span className="SubtitlePicker">
          {open && (
            <div className="Subtitles">
              {getTracks().map((track: Track) => (
                <div
                  className={getSubtitleClassName(track)}
                  key={track.language}
                  onClick={() => selectTrack(track.language)}
                >
                  {track.label}
                </div>
              ))}
            </div>
          )}
          <button
            type="button"
            className="SubtitlePickerButton PlayerButton"
            onClick={() => setOpen(!open)}
          >
            <Icon component={Subtitles} className="SubtitlePickerIcon" />
          </button>
        </span>
      )}
    </>
  )
}

export default SubtitlePicker
