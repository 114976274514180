import React, { FC } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './AsideMediaSection.module.scss'
import TitleTextButton from './TitleTextButton/TitleTextButton'

import { AsideMediaSection } from './AsideMediaSection.interface'

interface Props {
  sectionData: AsideMediaSection
}

const AsideMediaSectionComponent: FC<Props> = ({ sectionData }) => {
  const buttonClick = () => {
    return <Link to={`/${sectionData.link}`} />
  }

  return (
    <div
      className={classNames(
        styles.AsideMediaSectionContainer,
        styles[sectionData.backgroundColor]
      )}
      data-testid="aside-media-section-container"
    >
      <div
        className={styles.MediaContainer}
        data-testid="aside-media-section-media-container"
      >
        {sectionData.imagePath && (
          <img
            className={styles.Media}
            src={sectionData.imagePath}
            alt={t` ${sectionData.sectionTitle}`}
            data-testid="aside-media-section-media"
          />
        )}
        {sectionData.videoPath && !sectionData.imagePath && (
          <video
            className={classNames(styles.Media, styles.Video)}
            src={sectionData.videoPath}
            data-testid="aside-media-section-media"
            controls
          />
        )}
      </div>
      <div
        className={styles.TextButtonContainer}
        data-testid="aside-media-section-container-text"
      >
        <TitleTextButton
          key={sectionData.id}
          title={sectionData.sectionTitle}
          text={sectionData.text}
          buttonLabel={sectionData.buttonLabel}
          buttonFunction={buttonClick}
        />
      </div>
    </div>
  )
}
export default AsideMediaSectionComponent
