import React from 'react'
import { MyAccountIcon } from 'assets/icons/MyAccount'
import { MyPurchasesIcon } from 'assets/icons/MyPurchasesIcon'
import { SidebarMenuLink } from 'components/SidebarNavigation/MenuItem/MenuItem'
import { LinkType } from 'components/page/menuLink/MenuLink'
import { t } from '@lingui/macro'
import { ScarfIcon } from 'pages/ChannelPage/Purchase/PurchaseOptions/Icons/ScarfIcon'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`

export const MenuLinks: SidebarMenuLink[] = [
  {
    translationText: t`My profile`,
    link: `${baseUrl}/user/profile/home`,
    type: LinkType.AnchorTag,
    linkTarget: LinkTarget.NewWindow,
    icon: <MyAccountIcon />
  },
  {
    translationText: t`My purchases`,
    link: `${baseUrl}/user/profile/purchases/allpurchases`,
    type: LinkType.AnchorTag,
    linkTarget: LinkTarget.NewWindow,
    icon: <MyPurchasesIcon />
  },
  {
    translationText: t`Following`,
    link: `${baseUrl}/user/profile/account/following`,
    type: LinkType.AnchorTag,
    linkTarget: LinkTarget.NewWindow,
    icon: <ScarfIcon />
  }
]
