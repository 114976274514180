import React, { FC, useCallback, useMemo, useState } from 'react'
import { getDefaultSettings } from '../../../../api/channel/settings/getDefaultSettings'
import { putActivateLivestream } from '../../../../api/stream/putActivateLivestream'
import { useDataApi } from '../../../../api/useDataApi'
import {
  getMediaObject,
  MediaObjectParams
} from '../../../../api/viewer/getMediaObject'
import { Game } from '../../../../interfaces'
import { ActivateLivestreamModal } from './ActivateLivestreamModal/ActivateLivestreamModal'

interface ActivateLivestreamProps {
  channelSlug: string
  id: string
  onClose: () => void
}

export const ActivateLivestream: FC<ActivateLivestreamProps> = ({
  channelSlug,
  id,
  onClose
}) => {
  const [isActivating, setIsActivating] = useState(false)
  const [isSuccessfullyActivated, setIsSuccessfullyActivated] = useState(false)
  const [hasActivationValidationError, setHasActivationValidationError] =
    useState(false)

  const [isErrorActivating, setIsErrorActivating] = useState(false)
  const params: MediaObjectParams = useMemo(
    () => ({
      game_ident: id,
      company: channelSlug
    }),
    [id, channelSlug]
  )

  const settingsResponse = useDataApi({
    apiCall: useCallback(getDefaultSettings, []),
    parameters: channelSlug
  })

  const mediaObjectResponse = useDataApi({
    apiCall: useCallback(getMediaObject, []),
    parameters: params
  })

  const gameOrMediaObject: any = mediaObjectResponse.data
  const isAlreadyActivated = !!gameOrMediaObject?.game
  const game: Game = isAlreadyActivated
    ? gameOrMediaObject.game
    : gameOrMediaObject

  const handleActivateLivestream = async () => {
    setIsActivating(true)
    try {
      const activationResponse = await putActivateLivestream({
        channelSlug,
        mediaObjectIdent: id
      })
      if (activationResponse.hasError) {
        setHasActivationValidationError(true)
      }
      setIsSuccessfullyActivated(true)
    } catch {
      setIsErrorActivating(true)
    } finally {
      setIsActivating(false)
    }
  }

  const defaultPrices =
    settingsResponse.data?.defaultLivestreamPrices || undefined
  const isError =
    isErrorActivating || settingsResponse.isError || mediaObjectResponse.isError

  const isLoading =
    isActivating || settingsResponse.isLoading || mediaObjectResponse.isLoading

  return (
    <ActivateLivestreamModal
      isError={isError}
      isLoading={isLoading}
      hasActivationValidationError={hasActivationValidationError}
      defaultPrices={defaultPrices}
      isAlreadyActivated={isAlreadyActivated}
      isSuccessfullyActivated={isSuccessfullyActivated}
      onActivateLivestream={handleActivateLivestream}
      numberOfStreams={1}
      game={game}
      onClose={onClose}
    />
  )
}
