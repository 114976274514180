import React, { FC } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { forgotPasswordFormValidationSchema } from './ForgotPasswordFormValidationSchema'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './ForgotPasswordForm.module.scss'

interface ForgotPasswordFormValues {
  userEmail: string
}
interface Props {
  onSubmitFormSuccess: () => void
}
export const ForgotPasswordForm: FC<Props> = ({ onSubmitFormSuccess }) => {
  const dispatch = useDispatch()
  const initialValues: ForgotPasswordFormValues = {
    userEmail: ''
  }
  const onSubmit = async (
    values: ForgotPasswordFormValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    const submitValues = { ...values }
    formikHelpers.setSubmitting(true)
    dispatch(
      authenticationActions.sendEmail(submitValues.userEmail.toLowerCase())
    )
    onSubmitFormSuccess()
    formikHelpers.setSubmitting(false)
  }

  return (
    <div className={styles.ForgotPasswordFormContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordFormValidationSchema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <Form>
              <div className={styles.Form}>
                <Field
                  component={TextInput}
                  id="userEmail"
                  data-testid="email-input"
                  name="userEmail"
                  placeholder={t`Email`}
                  onChange={formik.handleChange}
                  textValue={formik.values.userEmail}
                  isValid={!formik.errors.userEmail}
                  validationMessage={formik.errors.userEmail}
                />
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SendButton}
                  disabled={!formik.dirty || formik.isSubmitting}
                  label={t`Send `}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
