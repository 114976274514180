import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { GeneralSection } from './bottomSection/GeneralSection'
import { IconRow } from './topSection/IconRow'
import { ContactInfo } from './topSection/ContactInfo'

import styles from './ThemedFooter.module.scss'
import { channelAboutSelector } from 'redux-store/channelAbout/channelAbout.selectors'

export interface RootChannelContact {
  address: string
  phone: string
  email: string
}
export interface RootChannelSocialMedia {
  facebook?: string
  twitter?: string
  instagram?: string
}
export interface Locale {
  abbr: string
  name: string
}
interface Props {
  languages: Locale[]
  socialMedia?: RootChannelSocialMedia
  companyName: string
}

export const ThemedFooter: FC<Props> = ({ languages, companyName }) => {
  const channelState = useSelector(channelSelector)
  const channelInfo = useSelector(channelAboutSelector)
  const channel = channelState?.data

  if (!channel || !channelInfo) {
    return null
  }

  const socialMedia = channelInfo &&
    channelInfo.socialMedia && {
      facebook: channelInfo.socialMedia.facebook,
      twitter: channelInfo.socialMedia.twitter,
      instagram: channelInfo.socialMedia.instagram
    }

  const contact = channelInfo?.contact
  const hasSocialMedia: boolean = !!(
    (socialMedia?.facebook && socialMedia.facebook !== '') ||
    (socialMedia?.twitter && socialMedia.twitter !== '') ||
    (socialMedia?.instagram && socialMedia.instagram !== '')
  )
  const logo = channel?.parentLogoUrl

  const rootChannelSubdomain = channel?.parentChannelSubdomain

  return (
    <div className={styles.ThemedFooterContainer}>
      <div className={styles.ParentChannelContainer}>
        <IconRow
          logoPath={logo}
          channelName={channel?.name}
          rootChannelSubdomain={rootChannelSubdomain}
          hasSocialMedia={hasSocialMedia}
          socialMedia={socialMedia}
        />
        {contact && <ContactInfo contactInfo={contact} />}
      </div>
      <div className={styles.SectionDivider} />
      <div className={styles.GeneralSectionContainer}>
        <GeneralSection companyName={companyName} languages={languages} />
      </div>
    </div>
  )
}
