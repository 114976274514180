import ApiEndpoint from 'network/ApiEndpoint'
interface Response {
  hasError: boolean
  errors?: any
}
export const deleteHeroImageCallToAction = async (
  channelSlug: string
): Promise<Response> => {
  try {
    const response = await ApiEndpoint.call({
      path: `channels/${channelSlug}/call_to_action/hero_image`,
      method: ApiEndpoint.METHODS.DELETE,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
