import React, { FC, useState } from 'react'

import { Trans, t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import {
  CustomLinkForm,
  CustomLinkFormFields
} from 'components/Forms/CustomLinkFormFields/CustomLinkFormFields'
import { putCustomTopNavigationLink } from 'api/channel/settings/putCustomTopNavigationLink'
import {
  StatusMessage,
  StatusMessageType
} from 'pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { topNavigationLinkSchema } from './TopNavigationLinksFormSchema'
import styles from './TopNavigationLinkForm.module.scss'

interface Props {
  channelSlug: string
  existingForm?: CustomLinkForm
  onSubmitSuccess: () => void
}
export const TopNavigationLinkForm: FC<Props> = ({
  channelSlug,
  existingForm,
  onSubmitSuccess
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const initialValues: CustomLinkForm = {
    textField: existingForm?.textField || '',
    linkField: existingForm?.linkField || '',
    target: existingForm?.target || LinkTarget.CurrentWindow
  }

  const onSubmit = async (
    values: CustomLinkForm,
    formikHelpers: FormikHelpers<CustomLinkForm>
  ) => {
    formikHelpers.setSubmitting(true)

    const response = await putCustomTopNavigationLink({
      customLinkText: values.textField,
      customLinkHref: values.linkField,
      customLinkTarget: values.target,
      channelSlug
    })
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
      formikHelpers.setSubmitting(false)
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
      onSubmitSuccess()
    }
  }

  return (
    <div className={styles.Container}>
      <h5>
        <Trans>Top navigation link</Trans>
      </h5>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={topNavigationLinkSchema}
          validateOnBlur
          validateOnChange
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(props: FormikProps<CustomLinkForm>) => (
            <Form>
              <CustomLinkFormFields
                textFieldLabel={t`Label`}
                textFieldPlaceHolder={t`Write the link label here`}
                linkFieldLabel={t`Url`}
                formik={props}
                textFieldName={'textField'}
                linkFieldName={'linkField'}
                showAlwaysAsColumn={false}
              />
              <SubmitButton
                type="secondary"
                disabled={!props.dirty || props.isSubmitting}
                label={t`Save changes`}
              />
              {showSuccessMessage && (
                <StatusMessage
                  onClose={() => setShowSuccessMessage(false)}
                  message={t`the custom link was successfully updated`}
                  messageType={StatusMessageType.Success}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
