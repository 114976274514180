import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadCustomFont } from '../../../app/loadCustomFont'
import { ChannelFont } from '../../../redux-store/channelFonts/channelFonts.interface'

interface Props {
  channelName: string
  customFontTheme: string | undefined
  isFontLoaded: boolean
  customFont: ChannelFont | undefined
  setLoadedAction: () => void
  setLoadedFont: any
}

export const useThemeFont = ({
  channelName,
  customFontTheme,
  isFontLoaded,
  customFont,
  setLoadedAction,
  setLoadedFont
}: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true
    if (customFontTheme && !isFontLoaded && customFont) {
      loadCustomFont(customFont, setLoadedFont).then(() => {
        if (isMounted) {
          dispatch(setLoadedAction())
        }
      })
      return () => {
        isMounted = false
      }
    }
  }, [
    dispatch,
    customFontTheme,
    isFontLoaded,
    channelName,
    customFont,
    setLoadedAction,
    setLoadedFont
  ])

  return null
}
