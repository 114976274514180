import { t } from '@lingui/macro'
import { FeatureFlagsAvailable } from '../../../featureFlags/FeatureFlagsAvailable'
import { LinkType, MenuLink } from '../../../components/page/menuLink/MenuLink'

export interface SubMenuLink extends MenuLink {
  id: number
  hidden: boolean
  showInBreadcrumb: boolean
}

export enum SettingsMenuItemId {
  Channel = 1,
  Account = 2,
  Branding = 3,
  Invoice = 4
}

export const settingsSubmenuLinks: SubMenuLink[] = [
  {
    id: SettingsMenuItemId.Channel,
    translationText: t`Channel`,
    type: LinkType.Route,
    link: '/channel',
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: SettingsMenuItemId.Account,
    translationText: t`Account`,
    type: LinkType.Route,
    link: '/account',
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: SettingsMenuItemId.Invoice,
    translationText: t`Invoice`,
    type: LinkType.Route,
    link: '/invoice',
    hidden:
      localStorage.getItem(FeatureFlagsAvailable.ChannelOverviewSetting) !==
      'true',
    showInBreadcrumb: true
  },
  {
    id: SettingsMenuItemId.Branding,
    translationText: t`Branding`,
    type: LinkType.Route,
    link: '/branding',
    hidden: false,
    showInBreadcrumb: true
  }
]
