import { RolesState } from '../../../../redux-store/roles/roles.interface'
import { SubMenuLink } from '../../ChannelSettings/settingsSubmenuLinks'
import { ChannelTypes } from '../../channelType'

export const getFilteredContentManagerMenuLinks = (
  links: SubMenuLink[],
  context: RolesState,
  channelType: ChannelTypes,
  hasActiveIntegration: boolean
) => {
  return links.filter((link) => {
    const { isSuperadmin, isAdmin, isChannelAdmin } = context
    switch (link.link) {
      case '/competitions':
        return (
          [ChannelTypes.team, ChannelTypes.league].includes(channelType) &&
          (isAdmin || isChannelAdmin || isSuperadmin) &&
          hasActiveIntegration
        )
      case '/teams':
        return (
          channelType === ChannelTypes.club &&
          (isAdmin || isChannelAdmin || isSuperadmin) &&
          hasActiveIntegration
        )
      case '/customcontent':
        return isAdmin || isChannelAdmin || isSuperadmin
      default:
        return !link.hidden
    }
  })
}
