import React, { FC, MutableRefObject } from 'react'
import IconButtonRound, {
  RoundButtonType
} from '../../../../../components/buttons/IconButtonRound/IconButtonRound'
import { DropDownListItem } from '../DropdownList/DropdownList'
import CrossIcon from './CrossIcon'
import styles from './MobileSelectList.module.scss'
import MobileSelectListItem from './MobileSelectListItem/MobileSelectListItem'

interface Props {
  list: DropDownListItem[]
  selectedItemId: string
  reference: MutableRefObject<any>
  onItemSelected: (label: string, id: string) => void
  onCloseClick: () => void
}
const MobileSelectList: FC<Props> = ({
  list,
  selectedItemId,
  reference,
  onItemSelected,
  onCloseClick
}) => (
  <div className={styles.MobileSelectListContainer} ref={reference}>
    <div className={styles.ListContainer}>
      {list.map((item: DropDownListItem) => (
        <MobileSelectListItem
          key={item.id}
          label={item.label}
          id={item.id}
          selectedId={selectedItemId}
          onItemSelected={(label, id) => onItemSelected(label, id)}
        />
      ))}
    </div>
    <div className={styles.CloseIconContainer}>
      <IconButtonRound
        type={RoundButtonType.Light}
        buttonSize="Medium"
        onClick={onCloseClick}
      >
        <CrossIcon />
      </IconButtonRound>
    </div>
  </div>
)
export default MobileSelectList
