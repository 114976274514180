export interface UserInterface {
  menuOpen: boolean
  reportContentModalIsOpen: boolean
  broadcastInformationModalIsOpen: boolean
  quickEditModalIsOpen: boolean
  paymentModalIsOpen: boolean
  shareContentModalIsOpen: boolean
  isViewingStream: boolean
  viewDvrFromBeginning: boolean
}

export const uiConstants = {
  OPEN_MENU: 'OPEN_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
  OPEN_REPORT_CONTENT_DIALOG: 'OPEN_REPORT_CONTENT_DIALOG',
  CLOSE_REPORT_CONTENT_DIALOG: 'CLOSE_REPORT_CONTENT_DIALOG',
  OPEN_BROADCAST_INFROMATION_MODAL: 'OPEN_BROADCAST_INFROMATION_MODAL',
  CLOSE_BROADCAST_INFROMATION_MODAL: 'CLOSE_BROADCAST_INFROMATION_MODAL',
  CLOSE_QUICK_EDIT_DIALOG: 'CLOSE_QUICK_EDIT_DIALOG',
  OPEN_QUICK_EDIT_DIALOG: 'OPEN_QUICK_EDIT_DIALOG',
  OPEN_PAYMENT_DIALOG: 'OPEN_PAYMENT_DIALOG',
  CLOSE_PAYMENT_DIALOG: 'CLOSE_PAYMENT_DIALOG',
  OPEN_SHARE_CONTENT_MODAL: 'OPEN_SHARE_CONTENT_MODAL',
  CLOSE_SHARE_CONTENT_MODAL: 'CLOSE_SHARE_CONTENT_MODAL',
  OPEN_VIEW_STREAM: 'OPEN_VIEW_STREAM',
  CLOSE_VIEW_STREAM: 'CLOSE_VIEW_STREAM',
  VIEW_DVR_FROM_BEGINNING: 'VIEW_DVR_FROM_BEGINNING',
  VIEW_DVR_FROM_LIVE: 'VIEW_DVR_FROM_LIVE'
}

export const initialState = {
  menuOpen: false,
  reportContentModalIsOpen: false,
  broadcastInformationModalIsOpen: false,
  quickEditModalIsOpen: false,
  paymentModalIsOpen: false,
  shareContentModalIsOpen: false,
  isViewingStream: false,
  viewDvrFromBeginning: false
}

function userInterface(state = initialState, action: any) {
  switch (action.type) {
    case uiConstants.OPEN_MENU:
      return {
        ...state,
        menuOpen: true
      }
    case uiConstants.CLOSE_MENU:
      return {
        ...state,
        menuOpen: false
      }
    case uiConstants.OPEN_REPORT_CONTENT_DIALOG:
      return {
        ...state,
        reportContentModalIsOpen: true
      }
    case uiConstants.CLOSE_REPORT_CONTENT_DIALOG:
      return {
        ...state,
        reportContentModalIsOpen: false
      }
    case uiConstants.OPEN_BROADCAST_INFROMATION_MODAL:
      return {
        ...state,
        broadcastInformationModalIsOpen: true
      }
    case uiConstants.CLOSE_BROADCAST_INFROMATION_MODAL:
      return {
        ...state,
        broadcastInformationModalIsOpen: false
      }
    case uiConstants.OPEN_QUICK_EDIT_DIALOG:
      return {
        ...state,
        quickEditModalIsOpen: true
      }
    case uiConstants.CLOSE_QUICK_EDIT_DIALOG:
      return {
        ...state,
        quickEditModalIsOpen: false
      }
    case uiConstants.OPEN_PAYMENT_DIALOG:
      return {
        ...state,
        paymentModalIsOpen: true
      }
    case uiConstants.CLOSE_PAYMENT_DIALOG:
      return {
        ...state,
        paymentModalIsOpen: false
      }
    case uiConstants.OPEN_SHARE_CONTENT_MODAL:
      return {
        ...state,
        shareContentModalIsOpen: true
      }
    case uiConstants.CLOSE_SHARE_CONTENT_MODAL:
      return {
        ...state,
        shareContentModalIsOpen: false
      }
    case uiConstants.OPEN_VIEW_STREAM:
      return {
        ...state,
        isViewingStream: true
      }
    case uiConstants.CLOSE_VIEW_STREAM:
      return {
        ...state,
        isViewingStream: false
      }
    case uiConstants.VIEW_DVR_FROM_BEGINNING:
      return {
        ...state,
        viewDvrFromBeginning: true
      }
    case uiConstants.VIEW_DVR_FROM_LIVE:
      return {
        ...state,
        viewDvrFromBeginning: false
      }
    default:
      return state
  }
}

export default userInterface
