import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getClubsForDistrict = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/channels/district_clubs?page=${page}&per_page=${perPageCount}`
    })

    const responseData = await resultResponse
    return { data: responseData.data, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
