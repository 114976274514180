import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { TitleThumbnailSection } from '../../../components/content/TitleThumbnailSection/TitleThumbnailSection'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import styles from './index.module.scss'
import { BroadcastThumbnailInterface } from '../../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import ContentContainer from '../../../components/ContentContainer'
import { Loader } from '../../../components/Loader/Loader'

interface Props {
  latestVideoContent: BroadcastThumbnailInterface[]
  upcomingLivestreams: BroadcastThumbnailInterface[]
  isLoading: boolean
  isError: boolean
}
const VideoContentSection: FC<Props> = ({
  latestVideoContent,
  upcomingLivestreams,
  isLoading,
  isError
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const thumbnailSize =
    currentBreakpoint > Breakpoint.mobile ? 'large' : 'small'
  if (isLoading) {
    return (
      <div
        className={styles.VideoContentSection}
        data-testid="video-content-section-loading"
      >
        <Loader />
      </div>
    )
  }
  if (isError) {
    return (
      <div
        className={styles.VideoContentSection}
        data-testid="video-content-section-error"
      >
        Something went wrong
      </div>
    )
  }
  return (
    <div
      className={styles.VideoContentSection}
      data-testid="front-page-video-container"
    >
      <ContentContainer
        hasLightBackground
        hasTopBottomPadding
        hasLeftRightPadding={false}
      >
        <TitleThumbnailSection
          thumbnailContent={latestVideoContent}
          sectionTitle={t`Latest videos`}
          showAllLinkPath="/"
          linkLabel={t`All`}
          thumbnailsCardIsShared={false}
          size={thumbnailSize}
          isLoading={isLoading}
        />
      </ContentContainer>
      <ContentContainer
        hasLightBackground
        hasTopBottomPadding
        hasLeftRightPadding={false}
      >
        <TitleThumbnailSection
          thumbnailContent={upcomingLivestreams}
          sectionTitle={t`Upcoming livestreams`}
          showAllLinkPath="/"
          linkLabel={t`All`}
          thumbnailsCardIsShared={false}
          size={thumbnailSize}
          isLoading={isLoading}
        />
      </ContentContainer>
    </div>
  )
}

export default VideoContentSection
