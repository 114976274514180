import { Trans } from '@lingui/macro'
import React, { FC } from 'react'
import styles from '../AdminDetails.module.scss'

interface AddAdminProps {
  channelSlug: string
}

export const AddAdmin: FC<AddAdminProps> = ({ channelSlug }) => {
  const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  return (
    <div className={styles.AdminView} data-testid="add-admin-view">
      <a
        className={styles.LinkText}
        href={`${baseURL}/admin/settings/account/users?select_company=${channelSlug}`}
        data-testid="admin-show-all-link"
      >
        <Trans>Add Admin</Trans>
      </a>
    </div>
  )
}
