import * as yup from 'yup'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
export const generalStreamInfoValidationSchema = () => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
  return yup.object({
    title: yup.string().required(t`The title of the stream is required`),
    description: yup.string(),
    startDate: yup
      .date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value
        }

        const result = dayjs(originalValue, 'YYYY-MM-DD')
        return result
      })
      .typeError(t`Enter avalid start date`)
      .required(t`Start date is required`),
    startTime: yup
      .string()
      .matches(timeRegex, t`Must be in the format HH:mm`)
      .required(t`Start time is required`),
    endDate: yup
      .date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value
        }

        const result = dayjs(originalValue, 'YYYY-MM-DD')
        return result
      })
      .typeError(t`Enter avalid end date`)
      .required(t`Start date is required`)
      .min(yup.ref('startDate'), t`End date must be after the start date`),
    endTime: yup
      .string()
      .matches(timeRegex, t`Must be in the format HH:mm`)
      .required(t`End time is required`)
  })
}
