import React, { FC } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'

import { useTranslate } from '../../../../hooks/Translate'
import { Breadcrumb as CommonBreadCrumb } from 'components/Breadcrumb/Breadcrumb'
import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'
import { getPathList } from './getPathList'

const Breadcrumb: FC = () => {
  const location = useLocation()
  const channel = useSelector(channelSelector)
  const translate = useTranslate()

  const {
    parentChannelSubdomain,
    isRootChannel,
    name: channelName,
    subdomain
  } = channel.data || {}

  let linkList: BreadCrumbLink[] = getPathList(
    parentChannelSubdomain,
    subdomain,
    channelName,
    isRootChannel,
    location,
    translate
  )
  return <CommonBreadCrumb pathList={linkList} />
}
export default Breadcrumb
