import React, { FC, useState } from 'react'

import classNames from 'classnames'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link } from 'react-router-dom'
import ArticleDate from './ArticleDate'
import styles from './ArticleThumbnail.module.scss'
import { ArticleTitle } from './ArticleTitle/ArticleTitle'
import NewspaperFold from './Icons/NewspaperFold'

interface ArticleThumbnailProps {
  title: string
  channelSlug: string
  articleSlug: string
  updatedOn: number
  imageUrl?: string | null
  size: 'small' | 'medium' | 'large' | 'xlarge'
  summaryText?: string
}
export const ArticleThumbnail: FC<ArticleThumbnailProps> = ({
  title,
  summaryText,
  channelSlug,
  articleSlug,
  updatedOn,
  imageUrl,
  size
}) => {
  const [hover, setHover] = useState(false)
  const targetUrl = `/${channelSlug}/news/${articleSlug}`
  const svgString = encodeURIComponent(renderToStaticMarkup(<NewspaperFold />))

  const imageStyle = {
    hover: {
      backgroundImage: imageUrl
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),url(${imageUrl})`
        : `linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),url("data:image/svg+xml,${svgString}")`
    }
  }
  return (
    <Link className={styles.ArticleThumbnailLink} to={targetUrl}>
      <div
        data-testid="article-thumbnail-container"
        className={classNames(styles.ArticleThumbnailContainer, styles[size])}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={classNames(
            imageUrl ? styles.ImageContainer : styles.NoImageContainer,
            styles[size]
          )}
          data-testid={
            imageUrl
              ? 'article-thumbnail-image-container'
              : 'article-thumbnail-no-image-container'
          }
        >
          <div
            className={styles.ArticleImage}
            style={{
              backgroundImage: imageUrl
                ? `url(${imageUrl})`
                : `url("data:image/svg+xml;utf8,${svgString}")`,
              ...(hover ? imageStyle.hover : null)
            }}
          />
          {summaryText && size !== 'xlarge' && (
            <div className={styles.SummaryText}>
              <p className={styles.TextContent}>{summaryText}</p>
            </div>
          )}
        </div>

        <div className={classNames(styles.ArticleTextContainer, styles[size])}>
          <ArticleDate updatedDate={updatedOn} size={size} />
          <ArticleTitle title={title} size={size} extraContent={summaryText} />
        </div>
      </div>
    </Link>
  )
}
