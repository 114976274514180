import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface PageApiRequestParams {
  company: string
  slug: string
  include_unpublished: boolean
}

export interface PageApiResponse {
  slug: string
  title?: string
  content?: string
  preamble?: any
  image_url?: string
}

export interface Page {
  slug: string
  title?: string
  preamble?: string
  imageUrl?: string | null
  content?: string
}

interface GetPageParams {
  channelSlug: string
  pageSlug: string
  includeUnpublished?: boolean
}

export interface GetPageResponse {
  data?: Page
  status: number
}

export const getPage = async ({
  channelSlug,
  pageSlug,
  includeUnpublished = false
}: GetPageParams): Promise<GetPageResponse> => {
  try {
    const params: PageApiRequestParams = {
      company: channelSlug,
      slug: pageSlug,
      include_unpublished: includeUnpublished
    }
    const pageResponse: ApiResponse = await ApiEndpoint.call({
      path: 'page',
      params
    })

    const mappedResponse = mapPageResponse(pageResponse.data)

    return {
      data: mappedResponse,
      status: pageResponse.status
    }
  } catch (e: any) {
    if (e.status === 404) {
      return { status: 404 }
    }
    throw new Error(e.message)
  }
}

export const mapPageResponse = (apiResponse: PageApiResponse): Page => ({
  slug: apiResponse.slug,
  title: apiResponse.title,
  preamble: apiResponse.preamble,
  imageUrl: apiResponse.image_url,
  content: apiResponse.content
})
