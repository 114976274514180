import React, { FC } from 'react'
import classNames from 'classnames'
import { t } from '@lingui/macro'
import { TitleThumbnailSection } from 'components/content/TitleThumbnailSection/TitleThumbnailSection'

import styles from './Start.module.scss'

interface Props {
  showLatestVideosSection: boolean
  isRootPage: boolean
  latestVideosRef: any
  latestVideosInView: boolean
  getlatestVideosScrollRef: any
  latestVideosData: any
  channelSlug: string
  thumbnailSize: 'small' | 'large'
  isLoading: boolean
}

export const LatestVideosSection: FC<Props> = ({
  showLatestVideosSection,
  isRootPage,
  latestVideosRef,
  latestVideosInView,
  getlatestVideosScrollRef,
  latestVideosData,
  channelSlug,
  thumbnailSize,
  isLoading
}) =>
  showLatestVideosSection ? (
    <div
      className={classNames(styles.LatestVideosContainer, {
        [styles.ThemedPastGames]: isRootPage,
        [styles.NotThemedPastGames]: !isRootPage
      })}
      data-testid="latest-videos-container"
      ref={latestVideosRef}
    >
      {latestVideosInView && (
        <TitleThumbnailSection
          getInfiniteScrollRef={getlatestVideosScrollRef}
          thumbnailContent={latestVideosData}
          sectionTitle={t({
            id: 'latest videos slider start page',
            message: 'Latest videos'
          })}
          showAllLinkPath={`/${channelSlug}/videos`}
          linkLabel={t`All`}
          thumbnailsCardIsShared={false}
          size={thumbnailSize}
          useLeftPadding
          isLoading={isLoading}
        />
      )}
    </div>
  ) : null
