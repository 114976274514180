// import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import featuredData from '../../pages/StartPage/featuredSection.json'

export const getFeaturedContent = async () => {
  return new Promise((resolve) =>
    setTimeout(() => resolve(featuredData.featuredSection), 100)
  )

  // commented out code gives an idea of the expected code
  /*
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: '/featured'
    })
    const responseData = await resultResponse
    return responseData
  } catch (error) {
    throw new error // log?
  }
  */
}
