import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'

interface LandingPageHeaderProps {
  headerImgPath: string
}
const LandingPageHeader: FC<LandingPageHeaderProps> = ({ headerImgPath }) => (
  <Link to="/">
    <div className={styles.LandingPageHeader}>
      <img src={headerImgPath} alt="Solidsport logo" />
    </div>
  </Link>
)

export default LandingPageHeader
