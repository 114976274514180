import React, { FC } from 'react'
import { t } from '@lingui/macro'
import LiveIcon from '../../../../assets/icons/LiveIcon'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'

interface Props {
  id: string
  channelSlug: string
  rootDomain?: string
}
export const LiveButton: FC<Props> = ({ id, channelSlug, rootDomain }) => (
  <LinkIconButton
    type={ButtonType.Primary}
    buttonSize="Small"
    label={t`Live`}
    id="live"
    href={`/${channelSlug}/games/g/${id}`}
    rootDomain={rootDomain}
  >
    <LiveIcon />
  </LinkIconButton>
)
