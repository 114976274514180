import { LinkTarget } from 'interfaces/LinkTarget.interface'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  hasLivestream: boolean
  gameSlug: string
  children?: React.ReactNode
  rootDomain?: string
}
export const DecideLink: FC<Props> = ({
  hasLivestream,
  gameSlug,
  children,
  rootDomain
}) => {
  const link = rootDomain ? `https://${rootDomain}${gameSlug}` : gameSlug
  if (hasLivestream) {
    return rootDomain ? (
      <a href={link} target={LinkTarget.NewWindow}>
        {children}
      </a>
    ) : (
      <Link to={gameSlug}>{children}</Link>
    )
  }

  return <>{children}</>
}
