/* eslint-disable no-unused-expressions */
import { Settings, Check } from '@mui/icons-material'
import { CircularProgress, Icon } from '@mui/material'
import Hls from 'hls.js'
import React, { useEffect, useState } from 'react'
import styles from './QualityPicker.module.scss'
import { getQualityLevels, QualityLevel } from './getQualityLevels'
import classNames from 'classnames'

type QualityPickerProps = {
  hls: Hls | undefined
}

export const QualityPicker: React.FC<QualityPickerProps> = ({
  hls = undefined
}) => {
  const [open, setOpen] = useState(false)
  const [loadingLevel, setLoadingLevel] = useState(false)
  const [qualityLevels, setQualityLevels] = useState<QualityLevel[]>([])

  useEffect(() => {
    const onLevelSwitching = () => {
      setLoadingLevel(true)
    }
    const onLevelSwitched = () => {
      setLoadingLevel(false)
    }
    if (hls) {
      if (hls.levels) onLevels()
      hls.on(Hls.Events.LEVEL_LOADED, onLevels)
      hls.on(Hls.Events.LEVELS_UPDATED, onLevels)
      hls.on(Hls.Events.LEVEL_SWITCHING, onLevelSwitching)
      hls.on(Hls.Events.LEVEL_SWITCHED, onLevelSwitched)
    }
    return () => {
      if (hls) {
        hls.off(Hls.Events.LEVEL_LOADED, onLevels)
        hls.off(Hls.Events.LEVELS_UPDATED, onLevels)
        hls.off(Hls.Events.LEVEL_SWITCHING, onLevelSwitching)
        hls.off(Hls.Events.LEVEL_SWITCHED, onLevelSwitched)
      }
    }
  }, [hls])

  const onLevels = () => {
    if (hls?.levels) {
      setQualityLevels(getQualityLevels(hls.levels))
    }
  }

  const selectLevel = (nextLevel: number) => {
    if (hls && !loadingLevel) {
      // eslint-disable-next-line no-param-reassign
      hls.nextLevel = nextLevel
    }
  }

  const manualLevel = (): number => {
    if (hls) {
      return hls.manualLevel
    }
    return -1
  }

  const nextLoadLevel = (): number => {
    if (hls) {
      return hls.nextLoadLevel
    }
    return -1
  }

  return (
    <>
      {qualityLevels && qualityLevels.length > 1 && (
        <span className={styles.QualityPicker}>
          {open && (
            <div className={styles.Levels}>
              {qualityLevels.map((level: QualityLevel) => (
                <div
                  className={classNames(styles.Level, {
                    [styles.loading]:
                      level.index === nextLoadLevel() && loadingLevel,
                    [styles.manual]: level.index === manualLevel()
                  })}
                  key={level.bitrate}
                  onClick={() => selectLevel(level.index)}
                >
                  <span>{level.name}</span>
                  {level.addon && (
                    <span className={styles.addon}>{level.addon}</span>
                  )}
                  {level.index === nextLoadLevel() && loadingLevel && (
                    <span className={styles.spinner}>
                      <CircularProgress size=".85em" />
                    </span>
                  )}
                  {level.index === nextLoadLevel() && !loadingLevel && (
                    <span className={styles.check}>
                      <Icon component={Check} style={{ fontSize: '.85em' }} />
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
          <button
            type="button"
            className={classNames(
              styles.QualityPickerButton,
              styles.PlayerButton
            )}
            onClick={() => setOpen(!open)}
          >
            <Icon
              component={Settings}
              className={classNames(
                styles.QualityPickerIcon,
                styles.PlayerButtonIcon
              )}
            />
          </button>
        </span>
      )}
    </>
  )
}
