import React, { FC } from 'react'

import { t } from '@lingui/macro'
import { TextInput } from 'components/Forms/TextInputField/TextInput'

import { Field, FormikProps } from 'formik'

import { TextArea } from 'components/Forms/TextArea/TextArea'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { HeroImageCallToAction } from './HeroImageCallToActionForm'
import styles from './HeroImageCallToActionFormFields.module.scss'
import { CustomLinkFormFields } from 'components/Forms/CustomLinkFormFields/CustomLinkFormFields'

interface Props {
  formik: FormikProps<HeroImageCallToAction>
}

export const HeroImageCallToActionFormFields: FC<Props> = ({ formik }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const titlePlaceholder =
    currentBreakpoint < Breakpoint.sm
      ? t`Write a title here`
      : t`Write something to engage your audience here`

  return (
    <div className={styles.FieldsContainer}>
      <div className={styles.TextInput}>
        <Field
          component={TextInput}
          id="title"
          name="title"
          label={t`Title`}
          placeholder={titlePlaceholder}
          onChange={formik.handleChange}
          textValue={formik.values.title}
          isValid={!formik.errors.title}
          validationMessage={formik.errors.title}
          isRequired={false}
        />
      </div>
      <div className={styles.TextArea}>
        <Field
          component={TextArea}
          label={t`Text`}
          id="text"
          name="text"
          className={styles.MessageText}
          placeholder={t`Write a descriptive text here`}
        />
      </div>
      <div className={styles.ButtonsFieldsContainer}>
        <CustomLinkFormFields
          formik={formik}
          textFieldLabel={t`Button text`}
          textFieldPlaceHolder={t`Button text`}
          linkFieldLabel={t`Button link`}
          textFieldName="textField"
          linkFieldName={'linkField'}
          showAlwaysAsColumn={true}
        />
      </div>
    </div>
  )
}
