import React, { FC, useCallback, useMemo } from 'react'
import styles from './ScoreboardDetails.module.scss'
import classNames from 'classnames'
import { Trans } from '@lingui/macro'

import { useSelector } from 'react-redux'
import { MediaObject } from 'interfaces'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { Referee, getTeamLineups } from 'api/gameStatistics/getTeamLineups'
import { useDataApi } from 'api/useDataApi'
import { Loader } from 'components/Loader/Loader'
interface Props {}

export const RefereeSection: FC<Props> = () => {
  const stream = useSelector(streamSelector)
  const mediaObject: MediaObject = stream?.data?.mediaObject
  const gameId = mediaObject.game.id
  const parameters = useMemo(() => ({ gameId: gameId }), [gameId])
  const {
    data: lineups,
    isLoading,
    isError
  } = useDataApi({
    apiCall: useCallback(getTeamLineups, []),
    parameters
  })
  if (isLoading) {
    return <Loader />
  }
  if (isError || !lineups || !lineups.referees || lineups.referees.length === 0)
    return null

  return (
    <div
      className={classNames(styles.RefereeContainer, styles.DetailsItem)}
      data-testid="referee-section"
    >
      <Trans>Referees:</Trans>
      {lineups?.referees.map((referee: Referee) => (
        <div key={referee.id} className={styles.DetailsItem}>
          {referee.name}
        </div>
      ))}
    </div>
  )
}
