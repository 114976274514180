import React, { FC } from 'react'
import SolidsportLogo from 'components/page/footer/defaultFooter/SolidSportLogo'

import styles from './CompanyInfo.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { useSelector } from 'react-redux'
import { languageSelector } from 'redux-store/language/language.selectors'

interface Props {}

export const CompanyInfo: FC<Props> = () => {
  const lang = useSelector(languageSelector)
  const parsedLink = lang
    ? `https://www.solidsport.com/${lang}/products/`
    : 'https://www.solidsport.com/en/products/'
  return (
    <a
      className={styles.CompanyInfoContainer}
      href={parsedLink}
      target={LinkTarget.NewWindow}
    >
      <div className={styles.CompanyText}>powered by</div>
      <div className={styles.CompanyLogoContainer}>
        <SolidsportLogo />
      </div>
    </a>
  )
}
