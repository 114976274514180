import React, { FC } from 'react'
import { CustomFontDisplayItem } from '../customFontDisplayItem'

import styles from './CustomFontList.module.scss'

interface Props {
  fontList: CustomFontDisplayItem[]
}

export const CustomFontList: FC<Props> = ({ fontList }) => (
  <>
    <div className={styles.ListHeader}>Uploaded Fonts</div>
    <div className={styles.FontList}>
      <div className={styles.FontListHeader}>
        <div className={styles.Cell}>Identifier</div>
        <div className={styles.Cell}>File name</div>
      </div>
      {fontList.length > 0 &&
        fontList?.map((font: CustomFontDisplayItem) => (
          <div key={font.identifier} className={styles.Font}>
            <div className={styles.Cell}>{font.identifier}</div>
            <div className={styles.Cell}>{font.name}</div>
          </div>
        ))}
      {fontList?.length < 1 && <div>There are no uploaded fonts</div>}
    </div>
  </>
)
