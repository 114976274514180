import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'

import {
  channelImpressumSelector,
  channelSelector
} from 'redux-store/channel/channel.selectors'
import languageActions from 'redux-store/language/language.actions'

import { Locale } from '../ThemedFooter'
import { LinkSection } from './LinkSection'

import styles from './GeneralSection.module.scss'
import { LanguageDropdown } from './LanguageDropdown'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

interface Props {
  languages: Locale[]
  companyName: string
}

export const getCurrentYear = () => new Date().getFullYear()

export const GeneralSection: FC<Props> = ({ languages, companyName }) => {
  const dispatch = useDispatch()
  const breakpoint = useCurrentBreakpoint()

  const selectedLanguage = useSelector((state: any) => state.language)
  const impressumPage = useSelector(channelImpressumSelector)
  const channel = useSelector(channelSelector)

  const year = getCurrentYear()

  const languageLabel = t`Language`
  const languagesList = languages.map((language: Locale) => ({
    name: language.name,
    value: language.abbr
  }))

  const handleLanguageChange = (lang: string) => {
    dispatch(languageActions.setLanguage(lang))
  }

  return (
    <div className={styles.GeneralSectionContainer}>
      {breakpoint >= Breakpoint.sm && (
        <div className={styles.LanguageAndCompany}>
          <LanguageDropdown
            label={languageLabel}
            selectedLanguage={selectedLanguage}
            languagesList={languagesList}
            onChange={handleLanguageChange}
          />
          <div>
            &copy; {year} {companyName}
          </div>
        </div>
      )}

      {breakpoint < Breakpoint.sm && (
        <div>
          &copy; {year} {companyName}
        </div>
      )}
      <div>
        <LinkSection
          impressum={impressumPage}
          channelSlug={channel.data.subdomain}
          channelCountry={channel.data.country}
        />
      </div>

      {breakpoint < Breakpoint.sm && (
        <LanguageDropdown
          label={languageLabel}
          selectedLanguage={selectedLanguage}
          languagesList={languagesList}
          onChange={handleLanguageChange}
        />
      )}
    </div>
  )
}
