import { Trans } from '@lingui/macro'
import React, { FC } from 'react'

export const PrivacyTextSection: FC = () => {
  const privacyPolicyLink = 'https://www.solidsport.com/privacy-policy/'
  const Link = (
    <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
      <Trans>Privacy Policy</Trans>.
    </a>
  )
  return (
    <Trans>
      Your data is safe with Solidsport. We&apos;re GDPR compliant and committed
      to protecting your privacy. Read our {Link} for more details
    </Trans>
  )
}
