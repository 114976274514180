import React, { FC } from 'react'
import styles from './index.module.scss'

interface Props {
  tagText: string
  children?: React.ReactNode
}
const IconTextTag: FC<Props> = ({ tagText, children }) => {
  return (
    <div className={styles.TagContainer}>
      <div className={styles.TagIcon}>{children}</div>
      {tagText}
    </div>
  )
}
export default IconTextTag
