import React, { FC } from 'react'

export const EarthIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.47656 11.8535C2.47656 14.3731 3.47745 16.7894 5.25905 18.571C7.04064 20.3526 9.45701 21.3535 11.9766 21.3535C14.4961 21.3535 16.9125 20.3526 18.6941 18.571C20.4757 16.7894 21.4766 14.3731 21.4766 11.8535C21.4766 9.33396 20.4757 6.9176 18.6941 5.136C16.9125 3.35441 14.4961 2.35352 11.9766 2.35352C9.45701 2.35352 7.04064 3.35441 5.25905 5.136C3.47745 6.9176 2.47656 9.33396 2.47656 11.8535V11.8535Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.69092 21.0752C8.40567 19.1904 7.54688 15.7653 7.54688 11.853C7.54688 7.94073 8.40567 4.51566 9.69092 2.63086"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.47656 11.8535H21.4766"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9375 16.9199H20.0123"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9375 6.78711H20.0123"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2656 2.63086C15.5509 4.51566 16.4097 7.94073 16.4097 11.853C16.4097 15.7653 15.5509 19.1904 14.2656 21.0752"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
