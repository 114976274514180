import React, { FC } from 'react'

import { AdminEditButtons } from './AdminEditButton'
import { LiveButton } from './LiveButton'
import { AdminNotPlannedButton } from './AdminNotPlannedButton'
import { UpcomingLivestreamButton } from './UpcomingLivestreamButton'
import { HighlightAndReplayButtons } from './HighlightAndReplayButtons'
import { NoBroadcastLabel } from './NoBroadcastLabel'
import { ReplayButton } from './ReplayButton'
import { AdminEditLiveButtons } from './AdminEditLive'
import { AdminEditPastGameButtons } from './AdminEditPastGame'
import { AdminPastGameNoBroadcastButton } from './AdminPastGameNoBroadcastButton'

import styles from './GameScheduleButtons.module.scss'

export enum ActionButtons {
  Live = 'Live',
  AdminPlanned = 'AdminPlanned',
  UpcomingLivestream = 'UpcomingLivestream',
  AdminLive = 'AdminLive',
  AdminPastGame = 'AdminPastGame',
  AdminPastGameNoBroadcasted = 'AdminPastGameNoBroadcasted',
  AdminNotPlanned = 'AdminNotPlanned',
  NoLivestream = 'NoLivestream',
  Replay = 'Replay',
  HighlightAndReplay = 'HighlightAndReplay',
  AdminHighlightAndReplay = 'AdminHighlightAndReplay'
}

interface Props {
  type: ActionButtons
  id: string
  channelSlug: string
  displayChannelSlug: string
  higlightSlug: string
  onClick: (path: string, isAdmin: boolean) => void
  rootDomain?: string
}

export const GameScheduleButtons: FC<Props> = ({
  type,
  id,
  channelSlug,
  higlightSlug,
  displayChannelSlug,
  onClick,
  rootDomain
}) => {
  const buttonType = () => {
    switch (type) {
      case ActionButtons.Live:
        return (
          <LiveButton
            id={id}
            channelSlug={channelSlug}
            rootDomain={rootDomain}
          />
        )

      case ActionButtons.AdminPlanned:
        return <AdminEditButtons id={id} channelSlug={channelSlug} />

      case ActionButtons.AdminNotPlanned:
        return (
          <AdminNotPlannedButton
            onClick={onClick}
            id={id}
            channelSlug={channelSlug}
          />
        )

      case ActionButtons.AdminLive:
        return <AdminEditLiveButtons channelSlug={channelSlug} id={id} />

      case ActionButtons.AdminPastGame:
        return <AdminEditPastGameButtons channelSlug={channelSlug} id={id} />

      case ActionButtons.UpcomingLivestream:
        return (
          <UpcomingLivestreamButton
            id={id}
            channelSlug={channelSlug}
            rootDomain={rootDomain}
          />
        )

      case ActionButtons.Replay:
        return (
          <ReplayButton
            id={id}
            channelSlug={channelSlug}
            rootDomain={rootDomain}
          />
        )

      case ActionButtons.HighlightAndReplay:
        return (
          <HighlightAndReplayButtons
            id={id}
            channelSlug={channelSlug}
            highlightSlug={higlightSlug}
            displayChannelSlug={displayChannelSlug}
            rootDomain={rootDomain}
          />
        )
      case ActionButtons.AdminPastGameNoBroadcasted:
        return (
          <AdminPastGameNoBroadcastButton id={id} channelSlug={channelSlug} />
        )

      case ActionButtons.NoLivestream:
        return <NoBroadcastLabel />

      default:
        return null
    }
  }
  return <div className={styles.ButtonContainer}>{buttonType()}</div>
}
