import { t } from '@lingui/macro'
import { IntegrationName } from './getLogoForIntegration'

export const getCategoryLeaderListToShow = (
  isSwedishHandball: boolean,
  integrationName: IntegrationName
) => {
  if (isSwedishHandball && integrationName === IntegrationName.Profixio) {
    return [
      { title: t`Total Goals`, statsIdentifier: 'totalGoals' },
      { title: t`Goals`, statsIdentifier: 'gameGoals' },
      { title: t`Penalty Goals`, statsIdentifier: 'penaltyGoals' }
    ]
  }
  return []
}
