export enum GameScheduleItemStatus {
  Undefined = 'null',
  Future = 'future',
  Active = 'active',
  Past = 'past'
}

export interface GameScheduleItem {
  id: string
  title: string
  startDate: number
  endDate: number
  teamId: string
  teamName: string
  status: GameScheduleItemStatus
  totalGames: number
}
