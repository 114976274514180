import { IntegrationName } from '../getLogoForIntegration'

export interface PlayerStatisticsTableColumns {
  name: string
  identifier: string
  abbreviation: string
  dataType: string
}
export interface Player {
  name: string
  team: string
}
export interface PlayerStatisticsTableRows {
  [headerIdentifier: string]: string | number | boolean | Player
}
export interface Integration {
  name: IntegrationName
  link: string
  lastUpdated: number
}
export interface PlayerStatisticsTableInterface {
  name: string
  id: string
  integration: Integration
  sortedBy: string
  columns: PlayerStatisticsTableColumns[]
  rows: PlayerStatisticsTableRows[]
}

export enum PlayerStatsVariantsIdentifiers {
  TeamLogoUrl = 'teamLogoUrl',
  Player = 'player',
  Rank = 'rank'
}
