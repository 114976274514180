import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './TeamCard.module.scss'
import { Team } from './Team.interface'

interface Props {
  cardData: Team
}

const TeamCardComponent: FC<Props> = ({ cardData }) => {
  const color1 = '#000000'
  const color2 = 'rgba(0, 0, 0, 0) 58.21%'
  const [hover, setHover] = useState(false)

  const style = {
    normal: {
      backgroundImage: `linear-gradient(to top, ${color1} 0%, ${color2} 100%) `
    },
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  return (
    <Link to={`/${cardData.channelSlug}`}>
      <div
        className={styles.Card}
        data-testid="background-container"
        style={{ backgroundImage: `url(${cardData.imagePath})` }}
      >
        <div
          className={styles.FeaturedContent}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            ...style.normal,
            ...(hover ? style.hover : null)
          }}
        >
          <div className={styles.LogoContainer}>
            <img
              src={cardData.logoImagePath}
              alt={cardData.channelName}
              className={styles.LogoImage}
            />
          </div>
        </div>
      </div>
    </Link>
  )
}
export default TeamCardComponent
