import React, { FC } from 'react'
import { t } from '@lingui/macro'
import BroadcastIcon from '../../../../assets/icons/BroadcastIcon'
import EditIcon from '../../../../assets/icons/EditIcon'
import IconTextTag from '../../../Tags/IconTextTag'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'
import { getAdminPath } from 'helpers/getAdminPath'
import styles from './GameScheduleButtons.module.scss'

interface Props {
  id: string
  channelSlug: string
  children?: React.ReactNode
}
export const AdminEditButtons: FC<Props> = ({ id, channelSlug }) => {
  const adminPath = getAdminPath()
  return (
    <>
      <div className={styles.FirstButton}>
        <IconTextTag tagText={t`Planned`}>
          <BroadcastIcon />
        </IconTextTag>
      </div>
      <LinkIconButton
        type={ButtonType.Ghost}
        buttonSize="Small"
        label={t`Edit`}
        id="edit"
        href={`${adminPath}/admin/games/${id}/edit?select_company=${channelSlug}`}
      >
        <EditIcon />
      </LinkIconButton>
    </>
  )
}
