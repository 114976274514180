import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface Group {
  groupId: number
  groupName: string
}

export interface GroupResponseApi {
  id: number
  name: string
}

export const getGroupsForChannel = async ({
  channelSlug
}: {
  channelSlug: string
}): Promise<Group[]> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/competitions/districts`,
      authenticatable: true
    })

    const responseData = await resultResponse
    const mapped: Group[] = mapGroupList(responseData)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapGroupList = (responseData: ApiResponse) =>
  responseData?.data?.map((item: GroupResponseApi) => ({
    groupName: item.name,
    groupId: item.id
  }))
