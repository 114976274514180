// eslint-disable-next-line no-shadow
export enum TallThumbnailType {
  Featured = 'Featured',
  Sport = 'Sport'
}
interface TallThumbnail {
  id: string
  name: string
  imageUrl: string
  targetUrl: string
  country: string
  type: TallThumbnailType | string
}

export interface Featured extends TallThumbnail {
  dates: string
  startDate?: number
  endDate?: number
  description: string
}

export interface Sport extends TallThumbnail {
  slug: string
}

export interface League extends TallThumbnail {
  description: string
  leagueImageUrl: string
}

export interface Cup extends TallThumbnail {
  description: string
  sport: string // Sport?
  cupImageUrl: string
}
