import { useEffect } from 'react'
import { Chromecast } from './chromecast'

interface Props {
  chromecast: Chromecast
  getVideoElement: () => any
  videoElementPlay: () => void
  videoPlaying: boolean
  setVideoPlaying: (value: boolean) => void
  setDuration: (value: any) => void
}

export const useChromecastEvents = ({
  chromecast,
  getVideoElement,
  videoElementPlay,
  videoPlaying,
  setVideoPlaying,
  setDuration
}: Props) => {
  useEffect(() => {
    if (chromecast.connected && chromecast.canSeek) {
      chromecast.seek(getVideoElement().currentTime)
    }
  }, [chromecast.connected, chromecast.canSeek])

  useEffect(() => {
    if (!chromecast.connected && chromecast.wasConnected) {
      getVideoElement().currentTime = chromecast.lastPlaybackTime
      if (chromecast.wasPlaying) {
        videoElementPlay()
      }
    }
  }, [chromecast.connected, chromecast.wasConnected, chromecast.wasPlaying])

  useEffect(() => {
    if (chromecast.connected && videoPlaying) {
      chromecast.play()
    }
  }, [chromecast.connected, videoPlaying])

  useEffect(() => {
    if (chromecast.playing) {
      getVideoElement().pause()
      setVideoPlaying(false)
    }
  }, [chromecast.playing])

  useEffect(() => {
    setDuration(chromecast.duration)
  }, [chromecast.duration])
}
