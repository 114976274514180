import React, { FC } from 'react'
import { MenuItem, SidebarMenuLink } from './MenuItem/MenuItem'
import styles from './SidebarNavigation.module.scss'
import { SignOutButton } from 'components/buttons/SignOutButton/SignOutButton'

interface Props {
  menuLinks: SidebarMenuLink[]
  showSignOutButton: boolean
  onSignOutClick: () => void
}

export const SidebarNavigation: FC<Props> = ({
  menuLinks,
  showSignOutButton,
  onSignOutClick
}) => {
  return (
    <div className={styles.SidebarNavigation}>
      <div className={styles.MenuLinks}>
        {menuLinks &&
          menuLinks.length > 0 &&
          menuLinks.map((menuLink: SidebarMenuLink) => (
            <MenuItem
              icon={menuLink.icon}
              {...menuLink}
              key={menuLink.translationText}
            />
          ))}
      </div>
      {showSignOutButton && <SignOutButton onClick={onSignOutClick} />}
    </div>
  )
}
