import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { CompetitionListItem } from './CompetitionItem/competitionListItem'
import { CompetitionItem } from './CompetitionItem/CompetitionItem'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

interface Props {
  listData: CompetitionListItem[]
  onAddCompetiton: (competionSelectedId: number) => void
  hasError: boolean
  channelType: ChannelTypes
}
export const CompetitionsList: FC<Props> = ({
  listData,
  onAddCompetiton,
  hasError,
  channelType
}) => {
  if (hasError) {
    return (
      <div>
        <Trans>
          Something went wrong trying to retrive the data, please try again by
          reloading the page.
        </Trans>
      </div>
    )
  }
  return (
    <div data-testid="select-from-game-schedule-list">
      {listData.map((competionItem: CompetitionListItem) => (
        <CompetitionItem
          key={competionItem.id}
          competitonListItem={competionItem}
          onAddCompetition={() => onAddCompetiton(competionItem.id)}
          channelType={channelType}
          competitionIsAlreadyClaimed={!!competionItem.channelSlug}
        />
      ))}
    </div>
  )
}
