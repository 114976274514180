import { useCallback, useState } from 'react'
import { FeatureFlagsAvailable } from './FeatureFlagsAvailable'
import { getParsedFeatureFlags } from './getParsedFeatureFlags'

export const useFeatureFlags = () => {
  const [flags, setFlags] = useState(getParsedFeatureFlags())

  const getFlag = useCallback(
    (flag: FeatureFlagsAvailable) => {
      const key = flag.toString()
      return flags[key]
    },
    [flags]
  )

  const setFlag = useCallback(
    (flag: FeatureFlagsAvailable, value: boolean) => {
      const key = flag.toString()
      if (value) {
        localStorage.setItem(key, 'true')
      } else {
        localStorage.removeItem(key)
      }
      setFlags({ ...flags, [key]: value })
    },
    [flags]
  )

  return { flags, getFlag, setFlag }
}
