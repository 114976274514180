import { MediaObject } from 'interfaces'

export const getViewerPageUrl = (
  mediaObject: MediaObject,
  rootDomain: string
) => {
  const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  const pageDomain = rootDomain ? `https://${rootDomain}` : baseUrl
  const rootDomainViewerPageUrl = mediaObject?.game
    ? `${pageDomain}/${mediaObject.game.display_company.subdomain}/games/g/${mediaObject.game.id}`
    : `${pageDomain}/${mediaObject?.company.subdomain}/watch/${mediaObject?.slug}`
  return rootDomainViewerPageUrl
}
