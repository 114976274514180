import React from 'react'

export const RoundArrowIcon = () => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F5F5F5" />
    <path
      d="M17 26L23 20L17 14"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
