import React, { FC, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik } from 'formik'
import * as yup from 'yup'
import { t, Trans } from '@lingui/macro'

import { useDataApi } from '../../../../api/useDataApi'
import { Loader } from '../../../../components/Loader/Loader'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import { ContactFormFields } from '../ContactForm/ContactFormFields'
import {
  StatusMessage,
  StatusMessageType
} from '../StatusMessage/StatusMessage'
import {
  ContactDetails,
  getInvoiceContact
} from 'api/channel/settings/getInvoiceContact'

import styles from './InvoiceContactForm.module.scss'
import { phoneRegex } from '../regex'

interface Props {
  onSubmitSuccess: () => void
  channelSlug: string
}

export const InvoiceContactForm: FC<Props> = ({
  onSubmitSuccess,
  channelSlug
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { data, isLoading, isError } = useDataApi({
    apiCall: getInvoiceContact,
    initialData: {},
    parameters: channelSlug
  })

  if ((!data || Object.keys(data).length === 0) && !isLoading) return null

  if (isLoading) return <Loader />

  const invoiceContactSchema = yup.object({
    firstName: yup.string().required(t`Please enter the contact's first name`),
    lastName: yup.string().required(t`Please enter the contact's last name`),
    phoneNumber: yup
      .string()
      .matches(phoneRegex, t`Please enter a valid phone number`)
      .required(t`Please enter a valid phone number`),
    email: yup
      .string()
      .email()
      .required(t`Please enter a valid email address`)
  })

  const initialValues: ContactDetails = {
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    phoneNumber: data.phoneNumber || '',
    email: data.email || ''
  }

  const onSubmit = async (
    values: ContactDetails,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const contactSettingValues = { ...values, channelSlug }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const doSomething = (thing: any) => ({ hasError: false, errors: undefined })
    const response = await doSomething(contactSettingValues)
    if (response.hasError) {
      // @ts-ignore
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
      onSubmitSuccess()
    }
  }

  return (
    <div className={styles.InvoiceContactFormContainer}>
      {isError && (
        <div>
          <Trans>
            There was a problem retriving your settings data. Please try again
          </Trans>
        </div>
      )}
      <h4>
        <Trans>Invoice contact</Trans>
      </h4>
      <p className={styles.FormDescription}>
        <Trans>
          The ‘Invoice Contact’ is the person who is responsible for the
          finances of your organisation, for example the Treasurer of an
          Association or a Governing Body. We require an Invoice Contact to
          ensure channel revenue payouts are allocated correctly.
        </Trans>
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={invoiceContactSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => (
          <Form>
            <ContactFormFields formik={props} />
            <div className={styles.SubmitButton}>
              <SubmitButton
                type="secondary"
                disabled={!props.dirty || props.isSubmitting}
                label={t`Save changes`}
              />
            </div>
            {showSuccessMessage && (
              <StatusMessage
                onClose={() => setShowSuccessMessage(false)}
                message={t`your invoice contact settings have been successfully updated`}
                messageType={StatusMessageType.Success}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
