import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { mapChildChannelResponse } from './mapChildChannelResponse'

export const getGroupsForEvent = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `channels/${slug}/children`,
      params: { page: page, per_page: perPageCount }
    })
    const responseData = await resultResponse
    const map = mapChildChannelResponse(responseData.data)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
