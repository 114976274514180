import React, { FC } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import useCurrentUser from 'hooks/CurrentUser'
import userActions from 'redux-store/user/user.actions'
import { TimezoneDropdown } from 'pages/ChannelPage/ChannelSettings/Overview/TimezoneDropdown'
import { t } from '@lingui/macro'
import { locales } from 'translations/i18n'
import { DropdownList } from 'components/Forms/DropdownListField/DropdownList'
import { DropDownListItem } from 'components/Forms/DropdownListField/DropdownItemList/DropdownItemList'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { patchTimezone } from 'api/user/patchTimezone'
import { patchLocale } from 'api/user/patchLocale'
import { preferencesValidationSchema } from './PreferencesValidationSchema'
import { useDispatch } from 'react-redux'
import styles from './PreferencesForm.module.scss'
import languageActions from 'redux-store/language/language.actions'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'

interface PreferencesValues {
  timezone: string
  language: string
}
export const PreferencesForm: FC = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const initialValues: PreferencesValues = {
    timezone: currentUser?.timezone || '',
    language: currentUser?.locale || ''
  }

  const languagesList: DropDownListItem[] = locales.map((locale) => ({
    label: locale.name,
    id: locale.abbr
  }))
  const onSubmit = async (
    values: PreferencesValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const { timezone, language } = values
    if (timezone !== currentUser?.timezone) {
      const response = await patchTimezone(timezone)
      if (!response.isSuccess) {
        formikHelpers.setFieldError(
          timezone,
          t`Something went wrong when trying to change the timezone`
        )
      } else {
        dispatch(userActions.updateUser())
      }
    }
    if (language !== currentUser?.locale) {
      const response = await patchLocale(language)
      if (!response.isSuccess) {
        formikHelpers.setFieldError(
          timezone,
          t`Something went wrong when trying to change the language`
        )
      } else {
        dispatch(languageActions.setLanguage(language))
        dispatch(userActions.updateUser())
      }
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange
      validationSchema={preferencesValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div
              className={styles.FormContainer}
              data-testid="preferences-form"
            >
              <FormSectionTitle title={t`Preferences`} />
              <div className={styles.FormFields}>
                <Field
                  component={TimezoneDropdown}
                  id="timezone"
                  name="timezone"
                  label={t`Timezone`}
                  placeholder={t`please select the timezone`}
                  textValue={formik.values.timezone}
                  isValid={!formik.errors.timezone}
                  validationMessage={formik.errors.timezone}
                  isRequired
                  value={formik.values.timezone}
                  type="dark"
                  onChange={(id: string) => {
                    formik.setFieldValue('timezone', id)
                  }}
                />
                <Field
                  component={DropdownList}
                  dropdownListData={languagesList}
                  value={formik.values.language}
                  id="language"
                  name="language"
                  selected={formik.values.language}
                  label={t`Preferred language`}
                  placeholder={t`please select a language`}
                  isValid={formik.values.language}
                  validationMessage={formik.values.language}
                  type="dark"
                  onChange={(id: string) =>
                    formik.setFieldValue('language', id)
                  }
                />
              </div>
              <div className={styles.ButtonContainer}>
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SubmitButton}
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                  label={t`Save changes`}
                  isSubmitting={formik.isSubmitting}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
