import { getCustomCategory } from '../../../api/channel/getCustomCategory'
import { getDefaultCategoriesList } from './Dropdown/getDefaultCategoryList'

import { getHighlightsForVideo } from '../../../api/channel/getHighlightsForVideo'
import { getVideosForChannel } from '../../../api/channel/getVideosForChannel'
import { DropDownListItem } from './Dropdown/DropdownList/DropdownList'

export const getCorrectApiforFilter = (categoryFilter: string) => {
  const defaultCategoriesList: DropDownListItem[] =
    getDefaultCategoriesList(true)
  switch (categoryFilter) {
    case defaultCategoriesList[0].id:
      return getVideosForChannel
    case defaultCategoriesList[1].id:
      return getHighlightsForVideo
    default:
      return getCustomCategory
  }
}
