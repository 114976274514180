export interface SignupValues {
  firstName: string
  lastName: string
  email: string
  password: string
  emailConsent?: boolean
}

export const getSignupToken = (): string | undefined => {
  const urlSearchParams = new URLSearchParams(location.search)
  const signupTokenParam = urlSearchParams.get('signup_token')
  if (signupTokenParam === null) {
    return undefined
  }
  return signupTokenParam
}
