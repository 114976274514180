import React, { FC } from 'react'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows,
  PlayerStatsVariantsIdentifiers
} from '../../PlayerStatisticsTable.interface'
import { PlayerRowCell } from './CellTypes/PlayerRowCell'
import { TeamImageRowCell } from './CellTypes/TeamImageRowCell'
import { DefaultRowCell } from './CellTypes/DefaultRowCell'

interface Props {
  row: PlayerStatisticsTableRows
  column: PlayerStatisticsTableColumns
  isSelected: boolean
}

export const RowCell: FC<Props> = ({ row, column, isSelected }) => {
  switch (column.identifier) {
    case PlayerStatsVariantsIdentifiers.TeamLogoUrl:
      return (
        <TeamImageRowCell
          imageUrl={row[column.identifier].toString()}
          isSelected={isSelected}
        />
      )
    case PlayerStatsVariantsIdentifiers.Player:
      return typeof row.player === 'object' ? (
        <PlayerRowCell
          playerName={row.player.name}
          teamName={row.player.team}
          isEmpty={false}
        />
      ) : (
        <PlayerRowCell playerName={''} teamName={''} isEmpty={true} />
      )
    default:
      return typeof row[column.identifier] !== 'object' ? (
        <DefaultRowCell
          cellText={row[column.identifier].toString()}
          isEmpty={false}
          isSelected={isSelected}
        />
      ) : (
        <DefaultRowCell cellText="-" isEmpty={false} isSelected={isSelected} />
      )
  }
}
