import React from 'react'

const BroadcastIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40503 20.0475C4.40503 20.0475 1.00032 17.1666 1.00032 12.4524C1.00032 7.73817 4.40503 4.59537 4.40503 4.59537"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.07153 16.9047C8.07153 16.9047 5.97633 15.2445 5.97633 12.5278C5.97633 9.81118 8.07153 8.00007 8.07153 8.00007"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.3931"
        y="10.8453"
        width="3.21421"
        height="3.21421"
        rx="1.60711"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5955 4.59537C19.5955 4.59537 23.0002 7.47627 23.0002 12.1905C23.0002 16.9047 19.5955 20.0475 19.5955 20.0475"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9287 7.73817C15.9287 7.73817 18.0239 9.39836 18.0239 12.115C18.0239 14.8317 15.9287 16.6428 15.9287 16.6428"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default BroadcastIcon
