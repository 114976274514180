import React, { FC } from 'react'
import { ActionButtons } from 'components/cards/GameScheduleCard/GameScheduleButtons/GameScheduleButtons'

import { WatchGameButton } from './Buttons/WatchGameButton'

import styles from './ActionButtonSection.module.scss'
import { NoBroadcastLabel } from './Buttons/NoBroadcastLabel'
import { HighlightsButton } from './Buttons/HighlightsButton'

interface Props {
  type: ActionButtons
  channelSlug: string
  id: string
  highlightsSlug: string
}

export const ActionButtonsSection: FC<Props> = ({
  type,
  channelSlug,
  id,
  highlightsSlug
}) => {
  const buttons = () => {
    switch (type) {
      case ActionButtons.Live:
      case ActionButtons.Replay:
      case ActionButtons.UpcomingLivestream:
      case ActionButtons.AdminPlanned:
      case ActionButtons.AdminLive:
      case ActionButtons.AdminPastGame:
        return <WatchGameButton id={id} channelSlug={channelSlug} />
      case ActionButtons.AdminHighlightAndReplay:
      case ActionButtons.HighlightAndReplay: {
        return (
          <>
            <HighlightsButton
              id={id}
              channelSlug={channelSlug}
              highlightSlug={highlightsSlug}
            />
            <WatchGameButton id={id} channelSlug={channelSlug} />
          </>
        )
      }
      case ActionButtons.NoLivestream:
      case ActionButtons.AdminPastGameNoBroadcasted:
      case ActionButtons.AdminNotPlanned:
        return <NoBroadcastLabel />
    }
  }
  return <div className={styles.ButtonContainer}>{buttons()}</div>
}
