import { t } from '@lingui/macro'
import { ErrorCode } from 'react-dropzone'
import { UPLOAD_TYPE } from '../../../../../../components/DropFileZone/UploadType'

export const getRejectionErrorText = (
  errorCode: ErrorCode | string,
  variant: UPLOAD_TYPE
): string => {
  const fileType = variant.toString().toLowerCase()
  switch (errorCode) {
    case ErrorCode.FileInvalidType:
      return t`File type must be ${fileType}`
    case ErrorCode.TooManyFiles:
      return t`Too many files selected`
    default:
      return t`Something went wrong uploading the files`
  }
}
