import React, { FC } from 'react'
import classNames from 'classnames'
import { t, Trans } from '@lingui/macro'

import UploadIcon from './UploadIcon'

import styles from './DropZone.module.scss'

interface Props {
  isMobile: boolean
  isDragActive: boolean
  getRootProps: any
  getInputProps: any
  mediaTypeText: string
  recommendedImageHeight?: string
  recommendedImageWidth?: string
}

export const DropZone: FC<Props> = ({
  isMobile,
  isDragActive,
  getRootProps,
  getInputProps,
  mediaTypeText,
  recommendedImageHeight,
  recommendedImageWidth
}) => {
  return (
    <div
      data-testid="drop-zone-containter"
      className={styles.DragZoneContainer}
    >
      <div
        className={classNames(styles.DragZone, {
          [styles.IsDragging]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <UploadIcon />
        <div className={styles.UploadVideo}>{t`Upload ${mediaTypeText}`}</div>
        {isDragActive ? (
          <div className={styles.SecondText}>
            <Trans>Drop the files here ...</Trans>
          </div>
        ) : (
          <div className={styles.SecondText}>
            <span className={styles.UnderlinedText}>
              <Trans>Click to upload</Trans>
            </span>
            {!isMobile && <> {t`or drag & drop`}</>}
          </div>
        )}
        {recommendedImageHeight && recommendedImageWidth && (
          <div className={styles.RecommededSize}>
            {t`Recommended size ${recommendedImageWidth} x ${recommendedImageHeight}`}
          </div>
        )}
      </div>
    </div>
  )
}
