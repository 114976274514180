import React, { FC } from 'react'
import styles from './Score.module.scss'

interface Props {
  homeScore: number
  awayScore: number
  showScore: boolean
  hasGameStarted: boolean
}

export const Score: FC<Props> = ({
  homeScore,
  awayScore,
  showScore,
  hasGameStarted
}) => {
  return (
    <div className={styles.ScoreContainer}>
      {showScore && hasGameStarted ? `${homeScore} - ${awayScore}` : '-'}
    </div>
  )
}
