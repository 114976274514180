/* eslint-disable global-require */
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

export const formatCurrency = (
  amount: number = -10000,
  currency: string = ''
): string => {
  return `${amount} ${currency}`
}

export const formatUnixDate = (unixDate: number) => {
  return dayjs.unix(unixDate).format('YYYY-MM-DD')
}

export const formatUnixDateShort = (unixDate: number) => {
  return dayjs.unix(unixDate).format('YY-MM-DD')
}

export const formatUnixTime = (unixDate: number) => {
  return dayjs.unix(unixDate).format('HH:mm')
}

export const formatUnixDay = (unixDate: number) => {
  return dayjs.unix(unixDate).format('dddd')
}

export const formatUnixDateAndTime = (unixDate: number) => {
  return dayjs.unix(unixDate).format('YYYY-MM-DD HH:mm')
}

export const formatUnixDateAndTimeWithDots = (unixDate: number) => {
  return dayjs.unix(unixDate).format('YYYY.MM.DD HH:mm')
}
export const formatUnixDateAndTimeWithMonthTexted = (unixDate: number) => {
  return dayjs.unix(unixDate).format('DD MMM YYYY HH:mm')
}

export const formatUnixDateAndTimeWithMonthTextedWihoutYear = (
  unixDate: number
) => {
  return dayjs.unix(unixDate).format('DD MMM HH:mm')
}

export const getTimePassed = (unixDate: number) => {
  return dayjs.unix(unixDate).fromNow()
}
