export const codes = {
  AD_ERROR: 'adError',
  CONTENT_PAUSE_REQUESTED: 'contentPauseRequested',
  CONTENT_RESUME_REQUESTED: 'contentResumeRequested',
  LOADED: 'loaded',
  COMPLETE: 'complete',
  STARTED: 'start',
  PAUSED: 'pause',
  RESUMED: 'resume',
  CLICK: 'click',
  READY_TO_PLAY: 'ready_to_play',
  NON_LINEAR_AD: 'non_linear_ad'
}
