import React, { FC, MouseEvent, ReactNode } from 'react'
import classNames from 'classnames'
import ArrowDown from './ArrowDown'
import styles from './Field.module.scss'

interface Props {
  selected: string | undefined | ReactNode
  onClick: () => void
  rotateArrow: boolean
  type: 'dark' | 'light'
  isPlaceholder: boolean
  customStyle?: string
}
export const DropdownField: FC<Props> = ({
  selected,
  onClick,
  rotateArrow,
  type,
  isPlaceholder,
  customStyle
}) => (
  <div
    role="button"
    data-testid="dropdown-field"
    onClick={(event: MouseEvent) => {
      event.preventDefault()
      onClick()
    }}
    className={classNames(styles.Field, {
      [styles.FormPrimary]: type === 'dark',
      [styles.FormSecondary]: type === 'light',
      [customStyle || '']: customStyle
    })}
    onKeyDown={onClick}
    tabIndex={0}
  >
    <div
      className={classNames(styles.Label, {
        [styles.Formdark]: type === 'dark',
        [styles.FormSecondary]: type === 'light',
        [styles.Placeholder]: isPlaceholder
      })}
    >
      {selected}
    </div>
    <div
      className={classNames(styles.Arrow, {
        [styles.ArrowUp]: rotateArrow,
        [styles.ArrowDown]: !rotateArrow
      })}
    >
      <ArrowDown />
    </div>
  </div>
)
