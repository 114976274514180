import React, { FC } from 'react'
import classNames from 'classnames'
import { FormValidationError } from '../FormValidationError/FormValidationError'

import styles from './textInput.module.scss'

interface Props {
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  textValue: string
  onChange: () => void
  isRequired: boolean
  readonly?: boolean
  customStyle?: string
}

export const TextInput: FC<Props> = ({
  id,
  label,
  placeholder,
  isValid,
  validationMessage,
  textValue,
  isRequired,
  onChange,
  readonly,
  customStyle
}) => (
  <div
    data-testid={id}
    className={classNames(styles.TextFieldContainer, {
      [customStyle || '']: customStyle
    })}
  >
    <label htmlFor={id}>
      {isRequired && '* '}
      {label}
    </label>
    <input
      className={classNames(styles.TextField, {
        [styles.Error]: !isValid
      })}
      placeholder={placeholder}
      id={id}
      onChange={onChange}
      value={textValue}
      disabled={readonly}
    />
    {!isValid && <FormValidationError errorMessage={validationMessage} />}
  </div>
)
