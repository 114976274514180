/* eslint-disable react/require-default-props */
import { t } from '@lingui/macro'
import React, { FC } from 'react'
import { scrollElementInToview } from '../../helpers/scrollElementInToView'
import { scrollToTop } from '../../helpers/scrollToTop'
import Button, { ButtonType } from '../buttons/button/Button'
import IconTextButton, {
  ButtonType as IconButtonType
} from '../buttons/TextIconButton'
import ArrowIcon from './ArrowIcon'
import styles from './LoadMoreSection.module.scss'

interface Props {
  onClick: () => void
  currentPage: number
  totalPages: number
  scrollId?: string
}
const LoadMoreSection: FC<Props> = ({
  onClick,
  currentPage,
  totalPages,
  scrollId
}) => {
  const showMoreButton = totalPages >= currentPage
  const scrollAction = () => {
    if (scrollId && scrollId !== '') {
      return scrollElementInToview(scrollId)
    }
    return scrollToTop()
  }

  return totalPages > 1 ? (
    <div className={styles.LoadMoreSection}>
      {showMoreButton && (
        <Button
          type={ButtonType.GhostWithBoarder}
          label={t`More`}
          buttonSize="Medium"
          onClick={onClick}
        />
      )}
      {currentPage > 1 && (
        <IconTextButton
          id="up-arrow"
          type={IconButtonType.Ghost}
          label=""
          buttonSize="Medium"
          onClick={scrollAction}
        >
          <ArrowIcon />
        </IconTextButton>
      )}
    </div>
  ) : null
}
export default LoadMoreSection
