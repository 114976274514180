import Modo from './Images/Modo.png'
import Dif from './Images/dif.png'
import Partille from './Images/Partille.png'
import { Testimonials } from './Testimonials.interface'
export const TestimonialsMockData: Testimonials[] = [
  {
    id: 'frank-testimonial',
    quote:
      'We have never experienced it so smoothly to broadcast live, or to get current content out quickly. Solid sports is the best platform we have been on.',

    association: 'Modo',
    name: 'Frank Eriksson',
    title: 'Head of Communications Modo Hockey',
    imagePath: Modo
  },
  {
    id: 'victor-testimonial',
    quote:
      'Every penny we get from this is of course very important, but it has also given everyone something to enjoy during a very tough time.',
    association: 'Degerfors',
    name: 'Victor Lundmark',
    title: 'Press and communications manager at Degerfors IF',
    imagePath: Dif
  },
  {
    id: 'fredrik-testimonial',
    quote:
      'A lot of people get the opportunity to take part in the world´s biggest and best handball tournament from TV couches around the handball world.',
    association: 'Partille cup',
    name: 'Fredrik Lundgren',
    title: 'Sales and marketing manager for Partille Cup.',
    imagePath: Partille
  }
]
