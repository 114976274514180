import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Featured, Sport, League, Cup } from './cardTypes'

import ContentTypeSelector from './ContentTypeSelector'
import styles from './TallThumbnail.module.scss'

interface Props {
  content: Featured | Sport | League | Cup
}

const TallThumbnail: FC<Props> = ({ content }) => {
  return (
    <Link className={styles.ThumbnailLink} to={content.targetUrl}>
      <div className={styles.Container}>
        <ContentTypeSelector content={content} />
      </div>
    </Link>
  )
}
export default TallThumbnail
