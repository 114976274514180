import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
export const ContentError: FC = () => {
  return (
    <div>
      <Trans>
        there was an error loading information about your content, please
        refresh your page to try again
      </Trans>
    </div>
  )
}
