import { Dispatch } from 'redux'
import {
  fetchStreamError,
  fetchStreamStart,
  fetchStreamSuccess
} from '../../../redux-store/stream/stream.actions'
import { ViewerParameters } from '../viewerPage.interfaces'
import { getVideoPlayerData } from './getVideoPlayerData'

export const createGetStreamActions = async (
  dispatch: Dispatch<any>,
  mediaObjectParams: ViewerParameters
) => {
  try {
    dispatch(fetchStreamStart())
    const videoPlayerData = await getVideoPlayerData(mediaObjectParams)
    // dispatch(fetchStream404Error())
    dispatch(fetchStreamSuccess(videoPlayerData))
  } catch (err: any) {
    dispatch(fetchStreamError(err))
  }
}
