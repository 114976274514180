import React, { FC, useState, useEffect } from 'react'
import ContentSlider from '../../sliders/ContentSlider'

import styles from './RecommendedSection.module.scss'

import { getRecommendedContent } from '../../../api/graphql/getRecommendedContent'
import { LinkThumbnail } from '../../cards/linkThumbnail/LinkThumbnail'
import useCurrentUser from '../../../hooks/CurrentUser'
import { getCurrentLocale } from '../../../translations/i18n'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import { LinkThumbnailPreloader } from 'components/cards/linkThumbnail/LinkThumbnailPreloader'

export interface RecommendedContentItem {
  id: string
  link: string
  title: string
  thumbnail: {
    url: string
  }
  updatedAt: string
}

interface RecommendedSectionProps {
  channelSlug: string
}

export const RecommendedSection: FC<RecommendedSectionProps> = ({
  channelSlug
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recommendedContent, setRecommendedContent] = useState<
    RecommendedContentItem[]
  >([])
  const currentUser = useCurrentUser()
  const [locale] = useState(currentUser?.locale || getCurrentLocale())
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint < Breakpoint.sm
  const width = isMobile ? 343 : 603
  const height = isMobile ? 193 : 340

  useEffect(() => {
    setIsLoading(true)
    getRecommendedContent(channelSlug, locale).then((data) => {
      setRecommendedContent(data)
      setIsLoading(false)
    })
  }, [locale, channelSlug])

  if (isLoading) {
    return (
      <div className={styles.RecommendedContentContainer}>
        <ContentSlider leftPadding>
          <LinkThumbnailPreloader />
        </ContentSlider>
      </div>
    )
  }

  return recommendedContent?.length > 0 ? (
    <div className={styles.RecommendedContentContainer}>
      <ContentSlider leftPadding>
        {recommendedContent.map((item) => {
          const backgroundImage = `${item.thumbnail.url}?w=${width}&h=${height}`
          return (
            <LinkThumbnail
              label={item.title}
              link={item.link}
              backgroundImage={backgroundImage}
              key={`RecommendedContentItem__${item.id}`}
            />
          )
        })}
      </ContentSlider>
    </div>
  ) : null
}
