import { useMemo } from 'react'
import { getArticleData } from '../../../../api/channel/getArticleData'
import { getRelatedArticles } from '../../../../api/channel/getRelatedArticles'
import { useDataApi } from '../../../../api/useDataApi'

const useArticleDataFetch = (
  slug: string | undefined,
  articleId: string | undefined
) => {
  const articleDataParam = useMemo(
    () => ({ channelSlug: slug, articleSlug: articleId }),
    [slug, articleId]
  )
  const articleData = useDataApi({
    apiCall: getArticleData,
    parameters: articleDataParam
  })

  const relatedArticlesParam = useMemo(
    () => ({ slug, articleId }),
    [slug, articleId]
  )
  const relatedArtilces = useDataApi({
    apiCall: getRelatedArticles,
    parameters: relatedArticlesParam
  })

  return { articleData, relatedArtilces }
}

export default useArticleDataFetch
