import React, { FC } from 'react'

export const FlagIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66602 10.0007C2.66602 10.0007 3.33268 9.33398 5.33268 9.33398C7.33268 9.33398 8.66602 10.6673 10.666 10.6673C12.666 10.6673 13.3327 10.0007 13.3327 10.0007V2.00065C13.3327 2.00065 12.666 2.66732 10.666 2.66732C8.66602 2.66732 7.33268 1.33398 5.33268 1.33398C3.33268 1.33398 2.66602 2.00065 2.66602 2.00065V10.0007Z"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66602 14.6667V10"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
