export const createTeamAbbreviation = (name: string) => {
  if (name === null || name === '' || !name) {
    return '-'
  }

  const words = name.split(' ')

  if (words.length >= 3) {
    const firstLetters = words.map((word: string) => word.substring(0, 1))
    const firstLettersString = firstLetters.join()
    return firstLettersString.replaceAll(',', '').substring(0, 3).toUpperCase()
  }
  return (name + '   ').substring(0, 3).toUpperCase()
}
