import React, { FC } from 'react'
import { t } from '@lingui/macro'
import Button, { ButtonType } from 'components/buttons/button/Button'
import styles from './ViewAsAdminButton.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import rolesActions from 'redux-store/roles/roles.actions'
import { RootState } from 'redux-store/store'
import useAdminAccess from 'hooks/AdminAccess'
import { streamSelector } from 'redux-store/stream/stream.selectors'

export const ViewAsAdminButton: FC = () => {
  const dispatch = useDispatch()
  const adminAccess = useAdminAccess()
  const stream = useSelector(streamSelector)
  const isGeoBlocked = stream?.data.streamUrl?.geoBlock
  const showAdminButtons = adminAccess.hasChannelAccess && !isGeoBlocked

  const roles = useSelector((state: RootState) => state.roles)
  const isViewingAsAdmin = roles.isViewingAsAdmin
  const label = isViewingAsAdmin ? t`Show as user` : t`Show as admin`

  const handleClick = () => {
    if (isViewingAsAdmin) {
      dispatch(rolesActions.setViewingAsUser())
    } else {
      dispatch(rolesActions.setViewingAsAdmin())
    }
  }
  if (showAdminButtons) {
    return (
      <Button
        data-testid="admin-toggle-view-button"
        type={ButtonType.GhostInverted}
        customStyle={styles.AdminButton}
        buttonSize="Small"
        label={label}
        onClick={handleClick}
      />
    )
  }
  return null
}
