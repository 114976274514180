import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getEventGroups } from 'api/multistageTournament/getEventGroups'
import { CategoryFilterType } from 'features/multistageTournaments/CategoryFilter/CategoryFilter'
import { FilterItem, FilterList } from './FilterList/FilterList'
import { FilterType } from './FilterItem/FilterItem'
import { FilterTitle } from './FilterTitle/FilterTitle'
import { getFilterItemFromData } from './getFilterItemFromData'
import { FilterHeader } from './FilterHeader/FilterHeader'
import { getEventGroupsChildren } from 'api/multistageTournament/getEventGroupsChildren'
import { FilteredContent } from 'features/multistageTournaments/FilteredContent/FilteredContent'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'
import styles from './HierarchicalFilter.module.scss'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'
interface Props {
  channelSlug: string
  filterType: CategoryFilterType
  backgroundType: FilteredGamesListType
  eventData: ChannelEvent
}

export const EventClassesFilter: FC<Props> = ({
  channelSlug,
  filterType,
  backgroundType,
  eventData
}) => {
  const history = useHistory()
  const { eventClass, group } = useParams<{
    group?: string
    eventClass?: string
  }>()
  const eventSlug = eventData.eventSlug
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])

  const {
    data,
    isLoading,
    isError,
    refetch
  }: {
    data: any | undefined
    isLoading: boolean
    isError: boolean
    refetch: () => void
  } = useQuery({
    // @ts-ignore
    queryKey: [`get-event-classes-${eventSlug}`],

    queryFn: () =>
      getEventGroups({
        eventSlug: eventSlug,
        slug: channelSlug
      })
  })
  const {
    data: eventGroupsData,
    isLoading: eventGroupsDataIsLoading,
    isError: eventGroupsDataIsError
  }: {
    data: any | undefined
    isLoading: boolean
    isError: boolean
  } = useQuery({
    queryKey: [`get-event-groups-${eventClass}`],

    queryFn: () =>
      getEventGroupsChildren({
        eventSlug: eventSlug,
        eventClassSlug: eventClass!,
        slug: channelSlug
      }),

    enabled: !!eventClass
  })
  useEffect(() => {
    clearFiltersSelected()
  }, [eventSlug])

  useEffect(() => {
    if (eventClass && !group && data) {
      const selectedClass = getFilterItemFromData(data?.data, eventClass)
      if (selectedClass !== undefined) {
        setSelectedFilters([...selectedFilters, selectedClass])
      }
    }
  }, [data])

  useEffect(() => {
    if (group && eventClass && eventGroupsData && data) {
      const selectedClass = getFilterItemFromData(data?.data, eventClass)
      const selectedGroup = getFilterItemFromData(eventGroupsData?.data, group)
      if (selectedGroup && selectedClass) {
        setSelectedFilters([])
        setSelectedFilters([...selectedFilters, selectedClass, selectedGroup])
      }
    }
  }, [eventGroupsData])
  const clearFiltersSelected = () => {
    setSelectedFilters([])
  }

  const handleOnFilterClick = (filterSlug: string) => {
    if (data) {
      const selectedFilterData = getFilterItemFromData(data?.data, filterSlug)
      if (selectedFilterData) {
        setSelectedFilters((arr) => [...arr, selectedFilterData])
        history.push(
          `/${channelSlug}/games/event/${eventSlug}/classes/${selectedFilterData.slug}`
        )
      }
    }
    if (eventGroupsData && eventClass) {
      const selectedFilterData = getFilterItemFromData(
        eventGroupsData?.data,
        filterSlug
      )
      if (selectedFilterData) {
        setSelectedFilters((arr) => [...arr, selectedFilterData])
        history.push(
          `/${channelSlug}/games/event/${eventSlug}/classes/${eventClass}/groups/${selectedFilterData.slug}`
        )
      }
    }
  }

  const removeSelectedFilter = () => {
    setSelectedFilters((arr) => arr.slice(0, -1))
    if (group) {
      history.push(
        `/${channelSlug}/games/event/${eventSlug}/classes/${eventClass}`
      )
    } else {
      history.push(`/${channelSlug}/games/event/${eventSlug}/classes`)
      clearFiltersSelected()
    }
    refetch()
  }
  const handleOnBackClick = () => {
    history.push(`/${channelSlug}/games/event/${eventSlug}/classes`)
    clearFiltersSelected()
  }

  return (
    <div data-testid="event-classes-filter">
      <FilterTitle title={t`Filter ${eventData.name} games by classes`} />
      <FilterHeader
        selectedFilters={selectedFilters}
        filterType={filterType}
        onBackClick={handleOnBackClick}
        onRemoveFilter={removeSelectedFilter}
      />
      {selectedFilters?.length < 1 && (
        <FilterList
          isError={isError}
          isLoading={isLoading}
          list={data?.data!}
          filterItemType={FilterType.Primary}
          onClick={(filterSlug) => handleOnFilterClick(filterSlug)}
        />
      )}
      {selectedFilters?.length === 1 && eventClass !== '' && (
        <div className={styles.FilterList}>
          <FilterList
            list={eventGroupsData?.data!}
            isLoading={eventGroupsDataIsLoading}
            isError={eventGroupsDataIsError}
            filterItemType={FilterType.Primary}
            onClick={(filterSlug) => handleOnFilterClick(filterSlug)}
          />
        </div>
      )}
      {selectedFilters?.length > 0 && (
        <div className={styles.ContentContainer}>
          <FilteredContent
            filterType={filterType}
            selectedFilter={selectedFilters[selectedFilters.length - 1].slug}
            eventSlug={eventSlug}
            channelSlug={channelSlug}
            type={backgroundType}
          />
        </div>
      )}
    </div>
  )
}
