import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface AdminDetailSettings {
  channelSlug: string
  channelTitle: string
  channelUrl: string
  channelTimeZone: string
}

interface AdminDetailSettingsRequestApi {
  company: string
  channel_title: string
  channel_time_zone: string
}

interface AdminDetailSettingsResponse {
  hasError: boolean
  errors?: any
}

export const patchAdminDetails = async (
  adminDetailSettings: AdminDetailSettings
): Promise<AdminDetailSettingsResponse> => {
  const params: AdminDetailSettingsRequestApi = {
    company: adminDetailSettings.channelSlug,
    channel_title: adminDetailSettings.channelTitle,
    channel_time_zone: adminDetailSettings.channelTimeZone
  }
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `/companies/${adminDetailSettings.channelSlug}/admin_details`,
      method: ApiEndpoint.METHODS.PATCH,
      params,
      authenticatable: true
    })
    await response

    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: []
    }
  }
}
