import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface PostRefereeAccessRequest {
  accessInviteCode: string
  integrationId: number
  ident: string
}

interface Response {
  hasError: boolean
  codeIsValid?: boolean
  errors?: any
}
export const postRefereeAccess = async (
  request: PostRefereeAccessRequest
): Promise<Response> => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `session/access_invite/${request.integrationId}/${request.ident}/confirm`,
      method: ApiEndpoint.METHODS.POST,
      params: { code: request.accessInviteCode }
    })
    await response
    return { hasError: false, codeIsValid: true }
  } catch (error: any) {
    if (error.status === 400) return { hasError: false, codeIsValid: false }
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
