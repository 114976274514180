import React, { FC, useEffect, useState } from 'react'
import { getEventGamesFromLocation } from 'api/multistageTournament/getEventGamesFromLocation'
import { useQuery } from '@tanstack/react-query'
import { CategoryFilterType } from 'features/multistageTournaments/CategoryFilter/CategoryFilter'
import { EventCardList } from 'features/multistageTournaments/EventCardList/EventCardList'
import { Loader } from 'components/Loader/Loader'
import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import { getEventGamesFromGroup } from 'api/multistageTournament/getEventGamesFromGroup'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'

interface Props {
  filterType: CategoryFilterType
  type: FilteredGamesListType
  selectedFilter: string
  eventSlug: string
  channelSlug: string
}
const PER_PAGE_COUNT = 25
export const FilteredContent: FC<Props> = ({
  filterType,
  type,
  selectedFilter,
  eventSlug,
  channelSlug
}) => {
  const now = Date.now()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [dataToDisplay, setDataToDisplay] = useState<
    GameScheduleEventCardType[]
  >([])
  const apiToUse =
    filterType === CategoryFilterType.Locations
      ? getEventGamesFromLocation({
          channelSlug: channelSlug,
          eventSlug: eventSlug,
          selectedLocation: selectedFilter,
          currentPage: currentPage,
          perPage: PER_PAGE_COUNT
        })
      : getEventGamesFromGroup({
          channelSlug: channelSlug,
          eventSlug: eventSlug,
          selectedGroup: selectedFilter,
          currentPage: currentPage,
          perPage: PER_PAGE_COUNT
        })
  const {
    data,
    isLoading,
    isError
  }: {
    data: any
    isLoading: boolean
    isError: boolean
  } = useQuery({
    queryKey: [
      `get-event-games-filtered-${selectedFilter}-${eventSlug}`,
      currentPage
    ],
    queryFn: () => apiToUse
  })
  useEffect(() => {
    setDataToDisplay([])
  }, [selectedFilter])

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      if (
        data.data?.length > 0 &&
        currentPage < 2 &&
        dataToDisplay.find((d) => d.id === data.data[0].id)
      ) {
        setDataToDisplay(data.data)
      } else {
        setDataToDisplay((prev) => [...prev, ...data.data])
      }
      setPageCount(data.totalCount! / PER_PAGE_COUNT)
    }
  }, [isLoading, isError, data, currentPage])
  if (isLoading && dataToDisplay.length < 1) {
    return <Loader />
  }
  return (
    <>
      {dataToDisplay.length >= 0 && (
        <EventCardList
          eventList={dataToDisplay}
          type={type}
          now={now}
          totalPages={pageCount}
          isLoading={isLoading}
          currentPage={currentPage}
          increasePageNumber={() =>
            setCurrentPage((prevValue) => prevValue + 1)
          }
        />
      )}
    </>
  )
}
