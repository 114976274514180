import React, { FC } from 'react'
import { ProsItem, ProsListItem } from './ProsItem/ProsItem'
import styles from './ProsList.module.scss'
interface Props {
  prosList: ProsListItem[]
}

export const ProsList: FC<Props> = ({ prosList }) => {
  return prosList && prosList.length > 0 ? (
    <div className={styles.ProsList}>
      {prosList.map((item: ProsListItem) => (
        <ProsItem icon={item.icon} text={item.text} key={item.id} />
      ))}
    </div>
  ) : null
}
