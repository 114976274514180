import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { ActivateLivestream } from './variants/ActivateLivestream'
import { AlreadyActivated } from './variants/AlreadyActivated'
import { Loader } from '../../../../../components/Loader/Loader'
import { UnexpectedError } from './variants/UnexpectedError'
import { SuccessfulActivation } from './variants/SuccessfulActivation'
import { DefaultPrices } from './defaultPrices'
import { Game } from '../../../../../interfaces'

import styles from './ActivateLivestreamModal.module.scss'

interface Props {
  game: Game
  isAlreadyActivated: boolean
  isLoading: boolean
  isError: boolean
  isSuccessfullyActivated: boolean
  hasActivationValidationError: boolean
  numberOfStreams: number
  defaultPrices?: DefaultPrices
  onActivateLivestream: () => void
  onClose: () => void
  isMobile: boolean
}

export const ActivationContent: FC<Props> = ({
  isMobile,
  isSuccessfullyActivated,
  numberOfStreams,
  defaultPrices,
  onActivateLivestream,
  game,
  hasActivationValidationError,
  isAlreadyActivated,
  isLoading,
  isError,
  onClose
}) => {
  const unexpectedErrorDescription = hasActivationValidationError
    ? t`We couldn't publish the game. If this problem persists, please contact our support`
    : t`Something went wrong when fetching the game data. Close this popup and try again.`

  if (isError || hasActivationValidationError)
    return (
      <UnexpectedError
        contentDescription={unexpectedErrorDescription}
        onClose={onClose}
      />
    )
  if (isLoading) {
    return (
      <div className={styles.LoadingContainer}>
        <Loader />
      </div>
    )
  }
  if (isAlreadyActivated) {
    return <AlreadyActivated game={game} onClose={onClose} />
  }
  return isSuccessfullyActivated ? (
    <SuccessfulActivation onClose={onClose} numberOfStreams={numberOfStreams} />
  ) : (
    <ActivateLivestream
      game={game}
      isMobile={isMobile}
      prices={defaultPrices}
      onActivateLivestream={onActivateLivestream}
      onClose={onClose}
    />
  )
}
