import React, { FC } from 'react'
import ContentSlider from '../../sliders/ContentSlider'
import { BroadcastThumbnailPreloader } from 'components/preloaders/BroadcastThumbnailPreloader/BroadcastThumbnailPreloader'
import styles from './TitleThumbnailSection.module.scss'
import { MenuPreloader } from 'components/preloaders/MenuPreloader/MenuPreloader'

interface Props {
  useLeftPadding?: boolean
  size: 'small' | 'medium' | 'large' | 'xLarge'
}
export const TitleThumbnailSectionPreloader: FC<Props> = ({
  useLeftPadding = true,
  size
}) => {
  return (
    <div className={styles.TitleThumbnailSection}>
      <div className={styles.PreloaderTitle}>
        <MenuPreloader loaderCount={1} />
      </div>
      <div className={styles.ThumbnailSlider}>
        <ContentSlider leftPadding={useLeftPadding}>
          <div
            className={styles.Content}
            data-testid="thumbnail-container-preloader"
          >
            {Array.from({ length: 3 }, (_, i) => (
              <BroadcastThumbnailPreloader size={size} key={i} />
            ))}
          </div>
        </ContentSlider>
      </div>
    </div>
  )
}
