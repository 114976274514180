import React, { FC } from 'react'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'

import { Highlights } from '../../../interfaces/Highlights.interface'

import SectionGridVideos from './SectionGridVideos'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

type GroupedList = {
  [title: string]: Highlights[]
}
interface Props {
  list: GroupedList
}

const ChronologicalVideoList: FC<Props> = ({ list }) => {
  const videoList = []
  const getTodayTomorrowText = (day: dayjs.Dayjs) => {
    return day.isToday() ? t`Today` : t`Tomorrow`
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(list)) {
    const day = dayjs(key)
    const title =
      day.isToday() || day.isTomorrow()
        ? getTodayTomorrowText(day)
        : day.format('D MMMM')
    videoList.push(<SectionGridVideos key={key} title={title} list={value} />)
  }

  return <div>{videoList}</div>
}

export default ChronologicalVideoList
