import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './BroadcastThumbnailPreloader.module.scss'

interface Props {
  size: 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
}

export const BroadcastThumbnailPreloader: FC<Props> = ({ size }) => {
  return (
    <div className={classNames(styles.PreLoaderContainer, styles[size])}>
      <div className={styles.Thumbnail}>
        <div className={classNames(styles.Loader, styles.InnerContainer)} />
      </div>
    </div>
  )
}
