import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

export interface CustomLinkResponseApi {
  custom_link_text: string
  custom_link_href: string
  custom_link_target: string
}
export interface CustomLink {
  text: string
  link: string
  target: LinkTarget
}
export const getCustomTopNavigationLink = async (
  channelSlug: string,
  disableCache: boolean
): Promise<CustomLink> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/custom_link`,
      authenticatable: disableCache
    })
    const responseData = await resultResponse
    if (responseData.data !== null) {
      const mapped = mapCustomLinks(responseData.data)
      return mapped
    }
    return responseData.data
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
export const mapCustomLinks = (data: CustomLinkResponseApi): CustomLink => ({
  text: data.custom_link_text,
  link: data.custom_link_href,
  // @ts-ignore
  target: data.custom_link_target
})
