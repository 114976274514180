import React, { FC } from 'react'
import { TeamsAndScore, EndResult } from '../TeamAndScore/TeamsAndScore'
import { DecideLink } from './DecideLink'
import styles from './TeamsContainer.module.scss'

interface Props {
  homeTeamLogo: string
  homeTeamName: string
  homeTeamScore: number
  awayTeamLogo: string
  awayTeamName: string
  awayTeamScore: number
  hasBeenPlayed: boolean
  isLive: boolean
  showScore: boolean
  gameSlug: string
  hasLivestream: boolean
  rootDomain?: string
}
export const TeamsContainer: FC<Props> = ({
  homeTeamLogo,
  homeTeamName,
  homeTeamScore,
  awayTeamLogo,
  awayTeamName,
  awayTeamScore,
  hasBeenPlayed,
  isLive,
  showScore,
  gameSlug,
  hasLivestream,
  rootDomain
}) => {
  const homeWin = homeTeamScore > awayTeamScore
  const awayWin = awayTeamScore > homeTeamScore
  const equal = homeTeamScore === awayTeamScore

  const setEndResultHomeTeam = () => {
    if (hasBeenPlayed) {
      if (homeWin) {
        return EndResult.Win
      }
      if (awayWin) {
        return EndResult.Loss
      }
      if (equal) {
        return EndResult.Equal
      }
    }
    return EndResult.Default
  }
  const setEndResultAwayTeam = () => {
    if (hasBeenPlayed) {
      if (homeWin) {
        return EndResult.Loss
      }
      if (awayWin) {
        return EndResult.Win
      }
      if (equal) {
        return EndResult.Equal
      }
    }
    return EndResult.Default
  }

  return (
    <div className={styles.TeamContainer} data-testid="team-container">
      <DecideLink
        gameSlug={gameSlug}
        hasLivestream={hasLivestream}
        rootDomain={rootDomain}
      >
        <div className={styles.HomeTeam}>
          <TeamsAndScore
            teamLogo={homeTeamLogo}
            teamName={homeTeamName}
            score={homeTeamScore}
            isLive={isLive}
            result={setEndResultHomeTeam()}
            hasBeenPlayed={hasBeenPlayed}
            showScore={showScore}
          />
        </div>
        <div className={styles.AwayTeam}>
          <TeamsAndScore
            teamLogo={awayTeamLogo}
            teamName={awayTeamName}
            score={awayTeamScore}
            isLive={isLive}
            result={setEndResultAwayTeam()}
            hasBeenPlayed={hasBeenPlayed}
            showScore={showScore}
          />
        </div>
      </DecideLink>
    </div>
  )
}
