import React, { FC } from 'react'
import IconTextButton, { ButtonType } from '../TextIconButton'
import { CreateNewAccountIcon } from 'assets/icons/CreateNewAccountIcon'
import { t } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
interface Props {
  isRounded?: boolean
  buttonType?: ButtonType
}
export const OpenSignupButton: FC<Props> = ({
  isRounded = false,
  buttonType = ButtonType.Ghost
}) => {
  const dispatch = useDispatch()
  const handleCreateNewAccountClick = () => {
    dispatch(authenticationActions.openLoginLayer(undefined, 'signup'))
  }
  return (
    <IconTextButton
      type={buttonType}
      onClick={handleCreateNewAccountClick}
      id={'create-account-button'}
      buttonSize={'Medium'}
      label={t`Create new account`}
      isFluid
      isRounded={isRounded}
    >
      <CreateNewAccountIcon />
    </IconTextButton>
  )
}
