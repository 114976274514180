import React, { FC } from 'react'
import { FacebookIcon } from './FacebookIcon'
import { InstagramIcon } from './InstagramIcon'
import { TwitterIcon } from './TwitterIcon'

import styles from './SocialMedia.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface Props {
  socialMedia: any
}

export const SocialMedia: FC<Props> = ({ socialMedia }) => {
  const { facebook, twitter, instagram } = socialMedia
  return (
    <div className={styles.SocialMediaContainer}>
      {facebook && (
        <a href={facebook} target={LinkTarget.NewWindow}>
          <FacebookIcon />
        </a>
      )}
      {twitter && (
        <a href={twitter} target={LinkTarget.NewWindow}>
          <TwitterIcon />
        </a>
      )}
      {instagram && (
        <a href={instagram} target={LinkTarget.NewWindow}>
          <InstagramIcon />
        </a>
      )}
    </div>
  )
}
