import React, { FC, useState } from 'react'

import { t, Trans } from '@lingui/macro'
import { Form, Field, Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'

import { Loader } from '../../../../components/Loader/Loader'
import { useDataApi } from '../../../../api/useDataApi'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import { getReportTypes } from '../../../../api/viewer/reportContent/getReportTypes'
import {
  PostReportContent,
  postReportContent
} from '../../../../api/viewer/reportContent/postReportContent'
import { CheckboxGroup } from './CheckboxGroup'

import styles from './ReportContent.module.scss'
import {
  browserName,
  browserVersion,
  isAndroid,
  isIOS
} from 'react-device-detect'

interface Props {
  gameIdent?: string
  mediaIdent: string
  channelSlug: string
  onSuccess: () => void
}

export const ReportContentForm: FC<Props> = ({
  gameIdent,
  mediaIdent,
  channelSlug,
  onSuccess
}) => {
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined
  )
  const { isLoading, data: reportTypes } = useDataApi({
    apiCall: getReportTypes,
    initialData: []
  })

  const initialValues: any = {
    messageText: '',
    reportType: undefined
  }

  const reportContentSchema = yup.object().shape({
    messageText: yup.string(),
    reportType: yup.string()
  })

  const handleSubmit = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)

    const requestParam: PostReportContent = {
      userMessage: values.messageText,
      ticketType: values.reportType,
      mediaObjectIdent: mediaIdent,
      gameIdent: gameIdent,
      platform: 'browser',
      channelSlug,
      referrer: window.location.href,
      userAgent: navigator?.userAgent!,
      browser: browserName,
      browserVersion: browserVersion,
      isAndroid: isAndroid,
      isIOS: isIOS,
      browser_width: window.innerWidth.toString(),
      browser_height: window.innerHeight.toString(),
      screenWidth: screen.width.toString(),
      screenHeight: screen.height.toString()
    }

    const response = await postReportContent(requestParam)

    formikHelpers.setSubmitting(false)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      onSuccess()
      formikHelpers.resetForm()
    }
  }

  return (
    <div className={styles.ReportContentFormContainer}>
      <h2>
        <Trans>Report stream</Trans>
      </h2>
      <div className={styles.FormDescription}>
        <Trans>Please report any offensive and innaccurate content</Trans>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={reportContentSchema}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<any>) => {
          return (
            <Form>
              {isLoading && !reportTypes && reportTypes?.length < 1 ? (
                <Loader />
              ) : (
                <div className={styles.FormOptionsContainer}>
                  <div className={styles.CheckboxGroup}>
                    <Field
                      component={CheckboxGroup}
                      reportTypes={reportTypes}
                      selectedItem={selectedItem}
                      onChange={(value: string | undefined) => {
                        setSelectedItem(value)
                        props.setFieldValue('reportType', value)
                      }}
                    />
                  </div>
                  <div
                    data-testid="report-form-message-container"
                    className={styles.MessageContainer}
                  >
                    <Field
                      component="textarea"
                      id="messageText"
                      name="messageText"
                      className={styles.MessageText}
                      placeholder={t`Leave a message`}
                    />
                  </div>

                  <div className={styles.ButtonContainer}>
                    <div data-testid="report-form-submit-button-container">
                      <SubmitButton
                        label={t`Report`}
                        disabled={!props.dirty || props.isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
