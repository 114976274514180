import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import React, { FC } from 'react'
import { LoginContent } from './LoginContent'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSignupClick: () => void
  onFormSuccess: () => void
  onForgotPasswordClick: () => void
}

export const LoginModal: FC<Props> = ({
  isOpen,
  onClose,
  onFormSuccess,
  onSignupClick,
  onForgotPasswordClick
}) => {
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={onClose}
      firstContainerContent={
        <LoginContent
          onForgotPasswordClick={onForgotPasswordClick}
          onSignupClick={onSignupClick}
          onFormSuccess={onFormSuccess}
        />
      }
      firstContainerTheme={ModalTheme.Primary}
    />
  )
}
