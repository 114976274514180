interface Domain {
  host: string
  facebook: boolean
  apple: boolean
  facebookUid?: string
}

const availableDomains: Domain[] = [
  // Facebook production app - "Solidtango"
  { host: 'solidtango.com', facebook: true, apple: true },
  {
    host: 'solidsport.com',
    facebook: true,
    apple: true,
    facebookUid: '268378253696636'
  },

  // powered by
  {
    host: 'baskettv.se',
    facebook: true,
    apple: false,
    facebookUid: '1575388742965818'
  },
  { host: 'live.hvnb-online.de', facebook: false, apple: false },
  {
    host: 'handbollplay.se',
    facebook: true,
    apple: false,
    facebookUid: '775311980307130'
  },
  {
    host: 'mffplay.se',
    facebook: true,
    apple: false,
    facebookUid: '1644546316016638'
  },

  {
    host: 'poweredbysolidsport.com',
    facebook: true,
    apple: false,
    facebookUid: '1386323212166361'
  },
  { host: 'sttest.se', facebook: false, apple: false },

  // Facebook testing app - "Solidtango - Test"
  { host: 'local.stdev.se', facebook: true, apple: true },
  {
    host: 'ststage.se',
    facebook: true,
    apple: true,
    facebookUid: '1386323212166361'
  },
  { host: 'stdev.se', facebook: true, apple: true },

  { host: 'auth.stdev.se', facebook: false, apple: false },
  { host: 'sportstage.se', facebook: true, apple: true },
  { host: 'auth.ststage.se', facebook: false, apple: false },
  { host: 'testing1.se', facebook: false, apple: false },
  { host: 'testing2.se', facebook: false, apple: false },

  // Other, subdomains of enabled domains
  {
    host: 'local.sportstage.se:3000',
    facebook: true,
    apple: true
  },
  {
    host: 'prby.sportstage.se:3000',
    facebook: true,
    apple: false
  },
  // Storybook
  { host: 'localhost:6006', facebook: true, apple: true }
]

export const getFacebookLoginId = () => {
  const result = availableDomains.find((obj) => {
    return obj.host === window.location.host
  })
  if (result === undefined) {
    return process.env.REACT_APP_FACEBOOK_API_ID
  }
  return result.facebookUid! || process.env.REACT_APP_FACEBOOK_API_ID
}

export const getIsFacebookLoginAvailable = () =>
  availableDomains.some((domain: Domain) => {
    return domain.host === window.location.host && domain.facebook
  })

export const getIsAppleLoginAvailable = () =>
  availableDomains.some((domain: Domain) => {
    return domain.host === window.location.host && domain.apple
  })
