import React, { FC } from 'react'
import { PlayStreamIcon } from 'assets/icons/PlayStreamIcon'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import styles from './PlayIconButton.module.scss'

interface Props {
  onClick: () => void
}

export const PlayIconButton: FC<Props> = ({ onClick }) => (
  <IconButton
    customStyle={styles.PlayStreamButton}
    id="play-icon-button"
    onClick={onClick}
  >
    <PlayStreamIcon />
  </IconButton>
)
