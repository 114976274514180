import React, { FC } from 'react'
import {
  Association,
  Article,
  Channel,
  Event,
  SearchResult,
  SearchResultType,
  Video
} from './cardTypes'
import SearchResultCardAssociation from './content/SearchResultCardAssociation'
import SearchResultCardArticle from './content/SearchResultCardArticle'
import SearchResultCardChannel from './content/SearchResultCardChannel'
import SearchResultCardEvent from './content/SearchResultCardEvent'
import SearchResultCardVideo from './content/SearchResultCardVideo'

interface Props {
  content: SearchResult
}

export const SearchResultCard: FC<Props> = ({ content }) => {
  switch (content.type) {
    case SearchResultType.Article:
      return <SearchResultCardArticle content={content as Article} />
    case SearchResultType.Association:
      return <SearchResultCardAssociation content={content as Association} />
    case SearchResultType.Channel:
      return <SearchResultCardChannel content={content as Channel} />
    case SearchResultType.Event:
      return <SearchResultCardEvent content={content as Event} />
    case SearchResultType.Video:
      return <SearchResultCardVideo content={content as Video} />

    default:
      return null
  }
}
