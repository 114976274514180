import React, { FC } from 'react'
import { Icon } from '@mui/material'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import styles from './footerItemDropdown.module.scss'
import { useComponentVisible } from '../../../../../helpers/useComponentVisible'

export interface ListItem {
  name: string
  value: string
}

interface FooterItemDropDownProps {
  label: string
  itemList: ListItem[]
  value: string
  onItemSelected: (item: string) => void
}
const FooterItemDropdown: FC<FooterItemDropDownProps> = ({
  label,
  itemList,
  onItemSelected,
  value
}) => {
  const dropdownContainer = document.querySelector(
    `.${styles.FooterItemDropdown}`
  )
  const dropDownRect = dropdownContainer?.getBoundingClientRect()
  const appContainer = document.querySelector('.App')
  const appRect = appContainer?.getBoundingClientRect()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const openDropDown = () => {
    if (!isComponentVisible) {
      setIsComponentVisible(true)
    } else {
      setIsComponentVisible(false)
    }
  }

  const topItem = itemList.filter((list) => list.value === value)

  const newList =
    topItem.length !== 0
      ? [topItem[0]].concat(itemList.slice(0, itemList.length - 1))
      : itemList

  const handleClick = (item: any) => {
    setIsComponentVisible(false)
    onItemSelected(item.value)
  }

  const calculateTopOrBottom = () => {
    if (appRect && dropDownRect) {
      const valueTest = appRect.height - dropDownRect.top - dropDownRect.height
      if (valueTest - 140 >= 0) {
        return 'Content--Bottom'
      }
      if (valueTest - 140 < 0) {
        return 'Content--Top'
      }
    }
    return 'Content--Top'
  }
  const dropDownExpand = calculateTopOrBottom()
  return (
    <div className={styles.FooterItemDropdown} ref={ref}>
      <div
        data-testid="footer-item-language-dropdown"
        className={styles.Button}
        onClick={() => openDropDown()}
        onKeyPress={() => openDropDown()}
        role="button"
        tabIndex={0}
      >
        {label}
        <Icon
          className={styles.Icon}
          fontSize="small"
          component={
            isComponentVisible
              ? KeyboardArrowUpRoundedIcon
              : KeyboardArrowDownRoundedIcon
          }
        />
      </div>
      {isComponentVisible && (
        <div
          data-testid={'dropdown-content'}
          className={[styles.DropdownContent, styles[dropDownExpand]].join(' ')}
        >
          {newList.map((item: ListItem, i: number) => {
            const highlight = item.value === Object.values(value)[0]
            const classes = highlight
              ? `${styles.Highlight} ${styles.Item}`
              : styles.Item
            const labelTestDataId = highlight
              ? 'dropdown-item-higlighted'
              : 'dropdown-item-label'
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.value}${i}`}
                data-testid="footer-language-dropdown-item"
                className={classes}
                onClick={() => handleClick(item)}
                onKeyPress={() => onItemSelected(item.value)}
                role="button"
                tabIndex={0}
              >
                <div
                  className={styles.LanguageLabel}
                  data-testid={labelTestDataId}
                >
                  {item.name}
                </div>
                <Icon
                  className={styles.Checkmark}
                  fontSize="small"
                  component={CheckRoundedIcon}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default FooterItemDropdown
