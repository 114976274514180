export enum TeamItemStatus {
  Active = 'active',
  NotActive = 'not active',
  Disabled = 'disabled'
}
export interface TeamItemInterface {
  teamName: string
  teamSlug: string
  ongoingCompetitions: number
  status: TeamItemStatus
}
export interface TeamItemProps extends TeamItemInterface {
  onClickTeamItem: () => void
  onAddCompetitionToTeam: () => void
}
