import React, { FC } from 'react'
import { DropdownItem } from '../DropdownItem/DropdownItem'

import styles from './dropdownItemList.module.scss'

export interface DropDownListItem {
  label: string
  id: string
  icon?: any
  disabled?: boolean
}

interface Props {
  id: string
  list: DropDownListItem[] | null
  value: string
  onItemSelected: (itemLabel: string, itemId: string) => void
}

export const DropdownItemList: FC<Props> = ({
  id,
  list,
  value,
  onItemSelected
}) => {
  const data = list || []

  return (
    <div id={id} className={styles.DropdownFieldContainer}>
      <div className={styles.DropdownList} data-testid="form-dropdown-list">
        {data.map((item: DropDownListItem) => (
          <DropdownItem
            key={item.id}
            disabled={item.disabled}
            icon={item.icon}
            label={item.label}
            id={item.id}
            selectedId={value}
            onItemSelected={(itemLabel, itemId) =>
              onItemSelected(itemLabel, itemId)
            }
          />
        ))}
      </div>
    </div>
  )
}
