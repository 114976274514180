import React from 'react'
import LandingPageHero from './LandingPageHero'
import styles from './index.module.scss'

const LandingPage = () => (
  <div className={styles.LandingPage}>
    <LandingPageHero />
  </div>
)
export default LandingPage
