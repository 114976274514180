import ContentContainer from 'components/ContentContainer'
import React, { FC } from 'react'
import styles from './ErrorMessage.module.scss'
interface Props {
  title: string
  subText: string
}

export const ErrorMessage: FC<Props> = ({ title, subText }) => {
  return (
    <ContentContainer
      hasLeftRightPadding={true}
      hasTopBottomPadding={true}
      hasLightBackground={true}
    >
      <div className={styles.ErrorContainer}>
        <h4 data-testid="error-title" className={styles.Title}>
          {title}
        </h4>
        <div data-testid="error-sub-text" className={styles.SubText}>
          {subText}
        </div>
      </div>
    </ContentContainer>
  )
}
