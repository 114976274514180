import React, { FC } from 'react'

import './ImageSliderPreloader.scss'
interface ImageSliderPreloaderProps {
  title: string
}

export const ImageSliderPreloader: FC<ImageSliderPreloaderProps> = ({
  title
}) => {
  return (
    <div className="ImageSliderPreloader Container">
      <div className="ContainerTitle">
        <h3>{title}</h3>
      </div>
      <div className="Wrapper">
        <div className="ObjectPreloader object1">
          <div id="one" className="LoaderImage" />
          <div className="BottomContainer">
            <div id="two" className="Category" />
            <div id="three" className="LoaderTitle" />
            <div id="four" className="LoaderContent" />
            <div id="five" className="LoaderTime" />
          </div>
        </div>
        <div className="ObjectPreloader object2">
          <div id="one" className="LoaderImage" />
          <div className="BottomContainer">
            <div id="two" className="Category" />
            <div id="three" className="LoaderTitle" />
            <div id="four" className="LoaderContent" />
            <div id="five" className="LoaderTime" />
          </div>
        </div>
        {window.innerWidth > 812 && (
          <div className="ObjectPreloader">
            <div id="one" className="LoaderImage" />
            <div className="BottomContainer">
              <div id="two" className="Category" />
              <div id="three" className="LoaderTitle" />
              <div id="four" className="LoaderContent" />
              <div id="five" className="LoaderTime" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
