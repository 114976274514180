import React, { FC } from 'react'
import styles from './AccountDetails.module.scss'
import { ContactDetailsForm } from './ContactDetailsForm/ContactDetailsForm'
import { PreferencesForm } from './PreferencesForm/PreferencesForm'
import { DisconnectFromFacebookButton } from 'components/buttons/Facebook/DisconnectFromFacebookButton/DisconnectFromFacebookButton'
import { DeleteAccountSection } from './DeleteAccountSection/DeleteAccountSection'

export const AccountDetails: FC = () => {
  return (
    <div className={styles.AccountDetails}>
      <ContactDetailsForm />
      <PreferencesForm />
      <DisconnectFromFacebookButton />
      <DeleteAccountSection />
    </div>
  )
}
