import React, { FC } from 'react'
import { PlayerStatisticsTableInterface } from '../Table/PlayerStatisticsTable.interface'
import { CategoryLeader } from '../CategoryLeader/CategoryLeader'
import ContentSlider from 'components/sliders/ContentSlider'
import styles from './CategoryLeaderSection.module.scss'
import ContentContainer from 'components/ContentContainer'
import { t } from '@lingui/macro'
export interface CategoryLeader {
  statsIdentifier: string
  title: string
}
interface Props {
  playerStatisticsData: PlayerStatisticsTableInterface
  categoryLeaderList: CategoryLeader[]
}

export const CategoryLeaderSection: FC<Props> = ({
  playerStatisticsData,
  categoryLeaderList
}) => {
  const title = t`Top 5 - ${playerStatisticsData.name}`
  return categoryLeaderList.length > 0 &&
    playerStatisticsData.columns.length > 0 &&
    playerStatisticsData.rows.length > 0 ? (
    <div className={styles.Container} data-testid="category-leaders-contianer">
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={false}
        hasLightBackground={false}
      >
        <h3>{title}</h3>
      </ContentContainer>
      <ContentSlider leftPadding={true}>
        <div className={styles.LeadersWrapper}>
          {categoryLeaderList.map((categoryLeader: CategoryLeader) => (
            <CategoryLeader
              key={categoryLeader.title}
              title={categoryLeader.title}
              statsIdentifier={categoryLeader.statsIdentifier}
              rows={playerStatisticsData.rows}
              columns={playerStatisticsData.columns}
            />
          ))}
        </div>
      </ContentSlider>
    </div>
  ) : null
}
