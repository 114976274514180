import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  actions: {
    start: (key?: any) => void
    success: (data: any) => void
    error: (error?: any) => void
  }
  apiCall: (parameters?: any) => any
  parameters?: any
  makeCall?: boolean
}
export const useStateDataApi = ({
  actions,
  apiCall,
  parameters,
  makeCall = true
}: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actions.start())

      try {
        const response: any = await apiCall(parameters)
        dispatch(actions.success(response))
      } catch (error: any) {
        dispatch(
          actions.error({ context: parameters, message: error?.message })
        )
      }
    }
    if (makeCall) {
      fetchData()
    }
  }, [parameters, apiCall, dispatch, makeCall])
  return null
}
