import React, { FC } from 'react'
import { t } from '@lingui/macro'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import styles from '../ActivateLivestreamModal.module.scss'

interface UnexpectedErrorProps {
  onClose: () => void
  contentDescription: string
}

export const UnexpectedError: FC<UnexpectedErrorProps> = ({
  onClose,
  contentDescription
}) => {
  const contentTitle = t`Something went wrong`

  return (
    <div className={styles.ModalContent}>
      <div className={styles.TopContainer}>
        <div
          className={styles.ContentTitle}
          data-testid="activate-livestream-content-title"
        >
          {contentTitle}
        </div>
        <div className={styles.ContentDescription}>{contentDescription}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          type={ButtonType.Primary}
          buttonSize="Medium"
          label={t`Close`}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
