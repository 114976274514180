/* eslint-disable no-unused-expressions */
import { Trans } from '@lingui/macro'
import React, { FC, FocusEvent, useRef } from 'react'
import styles from './CopyLink.module.scss'

interface CopyLinkProps {
  textValue: string
  onCopy?: null | (() => void)
}

export const CopyLink: FC<CopyLinkProps> = ({ textValue, onCopy }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const copyCodeToClipboard = (id: string) => {
    inputRef?.current?.select()
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textValue)
    } else {
      const copyText = document.getElementById(id) as HTMLInputElement
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }
    if (onCopy) onCopy()
  }
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    event?.target?.select()
  }
  return (
    <div className={styles.CopyLinkContainer} data-testid="copy-link-container">
      <input
        ref={inputRef}
        data-testid="copy-link"
        type="text"
        className={styles.TextField}
        value={textValue}
        onFocus={handleFocus}
        onChange={() => {}}
      />

      <div
        className={styles.CopyBtn}
        role="button"
        onClick={() => {
          copyCodeToClipboard('copy-input-selector')
        }}
        onKeyDown={() => copyCodeToClipboard('copy-input-selector')}
        tabIndex={0}
        data-testid="copy-link-button"
      >
        <Trans>Copy</Trans>
      </div>
    </div>
  )
}

CopyLink.defaultProps = {
  onCopy: null
}
