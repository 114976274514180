export enum FeatureFlagsAvailable {
  FeatureFlags = 'featureFlags',
  FeatureFlagsExpanded = 'featureFlagsExpanded',
  ShowChannelTypeLabel = 'showChannelTypeLabel',
  VideoDebug = 'videoDebug',
  ShowChannelAdminGuidance = 'showChannelAdminGuidance',
  HideComingSoon = 'hideComingSoon',
  ComingSoon = 'comingSoon',
  ChannelOverviewSetting = 'channelOverviewSetting',
  TesRelativeAPILinks = 'testRelativeAPILinks'
}
