import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { Game } from '../../../../../../interfaces'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import styles from '../ActivateLivestreamModal.module.scss'

interface AlreadyActivatedProps {
  game: Game
  onClose: () => void
}

export const AlreadyActivated: FC<AlreadyActivatedProps> = ({
  game,
  onClose
}) => {
  const gameTitle = game?.title
  const contentTitle = t`This stream is already published`
  const contentDescription = t`The stream ${gameTitle} is already published, and is available for your viewers. Wait a minute and reload the page to see it in the list.`
  return (
    <div className={styles.ModalContent}>
      <div className={styles.TopContainer}>
        <div
          className={styles.ContentTitle}
          data-testid="activate-livestream-content-title"
        >
          {contentTitle}
        </div>
        <div className={styles.ContentDescription}>{contentDescription}</div>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          type={ButtonType.Primary}
          buttonSize="Medium"
          label={t`Got it`}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
