import React, { FC, ReactNode } from 'react'
import { DefaultPrices } from '../defaultPrices'

import styles from './DefaultPriceList.module.scss'

interface Props {
  prices: DefaultPrices
}

export const DefaultPriceList: FC<Props> = ({ prices }) => {
  const pricelist: ReactNode[] = []

  const { length } = Object.entries(prices)
  Object.entries(prices).forEach((item: string[], index: number) => {
    const currency = `${item[0].toString().toUpperCase()}:`
    const priceValue = Number(item[1])
    const price = priceValue.toFixed(2).replace(/\.?0+$/, '')
    pricelist.push(
      <div className={styles.Price} key={item[0]}>
        <div>{currency}</div>
        <div>{price}</div>
        {index !== length - 1 && <div className={styles.Divider}>|</div>}
      </div>
    )
  })

  return <div className={styles.DefaultPricesContainer}>{pricelist}</div>
}
