import React, { FC } from 'react'
import { t } from '@lingui/macro'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { FacebookIcon } from '../FacebookLoginButton/FacebookIcon'
import useCurrentUser from 'hooks/CurrentUser'
import useFacebookAuth from '../useFacebookAuth'
import styles from './DisconnectFromFacebookButton.module.scss'

export const DisconnectFromFacebookButton: FC = () => {
  const facebookAuth = useFacebookAuth()
  const currentUser = useCurrentUser()
  return currentUser && currentUser?.providers.facebook ? (
    <IconTextButton
      type={ButtonType.Ghost}
      buttonSize="Medium"
      label={t`Disconnect from Facebook`}
      onClick={facebookAuth.disconnectUserFromBackend}
      id="facebook-disconnect-button"
      externalStyle={styles.FacebookButton}
    >
      <FacebookIcon />
    </IconTextButton>
  ) : null
}
