import React from 'react'

export const InformationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11.25"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M11.6699 8.28627C11.6699 8.6077 11.9305 8.86827 12.2519 8.86827C12.5734 8.86827 12.8339 8.60769 12.8339 8.28627V8.15427C12.8339 7.83284 12.5734 7.57227 12.2519 7.57227C11.9305 7.57227 11.6699 7.83284 11.6699 8.15427V8.28627ZM11.6939 15.9423C11.6939 16.2504 11.9437 16.5003 12.2519 16.5003C12.5601 16.5003 12.8099 16.2504 12.8099 15.9423V10.6143C12.8099 10.3061 12.5601 10.0563 12.2519 10.0563C11.9437 10.0563 11.6939 10.3061 11.6939 10.6143V15.9423Z"
      fill="#2F3135"
    />
  </svg>
)
