import React, { FC } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import styles from './index.module.scss'

dayjs.extend(duration)
interface Props {
  durationInSeconds: number
}
const BottomTimeLabel: FC<Props> = ({ durationInSeconds }) => {
  const getCorrectLengthFormat = () => {
    if (durationInSeconds >= 3600) {
      return dayjs.duration(durationInSeconds * 1000).format('HH:mm:ss')
    }
    return dayjs.duration(durationInSeconds * 1000).format('mm:ss')
  }

  return <div className={styles.BotomTimeLabel}>{getCorrectLengthFormat()}</div>
}
export default BottomTimeLabel
