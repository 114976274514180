import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'

import SponsorSection from '../../../components/content/SponsorSection'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'

import { Channel } from '../../../api/channel/getChannel'
import { getPastGamesForChannel } from '../../../api/channel/getPastGamesforChannel'
import { getUpcomingBroadcastsForTeams } from '../../../api/channel/getUpcomingBroadcastForTeams'
import { getUpcomingBroadcastsForChannel } from '../../../api/channel/getUpcomingBroadcastsForChannel'
import { getHighlightsForChannel } from '../../../api/channel/getHighlightsForChannel'

import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { NewsSection, PagePosition } from './NewsSection'
import { HighlightsSection } from './HighlightsSection'
import { LatestVideosSection } from './LatestVideosSection'
import { UpcomingStreamsSection } from './UpcomingStreamsSection'
import { ChannelStartAdSlot } from './ChannelStartAdSlot'

import { ChannelTypes } from '../channelType'
import { Standings } from '../../../components/content/Standings/Standings'

import { PromotedCategories } from './PromotedCategories/PromotedCategories'
import LiveSection from '../../../components/content/LiveContentSection/LiveContentSection'
import { useFetchChannelSliderData } from './useFetchChannelSliderData'
import { getDisplayData } from './getDisplayData'
import { getScrollRefs } from './getScrollRefs'

import styles from './Start.module.scss'
import { BannersSection } from './BannersSection/BannersSection'
import { TeamsInLeageueSection } from './TeamsInLeagueSection'

interface Props {
  channelSlug: string
  channelType: ChannelTypes
  hasRecentArticles: boolean
  isViewingAsUser: boolean
  isRootPage: boolean
  onChangeTab: (destination: string) => void
}

export const Start: FC<Props> = ({
  channelSlug,
  channelType,
  isViewingAsUser,
  hasRecentArticles,
  isRootPage,
  onChangeTab
}) => {
  const { ref: upcomingStreamsRef, inView: upcomingStreamsInView } = useInView({
    triggerOnce: true
  })
  const { ref: sponsorsRef, inView: sponsorsInView } = useInView({
    triggerOnce: true
  })
  const { ref: latestVideosRef, inView: latestVideosInView } = useInView({
    triggerOnce: true
  })
  const { ref: highlightRef, inView: highlightsInView } = useInView({
    triggerOnce: true
  })
  const { ref: promotedCategoriesRef, inView: promotedCategoriesInView } =
    useInView({
      triggerOnce: true
    })
  const { ref: newsArticleRef, inView: newsArticlesInView } = useInView({
    triggerOnce: true
  })

  const channel = useSelector(channelSelector)

  const [upcomingStreamsScrollRef, setUpcomingStreamsScrollRef] =
    useState<MutableRefObject<Element | null> | null>(null)
  const [latestVideosScrollRef, setLatestVideosScrollRef] =
    useState<MutableRefObject<Element | null> | null>(null)

  const [highlightsScrollRef, setHighlightsScrollRef] =
    useState<MutableRefObject<Element | null> | null>(null)

  const currentBreakpoint = useCurrentBreakpoint()

  const {
    getUpcomingStreamsScrollRef,
    getlatestVideosScrollRef,
    getHighlightsScrollRef
  } = getScrollRefs(
    setUpcomingStreamsScrollRef,
    setLatestVideosScrollRef,
    setHighlightsScrollRef
  )

  const upcomingStreamsData = useFetchChannelSliderData(
    'upcomingStreams',
    channelSlug,
    upcomingStreamsScrollRef,
    channelType === ChannelTypes.team
      ? getUpcomingBroadcastsForTeams
      : getUpcomingBroadcastsForChannel,
    true
  )

  const latestVideosData = useFetchChannelSliderData(
    'latestVideos',
    channelSlug,
    latestVideosScrollRef,
    getPastGamesForChannel,
    latestVideosInView
  )
  const highlightsData = useFetchChannelSliderData(
    'highlights',
    channelSlug,
    highlightsScrollRef,
    getHighlightsForChannel,
    highlightsInView
  )

  const thumbnailSize =
    currentBreakpoint > Breakpoint.mobile ? 'large' : 'small'

  const channelDetails: Channel = channel?.data
  const showTeamSlider = channelType === ChannelTypes.league
  const [isReadyToRender, setIsReadyToRender] = useState(false)

  const {
    showUpcomingStreamsSection,
    showLatestVideosSection,
    showHighlightsSection,
    showNewsSection,
    showRelatedChannel,
    showStandings
  } = getDisplayData(upcomingStreamsData, isReadyToRender, channelDetails)

  useEffect(() => {
    if (channel.data && !channel.isLoading && !channel.isError) {
      setIsReadyToRender(true)
    }
  }, [channel])

  return isReadyToRender ? (
    <div
      data-testid="channel-start-contatiner"
      className={classNames(styles.StartContainer, {
        [styles.NotThemed]: !isRootPage
      })}
    >
      <BannersSection
        channelSlug={channelSlug}
        channelName={channelDetails.name}
        channelType={channelType}
        channelDetails={channelDetails}
        isRootPage={isRootPage}
        isViewingAsUser={isViewingAsUser}
        showRelatedChannel={showRelatedChannel}
        onSupportBannerClick={onChangeTab}
      >
        <LiveSection channelSlug={channelSlug} />
        {showTeamSlider && <TeamsInLeageueSection />}
      </BannersSection>
      <NewsSection
        showNewsSection={showNewsSection}
        newsArticleRef={newsArticleRef}
        thumbnailSize={thumbnailSize}
        channelSlug={channelSlug}
        newsArticlesInView={newsArticlesInView}
        pagePosition={PagePosition.Top}
        hasRecentArticles={hasRecentArticles}
      />
      <div ref={sponsorsRef}>
        {sponsorsInView && <SponsorSection channelSlug={channelSlug} />}
      </div>

      {showStandings && (
        <Standings channelSlug={channelSlug} showExpandedToggle={true} />
      )}

      <UpcomingStreamsSection
        showUpcomingStreamsSection={showUpcomingStreamsSection}
        upcomingStreamsRef={upcomingStreamsRef}
        upcomingStreamsInView={upcomingStreamsInView}
        getUpcomingStreamsScrollRef={getUpcomingStreamsScrollRef}
        upcomingStreamsData={upcomingStreamsData.data}
        thumbnailSize={thumbnailSize}
        isLoading={upcomingStreamsData.isLoading}
      />

      <ChannelStartAdSlot channelDetails={channelDetails} />

      <LatestVideosSection
        showLatestVideosSection={showLatestVideosSection}
        isRootPage={isRootPage}
        latestVideosRef={latestVideosRef}
        latestVideosInView={latestVideosInView}
        getlatestVideosScrollRef={getlatestVideosScrollRef}
        latestVideosData={latestVideosData.data}
        channelSlug={channelSlug}
        thumbnailSize={thumbnailSize}
        isLoading={latestVideosData.isLoading}
      />

      <HighlightsSection
        highlightsInView={highlightsInView}
        showHighlightsSection={showHighlightsSection}
        highlightRef={highlightRef}
        getHighlightsScrollRef={getHighlightsScrollRef}
        highlightsData={highlightsData.data}
        thumbnailSize={thumbnailSize}
        isLoading={highlightsData.isLoading}
      />
      <div
        className={styles.PromotedCategoriesContainer}
        data-testid="promoted-categories-container"
        ref={promotedCategoriesRef}
      >
        {promotedCategoriesInView && (
          <PromotedCategories
            channelSlug={channelSlug}
            thumbnailSize={thumbnailSize}
          />
        )}
      </div>
      <NewsSection
        showNewsSection={showNewsSection}
        newsArticleRef={newsArticleRef}
        thumbnailSize={thumbnailSize}
        channelSlug={channelSlug}
        newsArticlesInView={newsArticlesInView}
        pagePosition={PagePosition.Bottom}
        hasRecentArticles={hasRecentArticles}
      />
    </div>
  ) : null
}
