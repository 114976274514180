import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { t } from '@lingui/macro'
import SpacedList from '../SpacedList'
import { Channel } from '../cardTypes'
import Image from '../Image'
import { ChannelTypeLabel } from './ChannelTypeLabel/ChannelTypeLabel'
import styles from '../SearchResultCard.module.scss'

interface Props {
  content: Channel
}

const SearchResultCardChannel: FC<Props> = ({ content }) => (
  <div className={styles.Container} data-testid="channel-search-result">
    <div
      data-testid="search-result-image-container"
      className={styles.ImageChannelContainer}
    >
      <Image imageUrl={content.imageUrl} title={content.title} />
    </div>
    <div className={styles.ContentContainer}>
      <div className={styles.TitleContainer}>
        <div data-testid="search-result-title" className={styles.Title}>
          <Link to={content.targetUrl}>{content.title}</Link>
        </div>
        {content.partnerType && (
          <ChannelTypeLabel channelType={content.partnerType} />
        )}
      </div>
      <div className={styles.Description}>{content.description}</div>
      <div className={styles.BottomFrame}>
        <SpacedList
          list={[
            content.sport,
            content.location,
            `${t`Games`} (${content.games})`,
            `${t`Followers`} (${content.followers})`,
            `${t`Monthly supporters`} (${content.supporters})`
          ]}
        />
        {content.comingGameUrl && (
          <a href={content.comingGameUrl}>
            <span className={styles.ComingGame}>{t`Coming game`}</span>
          </a>
        )}
        {/* {content.liveGameUrl && (
          <a href={content.liveGameUrl}>
            <span className={styles.IsLive}>{t`Live right now`}</span>
          </a>
        )} */}
      </div>
    </div>
  </div>
)

export default SearchResultCardChannel
