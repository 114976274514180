import React, { FC } from 'react'
import styles from './ToggleSwitch.module.scss'

interface Props {
  onToggle: () => void
  isOn: boolean
  id: string
  label?: string
}

export const ToggleSwitch: FC<Props> = ({ onToggle, id, label, isOn }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.ToggleContainer}>
        <input
          type="checkbox"
          className={styles.CheckBox}
          name={id}
          id={id}
          defaultChecked={isOn}
          onClick={onToggle}
        />
        <label className={styles.Label} htmlFor={id}>
          <span className={styles.Inner} />
          <span className={styles.Switch} />
        </label>
      </div>
      {label && <div className={styles.TextContent}>{label}</div>}
    </div>
  )
}
