import { Price } from '../../../interfaces/Price.interface'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface DefaultSettingsResponseApi {
  default_livestream_prices: { [key: string]: string }
  default_video_prices: { [key: string]: string }
}

export interface DefaultSettings {
  defaultLivestreamPrices: Price
  defaultVideoPrices: Price
}

export const getDefaultSettings = async (
  slug: string
): Promise<DefaultSettings> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `session/companies/${slug}/default_settings`
    })
    const responseData = await resultResponse
    const mapped: DefaultSettings = mapDefaultSettings(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapDefaultSettings = (settings: DefaultSettingsResponseApi) => {
  try {
    return {
      defaultLivestreamPrices: settings.default_livestream_prices,
      defaultVideoPrices: settings.default_video_prices
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
