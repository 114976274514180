import { Subscription } from '../../../../interfaces'
import { SubscriptionType } from '../SubscriptionType'

export const getSubscriptionType = (card: Subscription) => {
  let subscriptionType: SubscriptionType
  switch (card.partner_type_name) {
    case 'team':
      subscriptionType = SubscriptionType.Team
      break
    case 'club':
      subscriptionType = SubscriptionType.Club
      break
    case 'league':
      subscriptionType = SubscriptionType.League
      break
    default:
      subscriptionType = SubscriptionType.Club
      break
  }
  return subscriptionType
}
