import React, { FC } from 'react'

import styles from './Testimonial.module.scss'

import { Testimonials } from '../Testimonials.interface'

interface Props {
  sliderItem: Testimonials
}

const Testimonial: FC<Props> = ({ sliderItem }) => {
  return (
    <div className={styles.TestimonialContainer}>
      <span className={styles.Quote}>&quot;{sliderItem.quote}&quot;</span>
      <div className={styles.QuoterInformation}>
        <span className={styles.Name}>{sliderItem.name} </span>-
        <span className={styles.Title}> {sliderItem.title}</span>
      </div>
    </div>
  )
}
export default Testimonial
