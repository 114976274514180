import React, { FC, ReactNode } from 'react'
import Modal from '../Modal/Modal'
import classNames from 'classnames'
import styles from './BasicModal.module.scss'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
export enum ModalTheme {
  Primary = 'primary',
  Secondary = 'secondary'
}
interface Props {
  isOpen: boolean
  handleClose: () => void
  firstContainerContent: ReactNode
  firstContainerTheme: ModalTheme
  secondContainerContent?: ReactNode
  secondaryContainerTheme?: ModalTheme
  customStyle?: string
}

export const BasicModal: FC<Props> = ({
  firstContainerContent,
  secondContainerContent,
  firstContainerTheme,
  secondaryContainerTheme = ModalTheme.Primary,
  isOpen,
  handleClose,
  customStyle
}) => {
  const breakpoint = useCurrentBreakpoint()
  const isMobile = breakpoint <= Breakpoint.sm
  const secondaryContent = (
    <div
      className={classNames(
        styles.SecondContainerContent,
        styles[secondaryContainerTheme]
      )}
      data-testid="second-container-content"
    >
      {secondContainerContent}
    </div>
  )

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div
        className={classNames(styles.ModalContent, {
          [customStyle || '']: customStyle
        })}
        data-testid="basic-modal"
      >
        {secondContainerContent && isMobile && secondaryContent}
        <div
          className={classNames(
            styles.FirstContainerContent,
            styles[firstContainerTheme]
          )}
          data-testid="first-container-content"
        >
          {firstContainerContent}
        </div>
        {secondContainerContent && !isMobile && secondaryContent}
      </div>
    </Modal>
  )
}
