import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import useCurrentUser from './CurrentUser'

export default function usePreferredCurrencyPriorityList(): Array<string> {
  const currentChannel = useSelector(channelSelector)
  const currentCompany = currentChannel.data
  const currentUser = useCurrentUser()
  const preferredCurrencyPriorityList: Array<string | undefined | null> = [
    currentUser?.preferred_currency,
    currentCompany?.default_currency
  ]
  return preferredCurrencyPriorityList.filter(
    (item): item is string => item !== undefined && item !== null
  )
}
