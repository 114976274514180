import React, { FC } from 'react'
import { FilterItem, FilterType } from '../FilterItem/FilterItem'
import styles from './FilterList.module.scss'
import { Loader } from 'components/Loader/Loader'
import { Trans } from '@lingui/macro'
export interface FilterItem {
  label: string
  shortLabel: string | null
  slug: string

  children?: FilterItem[]
}
interface Props {
  list: FilterItem[]
  filterItemType: FilterType
  isLoading: boolean
  isError: boolean
  onClick: (itemSlug: string) => void
}

export const FilterList: FC<Props> = ({
  list,
  filterItemType,
  isLoading,
  isError,
  onClick
}) => {
  if (isLoading) return <Loader />
  if (isError)
    return (
      <div>
        <Trans>
          There is a problem trying to fetch the filters, please reload the page
          and try again.
        </Trans>
      </div>
    )
  return (
    <div className={styles.Container} data-testid="filter-item-container">
      {list?.map((item) => (
        <FilterItem
          key={item.slug}
          type={filterItemType}
          label={item.shortLabel ? item.shortLabel : item.label}
          slug={item.slug}
          onClick={onClick}
        />
      ))}
    </div>
  )
}
