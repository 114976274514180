import React, { FC } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import ContentContainer from 'components/ContentContainer'
import styles from './UserSessionPage.module.scss'
import {
  LoginLayerType,
  ModalContent
} from 'components/session/ModalContent/ModalContent'
import { LoginCallbackModal } from 'components/session/LoginCallback/LoginCallbackModal'

export enum LoginLayerTypes {
  Login = 'login',
  Signup = 'signup',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword'
}

export const UserSessionPage: FC = () => {
  const history = useHistory()
  const switchMode = (mode: LoginLayerType) => {
    switch (mode) {
      default:
      case LoginLayerTypes.Login:
        history.push('/login')
        break
      case LoginLayerTypes.Signup:
        history.push('/sign_up')
        break
      case LoginLayerTypes.ForgotPassword:
        history.push('/password/new')
        break
    }
  }
  const handleReadyCallback = () => {
    history.push('/calendar')
  }
  return (
    <ContentContainer
      hasLeftRightPadding={true}
      hasTopBottomPadding={true}
      hasLightBackground={false}
    >
      <div className={styles.ContentContainer}>
        <div className={styles.Content}>
          <Switch>
            <Route path="/login/callback">
              <LoginCallbackModal isOpen={true} onClose={handleReadyCallback} />
            </Route>
            <Route path="/login">
              <ModalContent
                readyCallback={handleReadyCallback}
                switchModeCallback={(mode: LoginLayerType) => switchMode(mode)}
                loginLayerType={LoginLayerTypes.Login}
                isLoginLayerOpen={true}
              />
            </Route>
            <Route path="/sign_up">
              <ModalContent
                readyCallback={handleReadyCallback}
                switchModeCallback={(mode: LoginLayerType) => switchMode(mode)}
                loginLayerType={LoginLayerTypes.Signup}
                isLoginLayerOpen={true}
              />
            </Route>
            <Route path="/password/new">
              <ModalContent
                readyCallback={handleReadyCallback}
                switchModeCallback={(mode: LoginLayerType) => switchMode(mode)}
                loginLayerType={LoginLayerTypes.ForgotPassword}
                isLoginLayerOpen={true}
              />
            </Route>
            <Route path="/profile/password/edit">
              <ModalContent
                readyCallback={handleReadyCallback}
                switchModeCallback={(mode: LoginLayerType) => switchMode(mode)}
                loginLayerType={LoginLayerTypes.ResetPassword}
                isLoginLayerOpen={true}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </ContentContainer>
  )
}
