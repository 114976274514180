import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import ArticleThumbnailSlider from '../../../../components/sliders/ArticleThumbnailSlider'

import useArticleDataFetch from './useArticleDataFetch'
import { Breadcrumb } from './Breadcrumb/Breadcrumb'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { ArticleView } from './ArticleView/ArticleView'
import styles from './ReadNewsArticle.module.scss'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

export const ReadNewsArticle = () => {
  const { company, article } = useParams<{
    company: string | undefined
    article: string | undefined
  }>()
  const currentBreakpoint = useCurrentBreakpoint()

  const { articleData, relatedArtilces } = useArticleDataFetch(company, article)
  const channel = useSelector(channelSelector)
  const articleTitle = articleData?.data?.title
  const ArticleThumbnailSize =
    currentBreakpoint > Breakpoint.mobile ? 'large' : 'small'

  return (
    <div
      className={styles.ReadArticleContainer}
      data-testid="read-news-article-container"
    >
      <Breadcrumb
        channelSlug={company || ''}
        channelName={channel?.data?.name || ''}
        articleTitle={articleTitle || 'article'}
        articleSlug={article || 'article'}
        isRootChannel={channel?.data?.isRootChannel}
        parentChannelSubdomain={channel?.data?.parentChannelSubdomain}
      />
      <div className={styles.ReadArticle} data-testid="article-view-container">
        <ArticleView articleData={articleData} />
      </div>
      <ArticleThumbnailSlider
        title={t`More news`}
        size={ArticleThumbnailSize}
        relatedArticles={relatedArtilces}
        strongHeading={false}
      />
    </div>
  )
}
