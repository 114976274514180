import React, { FC } from 'react'

import ContentContainer from 'components/ContentContainer'
import ChecklistItemsContainer from './ChecklistItemsContainer'

interface Props {
  showAdminChannelGuidance: boolean
  onClose: () => void
}

export const GuidedChannelSetup: FC<Props> = ({
  showAdminChannelGuidance,
  onClose
}) =>
  showAdminChannelGuidance ? (
    <ContentContainer
      hasLightBackground
      hasLeftRightPadding
      hasTopBottomPadding
    >
      <ChecklistItemsContainer onClose={onClose} />
    </ContentContainer>
  ) : null
