import React, { FC } from 'react'
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'

import { HoneybadgerError } from '../components/app/HoneybadgerError/HoneybadgerError'

interface Props {
  children: React.ReactNode
}

export const ErrorMonitoring: FC<Props> = ({ children }) => {
  const config = {
    apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
    environment: process.env.REACT_APP_HONEYBADGER_ENVIRONMENT,
    revision: process.env.REACT_APP_HONEYBADGER_REVISION || '2'
  }
  const honeybadger = Honeybadger.configure(config)

  return (
    <HoneybadgerErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={HoneybadgerError}
    >
      {children}
    </HoneybadgerErrorBoundary>
  )
}
