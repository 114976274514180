import React, { FC, useCallback, useState } from 'react'

import { useDataApi } from 'api/useDataApi'
import { getUserSubscriptions } from 'api/user/getUsersSubscriptions'
import { Loader } from 'components/Loader/Loader'
import {
  SubscriptionItem,
  UserSubscriptionItem
} from './SubscriptionItem/SubscriptionItem'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { Trans, t } from '@lingui/macro'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal'
import { getCancelSubscription } from 'api/user/getCancelSubscription'
import styles from './Subscriptions.module.scss'

export const Subscriptions: FC = () => {
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false)
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0)
  const [selectedSubscriptionName, setSelectedSubscriptionName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [reloadData, setReloadData] = useState(false)

  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getUserSubscriptions, []),
    triggerCall: reloadData
  })
  const hasData = ![0, undefined].includes(data?.data?.length)

  const onOpenCancelSubscriptionModal = (id: number, name: string) => {
    setSelectedSubscriptionId(id)
    setSelectedSubscriptionName(name)
    setShowCancelSubscriptionModal(true)
  }

  const onCancelSubscription = async () => {
    if (selectedSubscriptionId) {
      const response = await getCancelSubscription(selectedSubscriptionId)
      if (!response.isSuccess) {
        setErrorMessage(
          t`Something went wrong when trying to cancel the subscription, please try again.`
        )
      } else {
        setShowCancelSubscriptionModal(false)
        setReloadData(!reloadData)
      }
    }
  }

  return (
    <div className={styles.Subscription}>
      <FormSectionTitle title={t`Your subscriptions`} />
      {!isLoading && hasData && (
        <div className={styles.List} data-testid="subscriptions-list">
          {data.data.map((subscription: UserSubscriptionItem) => (
            <SubscriptionItem
              id={subscription.id}
              key={subscription.title}
              title={subscription.title}
              channelName={subscription.channelName}
              channelSlug={subscription.channelSlug}
              price={subscription.price}
              timeFrame={subscription.timeFrame}
              autoRenew={subscription.autoRenew}
              onCancelSubscription={(id: number) =>
                onOpenCancelSubscriptionModal(id, subscription.title)
              }
            />
          ))}
        </div>
      )}
      {!isLoading && !hasData && (
        <div>
          <Trans>You have no active subscriptions.</Trans>
        </div>
      )}
      {isLoading && <Loader />}
      {showCancelSubscriptionModal && (
        <ConfirmationModal
          modalHeader={t`Cancel subscription`}
          title={t`Are you sure you want to cancel the subscription?`}
          subText={t`Canceling the subscription for ${selectedSubscriptionName}, will prevent its renewal.`}
          defaultOpen={showCancelSubscriptionModal}
          isError={errorMessage.length > 0}
          isLoading={false}
          errorMessage={errorMessage}
          confirmButtonLabel={t`Yes, cancel subscription`}
          cancelButtonLabel={t`No`}
          onClose={() => setShowCancelSubscriptionModal(false)}
          onConfirm={() => onCancelSubscription()}
        />
      )}
    </div>
  )
}
