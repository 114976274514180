import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface BrandingSettings {
  channelSlug: string
  domainName: string
}

interface BrandingSettingsRequestApi {
  company: string
  root_domain: string
}

interface BrandingSettingsResponse {
  hasError: boolean
  errors?: any
}

export const postBrandingSettings = async (
  brandingSettings: BrandingSettings
): Promise<BrandingSettingsResponse> => {
  const params: BrandingSettingsRequestApi = {
    company: brandingSettings.channelSlug,
    root_domain: brandingSettings.domainName
  }
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${brandingSettings.channelSlug}/root_domain`,
      method: ApiEndpoint.METHODS.POST,
      params,
      authenticatable: true
    })
    await response

    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: []
    }
  }
}
