import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'

import { Pagination } from '../../../components/Pagination/Pagination'
import { AddGamesModal } from '../AddGamesModal/AddGamesModal'
import { CompetitionsList } from '../CompetitionsList/CompetitionsList'
import { CompetitionSelectorHeader } from './CompetitionSelectorHeader/CompetitionSelectorHeader'
import { useDataApi } from '../../../api/useDataApi'
import {
  FilteredGameSchedule,
  getFilteredGameScheduleList
} from '../../../api/sportData/getFilteredGameScheduleLists'
import { Loader } from '../../../components/Loader/Loader'
import { DropDownListItem } from '../../../pages/ChannelPage/Videos/Dropdown/DropdownList/DropdownList'
import {
  Group,
  getGroupsForChannel
} from '../../../api/sportData/getGroupsForChannel'
import { getSearchPlaceholderText } from './getSearchPlaceholderText'
import {
  ContentManagerUrlParams,
  getChannelSlugToUseForCompetitions
} from '../Competitions/getChannelSlugToUseForCompetitions'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { useScrollToTop } from '../../../helpers/useScrollToTop'
import { AddLeagueModal } from '../joinLeagueAsLeague/AddLeagueModal/AddLeagueModal'
import { useStatusMessageDispatchContext } from '../../../pages/ChannelPage/ContentManager/StatusMessageContext'
import { Filters } from './Filters/Filters'

import styles from './CompetitionSelector.module.scss'
import TagManager from 'react-gtm-module'
import { getTotalPages } from 'helpers/getTotalPages'

interface Props {
  channelSlug: string
  channelType: ChannelTypes
}

export const CompetitionSelector: FC<Props> = ({
  channelSlug,
  channelType
}) => {
  useScrollToTop()

  const [selectedCompetitionId, setSelectedCompetitionId] = useState<number>(0)
  const [showAddModal, setShowAddModal] = useState(false)
  const [groupId, setGroupId] = useState('')
  const [searchText, setSearchText] = useState('')
  const urlParams: ContentManagerUrlParams = useParams()

  const [page, setPage] = useState(1)
  const PER_PAGE_COUNT = 10
  const [filteredGameScheduleData, setFilteredGameScheduleDatas] = useState<
    FilteredGameSchedule[]
  >([])
  const COMPETITION_SELECTOR_ID = 'competition-selector-id'
  const history = useHistory()
  const dispatch = useStatusMessageDispatchContext()

  const channelSlugToUse = getChannelSlugToUseForCompetitions(
    channelSlug,
    urlParams,
    channelType
  )
  const [totalPages, setTotalPages] = useState(0)
  const triggerGTMEvent = (selectedCompetitionId: number) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_add_league_button_clicked',
        channel_slug: channelSlug,
        channel_slug_used: channelSlugToUse,
        selected_competition_id: selectedCompetitionId,
        channel_type: channelType
      }
    })
  }
  const addCompetition = (id: number) => {
    setSelectedCompetitionId(id)
    triggerGTMEvent(id)
    setShowAddModal(true)
  }

  const { data, isError, isLoading } = useDataApi({
    apiCall: useCallback(getFilteredGameScheduleList, []),
    parameters: useMemo(
      () => ({
        searchText,
        groupId,
        channelSlug: channelSlugToUse,
        page: page,
        perPageCount: PER_PAGE_COUNT
      }),
      [channelSlugToUse, groupId, searchText, page]
    )
  })

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      setFilteredGameScheduleDatas(data.data)
      setTotalPages(getTotalPages(data.totalCount, PER_PAGE_COUNT))
    }
  }, [isLoading, isError, data])

  const { data: groupData, isError: groupDataError } = useDataApi({
    apiCall: useCallback(getGroupsForChannel, []),
    parameters: useMemo(
      () => ({
        channelSlug: channelSlugToUse
      }),
      [channelSlugToUse]
    )
  })
  const selectedItem = filteredGameScheduleData.find(
    (item: FilteredGameSchedule) => item.id === selectedCompetitionId
  )
  const handleGamesAdded = () => {
    setShowAddModal(false)
    if (channelType === ChannelTypes.club) {
      history.push(
        `/${channelSlug}/contentmanager/teams/${urlParams.selectedTeam}/competitions`
      )
    } else {
      history.push(`/${channelSlug}/contentmanager/competitions`)
    }
    dispatch({ type: 'show_competition_added' })
  }

  const mappedDropDownListData: DropDownListItem[] = groupData
    ? groupData.map((item: Group) => ({
        id: item.groupId.toString(),
        label: item.groupName
      }))
    : []

  const dropDownData: DropDownListItem[] = [
    { id: 'all', label: t`All districts` },
    ...mappedDropDownListData
  ]
  const handleSelectGroup = (id: string) => {
    if (id !== groupId) {
      if (id === dropDownData[0].id) {
        if (groupId !== '') {
          setFilteredGameScheduleDatas([])
          setGroupId('')
        }
      } else {
        setPage(1)
        setFilteredGameScheduleDatas([])
        setGroupId(id)
      }
      triggerGTMForSelectingGroup(id)
    }
  }
  const handleSearch = (searchInput: string) => {
    if (searchInput !== searchText) {
      setPage(1)
      setFilteredGameScheduleDatas([])
      setSearchText(searchInput)
      triggerGTMforSearch(searchInput)
    }
  }
  const getlabelFromDropdownData = (selectedGroupId: string) =>
    dropDownData.find((item) => item.id === selectedGroupId)?.label

  const selectedGroupName =
    groupId !== '' ? getlabelFromDropdownData(groupId) : ''

  const shouldOpenLeagueModal = [ChannelTypes.league].includes(channelType)
  const searchPlaceholder = getSearchPlaceholderText(selectedGroupName || '')

  const triggerGTMForSelectingGroup = (selectedGroupId: string) => {
    const selectedGroupName =
      selectedGroupId !== ''
        ? getlabelFromDropdownData(selectedGroupId)
        : 'All groups'

    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_selected_group_from_availabel_competitions',
        channel_slug: channelSlug,
        channel_type: channelType,
        selected_group_name: selectedGroupName,
        selected_group_id: selectedGroupId
      }
    })
  }
  const triggerGTMforSearch = (search: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_search_for_available_competitions',
        channel_slug: channelSlug,
        channel_type: channelType,
        search_input: search
      }
    })
  }
  return (
    <div className={styles.CompetitionsSelectorContainer}>
      {filteredGameScheduleData &&
        (shouldOpenLeagueModal ? (
          <AddLeagueModal
            channelSlug={channelSlugToUse}
            competitionId={selectedCompetitionId}
            onClose={() => setShowAddModal(false)}
            onAddCompetion={handleGamesAdded}
            district={selectedItem?.groupName ? selectedItem?.groupName : ''}
            leagueName={selectedItem?.title ? selectedItem?.title : ''}
            totalGames={selectedItem?.totalGames ? selectedItem?.totalGames : 0}
            competitionStartAt={
              selectedItem?.startAt ? selectedItem.startAt : 0
            }
            competitionEndAt={selectedItem?.endAt ? selectedItem.endAt : 0}
            defaultOpen={showAddModal}
          />
        ) : (
          <AddGamesModal
            channelSlug={channelSlugToUse}
            onClose={() => setShowAddModal(false)}
            onAddedGames={handleGamesAdded}
            defaultOpen={showAddModal}
            competitionId={selectedCompetitionId}
            groupName={selectedItem?.groupName || ''}
            competitionName={selectedItem?.title || ''}
          />
        ))}

      <div id={COMPETITION_SELECTOR_ID} className={styles.HeaderContainer}>
        <CompetitionSelectorHeader
          channelType={channelType}
          channelSlug={channelSlug}
          selectedTeamSlug={channelSlugToUse}
        />
        <Filters
          dropDownData={dropDownData}
          onCompetitionSearch={(searchInput: string) =>
            handleSearch(searchInput)
          }
          onSelectGroup={(id: string) => {
            handleSelectGroup(id)
          }}
          groupDataIsValid={!groupDataError}
          searchPlaceholder={searchPlaceholder}
        />
      </div>

      {filteredGameScheduleData && filteredGameScheduleData.length > 0 && (
        <CompetitionsList
          listData={filteredGameScheduleData}
          onAddCompetiton={(id: number) => addCompetition(id)}
          hasError={isError}
          channelType={channelType}
        />
      )}
      {!isLoading && filteredGameScheduleData.length < 1 && (
        <p>
          <Trans>Not available right now</Trans>
        </p>
      )}
      {isLoading && <Loader />}

      {!isError && (
        <div className={styles.PaginationContainer}>
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onChangePage={(value) => {
              setPage(value)
            }}
          />
        </div>
      )}
    </div>
  )
}
