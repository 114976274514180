import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import styles from './Divider.module.scss'

export const Divider: FC = () => (
  <div className={styles.DividerContainer}>
    <div className={styles.Divider}>
      <div className={styles.TextContainer}>
        <Trans>or</Trans>
      </div>
    </div>
  </div>
)
