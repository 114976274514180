import { BroadcastThumbnailInterface } from '../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import { getIsStreamLive } from '../../helpers/getIsStreamLive'
import { Company } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'

export const getUpcomingBroadcastsForTeams = async ({
  slug,
  page,
  perPageCount,
  channel
}: {
  slug: string
  page: number
  perPageCount: number
  channel: Company
}) => {
  // https://sportstage.se/api/play_v1/timeline_objects/collections/upcoming_games_and_broadcasts_for_team?company=tullinge-if-a-lag-herrar&page=1&per_page=10&include_games_without_livestreams=false'
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/upcoming_games_and_broadcasts_for_team',
      params: {
        company: slug,
        page: page,
        per_page: perPageCount
      }
    })

    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data, channel)
    const now = Date.now() / 1000
    return {
      data: mapped.filter(
        (item: BroadcastThumbnailInterface) =>
          !getIsStreamLive(item.startTime, item.endTime, now)
      ),
      totalCount: responseData.totalCount
    }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
