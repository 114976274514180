import { t } from '@lingui/macro'
import { TeamItemStatus } from './TeamItemProps'

export const getTranslatedTeamStatus = (teamItemStatus: TeamItemStatus) => {
  switch (teamItemStatus) {
    case TeamItemStatus.Active:
      return t`Active`
    case TeamItemStatus.Disabled:
      return t`Disabled`
    case TeamItemStatus.NotActive:
      return t`Not active`
    default:
      return ''
  }
}
