import React, { FC } from 'react'
import { LineupItem, LineupItemProps } from '../LineupItem/LineupItem'
import { Trans } from '@lingui/macro'
import styles from './CoachList.module.scss'
interface Props {
  coaches: LineupItemProps[]
}

export const CoachList: FC<Props> = ({ coaches }) =>
  coaches.length > 0 ? (
    <div className={styles.CoachList}>
      <div className={styles.ListTitle}>
        <Trans>Coaches</Trans>
      </div>
      <div className={styles.List}>
        {coaches.map((coach: LineupItemProps) =>
          coach.isCoach ? (
            <LineupItem
              key={coach.name}
              number=""
              name={coach.name}
              isCaptain={false}
              isHighlightedPosition={false}
              positionAbbreviation=""
              isCoach={true}
            />
          ) : null
        )}
      </div>
    </div>
  ) : null
