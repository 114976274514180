import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'

import { useStatusMessageDispatchContext } from '../../../../pages/ChannelPage/ContentManager/StatusMessageContext'
import { FormModal } from '../../FormModal/FormModal'
import { ItemContainer } from './ItemContainer/ItemContainer'
import { ModalActionButtons } from '../../../../components/Modals/ModalActionButtons/ModalActionButtons'
import {
  ConnectLeagueRequest,
  putConnectedCompetitionGamesToLeague
} from '../../../../api/sportData/putConnectCompetitionGamesToLeague'
import { ModalError } from '../../../../components/Modals/ConfirmationModal/ModalError/ModalError'
import styles from './AddLeagueModal.module.scss'
import TagManager from 'react-gtm-module'

interface Props {
  onClose: () => void
  onAddCompetion: () => void
  channelSlug: string
  competitionId: number
  defaultOpen: boolean
  leagueName: string
  district: string
  competitionStartAt: number
  competitionEndAt: number
  totalGames: number
  children?: React.ReactNode
}

export const AddLeagueModal: FC<Props> = ({
  onClose,
  onAddCompetion,
  channelSlug,
  competitionId,
  defaultOpen,
  leagueName,
  district,
  competitionStartAt,
  competitionEndAt,
  totalGames
}) => {
  const connectLeagueValues: ConnectLeagueRequest = {
    channelSlug,
    competitionId: competitionId.toString()
  }
  const [hasError, setHasError] = useState(false)
  const dispatch = useStatusMessageDispatchContext()
  const triggerGTMEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_added_league_to_channel_success',
        channel_slug: channelSlug,
        league_name: leagueName,
        district_name: district,
        league_start_at: competitionStartAt
      }
    })
  }
  const onAddCompetionToLeague = async () => {
    const response = await putConnectedCompetitionGamesToLeague(
      connectLeagueValues
    )
    if (response.hasError) {
      setHasError(true)
    } else {
      triggerGTMEvent()
      dispatch({ type: 'toggle_show_competition_added' })
      onAddCompetion()
    }
  }
  return (
    <FormModal
      modalHeader={t`Add competition to channel`}
      preHeader=""
      header={leagueName}
      modalInformation=""
      defaultOpen={defaultOpen}
      onClose={onClose}
    >
      {hasError ? (
        <div className={styles.ErrorContainer}>
          <ModalError
            errorMessage={t`There was a problem trying to add the competition.`}
            onCancel={onClose}
            onTryAgain={() => setHasError(false)}
          />
        </div>
      ) : (
        <div className={styles.InnerContainer}>
          <div className={styles.ItemsContainer}>
            <ItemContainer itemTitle={t`District`} item={district} />
            <ItemContainer itemTitle={t`Games`} item={totalGames} />
            {competitionStartAt > 0 && (
              <ItemContainer
                itemTitle={t`Start date`}
                item={dayjs.unix(competitionStartAt).format('MMM DD, YYYY')}
              />
            )}
            {competitionEndAt > 0 && (
              <ItemContainer
                itemTitle={t`End date`}
                item={dayjs.unix(competitionEndAt).format('MMM DD, YYYY')}
              />
            )}
          </div>
          <ModalActionButtons
            cancelLabel={t`Cancel`}
            confirmButtonLabel={t`Add competition`}
            onCancel={onClose}
            onConfirm={onAddCompetionToLeague}
          />
        </div>
      )}
    </FormModal>
  )
}
