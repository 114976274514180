import { useEffect } from 'react'
type NumberPair = [number, number]
export interface TargetingOptions {
  partnerDomain: string[]
  parentChannelDomain: string[]
  sportCategory: string[]
  countryCode: string[]
}

interface Props {
  path: string
  size?: number[] | string[]
  multiSize?: NumberPair[]
  id: string
  targetingOptions?: TargetingOptions
}

export const useAdUnit = ({
  path,
  size,
  multiSize,
  id,
  targetingOptions
}: Props): void =>
  useEffect(() => {
    // @ts-ignore
    const googletag = window.googletag || {}

    const tagSize = size ? size : multiSize

    if (googletag && googletag.apiReady && tagSize) {
      try {
        googletag.cmd = googletag.cmd || []

        const slot = googletag
          .pubads()
          .getSlots()
          .find((slot: any) => slot.getSlotElementId() === id)
        if (slot) {
          googletag.destroySlots([slot])
        }
        googletag.cmd.push(() => {
          googletag
            .defineSlot(path, tagSize, id)
            .setTargeting('partnerDomain', [targetingOptions?.partnerDomain])
            .setTargeting('parentChannelDomain', [
              targetingOptions?.parentChannelDomain
            ])
            .setTargeting('sportCategory', [targetingOptions?.sportCategory])
            .setTargeting('countryCode', [targetingOptions?.countryCode])
            .addService(googletag.pubads())
          googletag.pubads().collapseEmptyDivs(true)
          googletag.pubads().enableSingleRequest()
          googletag.enableServices()
          googletag.display(id)
        })
      } catch (err: any) {
        console.log(err)
      }
    }
  }, [])
