import * as yup from 'yup'
import { t } from '@lingui/macro'
export const step1ValidationSchema = () =>
  yup.object({
    firstName: yup
      .string()
      .required(t`Please enter your first name`)
      .max(40, t`First name is to long`),
    lastName: yup
      .string()
      .required(t`Please enter your last name`)
      .max(40, t`Last name is to long`),

    email: yup
      .string()
      .email(t`Please enter a valid email`)
      .required(t`Please enter your accounts email`),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref('email'), null], t`Email does not match`)
      .required(t`To continue you need to confirm your email`)
  })

export const step2ValidationSchema = () =>
  yup.object({
    newPassword: yup
      .string()
      .min(6, 'Must be a valid password')
      .required(t`Password is required`),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t`Password does not match`)
      .required(t`Please confirm your password`),
    privacyPolicyChecked: yup.boolean().oneOf([true]),
    recieveEmailChecked: yup.boolean()
  })

export const step3ValidationSchema = () => {
  const phoneRegExp = /^(?!0)\d{7,15}$/
  const firstCharNoZeroRegExp = /^(?!0)/
  return yup.object({
    phoneNumber: yup
      .string()
      .matches(firstCharNoZeroRegExp, t`First number can not be a zero`)
      .matches(phoneRegExp, t`Phone number is not valid`)
      .required(t`Please add your phone number`),
    countryCode: yup.string()
  })
}
