import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './TestimonialsSection.module.scss'
import Testimonial from './Testimonial/Testimonial'
import { Testimonials } from './Testimonials.interface'

import ForwardIcon from './ForwardIcon'
import BackwardIcon from './BackwardIcon'

interface Props {
  sectionData: Testimonials[]
}

const TestimonialsComponent: FC<Props> = ({ sectionData }) => {
  const [item, setItem] = useState(0)
  const [disableBackwards, setDisableBackwards] = useState(true)
  const [disableForwards, setDisableforwards] = useState(false)
  const numberOfItems = sectionData.length - 1

  useEffect(() => {
    if (item === 0) {
      setDisableBackwards(true)
    }
    if (item === numberOfItems) {
      setDisableforwards(true)
    }
  }, [item, numberOfItems])

  const clickForward = () => {
    if (!disableForwards && !(item === numberOfItems)) {
      setItem(item + 1)
      setDisableBackwards(false)
    }
  }

  const clickBackward = () => {
    if (!disableBackwards && !(item === 0)) {
      setItem(item - 1)
      setDisableforwards(false)
    }
  }

  return (
    <div className={styles.TestimonialsContainer}>
      <div className={styles.ImageContainer}>
        <img
          className={styles.Image}
          src={sectionData[item].imagePath}
          alt={`${sectionData[item].association} logo`}
          data-testid="testimonals-image"
        />
      </div>
      <div
        className={styles.RightSideContainer}
        data-testid="testimonials-right-side-container"
      >
        <Testimonial
          sliderItem={sectionData[item]}
          data-testid="testimonal-component"
        />
        <div className={styles.ButtonsContainer}>
          <div
            onClick={() => clickBackward()}
            onKeyPress={() => clickBackward()}
            role="button"
            className={classNames(styles.Button, {
              [styles.DisableBackwards]: disableBackwards
            })}
            tabIndex={0}
            data-testid="backwards-arrow"
          >
            <BackwardIcon />
          </div>

          <div
            onClick={() => clickForward()}
            onKeyPress={() => clickForward()}
            role="button"
            className={classNames(styles.Button, {
              [styles.DisableForwards]: disableForwards
            })}
            tabIndex={0}
            data-testid="forwards-arrow"
          >
            <ForwardIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TestimonialsComponent
