import React from 'react'
import { HomeIcon } from 'assets/icons/HomeIcon'
import { MyAccountIcon } from 'assets/icons/MyAccount'
import { MyPurchasesIcon } from 'assets/icons/MyPurchasesIcon'
import { SecurityIcon } from 'assets/icons/SecurityIcon'
import { SidebarMenuLink } from 'components/SidebarNavigation/MenuItem/MenuItem'
import { LinkType } from 'components/page/menuLink/MenuLink'
import { t } from '@lingui/macro'

export const UserAccountMenuLinks: SidebarMenuLink[] = [
  {
    translationText: t`Home`,
    link: '/user/profile/home',
    type: LinkType.Route,
    icon: <HomeIcon />
  },
  {
    translationText: t`My account`,
    link: '',
    type: LinkType.Route,
    icon: <MyAccountIcon />,
    subMenuLinks: [
      {
        translationText: t`Account details`,
        link: '/user/profile/account/details',
        type: LinkType.Route
      },
      {
        translationText: t`My channels`,
        link: '/user/profile/account/channels',
        type: LinkType.Route
      },
      {
        translationText: t`Following`,
        link: '/user/profile/account/following',
        type: LinkType.Route
      }
    ]
  },
  {
    translationText: t`My purchases`,
    link: '/menu',
    type: LinkType.Route,
    icon: <MyPurchasesIcon />,
    subMenuLinks: [
      {
        translationText: t`Subscriptions`,
        link: '/user/profile/purchases/subscriptions',
        type: LinkType.Route
      },
      {
        translationText: t`All purchases`,
        link: '/user/profile/purchases/allpurchases',
        type: LinkType.Route
      },
      {
        translationText: t`My cards`,
        link: '/user/profile/purchases/mycards',
        type: LinkType.Route
      }
    ]
  },
  {
    translationText: 'Security',
    link: '/security',
    type: LinkType.Route,
    icon: <SecurityIcon />,
    subMenuLinks: [
      {
        translationText: 'Password',
        link: '/user/profile/security/password',
        type: LinkType.Route
      }
    ]
  }
]
