import { StreamUrls } from 'features/viewerPage/viewerPage.interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getStreamUrls = async (
  mediaObject: any,
  company: string,
  adminAccess: boolean = false
): Promise<StreamUrls> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `media_object/${mediaObject.id}/request_stream_urls`,
      method: ApiEndpoint.METHODS.GET,
      credentials: 'include',
      params: {
        admin_access: adminAccess,
        company
      },
      authenticatable: true
    })
    if (resultResponse.data.status === 401) {
      return {
        requirePayment: false,
        adminAccess: resultResponse.data.show_admin_access
      }
    }

    if (resultResponse.data.status === 200) {
      return {
        urls: resultResponse.data.urls,
        requirePayment: false
      }
    }
    if (resultResponse.data.status === 402) {
      return {
        requirePayment: true,
        paymentMethods: resultResponse.data.payment_methods,
        adminAccess: resultResponse.data.show_admin_access
      }
    }
    if (resultResponse.data.status === 403) {
      return {
        requirePayment: false,
        geoBlock: resultResponse.data.geo_block_data
      }
    }
    throw new Error(`unexpected result: ${resultResponse.data.status}`)
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
