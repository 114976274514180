import React, { FC, useRef, useState } from 'react'
import { RoundButton } from '../RoundButton/RoundButton'
import CameraIcon from 'pages/ChannelPage/Header/Images/CameraIcon'
import { postUserImage } from 'api/uploadImage/postImageFile'
import { Loader } from 'components/Loader/Loader'
import styles from './UploadUserImgButton.module.scss'

interface Props {
  onUploadSuccess: (image: string) => void
}

export const UploadUserImgButton: FC<Props> = ({ onUploadSuccess }) => {
  const [isUploading, setIsUploading] = useState(false)
  const inputFile: any = useRef(undefined)
  const onChangeImage = async (event: any) => {
    if (event.target.files) {
      setIsUploading(true)
      const files: Array<File> = Array.from(event.target.files)
      const imageFile: File = files[0]
      const src = URL.createObjectURL(imageFile)
      const response = await postUserImage({ imageFile })

      if (response.isSuccess) {
        onUploadSuccess(src)
      }
    }
    setIsUploading(false)
  }
  const openImageSelector = () => {
    if (inputFile) {
      inputFile.current.click()
    }
  }

  return isUploading ? (
    <div className={styles.Loader}>
      <Loader />
    </div>
  ) : (
    <RoundButton id="upload-button" onClick={() => openImageSelector()}>
      <CameraIcon />
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onChangeImage}
      />
    </RoundButton>
  )
}
