import React, { FC, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import ContentContainer from 'components/ContentContainer'

import { TabListContainer } from './TabListContainer/TabListContainer'
import { EventStatus } from 'api/multistageTournament/mapChannelEventsResponse'

export interface ChannelEvent {
  channelSlug: string
  status: EventStatus
  eventSlug: string
  id: string
  name: string
  startDate: number
}
export const PAST_GAMES_TAB_ID = 'x'

interface Props {
  futureAndActiveEvents: ChannelEvent[]
  pastEvents: ChannelEvent[]
  channelSlug: string
}

export const EventScheduleTabs: FC<Props> = ({
  futureAndActiveEvents,
  pastEvents,
  channelSlug
}) => {
  const location = useLocation()

  const startIndex = location.pathname.indexOf('/event/')
  const eventFilter = location.pathname.substring(startIndex + 7)

  const paths = ['/schedule', '/classes', '/locations']
  const isFiltered = paths.some((t) => eventFilter.includes(t))
  const calculatedEventSlug = eventFilter.substring(0, eventFilter.indexOf('/'))

  const eventPathFilter = isFiltered ? calculatedEventSlug : eventFilter

  const [initialEventPath] = useState(eventPathFilter)
  const [eventData, setEventData] = useState<ChannelEvent | undefined>(
    undefined
  )
  const [selectedTabId, setSelectedTabId] = useState<string | undefined>()

  useEffect(() => {
    if (futureAndActiveEvents?.length > 0) {
      const pathEvent = futureAndActiveEvents?.find(
        (e: ChannelEvent) => e.eventSlug === initialEventPath
      )
      if (pathEvent) {
        setEventData(pathEvent)
        setSelectedTabId(pathEvent.id)
      } else {
        const pastEvent = pastEvents?.find(
          (e: ChannelEvent) => e.eventSlug === initialEventPath
        )
        if (pastEvent) {
          setEventData(pastEvent)
          setSelectedTabId(PAST_GAMES_TAB_ID)
        }
        const locationPathnameLastIndex = location.pathname.lastIndexOf('/')
        if (
          location.pathname.substring(locationPathnameLastIndex) === '/games'
        ) {
          setEventData(futureAndActiveEvents[0])
          setSelectedTabId(futureAndActiveEvents[0].id)
        }
      }
    } else {
      if (pastEvents?.length > 0) {
        const pastEvent = pastEvents.find(
          (e: ChannelEvent) => e.eventSlug === initialEventPath
        )
        if (pastEvent !== undefined) {
          setEventData(pastEvent)
          setSelectedTabId(PAST_GAMES_TAB_ID)
        } else {
          setEventData(pastEvents[0])
          setSelectedTabId(PAST_GAMES_TAB_ID)
        }
      }
    }
  }, [futureAndActiveEvents, pastEvents])

  const onTabSelected = (itemSelected: string) => {
    setSelectedTabId(itemSelected)
    if (itemSelected === PAST_GAMES_TAB_ID) {
      const pastEvent = pastEvents.find(
        (e: ChannelEvent) => e.id === itemSelected
      )
      setEventData(pastEvent || pastEvents[0])
    } else {
      setEventData(
        futureAndActiveEvents.find((e: ChannelEvent) => e.id === itemSelected)
      )
    }
  }

  const onPastEventSelected = (id: string) => {
    const pastEvent = pastEvents.find((e: ChannelEvent) => e.id === id)
    setEventData(pastEvent)
  }

  if (eventData)
    return (
      <ContentContainer
        hasLeftRightPadding
        hasTopBottomPadding
        hasLightBackground
      >
        <TabListContainer
          tabListData={futureAndActiveEvents}
          onTabSelected={onTabSelected}
          selectedTabId={selectedTabId || futureAndActiveEvents[0].id}
          channelSlug={channelSlug}
          eventData={eventData}
          pastEvents={pastEvents}
          onPastEventsSelected={onPastEventSelected}
        />
      </ContentContainer>
    )
  else return null
}
