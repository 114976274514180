import React, { lazy, Suspense, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useFeatureFlags } from './useFeatureFlags'
import { FeatureFlagsAvailable } from './FeatureFlagsAvailable'
import useAdminAccess from 'hooks/AdminAccess'

const LazyFeatureFlagsModal = lazy(() =>
  import('./FeatureFlagsModal').then(({ FeatureFlagsModal }) => ({
    default: FeatureFlagsModal
  }))
)

export const FeatureFlags = () => {
  const [mounted, setMounted] = useState<boolean>(false)
  const { getFlag, setFlag } = useFeatureFlags()
  const showFeatureFlags = getFlag(FeatureFlagsAvailable.FeatureFlags)
  const { isSuperadmin } = useAdminAccess()

  useEffect(() => {
    if (isSuperadmin && !getFlag(FeatureFlagsAvailable.FeatureFlags)) {
      setFlag(FeatureFlagsAvailable.FeatureFlags, true)
    }
  }, [isSuperadmin])

  useEffect(() => {
    if (showFeatureFlags) {
      const modal = document.createElement('div')
      modal.setAttribute('id', 'featureFlags')
      document.body.appendChild(modal)
      setMounted(true)
    }

    return () => {
      const element = document.getElementById('featureFlags')
      if (element) {
        element.remove()
      }
    }
  }, [])

  const element = document.getElementById('featureFlags')
  const shouldRender = showFeatureFlags && mounted && element

  return shouldRender ? (
    createPortal(
      <Suspense fallback={null}>
        <LazyFeatureFlagsModal />
      </Suspense>,
      element
    )
  ) : (
    <></>
  )
}
