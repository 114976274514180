import React, { FC, useCallback, useMemo, useState } from 'react'

import { t, Trans } from '@lingui/macro'
import { TextArea } from 'components/Forms/TextArea/TextArea'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import Button, { ButtonType } from 'components/buttons/button/Button'
import BroadcastThumbnail from 'components/cards/BroadcastThumbnail'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { QuickEdit, patchQuickEdit } from 'api/stream/patchQuickEdit'
import dayjs from 'dayjs'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { generalStreamInfoValidationSchema } from './GeneralStreamInfoValidationSchema'
import { FormDropFileZone } from 'components/DropFileZone/FormDropZone'
import { UPLOAD_TYPE } from 'components/DropFileZone/UploadType'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { Divider } from 'components/page/header/components/hamburgerMenuContent/divider/Divider'
import styles from './GeneralStreamInformationForm.module.scss'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { useDataApi } from 'api/useDataApi'
import { getQuickEdit } from 'api/stream/getQuickEdit'
import { Loader } from 'components/Loader/Loader'
import { DateFieldsSection } from './DateFieldsSection'
import { VideoPlayerData } from 'features/viewerPage/viewerPage.interfaces'

interface Props {
  onClose: () => void
}

interface GeneralStreamInfoValues {
  thumbnailImageUrl: string
  imageFile?: any
  title: string
  description: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  manualEdit?: boolean
}

export const GeneralStreamInformationForm: FC<Props> = ({ onClose }) => {
  const stream = useSelector(streamSelector)
  const [showUploadZone, setShowUploadZone] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const mediaPlayerData: VideoPlayerData = stream.data
  const streamIdent = mediaPlayerData.mediaObject.id
  const showDateContainer = mediaPlayerData.mediaObject.type === 'livestream'

  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getQuickEdit, []),
    parameters: useMemo(
      () => ({
        mediaObjectIdent: streamIdent
      }),
      [streamIdent]
    )
  })
  if (isLoading && !data) {
    return (
      <div className={styles.LoaderContainer}>
        <Loader />
      </div>
    )
  }
  if (isError) {
    return (
      <div>
        <Trans>Something went wrong. Try again</Trans>
      </div>
    )
  }
  if (data.data) {
    const showManualEdit = data.data?.showManualEdit

    const formatTime = (timestamp: number) => dayjs(timestamp).format('HH:mm')
    const formatDate = (timeStamp: number) =>
      dayjs(timeStamp).format('YYYY-MM-DD')
    const formatedStartTime = formatTime(data?.data?.startTime)
    const formatedStartDate = formatDate(data?.data?.startTime)
    const formatedEndDate = formatDate(data?.data?.endTime)
    const formatedEndTime = formatTime(data?.data?.endTime)

    const initialValues: GeneralStreamInfoValues = {
      thumbnailImageUrl: data?.data?.thumbnailImageUrl || '',
      title: data.data.title,
      description: data.data.description,
      startDate: formatedStartDate || '',
      startTime: formatedStartTime || '',
      endDate: formatedEndDate || '',
      endTime: formatedEndTime || '',
      imageFile: undefined,
      manualEdit: data?.data?.manualEdit
    }

    const onSubmit = async (
      values: GeneralStreamInfoValues,
      formikHelpers: FormikHelpers<any>
    ) => {
      formikHelpers.setSubmitting(true)
      const combinedStartDateTime = dayjs(
        `${values.startDate} ${values.startTime}`,
        'YYYY-MM-DD HH:mm'
      )
      const combinedEndDateTime = dayjs(
        `${values.endDate} ${values.endTime}`,
        'YYYY-MM-DD HH:mm'
      )
      if (!combinedStartDateTime.isBefore(combinedEndDateTime)) {
        formikHelpers.setFieldError(
          'endTime',
          t`End time needs to be after start time`
        )
      } else {
        const params: QuickEdit = {
          ident: mediaPlayerData.mediaObject.id,
          title: values.title,
          description: values.description,
          startTime: combinedStartDateTime.unix(),
          endTime: combinedEndDateTime.unix(),
          imageFile: values.imageFile,
          manualEdit: values.manualEdit
        }
        const response = await patchQuickEdit({
          ...params
        })
        if (!response.isSuccess) {
          if (response.message) {
            setErrorMessage(response.message)
          } else {
            setErrorMessage(
              t`Something went wrong when tying to update the stream information, please try again`
            )
          }
        } else {
          onClose()
        }
      }
      formikHelpers.setSubmitting(false)
    }
    const manualEditInfoText = t`This game is automatically updated. Turn on manual edit If you want to change the values in the fields below.`
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={generalStreamInfoValidationSchema}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          const handleUploadImageClick = () => {
            if (showUploadZone) {
              setShowUploadZone(false)
              props.setFieldValue('imageFile', undefined)
            } else {
              setShowUploadZone(true)
            }
          }
          const onManualEditChange = () => {
            props.setFieldValue('manualEdit', !props.values.manualEdit)
            props.setFieldTouched('manualEdit', true, false)
          }
          return (
            <Form className={styles.FormContainer}>
              {mediaPlayerData.thumbnailObject && (
                <div className={styles.ThumbnailContainer}>
                  <BroadcastThumbnail
                    content={mediaPlayerData.thumbnailObject}
                    shared={false}
                    showDetails={false}
                    size={isMobile ? 'keepRatio' : 'small'}
                    disableLink={true}
                  />
                  {showUploadZone && (
                    <FormDropFileZone
                      formFieldName="imageFile"
                      fileUrlFieldName="thumbnailImageUrl"
                      type={UPLOAD_TYPE.IMAGE}
                      formik={props}
                      recommendedImageHeight="1920px"
                      recommendedImageWidth="1080px"
                    />
                  )}
                  <Button
                    type={ButtonType.Secondary}
                    label={showUploadZone ? t`cancel` : t`Upload thumbnail`}
                    buttonSize={'Small'}
                    onClick={handleUploadImageClick}
                  />
                </div>
              )}
              <div className={styles.Fields}>
                {showManualEdit && (
                  <div className={styles.ToggleSwitchContainer}>
                    {isMobile && (
                      <div className={styles.Text}>
                        <Divider />
                        <Trans>
                          This game is automatically updated. Turn on manual
                          edit if you want to change the data in the fields
                          below.
                        </Trans>
                      </div>
                    )}
                    <ToggleSwitch
                      isOn={props.values.manualEdit}
                      id="manualEdit"
                      label={t`Manual edit`}
                      onToggle={onManualEditChange}
                    />
                    {!isMobile && (
                      <InfoTooltip
                        title={t`Manual edit`}
                        text={manualEditInfoText}
                      />
                    )}
                  </div>
                )}
                <Field
                  component={TextInput}
                  id="title"
                  name="title"
                  label={t`Title`}
                  placeholder={t`Write the title of the stream here`}
                  onChange={props.handleChange}
                  textValue={props.values.title}
                  isValid={!props.errors.title}
                  validationMessage={props.errors.title}
                  isRequired={false}
                  readonly={!props.values.manualEdit}
                />
                <Field
                  component={TextArea}
                  id="description"
                  label={t`Description`}
                  placeholder={t`Write the description of the stream here`}
                  readonly={!props.values.manualEdit}
                />
                {showDateContainer && <DateFieldsSection formik={props} />}
                <div className={styles.Buttons}>
                  {errorMessage && (
                    <FormValidationError errorMessage={errorMessage} />
                  )}
                  <FormActionButtons
                    formik={props}
                    cancelButtonLabel={t`Cancel`}
                    submitButtonLabel={t`Save`}
                    onCancel={onClose}
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  } else return null
}
