import { useEffect, useState } from 'react'
import AdjustingInterval from '../helpers/adjustingInterval'
import ApiEndpoint from '../network/ApiEndpoint'

export enum SType {
  NONE = 'none',
  PLAYCHANNEL = 'playchannel',
  EMBED = 'embed',
  FACEBOOK = 'facebook',
  SOLIDSPORT = 'solidsport'
}

export enum VideoStatisticsPostType {
  START = 'start',
  UPDATE = 'update',
  END = 'end'
}

export type VideoStatisticsMetaData = {
  total_time_played: number
  total_time_diff: number
  total_time_posted: number
  update_counter: number
}

export type VideoStatisticsData = {
  s_domain: string
  s_type: SType
  play_latency: number | undefined
  start_latency: number | undefined
  stored_play_time: number | undefined
  buffer_count: number | undefined
  event_count: number | undefined
}

export type VideoStatisticsInitialTimestamps = {
  play_latency: number | undefined
  start_latency: number | undefined
}

export type VideoStatisticsListner = (
  statisticsData: VideoStatisticsData
) => void

export type VideoStatisticsConstructorArgs = {
  videoPlayer: HTMLVideoElement
  media_object: string
  domain: string
}

export interface VideoStatisticsInterface {
  videoPlayer: HTMLVideoElement
  initialTimestamps: VideoStatisticsInitialTimestamps | undefined
  media_object: string
  data: VideoStatisticsData
  listeners: VideoStatisticsListner[]
  updater: AdjustingInterval | undefined
  addListener: (listener: VideoStatisticsListner) => void
  sendEvents: () => void
  postData: (type: VideoStatisticsPostType) => Promise<void>
}

type VideoStatisticsStoreHash = {
  [key: string]: VideoStatisticsClass
}
const VideoStatisticsStore: VideoStatisticsStoreHash = {}

export class VideoStatisticsClass implements VideoStatisticsInterface {
  initialTimestamps: VideoStatisticsInitialTimestamps = {
    play_latency: undefined,
    start_latency: undefined
  }

  videoPlayer: HTMLVideoElement

  media_object: string

  data: VideoStatisticsData

  listeners: VideoStatisticsListner[] = []

  updater: AdjustingInterval | undefined

  started = false

  firstPlay = true

  referrer = window.location.href

  autoUpdateInterval = 120 // Post time played every 2 minutes

  meta: VideoStatisticsMetaData = {
    total_time_played: 0,
    total_time_diff: 0,
    total_time_posted: 0,
    update_counter: 0
  }

  constructor({
    videoPlayer,
    media_object,
    domain
  }: VideoStatisticsConstructorArgs) {
    this.videoPlayer = videoPlayer
    this.media_object = media_object
    this.data = {
      s_domain: domain,
      s_type: SType.NONE,
      play_latency: undefined,
      start_latency: undefined,
      stored_play_time: undefined,
      buffer_count: undefined,
      event_count: undefined
    }
  }

  stop = () => {
    // eslint-disable-next-line no-unused-expressions
    this.updater?.stop()
    const { play_latency, start_latency, stored_play_time } = this.data
    if (
      start_latency ||
      play_latency ||
      (stored_play_time && stored_play_time > 0)
    ) {
      this.postData(VideoStatisticsPostType.END).then(() => {})
    }
    delete VideoStatisticsStore[this.media_object]
  }

  addListener = (listener: VideoStatisticsListner) => {
    this.listeners.push(listener)
  }

  sendEvents = () => {
    this.listeners.forEach((listener) => listener(this.data))
  }

  postData = async (type: VideoStatisticsPostType) => {
    return new Promise<void>((resolve) => {
      const params: any = {}
      let method: string | undefined
      params.play_latency = this.data.play_latency
      params.start_latency = this.data.start_latency
      params.s_type = 'none'
      params.s_referrer = this.referrer
      if (type === VideoStatisticsPostType.START) {
        method = 'POST'
      } else {
        params.event_count = this.data.event_count
        params.buffer_count = this.data.buffer_count
        params.stored_play_time = this.data.stored_play_time
        params.type = type
        method = 'PATCH'
      }
      ApiEndpoint.call({
        path: `video_player/${this.media_object}/statistics/session`,
        method: method,
        params: params
      })
        .then(() => {
          resolve()
          this.resetData()
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  countBuffer = () => {
    if (this.data.buffer_count === undefined) {
      this.data.buffer_count = 0
    }
    this.data.buffer_count += 1
  }

  countEvent = () => {
    if (this.data.event_count === undefined) {
      this.data.event_count = 0
    }
    this.data.event_count += 1
  }

  setInitialPlayLatency = () => {
    const now = Date.now()
    this.initialTimestamps.play_latency = now
  }

  setPlayLatency = () => {
    if (this.initialTimestamps.play_latency) {
      const now = Date.now()
      this.data.play_latency = now - this.initialTimestamps.play_latency
    }
  }

  setInitialStartLatency = () => {
    this.initialTimestamps.start_latency = Date.now()
  }

  setStartLatency = () => {
    if (this.initialTimestamps.start_latency) {
      this.data.start_latency =
        Date.now() - this.initialTimestamps.start_latency
    }
  }

  updatePlayTime = () => {
    this.data.stored_play_time = Number(
      (this.meta.total_time_played - this.meta.total_time_posted).toFixed(2)
    )
  }

  resetData = () => {
    this.data.stored_play_time = 0
    this.data.event_count = 0
    this.data.buffer_count = 0
  }

  startSession = async () => {
    if (!this.started) {
      this.started = true
      this.setInitialStartLatency()
      this.videoPlayer.addEventListener('playing', () => {
        if (this.data.stored_play_time === undefined) {
          this.data.stored_play_time = 0
        }
        if (this.firstPlay) {
          if (!this.data.start_latency) {
            this.setPlayLatency()
          }
          if (!this.data.start_latency) {
            this.setStartLatency()
          }
          this.postData(VideoStatisticsPostType.START)
          this.firstPlay = false
        }
      })
      this.videoPlayer.addEventListener('pause', () => {
        this.countEvent()
      })
      this.videoPlayer.addEventListener('seeking', () => {
        this.meta.total_time_diff =
          this.meta.total_time_played - this.videoPlayer.currentTime
      })
      this.videoPlayer.addEventListener('seeked', () => {
        this.updatePlayTime()
        this.countEvent()
      })
      this.videoPlayer.addEventListener('timeupdate', () => {
        this.meta.total_time_played +=
          this.videoPlayer.currentTime +
          this.meta.total_time_diff -
          this.meta.total_time_played
        this.updatePlayTime()
      })
      this.videoPlayer.addEventListener('waiting', () => {
        this.countBuffer()
      })

      this.videoPlayer.addEventListener('ended', () => {
        this.postData(VideoStatisticsPostType.END).then(() => {
          this.meta.total_time_posted = this.meta.total_time_played
          this.updatePlayTime()
        })
      })

      this.updater = new AdjustingInterval(() => {
        this.meta.update_counter += 1
        this.sendEvents()
        if (this.meta.update_counter % this.autoUpdateInterval === 0) {
          this.data.stored_play_time =
            this.meta.total_time_played - this.meta.total_time_posted
          if (!this.videoPlayer.paused) {
            this.postData(VideoStatisticsPostType.UPDATE)
              .then(() => {
                this.meta.total_time_posted = this.meta.total_time_played
              })
              .catch(() => {})
          }
        }
      }, 1000)
    }
  }
}

export const VideoStatistics = (
  media_object: string | undefined,
  args: VideoStatisticsConstructorArgs | undefined = undefined
): VideoStatisticsClass | undefined => {
  if (media_object) {
    if (args && !VideoStatisticsStore[media_object]) {
      VideoStatisticsStore[media_object] = new VideoStatisticsClass(args)
    }
    return VideoStatisticsStore[media_object]
  }
  return undefined
}

export const ListenForVideoStatistics = (
  media_object: string,
  listener: (videoStatistics: VideoStatisticsClass) => void
  // eslint-disable-next-line no-undef
): NodeJS.Timeout => {
  const setIntervalId = setInterval(() => {
    const videoStatistics = VideoStatistics(media_object)
    if (videoStatistics) {
      // This will go forever if the videoStatistics is never created
      clearInterval(setIntervalId)
      listener(videoStatistics)
    }
  }, 1000)

  return setIntervalId
}

export type VideoStatisticsArgs = {
  videoPlayer: HTMLVideoElement | undefined
  media_object: string | undefined
  domain: string | undefined
}

export const useVideoStatistics = (
  args: VideoStatisticsArgs
): VideoStatisticsClass | undefined => {
  const [videoStatistics, setVideoStatistics] = useState<
    VideoStatisticsClass | undefined
  >()
  useEffect(() => {
    if (!videoStatistics && args.videoPlayer) {
      setVideoStatistics(
        VideoStatistics(
          args.media_object,
          args as VideoStatisticsConstructorArgs
        )
      )
    }
    return () => {
      if (videoStatistics && videoStatistics.updater) {
        videoStatistics.stop()
        setVideoStatistics(undefined)
      }
    }
  }, [args.videoPlayer, videoStatistics])

  return videoStatistics
}

export const useVideoStatisticsData = (
  media_object: string
): Readonly<VideoStatisticsData> | undefined => {
  const [videoStatistics, setVideoStatistics] = useState<
    VideoStatisticsClass | undefined
  >(VideoStatistics(media_object))
  const [videoStatisticsData, setVideoStatisticsData] = useState<
    VideoStatisticsData | undefined
  >(undefined)
  useEffect(() => {
    // eslint-disable-next-line no-undef
    let intervalId: NodeJS.Timeout | undefined
    if (videoStatistics) {
      videoStatistics.addListener((data) => {
        setVideoStatisticsData(data)
      })
    } else {
      intervalId = ListenForVideoStatistics(
        media_object,
        (initialVideoStatistics) => {
          // remove listener after some time.
          setVideoStatistics(initialVideoStatistics)
          setVideoStatisticsData(initialVideoStatistics.data)
        }
      )
    }
    return () => {
      // eslint-disable-next-line no-undef
      if (intervalId) clearInterval(intervalId)
    }
  }, [videoStatistics])
  return videoStatisticsData
}
