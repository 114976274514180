import SOLIDSPORT_API_PATHS from '../../constants/solidsport_api_paths.constans'
import { TimelineObject } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getUpcomingGameScheduleContent = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  // https://solidsport.com/api/play_v1/timeline_objects/collections/games?company=tullingetp-herrrar&page=1&per_page=10
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: SOLIDSPORT_API_PATHS.TIMELINE_OBJECTS.COLLECTIONS.GAMES,
      params: {
        company: slug,
        per_page: perPageCount,
        page,
        include_games_without_livestreams: true
      }
    })
    const responseData = await resultResponse
    const mapped = mapUpcomingGameSchedulelResponse(responseData.data)
    return { data: mapped, totalCount: responseData.totalCount }
  } catch (e) {
    console.log('error', e)
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapUpcomingGameSchedulelResponse = (
  apiResponse: TimelineObject[]
) =>
  apiResponse.map((game: TimelineObject) => ({
    id: game.ident,
    channelName: game.display_company?.name,
    channelSlug:
      game?.display_company.subdomain || game?.event?.company?.subdomain,
    label: game?.event?.name || game?.sport?.name,
    labelSlug: game?.event?.company?.subdomain,
    startTime: game.start_at,
    endTime: game.end_at,
    hasLivestream: game.has_livestream,
    hasReplay: game.is_replay,
    hasBeenPlayed: game.is_replay,
    showResult: game.show_results,
    highlightSlug: game.highlight_slug,
    homeTeam: {
      name: game.home_team.name,
      slug: game.home_team.slug,
      logo: game.home_team.badge_image.md,
      score: game.home_score
    },
    awayTeam: {
      name: game.away_team.name,
      slug: game.away_team.slug,
      logo: game.away_team.badge_image.md,
      score: game.away_score
    },
    rootDomain: game.root_level_domain,
    displayChannelSlug: game.display_company.subdomain
  }))
