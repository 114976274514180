import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const getSubscriptionFromIdent = async ({
  channelSlug,
  ident
}: {
  channelSlug: string
  ident: string
}) => {
  try {
    if (ident) {
      const resultResponse: ApiResponse = await ApiEndpoint.call({
        path: `subscriptions/${ident}`,
        params: {
          company: channelSlug
        },
        authenticatable: true
      })
      const responseData = await resultResponse
      return {
        data: responseData.data
      }
    }
  } catch (e) {
    console.log('error', e)
    // @ts-ignore
    throw new Error(e.message)
  }
}
