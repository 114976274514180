import { ChannelTypes } from '../channelType'
import { ChannelMenuLink } from './menuLinks'

const clubAndLeague = [
  ChannelTypes.club.toString(),
  ChannelTypes.league.toString()
]

const filterLink = (context: any) => (link: ChannelMenuLink) => {
  const { isRootPage, content, channelType, store, misc, showStatsSubmenu } =
    context

  const showStats =
    [ChannelTypes.league].includes(channelType) && showStatsSubmenu

  const showGroups =
    content.has_child_channels && channelType === ChannelTypes.event.toString()

  switch (link.link) {
    case '/news':
      return !!content?.has_articles
    case '/districts':
      return misc?.relations?.has_districts
    case '/clubs':
      return misc?.relations?.has_clubs
    case '/leagues':
      return misc?.relations?.has_leagues
    case '/teams':
      return isRootPage && channelType !== channelType.league
        ? misc?.relations?.has_teams
        : clubAndLeague.some((type) => type === channelType)
    case '/events':
      return [ChannelTypes.district, ChannelTypes.association].includes(
        channelType
      )
    case '/groups':
      return showGroups
    case '/subscriptions':
      return (
        (store?.has_all_access ||
          store?.has_subscriptions ||
          store?.has_monthly_supporter) &&
        [ChannelTypes.team, ChannelTypes.club].includes(channelType)
      )
    case '/access':
      return (
        (store?.has_all_access ||
          store?.has_subscriptions ||
          store?.has_monthly_supporter) &&
        [
          ChannelTypes.association,
          ChannelTypes.district,
          ChannelTypes.league,
          ChannelTypes.event
        ].includes(channelType)
      )
    case '/games':
      return content?.has_games
    case '/stats':
      return showStats
    default:
      return !link.hidden
  }
}

export const getFilteredMenuLinks = (links: ChannelMenuLink[], context: any) =>
  links.filter(filterLink(context))
