import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
import { OpenLoginButton } from 'components/buttons/UserAccessButtons/OpenLoginButton'
import { OpenSignupButton } from 'components/buttons/UserAccessButtons/OpenSignupButton'
import styles from './NavigationContent.module.scss'
import { LanguageMenuLink } from 'components/SidebarNavigation/MenuItem/LanguageMenuLink/LanguageMenuLink'

interface Props {
  onHelpClick: () => void
  showLanguageSelector?: boolean
  onLanguageSelectorClick?: () => void
}

export const SignedOutContent: FC<Props> = ({
  onHelpClick,
  showLanguageSelector = false,
  onLanguageSelectorClick
}) => {
  return (
    <div className={styles.Container}>
      <div className={styles.TopContainer}>
        {showLanguageSelector && onLanguageSelectorClick && (
          <LanguageMenuLink onClick={onLanguageSelectorClick} />
        )}
        <div className={styles.ButtonContainer}>
          <OpenLoginButton />
          <OpenSignupButton />
        </div>
        <div className={styles.HelpText}>
          <ClickableTextButton
            defaultText={t`Need any help?`}
            clickableText={t`Click here!`}
            onClick={onHelpClick}
          />
        </div>
      </div>
    </div>
  )
}
