/* eslint-disable no-unused-expressions */
import React, { memo, useEffect, useRef } from 'react'
import { MediaObject } from '../../../interfaces/MediaObject.interface'
import { VideoPlayerControls } from '../VideoPlayerControls/VideoPlayerControls'
import { VideoPlayerProvider } from '../../../providers/VideoPlayerProvider/VideoPlayerProvider'

import './index.scss'
import { ViewerStreamUrls } from 'features/viewerPage/viewerPage.interfaces'

type VideoPlayerProps = {
  mediaObject?: MediaObject | undefined
  streamUrls?: ViewerStreamUrls
  autoPlay?: boolean
  seekTo?: number
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  mediaObject = undefined,
  streamUrls = { hls: undefined, dvr: undefined, dash: undefined },
  autoPlay = true,
  seekTo = undefined
}) => {
  useEffect(() => {}, [mediaObject, streamUrls])
  const videoWrapper = useRef<HTMLDivElement>(null)

  const getAdTag = (): string | undefined => {
    if (mediaObject) {
      return mediaObject.ad_tag_url
    }
    return undefined
  }
  return (
    <div id="VideoPlayer" ref={videoWrapper}>
      <VideoPlayerProvider
        adsURL={getAdTag()}
        mediaObject={mediaObject}
        subtitles={mediaObject?.subtitles}
        thumbnail={mediaObject?.thumbnail}
        ident={mediaObject?.stats_handler_ident}
        autoPlay={autoPlay}
        company={mediaObject?.company?.subdomain}
        streamUrls={streamUrls}
        seekTo={seekTo}
        videoWrapper={videoWrapper}
      >
        <VideoPlayerControls
          isLive={mediaObject?.type === 'livestream'}
          hasSubtitles={mediaObject?.has_subtitles}
        />
      </VideoPlayerProvider>
    </div>
  )
}

export default memo(VideoPlayer)
