import React, { FC, useState } from 'react'
import { ColumnVariants, StandingsInterface } from './Standings.interface'
import ContentSlider from 'components/sliders/ContentSlider'
import { MobileStandingsHeader } from './StandingsHeader/MobileStandingsHeader'
import { ExpandedToggle } from './ExpandedToggle/ExpandedToggle'
import { MobileColumns } from './MobileColumns/MobileColumns'
import styles from './MobileStandingsTable.module.scss'
import { getTableBasedOnTeamSlug } from './getTableBasedOnTeam'
import { getArrowToShow } from './getArrowToShow'

interface Props {
  standings: StandingsInterface
  showExpandedToggle: boolean
  teamSlug?: string
}
export const MobileStandingsTable: FC<Props> = ({
  standings,
  showExpandedToggle,
  teamSlug
}) => {
  const [isScrollingTable, setIsScrollingTable] = useState(!showExpandedToggle)
  const [expanded, setExpanded] = useState(false)
  const { columns, rows } = standings
  const listBasedOnTeam =
    teamSlug && rows && getTableBasedOnTeamSlug(rows, teamSlug)
  const rowsToUse =
    listBasedOnTeam && listBasedOnTeam?.length > 0
      ? listBasedOnTeam
      : rows.slice(0, 3)

  const rowsShown = !showExpandedToggle || expanded ? rows : rowsToUse
  const arrowToShow = getArrowToShow(rowsShown, expanded)

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.TeamList}>
          <MobileColumns
            rows={rowsShown}
            columns={columns}
            columnVariant={ColumnVariants.team}
            teamIsHighlighted={!!listBasedOnTeam}
          />
        </div>
        {isScrollingTable && <div className={styles.Divider} />}
        <ContentSlider
          leftPadding={false}
          getIsScrolling={(isScrolling) => setIsScrollingTable(isScrolling)}
        >
          <div className={styles.TableData}>
            <MobileStandingsHeader columns={columns} />
            <MobileColumns
              rows={rowsShown}
              columns={columns}
              teamIsHighlighted={!!listBasedOnTeam}
            />
          </div>
        </ContentSlider>
      </div>
      {showExpandedToggle && rows.length > 3 && (
        <ExpandedToggle
          expanded={expanded}
          onToggleExpanded={() => setExpanded((prev) => !prev)}
          arrowIconToShow={arrowToShow}
        />
      )}
    </>
  )
}
