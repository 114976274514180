import React, { FC } from 'react'
import styles from './MessageSubmitButton.module.scss'

interface Props {
  label: string
}
export const MessageSubmitButton: FC<Props> = ({ label }) => (
  <button className={styles.SubmitButton} type="submit">
    {label}
  </button>
)
