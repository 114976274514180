import React from 'react'

const UploadIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_497_106135)">
      <path
        d="M31.5 19.5L24 12L16.5 19.5"
        stroke="#2F3135"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 34.5V12"
        stroke="#2F3135"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 24C1.5 29.9674 3.87053 35.6903 8.0901 39.9099C12.3097 44.1295 18.0326 46.5 24 46.5C29.9674 46.5 35.6903 44.1295 39.9099 39.9099C44.1295 35.6903 46.5 29.9674 46.5 24C46.5 18.0326 44.1295 12.3097 39.9099 8.0901C35.6903 3.87053 29.9674 1.5 24 1.5C18.0326 1.5 12.3097 3.87053 8.0901 8.0901C3.87053 12.3097 1.5 18.0326 1.5 24V24Z"
        stroke="#2F3135"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_497_106135">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default UploadIcon
