import React, { FC } from 'react'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import styles from '../ThumbnailLabel.module.scss'

interface Props {
  highlightFromDate: number
}
const HighlightLabel: FC<Props> = ({ highlightFromDate }) => {
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  return (
    <div className={styles.Highlight} data-testid="higlight-thumbnail-label">
      {t`Highlight`} {dayjs.unix(highlightFromDate).format('D MMM')}
    </div>
  )
}
export default HighlightLabel
