import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'

export interface PatchUserPhoneNumberRequest {
  countryCode: string
  phoneNumber: string
}
export const patchUserPhoneNumber = async (
  request: PatchUserPhoneNumberRequest
) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'session/users/edit/phone_number',
      method: ApiEndpoint.METHODS.PATCH,
      params: {
        area_code: request.countryCode,
        phone_number: request.phoneNumber
      }
    })
    if (response.status === 204) {
      return { isSuccess: true }
    }
    return { isSuccess: false }
  } catch (error: any) {
    console.error('error!', error)
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
