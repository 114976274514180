import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface BlockUserFromChatProps {
  messageId: number
  mediaObjectId: string
}
export const blockUserFromChat = async (message: BlockUserFromChatProps) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `chat/${message.mediaObjectId}/block`,
      method: ApiEndpoint.METHODS.PUT,
      params: { message_id: message.messageId },
      authenticatable: true
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    throw new Error('block user from chat')
  }
}
