import { Channel } from '../../../api/channel/getChannel'
import { TargetingOptions } from '../../../advertising/useAdUnit'

export const getChannelAdTargeting = (
  channelDetails: Channel
): TargetingOptions => {
  if (channelDetails && channelDetails.category) {
    const parentChannelDomain = channelDetails.root_channel
      ? channelDetails.root_channel.subdomain
      : channelDetails.subdomain

    return {
      partnerDomain: [channelDetails.subdomain],
      parentChannelDomain: [parentChannelDomain],
      sportCategory: [channelDetails.category.translated_name!],
      countryCode: [channelDetails.country.code]
    }
  }
  return {
    partnerDomain: [],
    parentChannelDomain: [],
    sportCategory: [],
    countryCode: []
  }
}
