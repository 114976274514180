import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface AdminAccountSettings {
  channelSlug: string
  accountName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  address?: string
  postCode?: string
  city?: string
  country?: string
}

interface AdminAccountApi {
  channel_slug: string
  account_name: string
  contact_person_first_name: string
  contact_person_last_name: string
  contact_email: string
  contact_phone: string
  account_address?: string
  account_post_code?: string
  account_city?: string
  account_country_code?: string
}

interface AdminAccountSettingsResponse {
  hasError: boolean
  errors?: any
}

export const patchAdminAccount = async (
  adminDetailSettings: AdminAccountSettings
): Promise<AdminAccountSettingsResponse> => {
  const params: AdminAccountApi = {
    channel_slug: adminDetailSettings.channelSlug,
    account_name: adminDetailSettings.accountName,
    contact_person_first_name: adminDetailSettings.firstName,
    contact_person_last_name: adminDetailSettings.lastName,
    contact_email: adminDetailSettings.email,
    account_address: adminDetailSettings.address,
    account_post_code: adminDetailSettings.postCode,
    account_city: adminDetailSettings.city,
    contact_phone: adminDetailSettings.phoneNumber,
    account_country_code: adminDetailSettings.country
  }
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `/companies/${adminDetailSettings.channelSlug}/admin_account`,
      method: ApiEndpoint.METHODS.PATCH,
      authenticatable: true,
      params
    })
    await response

    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: []
    }
  }
}
