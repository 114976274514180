/* eslint-disable @typescript-eslint/indent */
import {
  TeamItemInterface,
  TeamItemStatus
} from '../../features/sportData/joinLeagueAsClub/Teams/TeamItem/TeamItemProps'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface TeamResponseApi {
  subdomain: string
  playpage_title: string
  leagues: any[]
  profile_image_url: string
  admin_email: string
  has_admin_email: boolean
}

export const getTeamsInClub = async ({
  channelSlug
}: {
  channelSlug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/channels/teams`
    })

    const responseData = await resultResponse
    const mapped: TeamItemInterface[] = mapTeamList(responseData)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapTeamList = (responseData: ApiResponse) =>
  responseData?.data[0].teams.map((item: TeamResponseApi) => ({
    teamName: item.playpage_title,
    teamSlug: item.subdomain,
    ongoingCompetitions: item.leagues.length,
    status: TeamItemStatus.Active
  }))
