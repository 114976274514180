import { ReactNode } from 'react'

import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'
import { metaFriendlyString } from 'helpers/generalHelpers'

export const getRichBreadcrumbMetaData = (
  breadcrumbs: BreadCrumbLink[]
): ReactNode => {
  const baseUrl = window.location.host
  const protocol = window.location.protocol

  const itemListElements = breadcrumbs.map(
    (crumb: BreadCrumbLink, i: number) => `{
    "@type": "ListItem",
    "position": ${i + 1},
    "name": "${metaFriendlyString(crumb.name)}",
    "item": "${protocol}//${baseUrl}${
      crumb.link.substring(0, 1) === '/' ? crumb.link : '/' + crumb.link
    }"
  }`
  )

  return `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [ ${itemListElements}]
}`
}

/* google's example
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Books",
    "item": "https://example.com/books"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "Science Fiction",
    "item": "https://example.com/books/sciencefiction"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "Award Winners"
  }]
}
*/
