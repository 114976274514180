import React, { FC } from 'react'
import { CustomContentAdminItem } from 'features/customContent/CustomContentAdminItem/CustomContentAdminItem'
import { CallToActionOverlay } from '../CallToActionOverlay/CallToActionOverlay'
import { HeroImageCallToAction } from '../NewHeroImagePromotionModal/Form/HeroImageCallToActionForm'
import styles from './HeroImageCallToActionItem.module.scss'

interface Props {
  channelSlug: string
  heroImageCallToActionData: HeroImageCallToAction
  onEdit: () => void
  onRemove: () => void
}

export const HeroImageCallToActionAdminItem: FC<Props> = ({
  channelSlug,
  heroImageCallToActionData,
  onEdit,
  onRemove
}) => {
  return (
    <div
      className={styles.AdminItemContainer}
      data-testid="hero-image-call-to-action-admin-item"
    >
      <CustomContentAdminItem
        channelSlug={channelSlug}
        onEdit={onEdit}
        onRemove={onRemove}
      >
        <div className={styles.PromotionContainer}>
          <CallToActionOverlay
            title={heroImageCallToActionData.title}
            text={heroImageCallToActionData.text}
            buttonText={heroImageCallToActionData.textField}
            buttonLink={heroImageCallToActionData.linkField}
            target={heroImageCallToActionData.target!}
          />
        </div>
      </CustomContentAdminItem>
    </div>
  )
}
