import React, { FC } from 'react'

import ContentContainer from 'components/ContentContainer'

import styles from './CustomPage.module.scss'

interface Props {
  children: React.ReactNode
}

export const CustomPageContainer: FC<Props> = ({ children }) => (
  <ContentContainer
    hasLeftRightPadding={true}
    hasTopBottomPadding={true}
    hasLightBackground={true}
  >
    <div className={styles.Container}>{children}</div>
  </ContentContainer>
)
