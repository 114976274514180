import PAYMENTS from './payments.constants'

export interface Payments {
  paymentsOpen: boolean
  closeIconClicked: boolean
  voucherOpen: boolean
  currentPayment: {
    value: number
    currency: string
    mediaObjectId: string
    subscription: any
    mediaObjectTitle: string
  }
  paymentFailed: boolean
  unlockedId: string
  giftCardReferenceId: string
}

export const initialState: Payments = {
  paymentsOpen: false,
  closeIconClicked: false,
  voucherOpen: false,
  currentPayment: {
    value: 0,
    currency: '',
    mediaObjectId: '',
    subscription: undefined,
    mediaObjectTitle: ''
  },
  paymentFailed: false,
  unlockedId: '',
  giftCardReferenceId: ''
}

function payments(state = initialState, action: any) {
  switch (action.type) {
    case PAYMENTS.OPEN_PAYMENT:
      return {
        ...state,
        paymentsOpen: true,
        closeIconClicked: false,
        currentPayment: action.regards
      }
    case PAYMENTS.PAYMENT_SUCCESS:
      return {
        ...state,
        unlockedId: action.regards
      }
    case PAYMENTS.HAS_GIFT_CARD:
      return {
        ...state,
        giftCardReferenceId: action.giftCardReferenceId
      }
    case PAYMENTS.PAYMENT_FAILED:
      return {
        ...state,
        paymentFailed: true
      }
    case PAYMENTS.PAYMENT_ONGOING:
      return {
        ...state,
        paymentFailed: false
      }
    case PAYMENTS.ALREADY_PURCHASED:
      return {
        ...state,
        unlockedId: action.regards
      }
    case PAYMENTS.CLOSE_PAYMENT:
      return {
        ...state,
        paymentsOpen: false,
        closeIconClicked: true,
        currentPayment: {},
        unlockedId: '',
        paymentFailed: false
      }
    case PAYMENTS.RETRY_PAYMENT:
      return {
        ...state,
        paymentsOpen: true,
        closeIconClicked: false,
        unlockedId: '',
        paymentFailed: false
      }
    case PAYMENTS.VOUCHER_OPEN:
      return {
        ...state,
        voucherOpen: action.open
      }
    default:
      return state
  }
}

export default payments
