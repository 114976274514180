import React, { FC } from 'react'
import classNames from 'classnames'

import { DomainStatusIconSelector } from './DomainStatus/DomainStatusIconSelector'
import styles from './CustomDomainField.module.scss'
import { DomainRegistrationStatus } from './DomainStatus/DomainRegistrationStatus'

interface CustomDomainFieldProps {
  id: string
  label: string
  placeholder: string
  isValid: boolean
  textValue: string
  domainStatus: DomainRegistrationStatus
  domainStatusDescription: string
  onChange: () => void
}

export const CustomDomainField: FC<CustomDomainFieldProps> = ({
  id,
  label,
  placeholder,
  isValid,
  textValue,
  domainStatus,
  domainStatusDescription,
  onChange
}) => (
  <div className={styles.TextFieldContainer}>
    <div className={styles.TextFieldLabels}>
      <label className={styles.PrimarayLabel} htmlFor={id}>
        {label}
      </label>
      <div className={styles.DomainStatus}>
        <label className={styles.SecondaryLabel} htmlFor={id}>
          {domainStatusDescription}
        </label>
        <DomainStatusIconSelector domainStatus={domainStatus} />
      </div>
    </div>
    <input
      className={classNames(styles.TextField, { [styles.Error]: !isValid })}
      placeholder={placeholder}
      id={id}
      onChange={onChange}
      value={textValue}
    />
  </div>
)
