import React, { FC } from 'react'

export const TicketsIcon: FC = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.51733 10.1635C1.51733 9.2069 2.29284 8.4314 3.24947 8.4314H19.7852C20.7418 8.4314 21.5173 9.2069 21.5173 10.1635V10.1635C21.5173 10.6525 21.3107 11.1187 20.9484 11.447L20.905 11.4863C19.7553 12.5282 19.7553 14.3346 20.905 15.3765L20.9484 15.4158C21.3107 15.7441 21.5173 16.2103 21.5173 16.6993V16.6993C21.5173 17.6559 20.7418 18.4314 19.7852 18.4314H3.24947C2.29284 18.4314 1.51733 17.6559 1.51733 16.6993V16.6993C1.51733 16.2103 1.72399 15.7441 2.08631 15.4158L2.12965 15.3765C3.27933 14.3346 3.27933 12.5282 2.12965 11.4863L2.08631 11.447C1.72399 11.1187 1.51733 10.6525 1.51733 10.1635V10.1635Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M19.7046 9.115C20.0443 9.35211 20.5118 9.26898 20.7489 8.92935C20.986 8.58971 20.9029 8.12217 20.5633 7.88506L19.7046 9.115ZM19.3997 5.43816L20.0612 5.79154L19.3997 5.43816ZM3.30102 7.14836L17.9273 2.78742L17.4987 1.34995L2.87243 5.71089L3.30102 7.14836ZM19.1714 3.46L19.2096 3.58817L20.647 3.15958L20.6088 3.03141L19.1714 3.46ZM19.1333 4.34506L18.7382 5.08479L20.0612 5.79154L20.4564 5.05182L19.1333 4.34506ZM19.2096 3.58817C19.2849 3.84066 19.2574 4.11267 19.1333 4.34506L20.4564 5.05182C20.7667 4.47082 20.8352 3.79081 20.647 3.15958L19.2096 3.58817ZM18.7382 5.08479C17.9933 6.47911 18.4085 8.21012 19.7046 9.115L20.5633 7.88506C19.89 7.41502 19.6743 6.51583 20.0612 5.79154L18.7382 5.08479ZM17.9273 2.78742C18.4566 2.62962 19.0136 2.93074 19.1714 3.46L20.6088 3.03141C20.2143 1.70826 18.8219 0.955445 17.4987 1.34995L17.9273 2.78742ZM2.87243 5.71089C1.58899 6.09356 0.872682 7.45825 1.28674 8.73191L2.71326 8.26815C2.55978 7.79605 2.82529 7.2902 3.30102 7.14836L2.87243 5.71089Z"
      fill="white"
    />
    <path
      d="M15.4656 10.2V16.7172"
      stroke="white"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M14.5522 4.80212L15.3957 8.31179"
      stroke="white"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
    <path
      d="M7.5 10.2V16.7172"
      stroke="white"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeDasharray="1.75 1.75"
    />
  </svg>
)
