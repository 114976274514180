import React, { FC } from 'react'
import { Trans } from '@lingui/macro'

import styles from './LinkSection.module.scss'
import { getHasImpressumPage } from 'pages/ChannelPage/Impressum/getHasImpressumPage'

interface Impressum {
  subdomain: string
}

interface ChannelCountry {
  code: string
}

interface Props {
  impressum: Impressum
  channelSlug: string
  channelCountry: ChannelCountry
}

export const LinkSection: FC<Props> = ({
  impressum,
  channelSlug,
  channelCountry
}) => {
  const impressumPageSubdomain = impressum?.subdomain || channelSlug
  const hasImpressumPage = getHasImpressumPage(channelCountry?.code)
  return (
    <div className={styles.LinkSectionContainer}>
      <a
        className={styles.Link}
        target="_blank"
        href="https://solidsport.freshdesk.com/en/support/home"
        rel="noreferrer"
      >
        <Trans>Support</Trans>
      </a>
      <a
        className={styles.Link}
        target="_blank"
        href="https://www.solidsport.com/en/privacy-policy/#tabs|0"
        rel="noreferrer"
      >
        <Trans>Terms &amp; Policy</Trans>
      </a>
      {hasImpressumPage && (
        <a
          className={styles.Link}
          href={`/${impressumPageSubdomain}/impressum`}
        >
          <Trans>Impressum</Trans>
        </a>
      )}
    </div>
  )
}
