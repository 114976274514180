import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import DotList from '../DotList'
import { Association } from '../cardTypes'
import Image from '../Image'
import styles from '../SearchResultCard.module.scss'

interface Props {
  content: Association
}

const SearchResultCardAssociation: FC<Props> = ({ content }) => (
  <div className={styles.Container}>
    <div
      data-testid="search-result-image-container"
      className={styles.ImageChannelContainer}
    >
      <Image imageUrl={content.imageUrl} title={content.title} />
    </div>
    <div className={styles.ContentContainer}>
      <div className={styles.Title}>
        <Link to={content.targetUrl}>{content.title}</Link>
      </div>
      <div
        data-testid="search-result-description"
        className={styles.Description}
      >
        {content.description}
      </div>
      <div className={styles.BottomFrame}>
        <div className={styles.BottomFrame}>
          <span
            data-testid="search-result-event-text"
            className={styles.EventText}
          >
            {content.event}
          </span>
          <span className={styles.LineDivider}>I</span>
          <DotList list={content.sports} />
        </div>
      </div>
    </div>
  </div>
)

export default SearchResultCardAssociation
