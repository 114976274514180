import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
export interface EventDate {
  name: string
  slug: string
}

export const getEventDates = async ({
  channelSlug,
  eventSlug
}: {
  channelSlug: string
  eventSlug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `events/event/${eventSlug}/dates`,
      params: {
        company: channelSlug,
        page: 1,
        per_page: 100
      }
    })
    const responseData = await resultResponse
    return { data: responseData }
  } catch (e: any) {
    throw new Error(e.message)
  }
}
