import React, { FC } from 'react'
import styles from './FilterItem.module.scss'
import classNames from 'classnames'
export const enum FilterType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}
interface Props {
  label: string
  type: FilterType
  slug: string
  onClick: (itemSlug: string) => void
}

export const FilterItem: FC<Props> = ({ label, type, slug, onClick }) => {
  return (
    <div
      role="button"
      className={classNames(styles.Container, styles[type])}
      onClick={() => onClick(slug)}
    >
      {label}
    </div>
  )
}
