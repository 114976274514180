import React, { FC } from 'react'
import { Field } from 'formik'
import { t, Trans } from '@lingui/macro'
import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'
import { DropdownList } from '../../../../components/Forms/DropdownListField/DropdownList'
import countries, {
  FormattedCountry
} from '../../../../helpers/formatedCountryArray'
import { ContactFormFields } from '../ContactForm/ContactFormFields'

import styles from './AccountFormFields.module.scss'

interface AccountFormFieldsProps {
  formik: any
}

export const AccountFormFields: FC<AccountFormFieldsProps> = ({ formik }) => {
  const countryList = countries.map((country: FormattedCountry) => ({
    id: country.country_code,
    label: country.name
  }))

  return (
    <div className={styles.AccountFormFieldsContainer}>
      <h4>
        <Trans>Contact Information</Trans>
      </h4>
      <p className={styles.FormDescription}>
        <Trans>
          The contact is the person who is responsible for the finances of your
          organisation, for example the Treasurer of an Association or a
          Governing Body. This helps ensure channel revenue payouts are
          allocated correctly.
        </Trans>
      </p>
      <ContactFormFields formik={formik} />
      <h4>
        <Trans>Account Details</Trans>
      </h4>
      <div className={styles.AccountDetailsContainer}>
        <Field
          component={TextInput}
          id="accountName"
          name="accountName"
          label={t`Name`}
          placeholder={t`channel/event/association`}
          onChange={formik.handleChange}
          textValue={formik.values.accountName}
          isValid={!formik.errors.accountName}
          validationMessage={formik.errors.accountName}
          isRequired
        />
        <div className={styles.AddressLineContainer}>
          <div className={styles.AddressFieldContainer}>
            <Field
              component={TextInput}
              id="address"
              name="address"
              label={t`Address`}
              placeholder={t`Street address`}
              onChange={formik.handleChange}
              textValue={formik.values.address}
              isValid={!formik.errors.address}
              validationMessage={formik.errors.address}
            />
          </div>
          <div className={styles.AddressFieldContainer}>
            <Field
              component={TextInput}
              id="postCode"
              name="postCode"
              label={t`Postcode`}
              placeholder={t`123 45`}
              onChange={formik.handleChange}
              textValue={formik.values.postCode}
              isValid={!formik.errors.postCode}
              validationMessage={formik.errors.postCode}
            />
          </div>
        </div>
        <div className={styles.AddressLineContainer}>
          <div className={styles.AddressFieldContainer}>
            <Field
              component={TextInput}
              id="city"
              name="city"
              label={t`City`}
              placeholder={t`City`}
              onChange={formik.handleChange}
              textValue={formik.values.city}
              isValid={!formik.errors.city}
              validationMessage={formik.errors.city}
            />
          </div>
          <div className={styles.AddressFieldContainer}>
            <Field
              component={DropdownList}
              dropdownListData={countryList}
              value={formik.values.country}
              id="country"
              name="country"
              label={t`Country`}
              placeholder={t`please select a country`}
              isValid={!formik.errors.country}
              validationMessage={formik.errors.country}
              type="dark"
              onChange={(id: string) => formik.setFieldValue('country', id)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
