import React from 'react'

const ReplayIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14.248V18.748H1.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71701 18.751C4.16998 17.1337 3.22625 15.0335 3.04418 12.8028C2.86211 10.5722 3.45277 8.34674 4.71706 6.49996C5.98135 4.65318 7.84234 3.29741 9.98778 2.66014C12.1332 2.02287 14.4326 2.14286 16.5 2.99999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71201 18.746C4.04976 17.0087 3.09042 14.717 3.01936 12.3136C2.94829 9.91019 3.77055 7.56576 5.32726 5.73328C6.88396 3.9008 9.06461 2.71034 11.4479 2.39191C13.8311 2.07349 16.2478 2.6497 18.231 4.00922C20.2142 5.36874 21.6231 7.41506 22.1855 9.7528C22.7479 12.0905 22.4238 14.5538 21.276 16.6665C20.1282 18.7793 18.238 20.3916 15.9706 21.192C13.7033 21.9924 11.2198 21.924 9.00001 21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 8.66439V15.3332C10.5 15.4512 10.5305 15.5671 10.5885 15.669C10.6464 15.771 10.7297 15.8553 10.8298 15.9135C10.9299 15.9716 11.0433 16.0015 11.1583 15.9999C11.2733 15.9984 11.3859 15.9656 11.4845 15.9049L16.6846 12.5714C16.7808 12.5121 16.8604 12.4283 16.9157 12.3281C16.9709 12.228 17 12.1148 17 11.9997C17 11.8845 16.9709 11.7714 16.9157 11.6712C16.8604 11.571 16.7808 11.4872 16.6846 11.4279L11.4845 8.09443C11.386 8.03406 11.2736 8.0015 11.1589 8.00005C11.0441 7.9986 10.931 8.02832 10.831 8.0862C10.731 8.14407 10.6478 8.22803 10.5896 8.32957C10.5315 8.43112 10.5006 8.54663 10.5 8.66439Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default ReplayIcon
