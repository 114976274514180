import React, { FC } from 'react'
import styles from './PurchaseListItem.module.scss'

interface Props {
  label: string
  isMobile: boolean
}

export const MobileLabel: FC<Props> = ({ label, isMobile }) => {
  return isMobile ? <div className={styles.Label}>{label}:</div> : null
}
