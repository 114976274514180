import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './InputContainer.module.scss'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { postChatMessage } from 'api/viewer/Chat/postChatMessage'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
import { MessageInput } from '../MessageInput/MessageInput'

interface InputContainerProps {
  mediaObjectId: string
}

export enum SpammingStatus {
  None = 0,
  SameMessage = 406,
  TooManyMessages = 429
}

export const InputContainer: FC<InputContainerProps> = ({ mediaObjectId }) => {
  const currentUser = useCurrentUser()
  const isViewingStream = useSelector(isViewingStreamSelector)

  const [userIsBlocked, setUserIsBlocked] = useState(false)
  const [spammingStatus, setSpammingStatus] = useState<number>(
    SpammingStatus.None
  )
  const dispatch = useDispatch()

  const sendMessage = async (textMessage: string, file: string) => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (textMessage !== '' || file) {
      setSpammingStatus(SpammingStatus.None)
      const result = await postChatMessage({
        text: textMessage,
        file,
        mediaObjectIdent: mediaObjectId
      })
      if (result.status === 403) {
        setUserIsBlocked(true)
      } else if (result.status === SpammingStatus.SameMessage) {
        setSpammingStatus(SpammingStatus.SameMessage)
      } else if (result.status === SpammingStatus.TooManyMessages) {
        setSpammingStatus(SpammingStatus.TooManyMessages)
      }
    }
    return false
  }
  return (
    <div
      className={classNames(styles.InputContainer, {
        [styles.IsViewingStream]: isViewingStream
      })}
      data-testid="chat-message-input-container"
    >
      <MessageInput
        onSuccess={(text, file) => sendMessage(text, file)}
        userIsBlocked={userIsBlocked}
        spammingStatus={spammingStatus}
        isViewingStream={isViewingStream}
      />
    </div>
  )
}
