import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Tooltip.module.scss'
import Button, { ButtonType } from 'components/buttons/button/Button'
export enum TooltipTheme {
  Light = 'light',
  Dark = 'dark'
}
interface TooltipProps {
  text: string
  title?: string
  position?: 'Top' | 'Bottom'
  pointerPosition?: 'Left' | 'Center' | 'Right'
  confirmButtonText?: string
  onConfirmButtonClick?: () => void
  theme?: TooltipTheme
}
export const Tooltip: FC<TooltipProps> = ({
  text,
  title,
  position = 'Top',
  pointerPosition = 'Center',
  confirmButtonText,
  onConfirmButtonClick,
  theme = TooltipTheme.Light
}) => {
  return (
    <div
      className={classNames(
        styles.TooltipContainer,
        styles[position],
        styles[theme]
      )}
    >
      <div className={classNames(styles.Content)}>
        {title && <div className={styles.Title}>{title}</div>}
        <div className={styles.TextBox}>{text}</div>
        {confirmButtonText && onConfirmButtonClick && (
          <div className={styles.ButtonContainer}>
            <Button
              type={ButtonType.Secondary}
              label={confirmButtonText}
              buttonSize={'XSmall'}
              onClick={onConfirmButtonClick}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.Triangle, styles[pointerPosition])} />
    </div>
  )
}
