import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Period } from './period'
import { getAutoRenewPeriodicityText } from './getAutoRenewPeriodicityText'
import styles from './CardText.module.scss'

interface Props {
  periodicity: Period
}
export const SubscriptionsHelpText: FC<Props> = ({ periodicity }) => (
  <div>
    <div className={styles.HelpText}>
      <div>{getAutoRenewPeriodicityText(periodicity)}</div>
      <div>
        <Trans> * No obligation, cancel anytime</Trans>
      </div>
    </div>
  </div>
)
