import React from 'react'

export const TicketsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5112 12.5746L22.9863 13.0813L22.5112 12.5746C20.9309 14.0561 20.9309 16.5646 22.5112 18.0461L22.8549 18.3683C23.107 18.6046 23.25 18.9347 23.25 19.2802V19.5172C23.25 20.2076 22.6904 20.7672 22 20.7672H2C1.30964 20.7672 0.75 20.2076 0.75 19.5172V19.2802C0.75 18.9347 0.89301 18.6046 1.14507 18.3683L1.48878 18.0461L0.975827 17.4989L1.48878 18.0461C3.06908 16.5646 3.06908 14.0561 1.48878 12.5746L1.14507 12.2523C0.89301 12.016 0.75 11.6859 0.75 11.3404V11.1034C0.75 10.4131 1.30964 9.85344 2 9.85344H22C22.6904 9.85344 23.25 10.4131 23.25 11.1034V11.3404C23.25 11.6859 23.107 12.016 22.8549 12.2523L22.5112 12.5746Z"
      stroke="#2F3135"
      strokeWidth="1.5"
    />
    <path
      d="M21.3596 5.85034L20.7117 5.47256L20.7117 5.47256L21.3596 5.85034ZM20.9194 6.60538L21.5673 6.98316L21.5673 6.98316L20.9194 6.60538ZM2.06267 8.90901L19.3588 3.75203L18.9302 2.31457L1.63408 7.47155L2.06267 8.90901ZM20.7117 5.47256L20.2715 6.2276L21.5673 6.98316L22.0075 6.22811L20.7117 5.47256ZM20.8228 4.54354C20.9158 4.85527 20.8756 5.19155 20.7117 5.47256L22.0075 6.22812C22.3802 5.58893 22.4717 4.824 22.2603 4.11495L20.8228 4.54354ZM20.2715 6.2276C19.4158 7.69518 19.9311 9.5793 21.4147 10.407L22.1455 9.09711C21.3951 8.67845 21.1345 7.72546 21.5673 6.98316L20.2715 6.2276ZM19.3588 3.75203C19.9817 3.56633 20.6371 3.9207 20.8228 4.54354L22.2603 4.11495C21.8379 2.69822 20.347 1.89216 18.9302 2.31457L19.3588 3.75203ZM1.63408 7.47155C0.506334 7.80779 -0.135302 8.99459 0.200943 10.1223L1.63841 9.69374C1.53887 9.35989 1.72882 9.00855 2.06267 8.90901L1.63408 7.47155Z"
      fill="#2F3135"
    />
    <path
      d="M16.5518 11.5862V19.0345"
      stroke="#2F3135"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
    <path
      d="M15.5876 5.91997L16.5516 9.93101"
      stroke="#2F3135"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
    <path
      d="M7.44824 11.5862V19.0345"
      stroke="#2F3135"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
  </svg>
)
