import React, { FC, useCallback, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { t, Trans } from '@lingui/macro'

import { useDataApi } from '../../../../../api/useDataApi'
import { SubmitButton } from '../../../../../components/buttons/SubmitButton/SubmitButton'
import {
  StatusMessage,
  StatusMessageType
} from '../../StatusMessage/StatusMessage'
import { postFontsForChannel } from '../../../../../api/channel/font/postFontsForChannel'
import { Loader } from '../../../../../components/Loader/Loader'
import { UploadFont } from './UploadFile/UploadFont'
import { FormValidationError } from '../../../../../components/Forms/FormValidationError/FormValidationError'
import { getFontsForChannel } from '../../../../../api/channel/font/getFontsForChannel'
import { CustomFontList } from './UploadedList/CustomFontList'
import { FontThemeMappingsForm } from './ThemeItems/FontThemeMappingsForm'
import { FontThemeSettings } from './fontThemeSettings'
import { CustomFontDisplayItem } from './customFontDisplayItem'

import styles from './FontFileForm.module.scss'

interface Props {
  onSubmitSuccess: () => void
  channelSlug: string
  themeFonts: FontThemeSettings
}

interface CustomFont {
  fontIdentifier: string
  fontFile?: File
}

export const FontFileForm: FC<Props> = ({
  onSubmitSuccess,
  channelSlug,
  themeFonts
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isUploadingFont, setIsUploadingFont] = useState(false)
  const [fontData, setFontData] = useState<CustomFontDisplayItem[]>([])

  const { isLoading, data, isError } = useDataApi({
    apiCall: useCallback(getFontsForChannel, [channelSlug]),
    parameters: channelSlug
  })
  if (isLoading) return <Loader />
  if (data && !isLoading && data.length > 0 && fontData.length < 1) {
    setFontData(data)
  }
  const fontFileUploadSchema = yup.object({
    fontIdentifier: yup.string().required('A font file is required'),
    fontFile: yup.mixed().required('File is required')
  })

  const initialValues: CustomFont = {
    fontIdentifier: '',
    fontFile: undefined
  }

  const postFonts = async (slug: string, formValues: any) => {
    try {
      await postFontsForChannel(slug, formValues)
      return { hasError: false }
    } catch (err: any) {
      return { hasError: true, errors: err }
    }
  }

  const onSubmit = async (
    values: CustomFont,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const fontFormValues = { ...values, channelSlug }

    setIsUploadingFont(true)
    const formData = new FormData()
    // @ts-ignore
    formData.append('font', fontFormValues.fontFile)
    formData.append('identifier', fontFormValues.fontIdentifier)
    const response = await postFonts(channelSlug, formData)
    if (response.hasError) {
      formikHelpers.setFieldError(
        'fontIdentifier',
        'There was an error uploading the file'
      )
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
      onSubmitSuccess()
      setFontData([
        ...fontData,
        {
          name: fontFormValues.fontFile!.name,
          identifier: fontFormValues.fontIdentifier
        }
      ])
    }
    setIsUploadingFont(false)
  }

  return (
    <div>
      <div className={styles.FontFileFormContainer}>
        <div className={styles.FontFileFormForm}>
          {isError && (
            <div>
              <Trans>
                There was a problem retriving the font settings data. Please
                refresh the page
              </Trans>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={fontFileUploadSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {(props: FormikProps<any>) => {
              return (
                <Form>
                  <UploadFont
                    uploadedFile={props.values.fontIdentifier}
                    onUploadAccepted={(file) => {
                      props.setFieldValue('fontIdentifier', file.name)
                      props.setFieldValue('fontFile', file)
                    }}
                    onUploadRejected={(file) =>
                      console.log('rejected', file.errors)
                    }
                    formik={props}
                  />
                  {isUploadingFont && <div>Uploading...</div>}

                  <ErrorMessage
                    name="fontIdentifier"
                    render={(msg) => <FormValidationError errorMessage={msg} />}
                  />
                  <SubmitButton
                    type="secondary"
                    disabled={!props.dirty || props.isSubmitting}
                    label={t`Upload Font`}
                  />
                  {showSuccessMessage && (
                    <StatusMessage
                      onClose={() => setShowSuccessMessage(false)}
                      message={t`the selected font was successfully uploaded`}
                      messageType={StatusMessageType.Success}
                    />
                  )}
                </Form>
              )
            }}
          </Formik>
          <CustomFontList fontList={fontData} />
          <FontThemeMappingsForm fontList={fontData} themeFonts={themeFonts} />
        </div>
      </div>
    </div>
  )
}
