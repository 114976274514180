import { t } from '@lingui/macro'
import { Price } from 'interfaces/Price.interface'
import * as yup from 'yup'

export const streamAccessValidationSchema = (minimumPrice: Price) =>
  yup.object({
    price: yup.object().shape({
      SEK: yup
        .number()
        .typeError(t`Please enter a valid number`)
        .positive(t`Must be a positive number in SEK`)
        .min(
          minimumPrice?.SEK!,
          t`The lowest price that you can set is ${minimumPrice?.SEK} SEK`
        )
        .nullable(),
      NOK: yup
        .number()
        .typeError(t`Please enter a valid number`)
        .positive(t`Must be a positive number in NOK`)
        .min(
          minimumPrice?.NOK!,
          t`The lowest price that you can set is ${minimumPrice?.NOK} NOK`
        )
        .nullable(),
      EUR: yup
        .number()
        .typeError(t`Please enter a valid number`)
        .positive(t`Must be a positive number in EUR`)
        .min(
          minimumPrice?.EUR!,
          t`The lowest price that you can set is ${minimumPrice?.EUR} EUR`
        )
        .nullable(),
      USD: yup
        .number()
        .typeError(t`Please enter a valid number`)
        .positive(t`Must be a positive number in USD`)
        .min(
          minimumPrice?.USD!,
          t`The lowest price that you can set is ${minimumPrice?.USD} USD`
        )
        .nullable()
    })
  })
