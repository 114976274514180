import { useState, useEffect, useCallback } from 'react'

export default function useExternalScript(
  url: string = '',
  loadCondition = true
) {
  const [loadStatus, setLoadStatus] = useState({
    code: 0,
    message: 'Init',
    src: ''
  })

  const scriptLoaded = useCallback(() => {
    setLoadStatus({ code: 200, message: 'Script loaded', src: url })
  }, [url])

  const loadError = (error: any) => {
    setLoadStatus({
      code: 500,
      message: 'Failed to load script',
      src: error.target.src
    })
  }

  const LoadExternalScript = useCallback(() => {
    const externalScript = document.createElement('script')
    externalScript.onerror = loadError
    externalScript.onload = scriptLoaded
    externalScript.async = true
    document.body.appendChild(externalScript)
    externalScript.src = url
  }, [url, scriptLoaded])

  useEffect(() => {
    if (loadCondition) {
      const scripts = document.getElementsByTagName('script')
      let scriptExists = false
      if (scripts && scripts.length > 0) {
        for (let i = 0; i < scripts.length; i += 1) {
          if (scripts[i].src.includes(url)) {
            scriptExists = true
            scriptLoaded()
          }
        }
      }
      if (!scriptExists) {
        LoadExternalScript()
      }
    } else {
      setLoadStatus({
        code: -1,
        message: 'Load condition false',
        src: ''
      })
    }
  }, [url, scriptLoaded, LoadExternalScript, loadCondition])

  return loadStatus
}
