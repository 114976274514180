import ApiEndpoint from '../../../network/ApiEndpoint'

export interface PostReportContent {
  ticketType: string
  referrer: string
  channelSlug: string
  gameIdent?: string
  mediaObjectIdent?: string
  platform: string
  userMessage: string
  userAgent?: string
  browser?: string
  browserVersion?: string
  isAndroid?: boolean
  isIOS?: boolean
  isMobile?: boolean
  isTablet?: boolean
  browser_width?: string
  browser_height?: string
  screenWidth?: string
  screenHeight?: string
}

interface PostReportContentResponse {
  hasError: boolean
  errors?: any
}

export const postReportContent = async (
  input: PostReportContent
): Promise<PostReportContentResponse> => {
  try {
    await ApiEndpoint.call({
      path: 'reports/submit_report',
      method: ApiEndpoint.METHODS.POST,
      params: mapInput(input),
      authenticatable: true
    })
    return { hasError: false }
  } catch (e: any) {
    return { hasError: true, errors: e }
  }
}

const mapInput = (input: PostReportContent) => ({
  ticket_type: input.ticketType,
  referrer: input.referrer,
  company: input.channelSlug,
  game_ident: input.gameIdent,
  media_object_ident: input.mediaObjectIdent,
  platform: input.platform,
  user_message: input.userMessage,
  user_agent: input.userAgent,
  browser: input.browser,
  browser_version: input.browserVersion,
  is_android: input.isAndroid,
  is_ios: input.isIOS,
  is_mobile: input.isMobile,
  is_tablet: input.isTablet,
  browser_width: input.browser_width,
  browser_height: input.browser_height,
  screen_width: input.screenWidth,
  screen_height: input.screenHeight
})
