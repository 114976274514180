import React from 'react'

const ForwardIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7921 0.660156L10.6691 1.78318L17.1387 8.25276H0.332031V9.81522H17.1387L10.6691 16.2848L11.7921 17.4078L19.6045 9.5955L20.1416 9.03399L19.6045 8.47248L11.7921 0.660156Z" />
    </svg>
  )
}
export default ForwardIcon
