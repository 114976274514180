import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import ThemedHeader from './ThemedHeader/ThemedHeader'
import { DefaultHeader } from './DefaultHeader/DefaultHeader'

export const Header: FC = () => {
  const channel = useSelector(channelSelector)
  const isRootChannel = channel?.data?.isRootChannel
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootOrChild = isRootChannel || isChildOfRootChannel
  return isRootOrChild ? <ThemedHeader /> : <DefaultHeader />
}
