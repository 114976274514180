import React, { FC } from 'react'
import classNames from 'classnames'
import { Trans } from '@lingui/macro'
import styles from './index.module.scss'

interface Props {
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'keepRatio'
}

const EmptyContent: FC<Props> = ({ size }) => (
  <div className={classNames(styles.EmptyContainer, styles[size])}>
    <Trans>There is no video content here</Trans>
  </div>
)
export default EmptyContent
