import React, { FC, useMemo } from 'react'
import { EventScheduleTabs } from 'features/multistageTournaments/EventScheduleTabs'

import { useDataApi } from 'api/useDataApi'
import {
  getPastChannelEvents,
  getFutureAndActiveChannelEvents,
  getAllChannelEvents
} from 'api/multistageTournament/getChannelEvents'
import { GameSchedule } from './GameSchedule'
import { ChannelTypes } from '../channelType'

interface Props {
  channelSlug: string
  isScheduleAdmin: boolean
  channelType: ChannelTypes
}

export const MultistageTournaments: FC<Props> = ({
  channelSlug,
  isScheduleAdmin,
  channelType
}) => {
  const { data: futureAndActiveEvents } = useDataApi({
    apiCall: getFutureAndActiveChannelEvents,
    initialData: [],
    parameters: useMemo(() => ({ channelSlug }), [])
  })

  const { data: pastEvents } = useDataApi({
    apiCall: getPastChannelEvents,
    initialData: [],
    parameters: useMemo(() => ({ channelSlug }), [])
  })

  const { data: allEvents, isLoading: allEventsIsLoading } = useDataApi({
    apiCall: getAllChannelEvents,
    parameters: useMemo(() => ({ channelSlug }), [])
  })

  const hasEvents = !allEventsIsLoading && allEvents && allEvents.length > 0

  return hasEvents ? (
    <EventScheduleTabs
      channelSlug={channelSlug}
      futureAndActiveEvents={futureAndActiveEvents}
      pastEvents={pastEvents}
    />
  ) : (
    <GameSchedule
      channelSlug={channelSlug}
      isScheduleAdmin={isScheduleAdmin}
      channelType={channelType}
    />
  )
}
