import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const postBannerPublish = async (
  channelSlug: string,
  publish: boolean
): Promise<any> => {
  try {
    const publishString: string = publish.toString()
    const formData = new FormData()
    formData.append('content_type', 'application/delta+json')
    formData.append('company', channelSlug)
    formData.append('publish', publishString)

    const response: ApiResponse = await ApiEndpoint.call({
      path: 'banners/frontpage',
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
