import { useEffect } from 'react'
import { FeatureFlagsAvailable } from '../../featureFlags/FeatureFlagsAvailable'

export const useComingSoon = (
  releaseDate: number | undefined,
  releaseDateHasNotPassed: number | boolean | undefined,
  isSpecialDomain: boolean,
  hideComingSoonPage: boolean,
  setFlag: (flag: FeatureFlagsAvailable, value: boolean) => void,
  channel: any
) => {
  const setComingSoonPage = () => {
    if (releaseDate) {
      if (releaseDateHasNotPassed && !isSpecialDomain) {
        if (!hideComingSoonPage) {
          setFlag(FeatureFlagsAvailable.ComingSoon, true)
        }
      }
    } else {
      setFlag(FeatureFlagsAvailable.ComingSoon, false)
    }
  }
  useEffect(() => {
    setComingSoonPage()
  }, [channel, releaseDateHasNotPassed, releaseDate])
}
