import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Container } from '@mui/material'
import styles from './DefaultError.module.scss'

const DefaultError: FC = () => {
  return (
    <Container maxWidth="lg" className="GridContainer">
      <div className={styles.error}>
        <h2>We are sorry, but something went wrong.</h2>
        <p>We are unable to display the desired content at this time.</p>
        <p>Please try again.</p>
        <Button
          variant="contained"
          onClick={() => {
            window.location.href = '/'
          }}
        >
          <Trans>Go to start page</Trans>
        </Button>
      </div>
    </Container>
  )
}

export default DefaultError
