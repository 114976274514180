import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { CustomContentAdminItem } from '../../CustomContentAdminItem/CustomContentAdminItem'
import { NewContentModal } from '../../NewContentModal/NewContentModal'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal'
import {
  StatusMessage,
  StatusMessageType
} from 'pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'

import styles from './StartPageBannerManager.module.scss'
import {
  StartBannerContent,
  StartBannerContentType
} from 'features/customContent/StartPageBanner/startPageBannerTypes'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { StartPageBannerSelector } from 'features/customContent/StartPageBanner/StartPageBannerSelector'

interface Props {
  data: StartBannerContent
  handleClose: (showMessage: boolean) => void
  removeIsLoading: boolean
  removeHasError: boolean
  removeStartPageBanner: () => void
  setShowSuccessMessage: (show: boolean) => void
  setShowNewContentModal: (show: boolean) => void
  setShowRemoveContentModal: (show: boolean) => void
  showNewContentModal: boolean
  showSuccessMessage: boolean
  showRemoveContentModal: boolean
}

export const StartPageBannerManager: FC<Props> = ({
  data,
  handleClose,
  removeIsLoading,
  removeHasError,
  removeStartPageBanner,
  setShowSuccessMessage,
  setShowNewContentModal,
  setShowRemoveContentModal,
  showNewContentModal,
  showSuccessMessage,
  showRemoveContentModal
}) => {
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data.subdomain

  const existingForm = data && {
    title: data.title,
    text: data?.content,
    hasButton: !!data?.buttonLabel,
    textField: data?.buttonLabel,
    linkField: data?.buttonLink,
    imageUrl: data?.imageUrl,
    imageFileUrl: data?.imageUrl,
    imageLinkUrl: data?.imageLink,
    target: data?.buttonLinkTarget,
    openInNewTab: data?.imageTarget === LinkTarget.NewWindow
  }

  const contentType: StartBannerContentType = data?.bannerType
    ? data.bannerType
    : StartBannerContentType.InfoBox

  return (
    <div className={styles.Container}>
      {data && (
        <CustomContentAdminItem
          channelSlug={channelSlug}
          onEdit={() => setShowNewContentModal(true)}
          onRemove={() => setShowRemoveContentModal(true)}
        >
          <StartPageBannerSelector content={data} />
        </CustomContentAdminItem>
      )}
      {showNewContentModal && (
        <NewContentModal
          onClose={handleClose}
          defaultOpen={showNewContentModal}
          channelSlug={channelSlug}
          existingForm={existingForm}
          selectedContentType={contentType}
        />
      )}
      {showSuccessMessage && (
        <StatusMessage
          onClose={() => setShowSuccessMessage(false)}
          message={t`the banner details have been successfully saved`}
          messageType={StatusMessageType.Success}
        />
      )}
      {showRemoveContentModal && (
        <ConfirmationModal
          modalHeader=""
          title={t`You are removing the custom banner`}
          subText={t`Are you sure that you would like to remove the custom banner from the start page?`}
          defaultOpen={showRemoveContentModal}
          isError={removeHasError}
          isLoading={removeIsLoading}
          errorMessage={t`something went wrong when trying to remove the start page banner, please try again.`}
          confirmButtonLabel={t`Yes`}
          cancelButtonLabel={t`No`}
          onClose={() => setShowRemoveContentModal(false)}
          onConfirm={removeStartPageBanner}
        />
      )}
    </div>
  )
}
