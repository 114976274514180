import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { ButtonType } from 'components/buttons/button/Button'
import { LinkButton } from 'components/buttons/LinkButton/LinkButton'

interface Props {
  id: string
  channelSlug: string
}
export const WatchGameButton: FC<Props> = ({ id, channelSlug }) => (
  <LinkButton
    type={ButtonType.Secondary}
    buttonSize="Small"
    label={t`Watch game`}
    href={`/${channelSlug}/games/g/${id}`}
  />
)
