import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import DotList from '../DotList'
import Image from '../Image'
import { Article } from '../cardTypes'
import styles from '../SearchResultCard.module.scss'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

interface Props {
  content: Article
}

const SearchResultCardArticle: FC<Props> = ({ content }) => {
  return (
    <div className={styles.Container}>
      <div
        data-testid="search-result-image-container"
        className={styles.ImageArticleContainer}
      >
        <Image imageUrl={content.imageUrl} title={content.title} />
      </div>
      <div className={styles.ContentContainer}>
        <div
          data-testid="search-result-article-published"
          className={styles.Published}
        >
          {dayjs.unix(content.published).fromNow()}
        </div>
        <div className={styles.ArticleTitle}>
          <Link to={content.targetUrl}>{content.title}</Link>
        </div>
        <div
          data-testid="search-result-description"
          className={styles.Description}
        >
          {content.preamble}
        </div>
        <div className={styles.BottomFrame}>
          <DotList
            list={[
              content.company || '',
              content.sport || '',
              content.country || '',
              content.tag || ''
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchResultCardArticle
