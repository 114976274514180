import React, { FC } from 'react'
import styles from './RootDomainLabel.module.scss'
interface Props {
  label: string
}

export const RootDomainLabel: FC<Props> = ({ label }) => {
  return (
    <div data-testid="root-domain-label" className={styles.LabelContainer}>
      {label}
    </div>
  )
}
