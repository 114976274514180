import * as yup from 'yup'
import { t } from '@lingui/macro'
export const resetPasswordFormValidationSchema = () =>
  yup.object({
    newPassword: yup
      .string()
      .min(6, 'Must be at least 6 characters long')
      .required(t`Password is required`),
    confirmNewPassword: yup
      .string()
      .min(6, 'Type your password again')
      .required(t`Password is required`)
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  })
