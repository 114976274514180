import { computeStateChange } from '../combineSubscribers'
import rolesActions from './roles.actions'
import { UpdateRolesPayload } from './roles.interface'

let prevState: UpdateRolesPayload = {
  roles: undefined,
  companies: undefined,
  subdomain: undefined,
  leagueId: undefined,
  loggedIn: undefined
}

export const rolesSubscriber = (store: any) => {
  const state = store.getState()
  const { roles, companies } = state?.user || {}
  const subdomain = state?.channel?.data?.subdomain
  const leagueId = state?.stream?.data?.mediaObject?.event_schedule?.id
  const loggedIn = state?.authentication?.loggedIn

  const nextState: UpdateRolesPayload = {
    roles,
    companies,
    subdomain,
    leagueId,
    loggedIn
  }

  if (computeStateChange(prevState, nextState)) {
    prevState = nextState
    store.dispatch(rolesActions.updateRoles(nextState))
  }
}
