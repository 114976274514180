import { t } from '@lingui/macro'
import { ProductType } from '../../ProductType'
import { SubscriptionType } from '../../SubscriptionType'

export const getPreDefinedTagLine = (
  productType: ProductType,
  subscriptionType: SubscriptionType,
  subscriptionChannelName: string
) => {
  if (productType === ProductType.AnnualSupporter) {
    return t`The perfect subscription for all our hardcore supporters!`
  }
  switch (subscriptionType) {
    case SubscriptionType.Team:
    case SubscriptionType.Club:
      return t`This is the perfect way to support ${subscriptionChannelName}`
    case SubscriptionType.League:
      return t`This is the perfect way to watch all streams from ${subscriptionChannelName}`
    default:
      return t`This is a perfect way to support your favourite channel`
  }
}
