import React, { FC } from 'react'
import Button, { ButtonType } from '../../buttons/button/Button'
import styles from './ModalActionButtons.module.scss'

interface Props {
  cancelLabel: string
  confirmButtonLabel: string
  onCancel: () => void
  onConfirm: () => void
}
export const ModalActionButtons: FC<Props> = ({
  cancelLabel,
  confirmButtonLabel,
  onCancel,
  onConfirm
}) => (
  <div className={styles.ButtonContainer}>
    <Button
      type={ButtonType.GhostWithBoarder}
      label={cancelLabel}
      roleType="button"
      onClick={onCancel}
      buttonSize="Medium"
    />
    <Button
      type={ButtonType.Secondary}
      label={confirmButtonLabel}
      roleType="button"
      onClick={onConfirm}
      buttonSize="Medium"
    />
  </div>
)
