import { FeatureFlagsAvailable } from './FeatureFlagsAvailable'

export const getParsedFeatureFlags = (): { [key: string]: boolean } => {
  const flagsArray = Object.values(FeatureFlagsAvailable)
  const flagsEntries = flagsArray.map((flag: string) => {
    const isSet = localStorage.getItem(flag) === 'true'
    return [flag, isSet]
  })
  const flags = Object.fromEntries(flagsEntries)
  return flags
}
