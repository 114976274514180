import React, { FC, useState } from 'react'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows
} from './PlayerStatisticsTable.interface'
import { Row } from './Row/Row'
import { getSortedList } from './getSortedList'
import { TableHeader } from './TableHeader/TableHeader'
import {
  IntegrationName,
  getLogoForIntegration
} from '../getLogoForIntegration'
import ContentSlider from 'components/sliders/ContentSlider'
import ContentContainer from 'components/ContentContainer'
import { getPageToDisplay } from './getPageToDisplay'
import { TableFooter } from './TableFooter/TableFooter'

import styles from './PlayerStatisticsTable.module.scss'
import { t } from '@lingui/macro'

interface Props {
  columns: PlayerStatisticsTableColumns[]
  rows: PlayerStatisticsTableRows[]
  defaultSortedBy: string
  integrationName: IntegrationName
  integrationLink: string
  eventName: string
  lastUpdatedTimestamp: number
}

interface PageSortState {
  sortedBy: string
  descending: boolean
  currentPage: number
}

export const PlayerStatisticsTable: FC<Props> = ({
  columns,
  rows,
  defaultSortedBy,
  integrationLink,
  integrationName,
  eventName,
  lastUpdatedTimestamp
}) => {
  const [pageSortState, setPageSortState] = useState<PageSortState>({
    sortedBy: defaultSortedBy,
    descending: true,
    currentPage: 1
  })

  const sortedRows = getSortedList(
    rows,
    pageSortState.sortedBy,
    pageSortState.descending
  )

  const integrationLogo = getLogoForIntegration(integrationName)

  const handleSelectedFilter = (identifier: string) => {
    if (pageSortState.sortedBy === identifier) {
      setPageSortState({
        descending: !pageSortState.descending,
        sortedBy: identifier,
        currentPage: 1
      })
    } else {
      setPageSortState({
        descending: true,
        sortedBy: identifier,
        currentPage: 1
      })
    }
  }

  const pageSize = 10
  const totalPages = Math.ceil(sortedRows.length / pageSize)

  const currentPageData = getPageToDisplay({
    currentPage: pageSortState.currentPage,
    pageSize,
    data: sortedRows
  })

  const incrementPage = () => {
    if (pageSortState.currentPage <= totalPages) {
      setPageSortState({
        ...pageSortState,
        currentPage: pageSortState.currentPage + 1
      })
    }
  }

  const decrementPage = () => {
    if (pageSortState.currentPage > 1) {
      setPageSortState({
        ...pageSortState,
        currentPage: pageSortState.currentPage - 1
      })
    }
  }

  return (
    <div className={styles.StatsContainer}>
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={false}
        hasLightBackground={false}
      >
        <h3 className={styles.EventName}>{t`Player stats - ${eventName}`}</h3>
      </ContentContainer>
      <ContentSlider leftPadding={true} showArrows>
        <div
          className={styles.TableContainer}
          data-testid="player-statistics-table"
        >
          <TableHeader
            columns={columns}
            selectedFilter={pageSortState.sortedBy}
            onSelectedFilterClick={(identifier: string) =>
              handleSelectedFilter(identifier)
            }
            descending={pageSortState.descending}
          />
          {currentPageData.map(
            // @ts-ignore
            (row: PlayerStatisticsTableRows, number: number) => {
              const ranking = pageSortState.descending
                ? pageSize * (pageSortState.currentPage - 1) + number + 1
                : sortedRows.length -
                  pageSize * pageSortState.currentPage +
                  (pageSize - number)

              return (
                <Row
                  columns={columns}
                  row={row}
                  rowNumber={ranking}
                  key={number}
                  selectedColumn={pageSortState.sortedBy}
                />
              )
            }
          )}
        </div>
      </ContentSlider>
      <TableFooter
        integrationLink={integrationLink}
        integrationLogo={integrationLogo}
        integrationName={integrationName}
        lastUpdatedTimestamp={lastUpdatedTimestamp}
        onIncrementPage={incrementPage}
        onDecrementPage={decrementPage}
        currentPage={pageSortState.currentPage}
        totalPages={totalPages}
      />
    </div>
  )
}
