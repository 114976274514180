import { mapLineupResponse } from './mapLineupResponse'

const BASE_URL = process.env.REACT_APP_SERVICE_API_BASE_URL

export interface Player {
  id: string
  number: number
  name: string
  position: string
  isHighlightedPosition: boolean
  isCaptain: boolean
}
export interface Coach {
  id: number
  name: string
}
export interface Referee {
  id: number
  name: string
}
export interface LineupApiResponse {
  homeTeam: {
    players: Player[]
    coaches: Coach[]
  }
  awayTeam: {
    players: Player[]
    coaches: Coach[]
  }
  referees: Referee[]
}
export const getTeamLineups = async ({ gameId }: { gameId: string }) => {
  try {
    const url = `${BASE_URL}game-bff/api/v1/${gameId}/lineups`
    const response = await fetch(url)
    const data = await response.json()
    const mapped = mapLineupResponse(data)
    return mapped
  } catch (e: any) {
    console.log('e', e)
    throw new Error(e.message)
  }
}
