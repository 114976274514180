import React, { FC } from 'react'
import { t } from '@lingui/macro'

import Supporter1Icon from './Supporter1Icon'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../hooks/CurrentBreakpoint'

import styles from '../Banner.module.scss'

interface Props {
  isSupporter: boolean
  channelName: string
}

export const Subscription: FC<Props> = ({ isSupporter, channelName }) => {
  const breakPoint = useCurrentBreakpoint()
  const title = isSupporter
    ? t`Thank you for supporting us!`
    : t`Become a supporter`

  const longDescription = isSupporter
    ? t`You are our best supporter 👊🏻`
    : t`Buy yourself a seat to our latest sports' streams and competitions. Get full access to ${channelName} with one of our subscriptions! Cancel anytime.`

  const shortDescription = isSupporter
    ? t`You are our best supporter 👊🏻`
    : t`Get full access, cancel anytime.`

  const description = !(breakPoint <= Breakpoint.mobile)
    ? longDescription
    : shortDescription
  return (
    <div>
      <div className={styles.TopContainer}>
        <div className={styles.Icon}>
          <Supporter1Icon />
        </div>
        <div className={styles.TitleAndPriceText}>
          <div className={styles.Title}>{title}</div>
        </div>
      </div>
      <div className={styles.Description}>{description}</div>
    </div>
  )
}
