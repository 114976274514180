import React, { FC } from 'react'

import styles from './TabListContainer.module.scss'

interface Props {
  children?: React.ReactNode
}

export const TabContent: FC<Props> = ({ children }) => {
  return <div className={styles.TabContent}>{children}</div>
}
