import React from 'react'

const TrophyIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.54175 19.2083H17.1667V22.75H6.54175V19.2083Z"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.90278 5.04168H3.80514C3.5916 5.04168 3.38681 5.12651 3.23582 5.2775C3.08483 5.42849 3 5.63328 3 5.84682C3 8.19509 3.93285 10.4472 5.59332 12.1077C7.2538 13.7681 9.50589 14.701 11.8542 14.701C14.2024 14.701 16.4545 13.7681 18.115 12.1077C19.7755 10.4472 20.7083 8.19509 20.7083 5.84682C20.7083 5.63328 20.6235 5.42849 20.4725 5.2775C20.3215 5.12651 20.1167 5.04168 19.9032 5.04168H14.8056"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1872 14.5443L8.81543 19.2083H14.7182L13.4778 14.5522"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2758 1.7558L13.258 3.69034H14.932C15.024 3.6885 15.1144 3.71434 15.1915 3.76453C15.2686 3.81471 15.3289 3.88692 15.3644 3.97177C15.4 4.05662 15.4092 4.1502 15.3909 4.24036C15.3726 4.33052 15.3276 4.4131 15.2618 4.47738L13.7224 5.9877L14.5739 7.94349C14.6114 8.03312 14.6197 8.13225 14.5978 8.22688C14.5759 8.32151 14.5248 8.40687 14.4517 8.47088C14.3786 8.5349 14.2873 8.57435 14.1906 8.58365C14.0939 8.59296 13.9968 8.57166 13.9128 8.52275L11.8547 7.36344L9.79662 8.52275C9.7127 8.57136 9.61565 8.59242 9.51913 8.58298C9.42261 8.57353 9.33148 8.53405 9.25858 8.4701C9.18567 8.40614 9.13466 8.32093 9.11272 8.22646C9.09079 8.132 9.09903 8.03303 9.1363 7.94349L9.98787 5.9877L8.45079 4.47502C8.38491 4.4107 8.33991 4.32804 8.32163 4.23781C8.30335 4.14757 8.31265 4.05392 8.3483 3.96903C8.38395 3.88415 8.44432 3.81195 8.52155 3.76183C8.59878 3.7117 8.6893 3.68597 8.78135 3.68798H10.4546L11.4376 1.75344C11.4777 1.67681 11.538 1.61265 11.612 1.56796C11.6861 1.52326 11.7709 1.49976 11.8574 1.5C11.9439 1.50025 12.0286 1.52423 12.1024 1.56934C12.1762 1.61444 12.2362 1.67895 12.2758 1.7558V1.7558Z"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrophyIcon
