import SUBSCRIBER from './subscriber.constants'

import subscriberService from './subscriber.service'

const subscriberActions = {
  connect,
  disconnect,
  newChatMessage,
  removeNewMessage,
  hideChatMessage,
  newBroadcastMessage,
  newCountdownMessage
}
export default subscriberActions

function connect(url: string) {
  subscriberService.connect(url)
  return {
    type: SUBSCRIBER.CONNECTING
  }
}

function disconnect() {
  subscriberService.disconnect()
  return {
    type: SUBSCRIBER.DISCONNECTING
  }
}

function newChatMessage(message: any) {
  return {
    type: SUBSCRIBER.NEW_CHAT_MESSAGE,
    message: message
  }
}

function removeNewMessage() {
  return { type: SUBSCRIBER.REMOVE_NEW_CHAT_MESSAGE }
}

function hideChatMessage(message: any) {
  return {
    type: SUBSCRIBER.HIDE_CHAT_MESSAGE,
    message: message
  }
}

function newBroadcastMessage(message: any) {
  return {
    type: SUBSCRIBER.NEW_BROADCAST_MESSAGE,
    message: message
  }
}

function newCountdownMessage(message: any) {
  return {
    type: SUBSCRIBER.NEW_COUNTDOWN_MESSAGE,
    message: message
  }
}
