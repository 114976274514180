export interface CompanionApplistItem {
  rootChannelName: string
  rootChannelSubdomain: string
  playStoreLink: string
  appStoreLink: string
}
export const companionAppList: CompanionApplistItem[] = [
  {
    rootChannelName: 'Basket TV',
    rootChannelSubdomain: 'sbbf',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mobileapp.Svenskbasket',
    appStoreLink: 'https://apps.apple.com/se/app/basket-tv/id6444649129'
  }
]

export const hasCompanionApp = (rootChannelSubdomain: string) => {
  return companionAppList.some(
    (item: CompanionApplistItem) =>
      rootChannelSubdomain === item.rootChannelSubdomain
  )
}

export const getAppStoreLink = (rootChannelSubdomain: string) => {
  const companionAppItem = companionAppList.find(
    (item: CompanionApplistItem) => {
      return item.rootChannelSubdomain === rootChannelSubdomain
    }
  )
  return companionAppItem?.appStoreLink
}

export const getPlayStoreLink = (rootChannelSubdomain: string) => {
  const companionAppItem = companionAppList.find(
    (item: CompanionApplistItem) => {
      return item.rootChannelSubdomain === rootChannelSubdomain
    }
  )
  return companionAppItem?.playStoreLink
}
