import React, { FC } from 'react'
import { getTranslatedChannelTypeIndefinite } from '../../../../../components/content/RelatedChannel/RelatedChannelItem/getTranslatedChannelTypeIndefinite'
import { ChannelTypes } from '../../../channelType'

import styles from './ChannelTypeLabel.module.scss'

interface Props {
  channelType: ChannelTypes
}

export const ChannelTypeLabel: FC<Props> = ({ channelType }) => {
  const translatedChannelTypeText =
    getTranslatedChannelTypeIndefinite(channelType)
  return (
    <div
      data-testid="channel-type-label"
      className={styles.ChannelTypeLabelContainer}
    >
      {translatedChannelTypeText}
    </div>
  )
}
