import React, { FC } from 'react'
import NewsThumbnailSection from './NewsThumbnailSection'

import styles from './Start.module.scss'
export enum PagePosition {
  Top = 'top',
  Bottom = 'bottom'
}
interface Props {
  showNewsSection: boolean
  newsArticleRef: any
  thumbnailSize: 'large' | 'small'
  channelSlug: string
  hasRecentArticles: boolean
  pagePosition: PagePosition
  newsArticlesInView: boolean
}

export const NewsSection: FC<Props> = ({
  channelSlug,
  showNewsSection,
  newsArticleRef,
  thumbnailSize,
  hasRecentArticles,
  pagePosition,
  newsArticlesInView
}) => {
  const showInPostition = (position: PagePosition) =>
    (hasRecentArticles && position === PagePosition.Top) ||
    (!hasRecentArticles && position === PagePosition.Bottom)

  return showNewsSection && showInPostition(pagePosition) ? (
    <div
      className={styles.NewsContainer}
      ref={newsArticleRef}
      data-testid="news-container"
    >
      {newsArticlesInView && (
        <NewsThumbnailSection
          thumbnailSize={thumbnailSize}
          channelSlug={channelSlug}
        />
      )}
    </div>
  ) : null
}
