import { MediaObject } from 'interfaces/MediaObject.interface'

export const tagsAndCategories = (
  mo: MediaObject,
  additionalKeywords: string[] = []
) => {
  const tags = mo.tags ? mo.tags.map((tag) => tag.name) : []
  const categories = mo.categories ? mo.categories.map((cat) => cat.name) : []
  return [...additionalKeywords, ...tags, ...categories]
}
