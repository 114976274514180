import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './InfoBanner.module.scss'
import { InfoIcon } from './InfoIcon'

import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../hooks/CurrentBreakpoint'
import { RoundArrowIcon } from './RoundArrowIcon'

export interface InfoBannerProps {
  title: string
  subTitle: string
  shortSubTitle: string
  linkPath: string
  onClick?: () => void
}
const InfoBanner: FC<InfoBannerProps> = ({
  title,
  subTitle,
  shortSubTitle,
  linkPath,
  onClick
}) => {
  const breakPoint = useCurrentBreakpoint()
  const subTitleText =
    breakPoint <= Breakpoint.mobile ? shortSubTitle : subTitle

  return (
    <Link to={linkPath} className={styles.InfoBanner} onClick={onClick}>
      <div className={styles.Content}>
        <div className={styles.TopContainer}>
          <div className={styles.Icon}>
            <InfoIcon />
          </div>
          <div className={styles.Title}>{title}</div>
        </div>
        <div className={styles.SubTitle}>{subTitleText}</div>
      </div>
      <div className={styles.ArrowIcon}>
        <RoundArrowIcon />
      </div>
    </Link>
  )
}

export default InfoBanner
