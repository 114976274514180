import React, { FC } from 'react'

import ShieldIcon from '../../../../../components/cards/GameScheduleCard/Team/ShieldIcon'
import {
  StandingsColumnInterface,
  StandingsRowInterface
} from '../../Standings.interface'
import styles from './StandingsTeam.module.scss'

interface StandingsTeamProps {
  column: StandingsColumnInterface
  row: StandingsRowInterface
  placement: number
}

export const StandingsTeam: FC<StandingsTeamProps> = ({
  column,
  row,
  placement
}) => {
  const name: any = row[column.field].toString()
  const image: string = row?.image?.toString()
  return (
    <div className={styles.StandingsTeam}>
      <span className={styles.StandingsTeamPosition}>{placement}</span>
      {image ? (
        <img src={image} alt={name} className={styles.StandingsTeamImage} />
      ) : (
        <ShieldIcon />
      )}
      <div className={styles.StandingsTeamName}>{name}</div>
    </div>
  )
}
