import { ChannelFont } from '../redux-store/channelFonts/channelFonts.interface'

export const loadCustomFont = async (
  font: ChannelFont,
  onLoaded: (fontName: string) => void
) => {
  return new Promise((resolve, reject) => {
    const customFont = new FontFace(font.identifier, `url(${font.fontUrl})`)
    customFont
      .load()
      .then(() => {
        document.fonts.add(customFont)
        onLoaded(font.identifier)
        // @ts-ignore
        resolve()
      })
      .catch(() => reject())
  })
}
