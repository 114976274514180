import { t } from '@lingui/macro'
import * as yup from 'yup'
import { domainRegex } from '../../regex'

export const customDomainSchema = yup.object({
  domainName: yup
    .string()
    .matches(domainRegex, t`Please enter a valid domain`)
    .required(t`Please provide a valid domain`)
})
