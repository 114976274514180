import React, { FC, ReactNode } from 'react'
import { CircularAddIcon } from './CircularAddIcon'

import styles from './Subheading.module.scss'

interface Props {
  showAddButton: boolean
  onAddClick: () => void
  children?: ReactNode
}

export const Subheading: FC<Props> = ({
  showAddButton,
  onAddClick,
  children
}) => (
  <div className={styles.SubheadingContainer}>
    <div>{children}</div>
    {showAddButton && (
      <div
        data-testid="add-games-button"
        className={styles.AddButton}
        tabIndex={0}
        onKeyPress={() => null}
        role="button"
        onClick={onAddClick}
      >
        <CircularAddIcon />
      </div>
    )}
  </div>
)
Subheading.defaultProps = {
  children: undefined
}
