import classNames from 'classnames'
import React, { FC } from 'react'
import { getTimePassed } from '../../../../helpers/formatHelper'
import styles from './ArticleDate.module.scss'

interface ArticleDateProps {
  updatedDate: number
  size: 'small' | 'medium' | 'large' | 'xlarge'
}

const ArticleDate: FC<ArticleDateProps> = ({ updatedDate, size }) => {
  return (
    <div className={styles.ArticleDateContainer}>
      <div
        data-testid="article-thumbnail-date"
        className={classNames(styles.LightSubText, styles[size])}
      >
        {getTimePassed(updatedDate)}
      </div>
    </div>
  )
}

export default ArticleDate
