import React, { FC } from 'react'
import { ChangePasswordForm } from './ChangePasswordForm/ChangePasswordForm'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { t } from '@lingui/macro'
import styles from './Password.module.scss'
export const Password: FC = () => {
  return (
    <div className={styles.PasswordContainer}>
      <FormSectionTitle title={t`Change password`} />
      <ChangePasswordForm />
    </div>
  )
}
