import React, { FC } from 'react'
import { TrendingChannelItem } from 'api/schedule/trendingChannel.interface'
import { ChannelListItemLink } from 'components/ChannelListItem/ChannelListItemLink'
import styles from './ChannelList.module.scss'

interface Props {
  list: TrendingChannelItem[]
  showBorder?: boolean
  isOnRootOrChildChannel?: boolean
}

export const ChannelList: FC<Props> = ({
  list,
  showBorder = false,
  isOnRootOrChildChannel = false
}) => (
  <div className={styles.ChannelList} data-testid="channel-list">
    {list.map((channel: TrendingChannelItem) => (
      <ChannelListItemLink
        key={channel.subdomain}
        channelLogo={channel.logoUrl}
        channelSubdomain={channel.subdomain}
        channelName={channel.name}
        sportLabel={channel.sportName}
        rootDomain={isOnRootOrChildChannel ? '' : channel.rootDomain}
        showBorder={showBorder}
      />
    ))}
  </div>
)
