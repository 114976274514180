import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { FilterPillButton } from 'components/buttons/FilterPillButton/FilterPillButton'
import { CalendarIcon } from 'features/multistageTournaments/CalendarIcon'
import { ResetIcon } from './ResetIcon'
import styles from './DateSelectorButtons.module.scss'

interface Props {
  dateSelected: string
  onCalendarClick: () => void
  onResetFiltersClick: () => void
}

export const DateSelectorButtons: FC<Props> = ({
  dateSelected,
  onCalendarClick,
  onResetFiltersClick
}) => {
  return (
    <div className={styles.Container} data-testid="date-selector-button">
      <FilterPillButton
        label={dateSelected}
        id="calendar"
        onClick={onCalendarClick}
      >
        <CalendarIcon />
      </FilterPillButton>
      {dateSelected && (
        <FilterPillButton
          label={t`reset`}
          id="reset"
          onClick={onResetFiltersClick}
        >
          <ResetIcon />
        </FilterPillButton>
      )}
    </div>
  )
}
