import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './IconButtonRound.module.scss'

export enum RoundButtonType {
  Light = 'Light',
  Dark = 'Dark',
  Red = 'Red'
}
interface Props {
  buttonSize: 'Small' | 'Medium' | 'Large'
  type: RoundButtonType
  onClick: () => void
  children?: React.ReactNode
}
const IconButtonRound: FC<Props> = ({
  buttonSize,
  type,
  children,
  onClick
}) => {
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      className={classNames(
        styles.RoundIconButton,
        styles[type],
        styles[buttonSize]
      )}
    >
      {children}
    </div>
  )
}
export default IconButtonRound
