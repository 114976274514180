import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'
import {
  PostRefereeAccessRequest,
  postRefereeAccess
} from 'api/refereeAccess/postRefereeAccess'
import { RefereeAccessFormFields } from './RefereeAccessFormFields'

interface RefereeAccessFormValues {
  accessCode: string
}
interface Props {
  integrationId: number
  ident: string
  onGetAccessSuccess: () => void
  isAlreadyActivated: boolean
}

export const RefereeAccessForm: FC<Props> = ({
  integrationId,
  ident,
  onGetAccessSuccess,
  isAlreadyActivated
}) => {
  const isActivatedText = t`Already activated`
  const defaultPlaceHolderText = isAlreadyActivated
    ? isActivatedText
    : t`Ex. c2e870771`

  const [accessCodeFieldPlaceholder, setAccessCodeFieldPlaceholder] = useState(
    defaultPlaceHolderText
  )
  const [accessCodeFieldreadOnly, setAccessCodeFieldReadonly] =
    useState(isAlreadyActivated)

  const initialValues: RefereeAccessFormValues = {
    accessCode: ''
  }
  const accessFormSchema = yup.object({
    code: yup.string()
  })
  const onSubmit = async (
    values: RefereeAccessFormValues,
    formikHelpers: FormikHelpers<RefereeAccessFormValues>
  ) => {
    formikHelpers.setSubmitting(true)
    const postRefereeAccessValues: PostRefereeAccessRequest = {
      accessInviteCode: values.accessCode,
      integrationId,
      ident
    }
    const response = await postRefereeAccess(postRefereeAccessValues)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      if (!response.codeIsValid) {
        formikHelpers.setFieldError('accessCode', t`This is not a valid code`)
      } else {
        formikHelpers.setSubmitting(false)
        formikHelpers.resetForm()
        setAccessCodeFieldPlaceholder(isActivatedText)
        setAccessCodeFieldReadonly(true)
        onGetAccessSuccess()
      }
    }
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={accessFormSchema}
        validateOnChange={false}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          const handleAccessCodeChange = (e: any) => {
            props.handleChange(e)
          }

          return (
            <RefereeAccessFormFields
              accessCodeFieldPlaceholder={accessCodeFieldPlaceholder}
              formik={props}
              handleAccessCodeChange={handleAccessCodeChange}
              accessCodeFieldreadOnly={accessCodeFieldreadOnly}
            />
          )
        }}
      </Formik>
    </div>
  )
}
