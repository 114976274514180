import React, { FC } from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './channelLogo.module.scss'

interface Props {
  logoPath: string
  channelName: string
  rootChannelSubdomain: string
  isHeader?: boolean
}

const ChannelLogo: FC<Props> = ({
  rootChannelSubdomain,
  logoPath,
  channelName,
  isHeader = true
}) => {
  const navRoot = rootChannelSubdomain ? `/${rootChannelSubdomain}` : '/'

  return rootChannelSubdomain ? (
    <Link
      className={classNames(styles.RootChannelLink, {
        [styles.ImageHeader]: isHeader
      })}
      to={navRoot}
    >
      <img
        alt={channelName}
        className={classNames(styles.RootLogoImage, {
          [styles.Header]: isHeader,
          [styles.ImageFooter]: !isHeader
        })}
        src={logoPath}
        data-testid="channel-logo"
      />
    </Link>
  ) : (
    <img
      alt={channelName}
      className={classNames(styles.RootLogoImage, {
        [styles.Header]: isHeader,
        [styles.ImageFooter]: !isHeader,
        [styles.Imageheader]: isHeader
      })}
      src={logoPath}
      data-testid="channel-logo"
    />
  )
}
export default ChannelLogo
