import { FilterItem } from './FilterList/FilterList'

export const getFilterItemFromData = (
  data: FilterItem[],
  filterSlug: string
) => {
  return data.find((element) => {
    return element.slug === filterSlug
  })
}
