import React, { FC } from 'react'
import styles from './TitleTextButton.module.scss'
import Button, {
  ButtonType
} from '../../../../components/buttons/button/Button'

interface Props {
  title: string
  text: string
  buttonLabel: string
  buttonFunction: () => void
}

const TitleTextButton: FC<Props> = ({
  title,
  text,
  buttonLabel,
  buttonFunction
}) => {
  return (
    <div className={styles.TitleTextButtonContainer}>
      <div className={styles.Title} data-testid="title-text-button-title">
        {title}
      </div>
      <div className={styles.Text} data-testid="title-text-button-text">
        {text}
      </div>

      <Button
        data-testid="title-text-button-button"
        label={buttonLabel}
        type={ButtonType.Primary}
        buttonSize="Medium"
        onClick={() => buttonFunction()}
      />
    </div>
  )
}
export default TitleTextButton
