import React from 'react'
import { t } from '@lingui/macro'

import LandingPageHeroSection from './LandingPageHeroSection/LandingPageHeroSection'
import GoLiveImg from '../../assets/images/landingpage_1_lowres.jpg'
import ConsumptionImg from '../../assets/images/landingpage_2_lowres.jpg'
import styles from './index.module.scss'

export enum LandingPageType {
  consume = 'consume',
  create = 'create'
}

const LandingPageHero = () => (
  <div className={styles.LandingPageHero}>
    <LandingPageHeroSection
      type={LandingPageType.create}
      backgroundImgPath={GoLiveImg}
      smallHeroText={t`Make money`}
      heroText={t`Broadcast live`}
      urlPath="/broadcast"
    />
    <LandingPageHeroSection
      type={LandingPageType.consume}
      smallHeroText={t`All sports. Everywhere.`}
      heroText={t`Watch sports`}
      backgroundImgPath={ConsumptionImg}
      urlPath="/browse"
    />
  </div>
)
export default LandingPageHero
