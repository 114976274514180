import { AnyAction } from 'redux'
import { RootState } from '../store'
import SUBSCRIBER from './subscriber.constants'

export interface NChanSubscriber {
  nchan: any
  subscription: any
  status: any
  newMessage: any
  newBroadcastMessage: any
  newCountdownMessage: any
  hideMessage: boolean
}

const NchanSubscriber = require('nchan')

// Initial state
const initialState: NChanSubscriber = {
  nchan: NchanSubscriber,
  subscription: null,
  status: SUBSCRIBER.DISCONNECTED,
  newMessage: null,
  newBroadcastMessage: null,
  newCountdownMessage: null,
  hideMessage: false
}

function nchanSubscriberReducer(state = initialState, action: AnyAction) {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case SUBSCRIBER.CONNECTING:
      return {
        ...state,
        status: action.type
      }
    case SUBSCRIBER.CONNECTED:
      return {
        ...state,
        status: action.type,
        subscription: action.subscription
      }
    case SUBSCRIBER.DISCONNECTING:
      return {
        ...state,
        status: action.type
      }
    case SUBSCRIBER.DISCONNECTED:
      return {
        ...state,
        status: action.type
      }
    case SUBSCRIBER.ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error
      }
    case SUBSCRIBER.NEW_CHAT_MESSAGE:
      return {
        ...state,
        status: action.type,
        newMessage: action.message
      }
    case SUBSCRIBER.REMOVE_NEW_CHAT_MESSAGE:
      return {
        ...state,
        newMessage: undefined
      }
    case SUBSCRIBER.HIDE_CHAT_MESSAGE:
      return {
        ...state,
        status: action.type,
        hideMessage: action.message
      }
    case SUBSCRIBER.NEW_BROADCAST_MESSAGE:
      return {
        ...state,
        status: action.type,
        newBroadcastMessage: action.message
      }
    case SUBSCRIBER.NEW_COUNTDOWN_MESSAGE:
      return {
        ...state,
        status: action.type,
        newCountdownMessage: action.message
      }
    default:
      return state
  }
}

export default nchanSubscriberReducer

export const newMessageSelector = (state: RootState) =>
  state.subscriber.newMessage

export const hideMessageSelector = (state: RootState) =>
  state.subscriber.hideMessage
