import React from 'react'
import { ComingSoon } from './ComingSoon'

export const checkReleaseDate = (
  channel: any,
  parentReleaseDate: number | undefined,
  setParentReleaseDate: React.Dispatch<
    React.SetStateAction<number | undefined>
  >,
  setComingSoonAttributes: React.Dispatch<React.SetStateAction<ComingSoon>>
) => {
  if (channel?.data?.release_date && !parentReleaseDate) {
    setParentReleaseDate(channel.data.release_date)
    setComingSoonAttributes({
      logoUrl: channel.data.logo_image_url,
      backgroundImageUrl: channel.data.background_image_url,
      vanityUrl: channel.data.root_domain,
      channelSlug: channel.data.subdomain
    })
  }
}
