import React, { FC, useEffect, useState } from 'react'

import { t } from '@lingui/macro'
import { useDropzone } from 'react-dropzone'
import { Breakpoint, useCurrentBreakpoint } from '../../hooks/CurrentBreakpoint'
import { getAcceptType } from './getAcceptType'
import { UPLOAD_TYPE } from './UploadType'
import { DropZone } from './DropZone'
import { FormikProps } from 'formik'
import { ImagePreview } from './ImagePreview'

interface Props {
  formik: FormikProps<any>
  type: UPLOAD_TYPE
  formFieldName: string
  fileUrlFieldName: string
  recommendedImageHeight?: string
  recommendedImageWidth?: string
}
export const FormDropFileZone: FC<Props> = ({
  formik,
  formFieldName: imageFieldName,
  type,
  fileUrlFieldName,
  recommendedImageHeight,
  recommendedImageWidth
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint < Breakpoint.mobile

  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: getAcceptType(type),
    onDrop: (acceptedFiles) => {
      setFiles(
        // @ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      // @ts-ignore
      formik.setFieldValue(imageFieldName, acceptedFiles[0])
      // @ts-ignore
      formik.setFieldTouched(imageFieldName, true, false)
    },
    maxSize: 307200000
  })

  const mediaTypeText =
    type === UPLOAD_TYPE.IMAGE || type === UPLOAD_TYPE.ICON
      ? t`image`
      : t`video`

  const previewImage =
    files && files.length > 0
      ? files.map((file) => (
          // @ts-ignore
          <ImagePreview key={file.name} file={file} />
        ))
      : formik.values[fileUrlFieldName] && (
          <ImagePreview file={formik.values[fileUrlFieldName]} />
        )

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // @ts-ignore
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  return (
    <>
      <DropZone
        isMobile={isMobile}
        isDragActive={isDragActive}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        mediaTypeText={mediaTypeText}
        recommendedImageHeight={recommendedImageHeight}
        recommendedImageWidth={recommendedImageWidth}
      />
      {previewImage}
    </>
  )
}
