import { mapBroadcastThumbnailResponse } from 'api/channel/mapBroadcastThumnbailFromResponse'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { SearchQuery } from '../search.interface'

interface PastGamesSearch extends SearchQuery {}

interface PastGamesSearchResult {}

interface PastGamesSearchResults {
  data: PastGamesSearchResult[]
  totalCount: number
}

export const getPastGamesSearch = async ({
  searchTerm,
  channelSlug,
  pageNumber = 1,
  pageSize = 15
}: PastGamesSearch): Promise<PastGamesSearchResults> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'search/past_streams',
      params: {
        query: searchTerm,
        page: pageNumber,
        per_page: pageSize,
        ...(channelSlug && { root_channel: channelSlug })
      }
    })
    const responseData = await resultResponse.data
    const searchResults: any = mapBroadcastThumbnailResponse(responseData)
    return {
      data: searchResults,
      totalCount: resultResponse.totalCount || 0
    }
  } catch (e: any) {
    console.log('error', e)

    // @ts-ignore
    throw new Error(e.message)
  }
}
