import {
  TeamsForLeagueApiResponse,
  getTeamsForLeague
} from 'api/channel/getTeamsForLeague'
import { useDataApi } from 'api/useDataApi'
import { Loader } from 'components/Loader/Loader'
import { Team, TeamSlider } from 'components/sliders/TeamSlider/TeamSlider'
import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

export const TeamsInLeageueSection: FC = () => {
  const channel = useSelector(channelSelector)

  const channelSlug = channel.data.subdomain
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getTeamsForLeague, []),
    parameters: useMemo(
      () => ({
        slug: channelSlug,
        page: 1,
        perPageCount: 50
      }),
      [channelSlug]
    )
  })
  if (isLoading) {
    return <Loader />
  }
  const mappedTeamData: Team[] =
    data &&
    data.data &&
    data.data.length > 0 &&
    data.data.map((team: TeamsForLeagueApiResponse) => ({
      name: team.playpage_title,
      logoUrl: team.profile_image_url,
      subDomain: team.subdomain
    }))
  return data && data.data && data.data.length > 0 ? (
    <TeamSlider teams={mappedTeamData} />
  ) : null
}
