/* eslint-disable react/button-has-type */
import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'

// eslint-disable-next-line no-shadow
export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Ghost = 'Ghost',
  GhostWithBoarder = 'GhostWithBoarder',
  GhostInverted = 'GhostInverted',
  CreateAccount = 'CreateAccount',
  ThemedPrimary = 'ThemedPrimary',
  ThemedInverted = 'ThemedInverted',
  Active = 'Active',
  CTA = 'CTA'
}

export interface ButtonProps {
  type: ButtonType
  label: string
  buttonSize: 'XSmall' | 'Small' | 'Medium' | 'Large'
  roleType?: 'button' | 'submit' | 'reset'
  customStyle?: string | null
  onClick?: () => void
}
const Button: FC<ButtonProps> = ({
  type,
  label,
  buttonSize = 'Medium',
  roleType = 'button',
  customStyle,
  ...props
}) => (
  <button
    data-testid={`btn-${label}`}
    type={roleType}
    className={classNames(styles.Button, styles[type], styles[buttonSize], {
      [customStyle || '']: customStyle
    })}
    {...props}
  >
    {label}
  </button>
)

export default Button
