import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './ToggleItem.module.scss'

export enum ItemType {
  Active = 'Active',
  Default = 'Default'
}
interface Props {
  label: string
  itemType: ItemType
  onClick: () => void
}
export const ToggleItem: FC<Props> = ({ label, itemType, ...props }) => {
  return itemType === 'Default' ? (
    <div
      role="button"
      className={classNames(styles.ToggleItem, styles[itemType])}
      {...props}
      data-testid="toggle-btn-unselected"
    >
      {label}
    </div>
  ) : (
    <div
      className={classNames(styles.ToggleItem, styles[itemType])}
      data-testid="toggle-btn-selected"
    >
      {label}
    </div>
  )
}
