import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { ContentManagerMenuItemId } from './contentManagerSubmenuLinks'

export const getDefaultSelectedLink = (
  hasActiveIntegration: boolean,
  channelType: ChannelTypes
) => {
  if (hasActiveIntegration) {
    if ([ChannelTypes.team, ChannelTypes.league].includes(channelType)) {
      return ContentManagerMenuItemId.Competitions
    }
    if ([ChannelTypes.club].includes(channelType)) {
      return ContentManagerMenuItemId.Teams
    }
  }
  return ContentManagerMenuItemId.CustomContent
}
