import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  streamSelector,
  streamViewTypeSelector
} from 'redux-store/stream/stream.selectors'
import { StreamLabel } from 'components/cards/BroadcastThumbnail/ThumbnailLabel/ThumbnailLabel'
import { ProducedBy } from '../ProducedBy/ProducedBy'

import styles from './StreamInfo.module.scss'
import { ViewType } from 'redux-store/stream/stream.interface'

export const StreamInfo: FC = () => {
  const stream = useSelector(streamSelector)
  const [shouldBeLive, setShouldBeLive] = useState(false)
  const now = Date.now() / 1000
  const mediaObject = stream.data.mediaObject
  const streamTitle = mediaObject.title
  const liveStartAt = mediaObject.live_start_at
  const isReplay = mediaObject.is_replay
  const publishedAt = mediaObject.published_at
  const startAt = mediaObject.live_start_at
  const highlightFromDate = mediaObject.highlight_source
    ? mediaObject.published_at
    : null
  const streamType = useSelector(streamViewTypeSelector)
  const type = mediaObject.type
  const producer = mediaObject?.game?.producer
  const subTitle = mediaObject?.categories[0]?.translated_name
  const shouldNotShowLiveLabel = [
    ViewType.HIGHLIGHT,
    ViewType.REPLAY,
    ViewType.VIDEO
  ].includes(streamType)
  useEffect(() => {
    if (liveStartAt && liveStartAt < now && !shouldNotShowLiveLabel) {
      setShouldBeLive(true)
    }
  }, [now, liveStartAt, shouldNotShowLiveLabel, streamType])

  return (
    <div className={styles.Container}>
      <div className={styles.MainText}>
        <h1 data-testid="stream-title" className={styles.StreamTitle}>
          {streamTitle}
        </h1>
        {subTitle && (
          <h2 data-testid="stream-sub-title" className={styles.SubTitle}>
            {subTitle}
          </h2>
        )}
      </div>
      <div className={styles.Label}>
        <StreamLabel
          isLive={shouldBeLive}
          isReplay={isReplay}
          highlightFromDate={highlightFromDate}
          publishedAt={publishedAt}
          startAt={startAt}
          type={type}
        />
      </div>
      <ProducedBy producer={producer} />
    </div>
  )
}
