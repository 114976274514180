import React from 'react'
import { Alert, AlertColor, AlertTitle } from '@mui/material'

/**
 * @typedef {object} AlertMessageProps'
 */
type AlertMessageProps = {
  visible: boolean
  level: AlertColor
  title: string
  text: string
}

/**
 * AlertMessage
 * @desc A wrapper around materials Alert message
 * @category components
 *
 * @example
 * return (
 *  <AlertMessage
 *    visible
 *    level={AlertMessage.ALERT_LEVELS.ERROR}
 *    title="Error"
 *    text="Something went wrong"
 *  />
 * )
 */
const AlertMessage: React.FC<AlertMessageProps> = ({
  visible,
  level,
  title,
  text
}) => {
  if (!visible) {
    return <div style={{ display: 'none' }} />
  }

  return (
    <Alert severity={level}>
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  )
}

export default AlertMessage
