import React, { FC } from 'react'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { FacebookIcon } from './FacebookIcon'
import styles from './FacebookLoginButton.module.scss'
import useFacebookAuth from '../useFacebookAuth'

export const FacebookLoginButton: FC = () => {
  const facebookAuth = useFacebookAuth()
  return (
    <IconTextButton
      type={ButtonType.Ghost}
      buttonSize="Medium"
      label={'Facebook'}
      onClick={() => facebookAuth.login()}
      id="facebook-login-button"
      externalStyle={styles.FacebookButton}
    >
      <FacebookIcon />
    </IconTextButton>
  )
}
