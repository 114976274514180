import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { TopTextSection } from 'components/Modals/Sections/TopTextSection/TopTextSection'
import { SendConfirmation } from 'features/sportData/AddTeamModal/SendConfirmation'
import styles from './ForgotPasswordModal.module.scss'

interface Props {
  onAnimationEnd: () => void
}

export const SendConfirmationContent: FC<Props> = ({ onAnimationEnd }) => (
  <div className={styles.SuccessEmailContainer}>
    <TopTextSection
      title={t`Password reset email has been sent!`}
      subText={''}
    />
    <SendConfirmation onAnimationEnd={onAnimationEnd} />
  </div>
)
