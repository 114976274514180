import React, { FC } from 'react'
import { StreamOverview } from './components/StreamOverview/StreamOverview'
import { StreamMessage } from './components/StreamMessage/StreamMessage'
import { StreamSubmenu } from './components/StreamSubmenu/StreamSubmenu'
import { StreamPageTabContent } from './StreamPageTabContent'

export const StreamPageLayout: FC = () => {
  return (
    <div>
      <StreamMessage />
      <StreamOverview />
      <StreamSubmenu />
      <StreamPageTabContent />
    </div>
  )
}
