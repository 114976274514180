import React from 'react'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { channelSelector } from '../../../../../redux-store/channel/channel.selectors'
import styles from './BrandingHeader.module.scss'
import { ProductVariantField } from './ProductVariantField'

export const BrandingHeader = () => {
  const channel = useSelector(channelSelector)
  const channelName = channel?.data?.name

  return (
    <div className={styles.BrandingHeader}>
      <div className={styles.Container}>
        <h4 className={styles.Title}>
          <Trans>Rebrand system for {channelName}</Trans>
        </h4>
        <div className={styles.SubText}>
          <Trans>
            Choose the system variant that {channelName} should have. Changing
            this will affect all channels that are related to {channelName}.
          </Trans>
        </div>
      </div>
      <ProductVariantField />
    </div>
  )
}
