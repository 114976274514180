import React, { FC } from 'react'
import styles from './SubmenuItem.module.scss'
import PageLink, { MenuLink } from 'components/page/menuLink/MenuLink'
import classNames from 'classnames'

interface Props {
  submenu: MenuLink
  onClick?: () => void
}

export const SubmenuLink: FC<Props> = ({ submenu, onClick }) => {
  const isActive = location.pathname === submenu.link

  return (
    <div
      className={classNames(styles.SubmenuLink, { [styles.Active]: isActive })}
      data-testid="submenu-item"
      tabIndex={0}
    >
      <PageLink
        type={submenu.type}
        link={submenu.link}
        translationText={submenu.translationText}
        linkTarget={submenu.linkTarget}
        onClick={onClick}
      />
    </div>
  )
}
