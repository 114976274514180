import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styles from './AboutStream.module.scss'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { getChannelAdTargeting } from 'pages/ChannelPage/Start/getChannelAdTargeting'

import { streamSelector } from 'redux-store/stream/stream.selectors'
import classNames from 'classnames'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import { BannerAdSection } from '../BannerAdSection/BannerAdSection'
import { NativeAd } from 'advertising/NativeAd/NativeAd'
import { BackgroundTheme } from 'advertising/BannerAd/BannerAd'

export const AboutStream: FC = () => {
  const stream = useSelector(streamSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)

  const mediaObject = stream.data.mediaObject
  const description = mediaObject?.description
  const splitTextArray = description ? description.split('\n') : []
  const splitText = splitTextArray.map((text: string, i: number) => (
    <div key={`${text}-${i}`}>{text}</div>
  ))

  const channelDetails = useSelector(channelSelector).data
  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channelDetails)

  return (
    <div
      className={classNames(styles.AboutStream, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      {isViewingStream && (
        <BannerAdSection
          backgroundTheme={BackgroundTheme.None}
          showOnlySmallAdSizes
        />
      )}
      <div className={styles.Wrapper}>
        <div className={styles.Description}>
          <div className={styles.ContentContainer}>
            {!isViewingStream && (
              <div className={styles.Title}>{mediaObject?.title}</div>
            )}
            <div className={styles.Text}>
              {splitTextArray.length === 0 ? description : splitText}
            </div>
          </div>
        </div>
        {!isViewingStream && (
          <NativeAd
            slotPath="/21792873324/Viewer_About_Slot"
            adId="div-gpt-ad-1680188725142-0"
            size={['fluid']}
            targetingOptions={{
              partnerDomain: partnerDomain,
              parentChannelDomain: parentChannelDomain,
              sportCategory: sportCategory,
              countryCode: countryCode
            }}
            externalStyle={styles.NativeAd}
          />
        )}
      </div>
    </div>
  )
}
