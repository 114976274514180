import { RolesState } from '../../../redux-store/roles/roles.interface'
import { SubMenuLink } from './settingsSubmenuLinks'

export const getFilteredSettingsMenuLinks = (
  links: SubMenuLink[],
  context: RolesState
) =>
  links.filter((link) => {
    const { isSuperadmin, isAdmin, isChannelAdmin } = context
    switch (link.link) {
      case '/channel':
      case '/account':
        return isAdmin || isChannelAdmin || isSuperadmin
      case '/branding':
        return isSuperadmin
      default:
        return !link.hidden
    }
  })
