import React, { FC, ReactNode } from 'react'
import { t } from '@lingui/macro'
import { RadioButton } from './RadioButton'

import styles from './BannerSelector.module.scss'

interface Props {
  isSelected: boolean
  children: ReactNode
  onChangeSelection: (id: string) => void
  id: string
}

export const BannerSelector: FC<Props> = ({
  children,
  isSelected,
  onChangeSelection,
  id
}) => (
  <div className={styles.Container}>
    <div className={styles.Selection}>
      <RadioButton
        id={id}
        isChecked={isSelected}
        onChange={() => onChangeSelection(id)}
        label={t`Show on start page`}
      />
    </div>
    {children}
  </div>
)
