import React, { FC } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'

import { ReactComponent as ChevronDown } from './chevron--down.svg'
import styles from './ExpandedToggle.module.scss'

import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { UpAndDownIcon } from './UpAndDownIcon'
export enum ArrowType {
  Default = 'default',
  UpAndDown = 'upAndDown'
}
interface ExpandedToggleProps {
  expanded: boolean
  arrowIconToShow: ArrowType
  onToggleExpanded: () => void
}

export const ExpandedToggle: FC<ExpandedToggleProps> = ({
  expanded,
  arrowIconToShow,
  onToggleExpanded
}) => {
  const text = expanded ? t`View less` : t`View all`
  const breakpoint = useCurrentBreakpoint()
  const buttonSize = breakpoint > Breakpoint.sm ? 'Medium' : 'Small'
  const arrowToDisplay = () => {
    switch (arrowIconToShow) {
      case ArrowType.Default:
        return (
          <ChevronDown
            className={classNames(styles.ShouldPointUp, {
              [styles.IsExpanded]: expanded
            })}
          />
        )
      case ArrowType.UpAndDown:
        return <UpAndDownIcon />
      default:
        return (
          <ChevronDown
            className={classNames({ [styles.IsExpanded]: expanded })}
          />
        )
    }
  }
  return (
    <div className={styles.ExpandedToggleContainer}>
      <IconTextButton
        id="load-more"
        type={ButtonType.Ghost}
        label={text}
        buttonSize={buttonSize}
        onClick={onToggleExpanded}
      >
        {arrowToDisplay()}
      </IconTextButton>
    </div>
  )
}
