import { useCallback } from 'react'
import { VideoAdsType } from 'hooks/VideoAds/videoAdsType'
import { Chromecast } from './chromecast'

export const togglePlayCallback = (
  adsDone: boolean,
  chromecast: Chromecast,
  playing: boolean,
  getVideoElement: () => any,
  videoElementPlay: () => void,
  videoAds: VideoAdsType
) => {
  return useCallback(() => {
    if (adsDone) {
      if (chromecast.connected) {
        if (playing) {
          chromecast.pause()
        } else {
          chromecast.play()
        }
      } else if (getVideoElement().paused) {
        videoElementPlay()
      } else {
        getVideoElement().pause()
      }
    } else {
      switch (videoAds.statusCode) {
        case videoAds.codes.PAUSED:
          videoAds.resume()
          break
        case videoAds.codes.RESUMED:
          videoAds.pause()
          break
        case videoAds.codes.STARTED:
          videoAds.pause()
          break
        case videoAds.codes.CLICK:
          videoAds.resume()
          break
        default:
          break
      }
    }
  }, [videoAds, chromecast.connected, adsDone])
}
