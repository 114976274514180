import { useEffect } from 'react'

interface Props {
  faviconUrl: string
}

export const useFavIcon = ({ faviconUrl }: Props) => {
  useEffect(() => {
    if (faviconUrl) {
      // eslint-disable-next-line quotes
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        // @ts-ignore
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      // @ts-ignore
      link.href = faviconUrl
    }
  }, [faviconUrl])
  return null
}
