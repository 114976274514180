import { mapBroadcastThumbnailResponse } from '../../channel/mapBroadcastThumnbailFromResponse'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const getUpcomingLiveContent = async () => {
  // https://sportstage.se/api/play_v1/timeline_objects/collections/livestreams?page=1&per_page=12
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: '/timeline_objects/collections/livestreams',
      params: { page: 1, per_page: 12, include_games_without_livestreams: true }
    })
    const responseData = await resultResponse
    const mapped = mapBroadcastThumbnailResponse(responseData.data)
    return mapped
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
