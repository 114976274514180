export enum AlertType {
  General = 'general',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error',
  HiddenNoBroadcast = 'hidden-no-broadcast'
}

export const getAlertType = (severityState: string) => {
  switch (severityState) {
    case 'success':
    case 'alert-success':
      return AlertType.Success
    case 'warning':
    case 'alert-warning':
      return AlertType.Warning
    case 'error':
    case 'alert-error':
      return AlertType.Error
    case 'default':
    case 'custom-alert':
      return AlertType.General
    case 'info':
    case 'alert-info':
      return AlertType.Info
    default:
      return AlertType.Info
  }
}
