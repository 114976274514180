import React, { FC } from 'react'
import classNames from 'classnames'
import FooterItemDropdown, {
  ListItem
} from 'components/page/footer/defaultFooter/FooterItemDropdown/footerItemDropdown'
import { GlobeIcon } from './GlobeIcon'

import styles from './GeneralSection.module.scss'

interface Props {
  label: string
  languagesList: ListItem[]
  onChange: (item: string) => void
  selectedLanguage: string
}

export const LanguageDropdown: FC<Props> = ({
  label,
  languagesList,
  onChange,
  selectedLanguage
}) => {
  return (
    <div className={styles.LanguageContainer}>
      <GlobeIcon />
      <div className={classNames(styles.MenuItem, styles.DropDownButton)}>
        <FooterItemDropdown
          label={label}
          itemList={languagesList}
          onItemSelected={(item: string) => onChange(item)}
          value={selectedLanguage}
        />
      </div>
    </div>
  )
}
