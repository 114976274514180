import React, { FC } from 'react'

import Lottie from 'lottie-react'
import classNames from 'classnames'

import { FormValidationError } from '../../../../components/Forms/FormValidationError/FormValidationError'

import { ValidIcon } from './ValidIcon'
import animationData from './loader.json'

import styles from './TeamNameField.module.scss'

interface Props {
  id: string
  placeholder?: string
  isValid: boolean
  validationMessage: string
  textValue: string
  onChange: () => void
  leftSideText: string
  isLoading: boolean
}

export const TeamNameField: FC<Props> = ({
  id,
  placeholder,
  isValid,
  validationMessage,
  textValue,
  onChange,
  leftSideText,
  isLoading
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  }
  // eslint-disable-next-line no-nested-ternary
  const rightSideIcon =
    // eslint-disable-next-line no-nested-ternary
    isValid && textValue && textValue !== '' && textValue.length > 3 ? (
      <ValidIcon />
    ) : isLoading ? (
      <div className={styles.Loader}>
        <Lottie {...defaultOptions} />
      </div>
    ) : null

  const fullUrl = `https://${leftSideText}${textValue}`

  return (
    <div className={styles.TextFieldContainer}>
      <div
        className={classNames(styles.TextContainer, {
          [styles.Error]: !isValid
        })}
      >
        <div className={styles.LeftSideText}>{leftSideText}</div>
        <input
          data-testid="team-name-field"
          className={styles.TextField}
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          value={textValue}
        />
        <div className={styles.IconContainer}>
          <div className={styles.Icon}>{rightSideIcon}</div>
        </div>
      </div>
      <div className={styles.FullUrl}>{fullUrl}</div>
      {!isValid && <FormValidationError errorMessage={validationMessage} />}
    </div>
  )
}
TeamNameField.defaultProps = {
  placeholder: undefined
}
