import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  CategoryFilter,
  CategoryFilterType
} from './CategoryFilter/CategoryFilter'
import { EventScheduleRoutes } from './EventScheduleRoutes'

import styles from './EventSchedule.module.scss'
import { ChannelEvent } from './EventScheduleTabs'
import { getEventFilterPath } from './getEventFilterPath'
import { EventsDropdown } from '../../components/EventsDropdown/EventsDropdown'

interface Props {
  channelSlug: string
  eventData: ChannelEvent
  isHistoricData: boolean
  pastEvents: ChannelEvent[]
  onPastEventsSelected: (id: string) => void
}

function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined
}

export const EventSchedule: FC<Props> = ({
  channelSlug,
  eventData,
  isHistoricData,
  pastEvents,
  onPastEventsSelected
}) => {
  const history = useHistory()
  const location = useLocation()
  const eventSlug = eventData.eventSlug
  const filterPath = getEventFilterPath(location.pathname, eventSlug)

  const urlFilter = enumFromStringValue(CategoryFilterType, filterPath)

  const defaultInitialCategoryFilter = isHistoricData
    ? CategoryFilterType.Results
    : CategoryFilterType.Schedule

  const initialCategoryFilter = !urlFilter
    ? defaultInitialCategoryFilter
    : urlFilter

  const [selectedFilter, setSelectedFilter] = useState<CategoryFilterType>(
    initialCategoryFilter
  )

  useEffect(() => {
    if (selectedFilter === CategoryFilterType.Schedule && isHistoricData) {
      const resultsFilter = CategoryFilterType.Results
      setSelectedFilter(resultsFilter)
    }
  }, [selectedFilter, eventSlug, isHistoricData])

  const onChangePastGames = (id: string) => {
    const pastEvent = pastEvents.find((pe: ChannelEvent) => pe.id === id)
    onPastEventsSelected(pastEvent!.id)
  }

  const handleSelectedFilter = (filter: CategoryFilterType) => {
    setSelectedFilter(filter)
    switch (filter) {
      case CategoryFilterType.Results:
      case CategoryFilterType.Schedule:
        history.push(`/${channelSlug}/games/event/${eventSlug}/schedule`)
        break
      case CategoryFilterType.Classes:
        history.push(`/${channelSlug}/games/event/${eventSlug}/classes`)
        break
      case CategoryFilterType.Locations:
        history.push(`/${channelSlug}/games/event/${eventSlug}/locations`)
        break
      default:
        history.push(`/${channelSlug}/games`)
        break
    }
  }

  useEffect(() => {
    if (!location.pathname.includes(initialCategoryFilter)) {
      handleSelectedFilter(selectedFilter)
    }
  }, [eventSlug])

  const historicSelectedValue =
    (isHistoricData &&
      pastEvents.length > 0 &&
      //@ts-ignore
      pastEvents.find((pe: ChannelEvent) => pe.eventSlug === eventSlug)?.id) ||
    ''

  return (
    <div className={styles.SelectedEventPageContainer}>
      {isHistoricData && (
        <div className={styles.PreviousEventsDropdownContainer}>
          <EventsDropdown
            validationMessage=""
            isValid={true}
            label=""
            placeholder=""
            id="pastEventsDropdown"
            channelSlug={channelSlug}
            isError={false}
            isLoading={false}
            value={historicSelectedValue}
            onChange={onChangePastGames}
            options={pastEvents}
          />
        </div>
      )}

      <div className={styles.CategoryFilterContainer}>
        <CategoryFilter
          isHistoricData={isHistoricData}
          selectedFilter={selectedFilter}
          onSelectFilter={(filter: CategoryFilterType) =>
            handleSelectedFilter(filter)
          }
        />
      </div>
      <EventScheduleRoutes
        channelSlug={channelSlug}
        eventData={eventData}
        isHistoricData={isHistoricData}
      />
    </div>
  )
}
