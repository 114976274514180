import { LinkType } from 'components/page/menuLink/MenuLink'
import { ChannelMenuLink } from './Header/menuLinks'
import { CustomPage } from 'interfaces'

export const getSubmenuLinksForChannel = (
  linkList: ChannelMenuLink[],
  customPageSubmenu: CustomPage | null
) => {
  if (customPageSubmenu) {
    return [
      ...linkList,
      {
        id: '23',
        name: customPageSubmenu.title,
        type: LinkType.Route,
        link: `/menu/${customPageSubmenu.slug}`,
        translationText: customPageSubmenu.title,
        hidden: false,
        showInBreadcrumb: true
      }
    ]
  }
  return linkList
}
