import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { ChatMessageInterface, MediaObject } from 'interfaces'

import { useDispatch, useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { InputContainer } from './InputContainer/InputContainer'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
import {
  hideMessageSelector,
  newMessageSelector
} from 'redux-store/NchanSubscriber/reducer'
import subscriberActions from 'redux-store/NchanSubscriber/subscriber.actions'
import { getChatMessages } from 'api/viewer/Chat/getChatMessages'
import { hideChatMessage } from 'api/viewer/Chat/hideChatMessage'
import { blockUserFromChat } from 'api/viewer/Chat/blockUserFromChat'

import useInfiniteScroll from 'hooks/InfiniteScroll'
import { ChatLoader } from './ChatLoader/ChatLoader'
import ReporterMessage from './ChatMessage/ReporterMessage/ReporterMessage'
import { reporterImage } from './ChatMessage/ReporterMessage/getReporterImage'
import { ChatMessage } from './ChatMessage/UserMessage/ChatMessage'
import styles from './Chat.module.scss'
import { reporterText } from './getReporterText'

export const Chat: FC = () => {
  const stream = useSelector(streamSelector)

  const mediaObject: MediaObject = stream.data.mediaObject
  const infiniteScrollRef = useRef(null)
  const isTopReached = useInfiniteScroll(infiniteScrollRef)
  const [isLoading, setIsLoading] = useState(false)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<ChatMessageInterface[][]>([[]])
  const [chatData, setChatData] = useState([])
  const mediaObjectId = mediaObject.id
  const newMessage = useSelector(newMessageSelector)
  const hideMessage = useSelector(hideMessageSelector)
  const per_page = 20
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (isTopReached && !(chatData.length === 0)) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isTopReached, chatData])

  useEffect(() => {
    const newMessageId = newMessage && newMessage.message_id
    const foundMessage = messages
      .flat()
      .find((msg: ChatMessageInterface) => msg.message_id === newMessageId)

    if (newMessageId && !foundMessage) {
      const newMsgs = messages
      newMsgs[0] = [newMessage, ...messages[0]]
      setMessages(newMsgs)
      dispatch(subscriberActions.removeNewMessage())
    }
  }, [newMessage])

  useEffect(() => {
    setMessages([[]])
  }, [mediaObjectId])

  useEffect(() => {
    if (hideMessage) {
      setMessages((preMessages) =>
        preMessages.map((page: ChatMessageInterface[]) =>
          page.filter(
            (message: ChatMessageInterface) =>
              message.message_id !== hideMessage.message_id
          )
        )
      )
    }
  }, [hideMessage])

  const fetchMessages = useCallback(async () => {
    setIsLoading(true)
    const result = await getChatMessages(mediaObjectId, {
      page: page,
      per_page: per_page
    })
    setIsLoading(false)
    if (result.length > 0 && page > 0) {
      setChatData(result)
      const msgs = messages
      msgs[page - 1] = result
      setMessages(msgs)
    }
  }, [page, mediaObjectId])

  useEffect(() => {
    if (mediaObjectId) {
      fetchMessages()
    }
  }, [mediaObjectId, fetchMessages])

  const hideMessageInChat = (messageId: number) => {
    const hideMessageParameters = { messageId: messageId, mediaObjectId }
    hideChatMessage(hideMessageParameters)
  }
  const blockUserInChat = (messageId: number) => {
    blockUserFromChat({ messageId, mediaObjectId })
  }
  return (
    <div
      className={classNames(styles.Chat, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      <div className={styles.InputContainer}>
        <InputContainer mediaObjectId={mediaObject.id} />
      </div>
      <div className={styles.MessagesContainer} data-testid="message-container">
        {isLoading && page < 2 && (
          <div className={styles.LoaderContainer}>
            <ChatLoader />
          </div>
        )}
        {messages &&
          messages.length > 0 &&
          messages.flat().map((message) => (
            <div
              data-testid="chat-messages"
              key={`msg-${message.message_id}`}
              className={classNames(styles.Message)}
            >
              {message.activity_log && (
                <div className={styles.AlignRight}>
                  <ReporterMessage
                    reporterName={message.activity_log.team_name}
                    reporterImgPath={reporterImage(message.activity_log)}
                    text={reporterText(message.activity_log)}
                    publishedTime={message.time}
                  />
                </div>
              )}
              {!message.activity_log && (
                <ChatMessage
                  ident={message.message_id}
                  userName={message.user_name}
                  userImgPath={message.thumbnail}
                  text={message.message_text}
                  chatImages={message.images[0]}
                  chatVideos={message.videos[0]}
                  isAdmin={message.is_admin}
                  isSuperAdmin={message.is_super_admin}
                  publishedTime={message.time}
                  onHide={(id: number) => hideMessageInChat(id)}
                  onBlock={(id: number) => blockUserInChat(id)}
                />
              )}
            </div>
          ))}
      </div>
      <div ref={infiniteScrollRef} />
    </div>
  )
}
