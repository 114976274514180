import ContentContainer from 'components/ContentContainer'
import React, { FC } from 'react'
import { StartPageBannerSelector } from '../StartPageBanner/StartPageBannerSelector'
import { useQuery } from '@tanstack/react-query'
import { getStartPageBanner } from 'api/customContent/getStartPageBanner'
import { ChannelStartBanner } from 'pages/ChannelPage/Start/ChannelStartBanner'
import { StartBannerContent } from '../StartPageBanner/startPageBannerTypes'
import { BannerSectionPreloader } from './BannerSectionPreloader'
interface Props {
  channelSlug: string
  showSupporterBanner: boolean
  eventPassAvailable: boolean
  subscriptionsAvailable: boolean
  channelName: string
  userIsSupporter: boolean
  userHasEventPass: boolean
  handleSupporterBannerClose: () => void
  onSupportBannerClick: (destination: string) => void
}
export const BannerSection: FC<Props> = ({
  channelSlug,
  showSupporterBanner,
  eventPassAvailable,
  subscriptionsAvailable,
  channelName,
  userIsSupporter,
  userHasEventPass,
  handleSupporterBannerClose,
  onSupportBannerClick
}) => {
  const {
    data,
    isLoading,
    isError
  }: {
    data: StartBannerContent | undefined
    isLoading: boolean
    isError: boolean
  } = useQuery({
    queryKey: ['get-start-page-banners'],

    queryFn: () =>
      getStartPageBanner({
        channelSlug
      }),

    retry: false
  })

  if (isLoading)
    return (
      <ContentContainer
        hasLightBackground
        hasLeftRightPadding
        hasTopBottomPadding
      >
        <BannerSectionPreloader />
      </ContentContainer>
    )

  if (
    !isLoading &&
    !isError &&
    data !== null &&
    data &&
    data.published !== undefined &&
    data.published
  ) {
    return (
      <ContentContainer
        hasLightBackground
        hasLeftRightPadding
        hasTopBottomPadding
      >
        <StartPageBannerSelector content={data!} />
      </ContentContainer>
    )
  }
  if (
    (!isLoading && data === null) ||
    (data && data.published !== undefined && data.published === false)
  ) {
    return (
      <ChannelStartBanner
        showBanner={showSupporterBanner}
        eventPassAvailable={!!eventPassAvailable}
        subscriptionsAvailable={!!subscriptionsAvailable}
        channelName={channelName}
        channelSlug={channelSlug}
        isSupporter={userIsSupporter}
        hasEventPass={userHasEventPass}
        onBannerClose={handleSupporterBannerClose}
        onBannerClick={onSupportBannerClick}
      />
    )
  }
  return null
}
