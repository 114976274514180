import React, { FC } from 'react'
import styles from './InformationButton.module.scss'

import { AccordionTheme } from '../AccordionItem'
import classNames from 'classnames'
import { InformationIcon } from './InformationIcon'

interface Props {
  onClick: () => void
  theme: AccordionTheme
}

export const InformationButton: FC<Props> = ({ onClick, theme }) => {
  return (
    <div
      className={classNames(styles.InformationButtonContainer, styles[theme])}
      role="button"
      onClick={onClick}
    >
      <InformationIcon />
    </div>
  )
}
