import React, { FC, useEffect, useState } from 'react'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'
import {
  FilteredGamesList,
  FilteredGamesListType
} from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'
import { DateFilteredContent } from 'features/multistageTournaments/DateFilteredContent/DateFilteredContent'

import {
  EventDate,
  getEventDates
} from 'api/multistageTournament/getEventDates'
import { DateFilterModal } from 'features/multistageTournaments/DateFilterModal/DateFilterModal'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getDateFromDateSlug } from 'features/multistageTournaments/DateFilterModal/getDateFromDateSlug'

interface Props {
  channelSlug: string
  now: number
  eventData: ChannelEvent
  isHistoricData: boolean
}

export const ScheduleContainer: FC<Props> = ({
  channelSlug,
  now,
  eventData,
  isHistoricData
}) => {
  const history = useHistory()
  const { date } = useParams<{
    date?: string
  }>()
  const eventSlug = eventData.eventSlug
  const [dateSelected, setDateSelected] = useState<EventDate | undefined>(
    undefined
  )
  const {
    data,
    isLoading
  }: {
    data: any | undefined
    isLoading: boolean
    //@ts-ignore
  } = useQuery({
    queryKey: [`get-event-dates-${eventSlug}`],

    queryFn: () =>
      getEventDates({
        eventSlug: eventSlug,
        channelSlug: channelSlug
      })
  })
  useEffect(() => {
    if (date && data) {
      const dateFromSlug = getDateFromDateSlug(date, data.data.data)
      setDateSelected(dateFromSlug)
    }
  }, [date, data])

  const showDateFilter = dateSelected && !isHistoricData
  const [showSelectDateModal, setShowSelectDateModal] = useState(false)
  const handleOnSelectDate = (date: EventDate) => {
    setDateSelected(date)
    setShowSelectDateModal(false)
    history.push(
      `/${channelSlug}/games/event/${eventSlug}/schedule/${date.slug}`
    )
  }

  useEffect(() => {
    setDateSelected(undefined)
  }, [eventSlug])

  const handleResetButton = () => {
    setDateSelected(undefined)
    history.push(`/${channelSlug}/games/event/${eventSlug}/schedule`)
  }

  return (
    <>
      {showDateFilter ? (
        <DateFilteredContent
          dateSelected={dateSelected}
          now={now}
          channelSlug={channelSlug}
          eventSlug={eventSlug}
          onCalendarClick={() => {
            setShowSelectDateModal(true)
          }}
          onResetFilterClick={handleResetButton}
        />
      ) : (
        <FilteredGamesList
          isHistoricData={isHistoricData}
          channelSlug={channelSlug}
          eventSlug={eventSlug}
          type={FilteredGamesListType.Secondary}
          now={now}
          onCalendarIconClick={() => {
            setShowSelectDateModal(true)
          }}
        />
      )}
      {showSelectDateModal && !isLoading && data?.data && (
        <DateFilterModal
          dropdownList={data.data.data}
          onSelectedDate={(selectedDate) => handleOnSelectDate(selectedDate)}
          preSelectedDate={dateSelected}
          onClose={() => setShowSelectDateModal(false)}
        />
      )}
    </>
  )
}
