import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import dayjs from 'dayjs'
import { getIsStreamLive } from 'helpers/getIsStreamLive'

export const groupVideosByDate = (
  videoList: GameScheduleEventCardType[],
  now: number
) => {
  const list = videoList.reduce((ac: any, a) => {
    const isLive = getIsStreamLive(a.startTime, a.endTime, now)
    const key: string = isLive
      ? 'live'
      : dayjs.unix(a.startTime).format('YYYY-MM-DD')

    ac[key] = (ac[key] || []).concat(a)
    return ac
  }, {})
  return list
}
