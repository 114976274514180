import React, { FC } from 'react'
import { Trans } from '@lingui/macro'

import styles from './ImagePreview.module.scss'

interface Props {
  file: any
}

export const ImagePreview: FC<Props> = ({ file }) => {
  const fileSize = file.size > 1000 ? file.size / 1000 : 0
  const source = file.preview ? file.preview : file
  return (
    <div className={styles.PreviewContainer} key={file.name}>
      <div className={styles.InnerPreview}>
        <img
          src={source}
          className={styles.PreviewImg}
          // Revoke data uri after image is loaded
          onLoad={() => {
            // @ts-ignore
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
      {file.path && (
        <div className={styles.FileInfo}>
          <div className={styles.FileName}>{file.path}</div>
          <div className={styles.FileSize}>
            <Trans>Size</Trans>:<div>{fileSize} kb</div>
          </div>
        </div>
      )}
    </div>
  )
}
