import React, { FC } from 'react'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import styles from './CallToActionOverlay.module.scss'
import {
  ButtonType,
  LinkButton
} from 'components/buttons/LinkButton/LinkButton'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
interface Props {
  title: string
  text: string
  buttonText: string
  buttonLink: string
  target: LinkTarget
}

export const CallToActionOverlay: FC<Props> = ({
  title,
  text,
  buttonText,
  buttonLink,
  target
}) => {
  const breakpoint = useCurrentBreakpoint()
  const buttonSize = breakpoint <= Breakpoint.sm ? 'Small' : 'Medium'
  const showButton = buttonLink && buttonText && target

  return (
    <div
      className={styles.PromotionOverlayContainer}
      data-testid="call-to-action-overlay"
    >
      <div className={styles.Content}>
        <h3 className={styles.Title}>{title}</h3>
        <div className={styles.Text}>{text}</div>
        {showButton && (
          <LinkButton
            linkTarget={target}
            label={buttonText}
            href={buttonLink}
            buttonSize={buttonSize}
            type={ButtonType.GhostWithBoarder}
          />
        )}
      </div>
    </div>
  )
}
