import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { t } from '@lingui/macro'

import menuItems, { ViewerPageMenuOption } from './StreamSubmenuLinks'
import { useSelector } from 'react-redux'
import {
  channelSelector,
  isSwedishHandballSelector
} from 'redux-store/channel/channel.selectors'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { MediaObject } from 'interfaces'
import MEDIA_OBJECT from 'constants/media_object.constants'

import styles from './StreamSubmenu.module.scss'
import { getSelectedTab } from './getSelectedTab'
import { useLocation } from 'react-router-dom'
import { ReplaceLink } from 'components/ReplaceLink/ReplaceLink'
import { useSearchParam } from 'hooks/useSearchParam'
import { VideoPlayerData } from 'features/viewerPage/viewerPage.interfaces'
import { getBroadcastPath } from 'features/viewerPage/helpFunctions/getBroadcastPath'

export const StreamSubmenu: FC = () => {
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const stream = useSelector(streamSelector)
  const channel = useSelector(channelSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const location = useLocation()
  const tab = useSearchParam('tab')
  const mediaObject: MediaObject = stream.data.mediaObject
  const mediaPlayerData: VideoPlayerData = stream.data
  const fallbackMenuId = ViewerPageMenuOption.AboutStream
  const showGameLineups = isSwedishHandball
  const { hasGame, canComment } = stream

  const { pathname } = location

  const selectedLink = getSelectedTab(menuItems, tab ? tab : 'about')
  const initialSelectedMenu = selectedLink ? selectedLink.id : fallbackMenuId
  const [selectedMenuId, setSelectedMenuId] = useState(initialSelectedMenu)
  const showChat =
    mediaObject?.type === MEDIA_OBJECT.TYPES.LIVESTREAM ||
    mediaObject?.is_replay

  const broadcastPath =
    mediaPlayerData &&
    getBroadcastPath(pathname, channel?.data?.subdomain, mediaPlayerData)

  useEffect(() => {
    const items = menuItems.filter((link) =>
      link.id === ViewerPageMenuOption.Chat ? canComment : true
    )
    if (items.length === 1) {
      setSelectedMenuId(items[0].id)
    } else {
      setSelectedMenuId(initialSelectedMenu)
    }
  }, [canComment, selectedLink, initialSelectedMenu])

  const items = menuItems.filter((link) => {
    switch (link.id) {
      case ViewerPageMenuOption.Chat:
        return canComment
      case ViewerPageMenuOption.Result:
        return hasGame
      case ViewerPageMenuOption.Lineup:
        return hasGame && showGameLineups
      default:
        return true
    }
  })
  if (items.length === 1) {
    return isViewingStream ? <div className={styles.Divider} /> : null
  }
  return (
    <div
      className={classNames(styles.ViewerSubmenuContainer, {
        [styles.IsViewingStream]: isViewingStream
      })}
      data-testid="viewer-page-submenu-container"
    >
      <ul data-testid="viewer-page-submenu-list">
        {items.map((link) => {
          const isSelected = link.id === selectedMenuId || items.length === 1
          return (
            <li
              data-testid={`viewer-page-submenu-${link.id}`}
              key={link.id}
              className={classNames({
                [styles.NotSelected]: !isSelected
              })}
              onClick={() => setSelectedMenuId(link.id)}
              onKeyUp={(event) => {
                if (event.key === ' ') {
                  setSelectedMenuId(link.id)
                }
              }}
            >
              {!showChat && link.id === ViewerPageMenuOption.Chat ? (
                <ReplaceLink
                  link={`${broadcastPath}?tab=comments`}
                  translationText={t`Comments`}
                />
              ) : (
                <ReplaceLink
                  {...{ ...link, link: `${broadcastPath}${link.link}` }}
                />
              )}
              <br />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
