import React, { FC } from 'react'
import { PlayerStatisticsTable } from '../Table/PlayerStatisticsTable'

import { Trans } from '@lingui/macro'
import ContentContainer from 'components/ContentContainer'
import { PlayerStatisticsTableInterface } from '../Table/PlayerStatisticsTable.interface'
interface Props {
  playerStatistics: PlayerStatisticsTableInterface
  selectedEventName: string
}

export const PlayerStatisticsTableSection: FC<Props> = ({
  playerStatistics,
  selectedEventName
}) => {
  if (
    playerStatistics &&
    playerStatistics.columns?.length > 0 &&
    playerStatistics.rows?.length > 0
  ) {
    return (
      <PlayerStatisticsTable
        columns={playerStatistics.columns}
        rows={playerStatistics.rows}
        defaultSortedBy={playerStatistics.sortedBy}
        integrationName={playerStatistics.integration.name}
        integrationLink={playerStatistics.integration.link}
        eventName={playerStatistics.name}
        lastUpdatedTimestamp={playerStatistics.integration.lastUpdated}
      />
    )
  }
  return (
    <ContentContainer
      hasLeftRightPadding={true}
      hasTopBottomPadding={false}
      hasLightBackground={false}
    >
      <Trans>
        There are no player statistics available for {selectedEventName}
      </Trans>
    </ContentContainer>
  )
}
