import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './NewsTitle.module.scss'

interface NewsTitleProps {
  title: string
  extraContent?: string
}

export const NewsTitle: FC<NewsTitleProps> = ({ title, extraContent }) => (
  <div className={styles.NewsTitleContainer}>
    <h4
      data-testid="news-thumbnail-title"
      className={classNames(styles.Newstitle)}
    >
      {title}
    </h4>

    <div className={styles.extraContent} data-testid="extra-content">
      {extraContent}
    </div>
  </div>
)
