import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { t, Trans } from '@lingui/macro'

import { CopyLink } from './Views/CopyLink'
import styles from './ShareComponent.module.scss'
import Button, { ButtonType } from '../../../buttons/button/Button'
import { OpenCloseArrow } from '../../../OpenCloseArrow/OpenCloseArrow'
import { ShareOnFacebookButton } from './ShareOnFacebookButton'
import { getFacebookLoginId } from '../../../session/getIsLoginAvailable'

enum ShareOptionType {
  Twitter,
  Facebook,
  Email
}

interface ShareComponentProps {
  mediaId: string
  mediaTitle: string
  companySlug: string
  onClose: () => void
}

type ShareItemType = {
  id: ShareOptionType
  name: string
  url?: string
}

export const ShareComponent: FC<ShareComponentProps> = ({
  mediaId,
  mediaTitle,
  onClose,
  companySlug
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [iFrameSrc, setIFrameSrc] = useState('')
  const shareList = [
    { id: ShareOptionType.Email, name: t`Email` },
    {
      id: ShareOptionType.Facebook,
      name: 'Facebook'
    },
    {
      id: ShareOptionType.Twitter,
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${mediaTitle} ${window.location.href}`
    }
  ]

  useEffect(() => {
    const iframeSrc = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/${companySlug}/widgets/thumbnail/${mediaId}`
    setIFrameSrc(
      `<iframe src="${iframeSrc}" width="560" height="315" frameborder="0" scrolling="0" allowfullscreen></iframe>`
    )
  }, [mediaId, companySlug, showAdvanced])

  const handleShareClick = (shareType: ShareItemType) => {
    if (shareType.id === ShareOptionType.Twitter) {
      window.open(shareType.url, '_blank')
    }
    if (shareType.id === ShareOptionType.Email) {
      window.open(`mailto:?subject=${mediaTitle}&body=${window.location.href}`)
    }

    onClose()
  }

  const facebookId = getFacebookLoginId()

  return (
    <div
      className={styles.ShareComponentContainer}
      data-testid="share-component-continer"
    >
      <div className={styles.ShareComponentSection} data-testid="share-link">
        <div className={styles.Heading}>
          <Trans>Link to broadcast</Trans>
        </div>
        <div>
          <CopyLink textValue={window.location.href} />
        </div>
      </div>

      <div
        className={styles.ShareComponentSection}
        data-testid="share-social-media"
      >
        <div className={styles.Heading}>
          <Trans>Share on social media</Trans>
        </div>
        <div className={styles.shareListSection} data-testid="share-buttons">
          {shareList.map((shareItem) => {
            if (shareItem.id === ShareOptionType.Facebook && facebookId) {
              return (
                <ShareOnFacebookButton
                  onClose={onClose}
                  key={shareItem.name}
                  facebookId={facebookId}
                />
              )
            }
            return (
              <div key={shareItem.name}>
                <Button
                  buttonSize="Medium"
                  label={shareItem.name}
                  type={ButtonType.GhostWithBoarder}
                  onClick={() => handleShareClick(shareItem)}
                />
              </div>
            )
          })}
        </div>
        <div>
          <div
            className={styles.Advanced}
            role="button"
            onClick={() => setShowAdvanced(!showAdvanced)}
          >
            <div className={styles.AdvancedHeading}>Advanced</div>
            <OpenCloseArrow isOpen={showAdvanced} />
          </div>
          {showAdvanced && (
            <div className={styles.AdvancedShare}>
              <div
                className={classNames(
                  styles.Heading,
                  styles.ShareComponentSection
                )}
                data-testid="share-embedded-link"
              >
                <div className={styles.Heading}>
                  <Trans>Embeded link</Trans>
                </div>
                <div data-testid="share-iframe-link">
                  <CopyLink textValue={iFrameSrc} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
