import React, { FC, useState } from 'react'
import { AccordionItem, AccordionTheme } from './AccordionItem/AccordionItem'
import styles from './Accordion.module.scss'

interface Props {
  items: AccordionItem[]
  initialOpenItems: string[]
  theme: AccordionTheme
  showInformationButton?: boolean
  onInformationClick?: (id: string) => void
}

export const Accordion: FC<Props> = ({
  items,
  initialOpenItems = [],
  theme,
  showInformationButton,
  onInformationClick
}) => {
  const [openItems, setOpenItems] = useState(initialOpenItems)

  const handleToggle = (id: string) => {
    if (openItems.find((item: string) => item === id)) {
      setOpenItems(openItems.filter((item: string) => item !== id))
    } else {
      setOpenItems([...openItems, id])
    }
  }

  const accordionItems = items.map((item: AccordionItem) => {
    const { id } = item
    const isAccordionOpen = openItems.find((item: string) => item === id)
    return (
      <AccordionItem
        theme={theme}
        key={item.headerText}
        {...item}
        toggleOpen={handleToggle}
        isOpen={!!isAccordionOpen}
        showInformationButton={showInformationButton}
        onInformationClick={onInformationClick}
      />
    )
  })

  return <div className={styles.AccordionContainer}>{accordionItems}</div>
}
