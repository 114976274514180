import React from 'react'
export const ShieldsIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2314 18.5533C15.3972 18.6313 15.5661 18.7035 15.7379 18.7696L15.7381 18.7697L16.3491 19.0044L16.5645 18.4444L16.3493 19.0045C16.8948 19.2143 17.4987 19.2143 18.0442 19.0045L17.8292 18.445L18.0444 19.0044L18.6553 18.7697L18.6555 18.7696C20.0873 18.2188 21.3186 17.2471 22.1871 15.9825C23.0556 14.7179 23.5205 13.2198 23.5205 11.6857V7.21763V7.21587H22.9205L23.5205 7.21989L23.5205 7.21763C23.5219 6.9346 23.4418 6.65713 23.2897 6.41842C23.1372 6.17908 22.9187 5.9889 22.6607 5.87076L22.6607 5.87067L22.6509 5.86641C21.6491 5.42912 20.6001 5.11784 19.5279 4.93746C19.5467 5.02053 19.5561 5.10589 19.5555 5.19191V6.1612C20.4502 6.32922 21.3256 6.59782 22.1647 6.9635C22.211 6.98549 22.2502 7.02008 22.2777 7.06334C22.306 7.10769 22.3208 7.15927 22.3205 7.21186H22.3205V7.21587V11.6857C22.3205 12.9772 21.9291 14.2385 21.1979 15.3031C20.4668 16.3677 19.4302 17.1858 18.2249 17.6495L18.4399 18.209L18.2247 17.6496L17.6138 17.8843L17.6136 17.8844C17.3453 17.9876 17.0482 17.9876 16.7799 17.8844L16.7797 17.8843L16.3806 17.731C16.2658 17.8277 16.1487 17.9215 16.0293 18.0123L15.9536 18.209L16.0291 18.0123C15.7734 18.2067 15.5071 18.3873 15.2314 18.5533ZM22.1647 6.9635L22.1612 6.96186L22.4109 6.41631L22.1709 6.96621L22.1647 6.9635Z"
      fill="#2F3135"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93025 6.11932V5.19191C4.92958 5.09239 4.94224 4.99375 4.9675 4.89853C3.81 5.07116 2.67721 5.39582 1.59905 5.86641L1.59901 5.86632L1.58932 5.87076C1.33126 5.9889 1.11283 6.17908 0.960296 6.41842C0.808169 6.65713 0.728054 6.9346 0.729512 7.21762V7.21588H1.32951L0.729525 7.21989L0.729512 7.21762V11.6857C0.729525 13.2198 1.19441 14.7179 2.06289 15.9825C2.93136 17.2471 4.16266 18.2188 5.59447 18.7696L5.59468 18.7697L6.20561 19.0044L6.42101 18.4444L6.2058 19.0045C6.75131 19.2143 7.35522 19.2143 7.90072 19.0045L7.68552 18.4444L7.90092 19.0044L8.51185 18.7697L8.51206 18.7696C8.72965 18.6859 8.9426 18.5925 9.15036 18.4898C9.62481 18.7843 10.1281 19.0351 10.6544 19.2376M4.93025 6.11932C3.95358 6.28287 2.99797 6.56575 2.0853 6.9635L2.08882 6.96186L1.83907 6.41631L2.07909 6.96621L2.0853 6.9635C2.03902 6.98549 1.99983 7.02009 1.97226 7.06335C1.944 7.1077 1.92915 7.15927 1.9295 7.21186L1.32951 7.21588H1.92951V11.6857C1.92952 12.9772 2.32091 14.2385 3.05208 15.3031C3.7832 16.3677 4.81975 17.1858 6.0251 17.6495L5.80989 18.2096L6.02531 17.6496L6.63622 17.8843L6.63641 17.8844C6.90473 17.9876 7.2018 17.9876 7.47012 17.8844L7.47031 17.8843L8.03194 17.6685C7.4527 17.1695 6.93676 16.5955 6.49912 15.9582C5.47725 14.4703 4.93026 12.7076 4.93025 10.9026V6.11932ZM8.11602 17.7401L8.29664 18.2096L8.11632 17.7403"
      fill="#2F3135"
    />
    <path
      d="M4.9302 5.19197V10.9026C4.93022 12.7077 5.47721 14.4703 6.49907 15.9583C7.52093 17.4462 8.96969 18.5896 10.6544 19.2376L11.4351 19.5377C11.9551 19.7377 12.5308 19.7377 13.0507 19.5377L13.8315 19.2376C15.5162 18.5896 16.9649 17.4462 17.9868 15.9583C19.0086 14.4703 19.5556 12.7077 19.5556 10.9026V5.19197C19.5571 4.97708 19.4964 4.76634 19.3809 4.58512C19.2654 4.40389 19.1 4.25989 18.9046 4.17044C16.8044 3.25375 14.5344 2.79089 12.2429 2.81215C9.9515 2.79089 7.68141 3.25375 5.58122 4.17044C5.38582 4.25989 5.22043 4.40389 5.10494 4.58512C4.98944 4.76634 4.92876 4.97708 4.9302 5.19197Z"
      fill="white"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
