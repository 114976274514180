/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useState, useMemo } from 'react'
import classNames from 'classnames'

import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'

import { channelSelector } from '../../redux-store/channel/channel.selectors'
import { useDataApi } from '../../api/useDataApi'
import { FilterSection } from './Filter/FilterSection'
import { FilterType } from './filterTypes'
import { Loader } from '../../components/Loader/Loader'
import { ResultContent } from './ResultContent/ResultContent'

import { getChannelSearch } from 'api/search/channelSearch/searchChannels'
import { getVideoSearch } from 'api/search/videoSearch/seachVideos'
import { getFutureGamesSearch } from 'api/search/futureGamesSearch/getFutureGamesSearch'
import { getPastGamesSearch } from 'api/search/pastGamesSearch/getPastGamesSearch'

import LoadMoreSection from 'components/LoadMoreSection/LoadMoreSection'

import styles from './SearchResults.module.scss'
import { useSearchParam } from 'hooks/useSearchParam'

export const SearchResultPage = () => {
  const query = useSearchParam('query')
  const channel = useSelector(channelSelector)

  const [filter, setFilter] = useState(FilterType.Channels)

  const isChildChannel = channel?.data?.isChildOfRootChannel
  const isRootPage = channel?.data?.isRootChannel
  const parentChannelSubdomain = channel?.data?.parentChannelSubdomain
  const isWithinRootOrganization = isChildChannel || isRootPage
  const rootChannelSubdomain = channel?.data?.subdomain
  const rootChannelType = channel?.data?.is_root_channel
    ? channel?.data?.type
    : channel?.data?.root_channel?.type
  const hasTeams = channel?.data?.meta_data.misc?.relations?.has_teams

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pagedData, setPagedData] = useState<any[][] | undefined>()

  const pageSize = 15

  const searchSubdomain = isChildChannel
    ? parentChannelSubdomain
    : rootChannelSubdomain

  const parameters = isWithinRootOrganization
    ? useMemo(
        () => ({
          //@ts-ignore
          searchTerm: query,
          channelSlug: searchSubdomain,
          pageNumber: currentPage,
          pageSize
        }),
        [query, currentPage]
      )
    : useMemo(
        () => ({ searchTerm: query, pageNumber: currentPage, pageSize }),
        [query, currentPage]
      )

  const wrappedGamesSearch = useCallback(getVideoSearch, [])
  const wrappedChannelSearch = useCallback(getChannelSearch, [])
  const wrappedPastGamesSearch = useCallback(getPastGamesSearch, [])
  const wrappedFutureGamesSearch = useCallback(getFutureGamesSearch, [])

  let getData

  switch (filter) {
    case FilterType.Channels: {
      getData = wrappedChannelSearch
      break
    }
    case FilterType.Videos: {
      getData = wrappedGamesSearch
      break
    }
    case FilterType.UpcomingStreams: {
      getData = wrappedFutureGamesSearch
      break
    }
    case FilterType.PastStreams: {
      getData = wrappedPastGamesSearch
      break
    }
    default: {
      break
    }
  }

  const { data, isLoading, isError } = useDataApi({
    // @ts-ignore
    apiCall: getData,
    parameters,
    initialData: {}
  })

  const increasePageNumber = () => {
    setCurrentPage((prevValue) => prevValue + 1)
  }
  useMemo(() => {
    setCurrentPage(1)
    setPagedData(undefined)
  }, [query, filter])

  useMemo(() => {
    if (data?.data) {
      setTotalPages(data.totalCount / pageSize)
      const newPagedData = [...(pagedData || [])]
      newPagedData[currentPage - 1] = data.data
      setPagedData(newPagedData)
    }
  }, [data])

  const headerTagId = 'header-id'

  return (
    <div className={styles.SearchResult}>
      <header id={headerTagId} className={styles.ResultsHeaderContainer}>
        <h2>
          <Trans>Results for</Trans>
          {` "${query}"`}
        </h2>
        <FilterSection
          rootChannelType={rootChannelType}
          onFilterChanged={setFilter}
          selectedFilter={filter}
          hasTeams={hasTeams}
        />
      </header>
      <section
        className={classNames({
          [styles.SearchResultChannelSection]: filter === FilterType.Channels,
          [styles.SearhResultVideoSection]: filter !== FilterType.Channels
        })}
      >
        {isError && (
          <div className={styles.SearchMessage}>
            <Trans>
              Search text must be at least 2 characters. Please try again
            </Trans>
          </div>
        )}
        {isLoading && !isError && currentPage === 1 && <Loader />}
        {!isError && pagedData && (
          <>
            {(!isLoading || currentPage > 1) && (
              <ResultContent
                isRootOrChildChannel={isWithinRootOrganization}
                filter={filter}
                // @ts-ignore
                data={pagedData.flat()}
              />
            )}
            {isLoading && currentPage > 1 && <Loader />}

            <LoadMoreSection
              onClick={increasePageNumber}
              currentPage={currentPage}
              totalPages={totalPages}
              scrollId={headerTagId}
            />
          </>
        )}
      </section>
    </div>
  )
}
