import React, { FC, useCallback, useEffect, useRef } from 'react'
import useExternalScript from '../../../../hooks/ExternalScript'
import Button, { ButtonType } from '../../../buttons/button/Button'

interface ShareOnFacebookButtonProps {
  onClose: () => void
  facebookId: string
}

export const ShareOnFacebookButton: FC<ShareOnFacebookButtonProps> = ({
  onClose,
  facebookId
}) => {
  const facebookLoadStatus = useExternalScript(
    'https://connect.facebook.net/en_US/all.js'
  )
  const fbElementRef = useRef<HTMLDivElement>(null)

  const onFacebookShare = useCallback(() => {
    ;(window as any).FB.ui(
      {
        display: 'popup',
        method: 'share',
        href: `${window.location.href}`
      },
      () => {
        onClose()
      }
    )
  }, [(window as any).FB])

  useEffect(() => {
    if (facebookLoadStatus.code === 200) {
      ;(window as any).FB?.init({
        appId: facebookId,
        status: true,
        xfbml: true,
        version: 'v11.0'
      })
    }
  }, [facebookLoadStatus.code, facebookId])

  return (
    <div ref={fbElementRef}>
      <Button
        buttonSize="Medium"
        label="Facebook"
        type={ButtonType.GhostWithBoarder}
        onClick={onFacebookShare}
      />
    </div>
  )
}
