import { useEffect } from 'react'

interface Props {
  adsDone: boolean
  log: (message: string, ...options: any) => void
  muted: boolean
  setMuted: (value: boolean) => void
  setIsVisible: (value: boolean) => void
  playing: boolean
  chromecastConnected: boolean
  firstInteractionDone: boolean
  videoElement: HTMLElement
  playButton: HTMLElement
  bigPlayButton: HTMLElement
  togglePlayPause: any
  videoWrapper: HTMLElement | null
  isMobile: boolean
  controlsWrapper: HTMLElement | null
  adContainer: HTMLElement
  adStatusCode: any
  progressBuffer?: number
}

export const usePlayerControls = ({
  adsDone,
  log,
  muted,
  setMuted,
  setIsVisible,
  playing,
  chromecastConnected,
  firstInteractionDone,
  videoElement,
  playButton,
  togglePlayPause,
  videoWrapper,
  isMobile,
  controlsWrapper,
  adContainer,
  bigPlayButton,
  adStatusCode,
  progressBuffer
}: Props) =>
  useEffect(() => {
    let controlsTimeout: any
    let hoveringControls = false
    let didTouch = false

    const onPlaying = () => {
      if (adsDone) {
        log('playing')
        doHideControls(2000)
      }
      didTouch = false
    }
    const onVolumeChange = (e: any) => {
      if (e.currentTarget.volume <= 0) {
        setMuted(true)
      } else if (muted) {
        setMuted(false)
      }
    }

    const onTouch = (e: any) => {
      if (adsDone && isMobile) {
        e.preventDefault()
        e.stopPropagation()
        e.cancelBubble = true
        didTouch = !didTouch
        setIsVisible(didTouch)
        doHideControls(3000)
      }
    }

    const onMouseOut = (e: any) => {
      e.stopPropagation()
      e.cancelBubble = true
      if (adsDone) {
        doHideControls()
      }
    }

    const onMouseMove = (e: any) => {
      e.stopPropagation()
      e.cancelBubble = true
      if (firstInteractionDone && adsDone) {
        setIsVisible(true)
        if (!hoveringControls && adsDone) {
          doHideControls(2500)
        }
      }
    }

    const onMouseOverControls = (e: any) => {
      if (!isMobile) {
        e.stopPropagation()
        e.cancelBubble = true
        clearTimeout(controlsTimeout)
        hoveringControls = true
      }
    }

    const onMouseOutControls = (e: any) => {
      if (!isMobile) {
        e.stopPropagation()
        e.cancelBubble = true
        hoveringControls = false
      }
    }

    const doHideControls = (timeout: number = 1000) => {
      clearTimeout(controlsTimeout)
      if (adsDone && playing && !chromecastConnected) {
        controlsTimeout = setTimeout(() => {
          setIsVisible(false)
          didTouch = false
        }, timeout)
      }
    }
    if (adsDone && playing && !chromecastConnected) {
      doHideControls(2000)
    }

    if ((chromecastConnected || !adsDone) && playing) {
      clearTimeout(controlsTimeout)
      setIsVisible(true)
    }

    if (videoElement) {
      videoElement.addEventListener('play', onPlaying)
      videoElement.addEventListener('volumechange', onVolumeChange)
      if (playButton) {
        playButton?.addEventListener('touchstart', togglePlayPause)
        playButton?.addEventListener('click', togglePlayPause)
      }
      if (bigPlayButton) {
        bigPlayButton?.addEventListener('touchstart', togglePlayPause)
        bigPlayButton?.addEventListener('click', togglePlayPause)
      }
      if (videoWrapper) {
        if (isMobile) {
          videoElement.addEventListener('touchstart', onTouch, false)

          if (adContainer) {
            adContainer.addEventListener('touchstart', onTouch, false)
          }
        } else {
          videoWrapper.addEventListener('mousemove', onMouseMove)
          videoWrapper.addEventListener('mouseleave', onMouseOut)
          if (adContainer) {
            adContainer.addEventListener('mousemove', onMouseMove)
            adContainer.addEventListener('mouseleave', onMouseOut)
          }
        }
      }
      // try to  add event listener to the
      // individual divs for control bar and contrsols
      if (controlsWrapper) {
        log('add event listener')
        const controls = controlsWrapper.getElementsByClassName('ControlBar')[0]
        if (controls) {
          controls.addEventListener('mouseenter', onMouseOverControls)
          controls.addEventListener('mouseleave', onMouseOutControls)
        }
      }
    }
    return () => {
      log('controls timeout cleared: usePlayerControls cleanup')
      clearTimeout(controlsTimeout)
      if (videoElement) {
        videoElement.removeEventListener('play', onPlaying)
        videoElement.removeEventListener('volumechange', onVolumeChange)
        if (playButton) {
          playButton?.removeEventListener('touchstart', togglePlayPause)
          playButton?.removeEventListener('click', togglePlayPause)
        }
        if (bigPlayButton) {
          bigPlayButton?.removeEventListener('touchstart', togglePlayPause)
          bigPlayButton?.removeEventListener('click', togglePlayPause)
        }
        if (videoWrapper) {
          if (isMobile) {
            videoElement.removeEventListener('touchstart', onTouch)
            videoElement.removeEventListener('click', onTouch)
            if (adContainer) {
              adContainer.removeEventListener('touchstart', onTouch)
              adContainer.removeEventListener('click', onTouch)
            }
          } else {
            videoWrapper.removeEventListener('mousemove', onMouseMove)
            videoWrapper.removeEventListener('mouseleave', onMouseOut)
            if (adContainer) {
              adContainer.removeEventListener('mousemove', onMouseMove)
              adContainer.removeEventListener('mouseleave', onMouseOut)
            }
          }
        }
        if (controlsWrapper) {
          const controls =
            controlsWrapper.getElementsByClassName('ControlBar')[0]
          if (controls) {
            controlsWrapper.removeEventListener(
              'mouseenter',
              onMouseOverControls
            )
            controlsWrapper.removeEventListener(
              'mouseleave',
              onMouseOutControls
            )
          }
        }
      }
    }
  }, [
    videoElement,
    adContainer,
    adStatusCode,
    adsDone,
    firstInteractionDone,
    playing,
    progressBuffer,
    chromecastConnected
  ])
