/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { getParentClubs } from '../../../api/channel/getParentClubs'
import { useDataApi } from '../../../api/useDataApi'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import ContentSlider from '../../sliders/ContentSlider'
import { RelatedChannelItem } from './RelatedChannelItem/RelatedChannelItem'
import styles from './RelatedChannel.module.scss'
import { RelatedChannelType } from './RelatedChannel.interface'
import { doDetermineRelatedLeagues } from './doDetermineRelatedLeagues'
import { Channel } from '../../../api/channel/getChannel'

dayjs.extend(relativeTime)

export const RelatedChannel: FC = () => {
  const channel = useSelector(channelSelector)
  const channelData: Channel = channel?.data
  const relatedLeagues = doDetermineRelatedLeagues(channelData)

  const slug = channel?.data?.subdomain

  const parameters = useMemo(() => ({ slug: slug }), [slug])
  const {
    isError,
    isLoading,
    data: parentClubsResponse
  } = useDataApi({
    apiCall: useCallback(getParentClubs, []),
    parameters
  })

  const hasRelatedChannels =
    relatedLeagues?.length || parentClubsResponse?.data?.length

  return hasRelatedChannels ? (
    <div className={styles.ContentSliderWrapper}>
      <ContentSlider leftPadding>
        <div data-testid="related-channel" className={styles.Content}>
          {!isError &&
            !isLoading &&
            !!parentClubsResponse?.data?.length &&
            parentClubsResponse.data.map(
              (club: RelatedChannelType, index: number) => {
                const { name, logo_url, subdomain } = club
                return (
                  <RelatedChannelItem
                    channelName={name}
                    logoUrl={logo_url}
                    subdomain={subdomain}
                    channelType={ChannelTypes.club}
                    key={`${index}__${subdomain}`}
                  />
                )
              }
            )}
          {!!relatedLeagues?.length &&
            relatedLeagues
              .filter((league: RelatedChannelType) => {
                const isCurrent =
                  dayjs().isBefore(dayjs(league.end_at * 1000)) &&
                  dayjs().isAfter(dayjs(league.start_at * 1000))
                return isCurrent
              })
              .map((league: RelatedChannelType, index: number) => {
                const { name, logo_url, subdomain } = league
                return (
                  <RelatedChannelItem
                    channelName={name}
                    logoUrl={logo_url}
                    subdomain={subdomain}
                    isCurrent
                    channelType={ChannelTypes.league}
                    key={`${index}__${subdomain}`}
                  />
                )
              })}
        </div>
      </ContentSlider>
    </div>
  ) : null
}
