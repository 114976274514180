import React from 'react'

export const GenericIcon = () => (
  <svg
    width="48"
    height="24"
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2237_159)">
      <rect width="48" height="24" rx="2" fill="white" />
      <path d="M-1 8L50 8" stroke="#2F3135" strokeWidth="6" />
      <path
        d="M3 16H27"
        stroke="#C9CBCF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3 19H10"
        stroke="#C9CBCF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14 19H21"
        stroke="#C9CBCF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="39.5" y="15.5" width="6" height="5" rx="0.5" fill="#C9CBCF" />
      <rect x="39.5" y="15.5" width="6" height="5" rx="0.5" stroke="#C9CBCF" />
    </g>
    <defs>
      <clipPath id="clip0_2237_159">
        <rect width="48" height="24" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
