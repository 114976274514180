import React from 'react'
import { FC, useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  wrapperId: string
  children?: React.ReactNode
}
const ReactPortal: FC<Props> = ({ children, wrapperId }) => {
  const [wrapperElement, setWrapperElement] = useState<Element | null>(null)

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId)
    let systemCreated = false

    if (!element) {
      systemCreated = true
      element = createWrapperAndAppendToBody(wrapperId)
    }
    if (element) {
      setWrapperElement(element)
    }

    return () => {
      if (element && systemCreated && element.parentNode) {
        element.parentNode.removeChild(element)
        document.body.style.overflow = 'initial'
      }
    }
  }, [wrapperId])

  if (wrapperElement === null) return null

  return createPortal(children, document.getElementById(wrapperId)!)
}

export default ReactPortal

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)
  document.body.style.overflow = 'hidden'
  document.body.appendChild(wrapperElement)
  return wrapperElement
}
