import { formatCurrency } from '../helpers/formatHelper'
import { PreferredPrice } from '../interfaces/PreferredPrice'
import { Price } from '../interfaces/Price.interface'
import usePreferredCurrencyPriorityList from './PreferredCurrencyPriorityList'

export default function usePriceFromPreference(price: Price): PreferredPrice {
  const preferredCurrencyPriorityList: Array<string> =
    usePreferredCurrencyPriorityList()
  // Add last default fallback as default values here
  let preferredPrice: PreferredPrice | null = null

  // Should ideally not be hardcoded like this.
  const supportedCurrenciesPriorityList = ['EUR', 'SEK', 'USD', 'NOK']

  if (price) {
    preferredCurrencyPriorityList.every((currency: string) => {
      if (
        supportedCurrenciesPriorityList.includes(currency) &&
        (price as any)[currency]
      ) {
        preferredPrice = {
          currency: currency,
          amount: (price as any)[currency],
          displayPrice: formatCurrency((price as any)[currency], currency)
        }
        return false
      }
      return true
    })

    if (!preferredPrice) {
      // #TODO: Change fallbackpriority depending on which country the user comes from/is in.
      let found: boolean = false
      supportedCurrenciesPriorityList.forEach((currency: string) => {
        if (!found && (price as any)[currency]) {
          preferredPrice = {
            currency: currency,
            amount: (price as any)[currency],
            displayPrice: formatCurrency((price as any)[currency], currency)
          }
          found = true
        }
      })
    }
  }

  if (!preferredPrice) {
    preferredPrice = {
      amount: price?.SEK || 0,
      currency: 'SEK',
      displayPrice: formatCurrency(price?.SEK || 0, 'SEK')
    }
  }
  return preferredPrice
}
