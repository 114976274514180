import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './DropdownItem.module.scss'

interface Props {
  label: string
  id: string
  selectedId: string
  onItemSelected: (itemLabel: string, itemId: string) => void
  icon?: any
  disabled?: boolean
}
export const DropdownItem: FC<Props> = ({
  label,
  id,
  selectedId,
  onItemSelected,
  icon: Icon,
  disabled = false
}) => {
  const isSelected = id === selectedId
  const handleItemSelected = () =>
    !disabled ? onItemSelected(label, id) : () => null

  return (
    <div
      role="button"
      className={classNames(styles.DropdownItem, {
        [styles.Selected]: isSelected,
        [styles.Disabled]: disabled
      })}
      onClick={handleItemSelected}
      onKeyPress={handleItemSelected}
      tabIndex={0}
    >
      <div>{label}</div>
      {Icon && <Icon />}
    </div>
  )
}
DropdownItem.defaultProps = {
  icon: undefined,
  disabled: false
}
