import { Channel } from '../../../api/channel/getChannel'
import { ChannelTypes } from '../channelType'

export const getDisplayData = (
  upcomingStreamsData: { data: any[]; isLoading: boolean; isError: unknown },
  isReadyToRender: boolean,
  channelDetails: Channel
) => {
  const showUpcomingStreamsSection =
    upcomingStreamsData.data &&
    upcomingStreamsData.data.length > 0 &&
    isReadyToRender

  const showLatestVideosSection =
    (channelDetails?.meta_data?.content?.has_replays ||
      channelDetails?.meta_data?.content?.has_videos) &&
    isReadyToRender &&
    showUpcomingStreamsSection !== null

  const showHighlightsSection =
    channelDetails?.meta_data?.content?.has_highlights && isReadyToRender

  const showNewsSection = channelDetails?.meta_data?.content?.has_articles

  const showStandings = channelDetails?.type === ChannelTypes.league
  const showRelatedChannel = channelDetails?.type === ChannelTypes.team
  return {
    showUpcomingStreamsSection,
    showLatestVideosSection,
    showHighlightsSection,
    showNewsSection,
    showStandings,
    showRelatedChannel
  }
}
