import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import { t } from '@lingui/macro'
import { Honeybadger } from '@honeybadger-io/react'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import process from 'process'
import { useLocation, useHistory } from 'react-router-dom'
import { defaultLocale } from '../../../translations/i18n'
import paymentActions from '../../../redux-store/payments/payments.actions'
import ApiEndpoint, {
  ApiError,
  ApiResponse
} from '../../../network/ApiEndpoint'
import { PaymentContainerCloseButton } from '../PaymentContainerCloseButton/PaymentContainerCloseButton'
import { PaymentContainerRetryButton } from '../PaymentContainerRetryButton/PaymentContainerRetryButton'
import PAYMENTS from '../../../redux-store/payments/payments.constants'
import AlertMessage from '../../dialogs/AlertMessage'
import styles from './PaymentContainer.module.scss'
import './adyenStyling.scss'
import { CustomText } from './CustomText/CustomText.interface'
import getCustomText from './CustomText/CustomText'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import Modal from 'components/Modals/Modal/Modal'
import { TextSection } from './TextSection/TextSection'

export const PaymentContainer = () => {
  const paymentsOpen = useSelector((state: any) => state.payments.paymentsOpen)
  const currentPayment = useSelector(
    (state: any) => state.payments.currentPayment
  )
  const channel = useSelector(channelSelector)
  const paymentContainer = useRef<HTMLElement | null>(
    document.getElementById('payment-container')
  )
  const config: any = useRef(undefined)
  const checkout: any = useRef(undefined)
  const aDropin: any = useRef(undefined)
  const dispatch = useDispatch()
  const language =
    sessionStorage.getItem('language') || navigator.language || defaultLocale
  const locale = language.substr(0, 2)

  const history = useHistory()
  const location = useLocation()
  const redirectParams: any = {
    result:
      new URLSearchParams(location.search).get('redirectResult') || undefined,
    media_object_id:
      new URLSearchParams(location.search).get('redirectMediaObjectId') ||
      undefined,
    subscription_id:
      new URLSearchParams(location.search).get('redirectSubscriptionId') ||
      undefined
  }

  const [errorMessage, setErrorMessage] = useState(<span />)

  // TODO: Change to a helper function if supporting currencys with 0 or 3 decimals.
  const multiplicatorForCurrency = 100

  const removeRedirectParams = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('redirectResult')
    queryParams.delete('redirectMediaObjectId')
    queryParams.delete('redirectSubscriptionId')
    history.replace({
      search: queryParams.toString()
    })
  }, [history, location.search])

  const closePaymentContainer = () => {
    setErrorMessage(<span />)
    if (aDropin.current) {
      aDropin.current.unmount()
    }
    dispatch(paymentActions.closePayment())
  }

  const retryPaymentContainer = () => {
    setErrorMessage(<span />)
    if (aDropin.current) {
      aDropin.current.setStatus('ready')
      dispatch(paymentActions.retryPayment())
    }
  }

  const showFinalResult = useCallback(
    (response: any, dropin: any) => {
      if (response && response.data) {
        let object_id: any
        switch (response.data.result_code) {
          case 'Authorised':
            switch (currentPayment.type) {
              case PAYMENTS.TYPE_PPV:
                object_id = currentPayment.mediaObjectId
                dropin.setStatus('success', { message: t`Payment successful` })
                dispatch(paymentActions.paymentSuccess(object_id))
                break
              case PAYMENTS.TYPE_SUBSCRIPTION:
                object_id = currentPayment.subscription?.id
                dropin.setStatus('success', { message: t`Payment successful` })
                dispatch(paymentActions.paymentSuccess(object_id))
                break
              case PAYMENTS.TYPE_ADD_CARD:
              default:
                object_id = response.data.ref_id
                dispatch(paymentActions.paymentSuccess(object_id))
                dropin.setStatus('success', { message: t`Added card` })
            }

            if (response.data.type === 'gift_card') {
              // Show gift card result component efter payment success animation is ready
              setTimeout(() => {
                aDropin.current.unmount()
                dispatch(paymentActions.hasGiftCard(response.data.ref_id))
                dispatch(paymentActions.closePayment())
              }, 1200)
            }
            break
          case 'Refused':
            setErrorMessage(
              <AlertMessage
                visible
                level="error"
                title={t`Error in payment`}
                text={t`The payment failed. Try again`}
              />
            )
            dropin.setStatus('error', {
              message: t`The payment failed. Try again`
            })
            dispatch(paymentActions.paymentFailed())
            break
          case 'Pending':
          case 'PresentToShopper':
          case 'Received':
            break
          case 'RedirectShopper':
            dropin.handleAction(response.data.action)
            break
          case '':
          case 'Error':
            setErrorMessage(
              <AlertMessage
                visible
                level="error"
                title={t`Error`}
                text={t`The server returned ${response.data.result_code}`}
              />
            )
            dropin.setStatus('error', {
              message: t`The server returned ${response.data.result_code}`
            })
            break
          default:
            setErrorMessage(
              <AlertMessage
                visible
                level="warning"
                title={t`Something went wrong`}
                text={t`Try again`}
              />
            )
            dropin.setStatus('error', {
              message: t`Something went wrong. Try again`
            })
            break
        }
      }
    },
    [currentPayment, dispatch]
  )

  const showPayment = useCallback(() => {
    if (currentPayment.type !== PAYMENTS.TYPE_REDIRECT_RESULT) {
      // commented out for now, we need to add back later
      // when Tag Manager specs are better defined
      /*
      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          currency: currentPayment.currency,
          items: currentPayment.type,
          value: currentPayment.value
        }
      })
      */
      /* returns...
      {
            "type": "TYPE_PPV",
            "value": 29,
            "currency": "SEK",
            "mediaObjectId": "kxxb1pxd",
            "subscription": {
                "price": {
                    "SEK": 29
                }
            }
      }
      */
    }
    if (paymentContainer && paymentContainer.current) {
      paymentContainer.current.classList.remove('hidden')
    }
    const betterLocale = `${locale}-${config.current?.country_code || 'US'}`
    let path: string
    let return_path: string
    let translations: CustomText | undefined
    switch (currentPayment.type) {
      case PAYMENTS.TYPE_PPV:
        path = 'payments/ppv'
        return_path = ''
        return_path = `${window.location.pathname}${
          currentPayment?.mediaObjectId
            ? `?redirectMediaObjectId=${currentPayment.mediaObjectId}`
            : ''
        }`
        break
      case PAYMENTS.TYPE_SUBSCRIPTION:
        path = 'payments/subscription'
        return_path = `${window.location.pathname}${
          currentPayment?.subscription?.id
            ? `?redirectSubscriptionId=${currentPayment.subscription.id}`
            : ''
        }`
        break
      case PAYMENTS.TYPE_ADD_CARD:
        path = 'users/credit_cards/add_card'
        translations = getCustomText(PAYMENTS.TYPE_ADD_CARD, betterLocale)
        return_path = `${window.location.pathname}${'?add_card=true'}`
        break
      default:
    }

    const utm_source = sessionStorage.getItem('utm_source')
    const configuration = {
      paymentMethodsResponse: config.current?.method,
      clientKey: config.current?.client,
      locale: betterLocale,
      translations: translations,
      amount: {
        value: Number(
          (currentPayment.value * multiplicatorForCurrency).toPrecision(10)
        ),
        currency: currentPayment.currency
      },
      showStoredPaymentMethods: currentPayment.type !== PAYMENTS.TYPE_ADD_CARD, // TODO: This does not seem to work for some reason.
      showRemovePaymentMethodButton: true,
      environment: process.env.REACT_APP_ADYEN_CHECKOUT_ENVIRONMENT,
      onSubmit: (state: any, dropin: any) => {
        // Global configuration for onSubmit
        // Your function calling your server to make the `/payments` request
        ApiEndpoint.call({
          path,
          method: ApiEndpoint.METHODS.POST,
          params: {
            origin: window.location.origin,
            media_object_id:
              currentPayment.type === PAYMENTS.TYPE_PPV
                ? currentPayment.mediaObjectId
                : undefined,
            subscription_id:
              currentPayment.type === PAYMENTS.TYPE_SUBSCRIPTION
                ? currentPayment.subscription.id
                : undefined,
            data: state.data,
            value: currentPayment.value,
            currency: currentPayment.currency,
            return_path: return_path,
            gateway: 'adyen',
            origin_company: window.location.pathname.split('/')[1],
            utm_source
          },
          authenticatable: true
        })
          .then((response: ApiResponse) => {
            const result = response.data || response
            if (response.data?.result_code === 'Authorised') {
              const channelData = channel?.data
              const dataLayer = {
                dataLayer: {
                  event: 'purchase',
                  purchase_type: currentPayment.type, // All Access, Monthly Subscription, PPV
                  channel_name: channelData?.name,
                  sport_category: channelData?.category?.slug,
                  powered_by: channelData?.isRootChannel
                    ? channelData?.subdomain
                    : channelData?.parentChannelSubdomain,
                  item_name: currentPayment.mediaObjectTitle,
                  value: currentPayment.value,
                  currency: currentPayment.currency,
                  transaction_id: currentPayment.mediaObjectId
                }
              }
              TagManager.dataLayer(dataLayer)
            }

            if (result.action) {
              dropin.handleAction(result.action)
            } else {
              showFinalResult(response, dropin)
            }
          })
          .catch((error: ApiError) => {
            console.warn('Error in payments call:', error)
            // TODO: Change check to error.code when error.code is available
            if (error.message === 'Already purchased') {
              dispatch(
                paymentActions.alreadyPurchased(
                  currentPayment.type === PAYMENTS.TYPE_PPV
                    ? currentPayment.mediaObjectId
                    : currentPayment.subscription_id
                )
              )
              dropin.setStatus('success', {
                message: t`You have already purchased this item`
              })
            } else {
              console.error('Payment error in onSubmit', error)
              dropin.setStatus('error', {
                message: t`Something went wrong. Try again`
              })
              dispatch(paymentActions.paymentFailed())
            }
          })
      },
      onAdditionalDetails: (state: any, dropin: any) => {
        const params: any = {
          data: state.data,
          return_path: '/'
        }
        switch (currentPayment.type) {
          case PAYMENTS.TYPE_PPV:
            params.media_object_id = currentPayment.mediaObjectId
            break
          case PAYMENTS.TYPE_SUBSCRIPTION:
            params.subscription_id = currentPayment.subscription?.id
            break
          case PAYMENTS.TYPE_REDIRECT_RESULT:
            params.subscription_id = redirectParams.subscription_id || undefined
            params.media_object_id = redirectParams.media_object_id || undefined
            break
          default:
            break
        }
        ApiEndpoint.call({
          path: 'payments/details',
          method: ApiEndpoint.METHODS.POST,
          params,
          authenticatable: true
        })
          .then((response: ApiResponse) => {
            if (response.data?.action) {
              // Drop-in handles the action object from the /payments response
              dropin.handleAction(response.data?.action)
            } else {
              // Your function to show the final result to the shopper
              showFinalResult(response, dropin)
            }
          })
          .catch((error: ApiError) => {
            // TODO: Change check to error.code when error.code is available
            if (error.message === 'Already purchased') {
              dispatch(
                paymentActions.alreadyPurchased(
                  currentPayment.type === PAYMENTS.TYPE_PPV
                    ? currentPayment.mediaObjectId
                    : currentPayment.subscription_id
                )
              )
              if (currentPayment.type === PAYMENTS.TYPE_REDIRECT_RESULT) {
                dropin.setStatus('success')
              } else {
                dropin.setStatus('success', {
                  message: t`You have already purchased this item`
                })
              }
            } else {
              console.error('Payment error in onAdditionalDetails', error)
              dropin.setStatus('error', {
                message: t`Something went wrong. Try again`
              })
            }
          })
          .then(() => {
            removeRedirectParams()
          })
      },
      handleAction: (action: any) => {
        console.info(
          'PaymentContainer unhandled handleAction from Adyen:',
          action
        )
        Honeybadger.notify(
          `Unhandled action from Adyen in PaymentContainer: ${JSON.stringify(
            action
          )}`
        )

        // Documentation is at https://docs.adyen.com/online-payments/drop-in-web#step-4-additional-front-end
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: currentPayment.type !== PAYMENTS.TYPE_ADD_CARD
        }
      },
      onSelect: () => {},
      onChange: () => {},
      onReady: () => {
        // Hide the div
        dispatch(paymentActions.closePayment())
      },
      onError: (error: any) => {
        if (error.error !== '') {
          dispatch(paymentActions.paymentFailed())
        } else {
          dispatch(paymentActions.paymentOngoing())
        }
      }
    }
    checkout.current = new AdyenCheckout(configuration)

    // Mount the AdyenCheckout
    aDropin.current = checkout.current
      .create('dropin')
      .mount('#dropin-container')

    if (redirectParams.result) {
      checkout.current.options.onAdditionalDetails(
        { data: { details: { redirectResult: redirectParams.result } } },
        aDropin.current
      )
    }
  }, [
    locale,
    redirectParams.result,
    redirectParams.media_object_id,
    redirectParams.subscription_id,
    dispatch,
    currentPayment,
    removeRedirectParams,
    showFinalResult,
    channel
  ])

  useEffect(() => {
    if (!paymentContainer.current) {
      paymentContainer.current = document.getElementById('payment-container')
    }
    if (paymentsOpen) {
      if (
        currentPayment.value !== undefined ||
        currentPayment.type === PAYMENTS.TYPE_ADD_CARD
      ) {
        let params: any = {}
        if (currentPayment.type !== PAYMENTS.TYPE_ADD_CARD) {
          params = {
            currency: currentPayment.currency,
            value: currentPayment.value
          }
        }
        if (currentPayment.type === PAYMENTS.TYPE_SUBSCRIPTION) {
          params.subscription_id = currentPayment.subscription?.id
        } else if (currentPayment.type === PAYMENTS.TYPE_PPV) {
          params.media_object_id = currentPayment.mediaObjectId
        } else if (currentPayment.type === PAYMENTS.TYPE_ADD_CARD) {
          params.add_card = true
        }

        ApiEndpoint.call({
          path: 'payments/methods',
          method: ApiEndpoint.METHODS.GET,
          params,
          authenticatable: true
        })
          .then((response: ApiResponse) => {
            // Errors from Adyen are not returned as errors
            if (response?.data?.method?.errorCode !== undefined) {
              setErrorMessage(
                <AlertMessage
                  visible
                  level="error"
                  title={t`Error`}
                  text={t`We could not load the payment methods. Try again`}
                />
              )
              dispatch(paymentActions.paymentFailed())
              console.warn(response?.data?.method)
            }
            config.current = response?.data
            try {
              config.current.method = JSON.parse(response?.data.method)
            } catch {
              config.current.method = response?.data.method
            }
            showPayment()
          })
          .catch((error: ApiError) => {
            // TODO: Change check to error.code when error.code is available
            if (error.message === 'Already purchased') {
              // this is the code that triggers aggain
              setErrorMessage(
                <AlertMessage
                  visible
                  level="info"
                  title={t`Already purchased`}
                  text={t`You already have access to this`}
                />
              )
              dispatch(
                paymentActions.alreadyPurchased(
                  currentPayment.type === PAYMENTS.TYPE_PPV
                    ? currentPayment.mediaObjectId
                    : currentPayment.subscription_id
                )
              )
            } else {
              setErrorMessage(
                <AlertMessage
                  visible
                  level="error"
                  title={t`Error`}
                  text={t`We could not load the payment methods. Try again`}
                />
              )
              dispatch(paymentActions.paymentFailed())
              console.warn(error)
            }
          })
      } else if (currentPayment.type === PAYMENTS.TYPE_REDIRECT_RESULT) {
        setTimeout(showPayment, 1200)
      }
    }
  }, [paymentsOpen, currentPayment, dispatch, showPayment])

  useEffect(() => {
    if (redirectParams.result && !paymentsOpen) {
      dispatch(
        paymentActions.openPayment({
          type: PAYMENTS.TYPE_REDIRECT_RESULT,
          redirectResult: redirectParams.result
        })
      )
    }
  }, [redirectParams.result, paymentsOpen, dispatch])

  if (paymentsOpen) {
    // Make sure that the container is visible
    try {
      window.scrollTo(0, 0)
    } catch (e) {
      // Ignore for now
    }
  }

  /* if (!paymentsOpen) {
    return <div id="payment-container" className="popup-container hidden" />
  } */

  return (
    <Modal
      isOpen={paymentsOpen}
      handleClose={() => closePaymentContainer()}
      customStyle={styles.ModalContainer}
    >
      <div id="payment-container" className={styles.PaymentContent}>
        <TextSection currentPayment={currentPayment} />
        {errorMessage}
        <div
          data-testid="payment-container"
          id="dropin-container"
          className={styles.Payments}
        />
        <div className={styles.ButtonContainer}>
          <PaymentContainerCloseButton
            closePaymentContainer={closePaymentContainer}
          />
          <PaymentContainerRetryButton
            retryPaymentContainer={retryPaymentContainer}
          />
        </div>
      </div>
    </Modal>
  )
}
