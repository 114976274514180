import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { DropdownList } from 'components/Forms/DropdownListField/DropdownList'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { Locale } from 'components/page/footer/themedFooter/ThemedFooter'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { languageSelector } from 'redux-store/language/language.selectors'
import { locales } from 'translations/i18n'
import styles from './LanguageForm.module.scss'
import languageActions from 'redux-store/language/language.actions'

interface Props {
  onSubmitSuccess: () => void
  onCancel: () => void
}
interface LanguageValues {
  language: string
}
export const LanguageForm: FC<Props> = ({ onSubmitSuccess, onCancel }) => {
  const currentLanguage = useSelector(languageSelector)
  const initialValues: LanguageValues = {
    language: currentLanguage
  }
  const languageList = locales.map((locale: Locale) => ({
    label: locale.name,
    id: locale.abbr
  }))
  const dispatch = useDispatch()

  const onSubmit = async (
    values: LanguageValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const { language } = values
    if (values.language) {
      dispatch(languageActions.setLanguage(values.language))
      onSubmitSuccess()
    } else {
      formikHelpers.setFieldError(
        language,
        t`Something went wrong when you tried to change the language, please try again`
      )
    }
    formikHelpers.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.FormContainer}>
              <Field
                component={DropdownList}
                dropdownListData={languageList}
                value={props.values.language}
                id="language"
                name="language"
                placeholder={t`please select a team`}
                isValid={!props.errors.language}
                validationMessage={props.errors.language}
                type="dark"
                onChange={(id: string) => props.setFieldValue('language', id)}
              />
              <FormActionButtons
                cancelButtonLabel={t`Cancel`}
                submitButtonLabel={t`Save`}
                onCancel={onCancel}
                formik={props}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
