import { t } from '@lingui/macro'
import React, { FC } from 'react'
import TextIconButton, {
  ButtonType
} from '../../../../components/buttons/TextIconButton'
import { AddIcon } from '../../GameScheduleListContainer/AddIcon'
import { LockIcon } from './LockIcon'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import styles from './AddButtonForLeague.module.scss'

interface Props {
  showLockIcon: boolean
  onAddCompetition: () => void
}

export const AddButtonForLeague: FC<Props> = ({
  showLockIcon,
  onAddCompetition
}) => {
  return (
    <>
      {showLockIcon ? (
        <div className={styles.LockIconContainer}>
          <div className={styles.LockIcon}>
            <LockIcon />
          </div>
          <div className={styles.TooltipWrapper}>
            <Tooltip
              text={t`This competition is already claimed by another channel.`}
              pointerPosition="Right"
            />
          </div>
        </div>
      ) : (
        <TextIconButton
          type={ButtonType.Ghost}
          buttonSize="Medium"
          label={t`Add`}
          id="add-competition"
          onClick={() => onAddCompetition()}
        >
          <AddIcon />
        </TextIconButton>
      )}
    </>
  )
}
