import { Team } from 'interfaces'
import { LineupItemProps } from './LineupItem/LineupItem'

export const mapLineupData = (
  playerList: LineupItemProps[] | [],
  coachList: LineupItemProps[] | [],
  teamData: Team | null
) => ({
  teamName: teamData ? teamData.name : '',
  teamLogo: teamData ? teamData.badge_url : '',
  playerList: playerList,
  coachList: coachList
})
