import React, { useCallback } from 'react'

import FrontPageHero from './FrontPageHero'
import backgroundImg from '../../assets/images/frontpageBackground.jpg'
import { getFeaturedContent } from '../../api/featuredContent/getFeaturedContent'
import { useDataApi } from '../../api/useDataApi'
import { getLatestVideoContent } from '../../api/latestVideoContent/getLatestVideoContent'
import VideoContentSection from './VideoContentSection'
import styles from './index.module.scss'
import { getUpcomingLiveContent } from '../../api/video/getUpcomingLiveContent/getUpcomingLiveContent'
import { useStateDataApi } from '../../api/useStateDataApi'
import { getSports } from '../../api/sports/getSports'
import actions from '../../redux-store/sports/sports.actions'
import SportsSection from './SportsSection/SportsSection'
import ContentContainer from '../../components/ContentContainer'

const StartPage = () => {
  const wrappedGetFeaturedContent = useCallback(getFeaturedContent, [])
  const wrappedGetLatestVideoContent = useCallback(getLatestVideoContent, [])
  const wrappedGetUpcomingLiveContent = useCallback(getUpcomingLiveContent, [])
  const wrappedGetSports = useCallback(getSports, [])

  const { data, isLoading, isError } = useDataApi({
    apiCall: wrappedGetFeaturedContent
  })
  const latestVideoContent = useDataApi({
    apiCall: wrappedGetLatestVideoContent
  })
  const upcomingLiveContent = useDataApi({
    apiCall: wrappedGetUpcomingLiveContent
  })

  const sportActions = {
    error: actions.fetchSportsError,
    start: actions.fetchSportsStart,
    success: actions.fetchSportsSuccess
  }

  useStateDataApi({ actions: sportActions, apiCall: wrappedGetSports })

  const isLoadingVideos =
    latestVideoContent.isLoading && upcomingLiveContent.isLoading

  const isErrorVideos =
    latestVideoContent.isError && upcomingLiveContent.isError

  return (
    <div className={styles.StartPage} data-testid="start-page-container">
      <FrontPageHero
        backgroundImg={backgroundImg}
        isLoading={isLoading}
        isError={isError}
        featuredSectionData={data}
      />
      <VideoContentSection
        latestVideoContent={latestVideoContent.data}
        upcomingLivestreams={upcomingLiveContent.data}
        isError={isErrorVideos}
        isLoading={isLoadingVideos}
      />
      <ContentContainer
        hasLightBackground
        hasLeftRightPadding
        hasTopBottomPadding
      >
        <div />
      </ContentContainer>
      <SportsSection />
    </div>
  )
}
export default StartPage
