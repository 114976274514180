import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { channelThemeSelector } from '../../../../../redux-store/channelTheme/channelTheme.selectors'
import { FontFileForm } from './FontFileForm'

import styles from './CustomFonts.module.scss'

interface Props {
  channelSlug: string
}

export const CustomFonts: FC<Props> = ({ channelSlug }) => {
  const channelTheme = useSelector(channelThemeSelector)
  const themeFonts = {
    channelSlug,
    headingFont: channelTheme.channelHeadingFontFamily || '',
    submenuFont: channelTheme.channelSubmenuFontFamily || '',
    textTransformation: channelTheme.channelTextTransformation || 'none'
  }
  return (
    <div className={styles.CustomFontsContainer}>
      <FontFileForm
        channelSlug={channelSlug}
        onSubmitSuccess={() => console.log('success!')}
        themeFonts={themeFonts}
      />
    </div>
  )
}
