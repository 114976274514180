type ChannelDataStatus = {
  channelDataIsReady: boolean
  channelShouldChange: boolean
  channelDataNotFound: boolean
  channelDataExpected: boolean
}

export const useChannelDataStatus = (
  channel: any,
  company: string | undefined,
  shouldChangeCallback?: Function | undefined
): ChannelDataStatus => {
  const channelShouldChange: boolean = checkForDifference(channel, company)
  const channelDataIsReady: boolean = checkIfChannelDataIsReady(
    channel,
    channelShouldChange
  )
  const channelDataNotFound: boolean = checkIfChannelDataIsNotFound(
    channel,
    channelShouldChange
  )
  if (channelShouldChange && shouldChangeCallback) shouldChangeCallback()

  const channelDataExpected = company !== undefined

  return {
    channelDataIsReady: channelDataIsReady,
    channelShouldChange: channelShouldChange,
    channelDataNotFound: channelDataNotFound,
    channelDataExpected: channelDataExpected
  }
}

const checkForDifference = (
  channel: any,
  company: string | undefined
): boolean => {
  return channel.data?.subdomain !== company
}

const checkIfChannelDataIsReady = (
  channel: any,
  shouldChange: boolean
): boolean => {
  return (
    !shouldChange &&
    !channel?.isLoading &&
    channel?.data !== undefined &&
    !channel?.isError
  )
}

const checkIfChannelDataIsNotFound = (
  channel: any,
  shouldChange: boolean
): boolean => {
  return (
    (!channel?.isLoading && channel?.data === undefined && !shouldChange) ||
    channel?.isError
  )
}
