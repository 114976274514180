import React, { FC, useCallback, useMemo } from 'react'

import { getCustomPage } from 'api/channel/customPages/getCustomPage'
import { Loader } from 'components/Loader/Loader'
import { useSelector } from 'react-redux'
import {
  channelCustomPageSelector,
  channelSelector
} from 'redux-store/channel/channel.selectors'
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import styles from './CustomPage.module.scss'
import { useDataApi } from 'api/useDataApi'
import { getBreadcrumbListForCustomPage } from './getBreadcrumbListForCustomPage'
import { CustomPageContainer } from './CustomPageContainer'
import { ArticleMeta } from 'meta/ArticleMeta'

export const CustomPageSection: FC = () => {
  const { submenuoption, custompage } = useParams<{
    custompage?: string
    submenuoption?: string
  }>()
  const channel = useSelector(channelSelector)
  const channelSlug = channel?.data?.subdomain
  const pageSlug = custompage
  const customPageList = useSelector(channelCustomPageSelector)

  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getCustomPage, []),
    parameters: useMemo(
      () => ({
        channelSlug,
        pageSlug: pageSlug
      }),
      [pageSlug, channelSlug]
    )
  })

  if (isLoading) {
    return (
      <CustomPageContainer>
        <Loader />
      </CustomPageContainer>
    )
  }

  if (isError) {
    return (
      <CustomPageContainer>
        <Trans>
          Something went wrong trying to load the page, please try again.
        </Trans>
      </CustomPageContainer>
    )
  }
  if (data) {
    const breadcrumbList = getBreadcrumbListForCustomPage(
      customPageList,
      data,
      submenuoption,
      channelSlug
    )

    return (
      <>
        <ArticleMeta title={data.title} />
        <CustomPageContainer>
          {breadcrumbList.length > 1 && (
            <div
              className={styles.BreadcrumbContainer}
              data-testid="custom-page-breadcrumb"
            >
              <Breadcrumb pathList={breadcrumbList} />
            </div>
          )}
          {data.content ? (
            <div
              data-testid="custom-page-content"
              className={styles.Content}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          ) : (
            <div data-testid="custom-page-empty-message">
              <Trans>This page does not have any content yet.</Trans>
            </div>
          )}
        </CustomPageContainer>
      </>
    )
  }
  return null
}
