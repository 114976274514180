import { InfoBox } from 'features/customContent/NewContentModal/InfoBoxForm/InfoBoxForm'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface ChannelInfoBox extends InfoBox {
  channelSlug: string
}
export const postCustomBanner = async (
  params: ChannelInfoBox
): Promise<any> => {
  try {
    const formData = new FormData()
    formData.append('company', params.channelSlug)
    formData.append('publish', 'true')
    formData.append('title', params.title)
    formData.append('content_type', 'application/delta+json')
    formData.append('publish', 'true')
    if (params.text) {
      formData.append('content', JSON.stringify(params.text))
    }
    formData.append('type', 'text_banner')
    if (params.imageFile) {
      formData.append('image', params.imageFile)
    }
    const button = params.hasButton ? 'true' : 'false'
    formData.append('button', button)
    if (params.hasButton) {
      // @ts-ignore
      formData.append('button_text', params.textField)
      // @ts-ignore
      formData.append('button_link', params.linkField)
      // @ts-ignore
      formData.append('button_link_target', params.target)
    }
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'banners/frontpage',
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
