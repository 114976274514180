import React, { FC } from 'react'
import styles from './UserProfileAvatar.module.scss'
import classNames from 'classnames'

export enum Theme {
  Light = 'Light',
  Dark = 'Dark',
  Red = 'Red'
}
interface Props {
  firstName: string
  lastName: string
  theme: Theme
}

export const UserProfileAvatar: FC<Props> = ({
  firstName,
  lastName,
  theme
}) => {
  const firstInitial = firstName ? firstName[0].toUpperCase() : ''
  const lastInitial = lastName ? lastName[0].toUpperCase() : ''
  const initials = `${firstInitial}${lastInitial}`
  return (
    <div className={classNames(styles.Avatar, styles[theme])}>{initials}</div>
  )
}
