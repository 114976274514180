import React, { FC } from 'react'
import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import { LoginCallbackView } from './LoginCallback'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const LoginCallbackModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={onClose}
      firstContainerContent={<LoginCallbackView />}
      firstContainerTheme={ModalTheme.Primary}
    />
  )
}
