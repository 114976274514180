import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { HomePage } from './HomePage/HomePage'
import { AccountDetails } from './MyAccountPage/AccountDetails/AccountDetails'
import { AllPurchases } from './MyPurchases/AllPurchases/AllPurchases'
import { MyCards } from './MyPurchases/MyCards/MyCards'
import { Following } from './MyAccountPage/Following/Following'
import { MyChannels } from './MyAccountPage/MyChannels/MyChannels'
import { Subscriptions } from './MyPurchases/Subscriptions/Subscriptions'
import { Password } from './SecurityPage/Password/Password'

export const UserAccountRoutes: FC = () => (
  <Switch>
    <Route exact path={['/user/profile/home', '/user/profile']}>
      <HomePage />
    </Route>
    <Route exact path="/user/profile/account/details">
      <AccountDetails />
    </Route>
    <Route exact path="/user/profile/account/channels">
      <MyChannels />
    </Route>
    <Route exact path="/user/profile/account/following">
      <Following />
    </Route>
    <Route exact path="/user/profile/purchases/subscriptions">
      <Subscriptions />
    </Route>
    <Route exact path="/user/profile/purchases/allpurchases">
      <AllPurchases />
    </Route>
    <Route exact path="/user/profile/purchases/mycards">
      <MyCards />
    </Route>
    <Route exact path="/user/profile/security/password">
      <Password />
    </Route>
  </Switch>
)
