import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { channelSelector } from '../../../redux-store/channel/channel.selectors'

import { getStandingsForLeague } from '../../../api/standings/getStandingsForLeague'
import { useDataApi } from '../../../api/useDataApi'

import { StandingsInterface } from './Standings.interface'
import { StandingsTable } from './StandingsTable'
import styles from './Standings.module.scss'
import { Loader } from '../../Loader/Loader'
import { findExternalReference } from './findExternalReference'
import { MobileStandingsTable } from './MobileStandingsTable'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

interface StandingsProps {
  channelSlug: string
  showExpandedToggle: boolean
  teamSlug?: string
}

export const Standings: FC<StandingsProps> = ({
  channelSlug,
  showExpandedToggle,
  teamSlug
}) => {
  const channel = useSelector(channelSelector)
  const breakpoint = useCurrentBreakpoint()
  const slug = channelSlug
  const name = channel?.data?.name
  const parent = channel?.data?.parent_channel_subdomain
  const externalReference = findExternalReference(
    channel?.data?.external_references
  )
  const apiCall = useCallback(getStandingsForLeague, [])

  const {
    isLoading,
    isError,
    data: tablesData
  } = useDataApi({
    apiCall,
    initialData: null,
    parameters: useMemo(
      () => ({
        slug,
        name,
        parent,
        externalReference
      }),
      [slug, name, parent, externalReference]
    )
  })

  const isMobile = breakpoint < Breakpoint.sm

  return isLoading && !isError ? (
    <Loader />
  ) : tablesData ? (
    <div className={styles.StandingsContainer}>
      {tablesData.map((standings: StandingsInterface, index: number) => (
        <div className={styles.TableWrapper} key={`Standings__${index}`}>
          {isMobile ? (
            <MobileStandingsTable
              standings={standings}
              showExpandedToggle={showExpandedToggle}
              teamSlug={teamSlug}
            />
          ) : (
            <StandingsTable
              standings={standings}
              showExpandedToggle={showExpandedToggle}
              teamSlug={teamSlug}
            />
          )}
        </div>
      ))}
    </div>
  ) : null
}
