import React, { FC, useEffect } from 'react'
import { t } from '@lingui/macro'
import { useLocation } from 'react-router-dom'

import { TabHeaders } from './TabHeaders'
import { TabContent } from './TabContent'
import { EventSchedule } from 'features/multistageTournaments/EventSchedule'
import {
  ChannelEvent,
  PAST_GAMES_TAB_ID
} from 'features/multistageTournaments/EventScheduleTabs'
import { EventStatus } from 'api/multistageTournament/mapChannelEventsResponse'

import styles from './TabListContainer.module.scss'

interface Props {
  tabListData: ChannelEvent[]
  onTabSelected: (itemSelected: string) => void
  selectedTabId: string
  channelSlug: string
  eventData: ChannelEvent
  pastEvents: ChannelEvent[]
  onPastEventsSelected: (id: string) => void
}

export const TabListContainer: FC<Props> = ({
  tabListData,
  onTabSelected,
  selectedTabId,
  channelSlug,
  eventData,
  pastEvents,
  onPastEventsSelected
}) => {
  const eventSlug = eventData.eventSlug
  const location = useLocation()

  const showPastGamesTab = pastEvents?.length > 0
  const isHistoricData = selectedTabId === PAST_GAMES_TAB_ID
  useEffect(() => {
    const windowLocation = window.location.pathname

    if (!windowLocation.includes(eventSlug) || isHistoricData) {
      const defaultEventUrl = `/${channelSlug}/games/event/${eventSlug}`
      const filterApplied =
        windowLocation.includes('/classes') ||
        windowLocation.includes('/locations')

      if (!filterApplied) {
        const replacedUrl = isHistoricData
          ? `${defaultEventUrl}/schedule`
          : defaultEventUrl

        window.history.replaceState({}, '', replacedUrl)
      }
    }
  }, [location, selectedTabId, eventData])

  const pastGamesTab = {
    channelSlug,
    status: EventStatus.Past,
    eventSlug: 'past-games',
    id: PAST_GAMES_TAB_ID,
    name: t`Past Events`
  }

  const tabList = showPastGamesTab
    ? [...tabListData, pastGamesTab]
    : tabListData

  return (
    <div className={styles.TabListContainer}>
      <TabHeaders
        tabListData={tabList}
        onTabSelected={onTabSelected}
        selectedTabId={selectedTabId}
      />
      <TabContent>
        <EventSchedule
          channelSlug={channelSlug}
          eventData={eventData}
          isHistoricData={isHistoricData}
          onPastEventsSelected={onPastEventsSelected}
          pastEvents={pastEvents || []}
        />
      </TabContent>
    </div>
  )
}
