import React from 'react'

export const GeneralIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1812_49689)">
      <rect
        x="0.617188"
        y="8.70117"
        width="2.5"
        height="6"
        rx="1"
        transform="rotate(-6.0136 0.617188 8.70117)"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="14.002"
        y="3.61328"
        width="3"
        height="13"
        rx="1"
        transform="rotate(-6.0136 14.002 3.61328)"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M19.3301 9.74609L22.8108 9.37942"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.125 6.75195L22.2599 5.19564"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.7539 12.7188L23.1443 13.5878"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.3301 5.24603C11.2015 7.1285 8.50675 8.08224 3.70492 9.38193"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M15.377 15.1913C11.9247 14.0021 9.09046 13.6308 4.12321 13.3603"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M6.16602 13.6465L8.54602 19.2219C8.71837 19.6257 9.1339 19.8698 9.57049 19.8238L9.97571 19.7811C10.6448 19.7106 11.0548 19.0128 10.7907 18.394L8.65226 13.3846"
        stroke="black"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1812_49689">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
