import React, { FC } from 'react'
import { TopNavigationLinkForm } from './TopNavigaitonLinkForm/TopNavigationLinkForm'
import {
  CustomLink,
  getCustomTopNavigationLink
} from 'api/channel/getCustomTopNavigationLink'
import { Loader } from 'components/Loader/Loader'
import { useQuery } from '@tanstack/react-query'
import styles from './CustomLinks.module.scss'
import { Trans } from '@lingui/macro'
import { CustomLinkForm } from 'components/Forms/CustomLinkFormFields/CustomLinkFormFields'

interface Props {
  channelSlug: string
}
export const CustomLinks: FC<Props> = ({ channelSlug }) => {
  const {
    data,
    isLoading,
    isError,
    refetch
  }: {
    data: CustomLink | undefined
    isLoading: boolean
    isError: boolean
    refetch: () => void
  } = useQuery({
    queryKey: ['get-custom-top-navigation-link'],
    queryFn: () => getCustomTopNavigationLink(channelSlug, true),
    retry: false
  })
  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    return (
      <div>
        <Trans>Something went wrong</Trans>
      </div>
    )
  }
  const existingForm: CustomLinkForm | undefined = data && {
    textField: data.text,
    linkField: data.link,
    target: data.target
  }
  return (
    <div className={styles.Container}>
      <TopNavigationLinkForm
        channelSlug={channelSlug}
        existingForm={existingForm ? existingForm : undefined}
        onSubmitSuccess={() => refetch()}
      />
    </div>
  )
}
