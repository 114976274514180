import { RootState } from '../store'
const enum RootChannelSubdomain {
  SwedishHandball = 'svenskhandboll'
}
export const channelSelector = (state: RootState) => state.channel

export const channelCountrySelector = (state: RootState) =>
  state.channel?.data?.country

export const channelImpressumSelector = (state: RootState) =>
  state.channel?.data?.impressumPage

export const channelCustomPageSelector = (state: RootState) =>
  state.channel?.data?.meta_data?.misc?.custom_pages || []

export const isSwedishHandballSelector = (state: RootState) =>
  [RootChannelSubdomain.SwedishHandball].includes(
    state.channel?.data?.root_channel?.subdomain
  ) ||
  [RootChannelSubdomain.SwedishHandball].includes(
    state.channel?.data?.subdomain
  )
