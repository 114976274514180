import React, { FC } from 'react'
import styles from './LinkSection.module.scss'
import { ClassIcon } from './ClassIcon'
import { LocationIcon } from './LocationIcon'
export interface Location {
  link: string
  text: string
}
export interface EventClass {
  link: string
  text: string
}
interface Props {
  location?: Location
  eventClass?: EventClass
}

export const LinkSection: FC<Props> = ({ location, eventClass }) => (
  <div className={styles.LinkSectionContainer}>
    {eventClass && (
      <div className={styles.Label}>
        <div className={styles.Icon}>
          <ClassIcon />
        </div>
        {eventClass.text}
      </div>
    )}
    {location && (
      <div className={styles.Label}>
        <div className={styles.Icon}>
          <LocationIcon />
        </div>
        {location.text}
      </div>
    )}
  </div>
)
