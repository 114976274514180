import ApiEndpoint from 'network/ApiEndpoint'
import { mapPromotedChannel } from './mapPromotedChannel'
import { TrendingChannelItem } from './trendingChannel.interface'

export const getPromotedChannelsForRoot = async (
  rootChannelSlug: string
): Promise<TrendingChannelItem[]> => {
  try {
    const resultResponse = await ApiEndpoint.call({
      path: 'channels/promoted',
      method: ApiEndpoint.METHODS.GET,
      params: { root_channel: rootChannelSlug }
    })
    const responseData = await resultResponse
    const mapped =
      responseData && responseData.data && mapPromotedChannel(responseData.data)
    return mapped || null
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
