import React, { FC } from 'react'

export const FacebookIcon: FC = () => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1321_1281)">
      <path
        d="M28.473 16.0724C28.473 9.40309 23.0645 4 16.4006 4C9.73121 4 4.32812 9.40309 4.32812 16.0724C4.32812 22.0979 8.74395 27.0932 14.5119 27.9946V19.56H11.4482V16.0724H14.5119V13.4111C14.5119 10.385 16.3093 8.7163 19.0726 8.7163C20.3925 8.7163 21.7768 8.95238 21.7768 8.95238V11.9249H20.253C18.756 11.9249 18.2892 12.8585 18.2892 13.8135V16.0778H21.6373L21.1008 19.5654H18.2892V28C24.0572 27.0879 28.473 22.0926 28.473 16.0724Z"
        fill="#36354D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1321_1281">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(4.32812 4)"
        />
      </clipPath>
    </defs>
  </svg>
)
