import { t } from '@lingui/macro'
import { ApiStatus } from '../../../pages/ChannelPage/ChannelSettings/Branding/Logos/UploadLogo/ApiStatus.interface'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const postSecondaryLogo = async (
  channelSlug: string,
  secondaryLogo: File
): Promise<ApiStatus> => {
  try {
    const formData = new FormData()
    formData.append('image', secondaryLogo)
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/secondary_logo`,
      method: ApiEndpoint.METHODS.POST,
      authenticatable: true,
      file: formData
    })
    if (response.status === 201) {
      return { isSuccess: true }
    }
    return { isSuccess: false, message: t`Unknown response status` }
  } catch (error: any) {
    console.error('error!', error)
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
