import React, { FC, useState } from 'react'
import { TrendingChannels } from './TrendingChannels/TrendingChannels'
import { UpcomingStreams } from './UpcomingStreams/UpcomingStreams'
import ContentContainer from 'components/ContentContainer'

import styles from './SchedulePage.module.scss'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { BackgroundTheme, BannerAd } from 'advertising/BannerAd/BannerAd'
import { getChannelAdTargeting } from 'pages/ChannelPage/Start/getChannelAdTargeting'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
interface Props {
  isDefaultSchedule: boolean
}

export const SchedulePage: FC<Props> = ({ isDefaultSchedule }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const showAllGames = isDefaultSchedule
  const showRootDomainLabel = isDefaultSchedule
  const channel = useSelector(channelSelector)
  const channelType = channel?.data?.type
  const isRootChannel = channel?.data?.isRootChannel
  const isEventChannel = [ChannelTypes.event].includes(channelType)
  const isEventRootChannel = isRootChannel && isEventChannel
  const [showEmptyMessageForSchedule, setShowEmptyMessageForSchedule] =
    useState(true)
  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channel?.data)
  const targetingOptions = {
    partnerDomain: partnerDomain,
    parentChannelDomain: parentChannelDomain,
    sportCategory: sportCategory,
    countryCode: countryCode
  }
  const isMobile = currentBreakpoint <= Breakpoint.sm
  return (
    <ContentContainer
      hasLeftRightPadding={true}
      hasTopBottomPadding={true}
      hasLightBackground={true}
    >
      <div className={styles.Container}>
        <BannerAd
          adId={'div-gpt-ad-1721717813085-0'}
          slotPath={'/21792873324/calendar_banner_slot_1'}
          multiSize={
            isMobile
              ? [
                  [320, 100],
                  [320, 320],
                  [320, 240],
                  [320, 50]
                ]
              : [
                  [970, 90],
                  [980, 240],
                  [728, 90],
                  [970, 250],
                  [980, 90],
                  [980, 120]
                ]
          }
          targetingOptions={targetingOptions}
          backgroundTheme={BackgroundTheme.Grey}
        />
        {!isEventRootChannel && (
          <TrendingChannels
            onTrendingChannelsExist={() =>
              setShowEmptyMessageForSchedule(false)
            }
          />
        )}
        {!showEmptyMessageForSchedule && (
          <BannerAd
            adId={'div-gpt-ad-1721721454513-0'}
            slotPath={'/21792873324/calendar_banner_slot_2'}
            multiSize={
              isMobile
                ? [
                    [320, 320],
                    [320, 100],
                    [320, 240],
                    [320, 50]
                  ]
                : [
                    [970, 90],
                    [980, 240],
                    [728, 90],
                    [980, 90],
                    [970, 250],
                    [980, 120]
                  ]
            }
            targetingOptions={targetingOptions}
            backgroundTheme={BackgroundTheme.Grey}
          />
        )}
        <UpcomingStreams
          showAllGames={showAllGames}
          showRootDomainLabel={showRootDomainLabel}
          showEmptyMessage={showEmptyMessageForSchedule}
        />
      </div>
    </ContentContainer>
  )
}
