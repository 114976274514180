import { t } from '@lingui/macro'
import { InfoBannerProps } from '../../../components/Banner/InfoBanner/InfoBanner'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

export const getInfoBannerInformation = (
  channelType: ChannelTypes,
  channelSlug: string
): InfoBannerProps => {
  switch (channelType) {
    case ChannelTypes.team:
      return {
        title: t`Add games to your channel`,
        subTitle: t`By adding the competition your team is participating in, all your games will show on your channel`,
        shortSubTitle: t`By adding the competition your team is participating in, all your games will show on your channel`,
        linkPath: `/${channelSlug}/contentmanager/competitions`
      }
    case ChannelTypes.club:
      return {
        title: t`Add games to your teams' channels`,
        subTitle: t`Help your teams get started with broadcasting their games by adding a competitions games to their channel`,
        shortSubTitle: t`Help your teams get started with broadcasting their games by adding games to their channel`,
        linkPath: `/${channelSlug}/contentmanager/teams`
      }
    case ChannelTypes.league:
      return {
        title: t`Add competition`,
        subTitle: t`Add all the games from the competitions that should be on this channel`,
        shortSubTitle: t`Add all the games from the competitions that should be on this channel`,
        linkPath: `/${channelSlug}/contentmanager/competitions`
      }
    default:
      return { title: '', subTitle: '', shortSubTitle: '', linkPath: '' }
  }
}
