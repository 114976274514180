import { t } from '@lingui/macro'
import { LinkType, MenuLink } from 'components/page/menuLink/MenuLink'

interface ViewerPageSubmenuType extends MenuLink {
  id: number
}

export enum ViewerPageMenuOption {
  AboutStream = 1,
  Chat = 2,
  Statistics = 3,
  Lineup = 4,
  Result = 5
}

const menuItems: ViewerPageSubmenuType[] = [
  {
    id: ViewerPageMenuOption.AboutStream,
    translationText: t`About`,
    type: LinkType.Route,
    link: '?tab=about'
  },
  {
    id: ViewerPageMenuOption.Chat,
    translationText: t`Chat`,
    type: LinkType.Route,
    link: '?tab=chat'
  },
  {
    id: ViewerPageMenuOption.Result,
    translationText: t`Result`,
    type: LinkType.Route,
    link: '?tab=result'
  },
  {
    id: ViewerPageMenuOption.Lineup,
    translationText: t`Lineup`,
    type: LinkType.Route,
    link: '?tab=lineup'
  }
]

export default menuItems
