import React from 'react'

const VideoCameraIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.55518 9.42749H16.4885V19.5608H2.55518V9.42749Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4883 16.3942L20.6995 17.9733C20.7952 18.0091 20.8981 18.0213 20.9994 18.0087C21.1008 17.9962 21.1976 17.9593 21.2817 17.9012C21.3657 17.8432 21.4344 17.7656 21.482 17.6753C21.5296 17.5849 21.5546 17.4843 21.555 17.3822V11.6079C21.5546 11.5058 21.5296 11.4052 21.482 11.3148C21.4344 11.2245 21.3657 11.1469 21.2817 11.0889C21.1976 11.0308 21.1008 10.9939 20.9994 10.9814C20.8981 10.9688 20.7952 10.981 20.6995 11.0168L16.4883 12.5942V16.3942Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.08838 14.8109C5.08838 15.2308 5.25519 15.6335 5.55213 15.9305C5.84906 16.2274 6.25179 16.3942 6.67171 16.3942C7.09164 16.3942 7.49437 16.2274 7.7913 15.9305C8.08823 15.6335 8.25505 15.2308 8.25505 14.8109C8.25505 14.3909 8.08823 13.9882 7.7913 13.6913C7.49437 13.3944 7.09164 13.2275 6.67171 13.2275C6.25179 13.2275 5.84906 13.3944 5.55213 13.6913C5.25519 13.9882 5.08838 14.3909 5.08838 14.8109V14.8109Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7886 14.8109C10.7886 15.2308 10.9554 15.6335 11.2523 15.9305C11.5493 16.2274 11.952 16.3942 12.3719 16.3942C12.7918 16.3942 13.1946 16.2274 13.4915 15.9305C13.7884 15.6335 13.9552 15.2308 13.9552 14.8109C13.9552 14.3909 13.7884 13.9882 13.4915 13.6913C13.1946 13.3944 12.7918 13.2275 12.3719 13.2275C11.952 13.2275 11.5493 13.3944 11.2523 13.6913C10.9554 13.9882 10.7886 14.3909 10.7886 14.8109V14.8109Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.67188 13.2275H12.3719"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2883 4.36084H15.855C15.687 4.36084 15.526 4.42757 15.4072 4.54634C15.2884 4.66511 15.2217 4.8262 15.2217 4.99417V6.26084C15.2217 6.42881 15.2884 6.5899 15.4072 6.70867C15.526 6.82745 15.687 6.89417 15.855 6.89417H20.2883C20.6243 6.89417 20.9465 6.76072 21.184 6.52317C21.4216 6.28563 21.555 5.96345 21.555 5.62751C21.555 5.29157 21.4216 4.96938 21.184 4.73184C20.9465 4.49429 20.6243 4.36084 20.2883 4.36084Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2218 5.62756H13.9551C13.6192 5.62756 13.297 5.76102 13.0595 5.99856C12.8219 6.23611 12.6885 6.55829 12.6885 6.89423V9.42756"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.18848 6.89417H8.25514C8.59108 6.89417 8.91327 7.02762 9.15081 7.26516C9.38836 7.50271 9.52181 7.82489 9.52181 8.16083V9.4275"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default VideoCameraIcon
