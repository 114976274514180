import React, { FC, useState } from 'react'
import { Featured } from '../cardTypes'
import Test from '../smallhandball.jpeg'

import styles from './featured.module.scss'

interface Props {
  content: Featured
}

const FeaturedContent: FC<Props> = ({ content }) => {
  const { name, description, dates, imageUrl } = content
  const image = imageUrl && imageUrl !== '' ? imageUrl : Test
  const color1 = '#000000'
  const color2 = 'rgba(0, 0, 0, 0) 58.21%'
  const [hover, setHover] = useState(false)

  const style = {
    normal: {
      backgroundImage: `linear-gradient(to top, ${color1} 0%, ${color2} 100%) `
    },
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  return (
    <>
      <div
        data-testid="featured-container"
        className={styles.FeaturedContainer}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div />
        <div
          className={styles.FeaturedContent}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            ...style.normal,
            ...(hover ? style.hover : null)
          }}
        >
          <div
            data-testid="featured-content-data"
            className={styles.FeaturedContentData}
          >
            <div className={styles.Dates}>{dates}</div>
            <h4>{name}</h4>
            <div className={styles.Description}>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FeaturedContent
