import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { CompanionAppLinks } from './CompanionAppLinks'
import {
  getAppStoreLink,
  getPlayStoreLink
} from '../../../../../helpers/companionApps'
import styles from './CompanionAppSection.module.scss'

interface Props {
  rootChannelSubdomain: string
  isRootOrChildChannels: boolean
}

export const CompanionAppSection: FC<Props> = ({
  rootChannelSubdomain,
  isRootOrChildChannels
}) => {
  const appStoreLink = getAppStoreLink(rootChannelSubdomain)
  const playStoreLink = getPlayStoreLink(rootChannelSubdomain)
  return (
    <div
      className={classNames(styles.CompanionAppSection, {
        [styles.Themed]: isRootOrChildChannels
      })}
    >
      <div className={styles.Text}>
        <Trans>Watch all the action on our companion app</Trans>
      </div>
      <CompanionAppLinks
        playStoreLink={playStoreLink || ''}
        appStoreLink={appStoreLink || ''}
      />
    </div>
  )
}
