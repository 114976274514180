import React, { FC } from 'react'
import classNames from 'classnames'

import { RightArrowIcon } from './RightArrowIcon'
import { ExternalLinkIcon } from './ExternalLinkIcon'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { getTranslatedChannelTypeIndefinite } from 'components/content/RelatedChannel/RelatedChannelItem/getTranslatedChannelTypeIndefinite'
import Shield from '../../assets/images/shield.png'

import styles from './ChannelListItem.module.scss'

interface Props {
  logo: string
  name: string
  sportLabel: string
  rootDomain?: string
  channelType?: ChannelTypes
  showBorder: boolean
}

export const ChannelListItem: FC<Props> = ({
  logo,
  name,
  sportLabel,
  rootDomain,
  channelType,
  showBorder
}) => {
  const Icon = rootDomain ? <ExternalLinkIcon /> : <RightArrowIcon />
  const channelLogoToUse = logo ? logo : Shield
  return (
    <div
      className={classNames(styles.ChannelListItemContainer, {
        [styles.WithExternalLink]: rootDomain,
        [styles.HasBorder]: showBorder
      })}
      data-testid="channel-item"
    >
      <img src={channelLogoToUse} alt={name} className={styles.Logo} />
      <div className={styles.VerticalDivider} />
      <div className={styles.LabelContainer}>
        <div className={styles.Name} data-testid="channel-name">
          {name}
        </div>
        <div className={styles.TagContainer}>
          {sportLabel && <div className={styles.Tag}>{sportLabel}</div>}
          {channelType && (
            <div className={styles.Tag}>
              {getTranslatedChannelTypeIndefinite(channelType)}
            </div>
          )}
        </div>

        {rootDomain && (
          <div className={styles.RootDomainLabel} data-testid="root-domain">
            {rootDomain}
          </div>
        )}
      </div>
      <div className={styles.IconContainer}>{Icon}</div>
    </div>
  )
}
