import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './HeroSection.module.scss'
import { HeroSectionInterface } from './HeroSection.interface'
import Button, { ButtonType } from '../../../components/buttons/button/Button'

interface Props {
  sectionData: HeroSectionInterface
}

const HeroSectionComponent: FC<Props> = ({ sectionData }) => {
  const { buttonLabel } = sectionData

  const buttonClick = () => {
    return <Link to={`/${sectionData.link}`} />
  }

  return (
    <div
      className={styles.HeroContainer}
      data-testid="background-container"
      style={{ backgroundImage: `url(${sectionData.imagePath})` }}
    >
      <div className={styles.ContentContainer}>
        <div className={styles.Title} data-testid="hero-title">
          {sectionData.sectionTitle}
        </div>
        <div className={styles.Text} data-testid="hero-text">
          {sectionData.text}
        </div>
        <Button
          data-testid="hero-button"
          label={buttonLabel}
          type={ButtonType.Primary}
          buttonSize="Medium"
          onClick={() => buttonClick()}
        />
      </div>
    </div>
  )
}
export default HeroSectionComponent
