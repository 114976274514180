import React, { FC } from 'react'
import { t } from '@lingui/macro'

import styles from './ImageLink.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface Props {
  imageUrl?: string
  imageLink?: string
  imageTarget: LinkTarget
}

export interface ImageLinkDisplay extends Props {}

export const ImageLink: FC<Props> = ({ imageLink, imageUrl }) => {
  return (
    <div className={styles.Container}>
      <a href={imageLink}>
        <img alt={t`banner for channel`} src={imageUrl} />
      </a>
    </div>
  )
}
