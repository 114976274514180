import React, { FC } from 'react'

export const VisaIcon: FC = () => (
  <svg
    width="48"
    height="24"
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="24" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1663 7.44798C31.5891 7.23205 30.6845 7 29.5543 7C26.6746 7 24.6453 8.44574 24.6283 10.5164C24.6125 12.0486 26.0765 12.9017 27.1824 13.4125C28.3164 13.9339 28.6983 14.2673 28.6923 14.7335C28.6851 15.4479 27.7869 15.7741 26.9491 15.7741C25.7823 15.7741 25.1629 15.6133 24.2056 15.2154L23.8297 15.0457L23.4206 17.4315C24.1021 17.7285 25.36 17.9868 26.6673 18C29.7314 18 31.7206 16.5712 31.7428 14.3594C31.7534 13.1469 30.9774 12.2243 29.2963 11.4645C28.2768 10.9711 27.6532 10.6415 27.66 10.1426C27.66 9.70058 28.1874 9.22588 29.3283 9.22588C30.2827 9.21103 30.9723 9.41847 31.5099 9.63482L31.7717 9.757L32.1663 7.44798ZM18.6824 17.8418L20.5048 7.18583H23.419L21.5958 17.8418H18.6824ZM37.3888 7.19625H39.6411L41.9998 17.8446H39.295C39.295 17.8446 39.0281 16.6211 38.9408 16.2482C38.7154 16.2482 37.7739 16.247 36.8891 16.2459L36.8768 16.2459C36.0971 16.2449 35.3642 16.244 35.208 16.244C35.0948 16.532 34.5946 17.8446 34.5946 17.8446H31.5335L35.8619 8.0799C36.1692 7.38546 36.6907 7.19625 37.3888 7.19625ZM36.0462 14.0644C36.2875 13.4505 37.2079 11.0851 37.2079 11.0851C37.1994 11.099 37.256 10.9523 37.3334 10.752L37.3335 10.7519C37.4146 10.5418 37.5186 10.2728 37.594 10.0682L37.7906 10.9871C37.7906 10.9871 38.3491 13.5303 38.4662 14.0644H36.0462ZM13.3847 14.46L16.2376 7.19358H19.3247L14.7366 17.8288L11.6525 17.833L9.04395 8.51291C10.894 9.43601 12.5495 11.2805 13.0803 12.9833L13.3847 14.46Z"
      fill="#1A1F71"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7374 7.18659H6.03703L6 7.40803C9.65663 8.29083 12.0762 10.4221 13.0804 12.9835L12.0583 8.08594C11.8821 7.411 11.37 7.20992 10.7374 7.18659Z"
      fill="#F9A51A"
    />
  </svg>
)
