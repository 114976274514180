import React, { FC } from 'react'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { step3ValidationSchema } from './SignupValidationSchemas'

import classNames from 'classnames'
import styles from './Form.module.scss'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { PhoneNumberField } from 'components/Forms/PhoneNumberField/PhoneNumberField'
import { Country } from 'api/countries/getCountriesList'
import { patchUserPhoneNumber } from 'api/user/patchUserPhoneNumber'

export interface SignupStep3Values {
  phoneNumber: string
  countryCode: string
}
interface Props {
  onSubmitFormSuccess: () => void
  onSkipClicked: () => void
}

export const SignupStep3Form: FC<Props> = ({
  onSkipClicked,
  onSubmitFormSuccess
}) => {
  const initialValues: SignupStep3Values = {
    phoneNumber: '',
    countryCode: ''
  }

  const onSubmit = async (
    values: SignupStep3Values,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const response = await patchUserPhoneNumber({
      phoneNumber: values.phoneNumber,
      countryCode: values.countryCode
    })
    if (response.isSuccess) {
      onSubmitFormSuccess()
    } else {
      formikHelpers.setErrors(response.message)
    }
    formikHelpers.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={step3ValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.FormContainer}>
              <div
                className={classNames(
                  styles.FormFields,
                  styles.PhoneNumberField
                )}
              >
                <PhoneNumberField
                  label={''}
                  id={'phoneNumber'}
                  placeholder={'733 73 73'}
                  onCountrySelected={(country: Country) => {
                    formik.setFieldValue('countryCode', country.countryCode)
                    formik.setFieldTouched('country', true, false)
                  }}
                />
              </div>

              <div className={styles.ButtonContainer}>
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SubmitButton}
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                  label={t`Add phone number`}
                />
                <Button
                  buttonSize="Medium"
                  customStyle={styles.BackButton}
                  type={ButtonType.GhostWithBoarder}
                  label={t`Skip`}
                  onClick={onSkipClicked}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
