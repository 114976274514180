import { ReactNode } from 'react'
import dayjs from 'dayjs'
import { Company } from '../interfaces/Company.interface'
import { MediaObject } from 'interfaces/MediaObject.interface'
import { metaFriendlyString } from 'helpers/generalHelpers'

export const getRichVideoMetaData = (
  mediaObject: MediaObject,
  channel: Company
): ReactNode => `{
    "@context": "https://schema.org",
    "@id": "${mediaObject.link}",
    "@type": "VideoObject",
    "name": "${metaFriendlyString(mediaObject.title)}",
    "url": "${window.location.href}",
    "embedUrl": "${window.location.href}",
    "description": "${
      metaFriendlyString(mediaObject.description) ||
      metaFriendlyString(mediaObject.title)
    }",
    "thumbnailUrl": "${mediaObject.thumbnail}",
    "datePublished": "${dayjs.unix(mediaObject.published_at).format()}",
    "uploadDate": "${dayjs.unix(mediaObject.created_at).format()}",
    "dateModified": "${dayjs.unix(mediaObject.updated_at).format()}",
    "duration": "${
      mediaObject.duration > 0
        ? dayjs.duration({ seconds: mediaObject.duration }).toISOString()
        : 1
    }",
    "interactionStatistic": {
      "@type": "InteractionCounter",
      "interactionType": { "@type": "http://schema.org/WatchAction" },
      "userInteractionCount": "${mediaObject.view_count}"
    },
    "requiresSubscription": "${mediaObject.access_restriction === 'paywall'}"
    ${
      channel.type !== 'solidtango'
        ? `
      ,"publisher": {
        "@type": "Organization",
        "name": "${metaFriendlyString(channel.name)}",
        "url": "${window.location.origin}/${channel.subdomain}",
        "logo": {
          "@type": "ImageObject",
          "url": "${channel.logo_image_url}",
          "width": "400",
          "height": "400"
        }
      }`
        : ''
    }
    ${
      mediaObject.type === 'livestream'
        ? `
      ,"publication": [
        {
          "@type": "BroadcastEvent",
          "isLiveBroadcast": "true",
          "startDate": "${dayjs.unix(mediaObject.live_start_at).format()}",
          "endDate": "${dayjs.unix(mediaObject.live_end_at).format()}"
        }
      ]
        `
        : ''
    }
  }`
