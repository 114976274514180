import React, { FC } from 'react'
import SolidsportLogo from '../../page/header/components/hamburgerMenuContent/topContainer/SolidsportLogo'

import styles from './SearchResultCard.module.scss'

interface Props {
  imageUrl: string | undefined | null
  title: string
}

const Image: FC<Props> = ({ imageUrl, title }) =>
  imageUrl ? (
    <img className={styles.Image} src={imageUrl} alt={title} />
  ) : (
    <div className={styles.Image}>
      <SolidsportLogo />
    </div>
  )

export default Image
