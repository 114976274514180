import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const RouterContext = React.createContext({})

type RouterProviderProps = {
  children: any
}

const RouterProvider: React.FC<RouterProviderProps> = ({ children }) => {
  const location = useLocation()
  const previous = useRef(location.pathname)

  useEffect(() => {
    previous.current = location.pathname
  }, [location])

  const route = {
    from: previous.current,
    to: location.pathname
  }

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  )
}

export default RouterProvider
