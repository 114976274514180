import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import styles from './LastUpdated.module.scss'

interface Props {
  timestamp: number
}

export const LastUpdated: FC<Props> = ({ timestamp }) => {
  const date = dayjs(timestamp).format('YYYY-MM-DD HH:mm')

  return (
    <div className={styles.Container}>
      <Trans>Last update:</Trans>
      <div>{date}</div>
    </div>
  )
}
