import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { HeroImageCallToAction } from 'features/customContent/HeroImageCallToAction/NewHeroImagePromotionModal/Form/HeroImageCallToActionForm'

interface HeroImageCallToActionParams extends HeroImageCallToAction {
  channelSlug: string
}
export const postHeroImageCallToAction = async (
  params: HeroImageCallToActionParams
): Promise<any> => {
  try {
    const formData = new FormData()
    formData.append('title', params.title)
    formData.append('content', params.text)
    // @ts-ignore
    formData.append('button_text', params.textField)
    // @ts-ignore
    formData.append('button_link', params.linkField)
    // @ts-ignore
    formData.append('button_link_target', params.target)

    const response: ApiResponse = await ApiEndpoint.call({
      path: `channels/${params.channelSlug}/call_to_action/hero_image`,
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
