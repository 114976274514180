import React from 'react'

import { HeroSectionMockDataEng } from './HeroSection/HeroMockDataEng'
import {
  AsideImageSectionMockData,
  AsideVideoMockDataEng
} from './AsideMediaSection/AsideMediaSectionMockData'
import {
  WhatYouGetMockData,
  LiveStreamsForEveryoneMockData,
  MoreUsersMockData
} from './InfoSection/InfoMockDataEng'
import { TeamsSectionMockData } from './TeamsSection/TeamsSectionMockData'
import { TestimonialsMockData } from './TestimonialsSection/TestimonaialsMockData'
import HeroSection from './HeroSection/HeroSection'
import InfoSectionComponent from './InfoSection/InfoSection'
import AsideMediaSectionComponent from './AsideMediaSection/AsideMediaSection'
import OurTeamsComponent from './TeamsSection/TeamsSection'
import TestimonialsComponent from './TestimonialsSection/TestimonialsSection'

const BroadcastStartPage = () => (
  <div data-testid="broadcast-container">
    <HeroSection sectionData={HeroSectionMockDataEng} />
    <AsideMediaSectionComponent sectionData={AsideVideoMockDataEng} />
    <InfoSectionComponent sectionData={WhatYouGetMockData} />
    <OurTeamsComponent sectionData={TeamsSectionMockData} />
    <AsideMediaSectionComponent sectionData={AsideImageSectionMockData} />
    <InfoSectionComponent sectionData={LiveStreamsForEveryoneMockData} />
    <InfoSectionComponent sectionData={MoreUsersMockData} />
    <TestimonialsComponent sectionData={TestimonialsMockData} />
  </div>
)
export default BroadcastStartPage
