import React, { FC } from 'react'

import styles from './TeamVsTeamBackground.module.scss'
import { TeamBadge } from './TeamBadge/TeamBadge'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { MediaObject } from 'interfaces'

export const TeamVsTeamBackground: FC = () => {
  const stream = useSelector(streamSelector)
  const mediaObject: MediaObject = stream.data.mediaObject
  if (mediaObject.game) {
    const homeTeam = mediaObject.game.home_team
    const awayTeam = mediaObject.game.away_team
    return (
      <div className={styles.Container} data-testid="team-vs-team-background">
        <div className={styles.RightBackground} />
        <div className={styles.InnerContainer}>
          <div className={styles.Teams}>
            <TeamBadge logoUrl={homeTeam?.badge_url} name={homeTeam?.name} />
            <TeamBadge logoUrl={awayTeam?.badge_url} name={awayTeam?.name} />
          </div>
        </div>
      </div>
    )
  }
  return null
}
