import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface Country {
  flagUrl: string
  countryCode: string
  name: string
  alpha2: string
}

export interface CountryApiResponse {
  flag: string
  country_code: string
  name: string
  alpha2: string
}

export const getCountryList = async (): Promise<Country[]> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'settings/countries'
    })

    const responseData = await resultResponse
    const mapped: Country[] = mapGroupList(responseData)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapGroupList = (responseData: ApiResponse) =>
  responseData?.data?.map((country: CountryApiResponse) => ({
    flagUrl: country.flag,
    countryCode: country.country_code,
    name: country.name,
    alpha2: country.alpha2
  }))
