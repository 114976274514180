import { mapBroadcastThumbnailResponse } from 'api/channel/mapBroadcastThumnbailFromResponse'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { SearchQuery } from '../search.interface'

interface FutureGamesSearch extends SearchQuery {}

interface FutureGamesSearchResult {}

interface FutureGamesSearchResults {
  data: FutureGamesSearchResult[]
  totalCount: number
}

export const getFutureGamesSearch = async ({
  searchTerm,
  channelSlug,
  pageNumber = 1,
  pageSize = 15
}: FutureGamesSearch): Promise<FutureGamesSearchResults> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'search/upcoming_streams',
      params: {
        query: searchTerm,
        page: pageNumber,
        per_page: pageSize,
        ...(channelSlug && { root_channel: channelSlug })
      }
    })
    const responseData = await resultResponse.data
    const searchResults: any = mapBroadcastThumbnailResponse(responseData)
    return {
      data: searchResults,
      totalCount: resultResponse.totalCount || 0
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
