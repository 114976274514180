import { formatCurrency } from '../../../../helpers/formatHelper'

export const getSubscriptionPrice = (price: any, preferredCurrency: string) => {
  if (price[preferredCurrency]) {
    return {
      currency: preferredCurrency,
      amount: price[preferredCurrency],
      displayPrice: formatCurrency(price[preferredCurrency], preferredCurrency)
    }
  }
  const keys = Object.keys(price)
  const values = Object.values(price)
  return {
    currency: keys[0],
    amount: values[0],
    displayPrice: formatCurrency(Number(values[0]), keys[0])
  }
}
