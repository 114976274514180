import { EventApi } from './getChannelEvents'

export enum EventStatus {
  Future = 'future',
  Past = 'past',
  FutureAndActive = 'future_and_active',
  Active = 'active'
}

const eventStatusFromString = (value: string) => {
  return (Object.values(EventStatus) as unknown as string[]).includes(value)
    ? (value as unknown as EventStatus)
    : undefined
}

export const mapChannelEventsResponse = (eventsResponse: EventApi[]) =>
  eventsResponse.map((response) => ({
    channelSlug: response.channel_slug,
    status: eventStatusFromString(response.status),
    eventSlug: response.slug,
    id: response.id.toString(),
    name: response.name,
    startDate: response.start_date
  }))
