import { t } from '@lingui/macro'
import { Period } from '../../PurchaseOptions/CardText/period'

export const getProductPeriodCost = (
  autoRenew: boolean,
  displayPrice: string,
  timeframe: Period
) => {
  if (autoRenew && timeframe) {
    if (timeframe === Period.Monthly) {
      return t`${displayPrice} / month`
    }
    return t`${displayPrice} / year`
  } else {
    return `${displayPrice}`
  }
}
