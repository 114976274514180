import React, { FC, createContext, useReducer, useContext } from 'react'

interface StatusMessageState {
  showAddCompetitionSuccess: boolean
  showRemoveTeamFromCompetitionStatus: boolean
  selectedTeam?: {
    name: string
    slug: string
  }
  showAddLeagueStatus: boolean
  showRemoveLeagueStatus: boolean
}
const initialState: StatusMessageState = {
  showAddCompetitionSuccess: false,
  showRemoveTeamFromCompetitionStatus: false,
  selectedTeam: undefined,
  showAddLeagueStatus: false,
  showRemoveLeagueStatus: false
}

export const StatusMessageContext = createContext<any>(initialState)
export const StatusMessageDispatchContext = createContext<any>(null)

export const useStatusMessageDispatchContext = () => {
  return useContext(StatusMessageDispatchContext)
}

export const useStatusMessageContext = () => {
  return useContext(StatusMessageContext)
}

interface Props {
  children?: React.ReactNode
}

export const StatusMessageProvider: FC<Props> = ({ children }) => {
  const [messageStatus, dispatch] = useReducer(
    statusMessageReducer,
    initialState
  )

  return (
    <StatusMessageContext.Provider value={messageStatus}>
      <StatusMessageDispatchContext.Provider value={dispatch}>
        {children}
      </StatusMessageDispatchContext.Provider>
    </StatusMessageContext.Provider>
  )
}

const statusMessageReducer = (statusMessageStatus: any, action: any) => {
  switch (action.type) {
    case 'team_selected': {
      return {
        ...statusMessageStatus,
        selectedTeam: action.selectedTeam
      }
    }
    case 'show_competition_added': {
      return {
        ...statusMessageStatus,
        showAddCompetitionSuccess: true
      }
    }
    case 'hide_competition_added': {
      return {
        ...statusMessageStatus,
        showAddCompetitionSuccess: false
      }
    }
    case 'show_remove_team_from_competition': {
      return {
        ...statusMessageStatus,
        showRemoveTeamFromCompetitionStatus: true
      }
    }
    case 'hide_remove_team_from_competition': {
      return {
        ...statusMessageStatus,
        showRemoveTeamFromCompetitionStatus: false
      }
    }
    case 'toggle_show_competition_added': {
      return {
        ...statusMessageStatus,
        showAddCompetitionSuccess:
          !statusMessageStatus.showAddCompetitionSuccess
      }
    }
    case 'toggle_show_competition_removed': {
      return {
        ...statusMessageStatus,
        showRemoveLeagueStatus: !statusMessageStatus.showRemoveLeagueStatus
      }
    }
    default: {
      return statusMessageStatus
    }
  }
}
