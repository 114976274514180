import React, { FC } from 'react'

const ContactSupportIcon: FC = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.63434 5.6141C4.70654 4.89044 4.95962 4.19658 5.37029 3.59637C5.78096 2.99616 6.33601 2.50891 6.98436 2.17945C7.63271 1.85 8.35351 1.68894 9.08043 1.71109C9.80734 1.73324 10.517 1.9379 11.1441 2.30622C11.7712 2.67454 12.2955 3.19469 12.6689 3.81879C13.0422 4.44289 13.2526 5.15088 13.2806 5.87759C13.3086 6.6043 13.1534 7.32638 12.8291 7.97736C12.5049 8.62834 12.0221 9.1873 11.4253 9.60278"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.22549 3.85797C5.89643 4.55063 6.69982 5.10128 7.58787 5.47715C8.47593 5.85303 9.43052 6.04645 10.3948 6.04592C11.3679 6.0469 12.3311 5.85028 13.226 5.46798"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0195 9.14206C7.77456 9.14206 7.53511 9.21469 7.33145 9.35078C7.12778 9.48686 6.96905 9.68028 6.87531 9.90658C6.78157 10.1329 6.75705 10.3819 6.80483 10.6221C6.85262 10.8624 6.97057 11.083 7.14377 11.2563C7.31698 11.4295 7.53765 11.5474 7.77789 11.5952C8.01813 11.643 8.26714 11.6185 8.49344 11.5247C8.71974 11.431 8.91316 11.2722 9.04925 11.0686C9.18533 10.8649 9.25797 10.6255 9.25797 10.3805C9.25797 10.0521 9.12749 9.73705 8.89523 9.5048C8.66297 9.27254 8.34796 9.14206 8.0195 9.14206Z"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.78104 10.3805H5.54257C4.88565 10.3805 4.25563 10.1196 3.79112 9.65506C3.3266 9.19055 3.06564 8.56053 3.06564 7.90361V7.01357"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 20.2883C17 18.8158 16.5958 17.3717 15.8315 16.1132C15.0671 14.8547 13.972 13.8302 12.6654 13.1514"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.23294 13.1514C3.92666 13.8305 2.83184 14.8551 2.0678 16.1135C1.30375 17.372 0.8998 18.816 0.899963 20.2883"
        stroke="#2F3135"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ContactSupportIcon
