/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Trans, t } from '@lingui/macro'

import { GameScheduleCardType } from '../../../components/cards/GameScheduleCard/gameScheduleType'
import { GameScheduleContent } from '../../../components/content/GameScheduleContent/GameScheduleContent'
import { Toggle } from '../../../components/Toggle/Toggle'
import { Loader } from '../../../components/Loader/Loader'
import { getPastGameScheduleContent } from '../../../api/channel/getPastGameScheduleContent'
import { getUpcomingGameScheduleContent } from '../../../api/channel/getUpcomingGameScheduleContent'
import { useChannelPage } from '../useChannelPage'
import { ChannelTypes } from '../channelType'
import LoadMoreSection from '../../../components/LoadMoreSection/LoadMoreSection'
import { CHANNEL_SUB_MENU_ID } from '../Header/Submenu/Submenu'
import { SchedulePreloader } from './Preloader/SchedulePreLoader'
import InfoBanner from 'components/Banner/InfoBanner/InfoBanner'

import styles from './Schedule.module.scss'
interface Props {
  isAdmin: boolean
  channelSlug: string
  channelType: ChannelTypes
}
export const Schedule: FC<Props> = ({ isAdmin, channelSlug, channelType }) => {
  const [upcomingIsActive, setIsUpcomingActive] = useState(true)
  const [upcomingGames, setUpcomingGames] = useState<GameScheduleCardType[]>([])
  const [pastGames, setPastGames] = useState<GameScheduleCardType[]>([])
  const [pageForUpcoming, setPageForUpcoming] = useState(1)
  const [totalPagesForUpcoming, setTotalPagesForUpcoming] = useState(0)
  const [totalPagesForPast, setTotalPagesForPast] = useState(0)
  const PER_PAGE_COUNT = 20
  const [pageForPast, setPageForPast] = useState(1)
  const upcomingChannelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: pageForUpcoming,
    channelDataApiCall: getUpcomingGameScheduleContent,
    onData: (data: any) => setUpcomingGames((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) =>
      setTotalPagesForUpcoming(total / PER_PAGE_COUNT)
  })

  const pastChannelPageProps = useChannelPage({
    channelSlug,
    currentPage: pageForPast,
    perPageCount: PER_PAGE_COUNT,
    channelDataApiCall: getPastGameScheduleContent,
    onData: (data: any) => setPastGames((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) =>
      setTotalPagesForPast(total / PER_PAGE_COUNT)
  })

  const isError =
    upcomingChannelPageProps.isError || pastChannelPageProps.isError
  const isLoading =
    upcomingChannelPageProps.isLoading || pastChannelPageProps.isLoading
  const isEmpty = !upcomingGames?.length && !pastGames?.length

  const totalPages = upcomingIsActive
    ? totalPagesForUpcoming
    : totalPagesForPast

  const increasePageNumber = () => {
    if (upcomingIsActive) {
      setPageForUpcoming((prevValue) => prevValue + 1)
    } else {
      setPageForPast((prevValue) => prevValue + 1)
    }
  }

  useEffect(() => {
    if (
      !isEmpty &&
      !isLoading &&
      upcomingGames.length < 1 &&
      pastGames.length > 0
    ) {
      setIsUpcomingActive(false)
    }
  }, [isLoading, upcomingGames, pastGames])
  const currentPage = upcomingIsActive ? pageForUpcoming : pageForPast

  if (isError) {
    return (
      <div className={styles.Error}>
        <Trans>
          Something went wrong trying to retrive the data, please try again by
          reloading the page.
        </Trans>
      </div>
    )
  }
  if (isLoading && isEmpty) {
    return <SchedulePreloader length={PER_PAGE_COUNT} />
  }
  if (!isEmpty)
    return (
      <div data-testid="game-schedule-container" className={styles.Schedule}>
        <div className={styles.ToggleButtons}>
          <Toggle
            firstLabel={t`Upcoming games`}
            secondLabel={t`Past games`}
            firstButtonSelected={upcomingIsActive}
            onClick={() => setIsUpcomingActive(!upcomingIsActive)}
          />
        </div>
        <div className={styles.GameScheduleContent}>
          <GameScheduleContent
            gameScheduleContent={upcomingIsActive ? upcomingGames : pastGames}
            key={`GameScheduleContent__${
              upcomingIsActive ? 'upcomingGames' : 'pastGames'
            }`}
            isAdmin={isAdmin}
            channelType={channelType}
            channelSlug={channelSlug}
          />
          {isLoading && <Loader />}
          <LoadMoreSection
            totalPages={totalPages}
            currentPage={currentPage}
            onClick={() => increasePageNumber()}
            scrollId={CHANNEL_SUB_MENU_ID}
          />
        </div>
      </div>
    )

  const subTitle = t`There are currently no games to display, if you think there should be
some here - please wait for a few minutes and try again. Click this banner to return to your channel's home`
  return (
    <div className={classNames(styles.GameScheduleContent, styles.Empty)}>
      <InfoBanner
        title={t`No games?`}
        shortSubTitle={subTitle}
        subTitle={subTitle}
        linkPath={`/${channelSlug}`}
      />
    </div>
  )
}
