import React, { FC } from 'react'
import styles from './TextSection.module.scss'
import PAYMENTS from 'redux-store/payments/payments.constants'
import { getBodyText } from './getBodyText'
import { getBulletPointList } from './getBulletPointList'
import { BulletPointList } from '../BulletPointList/BulletPointList'

interface Props {
  currentPayment: any
}

export const TextSection: FC<Props> = ({ currentPayment }) => {
  const bulletPoints = getBulletPointList(
    currentPayment.subscription,
    currentPayment
  )

  return (
    <div className={styles.TextContainer}>
      <h3 className={styles.Title}>
        {currentPayment.type === PAYMENTS.TYPE_PPV
          ? currentPayment?.mediaObjectTitle
          : currentPayment?.subscription?.title}
      </h3>
      <div
        className={styles.BodyPart}
        dangerouslySetInnerHTML={{
          __html: getBodyText(currentPayment)?.content
        }}
      />
      {bulletPoints && bulletPoints.length > 0 && (
        <BulletPointList bulletPoints={bulletPoints} />
      )}
    </div>
  )
}
