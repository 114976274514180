/* eslint-disable @typescript-eslint/indent */
import AUTHENTICATION from './authentication.constants'
import { Authentication } from './authentication.interface'

export const createInitialState = () => {
  // Initial app token
  const appToken = localStorage.getItem('appToken') ?? ''

  // Initial app token date
  const tokenDate = localStorage.getItem('tokenDate') ?? ''

  const baseState = {
    loggedIn: false,
    loggingIn: false,
    registered: false,
    registering: false,
    sent: false,
    sending: false,
    loginLayerOpen: false,
    error: null,
    loginLayerClosing: false,
    loginLayerPage: '',
    user: undefined,
    mode: 'login'
  }

  return appToken && tokenDate
    ? { ...baseState, loggedIn: true, appToken: appToken, tokenDate: tokenDate }
    : {
        ...baseState,
        appToken: undefined,
        tokenDate: undefined
      }
}

// Initial state
const initialState = { ...createInitialState() } as Authentication

function authentication(state = initialState, action: any): Authentication {
  switch (action.type) {
    case AUTHENTICATION.LOGIN_CALLBACK: {
      return {
        ...state,
        loggedIn: false,
        loggingIn: true
      }
    }
    case AUTHENTICATION.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        user: action.user,
        appToken: action.appToken,
        tokenDate: action.tokenDate
      }
    case AUTHENTICATION.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.user,
        appToken: action.appToken,
        loginLayerClosing: false,
        loginLayerPage: ''
      }
    case AUTHENTICATION.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        error: action.error,
        loginLayerClosing: false
      }
    case AUTHENTICATION.LOGOUT:
      localStorage.removeItem('user')
      localStorage.removeItem('appToken')
      localStorage.removeItem('tokenDate')
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        user: undefined,
        loginLayerClosing: false
      }
    case AUTHENTICATION.LOGOUT_WITHOUT_MIDDLEWARE:
      localStorage.removeItem('user')
      localStorage.removeItem('appToken')
      localStorage.removeItem('tokenDate')
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        user: undefined,
        loginLayerClosing: false
      }
    case AUTHENTICATION.REGISTER_REQUEST:
      return {
        ...state,
        registered: false,
        registering: true
      }
    case AUTHENTICATION.REGISTER_SUCCESS:
      return {
        ...state,
        registered: true,
        registering: false,
        loggedIn: true,
        user: action.user,
        loginLayerOpen: true
      }
    case AUTHENTICATION.REGISTER_FAILURE:
      return {
        ...state,
        registered: false,
        registering: false,
        error: action.error
      }
    case AUTHENTICATION.SEND_EMAIL_REQUEST:
      return {
        ...state,
        sent: false,
        sending: true
      }
    case AUTHENTICATION.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sent: true,
        sending: false
      }
    case AUTHENTICATION.SEND_EMAIL_FAILURE:
      return {
        ...state,
        sent: false,
        sending: false,
        error: action.error
      }
    case AUTHENTICATION.LOGIN_LAYER_OPEN:
      return {
        ...state,
        loginLayerOpen: true,
        loginLayerPage: action.page,
        loginLayerClosing: false,
        mode: action.mode
      }
    case AUTHENTICATION.LOGIN_LAYER_CLOSE:
      return {
        ...state,
        loginLayerClosing: true,
        loginLayerOpen: false,
        mode: undefined
      }
    case AUTHENTICATION.RESET_LOGIN_STATE:
      return {
        ...state,
        loginLayerClosing: false,
        loginLayerPage: ''
      }
    case AUTHENTICATION.CLEAR_STATE: // Clear unimportant state,
      // but don't know if this is good practice.
      return {
        ...state,
        registered: false,
        sent: false,
        error: null
      }
    case AUTHENTICATION.RESET_FROM_LOCAL_STATE:
      return {
        ...createInitialState()
      }
    default:
      return state
  }
}

export default authentication
