import { TallThumbnailType } from '../../components/cards/TallThumbnail/cardTypes'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { Sport } from '../../components/cards/TallThumbnail/cardTypes'

export const getSports = async () => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'settings/sports',
      params: { pt: 2 }
    })
    const responseData = await resultResponse
    const mapped = mapSportResponse(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const mapSportResponse = (apiResponse: any) =>
  apiResponse.map((sportApi: any) => ({
    id: sportApi.id,
    name: sportApi.name,
    // imageUrl: string
    targetUrl: `sport/${sportApi.slug}`,
    country: 'SE',
    type: TallThumbnailType.Sport
  }))

export const sortSportsAlphabetically = (sportsList: Sport[]) => {
  return [...sportsList].sort((a, b) => (a.name > b.name ? 1 : -1))
}
export const groupSportsByFirstLetter = (sportsList: Sport[]) => {
  const list = sportsList.reduce((ac: any, a) => {
    const key: string = a.name[0]

    // eslint-disable-next-line no-param-reassign
    ac[key] = (ac[key] || []).concat(a)
    return ac
  }, {})
  return list
}
