import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { ApiStatus } from 'pages/ChannelPage/ChannelSettings/Branding/Logos/UploadLogo/ApiStatus.interface'

export const patchTimezone = async (timezone: string): Promise<ApiStatus> => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'users/edit/time_zone',
      method: ApiEndpoint.METHODS.PATCH,
      params: {
        time_zone: timezone
      },
      authenticatable: true
    })
    await response
    if (response.status == 204) {
      return { isSuccess: true }
    }
    return {
      isSuccess: false,
      message: t`Something went wrong trying to update the timezone`
    }
  } catch (error: any) {
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
