import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './SubmitButton.module.scss'
import { t } from '@lingui/macro'

interface Props {
  label: string
  disabled?: boolean
  onClick?: () => void
  type?: 'primary' | 'secondary' | undefined
  customStyle?: string | null
  isSubmitting?: boolean
  submittingText?: string
}
export const SubmitButton: FC<Props> = ({
  label,
  disabled,
  onClick,
  type,
  customStyle,
  isSubmitting,
  submittingText = t`Saving...`,
  ...props
}) => (
  <button
    data-testid="form-submit-button"
    className={classNames(styles.Button, {
      [styles.Primary]: type === 'primary',
      [styles.Secondary]: type === 'secondary',
      [styles.Disabled]: disabled,
      [customStyle || '']: customStyle
    })}
    type="submit"
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {isSubmitting ? submittingText : label}
  </button>
)
