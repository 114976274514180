/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import { t } from '@lingui/macro'
import React, { FC } from 'react'
import ContentContainer from '../ContentContainer'
import styles from './PagePost.module.scss'

interface PagePostProps {
  headerImageUrl?: string | null
  richText: string
}

export const PagePost: FC<PagePostProps> = ({ headerImageUrl, richText }) => (
  <ContentContainer
    hasLeftRightPadding
    hasTopBottomPadding
    hasLightBackground={false}
  >
    <div className={styles.PagePost}>
      {!!headerImageUrl && <img src={headerImageUrl} alt={t`Post`} />}
      <div
        className={styles.PagePostContent}
        dangerouslySetInnerHTML={{ __html: richText }}
      />
    </div>
  </ContentContainer>
)
