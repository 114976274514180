import { Trans } from '@lingui/macro'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { getEventsForAssociation } from '../../../api/channel/getEventsForAssociation'
import { getEventsForDistrict } from '../../../api/channel/getEventsForDistrict'
import { useDataApi } from '../../../api/useDataApi'
import { ChannelListItem } from '../../../components/lists/ChannelListItem/ChannelListItem'
import { Loader } from '../../../components/Loader/Loader'
import useInfiniteScroll from '../../../hooks/InfiniteScroll'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { ChannelTypes } from '../channelType'
import styles from './Events.module.scss'

interface EventsProps {
  channelSlug: string
  channelType: ChannelTypes
}
const Events: FC<EventsProps> = ({ channelSlug, channelType }) => {
  const PER_PAGE_DATA = 30
  const [page, setPage] = useState(1)
  const [eventsData, setEventsData] = useState<AssociationClubEventDistrict[]>(
    []
  )
  const infiniteScrollRef = useRef(null)
  const param = useMemo(
    () => ({ slug: channelSlug, page, perPageCount: PER_PAGE_DATA }),
    [page, channelSlug]
  )

  const getEvents =
    channelType === ChannelTypes.association
      ? getEventsForAssociation
      : getEventsForDistrict

  const { isError, isLoading, data } = useDataApi({
    apiCall: useCallback(getEvents, [page]),
    parameters: param
  })

  const isBottomReached = useInfiniteScroll(infiniteScrollRef)

  useEffect(() => {
    if (isBottomReached && data && data.totalCount > page * PER_PAGE_DATA) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isBottomReached, data, page])

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      setEventsData((prev: AssociationClubEventDistrict[]) => [
        ...prev,
        ...data.data
      ])
    }
  }, [isLoading, data, isError])

  const eventsList = () =>
    eventsData &&
    eventsData.length > 0 &&
    eventsData.map((event: AssociationClubEventDistrict) => (
      <ChannelListItem
        key={event.subdomain}
        channelLink={{
          name: event.playpage_title,
          slug: event.subdomain,
          logo: event.profile_image_url
        }}
      />
    ))

  if (isError)
    return (
      <div className={styles.EmptyListMessage}>
        <Trans>error!</Trans>
      </div>
    )

  return (
    <div>
      {isLoading && eventsData.length === 0 && <Loader />}
      {eventsData && eventsData.length > 0 ? (
        <div
          className={styles.EventsListContainer}
          data-testid="events-container"
        >
          {eventsList()}
          <div ref={infiniteScrollRef} />
        </div>
      ) : (
        !isLoading && (
          <div
            className={styles.EmptyListMessage}
            data-testid="no-events-text-container"
          >
            <Trans>There are no events registered yet</Trans>
          </div>
        )
      )}
    </div>
  )
}

export default Events
