import React, { FC } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel'
import { FormValidationError } from '../FormValidationError/FormValidationError'
import styles from './PasswordField.module.scss'

interface Props {
  id: string
  label: string
  placeholder: string
  defaultFocus?: boolean
}

export const PasswordField: FC<Props> = ({
  id,
  label,
  placeholder,
  defaultFocus = false
}) => {
  const [field, meta] = useField(id)
  return (
    <div className={styles.PasswordContainer} data-testid={id}>
      <FormFieldLabel label={label} />
      <input
        className={classNames(styles.PasswordField, {
          [styles.Error]: meta.error
        })}
        type="password"
        placeholder={placeholder}
        id={id}
        data-testid={'password-input'}
        {...field}
        autoFocus={defaultFocus}
      />
      {meta.touched && meta.error && (
        <FormValidationError errorMessage={meta.error} />
      )}
    </div>
  )
}
