import React, { FC, useCallback, useMemo } from 'react'
import { getCategoryLeaderListToShow } from './getCategoryLeaderListToShow'
import { CategoryLeaderSection } from './CategoryLeaderSection/CategoryLeaderSection'
import { PlayerStatisticsTableSection } from './PlayerStatisticsTableSection/PlayerStatisticsTableSection'
import { useSelector } from 'react-redux'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'

import ContentContainer from 'components/ContentContainer'
import { Trans } from '@lingui/macro'
import { Loader } from 'components/Loader/Loader'
import { useDataApi } from 'api/useDataApi'
import { getPlayerStatisticsForLeague } from 'api/gameStatistics/getPlayerStatisticsForLeague'
import styles from './PlayerStatistics.module.scss'

interface Props {
  selectedEventName: string
  selectedEventId: string
}

export const PlayerStatsSection: FC<Props> = ({
  selectedEventName,
  selectedEventId
}) => {
  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getPlayerStatisticsForLeague, []),
    parameters: useMemo(
      () => ({ leagueId: selectedEventId }),
      [selectedEventId]
    )
  })
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    return (
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={false}
        hasLightBackground={false}
      >
        <div className={styles.Error}>
          <Trans>
            Something went wrong when trying to get the player statistics.
          </Trans>
        </div>
      </ContentContainer>
    )
  }
  if (data) {
    return (
      <div className={styles.StatsWrapper}>
        <CategoryLeaderSection
          playerStatisticsData={data}
          categoryLeaderList={getCategoryLeaderListToShow(
            isSwedishHandball,
            data?.integration?.name
          )}
        />
        <PlayerStatisticsTableSection
          selectedEventName={selectedEventName}
          playerStatistics={data}
        />
      </div>
    )
  }
  return null
}
