import React, { FC } from 'react'
import styles from './GameScheduleCardPreLoader.module.scss'
import classNames from 'classnames'

export const GameScheduleCardPreLoader: FC = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={classNames(styles.Loader, styles.InnerContainer)} />
      </div>
    </div>
  )
}
