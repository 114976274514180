import { t } from '@lingui/macro'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

export const getTranslatedChannelTypeIndefinite = (
  channelType: ChannelTypes
) => {
  switch (channelType) {
    case ChannelTypes.association:
      return t({ message: 'association', id: 'relatedChannel__association' })
    case ChannelTypes.district:
      return t({ message: 'district', id: 'relatedChannel__district' })
    case ChannelTypes.event:
      return t({ message: 'event', id: 'relatedChannel__event' })
    case ChannelTypes.club:
      return t({ message: 'club', id: 'relatedChannel__club' })
    case ChannelTypes.league:
      return t({ message: 'league', id: 'relatedChannel__league' })
    case ChannelTypes.team:
      return t({ message: 'team', id: 'relatedChannel__team' })
    default:
      return ''
  }
}
