import { callDatoCMS } from '../DatoCMS'

const createContentQuery = (channel: string, locale: string) => {
  return `
  query RecommendedContentQuery {
    recommendedContent(filter: {channelId: {eq: "${channel}"}}, locale: ${locale}) {
      channelId
      contentLinks {
        id
        title
        link
        thumbnail {
          url
        }
        updatedAt
      }
      updatedAt
    }
  }
  `
}

export const getRecommendedContent = async (
  channel: string,
  locale: string
) => {
  const query = createContentQuery(channel, locale)
  const data = await callDatoCMS(query)
  const recommendedContent = data?.recommendedContent?.contentLinks

  return recommendedContent
}
