import React, { FC } from 'react'
import styles from './SearchResultCard.module.scss'

interface Props {
  list: string[]
}

const formatText = (content: string[]) => {
  let formated: string | null = null
  content.forEach((text) => {
    if (text && text !== '') {
      if (formated) {
        formated += ` • ${text}`
      } else {
        formated = text
      }
    }
  })
  return formated
}

const DotList: FC<Props> = ({ list }) => {
  return <div className={styles.BottomFrame}>{formatText(list)}</div>
}

export default DotList
