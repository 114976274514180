import React, { FC, useState } from 'react'
import styles from './AccountHeader.module.scss'
import { SolidsportAccountLogo } from 'assets/brandLogos/SolidsportAccount'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import HamburgerMenuIcon from '../components/HamburgerMenuIcon'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import { UserInfoSection } from 'components/UserInfoSection/UserInfoSection'
import useCurrentUser from 'hooks/CurrentUser'
import { MobileNavigationMenu } from 'components/MobileNavigationMenu/MobileNavigationMenu'
import { UserAccountMenuLinks } from 'pages/UserAccountPage/UserAccountMenuLinks'
import { onHelpClick } from 'features/viewerPage/helpFunctions/onHelpClick'

export const AccountHeader: FC = () => {
  const currentBreakpoint = useCurrentBreakpoint()
  const [showMobileMenuNavigation, setShowMobileMenuNavigation] =
    useState(false)
  const isMobile = currentBreakpoint <= Breakpoint.sm

  const currentUser = useCurrentUser()

  return (
    <div className={styles.AccountHeaderContainer}>
      <div className={styles.Logo} data-testid="account-header-logo">
        <SolidsportAccountLogo />
      </div>

      {isMobile ? (
        <IconButton
          id="hamburger-menu-button"
          onClick={() => setShowMobileMenuNavigation(true)}
          customStyle={styles.HamburgerMenuIcon}
        >
          <HamburgerMenuIcon />
        </IconButton>
      ) : (
        <>
          {currentUser &&
            currentUser.firstname &&
            currentUser.lastname &&
            currentUser.email && (
              <UserInfoSection
                firstName={currentUser.firstname}
                lastName={currentUser.lastname}
                email={currentUser.email}
              />
            )}
        </>
      )}
      {currentUser && (
        <MobileNavigationMenu
          isOpen={showMobileMenuNavigation}
          menuLinks={UserAccountMenuLinks}
          currentUser={currentUser}
          onCloseModal={() => setShowMobileMenuNavigation(false)}
          onHelpClick={onHelpClick}
        />
      )}
    </div>
  )
}
