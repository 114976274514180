import { metaFriendlyString } from 'helpers/generalHelpers'

export const articleData = (
  title: string,
  image?: string | undefined | null
): string => {
  let data: any = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: `${metaFriendlyString(title)}`
  }
  if (image) {
    data = {
      ...data,
      image: [image]
    }
  }
  return JSON.stringify(cleanData(data))
}

const cleanData = (obj: any) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (obj[propName] instanceof Array && obj[propName].length === 0)
    ) {
      delete obj[propName]
    }
  }
  return obj
}
