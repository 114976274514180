import React, { FC } from 'react'

export const PlayIcon: FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8861 10.2912L6.00693 4.5874C5.94027 4.54891 5.85693 4.59702 5.85693 4.674V16.0817C5.85693 16.1586 5.94027 16.2067 6.00693 16.1683L15.8861 10.4644C15.9527 10.4259 15.9527 10.3297 15.8861 10.2912Z"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)
