import { TimelineObjectPath } from '../../helpers/PathHelper'
import { TimelineObject } from '../../interfaces'
import { News } from '../../interfaces/News.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getNewsForChannel = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/articles',
      params: {
        company: slug,
        per_page: perPageCount,
        page: page,
        include_games_without_livestreams: true
      }
    })
    const responseData = await resultResponse
    const mapped = mapNewsResponse(responseData.data)
    return { data: mapped, totalCount: responseData.totalCount }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapNewsResponse = (apiResponse: TimelineObject[]): News[] => {
  return apiResponse.map((news: TimelineObject) => ({
    id: news.ident,
    title: news.title,
    preamble: news.description,
    imageUrl: news.thumbnail,
    published: news.published_at,
    company: news.display_company.name,
    companySlug: news.display_company.subdomain,
    sport: news.sport.name,
    country: news.country,
    targetUrl: TimelineObjectPath(news)
  }))
}
