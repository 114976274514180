import { RootState } from '../store'

export const reportContentDialogIsOpenSelector = (state: RootState) =>
  state.userInterface.reportContentModalIsOpen

export const broadcastInformationModalIsOpenSelector = (state: RootState) =>
  state.userInterface.broadcastInformationModalIsOpen

export const quikEditDialogIsOpenSelector = (state: RootState) =>
  state.userInterface.quickEditModalIsOpen

export const paymentDialogIsOpenSelector = (state: RootState) =>
  state.userInterface.paymentModalIsOpen

export const shareContentModalIsOpenSelector = (state: RootState) =>
  state.userInterface.shareContentModalIsOpen

export const isViewingStreamSelector = (state: RootState) =>
  state.userInterface.isViewingStream

export const isViewingDvrFromBeginningSelector = (state: RootState) =>
  state.userInterface.viewDvrFromBeginning
