import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'

import { FormButtons } from '../FormButtons/FormButtons'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'

import styles from './ImageLinkForm.module.scss'
import { FileSelector } from '../InfoBoxForm/FileSelector'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { imageLinkSchema } from './imageLinkValidationSchema'
import { postImageLink } from 'api/customContent/postImageLink'

export interface ImageLink {
  imageLinkUrl: string
  imageFile?: any
  imageFileUrl: string
  openInNewTab: boolean
}

interface Props {
  onCancel: () => void
  onSubmitSuccess: () => void
  channelSlug: string
  existingForm?: ImageLink
}

export const ImageLinkForm: FC<Props> = ({
  onCancel,
  onSubmitSuccess,
  channelSlug,
  existingForm
}) => {
  const [apiError, setApiError] = useState<string | undefined>()

  const initialValues: ImageLink = {
    imageLinkUrl: existingForm?.imageLinkUrl || '',
    imageFile: undefined,
    openInNewTab: existingForm?.openInNewTab || false,
    imageFileUrl: existingForm?.imageFileUrl || ''
  }

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)

    const formValues = { ...values, channelSlug }
    const response = await postImageLink({
      ...formValues
    })

    if (response.hasError) {
      setApiError(
        t`There was a problem processing your request, please try again. If the data you entered appears to be correct, contact our support team for more help`
      )
      formikHelpers.setSubmitting(false)
    } else {
      formikHelpers.setSubmitting(false)
      onSubmitSuccess()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={imageLinkSchema}
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
    >
      {(props: FormikProps<ImageLink>) => {
        const onOpenInNewTabChange = () => {
          props.setFieldValue('openInNewTab', !props.values.openInNewTab)
          props.setFieldTouched('openInNewTab', true, false)
        }
        return (
          <Form>
            <div className={styles.ImageLinkForm}>
              <FileSelector
                formFieldName="imageFile"
                fileUrlFieldName="imageFileUrl"
                formik={props}
                recommendedImageHeight="300px"
                recommendedImageWidth="1222px"
              />
              <div className={styles.TextInput}>
                <Field
                  component={TextInput}
                  id="imageLinkUrl"
                  name="imageLinkUrl"
                  label={t`Image link`}
                  placeholder="https://link.com/link"
                  onChange={props.handleChange}
                  textValue={props.values.imageLinkUrl}
                  isValid={!props.errors.imageLinkUrl}
                  validationMessage={props.errors.imageLinkUrl}
                />
              </div>
              <div className={styles.NewTabToggle}>
                <ToggleSwitch
                  isOn={props.values.openInNewTab}
                  id="openInNewTab"
                  label={t`Open in new tab`}
                  onToggle={onOpenInNewTabChange}
                />
              </div>
              <div className={styles.ImageLinkForm}>
                {apiError && (
                  <div className={styles.FormError}>
                    <FormValidationError errorMessage={apiError} />
                  </div>
                )}
                <FormButtons formik={props} onCancelClick={onCancel} />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
