import React, { FC } from 'react'
import styles from './BuyOption.module.scss'

interface BuyOptionBannerProps {
  cost: string
  description: string
  onSelect: () => void
  children?: React.ReactNode
}
const BuyOptionBanner: FC<BuyOptionBannerProps> = ({
  cost,
  description,
  children,
  onSelect
}) => {
  const onClick = () => {
    onSelect()
  }
  return (
    <div
      data-testid="buy-banner-container"
      className={styles.BuyOptionBannerContent}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
    >
      {children}
      <div className={styles.TextSection} data-testid="buy-banner-text-section">
        <div
          className={styles.BannerAmounText}
          data-testid="buy-banner-text-cost"
        >
          {cost}
        </div>
        <div
          className={styles.BannerDescriptionText}
          data-testid="buy-banner-text-description"
        >
          {description}
        </div>
      </div>
    </div>
  )
}

export default BuyOptionBanner
