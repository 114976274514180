import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { TwoColumModal } from 'features/customContent/NewContentModal/TwoColumnModal'
import { GeneralStreamInformationForm } from './GeneralStreamInformationForm/GeneralStreamInformationForm'
import styles from './QuickEditModal.module.scss'
import { Toggle } from 'components/Toggle/Toggle'
import { StreamAccessForm } from './StreamAccessForm/StreamAccessForm'

interface Props {
  defaultOpen: boolean
  onClose: () => void
}

export const QuickEditModal: FC<Props> = ({ defaultOpen, onClose }) => {
  const [showGeneralForm, setShowGeneralForm] = useState(true)
  return (
    <TwoColumModal
      defaultOpen={defaultOpen}
      onClose={onClose}
      modalHeader={t`Edit stream`}
    >
      <div className={styles.QuickEditModal}>
        <div className={styles.ToggleButtonContainer}>
          <Toggle
            firstLabel={t`General`}
            firstButtonSelected={showGeneralForm}
            secondLabel={t`Access/Payment`}
            onClick={() => setShowGeneralForm(!showGeneralForm)}
          />
        </div>
        {showGeneralForm ? (
          <GeneralStreamInformationForm onClose={onClose} />
        ) : (
          <StreamAccessForm onClose={onClose} onSubmitSuccess={onClose} />
        )}
      </div>
    </TwoColumModal>
  )
}
