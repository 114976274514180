import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useCountdownTime = (timestamp: number = Infinity) => {
  const [startTime, setStartTime] = useState(timestamp)
  const newCountdownMessage = useSelector(
    (state: any) => state.subscriber.newCountdownMessage
  )

  useEffect(() => {
    if (newCountdownMessage) {
      const newTime = Math.round(
        Date.now() / 1000 + newCountdownMessage?.start_time
      )
      setStartTime(newTime)
    }
  }, [newCountdownMessage])

  return startTime
}
