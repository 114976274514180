import { useHistory } from 'react-router-dom'

export const HandleUtmSource = () => {
  const history = useHistory()
  if (history && location.search.includes('utm_source')) {
    const currentUrl = new URL(location.toString())
    const utmSource = currentUrl.searchParams.get('utm_source')
    sessionStorage.setItem('utm_source', utmSource!)
    currentUrl.searchParams.delete('utm_source')
    history.replace(currentUrl.pathname + currentUrl.search + currentUrl.hash)
  }
}
