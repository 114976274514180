import { Trans } from '@lingui/macro'
import React, { FC } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import styles from './ComingSoonPage.module.scss'
import SolidsportIcon from './SolidsportIcon'
import { useTheme } from '../ChannelPage/Theme/useTheme'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
interface Props {
  releaseDate: number
  backgroundImageUrl: string
  logoImageUrl: string
  vanityUrl: string
  channelSlug: string
}
const ComingSoonPage: FC<Props> = ({
  releaseDate,
  backgroundImageUrl,
  logoImageUrl,
  vanityUrl,
  channelSlug
}) => {
  const { Theme } = useTheme()
  return (
    <Theme>
      <div
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
        className={styles.ComingSoonPage}
      >
        <div className={styles.BackgroundOverlay} />
        <div className={styles.PoweredByContainer}>
          <div className={styles.PoweredByText}>Powered by</div>
          <div className={styles.IconAndLogo}>
            <a
              href="https://www.solidsport.com/sv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SolidsportIcon />
            </a>
            <div className={styles.VerticalLine} />
            <img
              className={styles.ChannelLogo}
              src={logoImageUrl}
              alt={channelSlug}
            />
          </div>
        </div>
        <div className={styles.BottomContainer}>
          <div className={styles.ReleaseContainer}>
            <div className={styles.ReleaseContent}>
              <div className={styles.ReleaseDate}>
                <div className={styles.UrlContainer}>
                  <div className={styles.UrlText}>{vanityUrl}</div>
                </div>
                <Trans>Release date</Trans>
                <div className={styles.Date}>
                  {dayjs.unix(releaseDate).format('MMMM YYYY')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Theme>
  )
}
export default ComingSoonPage
