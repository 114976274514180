import { CustomPage } from './getCustomPage'

export interface CustomPageApiResult {
  slug: string
  title: string
  content: string
  preamble: string | null
  image_url: string | null
}

export const mapCustomPageResponse = (
  input: CustomPageApiResult
): CustomPage => ({
  slug: input.slug,
  title: input.title,
  content: input.content,
  preamble: input.preamble,
  imageUrl: input.image_url
})
