import classNames from 'classnames'
import React, { FC } from 'react'
import { getTimePassed } from '../../../../helpers/formatHelper'
import styles from './NewsDate.module.scss'

interface NewsDateProps {
  updatedDate: number
}

const NewsDate: FC<NewsDateProps> = ({ updatedDate }) => {
  return (
    <div className={styles.NewsDateContainer}>
      <div
        data-testid="news-thumbnail-date"
        className={classNames(styles.LightSubText)}
      >
        {getTimePassed(updatedDate)}
      </div>
    </div>
  )
}

export default NewsDate
