import { ChannelTypes } from '../../../../../pages/ChannelPage/channelType'

export const showTopNavigationBroadcastLink = (
  rootChannelType: ChannelTypes
) => {
  switch (rootChannelType) {
    case ChannelTypes.association:
    case ChannelTypes.district:
      return true
    case ChannelTypes.league:
    case ChannelTypes.event:
    case ChannelTypes.club:
    case ChannelTypes.team:
      return false
    default:
      return false
  }
}

export const showTopNavigationScheduleLink = (
  rootChannelType: ChannelTypes
) => {
  switch (rootChannelType) {
    case ChannelTypes.association:
    case ChannelTypes.district:
    case ChannelTypes.league:
    case ChannelTypes.event:
      return true
    case ChannelTypes.club:
    case ChannelTypes.team:
      return false
    default:
      return false
  }
}
