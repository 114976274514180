import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import RightArrow from '../../../assets/icons/RightArrow'
import styles from './index.module.scss'
import LeftArrow from '../../../assets/icons/LeftArrow'

export enum Scheme {
  Dark = 'Dark',
  Light = 'Light',
  Red = 'Red',
  Black = 'Black'
}

interface Props {
  newClassName: Scheme
  iconSize: 'small' | 'medium' | 'large'
  onClick?: () => void
  arrowDirection?: 'left' | 'right'
}
const RoundIconButton: FC<Props> = ({
  newClassName,
  iconSize = 'medium',
  arrowDirection = 'right',
  ...props
}) => {
  const sizeStyle = `IconButton--${iconSize}`
  const iconFontMap = {
    small: 16,
    medium: 24,
    large: 56
  }
  const iconFontSize = iconFontMap[iconSize]
  return (
    <IconButton
      aria-label="Go to another page"
      className={[styles[newClassName], styles[sizeStyle]].join(' ')}
      {...props}
    >
      {arrowDirection === 'right' ? (
        <RightArrow height={`${iconFontSize}px`} width={`${iconFontSize}px`} />
      ) : (
        <LeftArrow height={`${iconFontSize}px`} width={`${iconFontSize}px`} />
      )}
    </IconButton>
  )
}

RoundIconButton.defaultProps = {
  arrowDirection: 'right',
  onClick: () => null
}
export default RoundIconButton
