import dayjs from 'dayjs'

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

import { ActivityLog } from '../../../../interfaces'

export const getActivityTime = (activityLog: ActivityLog) => {
  if (activityLog.game_time === '0') {
    return ''
  }
  const elapsedMilliseconds = Number(activityLog.game_time) * 1000
  const duration = dayjs.duration(elapsedMilliseconds)
  const minutes = duration.asMinutes()

  const minutesString = Math.floor(minutes).toString()
  const minutesDisplay =
    minutesString.length === 1
      ? '00'.concat(minutesString).slice(-2)
      : minutesString

  const seconds = duration.seconds().toString()
  const secondsDisplay = '00'.concat(seconds).slice(-2)

  return `${minutesDisplay}:${secondsDisplay}`
}
