import { plural, t } from '@lingui/macro'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import { BroadcastDetailsType } from './index'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
const renderViewersText = (viewers: number, showViewers: boolean) => {
  if (showViewers) {
    return plural(viewers, { one: '# viewer', other: '# viewers' })
  }
  return ''
}
const renderViewsText = (showViews: boolean, views: number) => {
  if (showViews) {
    return plural(views, { one: '# view', other: '# views' })
  }
  return ''
}
type RowType = Array<{ key: string; value: any }>

const renderTimeStamp = (highlightFromDate: number, endTime: number) => {
  if (highlightFromDate) {
    return dayjs.unix(highlightFromDate).fromNow()
  }
  return dayjs.unix(endTime).fromNow()
}
export const getDetailsContent = (
  broadcastType: BroadcastDetailsType,
  live: boolean,
  showViewers: boolean,
  viewers: number,
  views: number,
  showViews: boolean,
  highlightFromDate: number,
  publishedAt: number,
  endTime: number,
  typeOfGame: string,
  hasHighlight: boolean,
  replay: boolean,
  eventName?: string,
  locationName?: string,
  categoryName?: string
) => {
  const result: RowType = []
  switch (broadcastType) {
    case BroadcastDetailsType.Broadcast:
      if (categoryName) {
        result.push({
          key: 'Category',
          value: categoryName
        })
      }
      if (live) {
        result.push({
          key: 'BroadcastType',
          value: t({ id: 'thumbnail/detailsRow', message: 'Broadcast' })
        })

        if (showViewers) {
          result.push({
            key: 'Viewers',
            value: renderViewersText(viewers, showViewers)
          })
        }
      } else if (highlightFromDate) {
        result.push({
          key: 'Highlight',
          value: t`Highlight`
        })
        result.push({
          key: 'Timestamp',
          value: renderTimeStamp(highlightFromDate, endTime)
        })
      } else {
        result.push({
          key: 'BroadcastType',
          value: t({ id: 'thumbnail/detailsRow', message: 'Broadcast' })
        })
      }

      if (showViews && !live) {
        result.push({
          key: 'ViewsText',
          value: renderViewsText(showViews, views)
        })
      }
      break
    case BroadcastDetailsType.Game:
      if (eventName) {
        result.push({ key: 'EventName', value: eventName })
      }
      if (live) {
        if (typeOfGame) {
          result.push({
            key: 'Game',
            value: typeOfGame
          })
        } else {
          result.push({
            key: 'Game',
            value: t`Game`
          })
        }
        if (showViewers) {
          result.push({
            key: 'ViewersText',
            value: renderViewersText(viewers, showViewers)
          })
        }
      } else if (replay && hasHighlight) {
        result.push({
          key: 'Timestamp',
          value: renderTimeStamp(highlightFromDate, endTime)
        })
        result.push({
          key: 'higlights',
          value: t`1 highlights`
        })
      } else if (replay && !hasHighlight) {
        result.push({
          key: 'Timestamp',
          value: renderTimeStamp(highlightFromDate, endTime)
        })
      } else if (typeOfGame) {
        result.push({
          key: 'TypeOfGame',
          value: typeOfGame
        })
      } else {
        if (!eventName) {
          result.push({
            key: 'TypeOfGame',
            value: t`Game`
          })
        }
        if (locationName) {
          result.push({
            key: 'Location',
            value: locationName
          })
        }
      }
      if (showViews && !live) {
        result.push({
          key: 'Views',
          value: renderViewsText(showViews, views)
        })
      }

      break
    case BroadcastDetailsType.Highlight:
      result.push({
        key: 'Higlight',
        value: t`Highlight`
      })
      result.push({
        key: 'Timestamp',
        value: renderTimeStamp(highlightFromDate, endTime)
      })
      if (showViews) {
        result.push({
          key: 'Views',
          value: renderViewsText(showViews, views)
        })
      }
      break
    case BroadcastDetailsType.Video:
      if (categoryName) {
        result.push({
          key: 'Category',
          value: categoryName
        })
      }
      if (!categoryName) {
        result.push({
          key: 'Video',
          value: t`Video`
        })
      }
      result.push({
        key: 'Timestamp',
        value: renderTimeStamp(publishedAt, endTime)
      })
      if (showViews) {
        result.push({
          key: 'Views',
          value: renderViewsText(showViews, views)
        })
      }
      break
    case 'Default':
      result.push({
        key: 'Empty',
        value: ''
      })
      break
    default:
      if (replay && hasHighlight) {
        result.push({
          key: 'Timestamp',
          value: renderTimeStamp(highlightFromDate, endTime)
        })
        result.push({
          key: 'higlights',
          value: t`1 highlights`
        })
        result.push({
          key: 'Views',
          value: renderViewsText(showViews, views)
        })
      } else if (replay && !hasHighlight) {
        result.push({
          key: 'Timestamp',
          value: renderTimeStamp(highlightFromDate, endTime)
        })
        result.push({
          key: 'Views',
          value: renderViewsText(showViews, views)
        })
      } else {
        result.push({
          key: 'TypeOfGame',
          value: typeOfGame
        })
      }
  }
  return result
}
