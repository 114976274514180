import { codes } from './imaEventCodes'
import { VideoAdsType } from './videoAdsType'

export const noVideoAds: VideoAdsType = {
  statusCode: 'adError',
  adsLoaded: false,
  remainingTime: 0,
  currentPosition: 1,
  totalAds: 1,
  maxDuration: 0,
  playing: false,
  adsReady: false,
  nonLinear: false,
  codes,
  pause: () => null,
  resume: () => null,
  setFullscreen: () => null
}
