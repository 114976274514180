const datoCMSToken: string = process.env.REACT_APP_DATOCMS_TOKEN!
const datoCMSUrl: string = process.env.REACT_APP_DATOCMS_URL!
const datoCMSEnv: string = process.env.REACT_APP_DATOCMS_ENV!

export const callDatoCMS = async (query: string, variables: any = null) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${datoCMSToken}`)
  headers.append('x-environment', datoCMSEnv)
  const body = JSON.stringify({ query, variables })
  const options = {
    headers,
    method: 'post',
    body
  }

  const response = await fetch(datoCMSUrl, options)

  if (!response.ok) {
    throw new Error(`Request to DatoCMS failed with code ${response.status}`)
  }

  const result = await response.json()
  const data = result?.data

  return data
}
