import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { TitleThumbnailSection } from '../../../../components/content/TitleThumbnailSection/TitleThumbnailSection'

interface Props {
  promotedCategoryContent: any[]
  categoryTitle: string
  thumbnailSize: any
  isLoading: boolean
}

export const PromotedCategorySlider: FC<Props> = ({
  promotedCategoryContent,
  categoryTitle,
  thumbnailSize,
  isLoading
}) => {
  return (
    <TitleThumbnailSection
      getInfiniteScrollRef={undefined}
      thumbnailContent={promotedCategoryContent}
      sectionTitle={categoryTitle}
      showAllLinkPath={undefined}
      linkLabel={t`All`}
      thumbnailsCardIsShared={false}
      size={thumbnailSize}
      useLeftPadding
      isLoading={isLoading}
    />
  )
}
