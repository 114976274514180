import React, { FC } from 'react'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import styles from './AccordionItem.module.scss'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon } from 'components/ChannelListItem/ExternalLinkIcon'

import { RightArrowIcon } from 'components/ChannelListItem/RightArrowIcon'

interface Props {
  headerLink?: string
  headerText: string
  href?: string
  hrefTarget?: LinkTarget
}

export const AccordionItemLink: FC<Props> = ({
  headerLink,
  headerText,
  href,
  hrefTarget
}) => {
  if (href && hrefTarget) {
    if (hrefTarget === LinkTarget.NewWindow)
      return (
        <Link
          className={styles.HeaderLink}
          to={{ pathname: href }}
          target={hrefTarget}
        >
          <div className={styles.HrefLink}>
            {headerText}
            {hrefTarget === LinkTarget.NewWindow && <ExternalLinkIcon />}
          </div>
        </Link>
      )
    if (hrefTarget === LinkTarget.CurrentWindow)
      return (
        <a className={styles.HeaderLink} href={href}>
          <div className={styles.HrefLink}>
            {headerText} <RightArrowIcon />
          </div>
        </a>
      )
  }
  if (headerLink) {
    return (
      <Link className={styles.HeaderLink} to={headerLink}>
        <div className={styles.HrefLink}>
          {headerText}
          <RightArrowIcon />
        </div>
      </Link>
    )
  }
  return <div>{headerText}</div>
}
