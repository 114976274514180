/* eslint-disable no-unused-expressions */
import React, {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'
import { Subtitle } from '../../../interfaces/Subtitle.interface'

// /**
//  * @typedef {object} VideoElementProps'
// */
type VideoElementProps = {
  poster?: string
  subtitles?: Subtitle[] | undefined
  ident?: string
}

// /**
//  * VideoElement
//  * @desc Memoized wrapper for video element.
//  * @category video components
//  *
//  * @example
//  * return (
//  *   <VideoElement poster={...} />
//  * )
//  */

export type RefObject = {
  element: () => void
}

const VideoElement = (props: VideoElementProps, ref: Ref<RefObject>) => {
  const { poster, subtitles, ident } = props
  const elementRef = useRef<any>()

  useImperativeHandle(ref, () => ({ element }))
  const element = () => {
    return elementRef.current
  }

  useEffect(() => {}, [ident])

  const videoProps = () => {
    return {
      'webkit-playsinline': 'true',
      'x-webkit-airplay': 'allow'
    }
  }

  return (
    <>
      <video
        ref={elementRef}
        itemType="application/vnd.apple.mpegURL"
        style={{
          width: '100%',
          height: '100%',
          minWidth: '100%',
          minHeight: '100%'
        }}
        poster={poster}
        preload="none"
        playsInline
        {...videoProps()}
      >
        {subtitles &&
          subtitles.map((subtitle: Subtitle) => (
            <track
              label={subtitle.language}
              kind="subtitles"
              srcLang={subtitle.lang}
              key={subtitle.lang}
              src={subtitle.url}
            />
          ))}
      </video>
    </>
  )
}

export default memo(forwardRef(VideoElement))
