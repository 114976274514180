import React, { FC } from 'react'

const NewspaperFold: FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.25 6V20.25C23.25 20.8467 23.0129 21.419 22.591 21.841C22.169 22.2629 21.5967 22.5 21 22.5C20.4033 22.5 19.831 22.2629 19.409 21.841C18.9871 21.419 18.75 20.8467 18.75 20.25V3C18.75 2.60218 18.592 2.22064 18.3107 1.93934C18.0294 1.65804 17.6478 1.5 17.25 1.5H2.25C1.85218 1.5 1.47064 1.65804 1.18934 1.93934C0.908035 2.22064 0.75 2.60218 0.75 3V20.25C0.75 20.8467 0.987053 21.419 1.40901 21.841C1.83097 22.2629 2.40326 22.5 3 22.5H21"
        stroke="#93979f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15H15"
        stroke="#93979f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 18H10.5"
        stroke="#93979f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 5.25H15V11.25H4.5V5.25Z"
        stroke="#93979f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NewspaperFold
