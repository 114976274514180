import * as yup from 'yup'
import { t } from '@lingui/macro'

export const infoBoxSchema = () =>
  yup.object({
    title: yup.string().required(t`Please enter the title of the info box`),
    text: yup.object(),
    textField: yup.string(),
    linkField: yup
      .string()
      .url(t`Please enter web link in the format https://domain.com/path`),
    target: yup.string()
  })
