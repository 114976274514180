import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { UPLOAD_TYPE } from '../../../../../components/DropFileZone/UploadType'
import { UploadLogo } from './UploadLogo/UploadLogo'
import { ApiStatus } from './UploadLogo/ApiStatus.interface'

import styles from './Logos.module.scss'

interface Props {
  channelLogoUrl: string
  onChannelLogoUpload: (file: File) => Promise<ApiStatus>
  secondaryLogoUrl: string
  onSecondaryLogoUpload: (file: File) => Promise<ApiStatus>
  faviconUrl: string
  onFaviconUpload: (file: File) => Promise<ApiStatus>
}

export const Uploaders: FC<Props> = ({
  secondaryLogoUrl,
  channelLogoUrl,
  faviconUrl,
  onChannelLogoUpload,
  onSecondaryLogoUpload,
  onFaviconUpload
}) => (
  <div className={styles.LogosContent}>
    <UploadLogo
      label={t`Navigation logo`}
      onLogoUpload={onSecondaryLogoUpload}
      initialImageSrc={secondaryLogoUrl}
      variant={UPLOAD_TYPE.IMAGE}
      isThemed
      recommendedDimensions={{
        width: 200,
        height: 40
      }}
    />
    <UploadLogo
      label={t`Channel logo`}
      onLogoUpload={onChannelLogoUpload}
      initialImageSrc={channelLogoUrl}
      variant={UPLOAD_TYPE.IMAGE}
      isThemed={false}
      recommendedDimensions={{
        width: 100,
        height: 100
      }}
    />
    <UploadLogo
      label="Favicon"
      onLogoUpload={onFaviconUpload}
      initialImageSrc={faviconUrl}
      variant={UPLOAD_TYPE.ICON}
      isThemed={false}
      recommendedDimensions={{
        width: 32,
        height: 32
      }}
    />
  </div>
)
