import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './InfoSection.module.scss'
import IconTitleText from './IconTitleText/IconTitleText'

import { InfoSection } from './InfoSection.interface'

interface Props {
  sectionData: InfoSection
}

const InfoSectionComponent: FC<Props> = ({ sectionData }) => {
  const { content, backgroundColor } = sectionData

  return (
    <div
      className={classNames(
        styles.InfoSectionContainer,
        styles[backgroundColor]
      )}
    >
      <div className={styles.InfoSectionHeading} data-testid="section-title">
        {sectionData.sectionTitle}
      </div>
      <div className={styles.InfoItemsContainer} data-testid="section-content">
        {content.map((contentItem) => (
          <IconTitleText
            key={contentItem.id}
            iconPath={contentItem.iconPath}
            title={contentItem.title}
            text={contentItem.text}
          />
        ))}
      </div>
    </div>
  )
}
export default InfoSectionComponent
