import { t } from '@lingui/macro'
import { CustomContentAccordionListId } from '../customContentAccordionList'

const heroImageExample =
  'http://www.solidsport.com/wp-content/uploads/2023/11/heroImageExample.jpg'
const startPageBannerDesktop =
  'http://www.solidsport.com/wp-content/uploads/2023/11/startPageBannerDesktop.jpg'
const startPageBannerMobile =
  'http://www.solidsport.com/wp-content/uploads/2023/11/startPageBannerMobile.jpg'

export const getInformationModalContent = (
  informationType: CustomContentAccordionListId
) => {
  switch (informationType) {
    case CustomContentAccordionListId.StartPageBanner:
      return {
        header: t`Start page banner`,
        textContent: t`With start page banner, it is possible for you to add a info box or an image link to the start page. `,
        imageUrl: startPageBannerDesktop,
        mobileImageUrl: startPageBannerMobile
      }
    case CustomContentAccordionListId.HeroImagePromotion:
      return {
        header: t`Hero image promotion`,
        textContent: t`With hero image promotion it is possible for you to promote content on top of your hero image.`,
        imageUrl: heroImageExample,
        mobileImageUrl: ''
      }
    default:
      return {
        header: '',
        textContent: '',
        imageUrl: '',
        mobileImageUrl: ''
      }
  }
}
