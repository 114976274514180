const STREAM = {
  STREAM_FETCH_START: 'STREAM_FETCH_START',
  STREAM_FETCH_SUCCESS: 'STREAM_FETCH_SUCCESS',
  STREAM_FETCH_ERROR: 'STREAM_FETCH_ERROR',
  STREAM_FETCH_404_ERROR: 'STREAM_FETCH_404_ERROR',
  STREAM_MAKE_VOUCHER_PAYMENT_START: 'STREAM_MAKE_VOUCHER_PAYMENT_START',
  STREAM_MAKE_VOUCHER_PAYMENT: 'STREAM_MAKE_VOUCHER_PAYMENT',
  STREAM_MAKE_VOUCHER_PAYMENT_ERROR: 'STREAM_MAKE_VOUCHER_PAYMENT_ERROR',
  CLEAR_STREAM: 'CLEAR_STREAM',
  INCREMENT_STREAM_USER_LIKE_COUNT: 'INCREMENT_STREAM_USER_LIKE_COUNT',
  DECREMENT_STREAM_USER_LIKE_COUNT: 'DECREMENT_STREAM_USER_LIKE_COUNT',
  STREAM_IS_LIVE: 'STREAM_IS_LIVE',
  UPDATE_START_TIME: 'UPDATE_START_TIME'
}

export default STREAM
