import React, { FC } from 'react'
import styles from './FormSectionTitle.module.scss'

interface Props {
  title: string
}

export const FormSectionTitle: FC<Props> = ({ title }) => (
  <div className={styles.SectionTitle}>{title}</div>
)
