import React from 'react'

const SolidsportLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_107)">
      <path
        d="M72.6559 5.43021C69.6525 6.57061 66.7395 7.93614 63.9417 9.51514C60.9943 11.1115 58.3617 13.2304 56.1722 15.7685C52.969 19.6105 51.6404 23.1436 52.1862 26.3678C52.8881 30.6836 56.2712 34.1118 62.3356 36.6521C64.588 37.5519 69.0449 38.9015 75.7061 40.701C82.3674 42.5006 87.2741 44.0437 90.4263 45.3303C94.6252 47.0309 97.8074 49.0449 99.9728 51.3723C99.9728 50.9224 100.009 50.4725 100.009 50.0226C100.013 40.7887 97.4589 31.7341 92.6308 23.863C87.8027 15.9918 80.8888 9.61174 72.6559 5.43021Z"
        fill="#231F20"
      />
      <path
        d="M12.8891 67.0732C15.5254 60.4749 19.2744 55.4902 24.1362 52.1191C25.1619 51.4982 25.9642 51.2208 26.5431 51.2868C27.1189 51.3453 26.7725 52.1146 25.5173 53.6037C22.9208 56.3944 20.8169 59.6057 19.2954 63.1007C16.279 70.0194 15.3411 77.6664 16.5961 85.109C16.7503 85.9919 16.9515 86.8659 17.199 87.7273C23.194 92.9434 30.3464 96.6548 38.0627 98.5538C45.779 100.453 53.8369 100.485 61.568 98.6465C69.299 96.8084 76.4804 93.1534 82.5164 87.9848C88.5524 82.8161 93.269 76.2828 96.2749 68.9267C96.4762 67.2205 96.4413 65.4947 96.1714 63.798C95.3407 58.8133 92.3759 54.7929 87.2773 51.7367C84.548 50.237 79.2004 48.5065 71.2344 46.545C63.2685 44.5835 57.8699 42.9744 55.0386 41.7178C48.7673 38.8295 45.2027 34.7836 44.3449 29.5799C43.5951 25.0391 45.3946 20.1894 49.7435 15.0307C54.0924 9.87204 60.1958 5.98504 68.0538 3.36972C57.9356 -0.556116 46.8149 -1.08319 36.3708 1.8681C25.9267 4.81939 16.7265 11.0888 10.1595 19.7295C3.59243 28.3703 0.0151643 38.9132 -0.032118 49.7661C-0.0794002 60.619 3.40587 71.1927 9.89738 79.8903C10.0458 76.0303 11.0266 71.7564 12.8891 67.0732Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_107">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SolidsportLogo
