import React, { FC } from 'react'
import { t } from '@lingui/macro'
import EditIcon from '../../../../assets/icons/EditIcon'

import { getAdminPath } from 'helpers/getAdminPath'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'

interface Props {
  id: string
  channelSlug: string
}
export const AdminEditPastGameButtons: FC<Props> = ({ id, channelSlug }) => {
  const adminPath = getAdminPath()
  return (
    <LinkIconButton
      type={ButtonType.Ghost}
      buttonSize="Small"
      label={t`Edit`}
      id="edit"
      href={`${adminPath}/admin/games/${id}/edit?select_company=${channelSlug}`}
    >
      <EditIcon />
    </LinkIconButton>
  )
}
