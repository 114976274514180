import PAYMENTS from '../../../../redux-store/payments/payments.constants'
import addCardCustomText from './addCard'
import { CustomText } from './CustomText.interface'

const getCustomText = (
  paymentType: PAYMENTS,
  locale: string
): CustomText | undefined => {
  switch (paymentType) {
    case PAYMENTS.TYPE_ADD_CARD:
      return addCardCustomText(locale)
    default:
      return undefined
  }
}

export default getCustomText
