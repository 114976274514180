import React, { FC } from 'react'
import styles from './index.module.scss'
import { SponsorContent } from './SponsorContentInterface'
import SponsorItem from './SponsorItem/SponsorItem'

interface Props {
  sponsorContent: SponsorContent[]
}

const SponsorSlider: FC<Props> = ({ sponsorContent }) => {
  return (
    <div className={styles.SponsorSlider} data-testid="sponsor-slider">
      <div className={styles.Sponsors}>
        {sponsorContent.map((item) => (
          <SponsorItem
            key={item.sponsorName}
            imgPath={item.imagePath}
            urlPath={item.urlPath}
            sponsorName={item.sponsorName}
          />
        ))}
      </div>
    </div>
  )
}
export default SponsorSlider
