import { CustomPage } from 'api/channel/customPages/getCustomPage'
import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'
import { CustomPageItem } from 'interfaces'

export const getBreadcrumbListForCustomPage = (
  customPagesList: CustomPageItem[],
  currentCustomPage: CustomPage,
  submenuSlug: string | undefined,
  channelSlug: string
): BreadCrumbLink[] => {
  if (currentCustomPage) {
    const rootMenuItem = customPagesList[0]

    const rootCrumb: BreadCrumbLink = {
      name: rootMenuItem.title,
      link: `${channelSlug}/menu/${rootMenuItem.slug}`
    }
    const activeCrumbFirstLevelDown: BreadCrumbLink = {
      name: currentCustomPage.title,
      link: `${channelSlug}/menu/${rootMenuItem.slug}/${currentCustomPage.slug}`
    }

    if (submenuSlug) {
      const item = getCustomPageItemFromSlug(
        customPagesList[0].children,
        submenuSlug
      )

      if (item) {
        const submenuCrumb: BreadCrumbLink = {
          name: item.title,
          link: `${channelSlug}/menu/${rootMenuItem.slug}/${item.slug}`
        }
        const activeCrumbSecondLevelDown: BreadCrumbLink = {
          name: currentCustomPage.title,
          link: `${channelSlug}/menu/${rootMenuItem.slug}/${item.slug}/${currentCustomPage.slug}`
        }
        return [rootCrumb, submenuCrumb, activeCrumbSecondLevelDown]
      }
    }

    return [rootCrumb, activeCrumbFirstLevelDown]
  }
  return []
}

const getCustomPageItemFromSlug = (list: CustomPageItem[], slug: string) => {
  return list.find((item: CustomPageItem) => item.slug === slug)
}
