import React from 'react'

const GlobalHeaderLogo = () => (
  <svg
    data-testid="solidsport-logo"
    width="100%"
    height="100%"
    viewBox="0 0 586 100"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_67)">
      <path d="M70.5153 5.27135C67.6002 6.37614 64.7739 7.7024 62.0611 9.23852C59.1989 10.7842 56.6429 12.8395 54.5189 15.3033C51.406 19.0303 50.111 22.4594 50.6339 25.5905C51.327 29.81 54.6162 33.1358 60.5016 35.5677C62.6904 36.4433 67.0163 37.7535 73.4793 39.4984C78.318 40.7226 83.0832 42.2206 87.752 43.9854C91.2298 45.244 94.3917 47.2451 97.0178 49.8496C97.0178 49.4027 97.0178 48.9376 97.0178 48.518C97.0198 39.5662 94.5452 30.7883 89.8678 23.1557C85.1904 15.5231 78.4925 9.33332 70.5153 5.27135Z" />
      <path d="M12.5126 65.098C15.0661 58.6837 18.7141 53.8441 23.4565 50.5791C24.1366 50.081 24.9489 49.7954 25.7912 49.7583C26.3475 49.813 26.01 50.5609 24.788 52.0018C22.2721 54.7126 20.2338 57.8302 18.7597 61.2221C15.8313 67.9365 14.9249 75.3592 16.1514 82.5809C16.2969 83.4418 16.4887 84.2943 16.726 85.1345C22.5439 90.1987 29.4856 93.8025 36.9751 95.6469C44.4646 97.4914 52.2859 97.5232 59.7902 95.7399C67.2945 93.9566 74.2653 90.4095 80.1243 85.3929C85.9833 80.3763 90.5616 74.0348 93.4793 66.8947C93.6784 65.2386 93.6477 63.5629 93.3881 61.9152C92.5369 57.0816 89.6489 53.1631 84.7241 50.1596C82.0672 48.7004 76.878 47.0193 69.1564 45.1163C61.4349 43.2133 56.1909 41.6659 53.4245 40.4742C47.3446 37.6835 43.8821 33.762 43.0369 28.7095C42.3134 24.3077 44.0553 19.6018 48.2627 14.5919C52.47 9.58202 58.3949 5.80637 66.0374 3.26495C56.2213 -0.523603 45.4399 -1.01973 35.3174 1.85129C25.1948 4.72231 16.2794 10.805 9.91403 19.183C3.54871 27.5611 0.0782692 37.7807 0.0255128 48.3024C-0.0272435 58.824 3.34054 69.0779 9.62153 77.5194C9.86499 73.2437 10.8429 69.0418 12.5126 65.098Z" />
      <path d="M157.601 85.0616C148.032 85.0616 140.644 83.0461 135.44 79.0151C130.236 74.9841 127.5 69.2355 127.232 61.7693H141.186C141.702 66.0253 143.222 69.0652 145.746 70.8892C148.269 72.7132 152.035 73.6252 157.045 73.6252C158.793 73.6335 160.537 73.4686 162.253 73.1327C163.773 72.8498 165.236 72.3199 166.585 71.5641C167.787 70.8783 168.812 69.9192 169.576 68.7643C170.359 67.515 170.752 66.061 170.707 64.5874C170.766 63.0806 170.334 61.5953 169.476 60.3557C168.564 59.12 167.365 58.1246 165.983 57.4556C164.228 56.6203 162.395 55.9605 160.511 55.4857C158.371 54.975 155.96 54.4156 153.279 53.8076C150.135 53.0841 147.095 52.2815 144.159 51.3999C141.432 50.6063 138.844 49.3941 136.489 47.8067C134.3 46.3081 132.484 44.3268 131.181 42.0155C129.737 39.2252 129.043 36.1078 129.166 32.9686C129.1 29.8113 129.791 26.6842 131.181 23.8486C132.509 21.2368 134.433 18.9747 136.799 17.2458C139.36 15.3958 142.247 14.0439 145.308 13.2604C148.836 12.3455 152.47 11.9009 156.115 11.938C164.019 11.938 170.403 13.8046 175.267 17.5377C180.131 21.2707 182.803 26.7093 183.283 33.8532H169.631C169.537 32.2895 169.101 30.7655 168.352 29.3895C167.603 28.0135 166.561 26.8192 165.299 25.8915C162.578 24.0505 159.343 23.1212 156.06 23.2376C152.193 23.2376 149.114 23.9915 146.822 25.4993C145.772 26.1067 144.901 26.9807 144.297 28.0329C143.693 29.085 143.378 30.2781 143.384 31.4911C143.328 32.7525 143.645 34.0022 144.296 35.0844C145.035 36.1345 146.014 36.994 147.15 37.5924C148.66 38.3924 150.262 39.0049 151.92 39.4164C153.817 39.9453 156.075 40.5047 158.696 41.0944C162.295 41.8787 165.688 42.7451 168.874 43.6936C171.827 44.5432 174.641 45.8182 177.228 47.4784C179.565 48.9979 181.504 51.0557 182.882 53.4793C184.36 56.3602 185.07 59.5736 184.943 62.809C184.998 66.0873 184.292 69.3338 182.882 72.2937C181.548 75.0575 179.587 77.4715 177.155 79.3434C174.565 81.2945 171.632 82.7423 168.509 83.6115C164.962 84.6157 161.288 85.104 157.601 85.0616Z" />
      <path d="M216.279 84.9612C212.604 85.0129 208.957 84.3115 205.563 82.9001C202.447 81.596 199.628 79.6732 197.277 77.2482C194.926 74.8231 193.091 71.9463 191.883 68.7916C189.318 62.0349 189.318 54.5724 191.883 47.8158C193.098 44.6641 194.934 41.789 197.282 39.3616C199.644 36.9599 202.459 35.0504 205.563 33.7437C212.443 30.9951 220.115 30.9951 226.995 33.7437C230.108 35.0606 232.929 36.9826 235.294 39.3981C237.646 41.825 239.485 44.7001 240.702 47.8522C243.268 54.6089 243.268 62.0714 240.702 68.8281C239.491 71.9806 237.655 74.8557 235.304 77.2802C232.953 79.7048 230.136 81.6288 227.022 82.9366C223.617 84.3381 219.961 85.0272 216.279 84.9612ZM216.279 74.7378C220.401 74.7378 223.563 73.2178 225.764 70.1778C227.965 67.1378 229.062 63.1859 229.056 58.3219C229.056 53.4032 227.959 49.4209 225.764 46.3748C223.569 43.3288 220.407 41.8088 216.279 41.8149C212.151 41.8149 208.989 43.3348 206.794 46.3748C204.6 49.4148 203.502 53.4124 203.502 58.3675C203.502 63.274 204.6 67.226 206.794 70.2234C208.989 73.2208 212.151 74.7408 216.279 74.7834V74.7378Z" />
      <path d="M248.974 13.2148H262.335V83.493H248.974V13.2148Z" />
      <path d="M271.218 13.2148H284.587V25.2075H271.218V13.2148ZM271.218 33.0689H284.587V83.493H271.218V33.0689Z" />
      <path d="M312.102 84.9613C309.12 85.011 306.165 84.3876 303.456 83.1373C300.844 81.8989 298.543 80.0878 296.726 77.8386C294.749 75.3373 293.263 72.4842 292.348 69.43C291.261 65.845 290.731 62.1137 290.78 58.3676C290.738 54.7026 291.267 51.0534 292.348 47.5513C293.285 44.4889 294.806 41.6366 296.826 39.1519C298.69 36.8647 301.018 34.9996 303.657 33.6799C306.343 32.3508 309.306 31.6758 312.303 31.71C315.353 31.5871 318.376 32.3301 321.021 33.8532C323.209 35.205 325.076 37.0161 326.493 39.161H326.785V13.2148H340.155V83.493H327.342V76.8992H327.141C325.587 79.4678 323.371 81.5713 320.726 82.9896C318.08 84.408 315.101 85.0889 312.102 84.9613ZM315.057 73.762C319.052 73.762 322.064 72.4001 324.095 69.6763C326.126 66.9524 327.141 63.268 327.141 58.6229C327.164 56.4115 326.95 54.2041 326.503 52.0383C326.151 50.2406 325.467 48.5241 324.487 46.9768C323.599 45.5866 322.363 44.4518 320.903 43.6845C319.204 42.8449 317.325 42.4376 315.431 42.4989C313.776 42.421 312.131 42.7907 310.669 43.5691C309.207 44.3476 307.982 45.5059 307.123 46.922C305.299 49.8769 304.323 53.6708 304.323 58.3311C304.323 63.2376 305.235 67.0376 307.059 69.731C307.928 71.0386 309.122 72.0978 310.525 72.8045C311.927 73.5112 313.489 73.8411 315.057 73.762Z" />
      <path d="M377.173 85.0616C367.603 85.0616 360.216 83.0461 355.011 79.0151C349.807 74.9841 347.071 69.2355 346.803 61.7693H360.757C361.286 66.0253 362.806 69.0652 365.317 70.8892C367.828 72.7132 371.594 73.6252 376.616 73.6252C378.364 73.6335 380.109 73.4686 381.824 73.1327C383.344 72.8498 384.807 72.3199 386.156 71.5641C387.359 70.8783 388.383 69.9192 389.147 68.7643C389.93 67.5149 390.324 66.061 390.278 64.5874C390.336 63.0821 389.908 61.5981 389.056 60.3557C388.131 59.1184 386.92 58.1234 385.527 57.4556C383.773 56.6188 381.94 55.9589 380.055 55.4857C377.933 54.9749 375.531 54.4156 372.85 53.8076C369.7 53.0841 366.661 52.2815 363.73 51.3999C361.003 50.6063 358.415 49.3941 356.06 47.8067C353.872 46.3064 352.057 44.3255 350.752 42.0155C349.309 39.2252 348.614 36.1078 348.737 32.9686C348.671 29.8113 349.362 26.6841 350.752 23.8486C352.078 21.243 353.999 18.987 356.361 17.2641C358.918 15.4124 361.802 14.0603 364.861 13.2786C368.412 12.3512 372.071 11.9004 375.741 11.938C383.675 11.938 390.059 13.8046 394.893 17.5376C399.726 21.2707 402.395 26.7093 402.9 33.8532H389.22C389.124 32.2871 388.684 30.7615 387.93 29.3852C387.177 28.009 386.129 26.816 384.861 25.8915C382.138 24.0545 378.905 23.1256 375.622 23.2376C371.756 23.2376 368.676 23.9915 366.384 25.4993C365.331 26.1048 364.458 26.9781 363.853 28.0306C363.247 29.083 362.931 30.277 362.937 31.4911C362.878 32.7527 363.196 34.0032 363.849 35.0844C364.582 36.1364 365.558 36.9966 366.694 37.5924C368.204 38.3923 369.805 39.0049 371.464 39.4164C373.361 39.9453 375.622 40.5047 378.249 41.0944C381.848 41.8787 385.238 42.7451 388.418 43.6936C391.371 44.5432 394.185 45.8182 396.771 47.4784C399.109 48.9979 401.048 51.0557 402.426 53.4793C403.908 56.3586 404.618 59.5732 404.487 62.809C404.546 66.0877 403.84 69.3352 402.426 72.2937C401.095 75.0541 399.14 77.4675 396.717 79.3434C394.123 81.2928 391.188 82.7403 388.062 83.6115C384.52 84.6139 380.853 85.1023 377.173 85.0616Z" />
      <path d="M411.965 33.0689H424.843V39.3616H425.135C426.766 37.154 428.822 35.2937 431.181 33.8897C433.861 32.3748 436.905 31.627 439.982 31.7283C443.106 31.6813 446.199 32.3554 449.02 33.6982C451.698 35.0007 454.062 36.8674 455.951 39.1701C457.964 41.6513 459.467 44.5058 460.374 47.5696C461.42 51.0723 461.933 54.7122 461.897 58.3676C461.94 62.1104 461.411 65.8377 460.328 69.4209C459.406 72.492 457.884 75.3499 455.85 77.8295C453.961 80.0875 451.594 81.8974 448.919 83.1282C446.141 84.3799 443.12 85.0027 440.073 84.9522C437.006 85.0586 433.962 84.3839 431.227 82.9914C428.935 81.7392 426.98 79.9528 425.527 77.7839H425.335V99.9909H411.965V33.0689ZM437.228 73.8714C438.801 73.9226 440.363 73.5868 441.777 72.8935C443.19 72.2003 444.412 71.1707 445.335 69.8951C447.335 67.2382 448.336 63.5203 448.336 58.7415C448.336 53.9626 447.424 50.0623 445.6 47.0406C443.776 44.0006 440.812 42.4806 436.708 42.4806C434.846 42.4296 433.004 42.8767 431.373 43.7757C429.901 44.6158 428.65 45.7944 427.725 47.2139C426.719 48.7896 426.02 50.5409 425.663 52.3758C425.224 54.475 425.01 56.6151 425.025 58.7597C425.025 63.3501 426.056 67.0193 428.117 69.7675C430.178 72.5156 433.215 73.8836 437.228 73.8714Z" />
      <path d="M491.974 84.9613C488.299 85.013 484.652 84.3115 481.259 82.9002C478.143 81.596 475.324 79.6733 472.972 77.2482C470.621 74.8232 468.786 71.9463 467.579 68.7916C465.013 62.035 465.013 54.5725 467.579 47.8158C468.793 44.6642 470.629 41.7891 472.978 39.3616C475.339 36.9599 478.154 35.0504 481.259 33.7438C488.135 30.9957 495.805 30.9957 502.681 33.7438C505.799 35.0581 508.623 36.9803 510.99 39.3981C513.341 41.8251 515.18 44.7001 516.398 47.8523C518.963 54.609 518.963 62.0715 516.398 68.8281C515.187 71.9812 513.352 74.8567 511.001 77.2814C508.649 79.7061 505.832 81.6298 502.718 82.9367C499.313 84.3404 495.656 85.0296 491.974 84.9613ZM491.974 74.7378C496.097 74.7378 499.258 73.2178 501.459 70.1779C503.66 67.1379 504.758 63.1859 504.751 58.322C504.751 53.4033 503.654 49.4209 501.459 46.3749C499.264 43.3288 496.103 41.8088 491.974 41.8149C487.84 41.8149 484.678 43.3349 482.49 46.3749C480.301 49.4148 479.204 53.4124 479.197 58.3676C479.197 63.2741 480.295 67.2261 482.49 70.2235C484.685 73.2209 487.846 74.7409 491.974 74.7834V74.7378Z" />
      <path d="M537.383 41.1309H537.674C538.884 38.6233 540.631 36.4127 542.791 34.6557C544.913 33.1412 547.482 32.3804 550.087 32.4943C551.15 32.4546 552.212 32.587 553.233 32.8865V44.5691H552.941C548.503 44.1131 544.888 45.0646 542.098 47.4236C539.307 49.7826 537.899 53.613 537.875 58.9147V83.5385H524.505V33.0689H537.383V41.1309Z" />
      <path d="M556.544 33.0689H563.52V17.3279H576.598V33.0689H585.244V41.9517H576.598V68.6457C576.598 70.5518 577.054 71.8559 577.975 72.5764C579.031 73.3454 580.319 73.7285 581.623 73.6617H584.186C584.644 73.66 585.101 73.6265 585.554 73.5613V83.3926C584.907 83.4565 584.186 83.4929 583.393 83.4929C582.599 83.4929 581.788 83.5841 580.739 83.5841H577.2C575.565 83.5869 573.934 83.4064 572.339 83.0461C570.767 82.7049 569.279 82.0538 567.962 81.1309C566.611 80.1608 565.516 78.8778 564.77 77.3917C563.891 75.5557 563.469 73.5345 563.539 71.5002V41.9517H556.562L556.544 33.0689Z" />
    </g>
    <defs>
      <clipPath id="clip0_3_67">
        <rect width="585.536" height="100" />
      </clipPath>
    </defs>
  </svg>
)

export default GlobalHeaderLogo
