import React, { FC } from 'react'
import { Form, Field, FormikProps } from 'formik'
import { t } from '@lingui/macro'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import styles from './RefereeAccessForm.module.scss'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'

interface Props {
  formik: FormikProps<any>
  accessCodeFieldPlaceholder: string
  accessCodeFieldreadOnly: boolean
  handleAccessCodeChange: (e: any) => void
}

export const RefereeAccessFormFields: FC<Props> = ({
  formik,
  accessCodeFieldPlaceholder,
  accessCodeFieldreadOnly,
  handleAccessCodeChange
}) => {
  return (
    <div className={styles.Container}>
      <Form>
        <div
          className={styles.TextInput}
          data-testid="activation-code-text-input"
        >
          <Field
            component={TextInput}
            id="accessCode"
            name="accessCode"
            label={t`Activation code`}
            placeholder={accessCodeFieldPlaceholder}
            onChange={handleAccessCodeChange}
            textValue={formik.values.accessCode}
            isValid={!formik.errors.accessCode}
            validationMessage={formik.errors.accessCode}
            readonly={accessCodeFieldreadOnly}
          />
        </div>
        <div className={styles.SubmitButton}>
          <SubmitButton
            type="secondary"
            customStyle={styles.SubmitButton}
            disabled={!formik.dirty || formik.isSubmitting}
            label={t`Activate referee access`}
          />
        </div>
      </Form>
    </div>
  )
}
