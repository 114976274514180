type GroupedVideoData = { [key: string]: Array<any> }
export const mergeVideoDataObjects = (
  a: GroupedVideoData,
  b: GroupedVideoData
) => {
  const aCopy = { ...a }
  const bCopy = { ...b }
  const newVideoData: GroupedVideoData = {}
  Object.keys(aCopy).forEach((key: string) => {
    if (bCopy[key]) {
      newVideoData[key] = [...aCopy[key], ...b[key]]
      delete bCopy[key]
    } else {
      newVideoData[key] = [...aCopy[key]]
    }
  })
  Object.keys(bCopy).forEach((key: string) => {
    newVideoData[key] = [...bCopy[key]]
  })
  return newVideoData
}
