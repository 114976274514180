import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './ArticleTitle.module.scss'

interface ArticleTitleProps {
  title: string
  size: 'small' | 'medium' | 'large' | 'xlarge'
  extraContent?: string
}

export const ArticleTitle: FC<ArticleTitleProps> = ({
  title,
  size,
  extraContent
}) => {
  return (
    <div className={styles.ArticleTitleContainer}>
      <h4
        data-testid="article-thumbnail-title"
        className={classNames(styles.Articletitle, styles[size])}
      >
        {title}
      </h4>
      {size === 'xlarge' && (
        <div className={styles.extraContent} data-testid="extra-content">
          {extraContent}
        </div>
      )}
    </div>
  )
}
