import compactObject from './compactObject'

const loadStateFromStorage = (key: string) => {
  try {
    const serialState = localStorage.getItem(key)
    if (serialState === null) {
      return undefined
    }
    return JSON.parse(serialState)
  } catch (err) {
    return undefined
  }
}

const saveStateToStorage = (
  key: string,
  state: any,
  merge: boolean = false
) => {
  let new_state = state
  try {
    if (merge) {
      const oldState = loadStateFromStorage(key)
      if (oldState) {
        new_state = { ...oldState, ...compactObject(state) }
      }
    }
    const serialState = JSON.stringify(new_state)
    localStorage.setItem(key, serialState)
  } catch (err) {
    console.log(err)
  }
}

export { loadStateFromStorage, saveStateToStorage }
