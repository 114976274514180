import { DomainRegistrationStatus } from '../../../pages/ChannelPage/ChannelSettings/Branding/CustomDomain/DomainStatus/DomainRegistrationStatus'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export interface CustomDomainStatus {
  status: DomainRegistrationStatus
  customDomain: string
}

export const getCustomDomainStatus = async (channelSlug: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/root_domain_request_status`,
      authenticatable: true
    })
    const responseData = await resultResponse

    const overriddenStatus = responseData.data?.status === 'initiated'

    const statusNumber =
      responseData.data?.status_id || overriddenStatus
        ? responseData.data?.status_id ||
          DomainRegistrationStatus.WaitingForPartnerConfiguration
        : DomainRegistrationStatus.NotSet

    const status: DomainRegistrationStatus = statusNumber
    return {
      status,
      customDomain: responseData.data.domain
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
