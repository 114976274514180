import React, { FC } from 'react'
import { t } from '@lingui/macro'
import BuyOptionBanner from './BuyOptionBanner'
import { formatCurrency } from '../../../../../helpers/formatHelper'
import { SubscriptionIcon } from '../Icons/SubscriptionIcon'
import { TicketsIcon } from '../Icons/TicketIcon'

import { getTranslatedChannelType } from './getTranslatedChannelType'
import { VoucherCodeIcon } from '../Icons/VoucherCodeIcon'
import { PPVIcon } from '../Icons/PPVIcon'
import styles from './BuyOption.module.scss'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
export enum BUY_OPTIONS {
  SUBSCRIPTION,
  MONTHLY_SUPPORTER,
  PER_STREAM,
  ALL_ACCESS,
  VOUCHER
}

interface BuyOptionBannerFactoryProps {
  option: BUY_OPTIONS
  amount: number
  currency: string
  channelType?: ChannelTypes | null
  channelName?: string
  duration?: string | null
  description?: string
  onSelect: () => void
}

const BuyOptionBannerFactory: FC<BuyOptionBannerFactoryProps> = ({
  option,
  amount,
  currency,
  channelType,
  channelName,
  duration,
  description,
  onSelect
}) => {
  switch (option) {
    case BUY_OPTIONS.PER_STREAM:
      return getMatchBuyBanner(amount, currency, onSelect, description)
    case BUY_OPTIONS.SUBSCRIPTION:
      return getSubscriptionBanner(
        amount,
        currency,
        duration || t`Month`,
        channelName || '',
        onSelect,
        description
      )
    case BUY_OPTIONS.MONTHLY_SUPPORTER:
      return getMonthlySupporterBanner(
        amount,
        currency,
        duration || t`Month`,
        channelType || ChannelTypes.team,
        channelName || '',
        onSelect,
        description
      )
    case BUY_OPTIONS.ALL_ACCESS:
      return getAllAccessSubscriptionBanner(
        amount,
        currency,
        onSelect,
        description
      )
    case BUY_OPTIONS.VOUCHER:
      return getVoucherBanner(currency, onSelect)
    default:
      return null
  }
}
export const getSubscriptionBanner = (
  amount: number,
  currency: string,
  duration: string,
  channelName: string,
  onSelect: () => void,
  description?: string
) => {
  const bannerDesc =
    description || t`Get access to everything from ${channelName}`
  return (
    <BuyOptionBanner
      cost={`${formatCurrency(amount, currency)} / ${duration}`}
      description={bannerDesc}
      onSelect={onSelect}
    >
      <div className={styles.IconContainer} data-testid="subscription-icon">
        <SubscriptionIcon data-testid="subscription-icon" />
      </div>
    </BuyOptionBanner>
  )
}

export const getMonthlySupporterBanner = (
  amount: number,
  currency: string,
  duration: string,
  channelType: ChannelTypes,
  channelName: string,
  onSelect: () => void,
  description?: string
) => {
  const translatedChannelType = getTranslatedChannelType(channelType)
  const isClubSupporter = [ChannelTypes.club].includes(channelType)
  const defaultText = isClubSupporter
    ? t`Get access to everything from the ${translatedChannelType} ${channelName} and all associated teams`
    : t`Get access to everything from the ${translatedChannelType} ${channelName}`

  const bannerDesc = description || defaultText
  return (
    <BuyOptionBanner
      cost={`${formatCurrency(amount, currency)} / ${duration}`}
      description={bannerDesc}
      onSelect={onSelect}
    >
      <div className={styles.IconContainer} data-testid="subscription-icon">
        <SubscriptionIcon data-testid="subscription-icon" />
      </div>
    </BuyOptionBanner>
  )
}

export const getAllAccessSubscriptionBanner = (
  amount: number,
  currency: string,
  onSelect: () => void,
  description?: string
) => {
  const bannerDesc = description || t`Get full access live and on demand`
  return (
    <BuyOptionBanner
      cost={formatCurrency(amount, currency)}
      description={bannerDesc}
      onSelect={onSelect}
    >
      <div className={styles.IconContainer} data-testid="all-access-icon">
        <TicketsIcon />
      </div>
    </BuyOptionBanner>
  )
}

export const getMatchBuyBanner = (
  amount: number,
  currency: string,
  onSelect: () => void,
  description?: string
) => {
  const bannerDesc = description || t`Gain full access to this stream`
  return (
    <BuyOptionBanner
      cost={`${formatCurrency(amount, currency)}`}
      description={bannerDesc}
      onSelect={onSelect}
    >
      <div className={styles.IconContainer} data-testid="ppv-icon">
        <PPVIcon />
      </div>
    </BuyOptionBanner>
  )
}
export const getVoucherBanner = (
  currency: string,
  onSelect: () => void,
  description?: string
) => {
  const bannerDesc = description || t`Get free access to content`
  return (
    <BuyOptionBanner
      cost={`${formatCurrency(0, currency)}`}
      description={bannerDesc}
      onSelect={onSelect}
    >
      <div className={styles.IconContainer} data-testid="Voucher-icon">
        <VoucherCodeIcon />
      </div>
    </BuyOptionBanner>
  )
}
export default BuyOptionBannerFactory
