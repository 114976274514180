import React, { FC } from 'react'
import styles from './FilterHeaderItem.module.scss'
import classNames from 'classnames'
import CloseIcon from 'components/Modals/Modal/CloseIcon'

interface Props {
  label: string
  slug: string
  disabled: boolean
  onRemoveFilter: (slug: string) => void
}

export const FilterHeaderItem: FC<Props> = ({
  label,
  slug,
  disabled,
  onRemoveFilter
}) => {
  if (disabled)
    return (
      <div className={classNames(styles.Container, styles.Disabled)}>
        <div className={styles.Label}>{label}</div>
      </div>
    )
  return (
    <div
      role="button"
      onClick={() => onRemoveFilter(slug)}
      className={styles.Container}
    >
      <div className={styles.Label}>{label}</div>
      {!disabled && (
        <div className={styles.Icon}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
