import React, { FC } from 'react'
import { CustomPageMeta } from './PageListAccordion'
import { Link } from 'react-router-dom'

import styles from './ChildPageContent.module.scss'
import TagManager from 'react-gtm-module'
import { RightArrowIcon } from 'components/ChannelListItem/RightArrowIcon'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { ExternalLinkIcon } from 'components/ChannelListItem/ExternalLinkIcon'

interface Props {
  childPages: CustomPageMeta[]
  pageSlug: string
  menuSlug: string
  channelSlug: string
}

export const ChildPageContent: FC<Props> = ({
  childPages,
  pageSlug,
  menuSlug,
  channelSlug
}) => {
  const triggerGTMCustomPageSubmenuLink = (
    title: string,
    slug: string,
    pageSlug: string
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_page_submenu_clicked',
        title: title,
        link: slug,
        page_list_title: pageSlug
      }
    })
  }
  const content = childPages.map((childPage: CustomPageMeta) => {
    const onClick = triggerGTMCustomPageSubmenuLink(
      childPage.title,
      childPage.slug,
      pageSlug
    )

    return childPage.href && childPage.href_target ? (
      <a
        className={styles.ChildLink}
        href={childPage.href}
        target={childPage.href_target}
        onClick={() => onClick}
      >
        {childPage.title}
        {childPage.href_target === LinkTarget.NewWindow ? (
          <ExternalLinkIcon />
        ) : (
          <RightArrowIcon />
        )}
      </a>
    ) : (
      <Link
        data-testid="custom-page-submenu-link"
        key={childPage.slug}
        className={styles.ChildLink}
        to={`/${channelSlug}/menu/${menuSlug}/${pageSlug}/${childPage.slug}`}
        onClick={() => onClick}
      >
        {childPage.title}
        <RightArrowIcon />
      </Link>
    )
  })

  return <div className={styles.Container}>{content}</div>
}
