import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'

import { ProductType } from '../ProductType'
import Button, {
  ButtonType
} from '../../../../components/buttons/button/Button'

import styles from './purchaseOptionCard.module.scss'
import { CardText } from './CardText/CardText'
import { Period } from './CardText/period'
import { SubscriptionType } from '../SubscriptionType'
import { SubscriptionsCardIcon } from './SubscriptionsCardIcon'
import { CompanionAppSection } from './CompanionAppSection/CompanionAppSection'

interface Props {
  id: string
  productType: ProductType
  productTitle: string
  productPeriodCost: string
  onPurchaseClick: (id: string) => void
  description: string
  expirationDate: number
  subscriptionType: SubscriptionType
  isPurchased: boolean
  periodicity: Period
  appIsAvailable: boolean
  rootChannelSubdomain: string
  isRootOrChildChannels: boolean
  productChannelName: string
}

export const PurchaseOptionCard: FC<Props> = ({
  id,
  productType,
  productTitle,
  productPeriodCost,
  onPurchaseClick,
  description,
  expirationDate,
  subscriptionType,
  isPurchased,
  periodicity,
  appIsAvailable,
  rootChannelSubdomain,
  isRootOrChildChannels,
  productChannelName
}) => {
  const buttonSize = 'Medium'
  const showAppLinksSection =
    appIsAvailable && [SubscriptionType.Team].includes(subscriptionType)
  return (
    <div
      className={classNames(
        styles.PurchaseOptionCardContainer,
        styles[productType],
        {
          [styles.IsPurchasedBorder]: isPurchased
        }
      )}
    >
      {!!isPurchased && (
        <div className={styles.IsPurchasedTag}>
          <Trans>Your subscription</Trans>
        </div>
      )}
      <div
        className={classNames(styles.PurchaseOptionContentContainer, {
          [styles.PurchasedOptionContentContainer]: isPurchased
        })}
      >
        <div className={styles.TopContainer}>
          <div className={styles.PurchaseOptionIconContainer}>
            <div className={styles.PurchaseOptionIcon}>
              <SubscriptionsCardIcon productType={productType} />
            </div>
          </div>
          <div className={styles.PurchaseOptionTitle}>
            <h4>{productTitle}</h4>
            <div className={styles.ProductPeriodCost}>{productPeriodCost}</div>
          </div>
          <CardText
            productType={productType}
            subscriptionType={subscriptionType}
            customDescription={description}
            expirationDate={expirationDate}
            periodicity={periodicity}
            subscriptionChannelName={productChannelName}
          />
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            buttonSize={buttonSize}
            type={isPurchased ? ButtonType.Active : ButtonType.Secondary}
            label={isPurchased ? t`Go to settings` : t`Buy now`}
            onClick={() => onPurchaseClick(id)}
          />
        </div>
        {showAppLinksSection && (
          <CompanionAppSection
            rootChannelSubdomain={rootChannelSubdomain}
            isRootOrChildChannels={isRootOrChildChannels}
          />
        )}
      </div>
    </div>
  )
}
