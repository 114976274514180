import React, { FC } from 'react'
import { LineupItemProps } from '../LineupItem/LineupItem'
import { PlayerList } from '../PlayerList/PlayerList'
import styles from './TeamLineup.module.scss'
import { CoachList } from '../CoachList/CoachList'
import { t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { useSelector } from 'react-redux'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
interface Props {
  playerList: LineupItemProps[]
  coachList: LineupItemProps[]
  teamLogo: string
  teamName: string
  isHometeam: boolean
  gameHasBeenPlayed: boolean
}

export const TeamLineup: FC<Props> = ({
  playerList,
  coachList,
  teamLogo,
  teamName,
  isHometeam,
  gameHasBeenPlayed
}) => {
  const breakPoint = useCurrentBreakpoint()
  const isViewingStream = useSelector(isViewingStreamSelector)
  const showTeamLabel = !isViewingStream && breakPoint >= Breakpoint.md
  const teamLabel = isHometeam ? t`Home team` : t`Away team`
  const dataTestId = isHometeam ? 'home-team-container' : 'away-team-container'
  const noLineupText = gameHasBeenPlayed
    ? t`There is no lineup information available`
    : t`Waiting for lineup...`
  return (
    <div
      className={classNames(styles.TeamLineup, {
        [styles.IsViewingStream]: isViewingStream
      })}
      data-testid={dataTestId}
    >
      {showTeamLabel && <div className={styles.TeamLabel}>{teamLabel}</div>}
      <div
        className={styles.TopContainer}
        data-testid="logo-and-name-container"
      >
        <img
          className={styles.TeamLogo}
          src={teamLogo}
          alt={teamName}
          data-testid="team-logo"
        />
        <div className={styles.TeamName} data-testid="team-name">
          {teamName}
        </div>
      </div>
      <div className={styles.List}>
        {playerList.length > 0 ? (
          <PlayerList players={playerList} />
        ) : (
          <div
            className={styles.EmptyLineupText}
            data-testid="empty-lineup-text"
          >
            {noLineupText}
          </div>
        )}
        <CoachList coaches={coachList} />
      </div>
    </div>
  )
}
