import { InfoBoxDisplay } from './InfoBox'
import { ImageLinkDisplay } from './ImageLink'

export enum StartBannerContentType {
  InfoBox = 'text_banner',
  ImageLink = 'image_banner'
}

export interface StartBannerContent extends InfoBoxDisplay, ImageLinkDisplay {
  bannerType: StartBannerContentType
  published: boolean
}
