import React, { FC } from 'react'
import classNames from 'classnames'
import Lottie from 'lottie-react'

import animationData from './loader-dots.json'
import styles from './Loader.module.scss'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
}

interface LoaderProps {
  variant?: 'large' | 'small'
}

export const Loader: FC<LoaderProps> = ({ variant }) => (
  <div
    className={classNames(styles.Loader, {
      [styles.Large]: variant === 'large',
      [styles.Small]: variant === 'small'
    })}
    data-testid="animated-loader"
    title="Loading"
  >
    <Lottie {...defaultOptions} />
  </div>
)

Loader.defaultProps = {
  variant: 'large'
}
