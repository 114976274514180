import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { t } from '@lingui/macro'

export const uploadImageFile = async (
  loadingStatus: {
    loading: () => void
    loadingMore: () => void
    success: () => void
    error: () => void
    empty: () => void
    reset: () => void
    isInitializing: () => boolean
    isLoading: () => boolean
    isLoadingMore: () => boolean
    isSuccess: () => boolean
    isError: () => boolean
    isEmpty: () => boolean
    currentStatus: string
  },
  path: string,
  image: any,
  selectImage: (uploadedImage: any) => void
) => {
  loadingStatus.reset()
  loadingStatus.loading()
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path,
      method: ApiEndpoint.METHODS.POST,
      file: image
    })

    if (response.data?.image) {
      selectImage(response.data.image)
    } else {
      selectImage(null)
    }
    loadingStatus.success()
    return { isSuccess: true }
  } catch (error: any) {
    loadingStatus.error()
    if (error?.status === 413 && error?.message) {
      return {
        isSuccess: false,
        message: t`The image file size is too large. The maximum allowed is 2MB`
      }
    }
    if (error?.status === 400 && error.message) {
      return {
        isSuccess: false,
        message: t`Unsuported image type. Try uploading a .jpg or .png file`
      }
    }
    console.error(error)
    loadingStatus.error()
    return {
      isSuccess: false,
      message: error.message
    }
  }
}
