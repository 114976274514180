import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { Honeybadger } from '@honeybadger-io/react'
import { useHistory } from 'react-router-dom'
import { ErrorIcon } from './ErrorIcon'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import ContentContainer from 'components/ContentContainer'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'

import styles from './StreamError.module.scss'
import { BackArrow } from 'components/Searchfield/BackArrow'
import { FlagIcon } from 'assets/icons/FlagIcon'

interface Props {
  homePage: string
}

export const StreamError: FC<Props> = ({ homePage }) => {
  const history = useHistory()
  const stream = useSelector(streamSelector)
  const is404Error = stream.error?.status! === 404

  const naviagateHome = () => {
    history.push(homePage)
  }
  const handleStartPageClick = () => {
    naviagateHome()
  }
  const handleReportErrorClick = () => {
    Honeybadger.notify('user has reported an error with the viewer page')
    naviagateHome()
  }
  const titleText = is404Error
    ? t`Page not found`
    : t`Something went wrong with the broadcast`
  const subText = is404Error
    ? t`The page you are looking for does not exist, please check your URL or return to the start page`
    : t`There is a problem with the broadcast right now, try to reload the
            page and see if that fixes it. If it is still not working, report it
            to our support.`
  return (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding={false}
      hasLightBackground
    >
      <div className={styles.ErrorContainer}>
        <div className={styles.ErrorIcon}>
          <ErrorIcon />
        </div>
        <h2>{titleText}</h2>
        <p className={styles.SubText}>{subText}</p>
        <div className={styles.ButtonContainer}>
          <IconTextButton
            label={t`Go back to start page`}
            buttonSize="Medium"
            type={ButtonType.Secondary}
            onClick={handleStartPageClick}
            id={'back-button'}
            isRounded
            externalStyle={styles.BackButton}
          >
            <BackArrow />
          </IconTextButton>
          {!is404Error && (
            <IconTextButton
              label={t`Report`}
              buttonSize="Medium"
              type={ButtonType.Ghost}
              onClick={handleReportErrorClick}
              id={'report-button'}
              isRounded
              externalStyle={styles.ReportButton}
            >
              <FlagIcon />
            </IconTextButton>
          )}
        </div>
      </div>
    </ContentContainer>
  )
}

export default Error
