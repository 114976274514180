export const getEventFilterPath = (pathname: string, eventSlug: string) => {
  const eventIndex = pathname.indexOf(eventSlug) + eventSlug.length + 1
  const endOfEventIndex = pathname.indexOf('/', eventIndex + 2)

  const filterPath =
    eventIndex === -1
      ? ''
      : endOfEventIndex === -1
      ? pathname.substring(eventIndex)
      : pathname.substring(eventIndex, endOfEventIndex)
  return filterPath
}
