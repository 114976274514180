import { FeatureFlagsAvailable } from 'featureFlags/FeatureFlagsAvailable'
import { useFeatureFlags } from 'featureFlags/useFeatureFlags'
import { BannerSection } from 'features/customContent/BannerSection/BannerSection'
import React, { FC, ReactNode, useState } from 'react'
import { getBannerDisplayData } from '../getBannerDisplayData'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { Channel } from 'api/channel/getChannel'
import { useSelector } from 'react-redux'
import {
  isUserChannelAdminSelector,
  userHasAllAccessPassSelector,
  userIsSupporterSelector
} from 'redux-store/user/user.selectors'
import { RecommendedSection } from 'components/content/RecommendedSection/RecommendedSection'
import useAdminAccess from 'hooks/AdminAccess'
import { RelatedChannel } from 'components/content/RelatedChannel/RelatedChannel'
import { GuidedChannelSetup } from '../GuidedSetupChannelSection/GuidedChannelSetup'
import { InfoBannerContainer } from 'features/sportData/InfoBannerContainer/InfoBannerContainer'

interface Props {
  children: ReactNode
  channelSlug: string
  channelName: string
  channelType: ChannelTypes
  channelDetails: Channel
  isRootPage: boolean
  isViewingAsUser: boolean
  showRelatedChannel: boolean

  onSupportBannerClick: (destination: string) => void
}

export const BannersSection: FC<Props> = ({
  children,
  channelSlug,
  channelName,
  channelType,
  channelDetails,
  isRootPage,
  isViewingAsUser,
  showRelatedChannel,
  onSupportBannerClick
}) => {
  const { getFlag } = useFeatureFlags()
  const [showBanner, setShowBanner] = useState(true)
  const [isAdminChannelGuidanceClosed, setIsAdminChannelGuidanceClosed] =
    useState(false)

  const userIsSupporter = useSelector(userIsSupporterSelector)
  const userHasEventPass = useSelector(userHasAllAccessPassSelector)
  const { isSuperadmin } = useAdminAccess()
  const isChannelAdmin = useSelector(isUserChannelAdminSelector)
  const adminChannelGuidanceFlag = getFlag(
    FeatureFlagsAvailable.ShowChannelAdminGuidance
  )

  const { eventPassAvailable, subscriptionsAvailable } = getBannerDisplayData(
    channelDetails,
    channelType
  )

  const showAdminChannelGuidance =
    adminChannelGuidanceFlag &&
    (isChannelAdmin || isSuperadmin) &&
    !isRootPage &&
    !isAdminChannelGuidanceClosed

  const showSdmInfoBanner =
    !isViewingAsUser &&
    (isChannelAdmin || isSuperadmin) &&
    [ChannelTypes.league, ChannelTypes.club, ChannelTypes.team].includes(
      channelType
    )

  const handleBannerClose = () => {
    setShowBanner(false)
  }

  return (
    <>
      {showSdmInfoBanner && <InfoBannerContainer />}
      <GuidedChannelSetup
        showAdminChannelGuidance={showAdminChannelGuidance}
        onClose={() => setIsAdminChannelGuidanceClosed(true)}
      />
      {showRelatedChannel && <RelatedChannel />}
      {children}

      {isRootPage && <RecommendedSection channelSlug={channelSlug} />}

      <BannerSection
        channelSlug={channelSlug}
        channelName={channelName}
        showSupporterBanner={
          (showBanner && !(isChannelAdmin || isSuperadmin)) || isViewingAsUser
        }
        eventPassAvailable={!!eventPassAvailable}
        subscriptionsAvailable={!!subscriptionsAvailable}
        userIsSupporter={userIsSupporter}
        userHasEventPass={userHasEventPass}
        handleSupporterBannerClose={handleBannerClose}
        onSupportBannerClick={onSupportBannerClick}
      />
    </>
  )
}
