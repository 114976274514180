import React, { FC } from 'react'

export const AppleIcon: FC = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5704_3445)">
      <path
        d="M10.293 4.61538C11.1957 4.61538 12.3273 4.00509 13.0012 3.19135C13.6115 2.45391 14.0565 1.42403 14.0565 0.394151C14.0565 0.254291 14.0438 0.114431 14.0183 0C13.0139 0.0381436 11.806 0.673872 11.0813 1.52575C10.5091 2.17419 9.98781 3.19136 9.98781 4.23395C9.98781 4.38652 10.0132 4.5391 10.026 4.58996C10.0895 4.60267 10.1912 4.61538 10.293 4.61538ZM7.11432 20C8.34763 20 8.89435 19.1736 10.4328 19.1736C11.9967 19.1736 12.34 19.9746 13.7132 19.9746C15.0609 19.9746 15.9636 18.7285 16.8155 17.5079C17.7691 16.1093 18.1633 14.7362 18.1887 14.6726C18.0997 14.6472 15.5186 13.5919 15.5186 10.6294C15.5186 8.06103 17.553 6.90401 17.6674 6.815C16.3197 4.88239 14.2726 4.83153 13.7132 4.83153C12.2001 4.83153 10.9668 5.74698 10.1912 5.74698C9.35208 5.74698 8.24591 4.88239 6.93631 4.88239C4.44426 4.88239 1.91406 6.94215 1.91406 10.8328C1.91406 13.2486 2.85494 15.8042 4.01197 17.4571C5.0037 18.8557 5.86829 20 7.11432 20Z"
        fill="#2F3135"
      />
    </g>
    <defs>
      <clipPath id="clip0_5704_3445">
        <rect width="20" height="20" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
)
