import React, { FC } from 'react'
import { PlayArrow } from '@mui/icons-material'
import { Icon } from '@mui/material'

import styles from './BigPlayButton.module.scss'
import { Spinner } from './Spinner'

interface Props {
  bigPlay: any
  readyToPlay: boolean
}

export const BigPlayButton: FC<Props> = ({ bigPlay, readyToPlay }) => (
  <div className={styles.BigPlay} ref={bigPlay}>
    {readyToPlay ? (
      <Icon component={PlayArrow} className={styles.BigPlayIcon} />
    ) : (
      <Spinner />
    )}
  </div>
)
