import React, { FC } from 'react'
import { t } from '@lingui/macro'
import Lottie from 'lottie-react'

import Button, { ButtonType } from '../../../buttons/button/Button'
import styles from './ModalError.module.scss'
import animationData from './error-occoured.json'

interface Props {
  errorMessage: string
  onCancel: () => void
  onTryAgain: () => void
}
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
}
export const ModalError: FC<Props> = ({
  errorMessage,
  onCancel,
  onTryAgain
}) => (
  <div className={styles.InsideContainer}>
    <div className={styles.ErrorAnimation}>
      <Lottie {...defaultOptions} />
    </div>

    <div className={styles.ModalInfoContainer}>
      <div className={styles.Title}>Oops! Something went wrong</div>
      <div className={styles.SubText}>{errorMessage}</div>
    </div>
    <div className={styles.ButtonContainer}>
      <Button
        type={ButtonType.GhostWithBoarder}
        label={t`Cancel`}
        buttonSize="Medium"
        roleType="button"
        customStyle={styles.ModalButton}
        onClick={() => onCancel()}
      />
      <Button
        type={ButtonType.Secondary}
        label={t`Try again`}
        buttonSize="Medium"
        roleType="button"
        customStyle={styles.ModalButton}
        onClick={() => onTryAgain()}
      />
    </div>
  </div>
)
