import React, { FC } from 'react'
import styles from './CategoryLeaderFirstPlace.module.scss'
import {
  PlayerStatisticsTableRows,
  PlayerStatsVariantsIdentifiers
} from '../../Table/PlayerStatisticsTable.interface'

interface Props {
  rank: number
  selectedStatsIdentifier: string
  row: PlayerStatisticsTableRows
}

export const CategoryLeaderFirstPlace: FC<Props> = ({
  rank,
  selectedStatsIdentifier,
  row
}) => {
  const imageURl = row[PlayerStatsVariantsIdentifiers.TeamLogoUrl].toString()
  const player = typeof row.player === 'object' && row.player
  const firstName = player
    ? player.name.toString().split(' ').slice(0, 1).join(' ')
    : ''
  const lastName =
    player && player.name ? player.name.split(' ').slice(1).join(' ') : ''
  const teamName = player && player.team ? player.team : ''
  const statsValueToDisplay = row[selectedStatsIdentifier].toString()

  return (
    <div className={styles.Container}>
      <div className={styles.LeftWrapper}>
        <div className={styles.Rank}>{rank}</div>
        <div className={styles.FirstName}>{firstName}</div>
        <div className={styles.LastName}>{lastName}</div>
        <div className={styles.TeamName}>{teamName}</div>
      </div>
      <div className={styles.StatsContainer}>{statsValueToDisplay}</div>
      <img className={styles.TeamImage} src={imageURl} alt={teamName} />
    </div>
  )
}
