import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import ContentContainer from '../../../components/ContentContainer'
import InfoBanner from '../../../components/Banner/InfoBanner/InfoBanner'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { getInfoBannerInformation } from './getInfoBannerInformation'
import { useDataApi } from '../../../api/useDataApi'
import { getFilteredGameScheduleList } from '../../../api/sportData/getFilteredGameScheduleLists'
import TagManager from 'react-gtm-module'

export const InfoBannerContainer = () => {
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data?.subdomain
  const channelType = channel.data?.type
  const channelName = channel.data?.name
  const infobannerData = getInfoBannerInformation(channelType, channelSlug)

  const { data } = useDataApi({
    apiCall: useCallback(getFilteredGameScheduleList, []),
    parameters: useMemo(
      () => ({
        searchText: '',
        groupId: '',
        channelSlug,
        page: 1,
        perPageCount: 10
      }),
      [channelSlug]
    )
  })
  const handleGTMEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sports_data_info_banner_clicked',
        value: channelSlug,
        channel_name: channelName,
        channel_type: channelType
      }
    })
  }

  if (data?.data && data.data?.length > 0) {
    return (
      <ContentContainer
        hasLeftRightPadding
        hasLightBackground
        hasTopBottomPadding
      >
        <InfoBanner
          title={infobannerData.title}
          subTitle={infobannerData.subTitle}
          shortSubTitle={infobannerData.shortSubTitle}
          linkPath={infobannerData.linkPath}
          onClick={handleGTMEvent}
        />
      </ContentContainer>
    )
  }
  return null
}
