import { t } from '@lingui/macro'
import { getBreadcrumbSubroutes } from './getBreadcrumbSubroutes'
import { BreadCrumbLink } from 'components/Breadcrumb/breadcrumbLink'

export const getPathList = (
  parentChannelSubdomain: any,
  subdomain: any,
  channelName: any,
  isRootChannel: any,
  location: any,
  translate: (phrase: string) => string
) => {
  const homePath = parentChannelSubdomain ? parentChannelSubdomain : ''
  const homeLink: BreadCrumbLink = {
    name: t`Home`,
    link: homePath
  }

  const channelPath = subdomain
  const channelLink: BreadCrumbLink = {
    name: channelName,
    link: channelPath
  }
  let linkList: BreadCrumbLink[] = []

  if (parentChannelSubdomain) {
    linkList.push(homeLink)
  }

  if (!isRootChannel) {
    linkList.push(channelLink)
  }

  const subroutes = getBreadcrumbSubroutes(location.pathname, subdomain)

  const baseChannelPath = isRootChannel ? homePath : channelPath

  const subLinks = subroutes.map((route: any, mapIndex: number) => {
    const path = subroutes.reduce(
      (acc: string, curr: any, reduceIndex: number): string =>
        reduceIndex <= mapIndex ? acc + curr.link : acc,
      baseChannelPath
    )
    const name = translate(route.translationText)
    const link: BreadCrumbLink = { link: path, name }
    return link
  })

  linkList = [...linkList, ...subLinks]
  return linkList
}
