import {
  PlayerStatisticsTableRows,
  PlayerStatsVariantsIdentifiers
} from './PlayerStatisticsTable.interface'

export const getSortedList = (
  rows: PlayerStatisticsTableRows[],
  sortedBy: string,
  descending: boolean
) => {
  if (sortedBy === PlayerStatsVariantsIdentifiers.Player) {
    rows.sort((a, b) => {
      if (typeof a.player === 'object' && typeof b.player === 'object') {
        return a.player.name < b.player.name ? 1 : -1
      }
      return a.totalGoals < b.totalGoals ? 1 : -1
    })
  }
  if (sortedBy === PlayerStatsVariantsIdentifiers.TeamLogoUrl) {
    if (descending) {
      rows.sort((a, b) => (a.totalGoals > b.totalGoals ? 1 : -1))
    }
    rows.sort((a, b) => {
      if (typeof a.player === 'object' && typeof b.player === 'object') {
        return a.player.team > b.player.team ? -1 : 1
      }
      return a.totalGoals < b.totalGoals ? 1 : -1
    })
  }
  if (sortedBy !== '')
    rows.sort((a, b) => {
      return descending
        ? a[sortedBy] < b[sortedBy]
          ? 1
          : -1
        : a[sortedBy] < b[sortedBy]
        ? -1
        : 1
    })
  return rows
}
