import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import CheckListHelpText from './CheckListHelpText'
import ChecklistItem from './ChecklistItem/ChecklistItem'
import styles from './ChecklistItemsContainer.module.scss'
import ProgressBanner from './ProgressBanner/ProgressBanner'

interface Props {
  onClose: () => void
}
const ChecklistItemsContainer: FC<Props> = ({ onClose }) => {
  const [progress, setProgress] = useState(0)
  const [showList, setShowList] = useState(false)
  const totalTasks = 3
  const [itemsCompleted, setItemsCompleted] = useState([1])

  const handleClick = (itemNumber: number) => {
    if (!itemsCompleted.includes(itemNumber)) {
      setItemsCompleted([...itemsCompleted, itemNumber])
    }
  }

  useEffect(() => {
    setProgress(itemsCompleted.length / totalTasks)
  }, [itemsCompleted])

  const roundedProgress = Math.floor(progress * 100)
  const progressBannerText =
    progress === 1
      ? t`Awesome work! Your channel is now ready`
      : t`There are a few more things you can do to get the most out of your channel.`
  return (
    <div className={styles.ChecklistItemsContainer}>
      <ProgressBanner
        title={t`Welcome to your channel`}
        subTitle={progressBannerText}
        percentageCompleted={roundedProgress}
        onToggleSectionClick={() => setShowList(!showList)}
        onClose={onClose}
        sectionIsOpen={showList}
      />
      {showList && (
        <div className={styles.List} data-testid="check-list-container">
          <ChecklistItem
            itemNumber={1}
            title={t`Create your Solidsport channel`}
            subTitle={t`Reach and engage your community with a solution tailored to the needs of sports fans.`}
            onClick={() => null}
            isActive={false}
            isComplete={itemsCompleted.includes(1)}
          />
          <ChecklistItem
            itemNumber={2}
            title={t`Validate the organisation behind the channel`}
            subTitle={t`Make sure that the information about your organisation is correct, and updated.`}
            onClick={() => {
              handleClick(2)
            }}
            isActive={false}
            isComplete={itemsCompleted.includes(2)}
          />
          <ChecklistItem
            itemNumber={3}
            title={t`Make your supporters feel at home`}
            subTitle={t`Choose a profile image and hero image that makes your audience recognise you.`}
            onClick={() => {
              handleClick(3)
            }}
            isActive={false}
            isComplete={itemsCompleted.includes(3)}
          />
          <CheckListHelpText />
        </div>
      )}
    </div>
  )
}

export default ChecklistItemsContainer
