import { Trans } from '@lingui/macro'
import React from 'react'
import styles from './ChecklistItemsContainer.module.scss'

const CheckListHelpText = () => (
  <div className={styles.HelpTextContainer}>
    <Trans comment="Help text for guided channel flow">
      Do you have questions? Do not hesitate to contact our
    </Trans>{' '}
    <a className={styles.Link} href={`${process.env.REACT_APP_SUPPORT_URL}`}>
      <Trans comment="Help text for guided channel flow">support</Trans>
    </a>{' '}
    <Trans comment="Help text for guided channel flow">or read our</Trans>{' '}
    <a className={styles.Link} href={`${process.env.REACT_APP_FAQ_URL}`}>
      <Trans comment="Help text for guided channel flow">FAQ</Trans>
    </a>
    .
  </div>
)

export default CheckListHelpText
