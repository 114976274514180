/* eslint-disable no-unused-expressions */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  FC
} from 'react'
import './index.scss'
import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'
import { formatTime } from '../formatTime'
import { isMobile } from 'react-device-detect'
import { useCurrentTime } from '../hooks/currentTime'

const ProgressControl: FC = () => {
  const { videoElement, adsDone, playerState, seek, chromecast } =
    useContext(VideoPlayerContext)
  const { duration, playing, seeking, isLive, isDvr } = playerState
  const [timePopTime, setTimePopTime] = useState(0)
  const [percentDone, setPercentDone] = useState(0)
  const [mouseDown, setMouseDown] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)
  const [animate, setAnimate] = useState(true)
  const progressContainer = useRef<any>()
  const progress = useRef<any>()
  const thumb = useRef<any>()
  const timePop = useRef<any>()
  const currentTime = useCurrentTime()
  const showProgressControll =
    (isLive && isDvr && !chromecast.connected) || !isLive

  useEffect(() => {
    const onMouseDown = (e: any) => {
      window.addEventListener('mouseup', onMouseUp)
      if (progressContainer.current) {
        progressContainer.current.addEventListener('mousemove', onMouseMove)
      }
      setMouseDown(true)
      setAnimate(false)

      const x = e.offsetX
      const width = e.currentTarget.clientWidth
      setPercentDone(Math.min(100, (x / width) * 100))
    }
    const onMouseUp = () => {
      window.removeEventListener('mouseup', onMouseUp)
      if (progressContainer.current) {
        progressContainer.current.removeEventListener('mousemove', onMouseMove)
      }
      setMouseDown(false)
      if (chromecast.connected) {
        setTimeout(() => {
          setAnimate(true)
        }, 1500)
      }
    }
    const onMouseMove = (e: any) => {
      const x = e.offsetX
      const width = e.currentTarget.clientWidth
      setPercentDone(Math.min(100, (x / width) * 100))
    }
    const moveTimePop = (e: any) => {
      if (timePop.current) {
        const width = e.currentTarget.clientWidth
        const x =
          Math.min(Math.max(0, e.offsetX), width) -
          timePop.current.clientWidth / 2
        const percent = Math.max(0, e.offsetX) / width
        setTimePopTime(duration * percent)
        if (x > 0) {
          timePop.current.style.visibility = 'visible'
          timePop.current.style.left = `${x}px`
        }
        e.cancelBubble = true
        e.stopPropagation()
      }
    }
    const onMouseOver = (e: any) => {
      if (progressContainer.current) {
        progressContainer.current.addEventListener('mousemove', moveTimePop)
        setMouseOver(true)
        e.cancelBubble = true
        e.stopPropagation()
      }
    }
    const onMouseOut = (e: any) => {
      if (progressContainer.current) {
        progressContainer.current.removeEventListener('mousemove', moveTimePop)
        setMouseOver(false)
        e.cancelBubble = true
        e.stopPropagation()
      }
      if (timePop.current) {
        timePop.current.style.visibility = 'hidden'
      }
    }
    if (adsDone) {
      if (progressContainer.current) {
        progressContainer.current.addEventListener('mousedown', onMouseDown)
        progressContainer.current.addEventListener('mouseenter', onMouseOver)
        progressContainer.current.addEventListener('mouseleave', onMouseOut)
      }
      if (!mouseDown) {
        // changed(duration * (percentDone / 100))
        // seek(duration * (percentDone / 100))
      }
    }
    const containerForRemoval = progressContainer.current
    return () => {
      if (adsDone) {
        if (containerForRemoval) {
          containerForRemoval.removeEventListener('mousedown', onMouseDown)
          containerForRemoval.removeEventListener('mouseenter', onMouseOver)
          containerForRemoval.removeEventListener('mouseleave', onMouseOut)
        }
      }
    }
  }, [
    videoElement,
    mouseDown,
    mouseOver,
    adsDone,
    chromecast.connected,
    showProgressControll
  ])

  useEffect(() => {
    if (currentTime && duration && adsDone && playing && animate) {
      setPercentDone(Math.min(100, (currentTime / duration) * 100))
    }
  }, [duration, currentTime, adsDone, playing, animate])

  useEffect(() => {
    if (adsDone) {
      setPercentDone(0)
    }
  }, [adsDone])

  useEffect(() => {}, [timePopTime])

  useEffect(() => {
    if (mouseDown) {
      seek(duration * (percentDone / 100))
    }
  }, [percentDone])

  const trackClassName = () => {
    const names = []
    names.push('Progress')
    if (mouseDown) {
      names.push('pressed')
    }
    if (!adsDone) {
      names.push('ads')
    }
    return names.join(' ')
  }

  const thumbClassName = () => {
    if (mouseDown) {
      return 'Thumb pressed'
    }
    return 'Thumb'
  }

  useEffect(() => {
    if (playing) {
      setTimeout(() => {
        setAnimate(true)
      }, 1000)
    } else {
      setAnimate(false)
    }
  }, [playing])

  useEffect(() => {
    if (seeking) {
      setAnimate(false)
    } else {
      setTimeout(() => {
        setAnimate(true)
      }, 1500)
    }
  }, [seeking])

  const containerClassName = useCallback(() => {
    const names = []
    names.push('ProgressControl')
    if (!adsDone) {
      names.push('ads')
    }
    if (animate) {
      names.push('animate')
    }
    return names.join(' ')
  }, [animate, adsDone])

  if (!showProgressControll) {
    return <></>
  }

  return (
    <div className="ControlsRow">
      <div className={containerClassName()} ref={progressContainer}>
        {!isMobile && duration > 0 && (
          <div
            className="TimePop"
            ref={timePop}
            style={{ visibility: 'hidden' }}
          >
            {formatTime(timePopTime)}
          </div>
        )}
        <div className="Track">
          <div
            className={trackClassName()}
            ref={progress}
            style={{ transform: `scaleX(${percentDone / 100})` }}
          />
          {adsDone && (
            <div
              className={thumbClassName()}
              ref={thumb}
              style={{ left: `${percentDone}%` }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

ProgressControl.defaultProps = {
  changed: null
}

export default ProgressControl
