import React, { MutableRefObject } from 'react'

export function getScrollRefs(
  setUpcomingStreamsScrollRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<Element | null> | null>
  >,
  setLatestVideosScrollRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<Element | null> | null>
  >,
  setHighlightsScrollRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<Element | null> | null>
  >
) {
  const getUpcomingStreamsScrollRef = (
    upcomingRef: MutableRefObject<Element | null>
  ) => {
    setUpcomingStreamsScrollRef(upcomingRef)
  }
  const getlatestVideosScrollRef = (
    latestRef: MutableRefObject<Element | null>
  ) => {
    setLatestVideosScrollRef(latestRef)
  }
  const getHighlightsScrollRef = (
    highlightsRef: MutableRefObject<Element | null>
  ) => {
    setHighlightsScrollRef(highlightsRef)
  }
  return {
    getUpcomingStreamsScrollRef,
    getlatestVideosScrollRef,
    getHighlightsScrollRef
  }
}
