import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './CountryCodeSelector.module.scss'
import { getCountryList, Country } from 'api/countries/getCountriesList'
import { useDataApi } from 'api/useDataApi'
import { DropdownField } from 'components/Forms/DropdownListField/DropdownField/Field'
import { Loader } from 'components/Loader/Loader'
import { SearchableCountrylist } from './SearchableCountryList/SearchableCountryList'
import { useSelector } from 'react-redux'
import { countrySelector } from 'redux-store/country/country.selectors'
import { User } from 'interfaces'

interface Props {
  onCountrySelected: (country: Country) => void
  currentUser?: User
}

export const CountryCodeSelector: FC<Props> = ({
  onCountrySelected,
  currentUser
}) => {
  const defaultAlpha2 = useSelector(countrySelector)
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getCountryList, [])
  })

  const getCountryFromAplha2 =
    data &&
    data.find((country: Country) =>
      defaultAlpha2 ? country.alpha2 === defaultAlpha2 : country.alpha2 === 'EN'
    )

  useEffect(() => {
    if (currentUser) {
      if (currentUser.area_code !== null) {
        const getCountryFromCountryCode =
          data &&
          data.find(
            (country: Country) => country.countryCode === currentUser.area_code
          )
        setSelectedCountry(getCountryFromCountryCode)
      } else {
        setSelectedCountry(getCountryFromAplha2)
      }
    } else {
      setSelectedCountry(getCountryFromAplha2)
    }
  }, [currentUser, defaultAlpha2, data])

  const [showCountryList, setShowCountryList] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country>()

  const handleSelectedCountry = (country: Country) => {
    setShowCountryList(false)
    setSelectedCountry(country)
    onCountrySelected(country)
  }

  useEffect(() => {
    if (selectedCountry) {
      onCountrySelected(selectedCountry)
    }
  }, [selectedCountry])

  if (isLoading) {
    return (
      <DropdownField
        onClick={() => null}
        selected={<Loader />}
        type="dark"
        isPlaceholder={false}
        rotateArrow={false}
      />
    )
  }

  if (data && selectedCountry) {
    return (
      <>
        <DropdownField
          onClick={() => setShowCountryList(!showCountryList)}
          selected={
            <div className={styles.SelectedCountry}>
              <img src={selectedCountry.flagUrl} />
              <div>{`+ ${selectedCountry.countryCode}`}</div>
            </div>
          }
          type="dark"
          isPlaceholder={false}
          rotateArrow={showCountryList}
          customStyle={styles.FieldContainer}
        />
        {showCountryList && (
          <SearchableCountrylist
            countryList={data}
            selectedAlpha2={selectedCountry.alpha2}
            onCountrySelect={(country: Country) =>
              handleSelectedCountry(country)
            }
          />
        )}
      </>
    )
  }
  return null
}
