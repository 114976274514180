export enum ColumnVariants {
  bold = 'bold',
  form = 'form',
  team = 'team'
}

export interface StandingsColumnInterface {
  index: number
  label: string
  field: string
  variant?: ColumnVariants
}

export interface StandingsRowInterface {
  [key: string]: string | number | boolean
}

export interface StandingsInterface {
  columns: StandingsColumnInterface[]
  rows: StandingsRowInterface[]
}
