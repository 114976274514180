import React from 'react'

export const LockIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 11.5H5.5C4.39543 11.5 3.5 12.3954 3.5 13.5V20.5C3.5 21.6046 4.39543 22.5 5.5 22.5H19.5C20.6046 22.5 21.5 21.6046 21.5 20.5V13.5C21.5 12.3954 20.6046 11.5 19.5 11.5Z"
      stroke="#93979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 11.5V7.5C7.5 6.17392 8.02678 4.90215 8.96447 3.96447C9.90215 3.02678 11.1739 2.5 12.5 2.5C13.8261 2.5 15.0979 3.02678 16.0355 3.96447C16.9732 4.90215 17.5 6.17392 17.5 7.5V11.5"
      stroke="#93979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
