import React, { FC } from 'react'
import Modal from 'components/Modals/Modal/Modal'
import { SuccessContent } from './SuccessContent'
interface Props {
  title: string
  subText: string
  isOpen: boolean
  buttonLabel: string
  handleClose: () => void
  onButtonClick: () => void
}

export const SuccessModal: FC<Props> = ({
  title,
  subText,
  isOpen,
  buttonLabel,
  onButtonClick,
  handleClose
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <SuccessContent
        title={title}
        subText={subText}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
      />
    </Modal>
  )
}
