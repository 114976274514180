import React, { FC } from 'react'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'
import ShieldIcon from '../../../cards/GameScheduleCard/Team/ShieldIcon'
import TrophyIcon from '../../../lists/ChannelListItem/Icons/TrophyIcon'

interface RelatedChannelItemLogoProps {
  logoUrl?: string
  channelType: ChannelTypes
}

export const RelatedChannelItemLogo: FC<RelatedChannelItemLogoProps> = ({
  logoUrl,
  channelType
}) => {
  if (logoUrl?.length) {
    return <img src={logoUrl} alt="logo" />
  }
  switch (channelType) {
    case ChannelTypes.league:
      return <TrophyIcon />
    case ChannelTypes.club:
    default:
      return <ShieldIcon />
  }
}

RelatedChannelItemLogo.defaultProps = {
  logoUrl: undefined
}
