import React, { FC } from 'react'

export const LikedIcon: FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6665 7.8778V4.54446C11.6665 3.88142 11.4031 3.24554 10.9343 2.7767C10.4654 2.30786 9.82954 2.04446 9.1665 2.04446L5.83317 9.54446V18.7111H15.2332C15.6351 18.7157 16.0252 18.5748 16.3314 18.3145C16.6377 18.0541 16.8396 17.6919 16.8998 17.2945L18.0498 9.79446C18.0861 9.55559 18.07 9.3117 18.0026 9.07967C17.9353 8.84765 17.8183 8.63304 17.6597 8.45073C17.5012 8.26841 17.3049 8.12274 17.0845 8.02381C16.8641 7.92488 16.6248 7.87506 16.3832 7.8778H11.6665ZM5.83317 18.7111H3.33317C2.89114 18.7111 2.46722 18.5355 2.15466 18.223C1.8421 17.9104 1.6665 17.4865 1.6665 17.0445V11.2111C1.6665 10.7691 1.8421 10.3452 2.15466 10.0326C2.46722 9.72006 2.89114 9.54446 3.33317 9.54446H5.83317"
      fill="white"
    />
    <path
      d="M5.83317 18.7111H3.33317C2.89114 18.7111 2.46722 18.5355 2.15466 18.223C1.8421 17.9104 1.6665 17.4865 1.6665 17.0445V11.2111C1.6665 10.7691 1.8421 10.3452 2.15466 10.0326C2.46722 9.72006 2.89114 9.54446 3.33317 9.54446H5.83317M11.6665 7.8778V4.54446C11.6665 3.88142 11.4031 3.24554 10.9343 2.7767C10.4654 2.30786 9.82954 2.04446 9.1665 2.04446L5.83317 9.54446V18.7111H15.2332C15.6351 18.7157 16.0252 18.5748 16.3314 18.3145C16.6377 18.0541 16.8396 17.6919 16.8998 17.2945L18.0498 9.79446C18.0861 9.55559 18.07 9.3117 18.0026 9.07967C17.9353 8.84765 17.8183 8.63304 17.6597 8.45073C17.5012 8.26841 17.3049 8.12274 17.0845 8.02381C16.8641 7.92488 16.6248 7.87506 16.3832 7.8778H11.6665Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
