import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface AddAdminRequest {
  email: string
  channelSlug: string
}
export const addAdminToChannel = async (request: AddAdminRequest) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.channelSlug}/admin_invite`,
      method: ApiEndpoint.METHODS.POST,
      params: {
        email: request.email,
        role: 'admin'
      },
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    return { hasError: true, errors: [error] }
  }
}
