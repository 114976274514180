import TagManager from 'react-gtm-module'

export const triggerGTMForSettingsButton = (
  channelName: string,
  channelSlug: string,
  channelType: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'admin_settings_button_clicked',
      channel_name: channelName,
      channel_slug: channelSlug,
      channel_type: channelType
    }
  })
}

export const triggerGTMForContentManagerButton = (
  channelName: string,
  channelSlug: string,
  channelType: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_content_manager_button_clicked',
      channel_name: channelName,
      channel_slug: channelSlug,
      channel_type: channelType
    }
  })
}
