import React, { FC } from 'react'
import {
  ColumnVariants,
  StandingsColumnInterface
} from '../Standings.interface'
import classNames from 'classnames'
import styles from './MobileStandingsHeader.module.scss'
import { getTranslatedStandingsHeading } from './getTranslatedStandingsHeading'

interface StandingsHeaderProps {
  columns: StandingsColumnInterface[]
}

export const MobileStandingsHeader: FC<StandingsHeaderProps> = ({
  columns
}) => {
  return (
    <div className={styles.StandingdHeader}>
      {columns.map((column, index) => {
        const translatedLabel = getTranslatedStandingsHeading(column.label)
        return column.variant !== ColumnVariants.team ? (
          <div
            className={classNames(styles.HeaderItem, {
              [styles.Form]: column.variant === ColumnVariants.form
            })}
            key={`StandingsColumn__${index}`}
          >
            {translatedLabel}
          </div>
        ) : null
      })}
    </div>
  )
}
