import React from 'react'
export const PPVIcon = () => (
  <svg
    width="32"
    height="38"
    viewBox="0 0 32 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5077 18.9133L9.52109 9.68328C9.45443 9.64479 9.37109 9.69291 9.37109 9.76989V28.23C9.37109 28.307 9.45443 28.3551 9.52109 28.3166L25.5077 19.0865C25.5744 19.048 25.5744 18.9518 25.5077 18.9133Z"
      stroke="#2F3135"
      strokeWidth="1.5"
    />
  </svg>
)
