import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { ItemType, ToggleItem } from 'components/Toggle/ToggleItem/ToggleItem'

import styles from './TimeFilter.module.scss'

export enum TimeFilterType {
  Live = 'live',
  Previous = 'previous',
  Upcoming = 'upcoming'
}

interface Props {
  onSelectFilter: (filter: TimeFilterType) => void
  selectedFilter: TimeFilterType
}

export const TimeFilter: FC<Props> = ({ onSelectFilter, selectedFilter }) => (
  <div
    className={styles.TimeFilterContainer}
    data-testid="time-filter-btn-container"
  >
    <ToggleItem
      itemType={
        selectedFilter !== TimeFilterType.Live
          ? ItemType.Default
          : ItemType.Active
      }
      label={t`Live`}
      onClick={() => onSelectFilter(TimeFilterType.Live)}
    />
    <ToggleItem
      itemType={
        selectedFilter !== TimeFilterType.Upcoming
          ? ItemType.Default
          : ItemType.Active
      }
      label={t`Upcoming`}
      onClick={() => onSelectFilter(TimeFilterType.Upcoming)}
    />
    <ToggleItem
      itemType={
        selectedFilter !== TimeFilterType.Previous
          ? ItemType.Default
          : ItemType.Active
      }
      label={t`previous`}
      onClick={() => onSelectFilter(TimeFilterType.Previous)}
    />
  </div>
)
