import React, { FC } from 'react'
import VolumeControl from './controls/VolumeControl'
import ProgressControl from './controls/ProgressControl'
import TimeDisplay from './controls/TimeDisplay'
import FullscreenButton from './controls/FullscreenButton'
import { QualityPicker } from './controls/QualityPicker/QualityPicker'
import SubtitlePicker from './controls/SubtitlePicker/index'
import ChromeCastButton from './controls/ChromeCastButton'
import AirplayButton from './controls/AirplayButton'
import { PlayPauseIcon } from './controls/PlayPauseIcon'

import './VideoPlayerControls.scss'
import { GoLiveButton } from 'components/buttons/GoLiveButton/GoLiveButton'

interface Props {
  isLive: boolean
  isPlaying: boolean
  playButton: any
  isMuted: boolean
  isIOS: boolean
  adsDone: boolean
  videoElement: any
  hls: any
  hasSubtitles: boolean
}

export const ControlBar: FC<Props> = ({
  isPlaying,
  playButton,
  isMuted,
  isIOS,
  adsDone,
  videoElement,
  hls,
  hasSubtitles
}) => {
  return (
    <div className="ControlBar">
      <div className="Background" />
      <GoLiveButton />
      <div className="Controls">
        <ProgressControl />
        <div className="ControlsRow">
          <button ref={playButton} type="button" className="PlayerButton">
            <PlayPauseIcon isPlaying={isPlaying} isLarge={false} />
          </button>
          {!isIOS && (
            <VolumeControl value={isMuted ? 0 : videoElement?.volume || 1} />
          )}
          <TimeDisplay />
          {adsDone && (
            <>
              <ChromeCastButton />
              <AirplayButton />
              <QualityPicker hls={hls} />
            </>
          )}
          {hasSubtitles && <SubtitlePicker videoElement={videoElement} />}
          <FullscreenButton videoElement={videoElement} />
        </div>
      </div>
    </div>
  )
}
