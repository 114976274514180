import React, { FC } from 'react'
import { InfoBox } from './InfoBox'
import { ImageLink } from './ImageLink'

import {
  StartBannerContent,
  StartBannerContentType
} from './startPageBannerTypes'
interface Props {
  content: StartBannerContent
}

export const StartPageBannerSelector: FC<Props> = ({ content }) => {
  if (content.bannerType === StartBannerContentType.InfoBox) {
    return (
      <InfoBox
        title={content.title}
        content={content.content}
        buttonLabel={content.buttonLabel}
        imageUrl={content.imageUrl}
        buttonLink={content.buttonLink}
        buttonLinkTarget={content.buttonLinkTarget}
      />
    )
  }
  if (content.bannerType === StartBannerContentType.ImageLink) {
    return (
      <ImageLink
        imageUrl={content.imageUrl}
        imageLink={content.imageLink}
        imageTarget={content.imageTarget}
      />
    )
  }
  return null
}
