import React, { FC, useState } from 'react'
import styles from './PurchaseListItem.module.scss'
import dayjs from 'dayjs'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { MobileLabel } from './MobileLabel'
import { ReceiptModal } from '../ReceiptModal/ReceiptModal'

export interface TransactionInformation {
  createdAt: number
  channelName: string
  channelSlug: string
  purchasedObjectName: string
  purchasedObjectSlug: string
  price: number
  currency: string
  refNumber: string
  vat: number
}

export const PurchaseListItem: FC<TransactionInformation> = ({
  createdAt,
  channelName,
  channelSlug,
  purchasedObjectName,
  purchasedObjectSlug,
  price,
  currency,
  refNumber,
  vat
}) => {
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const currentBreakpoint = useCurrentBreakpoint()
  const formatedDate = dayjs.unix(createdAt).format('YYYY-MM-DD HH:mm')
  const amount = `${price} ${currency}`
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  const channelUrl = `${baseUrl}/${channelSlug}`
  const objecSlug = `${baseUrl}/${purchasedObjectSlug}`

  return (
    <div className={styles.PurchaseListItem}>
      <div className={styles.Information}>
        <div className={styles.Date}>
          <MobileLabel isMobile={isMobile} label={t`Date`} />
          {formatedDate}
        </div>
        <div className={styles.ChannelName}>
          <MobileLabel isMobile={isMobile} label={t`Channel`} />
          <a href={channelUrl} target={LinkTarget.NewWindow}>
            {channelName}
          </a>
        </div>
        <div className={styles.ObjectName}>
          <MobileLabel isMobile={isMobile} label={t`Object`} />
          <a href={objecSlug} target={LinkTarget.NewWindow}>
            {purchasedObjectName}
          </a>
        </div>
        <div className={styles.Amount}>
          <MobileLabel isMobile={isMobile} label={t`Amount`} />
          {amount}
        </div>
      </div>
      <Button
        type={ButtonType.GhostWithBoarder}
        label={t`Receipt`}
        buttonSize="Small"
        onClick={() => setShowReceiptModal(true)}
        customStyle={styles.ReceiptButton}
      />
      {showReceiptModal && (
        <ReceiptModal
          description={purchasedObjectName}
          referenceNumber={refNumber}
          createdAt={createdAt}
          price={price}
          currency={currency}
          vat={vat}
          isOpen={showReceiptModal}
          onClose={() => setShowReceiptModal(false)}
        />
      )}
    </div>
  )
}
