import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { languageSelector } from '../../../redux-store/language/language.selectors'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

export enum LinkType {
  Route = 'route',
  AnchorTag = 'link'
}
export interface MenuLink {
  type: LinkType
  link: string | string[]
  translationText: string
  linkTarget?: LinkTarget
  showInBreadcrumb?: boolean
  onClick?: () => void
}

const PageLink: FC<MenuLink> = ({
  link,
  type,
  translationText,
  linkTarget,
  onClick
}) => {
  const lang = useSelector(languageSelector)
  const parsedLink: string = Array.isArray(link)
    ? `${link[0]}/${lang}/${link[1]}`
    : link
  const target =
    linkTarget && [LinkTarget.NewWindow].includes(linkTarget)
      ? LinkTarget.NewWindow
      : LinkTarget.CurrentWindow

  return type !== 'link' ? (
    <Link to={parsedLink} onClick={onClick}>
      <Trans id={translationText} />
    </Link>
  ) : (
    <a href={parsedLink} target={target}>
      <Trans id={translationText} />
    </a>
  )
}
export default PageLink
