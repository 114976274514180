import React, { useCallback, useEffect, useState } from 'react'
import { t } from '@lingui/macro'

import { useSelector } from 'react-redux'
import {
  CustomDomainStatus,
  getCustomDomainStatus
} from '../../../../api/channel/settings/getCustomDomainStatus'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { DomainRegistrationStatus } from './CustomDomain/DomainStatus/DomainRegistrationStatus'

import { BrandingHeader } from './BrandingHeader/BrandingHeader'
import { Divider } from '../../../../components/page/header/components/hamburgerMenuContent/divider/Divider'
import { Accordion } from '../../../../components/Accordion/Accordion'
import { AccordionTheme } from '../../../../components/Accordion/AccordionItem/AccordionItem'

import { Loader } from '../../../../components/Loader/Loader'

import { ProductTypeID } from './BrandingHeader/ProductTypes'
import { getBrandingAccordionList } from './BrandingAccordionList'
import { CustomUrlIcon } from './CustomDomain/CustomUrlIcon'
import { CustomDomain } from './CustomDomain/CustomDomain'

export const Branding = () => {
  const initialStatus: CustomDomainStatus = {
    status: DomainRegistrationStatus.NotSet,
    customDomain: ''
  }
  const [status, setStatus] = useState(initialStatus)
  const [isLoading, setIsLoading] = useState(false)

  const channel = useSelector(channelSelector)
  const channelSlug = channel.data?.subdomain
  const channelProductType = channel?.data?.productVariant.id

  const handleCheckDomain = useCallback(async () => {
    setIsLoading(true)
    const domainStatus = await getCustomDomainStatus(channelSlug)
    setStatus(domainStatus)
    setIsLoading(false)
  }, [channelSlug])

  useEffect(() => {
    if (channelSlug) {
      handleCheckDomain()
    }
  }, [handleCheckDomain, channelSlug])

  if (!channel.data) return <Loader />

  const staticAccordionList = getBrandingAccordionList(channelSlug)
  const accordionList =
    !isLoading && status.customDomain !== ''
      ? [
          {
            id: 'custom-url',
            headerText: t`Custom URL`,
            icon: CustomUrlIcon,
            children: (
              <CustomDomain
                onCheckDomain={handleCheckDomain}
                domainStatus={status}
                channelSlug={channelSlug}
              />
            )
          },
          ...staticAccordionList
        ]
      : staticAccordionList
  return (
    <div>
      <BrandingHeader />
      <Divider />
      {channelProductType === ProductTypeID.PoweredBy && (
        <Accordion
          theme={AccordionTheme.White}
          items={accordionList}
          initialOpenItems={[]}
        />
      )}
    </div>
  )
}
