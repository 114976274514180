import { AnyAction } from 'redux'
import { LOCATION } from './location.constants'

export interface Location {
  pages: { pathName: string }[] | any[]
}
const initinalState = {
  pages: []
}
export const location = (
  state: Location = initinalState,
  action: AnyAction
) => {
  if (!action.type) {
    return state
  }

  switch (action.type) {
    case LOCATION.SET_LOCATION:
      if (
        state.pages.length > 0 &&
        action.location.pathName ===
          state.pages[state.pages.length - 1].pathName
      )
        return { ...state }
      return {
        ...state,
        pages: [...state.pages, action.location]
      }
    default:
      return state
  }
}
