import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'

interface UserSubscriptionsApiResponse {
  id: number
  expires_at: number
  auto_renew: boolean
  company_subscription: {
    id: number
    title: string
    body: string
    company: {
      name: string
      subdomain: string
    }
    expires_in: number
    expires_at: number
    image_url: string
    published_at: number
    free_days: number
    promoted: boolean
    price: {
      SEK: number
      NOK: number
      EUR: number
      USD: number
    }
    subscription_type: string
    timeframe: string
    all_access: boolean
    has_trial: boolean
    auto_renew: boolean
    partner_type_name: string
    user_subscription: any
    is_gift_card: boolean
  }
  credit_card: any
  root_domain: string
}

export const getUserSubscriptions = async () => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/subscriptions',
      authenticatable: true,
      method: ApiEndpoint.METHODS.GET
    })

    const responseData = await resultResponse
    const mappedResponse = mapUserSubscriptionResponse(responseData.data)
    return { data: mappedResponse }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapUserSubscriptionResponse = (
  apiResonse: UserSubscriptionsApiResponse[]
) =>
  apiResonse.map((subscription: UserSubscriptionsApiResponse) => ({
    id: subscription.id,
    title: subscription.company_subscription.title,
    channelName: subscription.company_subscription.company.name,
    channelSlug: subscription.company_subscription.company.subdomain,
    rootDomain: subscription.root_domain,
    price: subscription.company_subscription.price,
    timeFrame: subscription.company_subscription.timeframe,
    autoRenew: subscription.auto_renew
  }))
