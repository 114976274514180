import PERMISSIONS from './permissions.constants'
import { RolesUpdatedPayload } from './permissions.interface'

const rolesUpdated = (payload: RolesUpdatedPayload) => {
  return {
    type: PERMISSIONS.ROLES_UPDATED,
    ...payload
  }
}

const permissionsActions = {
  rolesUpdated
}

export default permissionsActions
