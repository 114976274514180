import React, { FC } from 'react'

import styles from './AdContainer.module.scss'

interface Props {
  adContainer: any
}

export const AdContainer: FC<Props> = ({ adContainer }) => (
  <div className={styles.VideoAds}>
    <div className={styles.VideoAdsInner}>
      <div className={styles.AdContainer} id="ad-container" ref={adContainer} />
    </div>
  </div>
)
