import React, { FC } from 'react'

import { Field, FormikProps } from 'formik'
import { TextInput } from 'components/Forms/TextInputField/TextInput'

import styles from './CustomLinkFormFields.module.scss'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { t } from '@lingui/macro'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import classNames from 'classnames'
export interface CustomLinkForm {
  textField: string
  linkField: string
  target: LinkTarget
}

interface Props {
  formik: FormikProps<any>
  textFieldLabel: string
  textFieldPlaceHolder: string
  textFieldName: string
  linkFieldName: string
  linkFieldLabel: string
  showAlwaysAsColumn: boolean
}

export const CustomLinkFormFields: FC<Props> = ({
  formik,
  textFieldLabel,
  textFieldPlaceHolder,
  linkFieldLabel,
  textFieldName,
  linkFieldName,
  showAlwaysAsColumn
}) => {
  const toggleIsOn = formik.values.target === LinkTarget.NewWindow

  const onHasTargetChanged = () => {
    if (toggleIsOn) {
      formik.setFieldValue('target', LinkTarget.CurrentWindow)
    } else {
      formik.setFieldValue('target', LinkTarget.NewWindow)
    }
    formik.setFieldTouched('target', true, false)
  }
  return (
    <div className={styles.Container}>
      <div
        className={classNames(styles.FieldContainer, {
          [styles.AlwaysColumns]: showAlwaysAsColumn
        })}
      >
        <div className={styles.TextInput}>
          <Field
            component={TextInput}
            id={textFieldName}
            name={textFieldName}
            label={textFieldLabel}
            placeholder={textFieldPlaceHolder}
            onChange={formik.handleChange}
            textValue={formik.values[textFieldName]}
            isValid={!formik.errors[textFieldName]}
            validationMessage={formik.errors[textFieldName]}
          />
        </div>
        <div className={styles.TextInput}>
          <Field
            component={TextInput}
            id={linkFieldName}
            name={linkFieldName}
            label={linkFieldLabel}
            placeholder="https://link.com/link"
            onChange={formik.handleChange}
            textValue={formik.values[linkFieldName]}
            isValid={!formik.errors[linkFieldName]}
            validationMessage={formik.errors[linkFieldName]}
          />
        </div>
      </div>
      <div className={styles.ToggleSwitchContainer}>
        <ToggleSwitch
          onToggle={onHasTargetChanged}
          isOn={toggleIsOn}
          label={t`Open link in new tab`}
          id="open-in-new-window"
        />
      </div>
    </div>
  )
}
