import { CreditCardType } from 'components/cards/CreditCard/CreditCard'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
interface CreditCardsApiResponse {
  RecurringDetail: {
    additionalData: { cardBin: string }
    alias: string
    aliasType: string
    card: {
      expiryMonth: string
      expiryYear: string
      holderName: string
      number: string
    }
    contractTypes: string[]
    creationDate: string
    firstPspReference: string
    paymentMethodVariant: string
    recurringDetailReference: string
    variant: string
  }
}
export interface CreditCardInterface {
  brandType: CreditCardType
  code: string
  cardHolderName: string
  expirationYear: number
  expirationMonth: number
  referenceId: string
}

export const getCreditCards = async () => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'users/credit_cards',
      method: ApiEndpoint.METHODS.GET,
      authenticatable: true
    })

    const responseData = await resultResponse
    const mappedResponse = mapCreditCardResponse(responseData.data)
    return { data: mappedResponse }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapCreditCardResponse = (apiResonse: CreditCardsApiResponse[]) =>
  apiResonse.map((creditCard: CreditCardsApiResponse) => ({
    brandType: creditCard.RecurringDetail.variant,
    code: creditCard.RecurringDetail.card.number,
    cardHolderName: creditCard.RecurringDetail.card.holderName,
    expirationYear: creditCard.RecurringDetail.card.expiryYear,
    expirationMonth: creditCard.RecurringDetail.card.expiryMonth,
    referenceId: creditCard.RecurringDetail.recurringDetailReference
  }))
