import React, { FC } from 'react'
import classNames from 'classnames'
import styles from '../RowCell.module.scss'
import { CellType } from './DefaultRowCell'

interface Props {
  rowNumber: number
  type?: CellType
}
export const RankRowCell: FC<Props> = ({
  rowNumber,
  type = CellType.DefaultTable
}) => (
  <div
    className={classNames(styles.RowCell, {
      [styles.DefaultRankRowCell]: type === CellType.DefaultTable,
      [styles.CategoryLeaderRankRowCell]: type === CellType.CategoryLeader
    })}
  >
    {rowNumber}
  </div>
)
