import React, { FC } from 'react'

import { FormModal } from 'features/sportData/FormModal/FormModal'
import { getInformationModalContent } from './getInformationModalContent'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { CustomContentAccordionListId } from '../customContentAccordionList'
import styles from './InformationModal.module.scss'

interface Props {
  customContenttype: CustomContentAccordionListId
  onClose: () => void
  defaultOpen: boolean
}
export const InformationModal: FC<Props> = ({
  customContenttype,
  defaultOpen,
  onClose
}) => {
  const breakpoint = useCurrentBreakpoint()

  const content = getInformationModalContent(customContenttype)
  const mobileImage = content.mobileImageUrl
    ? content.mobileImageUrl
    : content.imageUrl
  const imageUrl = breakpoint < Breakpoint.sm ? mobileImage : content.imageUrl
  return (
    <FormModal
      modalHeader={t`Information`}
      preHeader=""
      header={content.header}
      modalInformation={content.textContent}
      onClose={onClose}
      defaultOpen={defaultOpen}
    >
      <div className={styles.Image}>
        <img src={imageUrl} alt={content.header} />
      </div>
      <Button
        buttonSize="Medium"
        type={ButtonType.Secondary}
        label={t`Got it`}
        onClick={onClose}
      />
    </FormModal>
  )
}
