import { AccessRestrictionType } from 'interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { Price } from 'interfaces/Price.interface'

interface MediaObjectAccessApiResponse {
  available: string[]
  current: { access_type: AccessRestrictionType; price: Price }
  default: { access_type: AccessRestrictionType; price: Price }
  minimum: { price: Price }
}

export const getMediaObjectAccess = async ({
  gameIdent,
  mediaObjectSlug,
  channelSlug
}: {
  gameIdent: string
  mediaObjectSlug: string
  channelSlug: string
}) => {
  const params = gameIdent
    ? { company: channelSlug, game_ident: gameIdent }
    : { company: channelSlug, media_object_slug: mediaObjectSlug }
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'media_object/watch/access',
      method: ApiEndpoint.METHODS.GET,
      params,
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped = mapResponse(responseData.data)
    return { data: mapped }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
export interface MediaObjectAccess {
  availableAccessTypes: string[]
  current: { accessType: AccessRestrictionType; price: Price }
  default: { accessType: AccessRestrictionType; price: Price }
  minimumPrice: { price: Price }
}

const mapResponse = (
  apiResponse: MediaObjectAccessApiResponse
): MediaObjectAccess => ({
  availableAccessTypes: apiResponse.available,
  current: {
    accessType: apiResponse.current.access_type,
    price: apiResponse.current.price
  },
  default: {
    accessType: apiResponse.default.access_type,
    price: apiResponse.default.price
  },
  minimumPrice: apiResponse.minimum
})
