import React, { FC, useCallback, useRef, useState } from 'react'
import Lottie from 'lottie-react'
import animationData from './circleLoader.json'
import useLoadingStatus from '../../../../hooks/LoadingStatus'
import CameraIcon from '../Images/CameraIcon'
import { uploadImageFile } from '../../../../api/uploadImage/uploadImageFile'
import {
  StatusMessage,
  StatusMessageType
} from 'pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'
import IconButtonRound, {
  RoundButtonType
} from 'components/buttons/IconButtonRound/IconButtonRound'
import styles from './imageUpload.module.scss'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
}
type Props = {
  path: string
  onImageUploaded: (image: any) => void
}

export const ImageUploadOverlayButton: FC<Props> = ({
  path,
  onImageUploaded
}) => {
  const loadingStatus = useLoadingStatus()
  const [errorMessage, setErrorMessage] = useState('')
  const [showStatusMessage, setShowStatusMessage] = useState(false)

  const inputFile: any = useRef(undefined)

  const selectImage = useCallback(onImageUploaded, [])

  const openImageSelector = () => {
    if (inputFile) {
      inputFile.current.click()
    }
  }

  const uploadImage = async (imageFile: any) => {
    const response = await uploadImageFile(
      loadingStatus,
      path,
      imageFile,
      selectImage
    )
    if (!response?.isSuccess && response?.message) {
      setErrorMessage(response?.message)
      setShowStatusMessage(true)
      loadingStatus.reset()
    }
  }
  const onChangeImage = (event: any) => {
    setShowStatusMessage(false)
    if (event.target.files) {
      const files: Array<File> = Array.from(event.target.files)
      const formData = new FormData()
      formData.append('image', files[0])
      uploadImage(formData)
    }
  }

  return (
    <>
      <div
        data-testid="image-upload-button-container"
        className={styles.ImageUploadContainer}
      >
        {loadingStatus.isLoading() ? (
          <div className={styles.Spinner}>
            <Lottie {...defaultOptions} />
          </div>
        ) : (
          <IconButtonRound
            aria-label="change image"
            buttonSize={'Medium'}
            type={RoundButtonType.Light}
            onClick={() => openImageSelector()}
          >
            <CameraIcon />
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={onChangeImage}
            />
          </IconButtonRound>
        )}
      </div>
      {showStatusMessage && errorMessage && (
        <StatusMessage
          message={errorMessage}
          onClose={() => {
            setShowStatusMessage(false)
            setErrorMessage('')
          }}
          messageType={StatusMessageType.Error}
        />
      )}
    </>
  )
}
