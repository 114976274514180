import React, { FC } from 'react'
import styles from './NotSignedInContent.module.scss'
import { SolidsportAccountLightLogo } from 'assets/brandLogos/SolidsportAccountLight'
import { Trans } from '@lingui/macro'
import { OpenSignupButton } from 'components/buttons/UserAccessButtons/OpenSignupButton'
import { OpenLoginButton } from 'components/buttons/UserAccessButtons/OpenLoginButton'
import { ButtonType } from 'components/buttons/TextIconButton'
import { DefaultFooter } from 'components/page/footer/defaultFooter/DefaultFooter'
import { locales } from 'translations/i18n'

export const NotSignedInContent: FC = () => {
  const headerImgUrl =
    'http://www.solidsport.com/wp-content/uploads/2022/09/background-1.png'
  const companyName = 'Solidsport AB'

  return (
    <div className={styles.NotSignedInContent}>
      <div className={styles.InnerContainer}>
        <img src={headerImgUrl} alt="team standing together in sun" />
        <div className={styles.Content}>
          <div className={styles.TopContent}>
            <SolidsportAccountLightLogo />
            <h1 className={styles.Text}>
              <Trans>Trusted by a Global Sports Community.</Trans>
              <br />
              All sports. Everywhere.
            </h1>
          </div>
          <div className={styles.ButtonContainer}>
            <OpenSignupButton isRounded />
            <OpenLoginButton buttonType={ButtonType.Primary} isRounded />
          </div>
        </div>
      </div>
      <DefaultFooter companyName={companyName} languages={locales} />
    </div>
  )
}
