import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration.js'

dayjs.extend(duration)

export const formatTime = (time: number): string => {
  if (!time) {
    return '00:00'
  }
  if (time < 60 * 60) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time - 60 * minutes)
    return dayjs.duration({ minutes, seconds }).format('mm:ss')
  }

  return dayjs.duration(time, 'seconds').format('HH:mm:ss')
}
