import React, { FC, useCallback, useMemo } from 'react'
import styles from './InfoSection.module.scss'

import { t, Trans } from '@lingui/macro'
import { CopyLinkField } from 'components/Forms/CopyLinkField/CopyLinkField'
import BroadcastIcon from 'assets/icons/BroadcastIcon'
import { LinkIcon } from 'pages/ChannelPage/ChannelSettings/Branding/CustomLinks/LinkIcon'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { useDataApi } from 'api/useDataApi'
import { getBroadcastInformation } from 'api/stream/getBroadcastInformation'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

import { streamSelector } from 'redux-store/stream/stream.selectors'

import { Loader } from 'components/Loader/Loader'
import { VideoPlayerData } from 'features/viewerPage/viewerPage.interfaces'

export const InfoSection: FC = () => {
  const channel = useSelector(channelSelector)
  const channelSlug = channel?.data?.subdomain
  const stream = useSelector(streamSelector)
  const mediaPlayerData: VideoPlayerData = stream.data
  const mediaIdent = mediaPlayerData?.mediaObject?.id
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getBroadcastInformation, []),
    parameters: useMemo(
      () => ({
        channelSlug: channelSlug,
        mediaObjectIdent: mediaIdent
      }),
      [channelSlug, mediaIdent]
    )
  })
  if (isLoading) {
    return <Loader />
  }
  if (!isLoading && data && data.data) {
    const streamKey = data.data.streamKey
    const streamUrl = data.data.streamURL
    return (
      <div className={styles.InfoSection}>
        <FormSectionTitle title={t`Broadcast information`} />
        <p className={styles.SubText}>
          <Trans>
            If you are not using our broadcaster app, please use the stream url
            and stream key below.
          </Trans>
        </p>

        <div className={styles.FieldContainer}>
          <CopyLinkField
            icon={<LinkIcon />}
            label={t`Stream URL`}
            textValue={streamUrl}
          />
          <CopyLinkField
            icon={<BroadcastIcon />}
            label={t`Stream key`}
            textValue={streamKey}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <Trans>
          Something went wrong when trying to get the broadcast information,
          please try again
        </Trans>
      </div>
    )
  }
}
