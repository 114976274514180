import React, { FC } from 'react'

import { AlertType } from './getAlertType'
import { ErrorIcon } from 'assets/icons/ErrorIcon'
import { SuccesIcon } from 'assets/icons/SuccessIcon'
import { NoticeIcon } from 'assets/icons/NoticeIcon'
import { GeneralIcon } from 'assets/icons/GeneralIcon'
import { InformationIcon } from 'assets/icons/InformationIcon'

interface Props {
  alertType: AlertType
}
export const MessageIcon: FC<Props> = ({ alertType }) => {
  switch (alertType) {
    case AlertType.Error:
      return <ErrorIcon />
    case AlertType.Success:
      return <SuccesIcon />
    case AlertType.Warning:
      return <NoticeIcon />
    case AlertType.General:
      return <GeneralIcon />
    case AlertType.Info:
      return <InformationIcon />
    default:
      return null
  }
}
