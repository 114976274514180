import React, { FC } from 'react'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows
} from '../Table/PlayerStatisticsTable.interface'
import styles from './CategoryLeader.module.scss'
import { getSortedList } from '../Table/getSortedList'
import { CategoryLeaderRow } from './CategoryLeaderRow/CategoryLeaderRow'
import { CategoryLeaderFirstPlace } from './CategoryLeaderFirstPlace/CategoryLeaderFirstPlace'

interface Props {
  title: string
  statsIdentifier: string
  rows: PlayerStatisticsTableRows[]
  columns: PlayerStatisticsTableColumns[]
}

export const CategoryLeader: FC<Props> = ({
  title,
  statsIdentifier,
  rows,
  columns
}) => {
  const totalRowsToShow = 5
  const sortedRows = getSortedList(rows, statsIdentifier, true)
  const slicedSortedRows = sortedRows.slice(0, totalRowsToShow)
  return (
    <div className={styles.CategoryLeaderContainer}>
      <h5 className={styles.Title}>{title}</h5>
      <div className={styles.RankingContainer}>
        {slicedSortedRows?.map(
          (row: PlayerStatisticsTableRows, number: number) => {
            const ranking = number + 1
            if (ranking === 1) {
              return (
                <CategoryLeaderFirstPlace
                  row={row}
                  key={'category-first-place'}
                  selectedStatsIdentifier={statsIdentifier}
                  rank={ranking}
                />
              )
            } else {
              return (
                <CategoryLeaderRow
                  columns={columns}
                  row={row}
                  rowNumber={ranking}
                  key={`category-leader-place-${ranking}`}
                  selectedColumn={statsIdentifier}
                />
              )
            }
          }
        )}
      </div>
    </div>
  )
}
