import React, { FC, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { DropFileZone } from '../../../../../../components/DropFileZone/DropFileZone'
import { UPLOAD_TYPE } from '../../../../../../components/DropFileZone/UploadType'
import { FormValidationError } from '../../../../../../components/Forms/FormValidationError/FormValidationError'
import styles from './UploadLogo.module.scss'
import { LoadingUpload } from './LoadingUpload/LoadingUpload'
import { DisplayLogo } from './DisplayLogo/DisplayLogo'
import { getRejectionErrorText } from './getRejectionErrorText'
import { ApiStatus } from './ApiStatus.interface'

export interface RecommendedDimensions {
  width: number
  height: number
}

interface UploadLogoProps {
  label: string
  initialImageSrc?: string
  onLogoUpload: (file: File) => Promise<ApiStatus>
  variant: UPLOAD_TYPE
  isThemed: boolean
  recommendedDimensions: RecommendedDimensions
}

export const UploadLogo: FC<UploadLogoProps> = ({
  label,
  initialImageSrc,
  onLogoUpload,
  variant,
  isThemed,
  recommendedDimensions
}) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(initialImageSrc)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const handleUpload = async (file: File) => {
    if (error) {
      setError(undefined)
    }
    setIsUploading(true)
    const uploadingPromise = onLogoUpload(file)
    const src = URL.createObjectURL(file)
    setImageSrc(src)

    const response: ApiStatus = await uploadingPromise
    if (!response.isSuccess) {
      setImageSrc(undefined)
      if (response.message) {
        setError(response.message)
      }
    }
    setIsUploading(false)
  }

  const handleError = (rejected: FileRejection) => {
    const errorCode = rejected.errors[0].code
    const errorText = getRejectionErrorText(errorCode, variant)
    setError(errorText)
  }

  return (
    <div data-testid="upload-logo-container" className={styles.LogosItem}>
      <div className={styles.LogosItemLabel}>{label}</div>
      {isUploading && imageSrc ? (
        <LoadingUpload imageSrc={imageSrc} />
      ) : imageSrc ? (
        <DisplayLogo
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          isThemed={isThemed}
        />
      ) : (
        <DropFileZone
          onUploadAccepted={handleUpload}
          onUploadRejected={handleError}
          type={variant}
          recommendedImageHeight={recommendedDimensions.height.toString()}
          recommendedImageWidth={recommendedDimensions.width.toString()}
        />
      )}
      {!!error && <FormValidationError errorMessage={error} />}
    </div>
  )
}
