import React, { FC } from 'react'
import { Icon } from '@mui/material'
import { Pause, PlayArrow } from '@mui/icons-material'

import styles from './Button.module.scss'

interface Props {
  isPlaying: boolean
  isLarge: boolean
}

export const PlayPauseIcon: FC<Props> = ({ isPlaying, isLarge }) => (
  <Icon
    component={isPlaying ? Pause : PlayArrow}
    className={
      isLarge ? styles.OverlayIcon + ' ' + styles.LargeOverlayIcon : ''
    }
  />
)
