import React, { FC, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik } from 'formik'
import { t } from '@lingui/macro'
import Delta from 'quill-delta'

import { InfoBoxFormFields } from './InfoBoxFormFields'
import { FormValidationError } from '../../../../components/Forms/FormValidationError/FormValidationError'
import { postCustomBanner } from 'api/customContent/postCustomBanner'
import { infoBoxSchema } from './infoBoxValidationSchema'
import { FormButtons } from '../FormButtons/FormButtons'

import styles from './InfoBoxForm.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface Props {
  onSubmitSuccess: () => void
  onCancel: () => void
  channelSlug: string
  existingForm?: InfoBox
}

export interface InfoBox {
  title: string
  text?: Delta
  hasButton: boolean
  textField?: string
  linkField?: string
  imageFile?: any
  imageUrl?: string
  target?: LinkTarget
}

export const InfoBoxForm: FC<Props> = ({
  onSubmitSuccess,
  existingForm,
  onCancel,
  channelSlug
}) => {
  const [apiError, setApiError] = useState<string | undefined>()

  const initialValues: InfoBox = {
    title: existingForm?.title || '',
    text: existingForm?.text || undefined,
    hasButton: existingForm?.hasButton || false,
    textField: existingForm?.textField || '',
    linkField: existingForm?.linkField || '',
    target: existingForm?.target || LinkTarget.CurrentWindow,
    imageUrl: existingForm?.imageUrl || undefined,
    imageFile: undefined
  }

  const onSubmit = async (
    values: InfoBox,
    formikHelpers: FormikHelpers<InfoBox>
  ) => {
    formikHelpers.setSubmitting(true)

    const formValues = { ...values, channelSlug }
    const response = await postCustomBanner({
      ...formValues
    })

    if (response.hasError) {
      setApiError(
        t`There was a problem processing your request, please try again. If the data you entered appears to be correct, contact our support team for more help`
      )
      formikHelpers.setSubmitting(false)
    } else {
      formikHelpers.setSubmitting(false)
      onSubmitSuccess()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={infoBoxSchema}
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
    >
      {(props: FormikProps<InfoBox>) => (
        <Form>
          <div className={styles.InfoBoxForm}>
            <InfoBoxFormFields formik={props} />
            {apiError && (
              <div className={styles.FormError}>
                <FormValidationError errorMessage={apiError} />
              </div>
            )}
            <FormButtons formik={props} onCancelClick={onCancel} />
          </div>
        </Form>
      )}
    </Formik>
  )
}
