import React from 'react'

export const PlayStreamIcon = () => (
  <svg
    width="80"
    height="92"
    viewBox="0 0 80 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.8501 44.3703L4.15002 1.8187C2.75002 1.01039 1 2.02075 1 3.63734V88.7405C1 90.3571 2.75002 91.3674 4.15002 90.5591L77.8501 48.0076C79.2501 47.1993 79.2501 45.1786 77.8501 44.3703Z"
      fill="white"
      stroke="white"
      strokeOpacity="0.85"
      strokeWidth="1.5"
    />
  </svg>
)
