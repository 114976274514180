import BroadcastWhite from './IconTitleText/Images/BroadcastWhite.png'
import TelescopeWhite from './IconTitleText/Images/Telescope.png'
import PaidWhite from './IconTitleText/Images/PaidWhite.png'
import WebcamWhite from './IconTitleText/Images/WebcamWhite.png'
import IconCup from './IconTitleText/Images/Iconcup.png'
import IconTeams from './IconTitleText/Images/IconTeams.png'
import IconCamera from './IconTitleText/Images/iconCamera.png'
import IconReport from './IconTitleText/Images/iconReport.png'
import IconCloud from './IconTitleText/Images/iconCloud.png'

export const WhatYouGetMockData = {
  sectionTitle: 'Why choose SolidSport?',
  backgroundColor: 'Black',

  content: [
    {
      id: 'channel',
      title: 'Your own channel',
      text: 'All video content you broadcast live and upload is collected on your own unique Solidsport channel.',
      iconPath: BroadcastWhite
    },
    {
      id: 'money',
      title: 'Make money for your team',
      text: 'You can choose to charge for your live broadcasts, then we will help you with payments and support. You get at least 50% of the revenue',
      iconPath: PaidWhite
    },
    {
      id: 'broadcast',
      title: 'Broadcast',
      text: 'Broadcasting live is easy if you use the Solidsport Broadcast app on a smartphone or tablet.',
      iconPath: WebcamWhite
    },
    {
      id: 'report',
      title: 'Report',
      text: 'With the help of Solidsport Report, you can easily create and share more advanced graphics in the broadcasts.',
      iconPath: IconReport
    },
    {
      id: 'highlights',
      title: 'Highlights',
      text: 'With a simple push of a button, you can create highlights that are automatically cut together into a highlights package.',
      iconPath: PaidWhite
    },
    {
      id: 'storage',
      title: 'Unlimited storage',
      text: 'Everything you send, is saved and becomes memories for life. It can also be used for analysis and for educational purposes.',
      iconPath: IconCloud
    }
  ]
}

export const MoreUsersMockData = {
  sectionTitle: 'More users',
  backgroundColor: 'Black',

  content: [
    {
      id: 'teams',
      title: 'Teams',
      text: 'Live broadcasts the team´s matches and makes the team coffers grow',
      iconPath: IconTeams
    },
    {
      id: 'associations',
      title: 'Associations',
      text: 'Gathers all content and all teams in a common structure.',
      iconPath: TelescopeWhite
    },
    {
      id: 'alliance',
      title: 'Alliance',
      text: 'Gathers the whole sport on a common platform.',
      iconPath: WebcamWhite
    },
    {
      id: 'leagues',
      title: 'Leagues',
      text: 'Collects all matches on a common league channel.',
      iconPath: TelescopeWhite
    },
    {
      id: 'cups',
      title: 'Cups',
      text: 'Live broadcasts all matches. Makes money. And makes the cup grow.',
      iconPath: IconCup
    },
    {
      id: 'events',
      title: 'Events',
      text: 'Reach the audience online. And make money.',
      iconPath: BroadcastWhite
    }
  ]
}

export const LiveStreamsForEveryoneMockData = {
  sectionTitle: 'Live broadcasts that works for everyone',
  backgroundColor: 'Red',
  content: [
    {
      id: 'app',
      title: 'Via the Broadcast app',
      text: 'Broadcasting live is easy if you use the Solidsport Broadcast app on a smartphone or tablet.',
      iconPath: BroadcastWhite
    },
    {
      id: 'production',
      title: 'Traditional production',
      text: 'Solidsport offers complete solutions for a traditional production with several cameras.',
      iconPath: IconCamera
    },
    {
      id: 'ai',
      title: 'AI Camera',
      text: 'A fully automated system that broadcasts matches live without any person behind the camera. Fully integrated with SolidSport´s streaming platform.',
      iconPath: WebcamWhite
    }
  ]
}
