import React, { FC, KeyboardEvent, MouseEvent } from 'react'
import { Plural, t } from '@lingui/macro'
import classNames from 'classnames'
import { TeamItemProps, TeamItemStatus } from './TeamItemProps'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import { AddIcon } from '../../../GameScheduleListContainer/AddIcon'
import { getTranslatedTeamStatus } from './getTranslatedTeamItemStatus'
import { ArrowRight2 } from './ArrowRight2'
import {
  useCurrentBreakpoint,
  Breakpoint
} from '../../../../../hooks/CurrentBreakpoint'

import styles from './TeamItem.module.scss'

export const TeamItem: FC<TeamItemProps> = ({
  teamName,
  teamSlug,
  ongoingCompetitions,
  status,
  onClickTeamItem,
  onAddCompetitionToTeam
}) => {
  const breakPoint = useCurrentBreakpoint()

  const onSelectTeamItem = (
    event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>
  ) => {
    if (event && event.currentTarget?.id === 'import-button') {
      onAddCompetitionToTeam()
    }
    if (event && event.currentTarget?.id === 'team-item-button') {
      onClickTeamItem()
    }

    event.stopPropagation()
  }

  return (
    <div
      className={styles.TeamItem}
      key={teamSlug}
      role="button"
      tabIndex={0}
      id="team-item-button"
      onClick={onSelectTeamItem}
      onKeyPress={onSelectTeamItem}
    >
      <div className={styles.TeamName}>{teamName}</div>

      {breakPoint >= Breakpoint.sm &&
        (ongoingCompetitions > 0 ? (
          <div className={styles.OngoingCompetitions}>
            <Plural
              value={ongoingCompetitions}
              one="1 competition"
              other="# competitions"
            />
          </div>
        ) : (
          <div className={styles.ImportButton}>
            <IconTextButton
              id="import-button"
              type={ButtonType.Ghost}
              label={t`Connect competition`}
              buttonSize="Small"
              // @ts-ignore
              onClick={onSelectTeamItem}
            >
              <AddIcon />
            </IconTextButton>
          </div>
        ))}
      <div
        className={classNames(styles.Status, {
          [styles.Active]: status === TeamItemStatus.Active,
          [styles.NotActive]: status === TeamItemStatus.NotActive,
          [styles.Disabled]: status === TeamItemStatus.Disabled
        })}
      >
        {getTranslatedTeamStatus(status)}
      </div>
      <div className={styles.Icon}>
        <ArrowRight2 />
      </div>
    </div>
  )
}
