import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { links, ChannelMenuLink } from '../menuLinks'
import { getMetaDescription, parseHTMLContent } from './getMetaDescription'
import { useTranslate } from '../../../../hooks/Translate'
import { metaFriendlyString } from '../../../../helpers/generalHelpers'

interface ChannelPageTitleProps {
  selectedSubmenu: string
  customContent?: {
    header?: string
    footer?: string
  }
  rootDomain: string
  sport: string
  channelSlug: string
}

export const ChannelPageTitle: FC<ChannelPageTitleProps> = ({
  selectedSubmenu,
  customContent,
  rootDomain,
  sport,
  channelSlug
}) => {
  const translate = useTranslate()
  const domain = rootDomain || window.location.hostname

  const submenuItem: ChannelMenuLink =
    links.find((link: ChannelMenuLink) => link.id === selectedSubmenu) ||
    links[0]

  const title = submenuItem
    ? `${translate(submenuItem.translationText)} | ${domain}`
    : domain

  const canUseCustomContent =
    submenuItem?.name === 'start' && customContent?.footer

  const unsafeDescription = canUseCustomContent
    ? parseHTMLContent(customContent.footer)
    : translate(getMetaDescription(submenuItem, domain, sport || 'sport'))

  const description =
    typeof unsafeDescription === 'string' &&
    metaFriendlyString(unsafeDescription?.slice(0, 155))

  const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  const pageDomain = rootDomain ? `https://${rootDomain}` : baseUrl
  const channelUrl = `${pageDomain}/${channelSlug}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <link rel="canonical" href={channelUrl} />
      {description && <meta name="description" content={description} />}
    </Helmet>
  )
}

ChannelPageTitle.defaultProps = {
  customContent: {}
}
