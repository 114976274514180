import React, { FC } from 'react'

import ContentContainer from 'components/ContentContainer'
import { useParams } from 'react-router-dom'
import { CustomPageSection } from './CustomPage/CustomPage'
import { CustomPageMeta, PageListAccordion } from './pageList/PageListAccordion'

interface Props {
  customPages: CustomPageMeta[]
  channelSlug: string
}

export const ContentSelector: FC<Props> = ({ customPages, channelSlug }) => {
  let { custompage } = useParams<any>()

  const rootPage = customPages[0].children
  const selectAccordion = rootPage.find(
    (menu: CustomPageMeta) => menu.slug === custompage
  )

  const openLeaf = selectAccordion?.slug

  return openLeaf && selectAccordion.children.length > 0 ? (
    <ContentContainer
      hasLightBackground
      hasLeftRightPadding
      hasTopBottomPadding
    >
      <PageListAccordion
        pageList={customPages}
        channelSlug={channelSlug}
        openLeaf={openLeaf}
      />
    </ContentContainer>
  ) : (
    <CustomPageSection />
  )
}
