import React, { FC } from 'react'
import dayjs from 'dayjs'

import { t } from '@lingui/macro'
import { SubscriptionsHelpText } from './SubscriptionsHelpText'
import { ProductType } from '../../ProductType'
import { SubscriptionType } from '../../SubscriptionType'
import { GreenTickBullet } from '../GreenTickListItem/GreenTickBullet'
import { getPreDefinedTagLine } from './getPreDefinedTagLine'
import { Period } from './period'
import {
  BulletPointContent,
  getPreDefinedBulletPoints
} from './getPreDefinedBulletPoints'
import styles from './CardText.module.scss'

interface Props {
  productType: ProductType
  subscriptionType: SubscriptionType
  customDescription: string
  expirationDate: number
  periodicity: Period
  subscriptionChannelName: string
}

export const CardText: FC<Props> = ({
  productType,
  subscriptionType,
  customDescription,
  expirationDate,
  periodicity,
  subscriptionChannelName
}) => {
  switch (productType) {
    case ProductType.MonthlySupporter:
    case ProductType.AnnualSupporter:
      return (
        <div>
          <div className={styles.PurchaseTagLine}>
            {getPreDefinedTagLine(
              productType,
              subscriptionType,
              subscriptionChannelName
            )}
          </div>
          <div className={styles.BulletPoints}>
            {getPreDefinedBulletPoints(
              subscriptionType,
              subscriptionChannelName
            ).map((bulletPoint: BulletPointContent) => (
              <GreenTickBullet
                bulletText={bulletPoint.text}
                key={bulletPoint.id}
              />
            ))}
          </div>
          <SubscriptionsHelpText periodicity={periodicity} />
        </div>
      )
    default:
      return (
        <div>
          <div className={styles.PurchaseTagLine}>{customDescription}</div>
          {expirationDate ? (
            <div className={styles.ExpirationDate}>
              {t`* Valid through ${dayjs
                .unix(expirationDate)
                .format('D MMMM YYYY')}`}
            </div>
          ) : (
            <SubscriptionsHelpText periodicity={periodicity} />
          )}
        </div>
      )
  }
}
