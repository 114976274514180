import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'

import { groupVideosByDate } from './groupByDate'
import { EventCardGroup } from './EventCardGroup'

import styles from './EventCardList.module.scss'
import LoadMoreSection from 'components/LoadMoreSection/LoadMoreSection'
import { CHANNEL_SUB_MENU_ID } from 'pages/ChannelPage/Header/Submenu/Submenu'
import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'
import { Loader } from 'components/Loader/Loader'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

interface Props {
  type: FilteredGamesListType
  eventList: GameScheduleEventCardType[]
  now: number
  totalPages: number
  currentPage: number
  isLoading: boolean
  increasePageNumber: () => void
  emptyListMessageOverride?: string
}

export const EventCardList: FC<Props> = ({
  type,
  eventList,
  now,
  totalPages,
  currentPage,
  isLoading,
  increasePageNumber,
  emptyListMessageOverride
}) => {
  const groupedEventList =
    eventList?.length > 0 && groupVideosByDate(eventList, now / 1000)

  const getGroupTitleText = (day: dayjs.Dayjs) => {
    return day.isToday() ? t`Today` : t`Tomorrow`
  }

  const getFormattedDateText = (day: dayjs.Dayjs) => {
    const currentDate = dayjs(now)
    const currentYear = currentDate.year()
    return day.year() === currentYear
      ? day.format('dddd D MMMM')
      : day.format('D MMMM YYYY')
  }

  const videoList = []
  for (const [key, value] of Object.entries(groupedEventList)) {
    const day = dayjs(key)
    const liveNowTitle = t`Live now`
    const dayTitle =
      day.isToday() || day.isTomorrow()
        ? getGroupTitleText(day)
        : getFormattedDateText(day)
    const title = key === 'live' ? liveNowTitle : dayTitle
    if (key === 'live') {
      const listPosition = 0
      const elementsToBeRemoved = 0
      videoList.splice(
        listPosition,
        elementsToBeRemoved,
        <EventCardGroup
          key={key}
          title={title}
          eventDayGroup={value}
          type={type}
        />
      )
    } else {
      videoList.push(
        <EventCardGroup
          key={key}
          title={title}
          eventDayGroup={value}
          type={type}
        />
      )
    }
  }
  return videoList.length !== 0 ? (
    <>
      <div
        data-testid="event-card-list-container"
        className={styles.EventCardListContainer}
      >
        {videoList}
      </div>
      {isLoading && <Loader />}
      <LoadMoreSection
        onClick={increasePageNumber}
        totalPages={totalPages}
        currentPage={currentPage}
        scrollId={CHANNEL_SUB_MENU_ID}
      />
    </>
  ) : (
    <div className={styles.EmptyMessageContainer}>
      {emptyListMessageOverride ? (
        emptyListMessageOverride
      ) : (
        <Trans>There is nothing to display for the current selection</Trans>
      )}
    </div>
  )
}
