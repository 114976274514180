import { Team, TeamFromApi } from '../../interfaces'

export const mapTeamDataFromResponse = (
  team: TeamFromApi,
  id: number
): Team => ({
  name: team?.name,
  badge_url: team?.badge_image.md,
  slug: team?.slug,
  id: id,
  country_code: team?.country_code,
  shirt_colors: [
    {
      class: 'Black',
      style: 'Dark'
    }
  ]
})
