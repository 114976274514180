/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'

import { MediaObject } from '../../interfaces/MediaObject.interface'
import MEDIA_OBJECT from '../../constants/media_object.constants'

import { Chat } from './components/Chat/Chat'
import { Comments } from './components/Comments/Comments'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'

export const StreamInteraction: FC = () => {
  const stream = useSelector(streamSelector)
  const mediaObject: MediaObject = stream.data.mediaObject
  return mediaObject.enable_comments &&
    (mediaObject.type === MEDIA_OBJECT.TYPES.LIVESTREAM ||
      mediaObject.is_replay) ? (
    <Chat />
  ) : (
    <Comments />
  )
}
