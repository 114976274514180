import React, { FC } from 'react'

import { t } from '@lingui/macro'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import { TextInput } from '../../../../../../components/Forms/TextInputField/TextInput'

import styles from './UploadFont.module.scss'

interface Props {
  onUploadAccepted: (font: File) => void
  onUploadRejected: (font: FileRejection) => void
  uploadedFile: string
  formik: any
}

export const UploadFont: FC<Props> = ({
  onUploadAccepted,
  onUploadRejected,
  uploadedFile,
  formik
}) => {
  const accept: Accept = { 'font/ttf': ['.ttf'], 'font/otf': ['.otf'] }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: (files) => {
      onUploadAccepted(files[0])
    },
    onDropRejected: (files) => {
      onUploadRejected(files[0])
    },
    maxSize: 307200000
  })

  return (
    <div className={styles.UploadFontContainer}>
      <div className={styles.UploadFontIdentifierText}>
        <TextInput
          id="fontIdentifier"
          label={t`Font Name`}
          placeholder={t`Font Name`}
          textValue={uploadedFile}
          isValid={!formik.errors.fontIdentifier}
          isRequired={false}
          validationMessage={formik.validationMessage}
          onChange={formik.handleChange}
        />
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          buttonSize="Medium"
          type={ButtonType.Secondary}
          label={t`File`}
        />
      </div>
    </div>
  )
}
