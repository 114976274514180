import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface DisconnectGamesRequest {
  teamId: string
  competitionId: string
  channelSlug: string
}
interface Response {
  hasError: boolean
  errors?: any
}
export const deleteDisconnectGamesFromTeam = async (
  request: DisconnectGamesRequest
): Promise<Response> => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.channelSlug}/competitions/${request.competitionId}/teams/${request.teamId}/disconnect_channel`,
      method: ApiEndpoint.METHODS.DELETE,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
