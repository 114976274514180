import React, { FC } from 'react'

import styles from './CategoryLeaderRow.module.scss'
import { CategoryRowCell } from './CategoryRowCell'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows
} from '../../Table/PlayerStatisticsTable.interface'
import { RankRowCell } from '../../Table/Row/RowCell/CellTypes/RankRowCell'
import { CellType } from '../../Table/Row/RowCell/CellTypes/DefaultRowCell'

interface Props {
  columns: PlayerStatisticsTableColumns[]
  row: PlayerStatisticsTableRows
  selectedColumn: string
  rowNumber: number
}

export const CategoryLeaderRow: FC<Props> = ({
  columns,
  row,
  rowNumber,
  selectedColumn
}) => {
  return (
    <div className={styles.Row} key={rowNumber}>
      <RankRowCell
        rowNumber={rowNumber}
        type={CellType.CategoryLeader}
        key={'category-leader-1'}
      />
      {columns.map((column, columnIndex) => {
        const isSelected = selectedColumn === column.identifier
        return (
          <CategoryRowCell
            column={column}
            row={row}
            key={`category-leader-${columnIndex}`}
            isSelected={isSelected}
          />
        )
      })}
    </div>
  )
}
