import React, { FC } from 'react'

import { getDetailsContent } from './getDetailsContent'
import styles from './index.module.scss'

export enum BroadcastDetailsType {
  Broadcast = 'Broadcast',
  Game = 'Game',
  Default = 'Default',
  Video = 'Video',
  Highlight = 'Highlight'
}
interface Props {
  broadcastType: BroadcastDetailsType
  live: boolean
  replay: boolean
  hasHighlight: boolean
  highlightFromDate: number
  publishedAt: number
  showViews: boolean
  showViewers: boolean
  viewers: number
  views: number
  endTime: number
  eventName?: string
  typeOfGame?: string
  locationName?: string
  categoryName?: string
}
export const DetailsRow: FC<Props> = ({
  broadcastType,
  live,
  replay,
  hasHighlight,
  highlightFromDate,
  publishedAt,
  showViews,
  showViewers,
  viewers,
  views,
  endTime,
  eventName,
  typeOfGame = '',
  locationName,
  categoryName
}) => {
  const rowContent = getDetailsContent(
    broadcastType,
    live,
    showViewers,
    viewers,
    views,
    showViews,
    highlightFromDate,
    publishedAt,
    endTime,
    typeOfGame,
    hasHighlight,
    replay,
    eventName,
    locationName,
    categoryName
  )

  const rowContentWithoutEmptyElements = rowContent.filter(
    (row: any) => row.value !== ''
  )
  return (
    <div className={styles.DetailsRows}>
      <span className={styles.Row}>
        {rowContentWithoutEmptyElements.map((column) => (
          <span key={column.key} className={styles.Column}>
            {column.value}
          </span>
        ))}
      </span>
    </div>
  )
}
DetailsRow.defaultProps = {
  typeOfGame: ''
}
