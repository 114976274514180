import React from 'react'
import Card from './Card'

import styles from './index.module.scss'

const TallImagePreloader: React.FC = () => (
  <div className={styles.TallImageSliderPreloader}>
    <div className={styles.Wrapper}>
      <Card />
      <Card />
      <Card />
      {window.innerWidth > 812 && <Card />}
    </div>
  </div>
)

export default TallImagePreloader
