/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Team } from '../../../../../interfaces'
import styles from './GameThumbNail.module.scss'
import DefaultShield from '../../../../../assets/images/shield_2_256x256.png'
import BottomTimeLabel from '../../BottomTimeLabel'
import { StreamLabel } from '../../ThumbnailLabel/ThumbnailLabel'
import { RootDomainLabel } from '../../RootDomainLabel/RootDomainLabel'

interface Props {
  homeTeam: Team
  awayTeam: Team
  isLive: boolean
  thumbnailSize:
    | 'xxSmall'
    | 'xSmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xLarge'
    | 'keepRatio'
  isReplay: boolean
  startTime: number
  duration: number
  highlightFromDate: number | null
  type: string
  hasHoverEffect: boolean
  rootDomain?: string
  showRootDomainLabel?: boolean
}
const GameThumbNail: FC<Props> = ({
  homeTeam,
  awayTeam,
  thumbnailSize,
  isLive,
  isReplay,
  startTime,
  duration,
  highlightFromDate,
  type,
  hasHoverEffect,
  rootDomain,
  showRootDomainLabel
}) => {
  return (
    <div
      className={classNames(styles.GameThumbNail, styles[thumbnailSize], {
        [styles.WithHoverEffect]: hasHoverEffect
      })}
    >
      <div className={classNames(styles.ThumbnailOverlay)} />
      <div className={styles.RightBackground} />
      <div className={styles.TeamsWrapper}>
        <div className={styles.LeftContainer}>
          {homeTeam?.badge_url ? (
            <img
              data-testid="home-team-logo"
              src={homeTeam.badge_url}
              alt={homeTeam.slug}
            />
          ) : (
            <img src={DefaultShield} alt="Default shield for team" />
          )}
        </div>
        <div className={styles.VsBox}>
          <Trans>VS</Trans>
        </div>
        <div className={styles.RightContainer}>
          {awayTeam?.badge_url ? (
            <img
              data-testid="away-team-logo"
              src={awayTeam.badge_url}
              alt={awayTeam.slug}
            />
          ) : (
            <img src={DefaultShield} alt="Default shield for team" />
          )}
        </div>
      </div>
      <div className={styles.ThumbnailLabelContainer}>
        <StreamLabel
          isLive={isLive}
          isReplay={isReplay}
          highlightFromDate={highlightFromDate}
          startAt={startTime}
          type={type}
          publishedAt={startTime}
        />
      </div>
      {(isReplay || highlightFromDate) && duration && (
        <div className={styles.TimeLabelContainer}>
          <BottomTimeLabel durationInSeconds={duration} />
        </div>
      )}
      {showRootDomainLabel && rootDomain && (
        <div className={styles.RootDomainLabelContainer}>
          <RootDomainLabel label={rootDomain} />
        </div>
      )}
    </div>
  )
}
export default GameThumbNail
