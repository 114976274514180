import ApiEndpoint from '../../network/ApiEndpoint'

const likeVideo = async (ident: string) => {
  try {
    await ApiEndpoint.call({
      path: 'users/like/media_object',
      method: ApiEndpoint.METHODS.PATCH,
      params: { media_object: ident },
      authenticatable: true
    })

    return
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const unlikeVideo = async (ident: string) => {
  try {
    await ApiEndpoint.call({
      path: 'users/dislike/media_object',
      method: ApiEndpoint.METHODS.PATCH,
      params: { media_object: ident },
      authenticatable: true
    })

    return
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const toggleLike = async (liked: boolean, ident: string) => {
  if (liked) {
    await unlikeVideo(ident)
  } else {
    await likeVideo(ident)
  }
}
