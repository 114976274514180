import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { postFavicon } from '../../../../../api/channel/settings/postFavicon'
import { postChannelLogo } from 'api/channel/settings/postChannelLogo'
import { postSecondaryLogo } from 'api/channel/settings/postSecondaryLogo'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import {
  StatusMessage,
  StatusMessageType
} from '../../StatusMessage/StatusMessage'
import { Uploaders } from './Uploaders'
import { ApiStatus } from './UploadLogo/ApiStatus.interface'

import styles from './Logos.module.scss'

interface LogosProps {
  channelSlug: string
}

export const Logos: FC<LogosProps> = ({ channelSlug }) => {
  const channel = useSelector(channelSelector)
  const [showStatusMessage, setShowStatusMessage] = useState(false)
  const [messageType, setMessageType] = useState(StatusMessageType.Success)
  const [statusMessageText, setStatusMessageText] = useState('')
  const initialChannelLogo = channel.data?.logo_image_url
  const initialSecondaryLogo = channel.data?.secondary_logo_image_url
  const faviconUrl = channel.data?.favicon_url

  const handleResponse = (isSuccess: boolean, uploadItem: string) => {
    if (isSuccess) {
      setStatusMessageText(
        t`Your ${uploadItem} has been successfully updated and will appear when you refresh the page.`
      )
      setMessageType(StatusMessageType.Success)
      setShowStatusMessage(true)
    } else {
      setStatusMessageText(
        t`Something went wrong when trying to upload the ${uploadItem}.`
      )
      setMessageType(StatusMessageType.Error)
      setShowStatusMessage(true)
    }
  }

  const handleUpload = async (
    file: File,
    apiCall: (channelSlug: string, file: File) => Promise<ApiStatus>,
    uploadItemText: string
  ) => {
    const response = await apiCall(channelSlug, file)
    handleResponse(response.isSuccess, uploadItemText)
    return response
  }

  const handleFaviconUpload = async (file: File) =>
    handleUpload(file, postFavicon, t`favicon`)
  const handleChannelLogo = async (file: File) =>
    handleUpload(file, postChannelLogo, t`channel logo`)
  const handleSecondaryLogo = async (file: File) =>
    handleUpload(file, postSecondaryLogo, t`navigation logo`)

  return (
    <div className={styles.LogosContainer}>
      <Uploaders
        secondaryLogoUrl={initialSecondaryLogo}
        channelLogoUrl={initialChannelLogo}
        faviconUrl={faviconUrl}
        onChannelLogoUpload={handleChannelLogo}
        onSecondaryLogoUpload={handleSecondaryLogo}
        onFaviconUpload={handleFaviconUpload}
      />
      {showStatusMessage && (
        <StatusMessage
          onClose={() => setShowStatusMessage(false)}
          message={statusMessageText}
          messageType={messageType}
        />
      )}
    </div>
  )
}
