import React, { FC, ReactNode } from 'react'
import styles from './RoundButton.module.scss'
interface Props {
  id: string
  onClick: () => void
  children?: ReactNode
}

export const RoundButton: FC<Props> = ({ id, onClick, children }) => {
  return (
    <div
      data-testid={`round-button-${id}`}
      className={styles.RoundButton}
      tabIndex={0}
      role="button"
      onClick={onClick}
    >
      <div className={styles.Icon}>{children}</div>
    </div>
  )
}
