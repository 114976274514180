import React, { FC } from 'react'
import ContentSlider from '../../sliders/ContentSlider'
import ThumbnailSectionHeader from '../../Headers/ThumbnailSectionHeader/index'
import { BroadcastThumbnailInterface } from '../../cards/BroadcastThumbnail/broadcastCardTypes'
import BroadcastThumbnail from '../../cards/BroadcastThumbnail'
import styles from './TitleThumbnailSection.module.scss'
import { TitleThumbnailSectionPreloader } from './TItleThumbnailSectionPreloader'
import { BroadcastThumbnailPreloader } from 'components/preloaders/BroadcastThumbnailPreloader/BroadcastThumbnailPreloader'

interface Props {
  thumbnailContent: BroadcastThumbnailInterface[]
  sectionTitle: string
  showAllLinkPath: string | undefined
  linkLabel: string
  thumbnailsCardIsShared: boolean
  size: 'small' | 'medium' | 'large' | 'xLarge'
  useLeftPadding?: boolean
  isLoading: boolean

  getInfiniteScrollRef?: null | ((infiniteScrollRef: any) => void)
}
export const TitleThumbnailSection: FC<Props> = ({
  thumbnailContent,
  sectionTitle,
  showAllLinkPath,
  linkLabel,
  thumbnailsCardIsShared,
  size,
  isLoading,

  getInfiniteScrollRef = null,
  useLeftPadding = true
}) => {
  if (
    (!thumbnailContent || (thumbnailContent && thumbnailContent.length < 1)) &&
    isLoading
  ) {
    return (
      <TitleThumbnailSectionPreloader
        size={size}
        useLeftPadding={useLeftPadding}
      />
    )
  }

  return thumbnailContent && thumbnailContent.length > 0 ? (
    <div className={styles.TitleThumbnailSection}>
      <ThumbnailSectionHeader
        label={sectionTitle}
        linkPath={showAllLinkPath}
        linkLabel={linkLabel}
        useLeftPadding={useLeftPadding}
      />
      <div className={styles.ThumbnailSlider}>
        <ContentSlider
          leftPadding={useLeftPadding}
          getEndOfContentRef={getInfiniteScrollRef}
        >
          <div className={styles.Content} data-testid="thumbnail-container">
            {thumbnailContent.map((card, index) => (
              <BroadcastThumbnail
                key={`${card.id}-${index}`}
                content={card}
                shared={thumbnailsCardIsShared}
                size={size}
                showDetails
                disableLink={false}
              />
            ))}
            {isLoading && <BroadcastThumbnailPreloader size={size} />}
          </div>
        </ContentSlider>
      </div>
    </div>
  ) : null
}
TitleThumbnailSection.defaultProps = {
  useLeftPadding: true,
  getInfiniteScrollRef: null
}
