import React, { FC } from 'react'
import { PurchaseList } from './PurchaseList/PurchaseList'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { t } from '@lingui/macro'
import styles from './AllPurchases.module.scss'

export const AllPurchases: FC = () => {
  return (
    <div className={styles.AllPurchases}>
      <FormSectionTitle title={t`Completed purchases`} />
      <PurchaseList />
    </div>
  )
}
