import React, { FC } from 'react'

import SolidsportLogo from '../assets/images/Solid_Logotype.png'
import BasicHeader from '../components/page/basicHeader/Header'
import { Header } from '../components/page/header/index'
import { TopSpacer } from './TopSpacer'
import { AccountHeader } from 'components/page/header/AccountHeader/AccountHeader'

interface Props {
  showBasicHeader: boolean
  showAccountHeader: boolean
  addTopSpacer: boolean
  isRootOrChild: boolean
  isRootClubChannel: boolean
  isViewingStream?: boolean
}

export const AppHeader: FC<Props> = ({
  showBasicHeader,
  showAccountHeader,
  addTopSpacer,
  isRootOrChild,
  isRootClubChannel,
  isViewingStream = false
}) =>
  isViewingStream ? null : (
    <>
      {showBasicHeader && !showAccountHeader && (
        <BasicHeader headerImgPath={SolidsportLogo} />
      )}
      {showAccountHeader && <AccountHeader />}
      {!showBasicHeader && !showAccountHeader && <Header />}
      {addTopSpacer && (
        <TopSpacer
          isRootClubChannel={isRootClubChannel}
          isRootOrChild={isRootOrChild}
        />
      )}
    </>
  )
