import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import {
  StandingsRowInterface,
  StandingsColumnInterface
} from '../Standings.interface'
import { StandingsCell } from '../StandingsCell'

import styles from './StandingsRow.module.scss'

interface StandingsRowProps {
  name: string
  placement: number
  channel?: string
  row: StandingsRowInterface
  columns: StandingsColumnInterface[]
  isLastPlacement: boolean
  isLastBeforeDivider: boolean
  isPreSelected: boolean
  gridTemplateColumns: string
}

export const StandingsRow: FC<StandingsRowProps> = ({
  name,
  placement,
  channel,
  row,
  columns,
  isLastPlacement,
  isLastBeforeDivider,
  isPreSelected,
  gridTemplateColumns
}) => {
  const history = useHistory()
  const handleClick = () => !!channel && history.push(`/${channel}`)
  return (
    <div
      className={classNames(styles.StandingsRow, {
        [styles.IsClickable]: !!channel,
        [styles.IsLastPlacement]: !!isLastPlacement,
        [styles.IsLastBeforeDivider]: !!isLastBeforeDivider,
        [styles.IsPreSelected]: isPreSelected
      })}
      style={{ gridTemplateColumns }}
      onClick={handleClick}
      key={`StandingsRow__${name}`}
    >
      {columns.map((column, columnIndex) => {
        return (
          <StandingsCell
            column={column}
            row={row}
            placement={placement}
            key={`StandingsCell__${name}__${columnIndex}`}
          />
        )
      })}
    </div>
  )
}
