import React, { FC } from 'react'

import { ReturnValues } from '../../../api/useDataApi'
import { ArticleThumbnail } from '../../cards/ArticleThumbnail/ArticleThumbnail'
import { ImageSliderPreloader } from '../../preloaders/ImageSliderPreloader/ImageSliderPreloader'
import ContentSlider from '../ContentSlider'
import { News } from '../../../interfaces/News.interface'

import styles from './ArticleThumbnailSlider.module.scss'

interface ArticleThumbnailSliderProps {
  title: string
  relatedArticles: ReturnValues
  size: 'small' | 'medium' | 'large' | 'xlarge'
  strongHeading: boolean
}
const ArticleThumbnailSlider: FC<ArticleThumbnailSliderProps> = ({
  title,
  relatedArticles,
  size,
  strongHeading
}) => {
  const { isLoading, data } = relatedArticles
  if (isLoading) {
    return (
      <div className={styles.Preloader} data-testid="article-slider-preloader">
        <ImageSliderPreloader title={title} />
      </div>
    )
  }
  return (
    !isLoading &&
    data &&
    data.length > 0 && (
      <div
        className={styles.ArticleSliderContainer}
        data-testid="article-thumbnail-slider"
      >
        <div>
          {strongHeading ? (
            <h3 className={styles.StrongHeading}>{title}</h3>
          ) : (
            <h4 className={styles.Label}>{title}</h4>
          )}
          <ContentSlider leftPadding>
            <div
              className={styles.Content}
              data-testid="article-thumbnail-list"
            >
              {data &&
                data.length > 0 &&
                data.map((article: News) => (
                  <ArticleThumbnail
                    key={article.id + article.title}
                    articleSlug={article.id}
                    channelSlug={
                      article.companySlug
                        ? article.companySlug!
                        : article.company!
                    }
                    title={article.title}
                    updatedOn={article.published}
                    imageUrl={article.imageUrl}
                    size={size}
                    summaryText={article.preamble}
                  />
                ))}
            </div>
          </ContentSlider>
        </div>
      </div>
    )
  )
}
export default ArticleThumbnailSlider
