import React, { FC, useCallback } from 'react'
import { useDataApi } from 'api/useDataApi'
import { getAdministratingChannels } from 'api/user/getAdministratingChannels'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { ChannelList } from 'components/ChannelList/ChannelList'
import { Trans, t } from '@lingui/macro'
import { Loader } from 'components/Loader/Loader'
import styles from './MyChannels.module.scss'

export const MyChannels: FC = () => {
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getAdministratingChannels, [])
  })
  const hasData = ![0, undefined].includes(data?.data?.length)

  return (
    <div className={styles.MyChannels}>
      <FormSectionTitle title={t`My channels`} />
      {!isLoading && hasData && (
        <div className={styles.ChannelsContainer}>
          <div className={styles.SubText}>
            <Trans>
              The channels listed below are those you manage and administer.
            </Trans>
          </div>
          <ChannelList list={data?.data} showBorder />
        </div>
      )}
      {!isLoading && !hasData && (
        <div>
          <Trans>You are not administrating any channels right now.</Trans>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  )
}
