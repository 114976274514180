import React, { FC } from 'react'
import tempUserImage from '../../../../../assets/images/default_user_image.png'
import { User } from 'interfaces'
import { t } from '@lingui/macro'
import styles from './UserImageButton.module.scss'

interface Props {
  onClick: () => void
  currentUser: User
}

export const UserImageButton: FC<Props> = ({ currentUser, onClick }) => (
  <div
    className={styles.UserImageButton}
    role="button"
    onClick={onClick}
    tabIndex={0}
    onKeyUp={onClick}
  >
    <img
      data-testid="user-image"
      src={currentUser.thumbnail || tempUserImage}
      alt={t`user`}
      className={styles.UserImage}
    />
  </div>
)
