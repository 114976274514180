import React from 'react'

import { Trans } from '@lingui/macro'

import styles from './NoBroadcastLabel.module.scss'

export const NoBroadcastLabel = () => (
  <div className={styles.NoBroadcastLabel}>
    <Trans>No broadcast</Trans>
  </div>
)
