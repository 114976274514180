import React, { FC } from 'react'

import BroadcastThumbnail from 'components/cards/BroadcastThumbnail'
import { BroadcastThumbnailInterface } from 'components/cards/BroadcastThumbnail/broadcastCardTypes'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import styles from './StreamResults.module.scss'
import classNames from 'classnames'
interface Props {
  streamList: BroadcastThumbnailInterface[]
}

export const StreamResults: FC<Props> = ({ streamList }) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const screenSize = {
    isDesktop: currentBreakpoint > Breakpoint.sm,
    isTablet:
      currentBreakpoint <= Breakpoint.sm &&
      currentBreakpoint > Breakpoint.mobile,
    isMobile: currentBreakpoint <= Breakpoint.mobile
  }
  const cardSize = screenSize.isDesktop
    ? 'medium'
    : screenSize.isTablet
    ? 'small'
    : 'keepRatio'

  return streamList.length > 0 ? (
    <div className={styles.StreamList} data-testid="stream-result-list">
      {streamList.map((stream: BroadcastThumbnailInterface) => {
        return (
          <div
            key={stream.id}
            className={classNames(
              styles.BroadcastThumbnailContainer,
              styles[stream.type]
            )}
          >
            <BroadcastThumbnail
              content={stream}
              shared={false}
              size={cardSize}
              disableLink={false}
              showDetails={true}
            />
          </div>
        )
      })}
    </div>
  ) : null
}
