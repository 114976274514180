import { t } from '@lingui/macro'
import React, { FC, useState } from 'react'
import { getTeamsForClubs } from '../../../api/channel/getTeamsForClub'
import { getTeamsForLeague } from '../../../api/channel/getTeamsForLeague'
import { ChannelListItem } from '../../../components/lists/ChannelListItem/ChannelListItem'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { useChannelPage } from '../useChannelPage'
import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { getChannelLinkMapper } from '../../../components/lists/ChannelListItem/getChannelLink'
import { ChannelTypes } from '../channelType'

interface TeamsProps {
  channelSlug: string
  channelType: ChannelTypes
}

const Teams: FC<TeamsProps> = ({ channelSlug, channelType }) => {
  const [teams, setTeams] = useState<AssociationClubEventDistrict[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const PER_PAGE_COUNT = 15
  const getTeamsApiCall =
    channelType === ChannelTypes.league ? getTeamsForLeague : getTeamsForClubs

  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getTeamsApiCall,
    onData: (data: any) => setTeams((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) => setTotalPages(total / PER_PAGE_COUNT)
  })

  const isEmpty = !teams?.length
  const emptyMessage = t`There are no teams associated to this ${channelType}`
  const channelLinkMapper = getChannelLinkMapper('teams', channelType)

  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }
  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {teams?.map((team: AssociationClubEventDistrict) => (
        <ChannelListItem
          key={team.subdomain}
          channelLink={channelLinkMapper(team)}
        />
      ))}
    </ChannelPageList>
  )
}

export default Teams
