import React, { FC } from 'react'

import ContentContainer from '../../../components/ContentContainer'
import { Banner, BannerType } from '../../../components/Banner/Banner'

interface Props {
  showBanner: boolean
  eventPassAvailable: boolean
  subscriptionsAvailable: boolean
  channelName: string
  onBannerClose: () => void
  onBannerClick: (destination: string) => void
  channelSlug: string
  isSupporter: boolean
  hasEventPass: boolean
  noContainer?: boolean
}

export const ChannelStartBanner: FC<Props> = ({
  showBanner,
  subscriptionsAvailable,
  eventPassAvailable,
  channelName,
  onBannerClose,
  onBannerClick,
  channelSlug,
  isSupporter,
  hasEventPass,
  noContainer
}) => {
  let banner
  if (showBanner && eventPassAvailable) {
    banner = (
      <Banner
        bannerType={BannerType.EventPassBanner}
        channelName={channelName}
        isSupporter={hasEventPass}
        onBannerClose={onBannerClose}
        onBannerClick={onBannerClick}
        channelSlug={channelSlug}
      />
    )
  }
  if (showBanner && subscriptionsAvailable) {
    banner = (
      <Banner
        bannerType={BannerType.SubscriptionBanner}
        channelName={channelName}
        isSupporter={isSupporter}
        onBannerClose={onBannerClose}
        onBannerClick={onBannerClick}
        channelSlug={channelSlug}
      />
    )
  }
  return banner ? (
    noContainer ? (
      banner
    ) : (
      <ContentContainer
        hasLightBackground
        hasLeftRightPadding
        hasTopBottomPadding
      >
        {banner}
      </ContentContainer>
    )
  ) : (
    <div />
  )
}
