import React, { FC } from 'react'
import { DayEvents } from './DayEvents'

import styles from './EventCardList.module.scss'
import { FilteredGamesListType } from 'features/multistageTournaments/FilteredGamesList/FilteredGamesList'

interface Props {
  title: string
  type: FilteredGamesListType
  eventDayGroup: any
}

export const EventCardGroup: FC<Props> = ({ title, eventDayGroup, type }) => (
  <div className={styles.EventCardGroupContainer}>
    <h4>{title}</h4>
    <DayEvents events={eventDayGroup} type={type} />
  </div>
)
