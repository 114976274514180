import dayjs from 'dayjs'

import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export const getMessageTimeStamp = (publishedTime: number) => {
  dayjs.extend(relativeTime)
  return dayjs.unix(publishedTime).fromNow()
}
