import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { BackButton } from 'components/page/header/SettingsContentHeader/BackButton/BackButton'
import { FilterHeaderItem } from './FilterHeaderItem/FilterHeaderItem'

import styles from './FIlterHeader.module.scss'
import { CategoryFilterType } from 'features/multistageTournaments/CategoryFilter/CategoryFilter'

export interface FilterHeaderItem {
  slug: string
  label: string
}

interface Props {
  selectedFilters: FilterHeaderItem[]
  filterType: CategoryFilterType
  onBackClick: () => void
  onRemoveFilter: (filterSlug: string) => void
}

export const FilterHeader: FC<Props> = ({
  selectedFilters,
  filterType,
  onBackClick,
  onRemoveFilter
}) => {
  const selectedFiltersLength = selectedFilters?.length
  const disabledFilter = selectedFilters[selectedFiltersLength - 2]
  const activeFilter = selectedFilters[selectedFiltersLength - 1]
  const backButtonLabel =
    filterType === CategoryFilterType.Classes
      ? t`All classes`
      : t`All locations`

  if (selectedFilters.length > 0) {
    return (
      <div className={styles.Container}>
        <BackButton label={backButtonLabel} onBack={onBackClick} />
        <div className={styles.SelectedFilters}>
          {selectedFiltersLength > 1 ? (
            <>
              <FilterHeaderItem
                label={disabledFilter.label}
                slug={disabledFilter.slug}
                disabled={true}
                onRemoveFilter={() => null}
              />
              <div className={styles.ConnectorLine} />
              <FilterHeaderItem
                label={activeFilter.label}
                slug={activeFilter.slug}
                disabled={false}
                onRemoveFilter={onRemoveFilter}
              />
            </>
          ) : (
            <FilterHeaderItem
              label={selectedFilters[0].label}
              slug={selectedFilters[0].slug}
              disabled={false}
              onRemoveFilter={onRemoveFilter}
            />
          )}
        </div>
      </div>
    )
  }
  return null
}
