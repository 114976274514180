import React, { FC } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { GameScheduleItemStatus } from './gameScheduleItem'
import { RemoveIcon } from './RemoveIcon'
import { GameScheduleListItemProps } from './gameScheduleListItemProps'

import styles from './GameScheduleListRow.module.scss'
import { getGameScheduleStatusText } from './getGameScheduleStatusText'

import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

export const GameScheduleListRow: FC<GameScheduleListItemProps> = ({
  gameListItem,
  onRemoveSchedule,
  showRemoveButton,
  channelType
}) => {
  const statusText = getGameScheduleStatusText(gameListItem)
  const showTotalGames = [ChannelTypes.league].includes(channelType)
  const showConnectedAs = [ChannelTypes.team, ChannelTypes.club].includes(
    channelType
  )
  return (
    <div className={classNames(styles.GameScheduleRow, styles.Item)}>
      <div className={styles.NameContainer}>
        <div className={styles.Name}>{gameListItem.title}</div>
        {showConnectedAs && (
          <div className={styles.ConnectedAs}>{gameListItem.teamName}</div>
        )}
      </div>
      {statusText && (
        <div
          className={classNames(styles.Status, {
            [styles.StatusActive]:
              gameListItem.status === GameScheduleItemStatus.Active,
            [styles.StatusUpcoming]:
              gameListItem.status === GameScheduleItemStatus.Future
          })}
        >
          {statusText}
        </div>
      )}
      {showTotalGames && (
        <div className={styles.TotalGames}>{gameListItem.totalGames}</div>
      )}
      <div className={styles.StartDate}>
        {dayjs.unix(gameListItem.startDate).format('MMM DD, YYYY')}
      </div>
      <div className={styles.EndDate}>
        {dayjs.unix(gameListItem.endDate).format('MMM DD, YYYY')}
      </div>
      {showRemoveButton && (
        <div className={styles.RemoveButton}>
          <div
            data-testid="remove-games-button"
            className={styles.RemoveButtonIcon}
            role="button"
            onClick={() =>
              onRemoveSchedule(gameListItem.id, gameListItem.teamId)
            }
            onKeyDown={() => null}
            tabIndex={0}
          >
            <RemoveIcon />
          </div>
        </div>
      )}
    </div>
  )
}
