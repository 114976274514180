import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { FormModal } from 'features/sportData/FormModal/FormModal'
import {
  HeroImageCallToActionForm,
  HeroImageCallToAction
} from './Form/HeroImageCallToActionForm'

interface Props {
  defaultOpen: boolean
  existingForm: HeroImageCallToAction
  channelSlug: string
  onClose: () => void
  onSubmitSuccess: () => void
}

export const NewHeroImageCallToActionModal: FC<Props> = ({
  defaultOpen,
  existingForm,
  channelSlug,
  onClose,
  onSubmitSuccess
}) => {
  return (
    <FormModal
      modalHeader={t`add Hero image promotion`}
      preHeader=""
      header=""
      modalInformation=""
      onClose={onClose}
      defaultOpen={defaultOpen}
    >
      <HeroImageCallToActionForm
        onSubmitSuccess={onSubmitSuccess}
        onCancel={onClose}
        channelSlug={channelSlug}
        existingForm={existingForm}
      />
    </FormModal>
  )
}
