/* eslint-disable react/require-default-props */
import React, { FC, RefObject, useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from './Popout.module.scss'

interface PopoutProps {
  anchor: 'NE' | 'SE' | 'SW' | 'NW'
  isOpen: boolean
  onClose: () => void
  anchorRef?: RefObject<HTMLDivElement>
  children?: React.ReactNode
}

export const Popout: FC<PopoutProps> = ({
  anchor = 'SW',
  children,
  isOpen,
  onClose,
  anchorRef
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node
    if (
      ref.current &&
      !ref.current.contains(target) &&
      !anchorRef?.current?.contains(target)
    ) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return isOpen ? (
    <div
      className={classNames(styles.Popout, {
        [styles.NE]: anchor === 'NE',
        [styles.SE]: anchor === 'SE',
        [styles.SW]: anchor === 'SW',
        [styles.NW]: anchor === 'NW'
      })}
      ref={ref}
      data-testid="popout-container"
    >
      {children}
    </div>
  ) : null
}
