import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { groupVideosByDate } from '../../../api/channel/getVideosForChannel'
import { useDataApi } from '../../../api/useDataApi'
import { getAllUpcomingVideos } from '../../../api/video/getAllUpcomingVideos'
// import { getScheduleContentForChannel } from '../../../api/channel/getScheduleContentForChannel'
import { getFilteredUpcomingStreams } from 'api/video/getFilteredUpcomingStreams'
import { mapBroadcastThumbnailResponse } from '../../../api/channel/mapBroadcastThumnbailFromResponse'
import ChronologicalVideoList from '../../../components/lists/ChronologicalVideoList/ChronologicalVideoList'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'
import { mergeVideoDataObjects } from '../mergeVideoDataObjects'
import LoadMoreSection from '../../../components/LoadMoreSection/LoadMoreSection'
import { Loader } from '../../../components/Loader/Loader'

import styles from './UpcomingStreams.module.scss'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { getScheduleContentForChannel } from 'api/channel/getScheduleContentForChannel'

interface Props {
  showAllGames: boolean
  showRootDomainLabel: boolean
  showEmptyMessage: boolean
}

export const UpcomingStreams: FC<Props> = ({
  showAllGames,
  showEmptyMessage
}) => {
  const { company } = useParams<{ company: string | undefined }>()
  const channel = useSelector(channelSelector)
  const channelType = channel?.data?.type
  const subdomain = channel?.data?.subdomain
  const isCurrentChannel = subdomain && subdomain === company
  const isRootChannel = isCurrentChannel && channel?.data?.isRootChannel
  const isChildOfRootChannel =
    isCurrentChannel && channel?.data?.isChildOfRootChannel

  const isRootOrChild = isRootChannel || isChildOfRootChannel
  const isEventPage = [ChannelTypes.event].includes(channelType)

  const PER_PAGE_COUNT = 15
  const [page, setPage] = useState(1)

  const [videoData, setVideoData] = useState<any>({})
  const [totalPages, setTotalPages] = useState(0)
  const apiToUse = showAllGames
    ? getAllUpcomingVideos
    : isEventPage
    ? getScheduleContentForChannel
    : getFilteredUpcomingStreams
  const { isLoading, isError, data } = useDataApi({
    apiCall: useCallback(apiToUse, [company, showAllGames]),
    parameters: useMemo(
      () =>
        isRootChannel && !isEventPage
          ? {
              rootChannelSlug: company,
              page,
              perPageCount: PER_PAGE_COUNT
            }
          : {
              slug: company,
              page,
              perPageCount: PER_PAGE_COUNT
            },
      [page, subdomain, company]
    )
  })

  useEffect(() => {
    if (!isLoading && !isError && data && data.data) {
      const map = mapBroadcastThumbnailResponse(data.data)

      const grouped = groupVideosByDate(map)
      const newVideoData = mergeVideoDataObjects(videoData, grouped)
      setVideoData(newVideoData)
      setTotalPages(data.totalCount / PER_PAGE_COUNT)
    }
  }, [isLoading, isError, data, isRootOrChild, subdomain, showAllGames])
  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }

  if (isError) {
    return (
      <h4>
        <Trans>
          Something went wrong trying to get the list of upcoming streams please
          try again
        </Trans>
      </h4>
    )
  }
  if (isLoading && Object.keys(videoData).length < 1) {
    return (
      <div className={styles.GameSchedulePageContainer}>
        <Loader />
      </div>
    )
  }
  return Object.keys(videoData).length > 0 ? (
    <div
      data-testid="upcoming-streams"
      className={styles.GameSchedulePageContainer}
    >
      <ChronologicalVideoList list={videoData} />
      {isLoading && <Loader />}
      <LoadMoreSection
        onClick={increasePageNumber}
        totalPages={totalPages}
        currentPage={page}
      />
    </div>
  ) : showEmptyMessage ? (
    <div className={styles.GameSchedulePageContainerEmpty}>
      <Trans>There are no upcoming streams right now</Trans>
    </div>
  ) : null
}
