import React, { FC, useEffect, useState } from 'react'
import styles from './FormModal.module.scss'
import Modal from '../../../components/Modals/Modal/Modal'

interface Props {
  modalHeader: string
  preHeader: string
  header: string
  modalInformation: string
  onClose: () => void
  defaultOpen: boolean
  children?: React.ReactNode
}
export const FormModal: FC<Props> = ({
  modalHeader,
  preHeader,
  header,
  modalInformation,
  onClose,
  defaultOpen,
  children
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }
  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])
  const showHeaderContainer = header || preHeader
  const showInfoContainer = showHeaderContainer || modalInformation

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} mobileHasFullHeight>
      <div className={styles.FormModalContainer}>
        <div className={styles.InsideContainer}>
          <div
            data-testid="form-modal-header-container"
            className={styles.ModalHeader}
          >
            {modalHeader}
          </div>
          {showInfoContainer && (
            <div className={styles.InfoContainer}>
              {showHeaderContainer && (
                <div className={styles.HeaderContainer}>
                  {preHeader && (
                    <div className={styles.PreHeader}>{preHeader}</div>
                  )}
                  {header && <h4 data-testid="modal-form-header">{header}</h4>}
                </div>
              )}
              {modalInformation && (
                <div className={styles.Information}>{modalInformation}</div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </Modal>
  )
}
