import React, { FC, Fragment } from 'react'
import { t } from '@lingui/macro'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

import styles from './breadcrumb.module.scss'

interface Props {
  channelSlug: string
  channelName: string
  isRootChannel: boolean
  articleTitle: string
  articleSlug: string
  parentChannelSubdomain: string
}

interface BreadCrumbLink {
  name: string
  link: string
}

export const Breadcrumb: FC<Props> = ({
  channelSlug,
  channelName,
  articleTitle,
  articleSlug,
  isRootChannel,
  parentChannelSubdomain
}) => {
  const homeLink = !isRootChannel ? parentChannelSubdomain : ''
  const crumbs: BreadCrumbLink[] = [
    { name: t`Home`, link: homeLink === undefined ? '' : homeLink },
    { name: channelName, link: channelSlug },
    { name: t`News`, link: `${channelSlug}/news` },
    { name: articleTitle, link: articleSlug }
  ]
  const pathList =
    isRootChannel || !parentChannelSubdomain ? crumbs.slice(1) : crumbs
  const filteredPathList = pathList.filter((path) => path.name !== undefined)

  return (
    <div
      className={styles.BreadcrumbContainer}
      data-testid="breadcrums-container"
    >
      {filteredPathList.map((path, i) => {
        const isLastCrumb = i === filteredPathList.length - 1
        return (
          <Fragment key={path.link}>
            <div
              className={classNames(styles.Breadcrumb, {
                [styles.CurrentPage]: isLastCrumb
              })}
            >
              {isLastCrumb ? (
                path.name
              ) : (
                <Link to={`/${path.link}`}>{path.name}</Link>
              )}
            </div>
            {!isLastCrumb && <div className={styles.Breadcrumb}>/</div>}
          </Fragment>
        )
      })}
    </div>
  )
}
