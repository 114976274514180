import React, { FC } from 'react'
import styles from './HomePage.module.scss'
import { t } from '@lingui/macro'
import backgroundImg from '../../../assets/images/sport-icon-background.jpg'
import { UserSection } from './UserSection/UserSection'
import useCurrentUser from 'hooks/CurrentUser'

export const HomePage: FC = () => {
  const currentUser = useCurrentUser()

  return currentUser ? (
    <div className={styles.HomePage}>
      <div className={styles.TopContainer}>
        <img
          src={backgroundImg}
          className={styles.BackgroundImg}
          alt={t`sports icons`}
        />
        <div className={styles.UserContainer}>
          <UserSection currentUser={currentUser} />
        </div>
      </div>
    </div>
  ) : null
}
