import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { ItemType, ToggleItem } from 'components/Toggle/ToggleItem/ToggleItem'

import styles from './CategoryFilter.module.scss'

export enum CategoryFilterType {
  Schedule = 'schedule',
  Classes = 'classes',
  Locations = 'locations',
  Results = 'results'
}

interface Props {
  onSelectFilter: (filter: CategoryFilterType) => void
  selectedFilter: CategoryFilterType
  isHistoricData: boolean
}

export const CategoryFilter: FC<Props> = ({
  onSelectFilter,
  selectedFilter,
  isHistoricData = false
}) => (
  <div
    className={styles.CategoryFilterContainer}
    data-testid="category-filter-btn-container"
  >
    {isHistoricData ? (
      <ToggleItem
        itemType={
          [CategoryFilterType.Results, CategoryFilterType.Schedule].includes(
            selectedFilter
          )
            ? ItemType.Active
            : ItemType.Default
        }
        label={t`Results`}
        onClick={() => onSelectFilter(CategoryFilterType.Results)}
      />
    ) : (
      <ToggleItem
        itemType={
          selectedFilter !== CategoryFilterType.Schedule
            ? ItemType.Default
            : ItemType.Active
        }
        label={t`Schedule`}
        onClick={() => onSelectFilter(CategoryFilterType.Schedule)}
      />
    )}

    <ToggleItem
      itemType={
        selectedFilter !== CategoryFilterType.Classes
          ? ItemType.Default
          : ItemType.Active
      }
      label={t`Classes`}
      onClick={() => onSelectFilter(CategoryFilterType.Classes)}
    />
    <ToggleItem
      itemType={
        selectedFilter !== CategoryFilterType.Locations
          ? ItemType.Default
          : ItemType.Active
      }
      label={t`Locations`}
      onClick={() => onSelectFilter(CategoryFilterType.Locations)}
    />
  </div>
)
