/* eslint-disable no-alert */

import React from 'react'

import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'

import * as yup from 'yup'
import dayjs from 'dayjs'

import { useSelector } from 'react-redux'
import { channelSelector } from '../../../../../redux-store/channel/channel.selectors'
import { postProductType } from '../../../../../api/channel/settings/postProductType'

import { DropdownList } from '../../../../../components/Forms/DropdownListField/DropdownList'
import { ProductTypeID, ProductTypeList } from './ProductTypes'
import { SubmitButton } from '../../../../../components/buttons/SubmitButton/SubmitButton'
import styles from './BrandingHeader.module.scss'

export const ProductVariantField = () => {
  const channel = useSelector(channelSelector)
  const channelProductType = channel?.data?.productVariant
  const channelSlug = channel?.data?.subdomain
  const releaseDate = channel?.data?.release_date

  const initialValues = {
    productType: channelProductType.id
  }
  const channelProductTypeSchema = yup.object({
    country: yup.string()
  })

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)
    const productTypeValues = { ...values, channelSlug }
    if (values.productType === ProductTypeID.Default) {
      if (
        !window.confirm(
          'Are you sure that you would like to change this channel to become the system variant "default".'
        )
      ) {
        formikHelpers.setSubmitting(false)
        return
      }
    }
    if (!releaseDate) {
      window.alert(
        'You need to set a relase date to be able to change the sytstem variant'
      )
    } else if (
      !window.confirm(
        `Your release date it set to ${dayjs
          .unix(releaseDate)
          .format('D MMMM YYYY HH:mm')}. Are you sure that is correct?`
      )
    ) {
      formikHelpers.setSubmitting(false)
      return
    }

    const response = await postProductType(productTypeValues)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={channelProductTypeSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.DropdownFieldWrapper}>
              <Field
                component={DropdownList}
                dropdownListData={ProductTypeList}
                value={props.values.productType}
                id="productType"
                name="productType"
                label={t`System variant`}
                placeholder={t`please select a system variant`}
                isValid={!props.errors.country}
                validationMessage={props.errors.country}
                type="dark"
                onChange={(id: string) =>
                  props.setFieldValue('productType', id)
                }
              />
            </div>

            <SubmitButton
              type="secondary"
              disabled={!props.dirty || props.isSubmitting}
              label={t`Save changes`}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
