import React, { FC } from 'react'

import styles from './TableHeader.module.scss'
import {
  PlayerStatisticsTableColumns,
  PlayerStatsVariantsIdentifiers
} from '../PlayerStatisticsTable.interface'
import { HeaderCell } from './HeaderCell'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import classNames from 'classnames'

interface Props {
  columns: PlayerStatisticsTableColumns[]
  selectedFilter: string
  descending: boolean
  onSelectedFilterClick: (identifier: string) => void
}

export const TableHeader: FC<Props> = ({
  columns,
  descending,
  selectedFilter,
  onSelectedFilterClick
}) => {
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const handleSelectedFilter = (identifier: string) => {
    onSelectedFilterClick(identifier)
  }
  return (
    <div
      className={classNames(styles.TableHeader, {
        [styles.ThemedHeader]: isRootOrChildChannel
      })}
    >
      <HeaderCell
        name="#"
        identifier={PlayerStatsVariantsIdentifiers.Rank}
        abbreviation="#"
        hasThemedHeader={isRootOrChildChannel}
        onClick={() => null}
        showArrow={false}
        descending={false}
      />
      {columns.map((col: PlayerStatisticsTableColumns) => {
        const isSelected = selectedFilter === col.identifier

        return (
          <HeaderCell
            name={col.name}
            abbreviation={col.abbreviation}
            identifier={col.identifier}
            key={col.identifier}
            hasThemedHeader={isRootOrChildChannel}
            onClick={() => handleSelectedFilter(col.identifier)}
            showArrow={isSelected}
            descending={descending}
          />
        )
      })}
    </div>
  )
}
