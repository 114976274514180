import React, { FC } from 'react'
import { Team } from '../Team/Team'
import { TeamScore } from '../TeamScore/TeamScore'
import styles from './TeamsAndScore.module.scss'

export enum EndResult {
  Win = 'Win',
  Loss = 'Loss',
  Equal = 'Equal',
  Default = 'Default'
}
interface Props {
  teamLogo: string
  teamName: string
  score: number
  result: EndResult
  hasBeenPlayed: boolean
  isLive: boolean
  showScore: boolean
}
export const TeamsAndScore: FC<Props> = ({
  teamLogo,
  teamName,
  score,
  result,
  hasBeenPlayed,
  isLive,
  showScore
}) => (
  <div
    className={styles.TeamsAndScoreContainer}
    data-testid="team-and-score-container"
  >
    <div className={styles.TeamsContainer}>
      <Team teamLogo={teamLogo} teamName={teamName} result={result} />
    </div>
    {showScore && (
      <TeamScore
        score={score}
        hasBeenPlayed={hasBeenPlayed}
        isLive={isLive}
        result={result}
      />
    )}
  </div>
)
