import { Trans } from '@lingui/macro'
import React, { FC } from 'react'

import styles from '../AdminDetails.module.scss'
import CheckmarkIcon from '../../Icons/CheckmarkIcon'

interface ShowAdminProps {
  channelSlug: string
}

export const ShowAdmin: FC<ShowAdminProps> = ({ channelSlug }) => {
  const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  return (
    <div className={styles.AdminView} data-testid="admin-email-view">
      <div className={styles.LightText} data-testid="channel-admin-label">
        <Trans>Channel Admin:</Trans>
      </div>
      <div className={styles.Checkmark} data-testid="channel-has-admin-email">
        <CheckmarkIcon />
      </div>
      <a
        className={styles.LinkText}
        href={`${baseURL}/admin/settings/account/users?select_company=${channelSlug}`}
        data-testid="admin-show-all-link"
      >
        <Trans>Show All</Trans>
      </a>
    </div>
  )
}
