import { AnyAction } from 'redux'
import LANGUAGE from './language.constants'

export interface Language {
  language: any
}

export const createInitialState = () => {
  return {
    language: sessionStorage.getItem('language')
  }
}

const initialState = {
  ...createInitialState()
}

const language = (state: Language = initialState, action: AnyAction) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case LANGUAGE.SET_LANGUAGE:
      sessionStorage.setItem('language', action.language)
      return {
        language: action.language
      }
    default:
      return state
  }
}

export default language
