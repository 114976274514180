import React, { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Trans, t } from '@lingui/macro'

import { getPromotedChannels } from 'api/schedule/getPromotedChannels'
import { getPromotedChannelsForRoot } from 'api/schedule/getPromotedChannelsForRoot'
import { useDataApi } from 'api/useDataApi'
import { channelSelector } from 'redux-store/channel/channel.selectors'

import { Loader } from 'components/Loader/Loader'
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage'

import styles from './TrendingChannels.module.scss'
import { ChannelList } from 'components/ChannelList/ChannelList'
interface Props {
  onTrendingChannelsExist: () => void
}
export const TrendingChannels: FC<Props> = ({ onTrendingChannelsExist }) => {
  const { company } = useParams<{ company: string | undefined }>()

  const channel = useSelector(channelSelector)

  const subdomain = channel?.data?.subdomain
  const isCurrentChannel = subdomain && subdomain === company
  const isRootChannel = isCurrentChannel && channel?.data?.isRootChannel
  const isChildOfRootChannel =
    isCurrentChannel && channel?.data?.isChildOfRootChannel

  const isRootOrChild = isRootChannel || isChildOfRootChannel

  const { isLoading, isError, data } = useDataApi({
    apiCall: useCallback(
      isRootOrChild ? getPromotedChannelsForRoot : getPromotedChannels,
      [isRootOrChild]
    ),
    parameters: useMemo(() => isRootOrChild && subdomain, [isRootOrChild])
  })

  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    return (
      <ErrorMessage
        title={t`Something went wrong when trying to get the list of trending channels`}
        subText={''}
      />
    )
  }
  if (data && data.length > 0) {
    onTrendingChannelsExist()
    return (
      <div
        className={styles.Container}
        data-testid="trending-channels-container"
      >
        <h4>
          <Trans>Trending channels</Trans>
        </h4>
        <ChannelList list={data} isOnRootOrChildChannel={isRootOrChild} />
      </div>
    )
  } else return null
}
