import { t } from '@lingui/macro'
import { Subscription } from 'interfaces'
import PAYMENTS from 'redux-store/payments/payments.constants'

export const getBodyText = (currentPayment: any) => {
  switch (currentPayment.type) {
    case PAYMENTS.TYPE_PPV:
      return { content: t`Gain full access to this stream` }
    case PAYMENTS.TYPE_SUBSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const subscription: Subscription = currentPayment?.subscription
      if (subscription) {
        if (subscription?.subscription_type === 'monthly_supporter') {
          return { content: subscription.body.replaceAll('\r\n', '<br>') }
        }
        if (subscription.body) {
          return { content: subscription.body }
        }
      }
      return { content: '' }
    default:
      return { content: '' }
  }
}
