import channelsActions from '../../redux-store/channel/channels.actions'
import userActions from '../../redux-store/user/user.actions'
import store from '../../redux-store/store'
import SOLIDSPORT_API_PATHS from '../../constants/solidsport_api_paths.constans'
import authHeader from '../../helpers/authHeader'
import { Company } from '../../interfaces/Company.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { RootState } from '../../redux-store/store'

export const toggleFollow = async () => {
  const state: RootState = store.getState()
  const channel = state.channel.data!
  const following =
    state.user.followed_companies &&
    state.user.followed_companies.includes(channel.subdomain)

  if (following) {
    await unfollowChannel(channel)
  } else {
    await followChannel(channel)
  }
}

export async function followChannel(channel: Company) {
  store.dispatch(userActions.addFollowCompany(channel))

  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `${SOLIDSPORT_API_PATHS.USERS.FOLLOW.COMPANY}/${channel.subdomain}`,
      method: ApiEndpoint.METHODS.PUT,
      headers: authHeader(),
      authenticatable: true
    })
    if (response.status !== 200) {
      store.dispatch(userActions.followCompanyError())
      throw Error('unable to follow channel: server error')
    }
    store.dispatch(userActions.addFollowCompanySuccess())
    store.dispatch(channelsActions.incrementFollowerCount())
  } catch (error: any) {
    // console.error(error)
    store.dispatch(userActions.followCompanyError())
  }
}

export async function unfollowChannel(channel: Company) {
  store.dispatch(userActions.removeFollowCompany(channel.subdomain))
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `${SOLIDSPORT_API_PATHS.USERS.UNFOLLOW.COMPANY}/${channel.subdomain}`,
      method: ApiEndpoint.METHODS.PUT,
      headers: authHeader(),
      authenticatable: true
    })
    if (response.status !== 200) {
      store.dispatch(userActions.followCompanyError())
      throw Error('unable to unfollow channel: server error')
    }
    store.dispatch(userActions.removeFollowCompanySuccess())
    store.dispatch(channelsActions.decrementFollowerCount())
  } catch (error: any) {
    // console.error(error)
    store.dispatch(userActions.followCompanyError())
  }
}
