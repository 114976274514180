import { StreamUrls } from 'features/viewerPage/viewerPage.interfaces'
import STREAM from './stream.constants'

export const fetchStreamStart = () => ({
  type: STREAM.STREAM_FETCH_START
})
export const fetchStreamSuccess = (data: any) => ({
  type: STREAM.STREAM_FETCH_SUCCESS,
  data
})
export const fetchStreamError = (error: any) => ({
  type: STREAM.STREAM_FETCH_ERROR,
  error
})
export const setVoucherPaymentStart = () => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT_START
})
export const setVoucherPaymentSuccess = (streamUrl: StreamUrls) => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT,
  data: { streamUrl }
})
export const setVoucherPaymentError = () => ({
  type: STREAM.STREAM_MAKE_VOUCHER_PAYMENT_ERROR
})
export const clearStream = () => ({
  type: STREAM.CLEAR_STREAM
})
export const incrementStreamUserLikeCount = () => ({
  type: STREAM.INCREMENT_STREAM_USER_LIKE_COUNT
})
export const decrementStreamUserLikeCount = () => ({
  type: STREAM.DECREMENT_STREAM_USER_LIKE_COUNT
})

export const setStreamIsLive = () => ({
  type: STREAM.STREAM_IS_LIVE
})

export const updateStartTime = (newStartTime: number) => ({
  type: STREAM.UPDATE_START_TIME,
  newStartTime
})
