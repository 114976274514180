/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import { Game } from '../../../../../../interfaces'
import Button, {
  ButtonType
} from '../../../../../../components/buttons/button/Button'
import GameThumbNail from '../../../../../../components/cards/BroadcastThumbnail/Game/TeamVsTeam/GameThumbNail'
import styles from '../ActivateLivestreamModal.module.scss'
import { DefaultPrices } from '../defaultPrices'
import { DefaultPriceList } from './DefaultPriceList'

interface ActivateLivestreamProps {
  game: Game
  isMobile: boolean
  prices?: DefaultPrices
  onActivateLivestream: () => void
  onClose: () => void
}

export const ActivateLivestream: FC<ActivateLivestreamProps> = ({
  game,
  isMobile,
  prices,
  onActivateLivestream,
  onClose
}) => {
  const contentDescription = t`Once the stream is published, your visitors can find the stream on your channel.`

  return (
    <div className={styles.ModalContent}>
      <div className={styles.TopContainer}>
        <div
          className={styles.ContentTitle}
          data-testid="activate-livestream-content-title"
        >
          <Trans>Publish stream</Trans>
        </div>
        {!isMobile && (
          <div className={styles.ContentDescription}>{contentDescription}</div>
        )}
      </div>
      {prices && (
        <div className={styles.TicketPriceContainer}>
          <div className={styles.TicketPriceTitle}>
            <Trans>Default ticket price:</Trans>
          </div>
          <div className={styles.TicketPriceDescription}>
            <DefaultPriceList prices={prices} />
          </div>
        </div>
      )}
      <div className={styles.GameContainer}>
        <GameThumbNail
          homeTeam={game.home_team!}
          awayTeam={game.away_team!}
          isLive={false}
          isReplay={false}
          startTime={game.start_at}
          duration={game.end_at - game.start_at}
          type={game.type}
          hasHoverEffect={false}
          highlightFromDate={null}
          thumbnailSize={isMobile ? 'small' : 'medium'}
          showRootDomainLabel={false}
        />
        <div className={styles.GameTitle}>{game?.title}</div>
        <div className={styles.GameDescription}>
          <Trans>Game</Trans>
        </div>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          type={ButtonType.Primary}
          buttonSize="Medium"
          label={t`Publish stream`}
          onClick={onActivateLivestream}
        />
        <Button
          type={ButtonType.GhostWithBoarder}
          buttonSize="Medium"
          label={t`Cancel`}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
