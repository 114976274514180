import TagManager from 'react-gtm-module'

export const triggerGTMOpenAddTeamModal = (
  channelName: string,
  channelSlug: string,
  rootChannelDomain: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_add_team_clicked',
      channel_name: channelName,
      channel_slug: channelSlug,
      root_channel_domain: rootChannelDomain
    }
  })
}

export const triggerGTMImportGames = (
  selectedTeamSlug: string,
  channelName: string,
  channelSlug: string,
  rootChannelDomain: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_connect_competition_clicked',
      selected_channel_to_connect_slug: selectedTeamSlug,
      channel_name: channelName,
      channel_slug: channelSlug,
      root_channel_domain: rootChannelDomain
    }
  })
}

export const triggerGTMClickTeamItem = (
  selectedTeamSlug: string,
  channelName: string,
  channelSlug: string,
  rootChannelDomain: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sports_data_team_list_item_clicked',
      selected_team_slug: selectedTeamSlug,
      channel_name: channelName,
      channel_slug: channelSlug,
      root_channel_domain: rootChannelDomain
    }
  })
}
