import React, { useState, useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import useAuthentication from '../../../hooks/Authentication'
import { LoginLayerType, ModalContent } from '../ModalContent/ModalContent'

export const UserSessionContainer: FC = () => {
  const { mode, loginLayerOpen } = useAuthentication()
  const dispatch = useDispatch()
  const [modeToShow, setModeToShow] = useState<LoginLayerType>(mode || 'login')

  useEffect(() => {
    setModeToShow(mode)
  }, [mode])

  const readyCallback = () => {
    dispatch(authenticationActions.closeLoginLayer())
  }

  const switchModeCallback = (nextMode: LoginLayerType) => {
    setModeToShow(nextMode)
  }

  if (loginLayerOpen) {
    try {
      window.scrollTo(0, 0)
    } catch (e) {}

    return (
      <ModalContent
        readyCallback={readyCallback}
        switchModeCallback={switchModeCallback}
        isLoginLayerOpen={loginLayerOpen}
        loginLayerType={modeToShow}
      />
    )
  }
  return null
}
