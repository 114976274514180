import React, { FC } from 'react'

import {
  ArrowDirection,
  PaginationArrowButton,
  PaginationButtonType
} from 'components/buttons/PaginationButton/PaginationArrowButton'
import styles from './PaginationButtons.module.scss'

interface Props {
  currentPage: number
  totalPages: number
  onIncrementPage: () => void
  onDecrementPage: () => void
}

export const PaginationButtons: FC<Props> = ({
  currentPage,
  totalPages,
  onIncrementPage,
  onDecrementPage
}) => (
  <div className={styles.Container}>
    <PaginationArrowButton
      type={PaginationButtonType.dark}
      onClick={onDecrementPage}
      disabled={currentPage === 1}
      direction={ArrowDirection.left}
    />
    <PaginationArrowButton
      type={PaginationButtonType.dark}
      onClick={onIncrementPage}
      disabled={currentPage === totalPages}
      direction={ArrowDirection.right}
    />
  </div>
)
