import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import { TitleThumbnailSection } from '../TitleThumbnailSection/TitleThumbnailSection'
import styles from './LiveContentSection.module.scss'
import useFetchLiveBroadcastData from './UseFetchLiveBroadcastsData'

interface Props {
  channelSlug: string
}
const LiveContentSection: FC<Props> = ({ channelSlug }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const largerThumbnailSizes =
    currentBreakpoint < Breakpoint.sm ? 'medium' : 'large'
  const defaultThumbnailSizes =
    currentBreakpoint < Breakpoint.sm ? 'small' : 'large'

  const [liveBroadcastDataScrollRef, setLiveBroadcastDataRef] =
    useState<MutableRefObject<Element | null> | null>(null)

  const [liveBroadcastsData, setLiveBroadcastsData] = useState<any[]>([])

  const getLiveBroadcastDataRef = (
    pastRef: MutableRefObject<Element | null>
  ) => {
    setLiveBroadcastDataRef(pastRef)
  }
  const [liveBroadcastData] = useFetchLiveBroadcastData(
    channelSlug,
    liveBroadcastDataScrollRef
  )
  useEffect(() => {
    if (!liveBroadcastData.isLoading && liveBroadcastData.data.data) {
      setLiveBroadcastsData((prev: any[]) => [
        ...prev,
        ...liveBroadcastData.data.data
      ])
    }
  }, [liveBroadcastData.data, liveBroadcastData.isLoading])

  const thumbnailSize =
    liveBroadcastsData?.length > 2
      ? defaultThumbnailSizes
      : largerThumbnailSizes

  if (
    liveBroadcastsData &&
    liveBroadcastsData.length > 0 &&
    !liveBroadcastData.isError
  ) {
    return (
      <div
        data-testid="live-section-container"
        className={styles.LiveContentSection}
      >
        <TitleThumbnailSection
          getInfiniteScrollRef={getLiveBroadcastDataRef}
          thumbnailContent={liveBroadcastsData}
          sectionTitle={t`Live now`}
          size={thumbnailSize}
          useLeftPadding
          thumbnailsCardIsShared={false}
          isLoading={liveBroadcastData.isLoading}
          linkLabel=""
          showAllLinkPath=""
        />
      </div>
    )
  }
  return null
}

export default LiveContentSection
