import React, { FC } from 'react'

import { GameScheduleCardPreLoader } from 'components/preloaders/GameScheduleCardPreLoader/GameScheduleCardPreLoader'
import { TogglePreLoader } from 'components/preloaders/TogglePreLoader/TogglePreLoader'
import styles from './SchedulePreloader.module.scss'

interface Props {
  length: number
}
export const SchedulePreloader: FC<Props> = ({ length }) => {
  return (
    <div data-testid="game-schedule-preloader" className={styles.Container}>
      <div className={styles.ToggleContainer}>
        <TogglePreLoader />
      </div>
      <div className={styles.Preloaders}>
        {Array.from({ length }, (_, i) => (
          <GameScheduleCardPreLoader key={i} />
        ))}
      </div>
    </div>
  )
}
