import React, { FC } from 'react'
import { useQuill } from 'react-quilljs'
import Delta from 'quill-delta'

import {
  ButtonType,
  LinkButton
} from 'components/buttons/LinkButton/LinkButton'
import { t } from '@lingui/macro'
import styles from './StartPageBanner.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface Props {
  title: string
  content: Delta
  imageUrl?: string
  buttonLabel?: string
  buttonLink?: string
  buttonLinkTarget?: LinkTarget
  imageLink?: string
}

export interface StartBanner extends Props {}

export interface InfoBoxDisplay extends Props {}

export const InfoBox: FC<Props> = ({
  title,
  content,
  imageUrl,
  buttonLabel,
  buttonLink,
  buttonLinkTarget
}) => {
  const theme = 'snow'

  const modules = {
    toolbar: false
  }
  const { quill, quillRef } = useQuill({ theme, modules, readOnly: true })

  if (quill) {
    quill.setContents(content)
    quill.enable(false)
  }

  return (
    <div className={styles.Container}>
      <div className={styles.InformationBox}>
        <div className={styles.TextContent}>
          <h3>{title}</h3>
          <div className={styles.Editor} ref={quillRef} />
          {quill && (
            <div
              data-testid="html-content-container"
              dangerouslySetInnerHTML={{ __html: quill.root.innerHTML! }}
            />
          )}
        </div>
        {buttonLabel && buttonLink && (
          <LinkButton
            label={buttonLabel}
            type={ButtonType.Secondary}
            buttonSize="Medium"
            href={buttonLink}
            linkTarget={buttonLinkTarget}
          />
        )}
      </div>
      {imageUrl && (
        <div className={styles.ImageContainer}>
          <img src={imageUrl} alt={t`info box image for ${title}`} />
        </div>
      )}
    </div>
  )
}
