import TagManager from 'react-gtm-module'

export const triggerGTMOnSkipAddPhoneNumber = (
  channelName: string,
  channelSlug: string,
  rootChannelDomain?: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'on_skip_add_phone_number_click',
      channel_name: channelName,
      channel_slug: channelSlug,
      root_channel_name: rootChannelDomain || 'Solidsport'
    }
  })
}

export const triggerGTMPhoneNumberIsAdded = (
  channelName: string,
  channelSlug: string,
  rootChannelDomain?: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'phone_number_is_added',
      channel_name: channelName,
      channel_slug: channelSlug,
      root_channel_name: rootChannelDomain || 'Solidsport'
    }
  })
}
