import USER from './user.constants'
import { Company, User } from '../../interfaces'

const addFollowCompany = (company: Company) => {
  return {
    type: USER.COMPANY_FOLLOW_ADD,
    company
  }
}

const removeFollowCompany = (company: string) => {
  return {
    type: USER.COMPANY_FOLLOW_REMOVE,
    company
  }
}

const addFollowTeam = (team: string) => {
  return {
    type: USER.TEAM_FOLLOW_ADD,
    team
  }
}

const removeFollowTeam = (team: string) => {
  return {
    type: USER.TEAM_FOLLOW_REMOVE,
    team
  }
}

const userUpdatedFromServer = (user: User) => {
  return {
    type: USER.USER_UPDATED_FROM_SERVER,
    user
  }
}

const userUpdateFailed = () => {
  return { type: USER.UPDATE_USER_FAILED }
}

const updateUser = () => {
  return {
    type: USER.UPDATE_USER
  }
}

const setLanguage = (language: string) => {
  return {
    type: USER.SET_LANGUAGE,
    language
  }
}

const addFollowCompanySuccess = () => ({
  type: USER.COMPANY_FOLLOW_ADD_SUCCESS
})

const removeFollowCompanySuccess = () => ({
  type: USER.COMPANY_FOLLOW_REMOVE_SUCCESS
})

const followCompanyError = () => ({
  type: USER.COMPANY_FOLLOW_ERROR
})

const removeUser = () => ({
  type: USER.USER_REMOVED
})

const resetFromLocalState = () => ({
  type: USER.RESET_FROM_LOCAL_STATE
})

const userActions = {
  addFollowCompany,
  addFollowCompanySuccess,
  removeFollowCompany,
  removeFollowCompanySuccess,
  followCompanyError,
  addFollowTeam,
  removeFollowTeam,
  userUpdatedFromServer,
  userUpdateFailed,
  updateUser,
  setLanguage,
  removeUser,
  resetFromLocalState
}

export default userActions
