import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'

import { DropDownListItem } from 'components/Forms/DropdownListField/DropdownItemList/DropdownItemList'
import { DropdownList } from 'components/Forms/DropdownListField/DropdownList'
import { Loader } from 'components/Loader/Loader'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import ReactPortal from 'components/ReactPortal'
import MobileSelectList from 'pages/ChannelPage/Videos/Dropdown/MobileSelectList/MobileSelectList'
import { useComponentVisible } from 'helpers/useComponentVisible'

interface Props {
  value: string | undefined
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  channelSlug: string
  onChange: (id: string) => void
  isLoading: boolean
  options: ChannelEvent[]
  isError: boolean
}

export const EventsDropdown: FC<Props> = (props) => {
  const breakpoint = useCurrentBreakpoint()
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const dropdownListData: DropDownListItem[] = props.options?.map((option) => {
    const year = option.startDate && dayjs.unix(option.startDate).format('YYYY')
    const label = option.startDate ? `${option.name} (${year})` : option.name
    return {
      id: option.id,
      label
    }
  })

  if (props.isError)
    return (
      <>
        <Trans>
          There has been a problem loading the past events list, please reload
          the page
        </Trans>
      </>
    )
  const openMobileDropdownList = () => {
    if (breakpoint < Breakpoint.sm) {
      if (!isComponentVisible) {
        setIsComponentVisible(true)
      } else {
        setIsComponentVisible(false)
      }
    }
  }

  const onItemSelected = (label: string, id: string) => {
    setIsComponentVisible(false)
    props.onChange(id)
  }

  return props.isLoading ? (
    <Loader variant="small" />
  ) : (
    <div ref={ref} onClick={() => openMobileDropdownList()}>
      {!isComponentVisible ? (
        <DropdownList
          {...props}
          type="dark"
          dropdownListData={dropdownListData}
          onChange={(id: string) => {
            onItemSelected('', id)
          }}
        />
      ) : (
        <ReactPortal wrapperId="react-portal-dropdown-container">
          <MobileSelectList
            list={dropdownListData}
            onItemSelected={(name, id) => onItemSelected(name, id)}
            selectedItemId={props.value!}
            reference={ref}
            onCloseClick={() => setIsComponentVisible(false)}
          />
        </ReactPortal>
      )}
    </div>
  )
}
