import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Trans } from '@lingui/macro'
import { getNewsForChannel } from '../../../api/channel/getNews'
import { useDataApi } from '../../../api/useDataApi'

import { SearchResultCard } from '../../../components/cards/SearchResultCard/SearchResultCard'
import { SearchResultType } from '../../../components/cards/SearchResultCard/cardTypes'
import { Loader } from '../../../components/Loader/Loader'
import useInfiniteScroll from '../../../hooks/InfiniteScroll'

import { News as NewsType } from '../../../interfaces/News.interface'
import styles from './News.module.scss'
import { NewsThumbnail } from 'components/cards/NewsThumbnail/NewsThumbnail'

interface NewsProps {
  channelSlug: string
}
export const News: FC<NewsProps> = ({ channelSlug }) => {
  const PER_PAGE_DATA = 10
  const [page, setPage] = useState(1)
  const [newsData, setNewsData] = useState<NewsType[]>([])
  const infiniteScrollRef = useRef(null)
  const param = useMemo(
    () => ({ slug: channelSlug, page, perPageCount: PER_PAGE_DATA }),
    [page, channelSlug]
  )
  const { isError, isLoading, data } = useDataApi({
    apiCall: useCallback(getNewsForChannel, []),
    parameters: param
  })

  const isBottomReached = useInfiniteScroll(infiniteScrollRef)

  useEffect(() => {
    if (isBottomReached && data && data.totalCount > page * PER_PAGE_DATA) {
      setPage((prevValue) => prevValue + 1)
    }
  }, [isBottomReached, data])

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      setNewsData((prev: NewsType[]) => [...prev, ...data.data])
    }
  }, [isLoading, data, isError])

  const getFirstNewsArticle = (article: NewsType) => (
    <div className={styles.FirstArticle} key={article.id}>
      <NewsThumbnail news={article} />
    </div>
  )
  const newsList = () =>
    newsData &&
    newsData.length > 0 &&
    newsData.map((article: NewsType, index: number) => {
      const articleObj = { ...article, type: SearchResultType.Article }
      return index === 0 ? (
        getFirstNewsArticle(article)
      ) : (
        <div className={styles.OtherArticles} key={article.id}>
          <SearchResultCard content={articleObj} />
        </div>
      )
    })

  if (isError) {
    return (
      <div className={styles.EmptyListMessage}>
        <Trans>Something went wrong please try again</Trans>
      </div>
    )
  }
  return (
    <div data-testid="news-container" className={styles.NewsContainer}>
      {isLoading && newsData.length === 0 && <Loader />}
      {newsData && newsData.length > 0 ? (
        <div
          className={styles.NewsListContainer}
          data-testid="news-list-container"
        >
          {newsList()}
          <div ref={infiniteScrollRef} />
        </div>
      ) : (
        !isLoading && (
          <div
            className={styles.EmptyListMessage}
            data-testid="no-news-text-container"
          >
            <Trans>
              There are no new articles available for this association
            </Trans>
          </div>
        )
      )}
    </div>
  )
}
