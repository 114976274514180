import React, { FC, useEffect, useMemo, useState } from 'react'
import { getAllChannelEvents } from 'api/multistageTournament/getChannelEvents'
import { useDataApi } from 'api/useDataApi'
import { EventsDropdown } from 'components/EventsDropdown/EventsDropdown'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import styles from './PlayerStatistics.module.scss'

import { useHistory, useParams } from 'react-router-dom'
import { ChannelEvent } from 'features/multistageTournaments/EventScheduleTabs'
import ContentContainer from 'components/ContentContainer'

import { PlayerStatsSection } from './PlayerStatsSection'

export const PlayerStatistics: FC = () => {
  const history = useHistory()
  const [selectedEventId, setSelectedEventId] = useState()
  const [selectedEventName, setSelectedEventName] = useState('')
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data.subdomain
  const { eventslug } = useParams<{
    eventslug?: string
  }>()

  const { data: allEvents, isLoading: allEventsIsLoading } = useDataApi({
    apiCall: getAllChannelEvents,
    parameters: useMemo(() => ({ channelSlug }), [])
  })

  useEffect(() => {
    if (allEvents && allEvents.length > 0) {
      if (eventslug) {
        const event = allEvents.find(
          (pe: ChannelEvent) => pe.eventSlug === eventslug
        )
        setSelectedEventId(event.id)
        setSelectedEventName(event.name)
      }

      if (!selectedEventId) {
        const preSelectedEvent = allEvents[0]

        if (preSelectedEvent) {
          setSelectedEventId(preSelectedEvent?.id)
          setSelectedEventName(preSelectedEvent?.name)
          setUrlEventSlug(preSelectedEvent)
        }
      }
    }
  }, [allEvents])

  const setUrlEventSlug = (event: ChannelEvent) => {
    history.push(`/${channelSlug}/stats/${event.eventSlug}`)
  }
  const handleSelectedEvent = (id: string) => {
    const event = allEvents.find((pe: ChannelEvent) => pe.id === id)
    if (event) {
      setUrlEventSlug(event)
      setSelectedEventId(event.id)
      setSelectedEventName(event.name)
    }
  }

  return (
    <div className={styles.Container}>
      <ContentContainer
        hasLeftRightPadding={true}
        hasTopBottomPadding={false}
        hasLightBackground={false}
      >
        <div className={styles.DropdownContainer}>
          {selectedEventId && (
            <EventsDropdown
              validationMessage=""
              isValid={true}
              label=""
              placeholder=""
              id="eventsDropdown"
              channelSlug={channelSlug}
              isError={false}
              isLoading={allEventsIsLoading}
              value={selectedEventId}
              onChange={(id: string) => handleSelectedEvent(id)}
              options={allEvents}
            />
          )}
        </div>
      </ContentContainer>
      {selectedEventId && selectedEventId !== '' && (
        <PlayerStatsSection
          selectedEventId={selectedEventId}
          selectedEventName={selectedEventName}
        />
      )}
    </div>
  )
}
