import React, { FC } from 'react'

import classNames from 'classnames'
import styles from '../RowCell.module.scss'
export enum CellType {
  DefaultTable = 'defaultTable',
  CategoryLeader = 'categoryLeader'
}
interface Props {
  cellText: string
  isEmpty: boolean
  isSelected: boolean
  type?: CellType
}

export const DefaultRowCell: FC<Props> = ({
  cellText,
  isEmpty,
  isSelected,
  type = CellType.DefaultTable
}) =>
  !isEmpty ? (
    <div
      className={classNames(styles.RowCell, {
        [styles.Selected]: isSelected,
        [styles.DefaultRowCell]: type === CellType.DefaultTable,
        [styles.CategoryLeaderRowCell]: type === CellType.CategoryLeader
      })}
    >
      {cellText}
    </div>
  ) : (
    <div
      className={classNames(styles.RowCell, styles.DefaultRowCell, {
        [styles.Selected]: isSelected
      })}
    >
      {cellText}
    </div>
  )
