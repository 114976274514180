import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

interface ViewerPageTitleProps {
  title?: string
  rootDomain?: string
  viewerPageUrl?: string
}

export const ViewerPageTitle: FC<ViewerPageTitleProps> = ({
  title,
  rootDomain,
  viewerPageUrl
}) => {
  const domain = rootDomain || window.location.hostname
  return title && domain ? (
    <Helmet>
      <title>
        {title} | {domain}
      </title>
      <meta name="title" content={title} />
      {viewerPageUrl && <link rel="canonical" href={viewerPageUrl} />}
    </Helmet>
  ) : null
}
