import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { PasswordField } from 'components/Forms/PasswordField/PasswordField'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import styles from './ChangePassword.module.scss'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { newPasswordValidationSchema } from './NewPasswordValidationSchema'
import { patchPassword } from 'api/user/patchPassword'
import {
  StatusMessage,
  StatusMessageType
} from 'pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'

interface ChangePasswordValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}
export const ChangePasswordForm: FC = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const initialValues: ChangePasswordValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }
  const onSubmit = async (
    values: ChangePasswordValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    if (
      values.confirmNewPassword &&
      values.currentPassword &&
      values.newPassword
    ) {
      const { currentPassword, newPassword, confirmNewPassword } = values
      const response = await patchPassword(
        currentPassword,
        newPassword,
        confirmNewPassword
      )
      if (!response.isSuccess) {
        formikHelpers.setFieldError(newPassword, response.message)
      } else {
        setShowSuccessMessage(true)
        formikHelpers.resetForm()
      }
    }
    formikHelpers.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={newPasswordValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.Form} data-testid="change-password-form">
              <Field
                component={PasswordField}
                id="currentPassword"
                name="currentPassword"
                label={t`Current password`}
                placeholder={t`Write your current password here`}
                onChange={formik.handleChange}
                textValue={formik.values.currentPassword}
                isValid={!formik.errors.currentPassword}
                validationMessage={formik.errors.currentPassword}
              />
              <div className={styles.NewPasswordContainer}>
                <Field
                  component={PasswordField}
                  id="newPassword"
                  name="newPassword"
                  label={t`New password`}
                  placeholder={t`Write your new password here`}
                  onChange={formik.handleChange}
                  textValue={formik.values.newPassword}
                  isValid={!formik.errors.newPassword}
                  validationMessage={formik.errors.newPassword}
                />
                <Field
                  component={PasswordField}
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  label={t`Confirm password`}
                  placeholder={t`Confirm your new password here`}
                  onChange={formik.handleChange}
                  textValue={formik.values.confirmNewPassword}
                  isValid={!formik.errors.confirmNewPassword}
                  validationMessage={formik.errors.confirmNewPassword}
                />
              </div>
              <div className={styles.ButtonContainer}>
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SubmitButton}
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                  label={t`Save changes`}
                  isSubmitting={formik.isSubmitting}
                />
              </div>
              {showSuccessMessage && (
                <StatusMessage
                  onClose={() => setShowSuccessMessage(false)}
                  message={t`Your password have been successfully updated`}
                  messageType={StatusMessageType.Success}
                />
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
