import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
export interface TeamsForLeagueApiResponse {
  admin_email: string
  has_admin_email: boolean
  leagues: []
  playpage_title: string
  profile_image_url: string
  subdomain: string
}
export const getTeamsForLeague = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/channels/league_teams`,
      params: {
        page: page,
        per_page: perPageCount
      }
    })

    const responseData = await resultResponse
    return { data: responseData.data, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
