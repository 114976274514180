import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface Team {
  teamId: number
  teamName: string
  isConnected: boolean
}

export interface TeamResponseApi {
  badge_url: string
  company: { name: string; subdomain: string }
  country_code: string
  is_connected: boolean
  id: number
  name: string
  shirt_colors: string
  slug: string
}
export const getTeamsForCompetition = async ({
  competitionId,
  channelSlug
}: {
  competitionId: number
  channelSlug: string
}): Promise<Team[]> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/competitions/${competitionId}/teams`,
      authenticatable: true
    })

    const responseData = await resultResponse

    const mapped: Team[] = mapTeamList(responseData)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapTeamList = (responseData: ApiResponse) =>
  responseData?.data?.map((item: TeamResponseApi) => ({
    teamId: item.id,
    teamName: item.name,
    isConnected: item.is_connected
  }))
