import React, { FC, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'

import { OptionsMenuItem } from './StreamOptionsMenuItem'
import { MeatballsIcon } from 'assets/icons/MeatballsIcon'
import { Popout } from 'components/Popout/Popout'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import styles from './StreamOptions.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import {
  streamAccessRestrictionSelector,
  streamSelector,
  streamViewTypeSelector
} from 'redux-store/stream/stream.selectors'
import { AccessRestrictionType, MediaObject } from 'interfaces'

import { downloadStream } from 'api/viewer/downloadStream'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import useAdminAccess from 'hooks/AdminAccess'
import {
  getShowBroadcastInfoButton,
  getShowDownloadButton
} from 'hooks/ActionPanel/getShowActionButtons'
import { PermissionsState } from 'redux-store/permissions/permissions.interface'
import { RolesState } from 'redux-store/roles/roles.interface'
import { rolesSelector } from 'redux-store/roles/roles.reducer'
import { permissionsSelector } from 'redux-store/permissions/permissions.reducer'
import { ViewType } from 'redux-store/stream/stream.interface'
import { onHelpClick } from 'features/viewerPage/helpFunctions/onHelpClick'

export const StreamOptionsButton: FC = () => {
  const channel = useSelector(channelSelector)
  const stream = useSelector(streamSelector)
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const adminAccess = useAdminAccess()
  const permissions: PermissionsState = useSelector(permissionsSelector)

  const roles: RolesState = useSelector(rolesSelector)
  const viewType: ViewType = useSelector(streamViewTypeSelector)
  const accessRestriction: AccessRestrictionType = useSelector(
    streamAccessRestrictionSelector
  )
  const context = {
    permissions,
    roles,
    viewType,
    accessRestriction
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const channelName = channel.data?.name
  const mediaObject: MediaObject = stream.data.mediaObject

  const { title: streamTitle, slug, type: streamType } = mediaObject
  const showEditButton = adminAccess.hasChannelAccess
  const showDownloadButton = getShowDownloadButton(context)
  const showBroadcastInfoButton = getShowBroadcastInfoButton(context)

  const toggleIsOpen = () => {
    setIsOpen((open) => !open)
  }

  const onDownload = async () => {
    if (stream && currentUser) {
      try {
        window.location.href = await downloadStream(
          mediaObject.company.subdomain,
          mediaObject.id
        )
      } catch {
        console.error('error in stream download')
      }
    }
  }

  const onReportStream = () => {
    if (currentUser) {
      dispatch(userInterface.openReportContentDialog())
    } else {
      dispatch(authenticationActions.openLoginLayer())
    }
  }
  const onEdit = () => {
    const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/admin`
    const trailURL = `${slug}/edit?select_company=${mediaObject.company.subdomain}`

    if (
      streamType === 'livestream' ||
      (streamType === 'video' && mediaObject.is_replay)
    ) {
      window.location.href = `${baseURL}/livestreams/${trailURL}`
    } else if (streamType === 'video') {
      window.location.href = `${baseURL}/videos/${trailURL}`
    }
  }

  return (
    <div className={styles.OptionsMenuAnchor} ref={anchorRef}>
      <IconButton id="meatball-menu" onClick={toggleIsOpen}>
        <div className={styles.MeatballsIcon} data-testid="options-btn">
          <MeatballsIcon />
        </div>
      </IconButton>

      <Popout
        anchor="SE"
        isOpen={isOpen}
        onClose={toggleIsOpen}
        anchorRef={anchorRef}
      >
        {showEditButton && (
          <OptionsMenuItem
            icon="edit"
            onClick={() => dispatch(userInterface.openQuickEditContentDialog())}
            dataTestId="quick-edit-btn"
          >
            <Trans>Quick edit</Trans>
          </OptionsMenuItem>
        )}
        {showEditButton && (
          <OptionsMenuItem
            icon="settings"
            onClick={onEdit}
            dataTestId="edit-btn"
          >
            <Trans>Advanced settings</Trans>
          </OptionsMenuItem>
        )}
        {showBroadcastInfoButton && (
          <OptionsMenuItem
            icon="broadcastInfo"
            onClick={() =>
              dispatch(userInterface.openBroadcastInformationModal())
            }
            dataTestId="broadcast-info-btn"
          >
            <Trans>Broadcast information</Trans>
          </OptionsMenuItem>
        )}

        {showDownloadButton && (
          <OptionsMenuItem
            icon="download"
            onClick={onDownload}
            dataTestId="download-btn"
          >
            <Trans>Download</Trans>
          </OptionsMenuItem>
        )}

        <OptionsMenuItem
          icon="help"
          onClick={() => onHelpClick(channelName, streamTitle)}
          dataTestId="help-btn"
        >
          <Trans>Help</Trans>
        </OptionsMenuItem>
        <OptionsMenuItem
          icon="flag"
          onClick={onReportStream}
          dataTestId="report-btn"
        >
          <Trans>Report stream</Trans>
        </OptionsMenuItem>
      </Popout>
    </div>
  )
}
