// https://jasonwatmore.com/post/2020/03/02/react-hooks-redux-user-registration-and-login-tutorial-example#user-service-js
// import config from 'config'
// import { authHeader } from '../helpers'
import { t } from '@lingui/macro'
import ApiEndpoint, {
  ApiError,
  ApiResponse,
  getSession
} from '../../network/ApiEndpoint'
import { saveStateToStorage } from '../../helpers/localStorageHelper'
import { dynamicActivate } from '../../translations/i18n'

const authenticationService = {
  login,
  loginCallback,
  facebookLogin,
  logout,
  register,
  sendEmail
}

function setUserAndToken(loginData: any) {
  const { token, user } = loginData
  saveStateToStorage('user', user)
  saveStateToStorage('appToken', token)
  saveStateToStorage('tokenDate', Date.now())
  sessionStorage.setItem('language', user.locale)
  dynamicActivate(user.locale)
  return user
}

function login(email: string, password: string) {
  return ApiEndpoint.call({
    path: 'session/auth',
    method: ApiEndpoint.METHODS.POST,
    credentials: ApiEndpoint.CREDENTIALS.INCLUDE,
    params: {
      username: email,
      password: password
    }
  })
    .then((response: ApiResponse) => {
      if (response?.data) {
        return setUserAndToken(response.data)
      }
      return Promise.reject(new Error(t`Failed to log in`))
    })
    .catch((error: ApiError) => {
      console.log('login error', error)
      return Promise.reject(error)
      // return error
    })
}

function loginCallback() {
  return getSession()
    .then((response: ApiResponse) => {
      if (response.data.error) {
        return Promise.reject(response.data.error)
      }
      return setUserAndToken(response.data)
    })
    .catch((error: ApiError) => {
      console.log('login callback', error)
      return Promise.reject(error)
    })
}

function facebookLogin(
  id: string,
  email: string,
  first_name: string,
  last_name: string,
  access_token: string,
  company?: string | undefined
) {
  return ApiEndpoint.call({
    path: 'session/auth/facebook',
    method: ApiEndpoint.METHODS.POST,
    credentials: ApiEndpoint.CREDENTIALS.INCLUDE,
    params: {
      uid: id,
      email: email,
      first_name: first_name,
      last_name: last_name,
      access_token: access_token,
      company: company
    }
  })
    .then((response: ApiResponse) => {
      if (response.data.error) {
        return Promise.reject(response.data.error)
      }
      return setUserAndToken(response.data)
    })
    .catch((error: ApiError) => {
      return Promise.reject(error)
    })
}

function logout() {
  ApiEndpoint.call({
    path: 'session/expire',
    method: ApiEndpoint.METHODS.GET,
    credentials: ApiEndpoint.CREDENTIALS.INCLUDE
  }).finally(() => {
    localStorage.removeItem('user')
    localStorage.removeItem('appToken')
    localStorage.removeItem('tokenDate')
  })
}

function register(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  signupToken?: string | undefined,
  emailConsent?: boolean
) {
  return ApiEndpoint.call({
    path: 'users/register',
    method: ApiEndpoint.METHODS.POST,
    credentials: ApiEndpoint.CREDENTIALS.SAME_ORIGIN,
    params: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      signup_token: signupToken,
      email_consent: emailConsent
    },
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
  })
    .then((response: ApiResponse) => {
      return setUserAndToken(response.data)
    })
    .catch((error: ApiError) => {
      return Promise.reject(error)
    })
}

function sendEmail(email: string) {
  return ApiEndpoint.call({
    path: 'users/recover_password',
    method: ApiEndpoint.METHODS.POST,
    params: {
      email: email
    },
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
  })
    .then((response: ApiResponse) => {
      console.log(response)
    })
    .catch((error: ApiError) => {
      return Promise.reject(error)
    })
}

export default authenticationService
