import React, { FC } from 'react'
import { Cup, Featured, League, Sport, TallThumbnailType } from './cardTypes'
import FeaturedContent from './Content/Featured'
import SportContent from './Content/Sport'

interface Props {
  content: Featured | Sport | League | Cup
}

const ContentTypeSelector: FC<Props> = ({ content }) => {
  switch (content.type) {
    case TallThumbnailType.Featured:
      return <FeaturedContent content={content as Featured} />

    case TallThumbnailType.Sport:
      return <SportContent content={content as Sport} />

    default:
      return null
  }
}

export default ContentTypeSelector
