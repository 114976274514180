import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import ContentContainer from 'components/ContentContainer'
import { CustomPageSection } from 'features/customPages/CustomPage/CustomPage'
import { CustomPageNotFound } from 'features/customPages/CustomPageNotFound'
import {
  CustomPageMeta,
  PageListAccordion
} from 'features/customPages/pageList/PageListAccordion'
import { ContentSelector } from 'features/customPages/ContentSelector'

interface Props {
  customPages: CustomPageMeta[]
  channelSlug: string
}

export const CustomPageRoutes: FC<Props> = ({ customPages, channelSlug }) => {
  return (
    <>
      <Route path="/:company/page/:slug">
        <CustomPageNotFound pageList={customPages} channelSlug={channelSlug} />
      </Route>
      <Route exact path="/:company/menu/:customsubmenu">
        {customPages.length > 0 && (
          <ContentContainer
            hasLightBackground
            hasLeftRightPadding
            hasTopBottomPadding
          >
            <PageListAccordion
              pageList={customPages}
              channelSlug={channelSlug}
            />
          </ContentContainer>
        )}
      </Route>
      <Switch>
        <Route exact path="/:company/menu/:customsubmenu/:custompage">
          <ContentSelector
            customPages={customPages}
            channelSlug={channelSlug}
          />
        </Route>
      </Switch>
      <Route
        exact
        path="/:company/menu/:customsubmenu/:submenuoption/:custompage"
      >
        <CustomPageSection />
      </Route>
    </>
  )
}
