import React, { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStatusMessageDispatchContext } from '../../../pages/ChannelPage/ContentManager/StatusMessageContext'

import { getChannel } from '../../../api/channel/getChannel'
import { useDataApi } from '../../../api/useDataApi'
import { BackButton } from '../../../components/page/header/SettingsContentHeader/BackButton/BackButton'
import styles from './SelectedTeamHeader.module.scss'

interface Props {
  teamChannelSlug: string
  channelSlug: string
}
export const SelectedTeamHeader: FC<Props> = ({
  teamChannelSlug,
  channelSlug
}) => {
  const history = useHistory()
  const channel = useDataApi({
    apiCall: useCallback(getChannel, []),
    parameters: teamChannelSlug
  })

  const dispatch = useStatusMessageDispatchContext()
  useEffect(() => {
    dispatch({
      type: 'team_selected',
      selectedTeam: {
        name: channel?.data?.name,
        slug: channel?.data?.subdomain
      }
    })
  }, [channel, dispatch])
  const channelName = channel.data?.name
  const handleOnBack = () => {
    history.push(`/${channelSlug}/contentmanager/teams`)
  }
  return (
    <div className={styles.SelectedTeamHeader}>
      <BackButton onBack={handleOnBack} />
      <h4>{channelName}</h4>
    </div>
  )
}
