import React, { FC } from 'react'
import styles from './LinkIconButton.module.scss'
import TextIconButton, { IconButtonProps, ButtonType } from '../TextIconButton'
import { LinkTarget } from 'interfaces/LinkTarget.interface'

interface LinkButtonProps extends Omit<IconButtonProps, 'onClick'> {
  href: string
  children: React.ReactNode
  rootDomain?: string
  linkOnClick?: () => void
}

export const LinkIconButton: FC<LinkButtonProps> = ({
  href,
  linkOnClick,
  rootDomain,
  ...props
}) => {
  const link = rootDomain ? `https://${rootDomain}${href}` : href
  const target = rootDomain ? LinkTarget.NewWindow : LinkTarget.CurrentWindow
  return (
    <a
      className={styles.LinkIconButton}
      href={link}
      onClick={linkOnClick}
      target={target}
    >
      <TextIconButton {...props} onClick={() => null} />
    </a>
  )
}

export { ButtonType }
