/* eslint-disable no-nested-ternary */
import React, { FC, useCallback, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { useDataApi } from '../../../api/useDataApi'
import { Loader } from '../../../components/Loader/Loader'
import { getPage, GetPageResponse, Page } from '../../../api/channel/getPage'
import { PagePost } from '../../../components/Post/PagePost'
import Breadcrumb from '../Header/Breadcrumb/Breadcrumb'
import styles from './Impressum.module.scss'
export const Impressum: FC = () => {
  const { company: channelSlug }: { company: string } = useParams()
  const pageSlug = 'impressum'

  const parameters = useMemo(() => ({ channelSlug, pageSlug }), [channelSlug])

  const { isLoading, data } = useDataApi({
    apiCall: useCallback(getPage, [parameters]),
    parameters
  })

  const response: GetPageResponse = data
  const impressumData: Page | undefined = response && response.data

  return isLoading ? (
    <Loader />
  ) : impressumData ? (
    <div>
      <div className={styles.BreadcrumbContainer}>
        <Breadcrumb />
      </div>
      <PagePost
        richText={impressumData.content || ''}
        headerImageUrl={impressumData.imageUrl}
      />
    </div>
  ) : response.status === 404 ? (
    <Redirect to="/404" />
  ) : (
    <Trans>Something went wrong please try again</Trans>
  )
}
