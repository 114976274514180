import React, { FC } from 'react'
import { ChannelListItemLink } from 'components/ChannelListItem/ChannelListItemLink'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import styles from './ChannelResult.module.scss'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
export interface SearchResultChannelItem {
  name: string
  logoUrl: string
  sportName: string
  channelType: ChannelTypes
  subdomain: string
  rootDomain?: string
}
interface Props {
  channelList: SearchResultChannelItem[]
  isRootOrChildChannel: boolean
}

export const ChannelResult: FC<Props> = ({
  channelList,
  isRootOrChildChannel
}) => {
  if (channelList.length > 0) {
    const channelData = useSelector(channelSelector)
    const channelRootDomain = channelData?.data?.is_root_channel
      ? channelData?.data?.root_domain
      : channelData?.data?.root_channel?.root_domain
    return (
      <div className={styles.ListContainer} data-testid="channel-result-list">
        {channelList.map((channelItem: SearchResultChannelItem) => {
          const rootDomain =
            channelItem.rootDomain &&
            channelItem.rootDomain !== channelRootDomain
              ? channelItem.rootDomain
              : ''

          const sportLabel = isRootOrChildChannel ? '' : channelItem.sportName
          return (
            <ChannelListItemLink
              key={channelItem.subdomain}
              channelSubdomain={channelItem.subdomain}
              channelName={channelItem.name}
              channelLogo={channelItem.logoUrl}
              rootDomain={rootDomain}
              sportLabel={sportLabel}
              channelType={channelItem.channelType}
            />
          )
        })}
      </div>
    )
  } else return null
}
