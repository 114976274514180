import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

interface Props {
  size: 'small' | 'medium' | 'large'
  text: string
  id: string
  onClick: (id: string) => void
  isSelected: boolean
  tabIndex: number
}

const FilterItemButton: FC<Props> = ({
  size = 'medium',
  text,
  id,
  onClick,
  isSelected = false,
  tabIndex = 0
}) => {
  const sizeStyle = `FilterItemButton--${size}`
  const handleClick = (itemId: string) => onClick(itemId)
  const dataTestName = `filter-item-button-${
    isSelected ? 'selected' : 'unselected'
  }`
  return (
    <div
      data-testid={dataTestName}
      className={classNames(styles.FilterItemButton, styles[sizeStyle], {
        [styles.Selected]: isSelected
      })}
      tabIndex={tabIndex}
      role="button"
      onClick={() => handleClick(id)}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleClick(id)
        }
      }}
    >
      {text}
    </div>
  )
}
export default FilterItemButton
