import React from 'react'

export const BinIcon = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.919 2.98938H16.3123C16.8324 2.98938 17.3312 3.15501 17.699 3.44984C18.0668 3.74467 18.2734 4.14454 18.2734 4.56149V6.52663"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.081 2.98938H5.68775C5.16761 2.98938 4.66877 3.15501 4.30098 3.44984C3.93319 3.74467 3.72656 4.14454 3.72656 4.56149V6.52663"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0881 1.50022L11.9062 1.5"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 7.625H21"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.7266 14V20.25C18.7266 20.5815 18.5829 20.8994 18.3272 21.1339C18.0714 21.3683 17.7246 21.5 17.3629 21.5H5.0902C4.72854 21.5 4.38169 21.3683 4.12596 21.1339C3.87023 20.8994 3.72656 20.5815 3.72656 20.25V10.25"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.36328 19.3516V10.25M10.9996 19.3516V10.25M14.636 19.3516V10.25"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
