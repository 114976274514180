import React, { FC } from 'react'
import {
  PlayerStatisticsTableColumns,
  PlayerStatisticsTableRows
} from '../PlayerStatisticsTable.interface'
import { RowCell } from './RowCell/RowCell'
import { RankRowCell } from './RowCell/CellTypes/RankRowCell'
import styles from './Row.module.scss'

interface Props {
  columns: PlayerStatisticsTableColumns[]
  row: PlayerStatisticsTableRows
  selectedColumn: string
  rowNumber: number
}

export const Row: FC<Props> = ({ columns, row, rowNumber, selectedColumn }) => {
  return (
    <div className={styles.Row} key={rowNumber}>
      <RankRowCell rowNumber={rowNumber} />
      {columns.map((column, columnIndex) => {
        const isSelected = selectedColumn === column.identifier
        return (
          <RowCell
            column={column}
            row={row}
            key={columnIndex}
            isSelected={isSelected}
          />
        )
      })}
    </div>
  )
}
