export const getHasImpressumPage = (countryCode: string) => {
  switch (countryCode) {
    case 'DE':
    case 'AT':
    case 'CH':
      return true
    default:
      return false
  }
}
