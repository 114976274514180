import React, { FC } from 'react'
import { getIconForCardType } from './getIconForCardType'

import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { t } from '@lingui/macro'
import { BinIcon } from 'assets/icons/BinIcon'
import styles from './CreditCard.module.scss'

export enum CreditCardType {
  MC = 'mc',
  Maestro = 'maestro',
  Visa = 'visa',
  Generic = 'generic'
}
interface Props {
  brandType: CreditCardType
  code: string
  expirationYear: number
  expirationMonth: number
  referenceId: string
  cardHolderName: string
  onDeleteCardClick: (referenceId: string) => void
}

export const CreditCard: FC<Props> = ({
  brandType,
  code,
  expirationYear,
  expirationMonth,
  referenceId,
  cardHolderName,
  onDeleteCardClick
}) => {
  const cardNumberText = `**** **** **** ${code}`
  const expirationDate = `${expirationMonth}/${expirationYear}`
  return (
    <div className={styles.CreditCard}>
      <div className={styles.InformationContainer}>
        <div className={styles.Icon}>{getIconForCardType(brandType)}</div>
        <div className={styles.TextInfo}>
          <div className={styles.CardHolder}>{cardHolderName}</div>
          <div className={styles.CardNumberText}>{cardNumberText}</div>
          <div className={styles.ExpirationDate}>{expirationDate}</div>
        </div>
      </div>
      <IconTextButton
        id={'delete-button'}
        type={ButtonType.Ghost}
        buttonSize={'Small'}
        label={t`Remove card`}
        onClick={() => onDeleteCardClick(referenceId)}
      >
        <BinIcon />
      </IconTextButton>
    </div>
  )
}
