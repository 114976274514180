import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import useInfiniteScroll from '../../../../hooks/InfiniteScroll'
import classNames from 'classnames'

import { Comment, MediaObject } from '../../../../interfaces'
import { CommentBubble } from './CommentBubble/CommentBubble'

import { CommentsInput } from './CommentsInput/CommentsInput'
import { getComments } from 'api/viewer/Comments/getComments'
import { deleteComment } from 'api/viewer/Comments/deleteComment'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import styles from './Comments.module.scss'
import { ChatLoader } from '../Chat/ChatLoader/ChatLoader'

export const Comments: FC = () => {
  const stream = useSelector(streamSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const [isLoading, setIsLoading] = useState(false)
  const mediaObject: MediaObject = stream.data.mediaObject
  const infiniteScrollRef = useRef(null)
  const isEndReached = useInfiniteScroll(infiniteScrollRef)

  const [comments, setComments] = useState<Comment[][]>([[]])
  const [page, setPage] = useState(1)
  const per_page = 10

  const fetchComments = useCallback(async () => {
    setIsLoading(true)
    const result = await getComments(mediaObject.id, {
      page: page,
      per_page: per_page
    })
    setIsLoading(false)
    comments[page - 1] = result
    setComments([...comments])
  }, [page, mediaObject.id])

  const addPostedComment = useCallback(
    (comment: Comment) => {
      comments[0] = [comment, ...comments[0]]
      setComments([...comments])
    },
    [page]
  )

  useEffect(() => {
    if (mediaObject.id) {
      fetchComments()
    }
  }, [mediaObject.id, fetchComments])

  useEffect(() => {
    if (
      isEndReached &&
      !(comments[page - 1] && comments[page - 1].length === 0)
    ) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isEndReached, comments])

  const deleteCommentWithId = (messageId: string) => {
    deleteComment({ messageId })
    setComments((preComments) =>
      preComments.map((page: Comment[]) =>
        page.filter((comment: Comment) => comment.id !== messageId)
      )
    )
  }
  return mediaObject ? (
    <div
      className={classNames(styles.Comments, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      <div className={styles.CommentsInput}>
        <CommentsInput
          mediaObjectId={mediaObject.id}
          onPostedComment={(comment: any) => addPostedComment(comment)}
          isViewingStream={isViewingStream}
        />
      </div>
      <div
        className={styles.CommentsContainer}
        data-testid="comments-container"
      >
        {isLoading && page < 2 && (
          <div className={styles.LoaderContainer}>
            <ChatLoader />
          </div>
        )}
        {comments &&
          comments.flat().length > 0 &&
          comments.flat().map((comment: Comment) => (
            <div className={styles.Comments} key={comment.id}>
              <CommentBubble
                ident={comment.id}
                userName={comment.user.name}
                userImgPath={comment.user.thumbnail}
                text={comment.content}
                isAdmin={comment.user.is_admin}
                isSuperAdmin={comment.user.is_super_admin}
                publishedTime={comment.created_at}
                onDelete={(id) => deleteCommentWithId(id)}
              />
            </div>
          ))}
        <div ref={infiniteScrollRef} />
      </div>
    </div>
  ) : null
}
