import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const putVoucherCode = async ({
  slug,
  voucherToken,
  mediaObjectIdent
}: {
  slug: string
  voucherToken: string
  mediaObjectIdent: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'payments/use_token',
      method: ApiEndpoint.METHODS.PUT,
      params: {
        token: voucherToken,
        company: slug,
        media_object: mediaObjectIdent
      },
      authenticatable: true
    })
    const responseData = await resultResponse
    if (responseData.status === 200) {
      if (responseData.data.status === 'token_not_found') {
        return {
          responseData,
          valid: false,
          message: t`Please use a valid code.`
        }
      }
      if (responseData.data.status === 'success') {
        return { responseData, valid: true, message: 'success' }
      }
    }
    return { responseData, valid: false, message: t`something went wrong` }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
