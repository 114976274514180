import React, { FC } from 'react'
import classNames from 'classnames'

import Button from '../../buttons/FilterItemButton'
import styles from './index.module.scss'

interface FilterItemButton {
  id: string
  text: string
  filterClicked: (id: string) => void
}

interface Props {
  size: 'small' | 'medium' | 'large'
  filterItemButtonList: FilterItemButton[]
  selectedValue: string
}

const FilterRow: FC<Props> = ({
  size = 'medium',
  filterItemButtonList,
  selectedValue
}) => {
  const sizeStyle = `FilterRow--${size}`
  const filterItemButtonComponents = filterItemButtonList.map(
    (button, index) => (
      <Button
        key={button.id}
        {...{
          tabIndex: index,
          size: size,
          text: button.text,
          id: button.id,
          onClick: button.filterClicked,
          isSelected: button.id === selectedValue
        }}
      />
    )
  )

  return (
    <div
      data-testid="search-filter-row"
      className={classNames(styles.FilterRow, styles[sizeStyle])}
    >
      {filterItemButtonComponents}
    </div>
  )
}
export default FilterRow
