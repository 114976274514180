/* eslint-disable no-underscore-dangle */
import { configureStore } from '@reduxjs/toolkit'
import {
  login,
  oauthLogin,
  logout,
  register,
  sendEmail
} from './authentication/authentication.middleware'
import { updateUser } from './user/user.middleware'
import { storeReducer } from './storeReducer'
import { rolesSubscriber } from './roles/roles.subscriber'
import { permissionsSubscriber } from './permissions/permissions.subscriber'
import { combineSubscribers } from './combineSubscribers'

const store = configureStore({
  // @ts-ignore
  reducer: storeReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat([login, oauthLogin, logout, register, sendEmail, updateUser])
})

combineSubscribers(store, [rolesSubscriber, permissionsSubscriber])

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

if (window.Cypress) {
  window.__store__ = store
}

export default store
