import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { ImageLink } from '../../features/customContent/NewContentModal/ImageLinkForm/ImageLinkForm'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface ChannelImageLink extends ImageLink {
  channelSlug: string
}
export const postImageLink = async (params: ChannelImageLink): Promise<any> => {
  try {
    const formData = new FormData()
    formData.append('company', params.channelSlug)
    formData.append('publish', 'true')
    formData.append('title', 'Image Banner')
    formData.append('content_type', 'application/delta+json')
    if (params.imageFile) {
      formData.append('image', params.imageFile)
    }
    formData.append('image_link', params.imageLinkUrl)
    formData.append('type', 'image_banner')
    formData.append(
      'image_link_target',
      params.openInNewTab ? LinkTarget.NewWindow : LinkTarget.CurrentWindow
    )
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'banners/frontpage',
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
