import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './SignOutButton.module.scss'
import { SignOutIcon } from 'assets/icons/SignOutIcon'

interface Props {
  onClick: () => void
}

export const SignOutButton: FC<Props> = ({ onClick }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(authenticationActions.logout())
    onClick()
  }
  return (
    <div
      role="button"
      data-testid="sign-out-button"
      tabIndex={0}
      className={styles.Container}
      onKeyDown={handleClick}
      onClick={handleClick}
    >
      <SignOutIcon />
      <Trans>Sign out</Trans>
    </div>
  )
}
