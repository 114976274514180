import React from 'react'

const CloseIcon = () => (
  <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1106_80806)">
      <g clipPath="url(#clip1_1106_80806)">
        <path
          d="M1.87891 20.1431L20.6844 1.83203"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6844 20.1431L1.87891 1.83203"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1106_80806">
        <rect width="22.5667" height="21.9733" fill="white" />
      </clipPath>
      <clipPath id="clip1_1106_80806">
        <rect width="22.5667" height="21.9733" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default CloseIcon
