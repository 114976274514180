/* eslint-disable @typescript-eslint/indent */
import { AssociationClubEventDistrict } from '../../interfaces/AssociationClubEventDistrict.interface'
import { ClubTeams } from '../../interfaces/ClubTeams'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getTeamsForClubs = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/channels/teams`,
      params: {
        page: page,
        per_page: perPageCount
      }
    })

    const responseData = await resultResponse
    return {
      data: mapClubTeams(responseData) as AssociationClubEventDistrict[],
      totalCount: responseData.totalCount
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapClubTeams = (responseData: ApiResponse) => {
  return (
    responseData?.data?.flatMap((teamsData: ClubTeams) => teamsData.teams) || []
  )
}
