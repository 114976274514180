/* eslint-disable no-unused-expressions */
import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { isIOS, isMobile } from 'react-device-detect'

import { VideoPlayerContext } from '../../../providers/VideoPlayerProvider/VideoPlayerProvider'
import { useFeatureFlags } from 'featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from 'featureFlags/FeatureFlagsAvailable'
import { usePlayerControls } from './usePlayerControls'
import { OverlayControls } from './OverlayControls'
import { ControlBar } from './ControlBar'

import './VideoPlayerControls.scss'

interface VideoPlayerControlProps {
  isLive?: boolean
  hasSubtitles?: boolean
}

export const PlayerControls: FC<VideoPlayerControlProps> = ({
  isLive = false,
  hasSubtitles = false
}) => {
  const {
    videoElement,
    adContainer,
    hls,
    adsDone,
    videoAds,
    firstInteractionDone,
    chromecast,
    playerState,
    togglePlay,
    getCurrentTime,
    getDuration,
    seek,
    play,
    pause
  } = useContext(VideoPlayerContext)
  const { playing } = playerState
  const [muted, setMuted] = useState(videoElement?.volume <= 0)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [progressBuffer, setProgressBuffer] = useState<number | undefined>(
    undefined
  )
  const videoWrapper = document.getElementById('VideoPlayer')
  const controlsWrapper = document.getElementById('VideoControls')

  const { getFlag } = useFeatureFlags()
  const debug = getFlag(FeatureFlagsAvailable.VideoDebug)

  const log = (message: string, ...params: any) => {
    if (debug) {
      console.log('> controls: ' + message, ...params)
    }
  }

  const playButton = useRef<any>()
  const bigPlayButton = useRef<any>()

  useEffect(() => {
    log('visibility changed (isVisible)', isVisible)
  }, [isVisible])

  useEffect(() => {}, [playing])

  useEffect(() => {
    if (!adsDone) {
      if (playing) {
        setIsVisible(true)
      }
    }
  }, [playing, adsDone])

  const togglePlayPause = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      log('togglePlay')
      togglePlay()
    },
    [togglePlay]
  )

  usePlayerControls({
    adsDone,
    log,
    muted,
    setMuted,
    setIsVisible,
    playing,
    chromecastConnected: chromecast.connected,
    firstInteractionDone,
    videoElement,
    playButton: playButton.current,
    togglePlayPause,
    videoWrapper,
    isMobile,
    controlsWrapper,
    adContainer,
    bigPlayButton: bigPlayButton.current,
    adStatusCode: videoAds.statusCode,
    progressBuffer
  })

  useEffect(() => {
    const onEnded = () => {}
    if (videoElement) {
      videoElement.addEventListener('ended', onEnded)
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', onEnded)
      }
    }
  }, [videoElement])

  const setProgress = (newProgress: number) => {
    if (Math.abs(getCurrentTime() - newProgress) > 0.5) {
      seek(newProgress || 0)
    }
  }

  const addToProgressBuffer = useCallback(
    (addedValue: number) => {
      if (playing) {
        pause()
      }
      setProgressBuffer((progressBuffer || 0) + addedValue)
    },
    [playing, progressBuffer]
  )

  useEffect(() => {
    let timeout: any
    if (progressBuffer) {
      timeout = setTimeout(() => {
        const newTime = Math.max(
          0,
          Math.min(getDuration(), getCurrentTime() + progressBuffer)
        )
        setProgress(newTime)
        setProgressBuffer(undefined)
        play()
      }, 1000)
    }
    return () => {
      if (timeout) {
        log('controls timeout cleared')
        clearTimeout(timeout)
      }
    }
  }, [progressBuffer])

  const handleKeydown = (event: any) => {
    const hasRelevantFocus =
      videoWrapper?.contains(document.activeElement) ||
      document?.activeElement?.tagName === 'BODY'

    if (!hasRelevantFocus) return

    const code = event?.code

    if (code === 'ArrowLeft') {
      event.preventDefault()
      setIsVisible(true)
      addToProgressBuffer(-5)
    } else if (code === 'ArrowRight') {
      event.preventDefault()
      setIsVisible(true)
      addToProgressBuffer(5)
    } else if (code === 'Space') {
      event.preventDefault()
      setIsVisible(true)
      if (firstInteractionDone) {
        togglePlay()
      } else {
        videoElement.play()
      }
    }
  }

  useEffect(() => {
    if (videoElement) {
      window.addEventListener('keydown', handleKeydown)
    }
    return () => {
      if (videoElement) {
        window.removeEventListener('keydown', handleKeydown)
      }
    }
  }, [videoElement, playing, firstInteractionDone, progressBuffer])

  const onAdContainerClick = () => {
    if (document.activeElement?.tagName! === 'IFRAME') {
      window.focus()
    }
  }

  useEffect(() => {
    if (adContainer) {
      adContainer.addEventListener('click', onAdContainerClick)
    }
    return () => {
      if (adContainer) {
        adContainer.removeEventListener('click', onAdContainerClick)
      }
    }
  }, [adContainer])

  return (
    <div
      className={adsDone ? '' : 'ads'}
      id="VideoControls"
      style={{ display: isVisible && firstInteractionDone ? 'block' : 'none' }}
    >
      <>
        {adsDone && (
          <OverlayControls
            adsDone={adsDone}
            addToProgressBuffer={addToProgressBuffer}
            progressBuffer={progressBuffer}
            isLive={isLive}
            isPlaying={playing}
            bigPlayButton={bigPlayButton}
            getCurrentTime={getCurrentTime}
            getDuration={getDuration}
          />
        )}
        <ControlBar
          isLive={isLive}
          isPlaying={playing}
          playButton={playButton}
          isMuted={muted}
          isIOS={isIOS}
          adsDone={adsDone}
          videoElement={videoElement}
          hls={hls}
          hasSubtitles={hasSubtitles}
        />
      </>
    </div>
  )
}

PlayerControls.defaultProps = {
  isLive: false,
  hasSubtitles: false
}

export const VideoPlayerControls = memo(PlayerControls)
