import { getAllAccess } from './getAllAccess'
import { getRecurringSubscription } from './getSubscriptions'

export const getSubscriptionsAndAllAccess = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page?: number
  perPageCount?: number
}) => {
  try {
    const allaccessData = await getAllAccess({ slug, page, perPageCount })
    const recurringSubscription = await getRecurringSubscription({
      slug,
      page,
      perPageCount
    })

    return {
      data: [...recurringSubscription.data, ...allaccessData.data]
    }
  } catch (e) {
    console.log('error', e)
    // @ts-ignore
    throw new Error(e.message)
  }
}
