import HeroImage from './Images/HeroBackgroundImage.png'

export const HeroSectionMockDataEng = {
  id: 'HeroSectionEasy',
  sectionTitle: 'It should be easy to broadcast sports live.',
  text: 'Live broadcast your sport and save memories for life. Use graphics that show time, period and goals. Create highlights and charge for your broadcasts. Simple and free.',
  buttonLabel: 'Start broadcasting live',
  imagePath: HeroImage,
  link: '/broadcast'
}
