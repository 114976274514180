import React, { FC, useCallback, useMemo } from 'react'
import { t, Trans } from '@lingui/macro'
import { DropdownList } from 'components/Forms/DropdownListField/DropdownList'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { AccessRestrictionType } from 'interfaces'
import { Price } from 'interfaces/Price.interface'

import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { AccessRestrictionText } from './AccessRestrictionText'
import { PriceFields } from './PriceFields'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { QuickEdit, patchQuickEdit } from 'api/stream/patchQuickEdit'

import styles from './StreamAccessForm.module.scss'
import { useDataApi } from 'api/useDataApi'
import { getMediaObjectAccess } from 'api/stream/getMediaObjectAccess'
import { Loader } from 'components/Loader/Loader'
import { getListOfAccessRestrictionsAvailable } from './accessRestrictionList'
import { streamAccessValidationSchema } from './streamAccessValidationSchema'
import { VideoPlayerData } from 'features/viewerPage/viewerPage.interfaces'

interface StreamAccessValues {
  accessType: AccessRestrictionType
  price?: Price
}
interface Props {
  onSubmitSuccess: () => void
  onClose: () => void
}

export const StreamAccessForm: FC<Props> = ({ onSubmitSuccess, onClose }) => {
  const stream = useSelector(streamSelector)
  const mediaPlayerData: VideoPlayerData = stream.data

  const streamIsGame = mediaPlayerData.mediaObject.game !== null
  const channelSlug = streamIsGame
    ? mediaPlayerData.mediaObject.game.display_company.subdomain
    : mediaPlayerData.mediaObject.company.subdomain

  const gameIdent = mediaPlayerData?.mediaObject?.game?.id
  const mediaObjectSlug = mediaPlayerData.mediaObject.slug
  const { data, isLoading, isError } = useDataApi({
    apiCall: useCallback(getMediaObjectAccess, []),
    parameters: useMemo(
      () => ({
        channelSlug: channelSlug,
        gameIdent: gameIdent,
        mediaObjectSlug: mediaObjectSlug
      }),
      [gameIdent, mediaObjectSlug]
    )
  })

  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    return (
      <div>
        <Trans>Something went wrong. Try again</Trans>
      </div>
    )
  }
  const currentAcccessType = data.data?.current?.accessType
  const price = data.data?.current?.price
    ? data.data?.current?.price
    : data.data?.default?.price

  const initialValues: StreamAccessValues = {
    accessType: currentAcccessType,
    price: price
  }
  const accessRestricionsAvailable = getListOfAccessRestrictionsAvailable(
    data?.data?.availableAccessTypes
  )

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)
    const params: QuickEdit = {
      ident: mediaPlayerData.mediaObject.id,
      accessRestriction: values.accessType,
      price: values.price
    }

    const response = await patchQuickEdit({
      ...params
    })
    if (!response.isSuccess) {
      console.log('error')
    } else {
      onSubmitSuccess()
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={true}
      validationSchema={streamAccessValidationSchema(
        data?.data?.minimumPrice.price
      )}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        const showPriceFields = [
          AccessRestrictionType.PaymentRequired,
          AccessRestrictionType.PaymentAndSubscriptionRequired
        ].includes(props.values.accessType)
        const price = props.values.price

        return (
          <Form className={styles.Form}>
            <div className={styles.AccessDropdown}>
              <Field
                component={DropdownList}
                dropdownListData={accessRestricionsAvailable}
                id="accessType"
                name="accessType"
                label={t`Access restrictions`}
                value={props.values.accessType}
                placeholder={t`Write the title of the stream here`}
                onChange={(id: string) => props.setFieldValue('accessType', id)}
                isValid={!props.errors.accessType}
                type="dark"
                validationMessage={props.errors.accessType}
                isRequired={false}
              />
            </div>
            {showPriceFields && price && (
              <PriceFields prices={price} formik={props} />
            )}

            <AccessRestrictionText
              accessRestrictionType={props.values.accessType}
            />
            <div className={styles.Buttons}>
              <FormActionButtons
                formik={props}
                cancelButtonLabel={t`Cancel`}
                submitButtonLabel={t`Save`}
                onCancel={onClose}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
