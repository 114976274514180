import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { mapCustomPageResponse } from './mapCustomPageResponse'

interface CustomPageRequest {
  channelSlug: string
  pageSlug: string
}

export interface CustomPage {
  slug: string
  title: string
  content: string
  preamble: string | null
  imageUrl: string | null
}

export const getCustomPage = async ({
  channelSlug,
  pageSlug
}: CustomPageRequest) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'page',
      params: {
        company: channelSlug,
        slug: pageSlug
      }
    })
    const responseData = await resultResponse
    const map: CustomPage = mapCustomPageResponse(responseData.data)
    return map
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
