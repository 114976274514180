/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { RootState } from 'redux-store/store'
import { loadStateFromStorage } from '../../helpers/localStorageHelper'
import { UserCompany } from '../../interfaces'
import ROLES from './roles.constants'
import { RolesState, UserRole } from './roles.interface'

export const hasAnyRole = (
  currentRoles: UserRole[] | undefined,
  roles: UserRole[]
) => roles.some((role) => currentRoles?.includes(role))

export const getIsSuperAdmin = (roles: UserRole[]) =>
  hasAnyRole(roles, ['supertech', 'supersale', 'superadmin'])

export const getIsTechAdmin = (roles: UserRole[]) =>
  hasAnyRole(roles, ['supertech'])

export const getIsAdmin = (roles: UserRole[]) =>
  hasAnyRole(roles, ['sales', 'csm', 'support', 'externalsupport'])

export const getIsViewer = (roles: UserRole[]) =>
  hasAnyRole(roles, ['superviewer', 'mediaviewer'])

export const getIsDownloader = (roles: UserRole[]) =>
  hasAnyRole(roles, ['superviewer', 'mediadownload'])

export const getIsChannelAdmin = (
  companies: UserCompany[],
  subdomain: string | undefined
) =>
  companies?.some(
    (company: any) => subdomain && company.subdomain === subdomain
  )

export const getIsLeagueAdmin = (
  companies: UserCompany[],
  leagueId: number | undefined
) =>
  companies
    ?.map((company) => company?.leagues?.map((league: any) => league.id))
    .flat()
    .some((id: any) => id === leagueId)

export const createInitialState = () => {
  const user = loadStateFromStorage('user')
  const roles = user?.roles
  const isSuperadmin = getIsSuperAdmin(roles)
  const isTechAdmin = getIsTechAdmin(roles)
  const isAdmin = getIsAdmin(roles)
  const isViewer = getIsViewer(roles)
  const isDownloader = getIsDownloader(roles)

  return {
    isLoggedIn: !!user,
    isSuperadmin,
    isTechAdmin,
    isAdmin,
    isViewer,
    isDownloader,
    isChannelAdmin: false,
    isLeagueAdmin: false,
    isViewingAsAdmin: false
  }
}

const initialState: RolesState = {
  ...createInitialState()
}

function rolesReducer(state: RolesState = initialState, action: AnyAction) {
  switch (action.type) {
    case ROLES.UPDATE_ROLES:
      const { roles, companies, subdomain, leagueId, loggedIn } = action

      const isSuperadmin = getIsSuperAdmin(roles)
      const isTechAdmin = getIsTechAdmin(roles)
      const isAdmin = getIsAdmin(roles)
      const isViewer = getIsViewer(roles)
      const isDownloader = getIsDownloader(roles)
      const isChannelAdmin = getIsChannelAdmin(companies, subdomain)
      const isLeagueAdmin = getIsLeagueAdmin(companies, leagueId)
      const isLoggedIn = !!loggedIn

      return {
        ...state,
        isSuperadmin,
        isTechAdmin,
        isAdmin,
        isViewer,
        isDownloader,
        isChannelAdmin,
        isLeagueAdmin,
        isLoggedIn
      }

    case ROLES.SET_VIEWING_AS_ADMIN:
      return {
        ...state,
        isViewingAsAdmin: true
      }
    case ROLES.SET_VIEWING_AS_USER:
      return {
        ...state,
        isViewingAsAdmin: false
      }
    default:
      return state
  }
}

export default rolesReducer

export const rolesSelector = (state: RootState) => state.roles
