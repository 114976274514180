import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { AddGamesForm } from './AddGamesForm'

import { FormModal } from '../FormModal/FormModal'

interface Props {
  channelSlug: string
  groupName: string
  competitionName: string
  competitionId: number
  onAddedGames: () => void
  onClose: () => void
  defaultOpen: boolean
}

export const AddGamesModal: FC<Props> = ({
  channelSlug,
  groupName,
  competitionId,
  competitionName,
  defaultOpen,
  onAddedGames,
  onClose
}) => {
  const handleSuccess = () => {
    onAddedGames()
  }

  const handleClose = () => {
    onClose()
  }
  return (
    <FormModal
      modalHeader={t`Add games to channel`}
      preHeader={groupName}
      header={competitionName}
      modalInformation={t`Select your team from the list below, to be able to add your games
          from this competition`}
      defaultOpen={defaultOpen}
      onClose={handleClose}
    >
      <AddGamesForm
        onSubmitSuccess={handleSuccess}
        onCloseForm={handleClose}
        channelSlug={channelSlug}
        competitionId={competitionId}
      />
    </FormModal>
  )
}
