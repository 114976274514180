import React from 'react'
import { ProsListItem } from './ProsItem/ProsItem'
import TrophyIcon from 'components/lists/ChannelListItem/Icons/TrophyIcon'
import { StartIcon } from 'assets/icons/StarIcon'
import { ShieldsIcon } from 'assets/icons/ShieldsIcon'
import { LockIcon } from 'assets/icons/LockIcon'
import { t } from '@lingui/macro'

export const accountProsList: ProsListItem[] = [
  {
    text: t`One account, infinite access`,
    icon: <StartIcon />,
    id: '1'
  },
  {
    text: t`Supporting sports at every level`,
    icon: <TrophyIcon />,
    id: '2'
  },
  {
    text: t`Trusted by the Global Sports Community`,
    icon: <ShieldsIcon />,
    id: '3'
  },
  {
    text: t`Your Privacy, Our Priority`,
    icon: <LockIcon />,
    id: '4'
  }
]
