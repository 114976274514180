import React, { FC } from 'react'
import styles from './DefaultTeamBadge.module.scss'
import { ShieldIcon } from './ShieldIcon'
import classNames from 'classnames'
import { createTeamAbbreviation } from 'api/multistageTournament/createTeamAbbreviation'
export enum BadgeSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}
interface Props {
  teamName: string
  size: BadgeSize
  abbreviation?: string
}

export const DefaultTeamBadge: FC<Props> = ({
  teamName,
  size,
  abbreviation
}) => {
  const firstLetter = createTeamAbbreviation(teamName)
  const abbreviationToDisplay = abbreviation && abbreviation.substring(0, 3)
  return (
    <div
      className={classNames(styles.Shield, styles[size])}
      data-testid="shield-container"
    >
      <div className={styles.Text}>
        {abbreviation ? abbreviationToDisplay : firstLetter}
      </div>
      <ShieldIcon />
    </div>
  )
}
