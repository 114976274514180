// import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface ContactResponseApi {
  first_name: string
  last_name: string
  phone_number: string
  email: string
  channel_slug: string
}

export interface ContactDetails {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
}

export const getInvoiceContact = async (): Promise<ContactDetails> => {
  try {
    // const resultResponse: ApiResponse = await ApiEndpoint.call({
    //   path: `session/companies/${slug}/invoice_contact`
    // })
    const resultResponse = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            channel_slug: 'testChannel',
            first_name: 'test firstname',
            last_name: 'test lastName',
            phone_number: 'testnumber',
            email: 'test@email.address.com'
          }
        })
      })
    })
    const responseData = await resultResponse
    const mapped: ContactDetails =
      // @ts-ignore
      mapInvoiceContact(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapInvoiceContact = (contact: ContactResponseApi) => {
  try {
    return {
      channelSlug: contact.channel_slug,
      firstName: contact.first_name,
      lastName: contact.last_name,
      phoneNumber: contact.phone_number,
      email: contact.email
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
