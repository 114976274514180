import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './TeamScore.module.scss'

interface Props {
  score: number
  result: 'Win' | 'Loss' | 'Equal' | 'Default'
  hasBeenPlayed: boolean
  isLive: boolean
}
export const TeamScore: FC<Props> = ({
  score,
  hasBeenPlayed,
  isLive,
  result
}) => (
  <div
    data-testid="team-score"
    className={classNames(styles.TeamScoreContainer, styles[result])}
  >
    {(hasBeenPlayed || isLive) && <div className={styles.Score}>{score}</div>}
  </div>
)
