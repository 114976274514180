import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './DropdownItem.module.scss'

interface Props {
  label: string
  id: string
  selectedId: string
  onItemSelected: (itemLabel: string, itemId: string) => void
}
const DropdownItem: FC<Props> = ({ label, id, selectedId, onItemSelected }) => {
  const isSelected = () => id === selectedId
  return (
    <div
      role="button"
      className={classNames(styles.DropdownItem, {
        [styles.Selected]: isSelected()
      })}
      onClick={() => onItemSelected(label, id)}
      onKeyPress={() => onItemSelected(label, id)}
      tabIndex={0}
    >
      {label}
    </div>
  )
}
export default DropdownItem
