import { VideoPlayerContext } from 'providers/VideoPlayerProvider/VideoPlayerProvider'
import { useContext, useEffect, useState } from 'react'

export const useCurrentTime = () => {
  const { getCurrentTime, chromecast } = useContext(VideoPlayerContext)
  const [time, setTime] = useState(getCurrentTime())
  useEffect(() => {
    const update = () => {
      setTime(getCurrentTime())
    }
    const interval = setInterval(update, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [chromecast.connected])
  return time
}
