import { t } from '@lingui/macro'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

export const getHeaderTitle = (
  channelType: ChannelTypes,
  useSelectedTeamFromClub: boolean,
  teamName: string
) => {
  switch (channelType) {
    case ChannelTypes.league:
      return t`Select the competition you want to add to this channel`
    case ChannelTypes.club:
    case ChannelTypes.team:
      if (!useSelectedTeamFromClub) {
        return t`Add competitions your team is participating in`
      }

      return t`Add competitions ${teamName} is participating in`
    default:
      return t`Add competitions your team is participating in`
  }
}
