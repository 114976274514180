import { Subscription } from '../../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const getSubscriptions = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page?: number
  perPageCount?: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'session/subscriptions/renewable_subscriptions',
      params: {
        company: slug,
        page: page || 1,
        per_page: perPageCount || 10
      }
    })
    const responseData = await resultResponse
    return {
      data: responseData.data,
      totalCount: responseData.totalCount
    }
  } catch (e) {
    console.log('error', e)
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const getRecurringSubscription = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page?: number
  perPageCount?: number
}) => {
  const list = await getSubscriptions({ slug, page, perPageCount })
  return {
    data: list.data.filter((sub: Subscription) => !sub.all_access),
    totalCount: list.totalCount
  }
}
