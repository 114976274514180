const SOLIDSPORT_API_PATHS = {
  TIMELINE_OBJECTS: {
    COLLECTIONS: {
      GLANCES: 'timeline_objects/collections/glances',
      ACTIVE_STREAMS: 'timeline_objects/collections/active_streams',
      FRONTPAGE: 'timeline_objects/collections/frontpage',
      SIDEBAR: 'timeline_objects/collections/sidebar',
      VIDEOS: 'timeline_objects/collections/videos',
      CALENDAR_LIVESTREAMS: 'timeline_objects/collections/calendar_livestreams',
      LIVESTREAMS: 'timeline_objects/collections/livestreams',
      GAMES: 'timeline_objects/collections/games',
      PAST_GAMES: 'timeline_objects/collections/past_games',
      HIGHLIGHTS: 'timeline_objects/collections/highlights',
      REPLAYS: 'timeline_objects/collections/replays',
      ARTICLES: 'timeline_objects/collections/articles',
      FEATURED: 'timeline_objects/collections/featured',
      POPULAR: 'timeline_objects/popular',
      PROMOTED_CATEGORIES: 'timeline_objects/collections/promoted_categories'
    },
    FROM_CATEGORY: 'timeline_objects/from_category',
    FROM_SPORT: 'timeline_objects/from_sport'
  },
  USERS: {
    FOLLOW: {
      COMPANY: 'users/follow/company'
    },
    UNFOLLOW: {
      COMPANY: 'users/unfollow/company'
    }
  },
  SESSION: {
    AUTH: {
      FACEBOOK: 'session/auth/facebook'
    }
  }
}

export default SOLIDSPORT_API_PATHS
