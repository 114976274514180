import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface BroadcastInformationApiResponse {
  stream_url: string
  stream_key: string[]
}

export interface BroadcastInformation {
  streamURL: string
  streamKey: string
}
export const getBroadcastInformation = async ({
  mediaObjectIdent,
  channelSlug
}: {
  mediaObjectIdent: string
  channelSlug: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `media_object/${mediaObjectIdent}/broadcast_information`,
      method: ApiEndpoint.METHODS.GET,
      params: {
        company: channelSlug
      },
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped = mapResponse(responseData.data)
    return { data: mapped }
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapResponse = (apiResponse: BroadcastInformationApiResponse) => ({
  streamURL: apiResponse.stream_url,
  streamKey: apiResponse.stream_key
})
