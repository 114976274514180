import React, { FC } from 'react'
import styles from './DateInputField.module.scss'
import classNames from 'classnames'
import { FormValidationError } from '../FormValidationError/FormValidationError'

interface Props {
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  dateValue: string
  onChange: () => void
  isRequired: boolean
  readonly?: boolean
  customStyle?: string
}

export const DateInputField: FC<Props> = ({
  id,
  label,
  placeholder,
  isValid,
  validationMessage,
  dateValue,
  isRequired,
  onChange,
  readonly,
  customStyle
}) => {
  return (
    <div
      data-testid={id}
      className={classNames(styles.DateFieldContainer, {
        [customStyle || '']: customStyle
      })}
    >
      <label htmlFor={id}>
        {isRequired && '* '}
        {label}
      </label>
      <input
        type="date"
        className={classNames(styles.DateField, {
          [styles.Error]: !isValid
        })}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        value={dateValue}
        disabled={readonly}
      />
      {!isValid && <FormValidationError errorMessage={validationMessage} />}
    </div>
  )
}
