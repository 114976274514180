import React from 'react'
import { Trans } from '@lingui/macro'
import styles from '../ThumbnailLabel.module.scss'

const LiveReportingLabel = () => {
  return (
    <div className={styles.Live} data-testid="live-reporting-label">
      <div className={styles.Dot} />
      <Trans>Live reporting</Trans>
    </div>
  )
}
export default LiveReportingLabel
