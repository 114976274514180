import { ChannelTypes } from '../../../pages/ChannelPage/channelType'

// eslint-disable-next-line no-shadow
export enum SearchResultType {
  Association = 'Association',
  Article = 'Article',
  Channel = 'Channel',
  Event = 'Event',
  Video = 'Video'
}

interface SearchResultBase {
  id: string
  title: string
  imageUrl: string | null | undefined
  type: SearchResultType
  targetUrl: string
}

export interface Association extends SearchResultBase {
  sports: string[]
  event: string
  description: string
}

export interface Article extends SearchResultBase {
  published: number
  preamble: string
  company?: string
  sport?: string
  country?: string
  tag?: string
}

interface Company extends SearchResultBase {
  sport: string
  games: number
  followers: number
  supporters: number
  comingGameUrl?: string
  partnerType: ChannelTypes
}

export interface Event extends Company {
  teams: string[]
  liveGameUrl: string
}

export interface Channel extends Company {
  location: string
  description: string
}

interface Team {
  badgeUrl: string
  country: string
  id: number
  name: string
  channelName: string
  slug: string
}
export interface Video extends SearchResultBase {
  isReplay: boolean
  duration: number
  channelUrl: string
  channelName: string
  homeTeam: Team
  awayTeam: Team
  views: number
  viewers: number
  typeOfGame: string
  liveStartTime: number
  liveEndTime: number
  liveGameUrl: string
  publishedAt: number
  country: string
  channelThumbnail: string
}

export type SearchResult = Association | Article | Event | Channel | Video
