import React, { FC, useEffect, useState } from 'react'
import CrossIcon from '../../../../components/Searchfield/CrossIcon'
import styles from './StatusMessage.module.scss'

import { StatusMessageIcon } from './StatusMessageIcon'
export enum StatusMessageType {
  Error = 'error',
  Success = 'success',
  Information = 'information'
}
interface Props {
  message: string
  onClose: () => void
  messageType: StatusMessageType
}

export const StatusMessage: FC<Props> = ({ message, onClose, messageType }) => {
  const [seconds, setSeconds] = useState(90)
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      onClose()
    }
  })

  return (
    <div className={styles.StatusMessageContainer} data-testid="status-message">
      <StatusMessageIcon messageType={messageType} />
      {message}
      <div
        role="button"
        className={styles.CrossIcon}
        tabIndex={0}
        onClick={() => onClose()}
        onKeyDown={() => onClose()}
      >
        <CrossIcon />
      </div>
    </div>
  )
}
