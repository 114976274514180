import React, { FC, useState } from 'react'
import { MobileNavigationMenu } from 'components/MobileNavigationMenu/MobileNavigationMenu'
import { MenuLinks } from './MenuLinks'
import useCurrentUser from 'hooks/CurrentUser'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { DesktopMenu } from './DesktopMenu'

import { LanguageModal } from 'components/Modals/LanguageModal/LanguageModal'
import { t } from '@lingui/macro'
import { LinkType } from 'components/page/menuLink/MenuLink'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { ShieldsIcon } from 'assets/icons/ShieldsIcon'
import { onHelpClick } from 'features/viewerPage/helpFunctions/onHelpClick'

interface Props {
  isOpen: boolean
  onClose: () => void
}
export const HamburgerMenu: FC<Props> = ({ isOpen, onClose }) => {
  const currentUser = useCurrentUser()
  const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`

  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint < Breakpoint.md
  const [showLanguageModal, setShowLanguageModal] = useState(false)
  const isChannelAdmin =
    currentUser?.companies && currentUser.companies.length > 0
  const links = isChannelAdmin
    ? [
        ...MenuLinks,
        {
          translationText: t`My channels`,
          link: `${baseUrl}/user/profile/account/channels`,
          type: LinkType.AnchorTag,
          linkTarget: LinkTarget.NewWindow,
          icon: <ShieldsIcon />
        }
      ]
    : MenuLinks

  return showLanguageModal ? (
    <LanguageModal onClose={onClose} isOpen={showLanguageModal} />
  ) : isMobile ? (
    <MobileNavigationMenu
      isOpen={isOpen}
      menuLinks={links}
      currentUser={currentUser}
      onCloseModal={onClose}
      onHelpClick={onHelpClick}
      showLanguageSelector
      onLanguageSelectorClick={() => setShowLanguageModal(true)}
    />
  ) : (
    <DesktopMenu
      onClose={onClose}
      menuLinks={links}
      currentUser={currentUser}
      onHelpClick={onHelpClick}
      showLanguageSelector
      onLanguageSelectorClick={() => setShowLanguageModal(true)}
    />
  )
}
