import React, { FC, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { getHeroImageCallToAction } from 'api/customContent/getHeroImageCallToAction'

import channelsActions from '../../../redux-store/channel/channels.actions'
import GreyDefaultHero from './Images/grey-background.png'
import LightDefaultHero from './Images/light-background.png'
import { ImageUploadOverlayButton } from './ImageUpload/ImageUpload'
import { ResizeHeroImageUrl } from './resizeHeroImageUrl'
import { useCurrentBreakpoint } from '../../../hooks/CurrentBreakpoint'
import { CallToActionOverlay } from 'features/customContent/HeroImageCallToAction/CallToActionOverlay/CallToActionOverlay'

import styles from './heroImage.module.scss'

interface Props {
  imageUrl: string | undefined
  canChangeImage: boolean
  channelSlug: string
  isRootPage: boolean
}

const HeroImage: FC<Props> = ({
  imageUrl,
  canChangeImage,
  channelSlug,
  isRootPage
}) => {
  const dispatch = useDispatch()
  const breakpoint = useCurrentBreakpoint()
  const handleImageUploaded = (imagePath: string) => {
    dispatch(channelsActions.updateCoverImage(imagePath))
  }
  const hasImage = imageUrl && imageUrl !== ''
  const defaultImage = canChangeImage ? GreyDefaultHero : LightDefaultHero
  const heroImage = hasImage ? imageUrl : defaultImage
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    setUrl(
      ResizeHeroImageUrl(heroImage, window.innerWidth, window.devicePixelRatio)
    )
  }, [breakpoint, heroImage])

  const {
    data,
    isLoading
  }: {
    data: any | undefined
    isLoading: boolean
  } = useQuery({
    queryKey: ['get-hero-image-call-to-action-for-channel'],

    queryFn: () =>
      getHeroImageCallToAction({
        channelSlug: channelSlug,
        disableCache: false
      }),

    retry: false
  })

  return (
    <div
      className={styles.HeroImageContainer}
      data-testid="hero-image-container"
    >
      {data && !isLoading && (
        <div className={styles.PromotionOverlay}>
          <CallToActionOverlay
            title={data.title}
            text={data.text}
            buttonText={data.textField}
            buttonLink={data.linkField}
            target={data.target!}
          />
        </div>
      )}
      <div
        className={classNames(styles.HeroImage, {
          [styles.RootHeroImage]: isRootPage,
          [styles.DefaultHeroImage]: !isRootPage
        })}
        style={{
          backgroundImage: `url(${url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        {canChangeImage && (
          <div className={styles.UploadIcon}>
            <ImageUploadOverlayButton
              path={`session/companies/${channelSlug}/cover`}
              onImageUploaded={handleImageUploaded}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default HeroImage
