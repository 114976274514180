enum FacebookAuthStatus {
  NOT_LOADED = 'NOT_LOADED',
  READY = 'READY',
  FACEBOOK_REDIRECTED_LOGIN = 'FACEBOOK_REDIRECTED_LOGIN',
  FACEBOOK_ME_REQUEST = 'FACEBOOK_ME_REQUEST',
  FACEBOOK_SUCCESS = 'FACEBOOK_SUCCESS',
  FACEBOOK_FAILED = 'FACEBOOK_FAILED',
  BACKEND_REQUEST = 'BACKEND_REQUEST',
  BACKEND_FAILED = 'BACKEND_FAILED'
}

export default FacebookAuthStatus
