import React, { FC, useCallback, useState } from 'react'
import { useDataApi } from 'api/useDataApi'
import { CreditCardInterface, getCreditCards } from 'api/user/getCreditCards'
import { CreditCard } from 'components/cards/CreditCard/CreditCard'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { Trans, t } from '@lingui/macro'
import { Loader } from 'components/Loader/Loader'
import { AddCreditCardButton } from 'components/buttons/AddCreditCardButton/AddCreditCardButton'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal'
import { deleteCreditCard } from 'api/user/deleteCreditCard'
import styles from './MyCards.module.scss'

export const MyCards: FC = () => {
  const [referenceId, setReferenceId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [reloadData, setReloadData] = useState(false)
  const [showDeleteCreditCardModal, setShowDeleteCreditCardModal] =
    useState(false)
  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getCreditCards, []),
    triggerCall: reloadData
  })

  const onRemoveCard = async () => {
    if (referenceId) {
      const response = await deleteCreditCard({ referenceId })
      if (!response.isSuccess) {
        setErrorMessage(
          t`Something went wrong when trying to remove the credit card, please try again.`
        )
      } else {
        setShowDeleteCreditCardModal(false)
        setReloadData(!reloadData)
      }
    }
  }
  const hasData = ![0, undefined].includes(data?.data?.length)

  return (
    <div className={styles.PaymentInformation}>
      <FormSectionTitle title={t`My cards`} />
      {!isLoading && hasData && (
        <div className={styles.CardList}>
          {data.data.map((card: CreditCardInterface) => (
            <CreditCard
              brandType={card.brandType}
              code={card.code}
              expirationYear={card.expirationYear}
              expirationMonth={card.expirationMonth}
              onDeleteCardClick={(referenceId: string) => {
                setShowDeleteCreditCardModal(true)
                setReferenceId(referenceId)
              }}
              key={card.code}
              referenceId={card.referenceId}
              cardHolderName={card.cardHolderName}
            />
          ))}
        </div>
      )}
      {!isLoading && !hasData && (
        <div>
          <Trans>You have no saved credit cards.</Trans>
        </div>
      )}
      {isLoading && <Loader />}
      <div className={styles.AddCreditCardButton}>
        <AddCreditCardButton
          onAddedCardSuccess={() => setReloadData(!reloadData)}
        />
      </div>
      {showDeleteCreditCardModal && (
        <ConfirmationModal
          modalHeader={t``}
          title={t`Are you sure you want to remove this credit card?`}
          subText={t`Once this card is removed, it cannot be used again. However, you can add a new or the same card again.`}
          defaultOpen={showDeleteCreditCardModal}
          isError={errorMessage.length > 0}
          isLoading={false}
          errorMessage={errorMessage}
          confirmButtonLabel={t`Yes, remove this credit card`}
          cancelButtonLabel={t`Cancel`}
          onClose={() => setShowDeleteCreditCardModal(false)}
          onConfirm={() => onRemoveCard()}
        />
      )}
    </div>
  )
}
