/* eslint-disable react/jsx-one-expression-per-line */

/* eslint-disable no-unused-expressions */
import Hls from 'hls.js'
import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { useVideoStatisticsData } from '../../../../hooks/VideoStatistics'
import './index.scss'

type DebugWindowProps = {
  hls: Hls
  media_object: string
}

export const DebugWindow: React.FC<DebugWindowProps> = ({
  hls,
  media_object
}) => {
  const [count, setCount] = useState(0)
  const [levelSwitching, setLevelSwitching] = useState('idle')
  const videoStatisticsData = useVideoStatisticsData(media_object)

  useEffect(() => {
    window.setTimeout(() => {
      setCount(count + 1)
    }, 1000)
  }, [count])

  useEffect(() => {
    const onLevelSwitching = () => {
      setLevelSwitching('Switching')
    }
    const onLevelSwitched = () => {
      setLevelSwitching('Did switch')
    }
    if (hls) {
      hls.on(Hls.Events.LEVEL_SWITCHING, onLevelSwitching)
      hls.on(Hls.Events.LEVEL_SWITCHED, onLevelSwitched)
    }
  }, [hls])

  return (
    <Draggable defaultPosition={{ x: 0, y: 0 }} handle=".VideoPlayerDebug">
      <div className="VideoPlayerDebug">
        <dl>
          <dt className="label">HLS</dt>
          <dt>Current level</dt>
          <dd>{hls?.currentLevel}</dd>
          <dt>Next level</dt>
          <dd>{hls?.nextLevel}</dd>
          <dt>Load level</dt>
          <dd>{hls?.loadLevel}</dd>
          <dt>Next load level</dt>
          <dd> {hls?.nextLoadLevel}</dd>
          <dt>Auto level</dt>
          <dd>{hls?.autoLevelEnabled}</dd>
          <dt>Manual level</dt>
          <dd>{hls?.manualLevel}</dd>
          <dt>Switch state</dt>
          <dd>{levelSwitching}</dd>
          <dt>Latency</dt>
          <dd>{Math.round(hls?.latency || 0)}</dd>
          <dt>Current time</dt>
          <dd>{Math.round(hls?.media?.currentTime || 0)}</dd>
          <dt className="label">Statistics</dt>
          <dt>Play Latency</dt>
          <dd>{videoStatisticsData?.play_latency}</dd>
          <dt>Start Latency</dt>
          <dd>{videoStatisticsData?.start_latency}</dd>
          <dt>Stored Time Played</dt>
          <dd>{videoStatisticsData?.stored_play_time}</dd>
          <dt>Buffer Count</dt>
          <dd>{videoStatisticsData?.buffer_count}</dd>
          <dt>Event Count</dt>
          <dd>{videoStatisticsData?.event_count}</dd>
          <dt>Drift</dt>
          <dd>{Math.round(hls?.drift || 0)}</dd>
          <dt>Live sync position</dt>
          <dd>{Math.round(hls?.liveSyncPosition || 0)}</dd>
        </dl>
      </div>
    </Draggable>
  )
}
