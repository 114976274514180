import React, { FC } from 'react'

import { Field, FormikProps } from 'formik'
import styles from './DateFieldsSection.module.scss'
import { t } from '@lingui/macro'
import { TimeField } from 'components/Forms/TimeField/TimeField'
import { DateInputField } from 'components/Forms/DateInputField/DateInputField'

interface Props {
  formik: FormikProps<any>
}

export const DateFieldsSection: FC<Props> = ({ formik }) => (
  <div className={styles.DateContainer}>
    <div className={styles.DateTimeContainer}>
      <Field
        component={DateInputField}
        id="startDate"
        name="startDate"
        label={t`Start date`}
        placeholder={t`yyyy-mm-dd`}
        onChange={formik.handleChange}
        dateValue={formik.values.startDate}
        isValid={!formik.errors.startDate}
        validationMessage={formik.errors.startDate}
        isRequired={false}
        readonly={!formik.values.manualEdit}
      />
      <Field
        component={TimeField}
        id="startTime"
        name="startTime"
        label={t`Start time`}
        placeholder={t`When does the stream start?`}
        onChange={formik.handleChange}
        timeValue={formik.values.startTime}
        isValid={!formik.errors.startTime}
        validationMessage={formik.errors.startTime}
        isRequired={false}
        readonly={!formik.values.manualEdit}
      />
    </div>
    <div className={styles.DateTimeContainer}>
      <Field
        component={DateInputField}
        id="endDate"
        name="endDate"
        label={t`End date`}
        placeholder={t`YYYY-MM-DD`}
        onChange={formik.handleChange}
        dateValue={formik.values.endDate}
        isValid={!formik.errors.endDate}
        validationMessage={formik.errors.endDate}
        isRequired={false}
        readonly={!formik.values.manualEdit}
      />
      <Field
        component={TimeField}
        id="endTime"
        name="endTime"
        label={t`End time`}
        placeholder={t`Write the title of the stream here`}
        onChange={formik.handleChange}
        timeValue={formik.values.endTime}
        isValid={!formik.errors.endTime}
        validationMessage={formik.errors.endTime}
        isRequired={false}
        readonly={!formik.values.manualEdit}
      />
    </div>
  </div>
)
