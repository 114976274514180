import React, { FC } from 'react'
import styles from './ResetPasswordModal.module.scss'
import { TopTextSection } from 'components/Modals/Sections/TopTextSection/TopTextSection'
import { t } from '@lingui/macro'
import { CompanyInfo } from 'components/page/footer/themedFooter/topSection/CompanyInfo'
import SolidSportLogo from 'components/page/footer/defaultFooter/SolidSportLogo'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
import { ResetPasswordForm } from './ResetPasswordForm/ResetPasswordForm'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
interface Props {
  onLoginClick: () => void
  onFormSuccess: () => void
}
export const ResetPasswordContent: FC<Props> = ({
  onLoginClick,
  onFormSuccess
}) => {
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const title = t`Enter a new password`
  const subTitle = t`Enter a new strong password in the fields below.`

  return (
    <div className={styles.ResetPasswordContent}>
      <TopTextSection title={title} subText={subTitle} />
      <ResetPasswordForm onSubmitForm={onFormSuccess} />
      <div className={styles.TextLinks}>
        <ClickableTextButton
          onClick={onLoginClick}
          defaultText={t`Do you already have an account?`}
          clickableText={t`Log in.`}
        />
      </div>
      <div className={styles.BottomLogo}>
        {isRootOrChildChannel ? (
          <CompanyInfo />
        ) : (
          <div className={styles.Logo}>
            <SolidSportLogo />
          </div>
        )}
      </div>
    </div>
  )
}
