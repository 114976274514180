import React, { FC } from 'react'
import styles from './LineupItem.module.scss'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
export interface LineupItemProps {
  number: string
  name: string
  isCaptain: boolean
  isHighlightedPosition: boolean
  positionAbbreviation: string
  isCoach: boolean
}

export const LineupItem: FC<LineupItemProps> = ({
  name,
  number,
  isCaptain,
  isHighlightedPosition,
  positionAbbreviation,
  isCoach
}) => {
  const isViewingStream = useSelector(isViewingStreamSelector)
  return (
    <div
      className={classNames(styles.Container, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      {!isCoach && <div className={styles.Number}>#{number}</div>}
      <div className={styles.Name}>{name}</div>
      {!isCoach && isHighlightedPosition && positionAbbreviation && (
        <div
          className={classNames(
            styles.LabelContainer,
            styles.isHighlightedPosition
          )}
        >
          {positionAbbreviation}
        </div>
      )}
      {!isCoach && isCaptain && (
        <div className={classNames(styles.LabelContainer, styles.IsCaptain)}>
          <Trans id="Captain of a team">C</Trans>
        </div>
      )}
    </div>
  )
}
