import React from 'react'

export const ClassIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M17.0522 9.87988V1.12988H3.30225V9.87988"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.05225 15.5049C2.05225 16.0022 2.24979 16.4791 2.60142 16.8307C2.95305 17.1823 3.42997 17.3799 3.92725 17.3799C4.42453 17.3799 4.90144 17.1823 5.25307 16.8307C5.6047 16.4791 5.80225 16.0022 5.80225 15.5049C5.80225 15.0076 5.6047 14.5307 5.25307 14.1791C4.90144 13.8274 4.42453 13.6299 3.92725 13.6299C3.42997 13.6299 2.95305 13.8274 2.60142 14.1791C2.24979 14.5307 2.05225 15.0076 2.05225 15.5049V15.5049Z"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.05225 19.8799C6.75017 19.3135 6.29985 18.8399 5.74942 18.5096C5.19899 18.1793 4.56915 18.0049 3.92725 18.0049C3.28534 18.0049 2.6555 18.1793 2.10507 18.5096C1.55464 18.8399 1.10432 19.3135 0.802246 19.8799"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5522 15.5049C14.5522 16.0022 14.7498 16.4791 15.1014 16.8307C15.4531 17.1823 15.93 17.3799 16.4272 17.3799C16.9245 17.3799 17.4014 17.1823 17.7531 16.8307C18.1047 16.4791 18.3022 16.0022 18.3022 15.5049C18.3022 15.0076 18.1047 14.5307 17.7531 14.1791C17.4014 13.8274 16.9245 13.6299 16.4272 13.6299C15.93 13.6299 15.4531 13.8274 15.1014 14.1791C14.7498 14.5307 14.5522 15.0076 14.5522 15.5049Z"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5522 19.8799C19.2502 19.3135 18.7998 18.8399 18.2494 18.5096C17.699 18.1793 17.0692 18.0049 16.4272 18.0049C15.7853 18.0049 15.1555 18.1793 14.6051 18.5096C14.0546 18.8399 13.6043 19.3135 13.3022 19.8799"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30225 15.5049C8.30225 16.0022 8.49979 16.4791 8.85142 16.8307C9.20305 17.1823 9.67997 17.3799 10.1772 17.3799C10.6745 17.3799 11.1514 17.1823 11.5031 16.8307C11.8547 16.4791 12.0522 16.0022 12.0522 15.5049C12.0522 15.0076 11.8547 14.5307 11.5031 14.1791C11.1514 13.8274 10.6745 13.6299 10.1772 13.6299C9.67997 13.6299 9.20305 13.8274 8.85142 14.1791C8.49979 14.5307 8.30225 15.0076 8.30225 15.5049Z"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3022 19.8799C13.0002 19.3135 12.5498 18.8399 11.9994 18.5096C11.449 18.1793 10.8192 18.0049 10.1772 18.0049C9.53534 18.0049 8.9055 18.1793 8.35507 18.5096C7.80464 18.8399 7.35432 19.3135 7.05225 19.8799"
      stroke="#2F3135"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
