import React from 'react'

const LiveIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7721 6.28571C21.0328 7.96759 21.7143 10.0129 21.7143 12.1148C21.7143 14.2167 21.0328 16.262 19.7721 17.9439"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9725 8.22961C18.7243 9.38626 19.1245 10.7362 19.1245 12.1157C19.1245 13.4952 18.7243 14.8451 17.9725 16.0017"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.22787 6.28571C2.96716 7.96759 2.28571 10.0129 2.28571 12.1148C2.28571 14.2167 2.96716 16.262 4.22787 17.9439"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.02754 8.22961C5.27572 9.38626 4.87555 10.7362 4.87555 12.1157C4.87555 13.4952 5.27572 14.8451 6.02754 16.0017"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.038 8.81771C10.9397 8.75871 10.8275 8.72686 10.7129 8.72539C10.5983 8.72393 10.4853 8.75291 10.3856 8.80937C10.2858 8.86583 10.2029 8.94775 10.1451 9.04677C10.0874 9.14579 10.057 9.25836 10.0569 9.37298V14.8575C10.0567 14.9722 10.0869 15.085 10.1446 15.1841C10.2023 15.2833 10.2853 15.3654 10.3851 15.4219C10.485 15.4784 10.5981 15.5074 10.7128 15.5057C10.8275 15.5041 10.9397 15.4721 11.038 15.4128L15.5717 12.671C15.6676 12.6134 15.7469 12.532 15.802 12.4347C15.857 12.3374 15.886 12.2275 15.886 12.1157C15.886 12.0039 15.857 11.894 15.802 11.7967C15.7469 11.6993 15.6676 11.618 15.5717 11.5604L11.038 8.81771Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default LiveIcon
