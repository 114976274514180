import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { getIsLive, getIsLiveforTeam } from '../../../api/channel/getIsLiveNow'
import { useDataApi } from '../../../api/useDataApi'
import useInfiniteScroll from '../../../hooks/InfiniteScroll'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { channelSelector } from '../../../redux-store/channel/channel.selectors'

const useFetchLiveBroadcastData = (
  channelSlug: string,
  liveBroadcastEndRef: null | MutableRefObject<Element | null | undefined>
) => {
  const PER_PAGE_COUNT = 11
  const channel = useSelector(channelSelector)
  const channelType = channel?.data?.type

  const [liveBroadcastPage, setLiveBroadcastPage] = useState(1)

  const isLiveBroadcastEndReached = useInfiniteScroll(liveBroadcastEndRef)

  const liveBroadcastData = useDataApi({
    apiCall: useCallback(
      channelType === ChannelTypes.team ? getIsLiveforTeam : getIsLive,
      []
    ),
    parameters: useMemo(
      () => ({
        slug: channelSlug,
        perPageCount: PER_PAGE_COUNT,
        page: liveBroadcastPage,
        channel: channel?.data
      }),
      [channelSlug, liveBroadcastPage]
    )
  })
  useEffect(() => {
    if (
      channelType !== ChannelTypes.team &&
      isLiveBroadcastEndReached &&
      liveBroadcastData &&
      liveBroadcastData?.data?.totalCount > liveBroadcastPage * PER_PAGE_COUNT
    ) {
      if (liveBroadcastData.data.data.length > 0) {
        setLiveBroadcastPage(liveBroadcastPage + 1)
      }
    }
  }, [isLiveBroadcastEndReached, liveBroadcastData.data])
  return [liveBroadcastData]
}

export default useFetchLiveBroadcastData
