import { PlayerStatisticsTableInterface } from 'features/statistics/PlayerStatistics/Table/PlayerStatisticsTable.interface'
import { PlayerStatisticsApiResponse } from './getPlayerStatisticsForLeague'
import { IntegrationName } from 'features/statistics/PlayerStatistics/getLogoForIntegration'

export const mapPlayerStatisticsResponse = (
  apiResponse: PlayerStatisticsApiResponse
): PlayerStatisticsTableInterface => {
  return {
    name: apiResponse.name,
    id: apiResponse.id,
    integration: {
      name:
        apiResponse.integration.name === IntegrationName.Profixio
          ? IntegrationName.Profixio
          : IntegrationName.Default,
      lastUpdated: apiResponse.integration.lastUpdated,
      link: apiResponse.integration.link
    },
    sortedBy: apiResponse.sortedBy,
    columns: apiResponse.columns,
    rows: apiResponse.rows
  }
}
