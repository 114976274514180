import React, { FC } from 'react'

export const ChatBubbleIcon: FC = () => (
  <svg
    width="159"
    height="132"
    viewBox="0 0 159 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.1417 106.709H52.0306L51.1914 107.437L27.2086 128.245V109.684V106.709H24.2326H23.8075C12.3025 106.709 2.97594 97.382 2.97594 85.877V23.8075C2.97594 12.3025 12.3025 2.97594 23.8075 2.97594H135.193C146.697 2.97594 156.024 12.3025 156.024 23.8075V85.877C156.024 97.382 146.697 106.709 135.193 106.709H53.1417Z"
      stroke="#C9CBCF"
      strokeWidth="6"
    />
  </svg>
)
