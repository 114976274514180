import React, { FC, useRef } from 'react'
import dayjs from 'dayjs'
import { ReceiptItem } from './ReceiptItem'
import { t } from '@lingui/macro'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import { Divider } from 'components/page/header/components/hamburgerMenuContent/divider/Divider'
import styles from './ReceiptContent.module.scss'

interface Props {
  description: string
  referenceNumber: string
  createdAt: number
  price: number
  currency: string
  vat: number
  onCloseClick: () => void
}

export const ReceiptContent: FC<Props> = ({
  description,
  referenceNumber,
  createdAt,
  price,
  currency,
  vat,
  onCloseClick
}) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const dateCreated = dayjs.unix(createdAt).format('YYYY-MM-DD HH:mm')
  const handelOnPrintClick = () => {
    if (componentRef && componentRef.current) {
      const companyInfoDiv = document.createElement('div')
      companyInfoDiv.style.textAlign = 'center'
      companyInfoDiv.style.marginBottom = '20px'

      companyInfoDiv.innerHTML = `
        <h1>Solidsport AB</h1>
      `
      const printContents = componentRef.current.innerHTML
      const originalContents = document.body.innerHTML
      const printContainer = document.createElement('div')
      printContainer.appendChild(companyInfoDiv)
      printContainer.innerHTML += printContents
      document.body.innerHTML = printContainer.innerHTML
      window.print()
      document.body.innerHTML = originalContents
      window.location.reload()
    }
  }
  return (
    <div className={styles.ReceiptContent}>
      <div ref={componentRef}>
        <FormSectionTitle title="Receipt" />
        <div className={styles.InnerContainer}>
          <Divider />
          <ReceiptItem
            descriptionLabel={t`Payment date`}
            content={dateCreated}
          />
          <ReceiptItem
            descriptionLabel={t`Description`}
            content={description}
          />
          <ReceiptItem
            descriptionLabel={t`Reference number`}
            content={referenceNumber}
          />

          <Divider />
          <ReceiptItem
            descriptionLabel={t`Amount`}
            content={`${price} ${currency}`}
          />
          <ReceiptItem
            descriptionLabel={t`VAT`}
            content={`${vat} ${currency}`}
          />
        </div>
      </div>
      <div className={styles.ButtonContainer}>
        <Button
          type={ButtonType.GhostWithBoarder}
          label={t`Close`}
          onClick={onCloseClick}
          buttonSize="Medium"
          customStyle={styles.Button}
        />
        <Button
          type={ButtonType.Secondary}
          label={t`Print`}
          onClick={handelOnPrintClick}
          buttonSize="Medium"
          customStyle={styles.Button}
        />
      </div>
    </div>
  )
}
