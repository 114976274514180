import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { LanguageForm } from './LanguageForm'
import { FormModal } from 'features/sportData/FormModal/FormModal'

interface Props {
  onClose: () => void
  isOpen: boolean
}
export const LanguageModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <FormModal
      preHeader={''}
      modalHeader={t`Select language`}
      modalInformation={''}
      defaultOpen={isOpen}
      onClose={onClose}
      header={t`Select the language of your preference`}
    >
      <LanguageForm onCancel={onClose} onSubmitSuccess={onClose} />
    </FormModal>
  )
}
