import { computeStateChange } from '../combineSubscribers'
import permissionsActions from './permissions.actions'
import { RolesUpdatedPayload } from './permissions.interface'

let prevState: RolesUpdatedPayload = {
  roles: undefined,
  mediaObject: undefined,
  streamUrl: undefined
}

export const permissionsSubscriber = (store: any) => {
  const state = store.getState()
  const { roles, stream } = state
  const { mediaObject, streamUrl } = stream?.data || {}

  const nextState: RolesUpdatedPayload = {
    roles,
    mediaObject,
    streamUrl
  }

  if (computeStateChange(prevState, nextState)) {
    prevState = nextState
    store.dispatch(permissionsActions.rolesUpdated(nextState))
  }
}
