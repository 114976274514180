import { useEffect } from 'react'
import { VideoAdsType } from 'hooks/VideoAds/videoAdsType'

interface Props {
  getVideoElement: any
  videoAds: VideoAdsType
  setAdsError: (value: boolean) => void
  setAdsDone: (value: boolean) => void
  setReadyToPlay: (value: boolean) => void
  resume: () => void
  pause: () => void
  log: (message: string) => void
}

export const useVideoAdsStatusCode = ({
  getVideoElement,
  videoAds,
  setAdsError,
  setAdsDone,
  setReadyToPlay,
  pause,
  log,
  resume
}: Props) =>
  useEffect(() => {
    log(`videoAds.statusCode ${videoAds.statusCode}`)
    switch (videoAds.statusCode) {
      case videoAds.codes.LOADED:
        break
      case videoAds.codes.READY_TO_PLAY:
        setReadyToPlay(true)
        break
      case videoAds.codes.NON_LINEAR_AD:
        setAdsDone(true)
        break
      case videoAds.codes.CONTENT_RESUME_REQUESTED:
        log('content resume requested, should call resume')
        setAdsDone(true)
        resume()
        break
      case videoAds.codes.CONTENT_PAUSE_REQUESTED:
        setAdsDone(false)
        pause()
        break
      case videoAds.codes.STARTED:
        if (!getVideoElement().paused && !videoAds.nonLinear) {
        }
        break
      case videoAds.codes.PAUSED:
      case videoAds.codes.RESUMED:
      case videoAds.codes.CLICK:
      case videoAds.codes.COMPLETE:
        break
      case videoAds.codes.AD_ERROR:
        setAdsDone(true)
        setAdsError(true)
        setReadyToPlay(true)
        break
      default:
        break
    }
  }, [videoAds.statusCode])
