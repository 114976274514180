import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import { FormModal } from '../FormModal/FormModal'
import { SendConfirmation } from './SendConfirmation'
import { ModalActionButtons } from '../../../components/Modals/ModalActionButtons/ModalActionButtons'
import { InviteAdminForm } from './InviteAdminForm/InviteAdminForm'
import { AddTeamToClubForm } from '../joinLeagueAsClub/AddTeamToClubForm/AddTeamToClubForm'
import {
  SportsDataModalClose,
  triggerGTMOnImportGameClick,
  triggerGTMOnModalClose
} from './gtmForAddTeamModal'

interface Props {
  clubName: string
  clubSlug: string
  isSendingInvite: boolean
  isInvitingAdmin: boolean
  defaultOpen: boolean
  onClose: () => void
}
export const AddTeamModal: FC<Props> = ({
  clubName,
  clubSlug,
  defaultOpen,
  isSendingInvite,
  isInvitingAdmin,
  onClose
}) => {
  const history = useHistory()
  const [headerText, setHeaderText] = useState('')
  const [modalInformationText, setModalInformationText] = useState('')
  const [showAdminInvited, setShowAdminInvited] = useState(false)
  const [createdChannelSlug, setCreatedChannelSlug] = useState('newTeamName')
  const [createdChannelName, setCreatedChannelName] = useState('')
  const [showInviteAdmin, setShowInviteAdmin] = useState(isInvitingAdmin)
  const [showIsSendingInvite, setShowIsSendingInvite] =
    useState(isSendingInvite)

  useEffect(() => {
    if (isSendingInvite) {
      setHeaderText(t`Sending invitation!`)
    }
    if (isInvitingAdmin) {
      setHeaderText(t`Awesome, ${createdChannelName} is now created`)
      setModalInformationText(
        t`Invite an admin to ${createdChannelName}, so that they are ready to broadcast their games, right away.`
      )
    }
  }, [isSendingInvite, createdChannelName, isInvitingAdmin])

  const handleNotNowClick = (stepInProgress: SportsDataModalClose) => {
    triggerGTMOnModalClose(clubSlug, stepInProgress)
    navigateToNewlyCreatedChannel(clubSlug, createdChannelSlug)
  }

  const navigateToNewlyCreatedChannel = (
    clubSlug: string,
    createdChannelSlug: string
  ) => {
    history.push(
      `/${clubSlug}/contentmanager/teams/${createdChannelSlug}/competitions`
    )
  }

  const handleClose = (stepInProgress: SportsDataModalClose) => {
    triggerGTMOnModalClose(clubSlug, stepInProgress)
    setHeaderText('')
    setModalInformationText('')
    setCreatedChannelName('')
    setCreatedChannelSlug('')
    setShowIsSendingInvite(false)
    setShowInviteAdmin(false)
    setShowAdminInvited(false)
    onClose()
  }
  const handleOnInviteSuccess = () => {
    setShowInviteAdmin(false)
    setShowIsSendingInvite(true)
    setHeaderText(t`Sending invitation!`)
  }
  const handleAnimation = () => {
    setShowIsSendingInvite(false)
    setShowAdminInvited(true)
    setHeaderText(t`Admin is invited!`)
    setModalInformationText(
      t`To get the team start broadcasting as soon as possible, import all their games to the channel.`
    )
  }
  const handleOnImportGames = () => {
    triggerGTMOnImportGameClick(
      clubSlug,
      createdChannelName,
      createdChannelSlug
    )
    navigateToNewlyCreatedChannel(clubSlug, createdChannelSlug)
  }

  useEffect(() => {}, [createdChannelName, createdChannelSlug])

  const submitNewTeamForm = (values: any) => {
    setCreatedChannelName(values.teamName)
    setCreatedChannelSlug(values.subdomain)
    setHeaderText(t`Awesome, ${values.teamName} is now created`)
    setModalInformationText(
      t`Invite an admin to ${values.teamName}, so that they are ready to broadcast their games, right away.`
    )
    setShowInviteAdmin(true)
  }

  return (
    <FormModal
      modalHeader={t`Add team to ${clubName}`}
      preHeader=""
      header={headerText}
      modalInformation={modalInformationText}
      defaultOpen={defaultOpen}
      onClose={() => handleClose(SportsDataModalClose.onCrossClick)}
    >
      {createdChannelName === '' && (
        <AddTeamToClubForm
          clubChannelSlug={clubSlug}
          onCloseForm={() => handleClose(SportsDataModalClose.createTeam)}
          onSubmitForm={submitNewTeamForm}
        />
      )}
      {showInviteAdmin && (
        <InviteAdminForm
          onCloseForm={() =>
            handleNotNowClick(SportsDataModalClose.inviteAdmin)
          }
          onInviteSuccess={handleOnInviteSuccess}
          createdChannelSlug={createdChannelSlug}
        />
      )}
      {showIsSendingInvite && (
        <SendConfirmation onAnimationEnd={handleAnimation} />
      )}

      {showAdminInvited && (
        <ModalActionButtons
          onConfirm={handleOnImportGames}
          onCancel={() => handleClose(SportsDataModalClose.importGames)}
          cancelLabel={t`Not now`}
          confirmButtonLabel={t`Import games`}
        />
      )}
    </FormModal>
  )
}
