import React, { FC } from 'react'
import googlePlay from './google-play.svg'
import appStore from './app-store.svg'
import styles from './StoreLinks.module.scss'

export const StoreLinks: FC = () => (
  <div className={styles.StoreLinks}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.solidsport.Broadcaster&hl=sv&gl=US"
      data-testid="play-store-link"
    >
      <img src={googlePlay} alt="Google Play" />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://apps.apple.com/se/app/solidsport-broadcast/id1232604557"
      data-testid="apple-store-link"
    >
      <img src={appStore} alt="App Store" />
    </a>
  </div>
)
