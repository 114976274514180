import { ChannelAbout } from 'redux-store/channelAbout/channelAbout.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface ChannelAboutApi {
  title: string
  content: string
  homepage: string
  contact: {
    name: string
    phone: string
    email: string
  }
  social_media: {
    facebook: string
    twitter: string
    instagram: string
  }
}

export const getChannelAbout = async (slug: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${slug}/about_channel`
    })
    const responseData = await resultResponse
    const mapped = mapAboutResponse(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapAboutResponse = (apiResponse: ChannelAboutApi): ChannelAbout => ({
  contact: {
    phone: apiResponse?.contact?.phone,
    email: apiResponse?.contact?.email
  },
  socialMedia: {
    facebook: apiResponse?.social_media?.facebook,
    twitter: apiResponse?.social_media?.twitter,
    instagram: apiResponse?.social_media?.instagram
  }
})
