import { AnyAction } from 'redux'
import { ChannelTypes } from '../../pages/ChannelPage/channelType'
import CHANNEL from './channel.constants'
import { getChannelProductType } from './getChannelProductType'

const initialState = {
  id: undefined,
  data: undefined,
  error: undefined,
  isLoading: false,
  isError: false
}
const enum RootChannelSubdomain {
  SwedishHandball = 'svenskhandboll'
}
const channel = (state: any = initialState, action: AnyAction) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case CHANNEL.SHOULD_CHANGE:
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case CHANNEL.LOADING_CHANNEL:
      return {
        id: action.id,
        isLoading: true,
        isError: false
      }
    case CHANNEL.CHANNEL_LOADED: {
      const withExtraAttributes = {
        ...action.data,
        isRootTeamChannel: action.data?.root_channel?.type
          ? action.data.root_channel.type === ChannelTypes.team
          : action.data.type === ChannelTypes.team &&
            action.data.is_root_channel,
        isRootClubChannel: action.data?.root_channel?.type
          ? action.data.root_channel.type === ChannelTypes.club
          : action.data.type === ChannelTypes.club &&
            action.data.is_root_channel,
        productVariant: getChannelProductType(action.data?.product_level_id),
        isSwedishHandball:
          action.data?.root_channel?.subdomain ===
            RootChannelSubdomain.SwedishHandball ||
          action.data?.subdomain === RootChannelSubdomain.SwedishHandball
      }
      return {
        data: withExtraAttributes,
        isLoading: false,
        isError: false
      }
    }
    case CHANNEL.LOADING_CHANNEL_ERROR:
      return {
        error: action.error,
        isLoading: false,
        isError: true
      }
    case CHANNEL.UPDATE_COVER_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          promotional_image_url: action.imagePath
        }
      }
    case CHANNEL.UPDATE_LOGO_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          logo_image_url: action.imagePath
        }
      }

    case CHANNEL.INCREMENT_FOLLOWERS:
      return {
        ...state,
        data: {
          ...state.data,
          follower_count: state?.data?.follower_count + 1
        }
      }
    case CHANNEL.DECREMENT_FOLLOWERS:
      // eslint-disable-next-line no-case-declarations
      const followers = state?.data?.follower_count
        ? state.data.follower_count
        : 0
      return {
        ...state,
        data: {
          ...state.data,
          follower_count: followers === 0 ? 0 : followers - 1
        }
      }

    default:
      return state
  }
}

export default channel
