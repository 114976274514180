import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export interface ChannelSettingsResponseApi {
  channel_title: string
  channel_url: string
  channel_time_zone: string
  contact_person_first_name: string
  contact_person_last_name: string
  contact_phone: string
  contact_email: string
  account_name: string
  account_address: string
  account_city: string
  account_post_code: string
  account_country_code: string
}

export interface ChannelAccountSettings {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  accountName: string
  address: string
  postCode: string
  city: string
  country: string
}

export interface ChannelOverviewSettings {
  url: string
  title: string
  timezone: string
}

export interface ChannelSettings {
  account: ChannelAccountSettings
  overview: ChannelOverviewSettings
}

export const getChannelSettings = async (
  slug: string
): Promise<ChannelSettings> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `session/companies/${slug}/admin_settings`
    })
    const responseData = await resultResponse
    const mapped = mapChannelSettings(responseData.data)
    return mapped
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapChannelSettings = (settings: ChannelSettingsResponseApi) => {
  try {
    return {
      account: {
        firstName: settings.contact_person_first_name,
        lastName: settings.contact_person_last_name,
        phoneNumber: settings.contact_phone,
        email: settings.contact_email,
        accountName: settings.account_name,
        address: settings.account_address,
        postCode: settings.account_post_code,
        city: settings.account_city,
        country: settings.account_country_code
      },
      overview: {
        url: settings.channel_url,
        title: settings.channel_title,
        timezone: settings.channel_time_zone
      }
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
