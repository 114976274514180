import { Level } from 'hls.js'

export type QualityLevel = {
  index: number
  bitrate: number
  name: string
  addon?: string
  width?: number
  height?: number
}

export const getQualityLevels = (levels: Partial<Level>[]): QualityLevel[] => {
  if (levels) {
    return [
      // Default auto level
      {
        index: -1,
        bitrate: -1,
        name: 'Auto',
        width: -1,
        height: -1
      },
      // Mapped quality levels
      ...levels.map((level: Partial<Level>, index: number): QualityLevel => {
        return {
          index: index,
          bitrate: level.bitrate!,
          name: qualityName(level.height!, level.bitrate!),
          addon: qualityAddon(level.height!),
          width: level.width,
          height: level.height
        }
      })
    ].sort((a: QualityLevel, b: QualityLevel) => b.bitrate - a.bitrate)
  }
  return []
}

const qualityName = (height: number, bitrate: number): string => {
  if (height <= 0 && bitrate > 0) {
    return `${Number(bitrate / 1000000).toFixed(2)} Mbit/s`
  }
  return `${height}p`
}

const qualityAddon = (height: number | undefined): string | undefined => {
  switch (height) {
    case 1080:
      return 'HD'
    case 2160:
      return '4K'
    case 4320:
      return '8K'
    default:
      return undefined
  }
}
