/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect } from 'react'
import { t } from '@lingui/macro'

import { VideoPlayerContext } from '../../../../../providers/VideoPlayerProvider/VideoPlayerProvider'
import { formatTime } from '../formatTime'
import './index.scss'
import { useCurrentTime } from '../hooks/currentTime'

// /**
//  * @typedef {object} TimeDisplay'
// */
type TimeDisplayProps = {
  isLive?: boolean
  hasDVR?: boolean
}

// /**
//  * TimeDisplay
//  * @desc show time on video/stream for video player
//  * @category video components controls
//  *
//  * @example
//  * return (
//  *   <TimeDisplay videoElement={HTMLVideoElement} isLive={boolean} />
//  * )
//  */

const TimeDisplay: React.FC<TimeDisplayProps> = () => {
  const { videoAds, adsDone, playerState } = useContext(VideoPlayerContext)
  const { duration, isLive, isDvr } = playerState
  const { remainingTime, currentPosition, totalAds } = videoAds
  const currentTime = useCurrentTime()

  useEffect(() => {}, [
    duration,
    currentTime,
    adsDone,
    remainingTime,
    currentPosition,
    totalAds
  ])

  const showLiveBadge =
    (isLive && !isDvr) || (isLive && isDvr && currentTime < duration - 40)

  return (
    <div className="TimeDisplay">
      {adsDone && (
        <>
          {showLiveBadge && <div className="liveDisplay">LIVE</div>}
          <div className="currentTime">{formatTime(currentTime)}</div>
          {!isLive && <div className="duration">{formatTime(duration)}</div>}
        </>
      )}
      {!adsDone && (
        <div className="ads">
          <span>{t`Ad`} </span>
          {!adsDone && `${currentPosition} / ${totalAds}`}
          <span> - </span>
          {!adsDone && formatTime(remainingTime)}
        </div>
      )}
    </div>
  )
}

export default TimeDisplay
