import React from 'react'

export const MeatballsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.11765" cy="12" r="2.11765" fill="white" />
    <circle cx="12" cy="12" r="2.11765" fill="white" />
    <circle cx="21.8823" cy="12" r="2.11765" fill="white" />
  </svg>
)
