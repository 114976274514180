import dayjs from 'dayjs'
import { BroadcastThumbnailInterface } from '../../components/cards/BroadcastThumbnail/broadcastCardTypes'
import { TimelineObjectPath } from '../../helpers/PathHelper'
import { TimelineObject } from '../../interfaces'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { mapTeamDataFromResponse } from './mapTeamFromResponse'

// https://sportstage.se/api/play_v1/timeline_objects/collections/highlights?per_page=16&page=1&company=mathias

export const getVideosForChannel = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/videos_and_replays',
      params: {
        per_page: perPageCount,
        page: page,
        company: slug
      }
    })
    const responseData = await resultResponse
    const map = mapVideosResponse(responseData.data)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

export const groupVideosByDate = (videoList: BroadcastThumbnailInterface[]) => {
  const list = videoList.reduce((ac: any, a) => {
    const key: string = dayjs
      .unix(a.startTime || a.publishedAt)
      .format('YYYY-MM-DD')

    // eslint-disable-next-line no-param-reassign
    ac[key] = (ac[key] || []).concat(a)
    return ac
  }, {})
  return list
}

export const mapVideosResponse = (
  apiResponse: any
): BroadcastThumbnailInterface[] =>
  apiResponse.map((latestVideo: TimelineObject) => ({
    id: latestVideo.ident,
    channelName:
      latestVideo.display_company && latestVideo.display_company.name,
    channelImg: '',
    channelUrl:
      latestVideo.display_company && latestVideo.display_company.subdomain,
    targetUrl: TimelineObjectPath(latestVideo),
    country: latestVideo.country,
    type: latestVideo.type,
    startTime: latestVideo.live_start_at
      ? latestVideo.live_start_at
      : latestVideo.published_at,
    endTime: latestVideo.live_end_at,
    publishedAt: latestVideo.published_at,
    live: false,
    awayTeam: mapTeamDataFromResponse(latestVideo?.away_team, 2),
    homeTeam: mapTeamDataFromResponse(latestVideo?.home_team, 1),
    highlightFromDate: latestVideo.highlight_from_date,
    hasHighlight: latestVideo.has_highlight,
    isReplay: latestVideo.is_replay,
    viewers: latestVideo.number_of_viewers,
    showViewers: false,
    views: latestVideo.number_of_views,
    showViews: false, // latestVideo.show_views_or_viewers,
    title: latestVideo.title,
    thumbnailImageUrl: latestVideo.thumbnail,
    duration: latestVideo.duration,
    categories: latestVideo.categories,
    rootDomain: latestVideo.root_level_domain
  }))
