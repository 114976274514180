import React, { FC, useCallback } from 'react'
import { getPromotedCategoriesForChannelStart } from '../../../../api/channel/getPromotedCategories'
import { useDataApi } from '../../../../api/useDataApi'
import { Loader } from '../../../../components/Loader/Loader'
import { PromotedCategorySlider } from './PromotedCategorySlider'
import { PromotedCategory } from './promotedCateoryTypes'
import styles from './PromotedCategories.module.scss'

interface Props {
  channelSlug: string
  thumbnailSize: string
}

export const PromotedCategories: FC<Props> = ({
  channelSlug,
  thumbnailSize
}) => {
  const { data, isError, isLoading } = useDataApi({
    apiCall: useCallback(getPromotedCategoriesForChannelStart, []),
    parameters: channelSlug
  })
  if (isError) {
    return <div>error getting promoted categories</div>
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {!isLoading &&
        data &&
        data.length > 0 &&
        data.map((category: PromotedCategory) => (
          <div
            key={category.category.slug}
            className={styles.PromotedCategoriesContainer}
          >
            <PromotedCategorySlider
              promotedCategoryContent={category.thumbnails}
              categoryTitle={category.category.name}
              thumbnailSize={thumbnailSize}
              isLoading={isLoading}
            />
          </div>
        ))}
    </div>
  )
}
