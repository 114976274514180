import React, { FC } from 'react'

import { ScarfIcon } from './Icons/ScarfIcon'

import { ProductType } from '../ProductType'
import { TicketIcon } from './Icons/TicketIcon'
import { DiamondIcon } from './Icons/DiamondIcon'

interface Props {
  productType: ProductType
}
export const SubscriptionsCardIcon: FC<Props> = ({ productType }) => {
  switch (productType) {
    case ProductType.MonthlySupporter:
      return <ScarfIcon />
    case ProductType.Subscription:
      return <ScarfIcon />
    case ProductType.AnnualSupporter:
      return <DiamondIcon />
    case ProductType.Tournament:
      return <TicketIcon />
    default:
      return <TicketIcon />
  }
}
