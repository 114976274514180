import React, { FC } from 'react'
import classNames from 'classnames'
import Shield from '../../../../../../../assets/images/shield.png'

import styles from '../RowCell.module.scss'
import { CellType } from './DefaultRowCell'

interface Props {
  imageUrl: string
  isSelected: boolean
  type?: CellType
}

export const TeamImageRowCell: FC<Props> = ({
  imageUrl,
  isSelected,
  type = CellType.DefaultTable
}) => {
  const imageUrlToUse = imageUrl ? imageUrl : Shield
  return (
    <div
      className={classNames(styles.RowCell, styles.TeamRowCell, {
        [styles.Selected]: isSelected,
        [styles.DefaultTeamRowCell]: type === CellType.DefaultTable,
        [styles.CategoryTeamRowCell]: type === CellType.CategoryLeader
      })}
    >
      <img className={styles.TeamImage} src={imageUrlToUse} />
    </div>
  )
}
