import { t } from '@lingui/macro'
import { CustomText } from './CustomText.interface'

const customText = (locale: string): CustomText => {
  const custom_text: CustomText = {}

  // Full list of keys: https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/language/locales/en-US.json
  custom_text[locale] = {
    payButton: `${t`Add card`}`
  }
  return custom_text
}

export default customText
