import { t } from '@lingui/macro'
import { AccessRestrictionType } from 'interfaces'
import { Price } from 'interfaces/Price.interface'
import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { ApiStatus } from 'pages/ChannelPage/ChannelSettings/Branding/Logos/UploadLogo/ApiStatus.interface'

export interface QuickEdit {
  ident: string
  title?: string
  description?: string
  startTime?: number
  endTime?: number
  manualEdit?: boolean
  accessRestriction?: AccessRestrictionType
  price?: Price
  imageFile?: any
}
export const patchQuickEdit = async (params: QuickEdit): Promise<ApiStatus> => {
  try {
    const formData = new FormData()
    const entries = [
      ['ident', params.ident],
      ['title', params.title],
      ['description', params.description],
      [
        'start_time',
        params.startTime ? JSON.stringify(params.startTime) : undefined
      ],
      ['end_time', params.endTime ? JSON.stringify(params.endTime) : undefined],
      ['access_restriction', params.accessRestriction],

      ['thumbnail', params.imageFile],
      [
        'manual_edit',
        params.manualEdit !== undefined
          ? params.manualEdit
            ? 'true'
            : 'false'
          : undefined
      ]
    ]
    entries.forEach(([key, value]) => {
      if (value !== undefined) {
        formData.append(key, value)
      }
    })
    if (params.price) {
      Object.entries(params.price).forEach(([currency, amount]) => {
        if (amount !== undefined) {
          formData.append(`price[${currency}]`, amount.toString())
        }
      })
    }
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'stream/quickedit',
      method: ApiEndpoint.METHODS.PATCH,
      file: formData,
      authenticatable: true
    })

    await response

    return {
      isSuccess: true
    }
  } catch (error: any) {
    if (error?.status === 413) {
      return {
        isSuccess: false,
        message: t`The file size of the image is too large (maximum allowed is 2MB)`
      }
    }
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
