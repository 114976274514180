/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
import { useState, useRef, useEffect, MutableRefObject } from 'react'

export default function useInfiniteScroll(
  ref: null | MutableRefObject<Element | null | undefined>,
  options: any = {}
) {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef<null | IntersectionObserver>(null)

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }

  useEffect(() => {
    if (!ref || !ref.current) return
    cleanObserver()
    const currentObserver = (observer.current = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
      { ...options }
    ))
    currentObserver.observe(ref.current)
    return () => cleanObserver()
  }, [ref, options])

  return isIntersecting
}
