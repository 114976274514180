import React, { FC } from 'react'
import styles from './ItemContainer.module.scss'

interface Props {
  itemTitle: string
  item: string | number
}

export const ItemContainer: FC<Props> = ({ itemTitle, item }) => (
  <div className={styles.ItemContainer}>
    <div className={styles.ItemTitle}>{itemTitle}:</div>
    <div className={styles.Item}>{item}</div>
  </div>
)
