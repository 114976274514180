import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'

export const postDeleteAccount = async () => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'users/edit/delete',
      method: ApiEndpoint.METHODS.POST,
      authenticatable: true
    })
    if (response.status === 204) {
      return { isSuccess: true }
    }
    return { isSuccess: false }
  } catch (error: any) {
    console.error('error!', error)
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
