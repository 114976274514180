import React, { FC, useState } from 'react'
import Modal from '../../../../components/Modals/Modal/Modal'
import { ReportContentForm } from './ReportContentForm'
import { ReportContentSuccess } from './success/ReportContentSuccess'

import styles from './ReportContentDialog.module.scss'

interface Props {
  isOpen: boolean
  onClose: () => void
  gameIdent?: string
  mediaIdent: string
  channelSlug: string
}

export const ReportContentDialog: FC<Props> = ({
  gameIdent,
  mediaIdent,
  channelSlug,
  onClose,
  isOpen
}) => {
  const [isFormClosed, setIsFormClosed] = useState(false)

  const onSuccess = () => {
    setIsFormClosed(true)
  }

  return (
    <Modal handleClose={onClose} isOpen={isOpen}>
      <div className={styles.Modal}>
        {isFormClosed ? (
          <div className={styles.Success}>
            <ReportContentSuccess />
          </div>
        ) : (
          <ReportContentForm
            gameIdent={gameIdent}
            mediaIdent={mediaIdent}
            channelSlug={channelSlug}
            onSuccess={onSuccess}
          />
        )}
      </div>
    </Modal>
  )
}
ReportContentDialog.defaultProps = {
  gameIdent: undefined
}
