/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import classNames from 'classnames'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../hooks/CurrentBreakpoint'
import BroadcastThumbnail from '../../cards/BroadcastThumbnail'

import styles from './chronologicalVideoList.module.scss'

interface Props {
  title: string
  list: any[]
}

const SectionGridVideos: FC<Props> = ({ title, list }) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const screenSize = {
    isDesktop: currentBreakpoint > Breakpoint.sm,
    isTablet:
      currentBreakpoint <= Breakpoint.sm &&
      currentBreakpoint > Breakpoint.mobile,
    isMobile: currentBreakpoint <= Breakpoint.mobile
  }
  // eslint-disable-next-line no-nested-ternary
  const cardSize = screenSize.isDesktop
    ? 'medium'
    : screenSize.isTablet
    ? 'small'
    : 'keepRatio'

  return (
    <div
      className={styles.SectionGridVideoTitle}
      data-testid="section-grid-videos"
    >
      <h4 className={styles.Date}>{title}</h4>
      <div className={styles.ThumbNailGridContainer}>
        {list.map((card) => (
          <div
            key={card.id}
            className={classNames(
              styles.BroadcastThumbnailContainer,
              styles[card.type]
            )}
          >
            <BroadcastThumbnail
              content={card}
              shared={false}
              size={cardSize}
              showDetails
              disableLink={false}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionGridVideos
