import React, { FC } from 'react'
import { Field, FormikProps } from 'formik'
import classNames from 'classnames'
import { t } from '@lingui/macro'
import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'
import { TextEditor } from 'components/Forms/TextEditor/TextEditor'
import Delta from 'quill-delta'

import { ButtonContent } from './ButtonContent'
import { InfoBox } from './InfoBoxForm'

import styles from './InfoBoxFormFields.module.scss'
import { FileSelector } from './FileSelector'

interface Props {
  formik: FormikProps<InfoBox>
}

export const InfoBoxFormFields: FC<Props> = ({ formik }) => {
  const onTextFieldChange = (text: Delta) => {
    formik.setFieldValue('text', text)
    formik.setFieldTouched('text', true, false)
  }
  const onHasButtonChange = () => {
    formik.setFieldValue('hasButton', !formik.values.hasButton)
    formik.setFieldTouched('hasButton', true, false)
  }

  return (
    <div className={styles.InfoBoxFormFieldsContainer}>
      <div className={styles.LeftSide}>
        <div className={classNames(styles.FormItem, styles.TextInput)}>
          <Field
            component={TextInput}
            id="title"
            name="title"
            label={t`Title`}
            placeholder={t`Write something to engage your audience here`}
            onChange={formik.handleChange}
            textValue={formik.values.title}
            isValid={!formik.errors.title}
            validationMessage={formik.errors.title}
            isRequired={false}
          />
        </div>
        <div className={styles.FormItem}>
          <label htmlFor="text">Text</label>
          <div className={styles.TextEditorContainer}>
            <Field
              component={TextEditor}
              placeholder={t`Write some descriptive text here`}
              onChange={onTextFieldChange}
              content={formik.values.text}
            />
          </div>
        </div>
        <div
          className={classNames(styles.FormItem, styles.ButtonContentContainer)}
        >
          <ButtonContent
            formik={formik}
            hasButton={formik.values.hasButton}
            onHasButtonChange={onHasButtonChange}
          />
        </div>
      </div>
      <div className={styles.RightSide}>
        <FileSelector
          formFieldName="imageFile"
          fileUrlFieldName="imageUrl"
          formik={formik}
          recommendedImageHeight="350px"
          recommendedImageWidth="500px"
        />
      </div>
    </div>
  )
}
