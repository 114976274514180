import { LoginIcon } from 'assets/icons/LoginIcon'
import React, { FC } from 'react'
import IconTextButton, { ButtonType } from '../TextIconButton'
import { useDispatch } from 'react-redux'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { t } from '@lingui/macro'
interface Props {
  isRounded?: boolean
  buttonType?: ButtonType
}
export const OpenLoginButton: FC<Props> = ({
  isRounded = false,
  buttonType = ButtonType.Secondary
}) => {
  const dispatch = useDispatch()
  const handleSingInClick = () => {
    dispatch(authenticationActions.openLoginLayer(undefined, 'login'))
  }
  return (
    <IconTextButton
      type={buttonType}
      onClick={handleSingInClick}
      id={'login-button'}
      buttonSize={'Medium'}
      label={t`Login`}
      isFluid
      isRounded={isRounded}
      data-testid="login-button"
    >
      <LoginIcon />
    </IconTextButton>
  )
}
