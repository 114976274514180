import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import styles from './IconButton.module.scss'

interface Props {
  id: string
  onClick: () => void
  children: ReactNode
  customStyle?: string
}

export const IconButton: FC<Props> = ({
  id,
  onClick,
  customStyle,
  children
}) => {
  return (
    <div
      tabIndex={0}
      data-testid={id}
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      className={classNames(
        styles.ButtonContainer,
        customStyle ? customStyle : ''
      )}
    >
      {children}
    </div>
  )
}
