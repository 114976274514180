import { LOCATION } from './location.constants'

const setLocation = (location: { pathName: string }) => ({
  type: LOCATION.SET_LOCATION,
  location
})

export const locationActions = {
  setLocation
}
