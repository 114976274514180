import React, { FC } from 'react'
import { t } from '@lingui/macro'
import CrossIcon from './CrossIcon'
import Button, {
  ButtonType
} from '../../../../../components/buttons/button/Button'
import styles from './ProgressBanner.module.scss'
import ProgressBar from './ProgressBar/ProgressBar'

interface Props {
  title: string
  subTitle: string
  percentageCompleted: number
  sectionIsOpen: boolean
  onToggleSectionClick: () => void
  onClose: () => void
}

const ProgressBanner: FC<Props> = ({
  title,
  subTitle,
  percentageCompleted,
  sectionIsOpen,
  onToggleSectionClick,
  onClose
}) => {
  const buttonText = sectionIsOpen ? t`Hide tips` : t`Show tips`
  return (
    <div className={styles.ProgressBanner}>
      <div className={styles.LeftContainer}>
        <h4 className={styles.Title}>{title}</h4>
        <div
          className={styles.subTitle}
          data-testid="progress-banner-sub-title"
        >
          {subTitle}
        </div>
        <ProgressBar percentageCompleted={percentageCompleted} />
      </div>
      <div className={styles.RightContainer}>
        <Button
          data-testid="progress-banner-button"
          label={buttonText}
          type={ButtonType.GhostWithBoarder}
          buttonSize="Medium"
          onClick={onToggleSectionClick}
        />
      </div>
      <div
        className={styles.CloseButton}
        onClick={onClose}
        onKeyPress={onClose}
        role="button"
        tabIndex={0}
      >
        <CrossIcon />
      </div>
    </div>
  )
}
export default ProgressBanner
