import { Middleware } from 'redux'
import TagManager from 'react-gtm-module'
import languageActions from '../language/language.actions'
import AUTHENTICATION from './authentication.constants'
import USER from '../user/user.constants'
import { User } from '../../interfaces/User.interface'
import { ApiError } from '../../network/ApiEndpoint'
import authenticationService from './authentication.service'

export const clearState: Middleware<unknown, {}, any> =
  (storeAPI: any) => () => (action) => {
    if (action.type === AUTHENTICATION.CLEAR_STATE) {
      // Clear failure

      const success = () => {
        return { type: AUTHENTICATION.CLEAR_STATE }
      }
      storeAPI.dispatch(success())
    }
  }

export const oauthLogin: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === AUTHENTICATION.LOGIN_CALLBACK) {
      // Login oauth callback
      const success = (user: User) => {
        return { type: AUTHENTICATION.LOGIN_SUCCESS, user }
      }
      const userAdded = (user: User) => {
        return { type: USER.USER_ADDED, user }
      }
      const failure = (error: ApiError) => {
        return { type: AUTHENTICATION.LOGIN_FAILURE, error }
      }
      authenticationService.loginCallback().then(
        (user) => {
          storeAPI.dispatch(success(user))
          storeAPI.dispatch(languageActions.setLanguage(user.locale))
          storeAPI.dispatch(userAdded(user))
        },
        (error) => {
          storeAPI.dispatch(failure(error.message))
        }
      )
    }
    return next(action)
  }

export const login: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === AUTHENTICATION.LOGIN_REQUEST) {
      const success = (user: User) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_login',
            method: user.providers.facebook ? 'facebook' : 'email',
            powered_by: window.location.hostname
          }
        })
        return { type: AUTHENTICATION.LOGIN_SUCCESS, user }
      }
      const userAdded = (user: User) => {
        return { type: USER.USER_ADDED, user }
      }
      const failure = (error: ApiError) => {
        return { type: AUTHENTICATION.LOGIN_FAILURE, error }
      }

      if (action.user.id) {
        // Facebook login
        authenticationService
          .facebookLogin(
            action.user.id,
            action.user.email,
            action.user.first_name,
            action.user.last_name,
            action.user.access_token,
            action.user.company
          )
          .then(
            (user) => {
              storeAPI.dispatch(success(user))
              storeAPI.dispatch(userAdded(user))
            },
            (error) => {
              storeAPI.dispatch(failure(error))
            }
          )
      } else {
        // Normal login
        authenticationService
          .login(action.user.email, action.user.password)
          .then(
            (user) => {
              storeAPI.dispatch(success(user))
              storeAPI.dispatch(languageActions.setLanguage(user.locale))
              storeAPI.dispatch(userAdded(user))
            },
            (error) => {
              storeAPI.dispatch(failure(error))
            }
          )
      }
    }
    return next(action)
  }

export const register: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === AUTHENTICATION.REGISTER_REQUEST) {
      const success = (user: User) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'create_account',
            method: 'email',
            powered_by: window.location.hostname
          }
        })
        return {
          type: AUTHENTICATION.REGISTER_SUCCESS,
          user
        }
      }
      const userAdded = (user: User) => {
        return { type: USER.USER_ADDED, user }
      }
      const failure = (error: ApiError) => {
        return { type: AUTHENTICATION.REGISTER_FAILURE, error }
      }

      authenticationService
        .register(
          action.user.firstName,
          action.user.lastName,
          action.user.email,
          action.user.password,
          action.user.signupToken,
          action.user.emailConsent
        )
        .then(
          (user) => {
            storeAPI.dispatch(success(user))
            storeAPI.dispatch(userAdded(user))
          },
          (error) => {
            storeAPI.dispatch(failure(error))
          }
        )
    }
    return next(action)
  }

export const sendEmail: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === AUTHENTICATION.SEND_EMAIL_REQUEST) {
      const success = () => {
        return { type: AUTHENTICATION.SEND_EMAIL_SUCCESS }
      }
      const failure = (error: ApiError) => {
        return { type: AUTHENTICATION.SEND_EMAIL_FAILURE, error }
      }

      authenticationService.sendEmail(action.user.email).then(
        () => {
          return storeAPI.dispatch(success())
        },
        (error) => {
          return storeAPI.dispatch(failure(error))
        }
      )
    }
    return next(action)
  }

export const logout: Middleware<unknown, {}, any> =
  (storeAPI: any) => (next) => (action) => {
    if (action.type === AUTHENTICATION.LOGOUT) {
      const userRemoved = () => {
        return { type: USER.USER_REMOVED }
      }
      authenticationService.logout()
      storeAPI.dispatch(userRemoved())
    }
    return next(action)
  }
