import { TimelineObjectPath } from '../../helpers/PathHelper'
import { News } from '../../interfaces/News.interface'
import { RelatedArticlesResponseType } from '../../interfaces/RelatedArticlesResponse.interface'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getRelatedArticles = async ({
  slug,
  articleId
}: {
  slug: string
  articleId: string
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `article/${articleId}/related`,
      params: { per_page: 4, company: slug }
    })
    const responseData = await resultResponse
    const mapped = mapRelatedArticleResponse(responseData.data)
    return mapped
  } catch (e) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapRelatedArticleResponse = (
  apiResponse: RelatedArticlesResponseType[]
): News[] => {
  return apiResponse.map((news: RelatedArticlesResponseType) => ({
    id: news.ident,
    title: news.title,
    preamble: news.description,
    imageUrl: news.thumbnail,
    published: news.published_at,
    company: news.display_company.subdomain,
    sport: news.sport.name,
    country: news.country,
    targetUrl: TimelineObjectPath(news)
  }))
}
