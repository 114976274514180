import React, { FC } from 'react'
import { StatusMessageType } from './StatusMessage'
import CheckmarkIcon from 'components/lists/ChannelListItem/Icons/CheckmarkIcon'
import { InformationIcon } from 'components/Accordion/AccordionItem/InformationButton/InformationIcon'
import { ErrorIcon } from 'assets/icons/ErrorIcon'

interface Props {
  messageType: StatusMessageType
}

export const StatusMessageIcon: FC<Props> = ({ messageType }) => {
  switch (messageType) {
    case StatusMessageType.Success:
      return <CheckmarkIcon />
    case StatusMessageType.Error:
      return <ErrorIcon />
    case StatusMessageType.Information:
      return <InformationIcon />
    default:
      return <CheckmarkIcon />
  }
}
