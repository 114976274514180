import React from 'react'

import styles from './index.module.scss'

const SponsorSectionPreloader: React.FC = () => (
  <div className={styles.SponsorSectionPreloader}>
    <div className={styles.LoaderImage} />
  </div>
)

export default SponsorSectionPreloader
