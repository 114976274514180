import { Honeybadger } from '@honeybadger-io/react'
import { useEffect, useRef, useState } from 'react'
import useExternalScript from '../../../hooks/ExternalScript'
import { AppleID } from './AppleID'

export default function useAppleLogin() {
  const AppleIDStatus = useExternalScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  )

  const [signIn, setSignIn] = useState<() => void>()
  const initRetries = useRef(0)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const PROTOCOLL = process.env.REACT_APP_PROTOCOLL
  const appleID = (window as any).AppleID as AppleID

  const init = () => {
    try {
      if (appleID?.auth) {
        appleID.auth?.init({
          clientId: `com.solidsport.${process.env.REACT_APP_ENVIRONMENT}`,
          scope: 'name email',
          redirectURI: `${PROTOCOLL}${API_ENDPOINT}session/auth/apple`,
          state: JSON.stringify({ redirect: window.location.href }),
          usePopup: false
        })
        setSignIn(() => () => appleID.auth.signIn())
      } else if (initRetries.current < 10) {
        setTimeout(() => {
          init()
        }, 500)
      } else {
        throw new Error('AppleID not initialized')
      }
    } catch (e: any) {
      Honeybadger.notify(e, {
        name: 'AppleLoginInitError',
        message: 'Apple login init error'
      })
    }
  }

  useEffect(() => {
    if (AppleIDStatus.code === 200) {
      init()
    }
  }, [AppleIDStatus])

  return signIn
}
