import React, { FC, useState } from 'react'
import { SearchIcon } from './SearchIcon'
import styles from './MobileSearch.module.scss'
import { MobileSearchField } from './MobileSearchField/MobileSearchField'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

interface Props {
  onSearch: (searchInput: string) => void
  placeholder: string
}

export const MobileSearch: FC<Props> = ({ onSearch, placeholder }) => {
  const [showSearchField, setShowSearchField] = useState(false)
  const [searchFieldIsFocused, setSearchFieldIsFocused] = useState(false)
  const onSearchIconClick = () => {
    setShowSearchField(!showSearchField)
    setSearchFieldIsFocused(true)
  }
  const handleOnSearch = (searchInput: string) => {
    onSearch(searchInput)
    setShowSearchField(false)
  }
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const isRootClubChannel = channel?.data?.isRootClubChannel
  return (
    <>
      <div
        role="button"
        onClick={onSearchIconClick}
        className={classNames(styles.SearchIcon, {
          [styles.Themed]: isRootOrChildChannel,
          [styles.Default]: !isRootOrChildChannel
        })}
      >
        <SearchIcon />
      </div>
      {showSearchField && (
        <div
          className={classNames(styles.SearchFieldContainer, {
            [styles.IsRootOrChildChannel]: isRootOrChildChannel,
            [styles.IsRootClubChannel]: isRootClubChannel
          })}
        >
          <MobileSearchField
            onSearch={handleOnSearch}
            searchFieldIsFocused={searchFieldIsFocused}
            onBackArrowClick={() => setShowSearchField(false)}
            placeholder={placeholder}
          />
        </div>
      )}
    </>
  )
}
