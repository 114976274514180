import React, { FC } from 'react'
import { TargetingOptions, useAdUnit } from '../useAdUnit'
import styles from './NativeAd.module.scss'
import classNames from 'classnames'

interface Props {
  adId: string
  slotPath: string
  size: number[] | string[]
  targetingOptions?: TargetingOptions
  externalStyle?: string | null
}
export const NativeAd: FC<Props> = ({
  adId,
  slotPath,
  size,
  targetingOptions,
  externalStyle
}) => {
  useAdUnit({ path: slotPath, id: adId, size, targetingOptions })
  const style =
    typeof size[0] === 'string' ? {} : { height: size[1], width: size[0] }

  return (
    <div
      className={classNames(styles.AdContainer, externalStyle)}
      id={adId}
      style={style}
    />
  )
}
NativeAd.defaultProps = {
  targetingOptions: undefined
}
