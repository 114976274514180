import React, { FC } from 'react'

export const StartIcon: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2985 9.66379L12 4.42705L13.7015 9.66379C13.8421 10.0964 14.2452 10.3893 14.7001 10.3893H20.2063L15.7517 13.6258C15.3837 13.8932 15.2297 14.3671 15.3703 14.7997L17.0718 20.0365L12.6171 16.8C12.2491 16.5326 11.7508 16.5326 11.3828 16.8L6.92816 20.0365L8.62968 14.7997C8.77025 14.3671 8.61626 13.8932 8.24825 13.6258L3.79361 10.3893H9.29985C9.75474 10.3893 10.1579 10.0964 10.2985 9.66379Z"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
)
