import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActivateLivestream } from '../../../../pages/ChannelPage/Schedule/ActivateLivestream/ActivateLivestream'
import { getAdminPath } from '../../../../helpers/getAdminPath'
import {
  GameScheduleButtons,
  ActionButtons
} from '../GameScheduleButtons/GameScheduleButtons'
import { LabelAndDate } from '../LabelAndDate/LabelAndDate'
import styles from './DateAndButtonsContainer.module.scss'

interface Props {
  label: string
  labelSlug: string
  startTime: number
  actionButtonType: ActionButtons
  id: string
  higlightSlug: string
  channelSlug: string
  displayChannelSlug: string
  rootDomain?: string
}

export const DateAndButtonsContainer: FC<Props> = ({
  label,
  labelSlug,
  startTime,
  actionButtonType,
  id,
  higlightSlug,
  displayChannelSlug,
  channelSlug,
  rootDomain
}) => {
  const [openActivateLivestream, setOpenActivateLivestream] = useState(false)
  const history = useHistory()
  const adminPath = getAdminPath()

  const onClick = (redirectPath: string, admin: boolean) => {
    if (actionButtonType === ActionButtons.AdminNotPlanned) {
      setOpenActivateLivestream(true)
    } else if (admin) {
      window.open(`${adminPath}/${redirectPath}`)
    } else {
      history.push(redirectPath)
    }
  }

  return (
    <div
      className={styles.DateAndButtonsContainer}
      data-testid={`game-schedule-buttons-container-${id}`}
    >
      <LabelAndDate
        label={label}
        labelSlug={labelSlug}
        startTime={startTime}
        rootDomain={rootDomain}
      />
      <div className={styles.RightContainer}>
        <GameScheduleButtons
          type={actionButtonType}
          id={id}
          channelSlug={channelSlug}
          displayChannelSlug={displayChannelSlug}
          higlightSlug={higlightSlug}
          onClick={onClick}
          rootDomain={rootDomain}
        />
      </div>
      {openActivateLivestream && (
        <ActivateLivestream
          id={id}
          key={id}
          channelSlug={channelSlug}
          onClose={() => setOpenActivateLivestream(false)}
        />
      )}
    </div>
  )
}
