import React, { FC } from 'react'
import { Checkbox } from '../../../../components/checkbox/Checkbox'

import styles from './ReportContent.module.scss'

interface Props {
  reportTypes: any[]
  selectedItem: string
  onChange: (newValue: string) => void
}

export const CheckboxGroup: FC<Props> = ({
  reportTypes,
  selectedItem,
  onChange
}) => (
  <>
    {reportTypes &&
      reportTypes.map((rt: any) => (
        <div key={rt.id} className={styles.FormOption}>
          <Checkbox
            id={rt.id}
            checked={rt.id === selectedItem}
            onChange={() => {
              onChange(rt.id)
            }}
            label={rt.description}
          />
        </div>
      ))}
  </>
)
