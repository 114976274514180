import React, { FC } from 'react'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import { BackArrowIcon } from 'assets/icons/BackArrowIcon'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { StreamOptionsButton } from './StreamOptionsButton/StreamOptionsButton'

import styles from './TopActionsBar.module.scss'
import { ViewAsAdminButton } from './ViewAsAdminButton/ViewAsAdminButton'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { locationSelector } from 'redux-store/location/location.selectors'

export const TopActionsBar: FC = () => {
  const channel = useSelector(channelSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const dispatch = useDispatch()
  const history = useHistory()
  const channelSlug = channel.data.subdomain
  const locations = useSelector(locationSelector)
  const handleOnBackClick = () => {
    if (isViewingStream) {
      dispatch(userInterface.closeViewStream())
    } else {
      if (locations?.pages?.length > 1) {
        history.goBack()
      } else {
        history.push(`/${channelSlug}`)
      }
    }
  }

  return (
    <div
      className={classNames(styles.TopActionsBar, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      <IconButton
        id={'back-arrow-button'}
        customStyle={styles.BackButton}
        onClick={handleOnBackClick}
      >
        <BackArrowIcon />
      </IconButton>
      <div className={styles.RightContainer}>
        {!isViewingStream && <ViewAsAdminButton />}
        <StreamOptionsButton />
      </div>
    </div>
  )
}
