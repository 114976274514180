import React, { FC, useState } from 'react'
import * as yup from 'yup'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'

import { DropdownList } from '../../../../../../components/Forms/DropdownListField/DropdownList'
import { CustomFontDisplayItem } from '../customFontDisplayItem'
import { patchFontTheme } from '../../../../../../api/channel/font/patchFontTheme'
import { FontThemeSettings } from '../fontThemeSettings'
import { SubmitButton } from '../../../../../../components/buttons/SubmitButton/SubmitButton'

import styles from './FontThemeMappingsForm.module.scss'
import {
  StatusMessage,
  StatusMessageType
} from '../../../StatusMessage/StatusMessage'

interface Props {
  fontList: CustomFontDisplayItem[]
  themeFonts: FontThemeSettings
}

export const FontThemeMappingsForm: FC<Props> = ({ fontList, themeFonts }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const initialValues = {
    channelHeadingFont: themeFonts.headingFont,
    channelSubmenuFont: themeFonts.submenuFont,
    channelTextTransformation: themeFonts.textTransformation
  }
  const validationSchema = yup.object({
    channelHeadingFont: yup.string(),
    channelSubmenuFont: yup.string(),
    channelTextTransformation: yup.string()
  })

  const dropdownFontList = fontList?.map((font) => ({
    id: font.identifier,
    label: font.identifier
  }))

  enum TransformationOptions {
    none = 'none',
    capitalize = 'capitalize',
    uppercase = 'uppercase',
    lowercase = 'lowercase'
  }

  const transformationOptions = Object.keys(TransformationOptions).map(
    (key: string) => ({ id: key, label: key })
  )

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)
    const themeValues: FontThemeSettings = {
      headingFont: values.channelHeadingFont,
      submenuFont: values.channelSubmenuFont,
      textTransformation: values.channelTextTransformation,
      channelSlug: themeFonts.channelSlug
    }
    const response = await patchFontTheme(themeValues)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      formikHelpers.setSubmitting(false)
      setShowSuccessMessage(true)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => (
        <Form>
          <div className={styles.FontThemeMappingContainer}>
            <Field
              component={DropdownList}
              dropdownListData={dropdownFontList}
              value={props.values.channelHeadingFont}
              id="channelHeadingFont"
              name="channelHeadingFont"
              label={t`Channel name`}
              placeholder={t`please select a font`}
              isValid={!props.errors.channelHeadingFont}
              validationMessage={props.errors.channelHeadingFont}
              type="dark"
              onChange={(id: string) =>
                props.setFieldValue('channelHeadingFont', id)
              }
            />
            <Field
              component={DropdownList}
              dropdownListData={dropdownFontList}
              value={props.values.channelSubmenuFont}
              id="channelSubmenuFont"
              name="channelSubmenuFont"
              label={t`Submenu`}
              placeholder={t`please select a font`}
              isValid={!props.errors.channelSubmenuFont}
              validationMessage={props.errors.channelSubmenuFont}
              type="dark"
              onChange={(id: string) =>
                props.setFieldValue('channelSubmenuFont', id)
              }
            />
            <Field
              component={DropdownList}
              dropdownListData={transformationOptions}
              value={props.values.channelTextTransformation}
              id="channelTextTransformation"
              name="channelTextTransformation"
              label={t`Text transformation`}
              placeholder={t`please select an option`}
              isValid={!props.errors.channelTextTransformation}
              validationMessage={props.errors.channelTextTransformation}
              type="dark"
              onChange={(id: string) =>
                props.setFieldValue('channelTextTransformation', id)
              }
            />
          </div>
          <SubmitButton
            type="secondary"
            label="Update Theme"
            disabled={!props.dirty || props.isSubmitting}
          />
          {showSuccessMessage && (
            <StatusMessage
              onClose={() => setShowSuccessMessage(false)}
              message={t`the theme has been updated - you may need to refresh the channel page to see the changes`}
              messageType={StatusMessageType.Success}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
