import React from 'react'

export const TicketsIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 36"
    fill="none"
    stroke="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.125 16.6523C1.125 15.6168 1.96447 14.7773 3 14.7773H33C34.0355 14.7773 34.875 15.6168 34.875 16.6523V17.0078C34.875 17.5261 34.6605 18.0212 34.2824 18.3757L33.7668 18.859C31.3964 21.0813 31.3964 24.844 33.7668 27.0663L34.2824 27.5497C34.6605 27.9041 34.875 28.3993 34.875 28.9176V29.273C34.875 30.3086 34.0355 31.148 33 31.148H3C1.96447 31.148 1.125 30.3086 1.125 29.273V28.9176C1.125 28.3993 1.33952 27.9041 1.71761 27.5497L2.23317 27.0663L1.46374 26.2456L2.23317 27.0663C4.60362 24.844 4.60361 21.0813 2.23317 18.859L1.71761 18.3757C1.33952 18.0212 1.125 17.5261 1.125 17.0078V16.6523Z"
      strokeWidth="2.25"
    />
    <path
      d="M32.0408 8.77329L33.0126 9.33996L33.0126 9.33996L32.0408 8.77329ZM31.3804 9.90586L30.4085 9.33919L30.4085 9.33919L31.3804 9.90586ZM3.09534 13.3613L29.0396 5.62584L28.3967 3.46964L2.45246 11.2051L3.09534 13.3613ZM31.0689 8.20663L30.4085 9.33919L32.3523 10.4725L33.0126 9.33996L31.0689 8.20663ZM31.2356 6.8131C31.375 7.28069 31.3147 7.78512 31.0689 8.20663L33.0126 9.33996C33.5717 8.38119 33.7089 7.23379 33.3918 6.17022L31.2356 6.8131ZM30.4085 9.33919C29.125 11.5406 29.898 14.3667 32.1233 15.6083L33.2196 13.6434C32.0941 13.0155 31.703 11.586 32.3523 10.4725L30.4085 9.33919ZM29.0396 5.62584C29.9739 5.34728 30.957 5.87883 31.2356 6.8131L33.3918 6.17022C32.7582 4.04512 30.5218 2.83602 28.3967 3.46964L29.0396 5.62584ZM2.45246 11.2051C0.760843 11.7095 -0.20161 13.4897 0.302757 15.1813L2.45896 14.5384C2.30965 14.0376 2.59457 13.5106 3.09534 13.3613L2.45246 11.2051Z"
      fill="white"
    />
    <path
      d="M24.8281 17.3789V28.5513"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
    <path
      d="M23.3828 8.87891L24.8288 14.8955"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
    <path
      d="M11.1719 17.3789V28.5513"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
  </svg>
)
