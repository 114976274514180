import React, {
  FC,
  FocusEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import styles from './CopyLinkField.module.scss'
import { CopyIcon } from 'assets/icons/CopyIcon'
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel'
import { Trans } from '@lingui/macro'
interface Props {
  label?: string
  icon?: ReactNode
  textValue: string
}

export const CopyLinkField: FC<Props> = ({ label, icon, textValue }) => {
  const [showConfirmationBox, setShowConfirmationBox] = useState(false)
  const [seconds, setSeconds] = useState(2)
  const inputRef = useRef<HTMLInputElement>(null)
  const copyTextToClipboard = (id: string) => {
    inputRef?.current?.select()
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textValue)
    } else {
      const copyText = document.getElementById(id) as HTMLInputElement
      copyText.select()
      copyText.setSelectionRange(0, 99999)
    }
    setShowConfirmationBox(true)
  }
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event?.target?.select()
  }
  useEffect(() => {
    if (showConfirmationBox && seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setShowConfirmationBox(false)
      setSeconds(2)
    }
  })
  return (
    <div className={styles.CopyLinkField}>
      <div className={styles.LabelContainer}>
        {icon && <div className={styles.Icon}>{icon}</div>}
        {label && <FormFieldLabel label={label} />}
      </div>
      <div className={styles.FieldContainer}>
        <input
          id="copy-input"
          className={styles.Field}
          ref={inputRef}
          data-testid="copy-link"
          type="text"
          onFocus={handleFocus}
          value={textValue}
          onChange={() => null}
        />
        <div
          role="button"
          tabIndex={0}
          onClick={() => copyTextToClipboard('copy-input')}
          onKeyDown={() => copyTextToClipboard('copy-input')}
          className={styles.CopyButton}
        >
          <CopyIcon />
        </div>
      </div>
      {showConfirmationBox && (
        <div className={styles.ConfirmationBox}>
          <Trans>Copied to clipboard</Trans>
        </div>
      )}
    </div>
  )
}
