import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

type GTMEnvironment = {
  name: string
  props: {
    env: string
    auth: string
  }
  events: any
}

type GTMEnvironments = {
  [key: string]: GTMEnvironment
}

export const GTMInitialize = () => {
  const gtmId: string = 'GTM-MJP9GGJ'

  const events = {
    login: 'log in',
    follow_click: 'follow channel',
    page_view: 'page_view',
    sign_up: 'sign up',
    add_to_cart: 'add to cart',
    begin_checkout: 'begin checkout',
    purchase: 'purchase',
    download: 'download stream',
    powered_by: 'is a poweredby channel'
  }
  const gtmEnvironments: GTMEnvironments = {
    production: {
      name: 'production',
      props: {
        env: 'env-1',
        auth: 'VyILqc-1uEeXERzj36-6Mg'
      },
      events
    },
    staging: {
      name: 'staging',
      props: {
        env: 'env-3',
        auth: 'Y2XKZHPAEJ_AkGxoK_hc5w'
      },
      events
    },
    development: {
      name: 'development',
      props: {
        env: 'env-4',
        auth: 'XAeIpEpHiwUBjNiaVp5zPQ'
      },
      events
    }
  }

  const findGTMEnv = (
    environments: GTMEnvironments
  ): Promise<GTMEnvironment> => {
    return new Promise((resolve, reject) => {
      const runningEnvironment: string | undefined =
        process.env.REACT_APP_ENVIRONMENT
      const matchingGTMKey: string | undefined = Object.keys(environments).find(
        (k) => k === runningEnvironment
      )
      if (matchingGTMKey) {
        const gtmEnvironment: GTMEnvironment = environments[matchingGTMKey]
        resolve(gtmEnvironment)
      } else {
        const error: Error = new Error()
        error.message =
          'No matching environment found for Google Tag Manager.\n' +
          ` - Current environment is: ${runningEnvironment}\n` +
          ` - Available GTM environments are: ${Object.keys(environments).join(
            ', '
          )}`
        reject(error)
      }
    })
  }

  findGTMEnv(gtmEnvironments)
    .then((environment: GTMEnvironment) => {
      const tagManagerArgs = {
        gtmId: gtmId,
        ...environment.props
      }
      TagManager.initialize(tagManagerArgs)
    })
    .catch((error: Error) => {
      console.log(error.message)
    })
}

export const GTMPageView = async () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      try {
        const domWindow = window as any
        if (domWindow.dataLayer) {
          const url: string = `${document.location.origin}${location.pathname}${location.search}`
          domWindow.dataLayer.push({
            event: 'pageview',
            page: {
              url: url,
              title: document.title
            }
          })
        }
      } catch (e) {
        console.log('Google Tag Manager dataLayer error', e)
      }
    }, 500)
  }, [location.pathname, location.search])
}
