export const getTableBasedOnTeamSlug = (rows: any, teamSlug: string) => {
  const selectedTeamInTable = rows.find(
    (row: any) => row.subdomain === teamSlug
  )
  const selectedTeamToReturn = { ...selectedTeamInTable, selectedTeam: true }
  const teamPosition =
    selectedTeamInTable &&
    selectedTeamInTable.Position !== '' &&
    parseInt(selectedTeamInTable.Position)

  if (rows.length < 4 || selectedTeamInTable === undefined) return undefined

  if (teamPosition && teamPosition === 1) {
    const itemAfter = getItemBasedOnPositon(rows, teamPosition + 1)
    const secondItemAfter = getItemBasedOnPositon(rows, teamPosition + 2)
    return [selectedTeamToReturn, itemAfter, secondItemAfter]
  } else if (teamPosition === rows.length) {
    const firstItem = getItemBasedOnPositon(rows, teamPosition - 2)
    const itemBefore = getItemBasedOnPositon(rows, teamPosition - 1)
    return [firstItem, itemBefore, selectedTeamToReturn]
  } else if (teamPosition > 1) {
    const itemBefore = getItemBasedOnPositon(rows, teamPosition - 1)
    const itemAfter = getItemBasedOnPositon(rows, teamPosition + 1)
    return [itemBefore, selectedTeamToReturn, itemAfter]
  }
  return [selectedTeamToReturn]
}

const getItemBasedOnPositon = (rows: any, position: number) => {
  return (
    position && rows.find((row: any) => row.Position === position.toString())
  )
}
