import React, { FC, useState } from 'react'
import { FormikHelpers, FormikProps, Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import { t } from '@lingui/macro'
import { TeamNameField } from './TeamNameField'
import { getSubdomainSearch } from '../../../../api/search/subdomainSearch'
import { postCreateClubTeamChannel } from '../../../../api/sportData/postCreateClubTeamChannel'

import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'
import { FormActionButtons } from '../../../../components/Forms/FormActionButtons/FormActionButtons'

import { sanitiseString } from '../../../../helpers/sanitiseString'
import styles from './AddTeamToClubForm.module.scss'
import { triggerGTMAddTeamSuccess } from 'features/sportData/AddTeamModal/gtmForAddTeamModal'

interface NewChannelForm {
  teamName: string
  subdomain: string
}

interface Props {
  clubChannelSlug: string
  onCloseForm: () => void
  onSubmitForm: (values: NewChannelForm) => void
}

export const AddTeamToClubForm: FC<Props> = ({
  clubChannelSlug,
  onCloseForm,
  onSubmitForm
}) => {
  const initialValues: NewChannelForm = {
    teamName: '',
    subdomain: ''
  }
  const addTeamToClubSchema = yup.object({
    teamName: yup.string().required(t`Please enter your team's name`),
    subdomain: yup
      .string()
      .min(4, t`Your channel Url must be longer than 4 characters`)
      .required()
  })

  const onSubmit = async (
    values: NewChannelForm,
    formikHelpers: FormikHelpers<any>
  ) => {
    const submitValues = { ...values, clubChannelSlug }
    formikHelpers.setSubmitting(true)
    const response = await postCreateClubTeamChannel({
      name: submitValues.teamName,
      clubChannelSlug: submitValues.clubChannelSlug,
      teamChannelSlug: submitValues.subdomain
    })
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      triggerGTMAddTeamSuccess(
        clubChannelSlug,
        submitValues.teamName,
        submitValues.subdomain
      )
      formikHelpers.setSubmitting(false)
      onSubmitForm(values)
    }

    formikHelpers.setSubmitting(false)
  }
  const [companyNames, setCompanyNames] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const domain = `${window.location.hostname}/`

  return (
    <div className={styles.AddTeamToClubFormContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={addTeamToClubSchema}
        validateOnChange={false}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          const search = async (term: string) => {
            try {
              setIsSearching(true)
              const searchTerm = sanitiseString(decodeURI(term))
              props.setFieldValue('subdomain', searchTerm)
              const list: string[] = await getSubdomainSearch(searchTerm)
              setCompanyNames(list)
              // eslint-disable-next-line no-empty
            } catch {
            } finally {
              setIsSearching(false)
              props.validateField('subdomain')
            }
          }

          const validateSubdomain = (value: string) => {
            const error =
              companyNames &&
              companyNames?.length > 0 &&
              companyNames.includes(value)
                ? t`This channel Url already exsits in the system`
                : undefined

            return error
          }

          const handleTeamChange = (e: any) => {
            props.handleChange(e)

            try {
              search(e.target.value)
            } catch {
              // eslint-disable-next-line no-empty
            } finally {
            }
          }

          return (
            <Form>
              <Field
                component={TextInput}
                id="teamName"
                name="teamName"
                label={t`Team name`}
                placeholder={t`enter your team's name`}
                onChange={handleTeamChange}
                textValue={props.values.teamName}
                isValid={!props.errors.teamName}
                validationMessage={props.errors.teamName}
              />
              <Field
                validate={validateSubdomain}
                component={TeamNameField}
                id="subdomain"
                name="subdomain"
                label={t`subdomain`}
                placeholder={t`enter the url`}
                onChange={props.handleChange}
                textValue={props.values.subdomain}
                isValid={!props.errors.subdomain}
                validationMessage={props.errors.subdomain}
                isLoading={isSearching}
                leftSideText={domain}
              />
              <FormActionButtons
                cancelButtonLabel={t`Cancel`}
                submitButtonLabel={t`Add`}
                onCancel={onCloseForm}
                formik={props}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
