import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { heroImageCallToActionSchema } from './HeroImageCallToActionValidationSchema'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { HeroImageCallToActionFormFields } from './HeroImageCallToActionFormFields'
import { postHeroImageCallToAction } from 'api/customContent/postHeroImageCallToAction'
import styles from './HeroImageCallToActionForm.module.scss'

export interface HeroImageCallToAction {
  title: string
  text: string
  hasButton: boolean
  textField: string
  linkField: string
  target: LinkTarget
}
interface Props {
  onSubmitSuccess: () => void
  onCancel: () => void
  channelSlug: string
  existingForm?: HeroImageCallToAction
}

export const HeroImageCallToActionForm: FC<Props> = ({
  onSubmitSuccess,
  existingForm,
  channelSlug,
  onCancel
}) => {
  const initialValues: HeroImageCallToAction = {
    title: existingForm?.title || '',
    text: existingForm?.text || '',
    hasButton: existingForm?.hasButton || false,
    textField: existingForm?.textField || '',
    linkField: existingForm?.linkField || '',
    target: existingForm?.target || LinkTarget.CurrentWindow
  }

  const onSubmit = async (
    values: HeroImageCallToAction,
    formikHelpers: FormikHelpers<HeroImageCallToAction>
  ) => {
    formikHelpers.setSubmitting(true)
    const formValues = { ...values, channelSlug }
    const response = await postHeroImageCallToAction({
      ...formValues
    })
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
      formikHelpers.setSubmitting(false)
    } else {
      formikHelpers.setSubmitting(false)
      onSubmitSuccess()
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={heroImageCallToActionSchema}
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
    >
      {(props: FormikProps<HeroImageCallToAction>) => (
        <Form>
          <div className={styles.FormContainer}>
            <HeroImageCallToActionFormFields formik={props} />
            <FormActionButtons
              cancelButtonLabel={t`Cancel`}
              submitButtonLabel={t`Save & publish`}
              onCancel={onCancel}
              formik={props}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}
