/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { getLeaguesForChannel } from '../../../api/channel/getLeaguesForChannel'
import {
  ChannelListItem,
  ChannelLinkIcons
} from '../../../components/lists/ChannelListItem/ChannelListItem'

import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { useChannelPage } from '../useChannelPage'
import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { getChannelLinkMapper } from '../../../components/lists/ChannelListItem/getChannelLink'

interface Props {
  channelSlug: string
}
const Leagues: FC<Props> = ({ channelSlug }) => {
  const [leagues, setLeagues] = useState<AssociationClubEventDistrict[]>([])
  const PER_PAGE_COUNT = 20
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getLeaguesForChannel,
    onData: (data: any) => setLeagues((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) => setTotalPages(total / PER_PAGE_COUNT)
  })

  const isEmpty = !leagues?.length
  const emptyMessage = t`There are no leagues for this association`
  const channelLinkMapper = getChannelLinkMapper('leagues')

  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }
  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {leagues?.map((league: AssociationClubEventDistrict) => (
        <ChannelListItem
          key={league.subdomain}
          channelLink={channelLinkMapper(league)}
          iconType={ChannelLinkIcons.trophy}
        />
      ))}
    </ChannelPageList>
  )
}

export default Leagues
