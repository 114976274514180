import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'

import React, { FC } from 'react'
import styles from './Buttons.module.scss'
import { ShareIcon } from 'assets/icons/ShareIcon'
import { useDispatch } from 'react-redux'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'

export const ShareButton: FC = () => {
  const dispatch = useDispatch()
  return (
    <IconTextButton
      id={'share-stream-content-button'}
      type={ButtonType.Secondary}
      buttonSize={'Medium'}
      label={''}
      onClick={() => dispatch(userInterface.openShareContentModal())}
      externalStyle={styles.ShareButton}
      isRounded
      data-testid="share-stream-button"
    >
      <ShareIcon />
    </IconTextButton>
  )
}
