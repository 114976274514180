import { t } from '@lingui/macro'
import { GameScheduleItem, GameScheduleItemStatus } from './gameScheduleItem'

export const getGameScheduleStatusText = (
  gameListItem: GameScheduleItem
): string => {
  switch (gameListItem.status) {
    case GameScheduleItemStatus.Active:
      return t`Active`
    case GameScheduleItemStatus.Future:
      return t`Upcoming`
    case GameScheduleItemStatus.Past:
    case GameScheduleItemStatus.Undefined:
    default:
      return ''
  }
}
