import React, { FC } from 'react'
import styles from './ClickableTextButton.module.scss'

interface Props {
  onClick: () => void
  defaultText?: string
  clickableText: string
}

export const ClickableTextButton: FC<Props> = ({
  onClick,
  defaultText,
  clickableText
}) => {
  return (
    <div
      data-testid="clickable-text-button"
      className={styles.TextLinkContainer}
      role="button"
      onClick={() => onClick()}
      tabIndex={0}
    >
      {defaultText && (
        <div className={styles.Text} data-testid="default-text">
          {defaultText}
        </div>
      )}
      <div className={styles.ClickableText} data-testid="clickable-text">
        {clickableText}
      </div>
    </div>
  )
}
