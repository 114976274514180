import React, { FC, useEffect, useState } from 'react'

import { Honeybadger } from '@honeybadger-io/react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { Company } from '../../interfaces/Company.interface'
import DefaultError from '../../components/app/DefaultError'
import { WebpageMeta } from '../../meta/WebpageMeta'
import { Header } from './Header/Header'
import useCurrentUser from '../../hooks/CurrentUser'
import authenticationActions from '../../redux-store/authentication/authentication.actions'
import { toggleFollow } from '../../api/user/followChannel'
import { links } from './Header/menuLinks'
import { ChannelTypes } from './channelType'
import TagManager from 'react-gtm-module'
import { getPathList } from './Header/Breadcrumb/getPathList'
import { useTranslate } from 'hooks/Translate'
import { channelCustomPageSelector } from 'redux-store/channel/channel.selectors'
import { getCustomSubmenuItem } from './Header/Submenu/getCustomSubmenuItem'
import { getSubmenuLinksForChannel } from './getSubmenuLinksForChannel'

interface Props {
  // eslint-disable-next-line react/require-default-props
  channel?: Company | undefined
  isAdmin: boolean
  viewAsUser: boolean
  onChangeView: () => void
  changedTab: string
  children?: React.ReactNode
}

const ChannelPageContent: FC<Props> = ({
  channel,
  children,
  isAdmin,
  viewAsUser,
  onChangeView,
  changedTab
}) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const location = useLocation()
  const translate = useTranslate()

  const { pathname } = location
  const split = pathname.split('/')
  const currentPathname = split.length > 2 && split[2]
  const currentPathNameCustomPage = split.length > 2 && `menu/${split[3]}`
  const channelCountry = channel?.country?.name
  const channelType = channel?.type
  const channelName = channel?.name
  const channelUrl = channel?.channel_url
  const custompages = useSelector(channelCustomPageSelector)
  const impressumLink = channel?.impressum_page?.slug
  const customPagesSubmenu = getCustomSubmenuItem(custompages, impressumLink)
  const linksToUse = getSubmenuLinksForChannel(links, customPagesSubmenu)
  const selectedLink = linksToUse.find(
    (link) =>
      `/${currentPathname}` === link.link ||
      `/${currentPathNameCustomPage}` === link.link
  )
  const [selectedMenu, setSelectedMenu] = useState(
    !selectedLink ? '1' : selectedLink.id
  )
  useEffect(() => {
    if (selectedLink && selectedLink.id !== selectedMenu) {
      setSelectedMenu(selectedLink?.id)
    }
  }, [changedTab, currentPathname])

  let PageMetaData = <></>
  try {
    const breadcrumbs = getPathList(
      // @ts-ignore
      channel?.parentChannelSubdomain,
      channel?.subdomain,
      channel?.name,
      // @ts-ignore
      channel?.isRootChannel,
      location,
      translate
    )
    PageMetaData = channel ? (
      <WebpageMeta channel={channel} breadcrumbs={breadcrumbs} />
    ) : (
      <></>
    )
  } catch (e) {
    console.log('Could not set headerData in MainContent', e)
  }
  const errorHandler = (error: Error, info: { componentStack: string }) => {
    Honeybadger.notify(error)
    console.log('channnel page errorHandler: ', error, '\ninfo:', info)
  }
  if (!channel) return null

  const sport = channel?.category?.translated_name || channel?.category?.name

  const channelDetails: Company = channel
  const {
    promotional_image_url,
    logo_image_url,
    subdomain,
    name,
    ident,
    type,
    // @ts-ignore
    isRootChannel,
    custom_content,
    root_domain
  } = channelDetails

  const following =
    currentUser && currentUser.followed_companies !== null
      ? currentUser.followed_companies.includes(subdomain)
      : false

  const handleFollowClick = () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (!following) {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'follow_clicked',
          value: subdomain,
          followed_channel_name: channelName,
          followed_sport: sport,
          followed_channel_country: channelCountry,
          followed_partner_type: channelType,
          followed_channel_url: channelUrl
        }
      })
    }
    return toggleFollow()
  }

  const handleSelectSubmenu = (id: string) => {
    setSelectedMenu(id)
  }

  return (
    <>
      {PageMetaData}
      <Header
        isRootPage={isRootChannel}
        heroImageUrl={promotional_image_url}
        logoImageUrl={logo_image_url}
        channelSlug={subdomain}
        channelName={name || 'unknown'}
        channelId={ident}
        followed={following}
        isAdmin={isAdmin}
        isViewingAsUser={viewAsUser}
        onChangeView={onChangeView}
        onFollowChannel={() => handleFollowClick()}
        selectedSubmenu={selectedMenu}
        channelSubmenuLinks={linksToUse}
        onSelectSubmenu={(id: string) => handleSelectSubmenu(id)}
        // @ts-ignore
        channelType={ChannelTypes[type]}
        customContent={custom_content}
        rootDomain={root_domain}
        sport={sport}
      />

      <ErrorBoundary
        FallbackComponent={DefaultError}
        onError={errorHandler}
        onReset={() => {
          console.log('error boundary error!')
        }}
      >
        <section>{children}</section>
      </ErrorBoundary>
    </>
  )
}
export default ChannelPageContent
