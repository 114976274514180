import { t } from '@lingui/macro'
import { LinkType } from '../../../../components/page/menuLink/MenuLink'
import { SubMenuLink } from '../../../../components/page/Submenu/Submenu.interface'

export enum ContentManagerMenuItemId {
  Competitions = 1,
  Teams = 2,
  CustomContent = 3
}

export const contentManagerSubmenuLinks: SubMenuLink[] = [
  {
    id: ContentManagerMenuItemId.Competitions,
    translationText: t`Competitions`,
    type: LinkType.Route,
    link: '/competitions',
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: ContentManagerMenuItemId.Teams,
    translationText: t`Teams`,
    type: LinkType.Route,
    link: '/teams',
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: ContentManagerMenuItemId.CustomContent,
    translationText: t`Custom content`,
    type: LinkType.Route,
    link: '/customcontent',
    hidden: false,
    showInBreadcrumb: true
  }
]
