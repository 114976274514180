import React, { FC } from 'react'

import { t } from '@lingui/macro'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Breakpoint, useCurrentBreakpoint } from '../../hooks/CurrentBreakpoint'
import { getAcceptType } from './getAcceptType'
import { UPLOAD_TYPE } from './UploadType'
import { DropZone } from './DropZone'

interface Props {
  onUploadAccepted: (media: File) => void
  onUploadRejected: (media: FileRejection) => void
  type: UPLOAD_TYPE
  recommendedImageHeight?: string
  recommendedImageWidth?: string
}
export const DropFileZone: FC<Props> = ({
  onUploadAccepted,
  onUploadRejected,
  type,
  recommendedImageHeight,
  recommendedImageWidth
}) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint < Breakpoint.mobile
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: getAcceptType(type),
    onDropAccepted: (files) => {
      onUploadAccepted(files[0])
    },
    onDropRejected: (files) => {
      onUploadRejected(files[0])
    },
    maxSize: 307200000
  })

  const mediaTypeText =
    type === UPLOAD_TYPE.IMAGE || type === UPLOAD_TYPE.ICON
      ? t`image`
      : t`video`

  return (
    <DropZone
      isMobile={isMobile}
      isDragActive={isDragActive}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      mediaTypeText={mediaTypeText}
      recommendedImageHeight={recommendedImageHeight}
      recommendedImageWidth={recommendedImageWidth}
    />
  )
}
