import React, { FC } from 'react'

import styles from './ChatLoader.module.scss'
import { ChatBubbleIcon } from './ChatBubbleIcon'
import { Loader } from 'components/Loader/Loader'

export const ChatLoader: FC = () => {
  return (
    <div
      className={styles.ChatLoaderWrapper}
      title="Loading"
      data-testid="chat-animated-loader"
    >
      <div className={styles.ChatLoader}>
        <ChatBubbleIcon />
        <div className={styles.LoaderDots}>
          <Loader variant="large" />
        </div>
      </div>
    </div>
  )
}
