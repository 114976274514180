const AUTHENTICATION = {
  REGISTER_REQUEST: 'AUTHENTICATION_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'AUTHENTICATION_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'AUTHENTICATION_REGISTER_FAILURE',

  LOGIN_REQUEST: 'AUTHENTICATION_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTHENTICATION_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTHENTICATION_LOGIN_FAILURE',
  LOGIN_CALLBACK: 'AUTHENTICATION_LOGIN_CALLBACK',
  RESET_LOGIN_STATE: 'RESET_LOGIN_STATE',

  LOGOUT: 'AUTHENTICATION_LOGOUT',
  LOGOUT_WITHOUT_MIDDLEWARE: 'LOGOUT_WITHOUT_MIDDLEWARE',

  LOGIN_LAYER_OPEN: 'AUTHENTICATION_LOGIN_LAYER_OPEN',
  LOGIN_LAYER_CLOSE: 'AUTHENTICATION_LOGIN_LAYER_CLOSE',

  SEND_EMAIL_REQUEST: 'AUTHENTICATION_SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'AUTHENTICATION_SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'AUTHENTICATION_SEND_EMAIL_FAILURE',

  GETALL_REQUEST: 'AUTHENTICATION_GETALL_REQUEST',
  GETALL_SUCCESS: 'AUTHENTICATION_GETALL_SUCCESS',
  GETALL_FAILURE: 'AUTHENTICATION_GETALL_FAILURE',

  DELETE_REQUEST: 'AUTHENTICATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'AUTHENTICATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'AUTHENTICATION_DELETE_FAILURE',

  CLEAR_STATE: 'AUTHENTICATION_CLEAR_STATE',

  RESET_FROM_LOCAL_STATE: 'RESET_FROM_LOCAL_STATE'
}

export default AUTHENTICATION
