import React, { FC } from 'react'
import { Field } from 'formik'
import { t } from '@lingui/macro'
import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'

import styles from './ContactFormFields.module.scss'

interface Props {
  formik: any
}

export const ContactFormFields: FC<Props> = ({ formik }) => (
  <div className={styles.ContactFormFieldsContainer}>
    <Field
      component={TextInput}
      id="firstName"
      name="firstName"
      label={t`First name`}
      placeholder={t`first name`}
      onChange={formik.handleChange}
      textValue={formik.values.firstName}
      isValid={!formik.errors.firstName}
      validationMessage={formik.errors.firstName}
      isRequired
    />
    <Field
      component={TextInput}
      id="lastName"
      name="lastName"
      label={t`Last name`}
      placeholder={t`last name`}
      onChange={formik.handleChange}
      textValue={formik.values.lastName}
      isValid={!formik.errors.lastName}
      validationMessage={formik.errors.lastName}
      isRequired
    />
    <Field
      component={TextInput}
      id="phoneNumber"
      name="phoneNumber"
      label={t`Phone`}
      placeholder={t`070 123 30 30`}
      onChange={formik.handleChange}
      textValue={formik.values.phoneNumber}
      isValid={!formik.errors.phoneNumber}
      validationMessage={formik.errors.phoneNumber}
      isRequired
    />
    <Field
      component={TextInput}
      id="email"
      name="email"
      label={t`Email`}
      placeholder={t`email address`}
      onChange={formik.handleChange}
      textValue={formik.values.email}
      isValid={!formik.errors.email}
      validationMessage={formik.errors.email}
      isRequired
    />
  </div>
)
