import { useCallback } from 'react'
import { useLingui } from '@lingui/react'

export const useTranslate = () => {
  const { i18n } = useLingui()

  const translate = useCallback(
    (phrase: string) => {
      return i18n._(phrase)
    },
    [i18n]
  )

  return translate
}
