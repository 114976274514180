import { useEffect, useState } from 'react'
import Hls from 'hls.js'
import { isSafari } from 'react-device-detect'

// DOCS: https://github.com/video-dev/hls.js/blob/master/docs/API.md
type HLSProps = {
  src: string | undefined
  videoElement: HTMLVideoElement | undefined
  adsDone: boolean
}

let hls: Hls

export const HLS_STATES = {
  initializing: 'initializing',
  readyToPlay: 'readyToPlay',
  loading: 'loading',
  error: 'error'
}

export type HLSState = {
  hls: Hls
  status: string
  isLive: boolean
}

const useHLS = (props: HLSProps): HLSState => {
  const { src, videoElement, adsDone } = props

  const [status, setStatus] = useState<string>(HLS_STATES.initializing)
  const [loaded, setLoaded] = useState(false)
  const [isLive, setIsLive] = useState(false)

  useEffect(() => {
    const onMediaAttached = () => {
      if (src && hls) {
        hls.loadSource(src)
        hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed)
        hls.on(Hls.Events.MANIFEST_LOADING, onManifestLoading)
        hls.on(Hls.Events.LEVEL_LOADED, onLevelLoaded)
        hls.on(Hls.Events.ERROR, onError)
      }
    }
    const onManifestParsed = () => {
      if (hls) {
        setStatus(HLS_STATES.readyToPlay)
        setLoaded(true)
        hls.off(Hls.Events.MEDIA_ATTACHED, onMediaAttached)
        hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed)
        hls.off(Hls.Events.MANIFEST_LOADING, onManifestLoading)
        hls.off(Hls.Events.ERROR, onError)
      }
    }
    const onManifestLoading = () => {
      setStatus(HLS_STATES.loading)
    }

    const onLevelLoaded = (event: any, data: any) => {
      var isLive = data?.details?.live! || false
      setIsLive(isLive)
    }

    const onError = () => {
      setStatus(HLS_STATES.error)
      if (hls) {
        hls.detachMedia()
      }
      setLoaded(false)
    }

    if (src && videoElement && adsDone && !loaded) {
      if (Hls.isSupported() && !isSafari && hls) {
        hls.on(Hls.Events.MEDIA_ATTACHED, onMediaAttached)
        hls.attachMedia(videoElement)
      } else if (videoElement.canPlayType('application/vnd.apple.mpegURL')) {
        videoElement.src = src
        videoElement.play()
        setStatus(HLS_STATES.readyToPlay)
        setLoaded(true)
      }
    }
  }, [src, videoElement, adsDone, loaded])

  useEffect(() => {
    hls = new Hls()
    hls.config.backBufferLength = 30
    hls.config.levelLoadingRetryDelay = 10000
    hls.config.levelLoadingMaxRetryTimeout = 10000
    hls.config.levelLoadingMaxRetry = 9999
    hls.config.autoStartLoad = true

    const w = window as any
    w.hls = hls
    return () => {
      if (hls) {
        hls.stopLoad()
        hls.detachMedia()
        setLoaded(false)
      }
    }
  }, [src])

  useEffect(() => {
    return () => {
      if (videoElement) {
        videoElement.pause()
        videoElement.src = ''
      }
    }
  }, [videoElement])

  return {
    hls: hls,
    status: status,
    isLive
  }
}
export default useHLS
