import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

interface Props {
  hasLeftRightPadding: boolean
  hasTopBottomPadding: boolean
  hasLightBackground: boolean
  children?: React.ReactNode
}
const ContentContainer: FC<Props> = ({
  hasLeftRightPadding,
  hasTopBottomPadding,
  hasLightBackground,
  children
}) => (
  <div
    className={classNames(styles.ContentContainer, {
      [styles.HasLeftRightPadding]: hasLeftRightPadding,
      [styles.HasTopBottomPadding]: hasTopBottomPadding,
      [styles.HasLightBackground]: hasLightBackground
    })}
  >
    {children}
  </div>
)
export default ContentContainer
