import React from 'react'
import { useSelector } from 'react-redux'

import { MediaObject } from '../../../../interfaces'
import { streamSelector } from '../../../../redux-store/stream/stream.selectors'
import { Scoreboard } from './Scoreboard/Scoreboard'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
import { BannerAdSection } from '../BannerAdSection/BannerAdSection'
import styles from './GameResult.module.scss'

export const GameResult = () => {
  const stream = useSelector(streamSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)

  const mediaObject: MediaObject = stream?.data?.mediaObject

  return (
    <div
      className={classNames(styles.GameResult, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      <BannerAdSection showOnlySmallAdSizes={isViewingStream} hidePadding />
      {mediaObject && (
        <Scoreboard
          game={mediaObject.game}
          leagueName={mediaObject?.event_schedule?.name}
        />
      )}
    </div>
  )
}
