import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import PlayFilledIcon from '../PlayFilledIcon'
import styles from '../ThumbnailLabel.module.scss'
import getDateText from '../getDateText'

interface Props {
  publishedAt: number
}
const VideoLabel: FC<Props> = ({ publishedAt }) => {
  const { getDateString } = getDateText(publishedAt)
  return (
    <div className={styles.Default} data-testid="video-thumbnail-label">
      <div className={styles.VideoContainer}>
        <div className={styles.Icon}>
          <PlayFilledIcon />
        </div>
        <Trans>Video</Trans>
        {` ${getDateString()}`}
      </div>
    </div>
  )
}
export default VideoLabel
