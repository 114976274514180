import { AnyAction } from 'redux'
import COUNTRY from './country.constants'

export interface Country {
  country: any
}

export const createInitialState = () => {
  return {
    country:
      localStorage.getItem('country') || navigator.language.substring(3, 5)
  }
}

const initialState = {
  ...createInitialState()
}

const country = (state: Country = initialState, action: AnyAction) => {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case COUNTRY.SET_COUNTRY:
      localStorage.setItem('country', action.country)
      return {
        country: action.country
      }
    default:
      return state
  }
}

export default country
