/* eslint-disable default-case */
import { AccessRestrictionType } from '../../interfaces'
import { ViewType } from '../../redux-store/stream/stream.interface'
import { ActionPanelStateContext } from './ActionPanel.interface'

export const getShowBuyStreamButton = ({
  permissions,
  viewType,
  accessRestriction
}: ActionPanelStateContext): boolean => {
  const isAdmin = permissions.hasChannelAccess
  switch (viewType) {
    case ViewType.UPCOMING:
    case ViewType.LIVE:
    case ViewType.REPLAY:
    case ViewType.HIGHLIGHT:
    case ViewType.BROADCAST_MESSAGE:
    case ViewType.VIDEO:
      if (
        accessRestriction &&
        [
          AccessRestrictionType.PaymentRequired,
          AccessRestrictionType.SubscriptionRequired,
          AccessRestrictionType.PaymentAndSubscriptionRequired
        ].includes(accessRestriction)
      ) {
        return !(isAdmin || permissions.canView)
      }
  }

  return false
}

export const getShowLoginButton = ({
  roles,
  viewType,
  accessRestriction
}: ActionPanelStateContext): boolean => {
  switch (viewType) {
    case ViewType.UPCOMING:
    case ViewType.LIVE:
    case ViewType.REPLAY:
    case ViewType.HIGHLIGHT:
    case ViewType.BROADCAST_MESSAGE:
    case ViewType.VIDEO:
      if (accessRestriction === AccessRestrictionType.LoginRequired) {
        return !roles.isLoggedIn
      }
  }

  return false
}

export const getShowEditButton = ({
  permissions
}: ActionPanelStateContext): boolean => {
  return permissions.hasAdminAccess || permissions.hasChannelAccess
}

export const getShowBroadcastInfoButton = ({
  permissions,
  viewType
}: ActionPanelStateContext): boolean => {
  switch (viewType) {
    case ViewType.UPCOMING:
    case ViewType.LIVE:
    case ViewType.BROADCAST_MESSAGE:
      return permissions.hasAdminAccess || permissions.hasChannelAccess
    case ViewType.REPLAY:
    case ViewType.HIGHLIGHT:
    case ViewType.VIDEO:
      return false
  }
  return false
}

export const getShowWatchButton = ({
  roles,
  permissions
}: ActionPanelStateContext): boolean => {
  return (
    !roles.isViewingAsAdmin && permissions.canView && !permissions.hasUserPaid
  )
}

export const getShowDownloadButton = ({
  permissions,
  viewType
}: ActionPanelStateContext): boolean => {
  switch (viewType) {
    case ViewType.REPLAY:
    case ViewType.HIGHLIGHT:
    case ViewType.VIDEO:
      return permissions.canDownload
    case ViewType.LIVE:
    case ViewType.UPCOMING:
      return false
  }

  return false
}
