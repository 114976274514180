import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChannelFont } from './channelFonts.interface'

export interface ChannelFontsState {
  slug: string
  isLoading: boolean
  isError: boolean
  data: ChannelFont[]
  error?: any
}

export const initialState = {
  slug: '',
  isLoading: false,
  isError: false,
  data: [],
  error: undefined
} as ChannelFontsState

const channelFontsSlice = createSlice({
  name: 'channelFonts',
  initialState,
  reducers: {
    getChannelFonts(state, action: PayloadAction<string>) {
      state.slug = action.payload
      state.data = []
      state.isLoading = true
      state.isError = false
    },
    getChannelFontsSuccess(state, action: PayloadAction<ChannelFont[]>) {
      state.data = action.payload
      state.isLoading = false
      state.isError = false
    },
    getChannelFontsError(state, action: PayloadAction<any>) {
      state.error = action.payload
      state.isLoading = false
      state.isError = true
    }
  }
})

export const { getChannelFonts, getChannelFontsError, getChannelFontsSuccess } =
  channelFontsSlice.actions

export default channelFontsSlice.reducer
