import React, { FC, useState } from 'react'
import { CreateNewHeroImagePromotionButton } from './CreateNewPromotionButton/CreateNewPromotionButton'
import { NewHeroImageCallToActionModal } from './NewHeroImagePromotionModal/NewHeroImageCallToActionModal'
import { HeroImageCallToAction } from './NewHeroImagePromotionModal/Form/HeroImageCallToActionForm'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import {
  StatusMessage,
  StatusMessageType
} from 'pages/ChannelPage/ChannelSettings/StatusMessage/StatusMessage'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import { getHeroImageCallToAction } from 'api/customContent/getHeroImageCallToAction'
import { Loader } from 'components/Loader/Loader'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal'
import { deleteHeroImageCallToAction } from 'api/customContent/deleteHeroImageCallToAction'
import { HeroImageCallToActionAdminItem } from './HeroImageCallToActionAdminItem/HeroImageCallToActionAdminItem'
import styles from './HeroImageCallToActionSection.module.scss'

interface Props {
  channelSlug: string
}
export const HeroImageCallToActionSection: FC<Props> = ({ channelSlug }) => {
  const [showNewHeroImagePromotionModal, setShowNewHeroImagePromotionModal] =
    useState(false)
  const [showRemoveContentModal, setShowRemoveContentModal] = useState(false)
  const [removeIsLoading, setRemoveIsLoading] = useState(false)
  const [removeHasError, setRemoveHasError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleClose = () => {
    setShowNewHeroImagePromotionModal(false)
  }
  const {
    data,
    isLoading,
    isError,
    refetch
  }: {
    data: HeroImageCallToAction | undefined
    isLoading: boolean
    isError: boolean
    refetch: () => void
  } = useQuery({
    queryKey: ['get-hero-image-call-to-action-for-channel-admin'],

    queryFn: () =>
      getHeroImageCallToAction({
        channelSlug: channelSlug,
        disableCache: true
      }),

    retry: false
  })
  const existingForm: HeroImageCallToAction = {
    title: data?.title || '',
    text: data?.text || '',
    hasButton: data?.hasButton || false,
    textField: data?.textField || '',
    linkField: data?.linkField || '',
    target: data?.target || LinkTarget.CurrentWindow
  }
  const removeStartPageBanner = async () => {
    setRemoveIsLoading(true)
    const response = await deleteHeroImageCallToAction(channelSlug)
    if (response.hasError) {
      setRemoveIsLoading(false)
      setRemoveHasError(true)
      return null
    }
    setRemoveIsLoading(false)
    setShowRemoveContentModal(false)
    refetch()
  }
  const onSubmitSuccess = () => {
    setShowNewHeroImagePromotionModal(false)
    refetch()
    setShowSuccessMessage(true)
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return (
      <div>
        <Trans>
          Something went wrong when trying to retrive the data, please reload
          the page and try again
        </Trans>
      </div>
    )
  }
  return (
    <div className={styles.Container}>
      {data ? (
        <HeroImageCallToActionAdminItem
          channelSlug={channelSlug}
          heroImageCallToActionData={existingForm}
          onEdit={() => setShowNewHeroImagePromotionModal(true)}
          onRemove={() => setShowRemoveContentModal(true)}
        />
      ) : (
        <CreateNewHeroImagePromotionButton
          onClick={() => setShowNewHeroImagePromotionModal(true)}
        />
      )}
      {showNewHeroImagePromotionModal && (
        <NewHeroImageCallToActionModal
          defaultOpen={showNewHeroImagePromotionModal}
          channelSlug={channelSlug}
          existingForm={existingForm}
          onClose={handleClose}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      {showSuccessMessage && (
        <StatusMessage
          onClose={() => setShowSuccessMessage(false)}
          message={t`the hero image promotion has been successfully saved`}
          messageType={StatusMessageType.Success}
        />
      )}
      {showRemoveContentModal && (
        <ConfirmationModal
          modalHeader=""
          title={t`You are removing the hero image promotion`}
          subText={t`Are you sure that you would like to remove the hero image promotion from your channel?`}
          defaultOpen={showRemoveContentModal}
          isError={removeHasError}
          isLoading={removeIsLoading}
          errorMessage={t`something went wrong when trying to remove the hero image promotion, please try again.`}
          confirmButtonLabel={t`Yes`}
          cancelButtonLabel={t`No`}
          onClose={() => setShowRemoveContentModal(false)}
          onConfirm={removeStartPageBanner}
        />
      )}
    </div>
  )
}
