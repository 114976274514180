import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { articleData } from './articleData'

interface Props {
  title: string
  imageUrl?: string | null | undefined
}

export const ArticleMeta: FC<Props> = ({ title, imageUrl }) => {
  return (
    <Helmet prioritizeSeoTags>
      <script type="application/ld+json">{articleData(title, imageUrl)}</script>
    </Helmet>
  )
}
