import React, { FC } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import VideoLabel from './Labels/VideoLabel'
import LiveLabel from './Labels/LiveLabel'
import ReplayLabel from './Labels/ReplayLabel'
import Upcoming from './Labels/UpcomingLabel'
import HighlightLabel from './Labels/HighlightLabel'
import LiveReportingLabel from './Labels/LiveReportingLabel'
import styles from './ThumbnailLabel.module.scss'
import classNames from 'classnames'

interface Props {
  isLive: boolean
  isLiveReporting?: boolean
  isReplay: boolean
  highlightFromDate: number | null
  publishedAt: number
  startAt: number
  type: string
  externalStyle?: string | null
}
export const StreamLabel: FC<Props> = ({
  isLive,
  isReplay,
  isLiveReporting,
  highlightFromDate,
  publishedAt,
  startAt,
  type,
  externalStyle
}) => {
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  const isVideo = type === 'video'
  const todaysDate = dayjs(new Date()).unix()
  const isUpcoming = startAt > todaysDate

  const getLabel = () => {
    if (isLiveReporting) {
      return <LiveReportingLabel />
    }
    if (isLive && !isLiveReporting) {
      return <LiveLabel />
    }
    if (highlightFromDate) {
      return <HighlightLabel highlightFromDate={highlightFromDate} />
    }
    if (isReplay) {
      return <ReplayLabel startAt={startAt} />
    }
    if (isVideo && !highlightFromDate) {
      return <VideoLabel publishedAt={publishedAt} />
    }
    if (isUpcoming) {
      return <Upcoming startAt={startAt} />
    }
    return null
  }

  return (
    <div className={classNames(styles.LabelContent, externalStyle)}>
      {getLabel()}
    </div>
  )
}
