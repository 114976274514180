import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { mapBroadcastThumbnailResponse } from './mapBroadcastThumnbailFromResponse'

export const getCustomCategory = async ({
  slug,
  category,
  page,
  perPageCount
}: {
  category: string
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/from_category',
      params: {
        company: slug,
        per_page: perPageCount,
        page: page,
        category: category
      }
    })
    const responseData = await resultResponse
    const map = mapBroadcastThumbnailResponse(responseData.data)
    return { data: map, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
