import React, { FC } from 'react'

export const CircularAddIcon: FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="46.5"
      height="46.5"
      rx="23.25"
      fill="white"
    />
    <path
      d="M17.3333 24.0001H30.6667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 30.6667V17.3334"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="46.5"
      height="46.5"
      rx="23.25"
      strokeWidth="1.5"
    />
  </svg>
)
