import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { GameScheduleCard } from '../../cards/GameScheduleCard/GameScheduleCard'
import { GameScheduleCardType } from '../../cards/GameScheduleCard/gameScheduleType'
import styles from './GameScheduleContent.module.scss'

interface Props {
  gameScheduleContent: GameScheduleCardType[]
  isAdmin: boolean
  channelSlug: string
  channelType: ChannelTypes
}
export const GameScheduleContent: FC<Props> = ({
  gameScheduleContent,
  isAdmin,
  channelSlug,
  channelType
}) => (
  <div
    className={styles.GameScheduleContent}
    data-testid="game-schedule-content"
  >
    {gameScheduleContent && gameScheduleContent.length > 0 ? (
      gameScheduleContent.map((content) => (
        <GameScheduleCard
          key={content.id}
          content={content}
          isAdmin={isAdmin}
          channelSlug={channelSlug}
          channelType={channelType}
        />
      ))
    ) : (
      <div className={styles.NoGames}>
        <Trans>There are no games for this selection</Trans>
      </div>
    )}
  </div>
)
