/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDataApi } from '../../api/useDataApi'
import useInfiniteScroll from '../../hooks/InfiniteScroll'

interface ChannelPageHookProps {
  channelSlug: string
  perPageCount: number
  currentPage: number
  channelDataApiCall: (args: {
    slug: string
    perPageCount: number
    page: number
  }) => any
  onData: any
  setTotalPageCount: (totalCount: number) => void
}

export const useChannelPage = ({
  channelSlug,
  perPageCount,
  currentPage,
  channelDataApiCall,
  onData,
  setTotalPageCount
}: ChannelPageHookProps) => {
  const infiniteScrollRef = useRef(null)
  const isBottomReached = useInfiniteScroll(infiniteScrollRef)
  const [page, setPage] = useState(currentPage)
  const { isError, isLoading, data } = useDataApi({
    apiCall: useCallback(channelDataApiCall, [page]),
    parameters: useMemo(
      () => ({
        slug: channelSlug,
        page,
        perPageCount
      }),
      [page, channelSlug]
    )
  })

  useEffect(() => {
    setPage(currentPage)
    if (isBottomReached && data && data.totalCount > page * perPageCount) {
      setPage((prevValue) => prevValue + 1)
    }
  }, [isBottomReached, data, currentPage])

  useEffect(() => {
    if (!isLoading && !isError && data.data) {
      onData(data.data)
      setTotalPageCount(data.totalCount)
    }
  }, [isLoading, isError, data])

  return { infiniteScrollRef, isLoading, isError }
}
