import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
export const useCountdown = (
  countdownTo: number,
  format: string = 'HH:mm:ss'
) => {
  const calculateTimeLeft = (countdownTo: number) => {
    const diff = dayjs.unix(countdownTo).diff(dayjs())
    if (diff > 0) {
      return dayjs.duration(diff).format(format)
    }
    return ''
  }
  const [remainingTime, setRemainingTime] = useState<string>(
    calculateTimeLeft(countdownTo)
  )
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateTimeLeft(countdownTo))
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [countdownTo])
  return { remainingTime }
}
