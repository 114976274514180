import React, { FC, useState } from 'react'
import { BasicModal, ModalTheme } from 'components/Modals/BasicModal/BasicModal'
import { ResetPasswordContent } from './ResetPasswordContent'
import { SuccessContent } from 'components/Modals/SuccessModal/SuccessContent'
import { t } from '@lingui/macro'

interface Props {
  isOpen: boolean
  onLoginClick: () => void
  onClose: () => void
}

export const ResetPasswordModal: FC<Props> = ({
  isOpen,
  onLoginClick,
  onClose
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const modalContent = showSuccessMessage ? (
    <SuccessContent
      title={t`You new password is updated!`}
      onButtonClick={onClose}
      subText={''}
      buttonLabel={'Continue'}
    />
  ) : (
    <ResetPasswordContent
      onLoginClick={onLoginClick}
      onFormSuccess={() => setShowSuccessMessage(true)}
    />
  )
  return (
    <BasicModal
      isOpen={isOpen}
      handleClose={onClose}
      firstContainerContent={modalContent}
      firstContainerTheme={ModalTheme.Primary}
    />
  )
}
