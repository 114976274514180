import React, { FC } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Trans } from '@lingui/macro'
import superAdminImage from '../../../../../assets/images/solidsport-logo-86x86.png'
import DefaultUserImage from '../../../../../assets/images/default_user_image.png'

import styles from './CommentBubble.module.scss'
import useAdminAccess from 'hooks/AdminAccess'
import { getDecodedHTMLContent } from 'features/viewerPage/helpFunctions/getDecodedHTMLContent'

interface Props {
  ident: string
  userName: string
  userImgPath: string
  text: string
  isAdmin: boolean
  isSuperAdmin: boolean
  publishedTime: number
  onDelete: (ident: string) => void
}
export const CommentBubble: FC<Props> = ({
  ident,
  userName,
  userImgPath,
  text,
  isAdmin,
  isSuperAdmin,
  publishedTime,
  onDelete
}) => {
  const userImage = isSuperAdmin
    ? superAdminImage
    : userImgPath || DefaultUserImage

  const adminOrUser = isAdmin ? `${userName} (Admin)` : userName
  const displayedUserName = isSuperAdmin ? 'Solidsport' : adminOrUser
  const renderTimeStamp = () => {
    dayjs.extend(relativeTime)
    return dayjs.unix(publishedTime).fromNow()
  }
  const adminAccess = useAdminAccess()

  const showDeleteButton = adminAccess.hasChannelAccess
  return (
    <div className={styles.CommentBubbleContainer} data-testid="comment-bubble">
      <img
        className={styles.UserImage}
        src={userImage}
        alt={displayedUserName}
      />
      <div className={styles.UserNameAndTextBox}>
        <div className={styles.UserName} data-testid="comment-user-name">
          {displayedUserName}
        </div>
        <div className={styles.CommentBoxContainer}>
          <div className={styles.Triangle} />
          <div className={styles.CommentText} data-testid="comment-text">
            {getDecodedHTMLContent(text)}
            <div className={styles.TimeStamp}>{renderTimeStamp()}</div>
          </div>
        </div>
        {showDeleteButton && (
          <div
            className={styles.AdminFunctionality}
            data-testid="comment-delete-button-container"
          >
            <div
              className={styles.DeleteButton}
              role="button"
              onClick={() => onDelete(ident)}
              onKeyDown={() => onDelete(ident)}
              tabIndex={0}
            >
              <Trans> Delete</Trans>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
