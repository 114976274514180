import React from 'react'

const EyeIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2084 5.07677C8.17741 5.00877 4.00842 7.82577 1.38742 10.7108C1.1123 11.0162 0.960052 11.4127 0.960052 11.8238C0.960052 12.2348 1.1123 12.6313 1.38742 12.9368C3.95141 15.7608 8.10842 18.6428 12.2084 18.5738C16.3084 18.6428 20.4664 15.7608 23.0324 12.9368C23.3075 12.6313 23.4598 12.2348 23.4598 11.8238C23.4598 11.4127 23.3075 11.0162 23.0324 10.7108C20.4084 7.82577 16.2394 5.00877 12.2084 5.07677Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9584 11.8257C15.9582 12.5674 15.7381 13.2923 15.3259 13.9089C14.9138 14.5254 14.328 15.0059 13.6428 15.2896C12.9575 15.5732 12.2036 15.6474 11.4762 15.5026C10.7488 15.3578 10.0807 15.0005 9.5564 14.476C9.03205 13.9515 8.675 13.2834 8.53039 12.556C8.38577 11.8286 8.4601 11.0746 8.74395 10.3894C9.02781 9.70428 9.50846 9.11867 10.1251 8.70666C10.7418 8.29465 11.4668 8.07474 12.2084 8.07474C12.701 8.07461 13.1888 8.17155 13.6439 8.36002C14.099 8.54849 14.5124 8.8248 14.8607 9.17315C15.209 9.5215 15.4852 9.93507 15.6735 10.3902C15.8619 10.8454 15.9587 11.3332 15.9584 11.8257Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EyeIcon
