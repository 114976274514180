import React, { FC, useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'

import SettingsIcon from '../Images/SettingsIcon'
import EditIcon from '../Images/EditIcon'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'

import { useDataApi } from '../../../../api/useDataApi'
import { getIsAnyGameScheduleContent } from './getIsAnyGameScheduleContent'
import { ChannelTypes } from '../../channelType'
import {
  triggerGTMForContentManagerButton,
  triggerGTMForSettingsButton
} from './gtmForAdminPanel'

import styles from './adminPanel.module.scss'

interface Props {
  channelSlug: string
}

const AdminPanel: FC<Props> = ({ channelSlug }) => {
  const channel = useSelector(channelSelector)
  const channelName = channel.data?.name
  const channelType = channel.data?.type

  const {
    data: isAnyGameScheduleContentAvailable,
    isLoading,
    isError
  } = useDataApi({
    apiCall: useCallback(getIsAnyGameScheduleContent, []),
    parameters: useMemo(
      () => ({
        channelSlug,
        channelType
      }),
      [channelSlug, channelType]
    )
  })
  const hasActiveIntegration =
    !isLoading && !isError && isAnyGameScheduleContentAvailable
  const showContentManager = true
  const openSettings = () => {
    triggerGTMForSettingsButton(channelName, channelSlug, channelType)
  }
  const getContentManagerHref = () => {
    if (hasActiveIntegration) {
      if ([ChannelTypes.team, ChannelTypes.league].includes(channelType))
        return `/${channelSlug}/contentmanager/competitions`
      if ([ChannelTypes.club].includes(channelType))
        return `/${channelSlug}/contentmanager/teams`
    }
    return `/${channelSlug}/contentmanager`
  }

  const openContentManager = () => {
    triggerGTMForContentManagerButton(channelName, channelSlug, channelType)
  }
  return (
    <>
      <div
        className={styles.AdminPanelContainer}
        data-testid="admin-panel-container"
      >
        <LinkIconButton
          id="settings"
          label={t`Settings`}
          type={ButtonType.Ghost}
          buttonSize="Large"
          href={`/${channelSlug}/settings/channel`}
          linkOnClick={openSettings}
        >
          <SettingsIcon />
        </LinkIconButton>
        {showContentManager && (
          <LinkIconButton
            id="contentManager"
            label={t`Content manager`}
            type={ButtonType.Ghost}
            buttonSize="Large"
            href={getContentManagerHref()}
            linkOnClick={() => openContentManager()}
          >
            <EditIcon />
          </LinkIconButton>
        )}
      </div>
    </>
  )
}
export default AdminPanel
