import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { PhoneNumberField } from 'components/Forms/PhoneNumberField/PhoneNumberField'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { patchName } from 'api/user/patchName'
import { patchEmail } from 'api/user/patchEmail'
import { Country } from 'api/countries/getCountriesList'
import { patchUserPhoneNumber } from 'api/user/patchUserPhoneNumber'
import { useDispatch } from 'react-redux'
import userActions from 'redux-store/user/user.actions'
import useCurrentUser from 'hooks/CurrentUser'
import { contactDetailsValidationSchema } from './ContactDetailsValidationSchema'
import { FormSectionTitle } from 'components/Forms/FormSectionTitle/FormSectionTitle'
import styles from './ContactDetailsForm.module.scss'

export interface ContactDetailsValues {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  countryCode: string
}

export const ContactDetailsForm: FC = () => {
  const dispatch = useDispatch()

  const currentUser = useCurrentUser()
  const initialValues: ContactDetailsValues = {
    firstName: currentUser?.firstname || '',
    lastName: currentUser?.lastname || '',
    email: currentUser?.email || '',
    phoneNumber: currentUser?.phone_number || '',
    countryCode: currentUser?.area_code || ''
  }
  const [initialValue, setInititalvalue] = useState(initialValues)
  useEffect(() => {
    if (currentUser) {
      setInititalvalue({
        firstName: currentUser?.firstname,
        lastName: currentUser?.lastname,
        email: currentUser?.email,
        phoneNumber: currentUser?.phone_number || '',
        countryCode: currentUser?.area_code || ''
      })
    }
  }, [currentUser])

  const onSubmit = async (
    values: ContactDetailsValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const { firstName, lastName, email, phoneNumber, countryCode } = values

    if (
      firstName !== currentUser?.firstname ||
      lastName !== currentUser.lastname
    ) {
      const response = await patchName(firstName, lastName)
      if (!response.isSuccess) {
        formikHelpers.setFieldError(
          firstName,
          t`Something went wrong when trying to change the name`
        )
      } else {
        dispatch(userActions.updateUser())
      }
    }
    if (email !== currentUser?.email) {
      const response = await patchEmail(email)
      if (!response.isSuccess) {
        formikHelpers.setFieldError(email, response.message)
      } else {
        dispatch(userActions.updateUser())
      }
    }

    if (
      phoneNumber !== currentUser?.phone_number ||
      countryCode !== currentUser.area_code
    ) {
      const response = await patchUserPhoneNumber({
        phoneNumber: values.phoneNumber,
        countryCode: values.countryCode
      })
      if (!response.isSuccess) {
        formikHelpers.setFieldError(phoneNumber, response.message)
      } else {
        dispatch(userActions.updateUser())
      }
    }

    formikHelpers.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange
      validationSchema={contactDetailsValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div
              className={styles.FormContainer}
              data-testid="contact-details-form"
            >
              <FormSectionTitle title={t`Contact details`} />
              <div className={styles.FieldsContainer}>
                <Field
                  component={TextInput}
                  id="firstName"
                  name="firstName"
                  label={t`First name`}
                  placeholder={t`Write your first name here`}
                  onChange={formik.handleChange}
                  textValue={formik.values.firstName}
                  isValid={!formik.errors.firstName}
                  validationMessage={formik.errors.firstName}
                  isRequired
                  customStyle={styles.TextInputFields}
                />
                <Field
                  component={TextInput}
                  id="lastName"
                  name="lastName"
                  label={t`Last name`}
                  placeholder={t`Write your last name here`}
                  onChange={formik.handleChange}
                  textValue={formik.values.lastName}
                  isValid={!formik.errors.lastName}
                  validationMessage={formik.errors.lastName}
                  isRequired
                  customStyle={styles.TextInputFields}
                />
              </div>
              <div className={styles.FieldsContainer}>
                <Field
                  component={TextInput}
                  id="email"
                  name="email"
                  label={t`Email`}
                  placeholder={t`Write your email here`}
                  onChange={formik.handleChange}
                  textValue={formik.values.email}
                  isValid={!formik.errors.email}
                  validationMessage={formik.errors.email}
                  isRequired
                  customStyle={styles.TextInputFields}
                />
                <div className={styles.PhoneNumberField}>
                  <PhoneNumberField
                    currentUser={currentUser}
                    label={t`Phone number`}
                    id={'phoneNumber'}
                    placeholder={t`Add your phone number here`}
                    onCountrySelected={(country: Country) => {
                      formik.setFieldValue('countryCode', country.countryCode)
                      formik.setFieldTouched('country', true, false)
                    }}
                  />
                </div>
              </div>
              {/* implement this when api for it exist */}
              {/* <ToggleSwitch
                onToggle={() => null}
                isOn={currentUser?.phone_number !== null}
                id={'toggleNotification'}
                label={t`Recieve sms notifications for important streams and events`}
              /> */}
              <div className={styles.ButtonContainer}>
                <SubmitButton
                  type="secondary"
                  customStyle={styles.SubmitButton}
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                  label={t`Save changes`}
                  isSubmitting={formik.isSubmitting}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
