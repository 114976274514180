/* eslint-disable @typescript-eslint/indent */
import { FeatureFlagsAvailable } from './featureFlags/FeatureFlagsAvailable'
import { getCustomChannelUrl } from './getCustomChannelUrl'

export const determineClientUrlAndRedirectIfNeeded = async (
  getFlag: (flag: FeatureFlagsAvailable) => boolean,
  setFlag: (flag: FeatureFlagsAvailable, value: boolean) => void
): Promise<string | undefined> => {
  const now = Date.now() / 1000

  const redirect = await getCustomChannelUrl(
    window.location,
    now,
    getFlag,
    setFlag
  ).catch((error: any) => {
    console.log('getCustomChannelUrl', error)
  })

  if (redirect && redirect.url && redirect.url !== window.location.href) {
    window.location.href = redirect.url
  }

  // return redirect
  return undefined
}
