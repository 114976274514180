import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

interface UploadImage {
  imageFile: File
}

export const postUserImage = async ({ imageFile }: UploadImage) => {
  try {
    const formData = new FormData()
    formData.append('image', imageFile)
    const response: ApiResponse = await ApiEndpoint.call({
      path: 'users/edit/profile_picture',
      method: ApiEndpoint.METHODS.POST,
      authenticatable: true,
      file: formData
    })
    if (response.status === 204) {
      return { isSuccess: true }
    }
    return { isSuccess: false }
  } catch (error: any) {
    if (error?.status === 413 && error?.message) {
      return { isSuccess: false, message: t`image file is to big` }
    }
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
