import React, { FC } from 'react'
import { LoginModal } from '../Login/LoginModal'
import { SignupModal } from '../Signup/SignupModal'
import { ForgotPasswordModal } from '../ForgotPassword/ForgotPasswordModal'
import { ResetPasswordModal } from '../ResetPassword/ResetPasswordModal'

export type LoginLayerType =
  | 'login'
  | 'signup'
  | 'forgotPassword'
  | 'resetPassword'
  | string
  | undefined
interface Props {
  readyCallback: () => void
  switchModeCallback: (loginLayerType: LoginLayerType) => void
  isLoginLayerOpen: boolean
  loginLayerType: LoginLayerType
}

export const ModalContent: FC<Props> = ({
  readyCallback,
  switchModeCallback,
  isLoginLayerOpen,
  loginLayerType
}) => {
  const getUserSessionContent = () => {
    switch (loginLayerType) {
      case 'login':
        return (
          <LoginModal
            isOpen={isLoginLayerOpen}
            onClose={readyCallback}
            onForgotPasswordClick={() => switchModeCallback('forgotPassword')}
            onSignupClick={() => switchModeCallback('signup')}
            onFormSuccess={() => readyCallback()}
          />
        )
      case 'signup':
        return (
          <SignupModal
            isOpen={isLoginLayerOpen}
            onClose={readyCallback}
            onLoginClick={() => switchModeCallback('login')}
            onAllFormSuccess={readyCallback}
          />
        )
      case 'forgotPassword':
        return (
          <ForgotPasswordModal
            isOpen={isLoginLayerOpen}
            onLoginClick={() => switchModeCallback('login')}
            onSignupClick={() => switchModeCallback('signup')}
            onSubmitFormSuccess={readyCallback}
            onClose={readyCallback}
          />
        )
      case 'resetPassword':
        return (
          <ResetPasswordModal
            isOpen={isLoginLayerOpen}
            onLoginClick={() => switchModeCallback('login')}
            onClose={readyCallback}
          />
        )
      default:
        return (
          <LoginModal
            isOpen={isLoginLayerOpen}
            onClose={readyCallback}
            onForgotPasswordClick={() => switchModeCallback('forgotPassword')}
            onSignupClick={() => switchModeCallback('signup')}
            onFormSuccess={readyCallback}
          />
        )
    }
  }
  return <>{isLoginLayerOpen && getUserSessionContent()}</>
}
