import React from 'react'

export const CustomUrlIcon = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4857 16.2175C27.2297 16.2175 26.9795 16.1416 26.7666 15.9993C26.5538 15.8571 26.3879 15.655 26.2899 15.4185C26.192 15.182 26.1663 14.9217 26.2163 14.6707C26.2662 14.4196 26.3895 14.189 26.5705 14.008C26.7515 13.827 26.9821 13.7037 27.2332 13.6538C27.4843 13.6038 27.7445 13.6295 27.981 13.7274C28.2175 13.8254 28.4196 13.9913 28.5618 14.2041C28.7041 14.417 28.78 14.6672 28.78 14.9232C28.78 15.2664 28.6436 15.5957 28.4009 15.8384C28.1582 16.0811 27.8289 16.2175 27.4857 16.2175"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.807 16.2175C19.551 16.2175 19.3008 16.1416 19.0879 15.9993C18.8751 15.8571 18.7092 15.655 18.6112 15.4185C18.5133 15.182 18.4876 14.9217 18.5376 14.6707C18.5875 14.4196 18.7108 14.189 18.8918 14.008C19.0728 13.827 19.3034 13.7037 19.5545 13.6538C19.8055 13.6038 20.0658 13.6295 20.3023 13.7274C20.5388 13.8254 20.7409 13.9913 20.8831 14.2041C21.0253 14.417 21.1013 14.6672 21.1013 14.9232C21.1013 15.2664 20.9649 15.5957 20.7222 15.8384C20.4794 16.0811 20.1502 16.2175 19.807 16.2175"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6273 8.08856C31.3713 8.08856 31.1211 8.01265 30.9082 7.87043C30.6954 7.72821 30.5295 7.52608 30.4315 7.28958C30.3336 7.05308 30.3079 6.79284 30.3579 6.54178C30.4078 6.29071 30.5311 6.06009 30.7121 5.87909C30.8931 5.69808 31.1237 5.57481 31.3748 5.52487C31.6259 5.47493 31.8861 5.50056 32.1226 5.59852C32.3591 5.69648 32.5612 5.86237 32.7034 6.07522C32.8457 6.28806 32.9216 6.53829 32.9216 6.79428C32.9216 7.13754 32.7852 7.46675 32.5425 7.70947C32.2998 7.9522 31.9706 8.08856 31.6273 8.08856Z"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4242 8.08856C16.1682 8.08856 15.9179 8.01265 15.7051 7.87043C15.4923 7.72821 15.3264 7.52608 15.2284 7.28958C15.1304 7.05308 15.1048 6.79284 15.1548 6.54178C15.2047 6.29071 15.328 6.06009 15.509 5.87909C15.69 5.69808 15.9206 5.57481 16.1717 5.52487C16.4227 5.47493 16.683 5.50056 16.9195 5.59852C17.156 5.69648 17.3581 5.86237 17.5003 6.07522C17.6425 6.28806 17.7184 6.53829 17.7184 6.79428C17.7184 7.13754 17.5821 7.46675 17.3394 7.70947C17.0966 7.9522 16.7674 8.08856 16.4242 8.08856Z"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6273 43.5007C31.3713 43.5007 31.1211 43.4248 30.9082 43.2825C30.6954 43.1403 30.5295 42.9382 30.4315 42.7017C30.3336 42.4652 30.3079 42.2049 30.3579 41.9539C30.4078 41.7028 30.5311 41.4722 30.7121 41.2912C30.8931 41.1102 31.1237 40.9869 31.3748 40.937C31.6259 40.887 31.8861 40.9127 32.1226 41.0106C32.3591 41.1086 32.5612 41.2745 32.7034 41.4873C32.8457 41.7002 32.9216 41.9504 32.9216 42.2064C32.9216 42.5497 32.7852 42.8789 32.5425 43.1216C32.2998 43.3643 31.9706 43.5007 31.6273 43.5007Z"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4242 43.5007C16.1682 43.5007 15.9179 43.4248 15.7051 43.2825C15.4923 43.1403 15.3264 42.9382 15.2284 42.7017C15.1304 42.4652 15.1048 42.2049 15.1548 41.9539C15.2047 41.7028 15.328 41.4722 15.509 41.2912C15.69 41.1102 15.9206 40.9869 16.1717 40.937C16.4227 40.887 16.683 40.9127 16.9195 41.0106C17.156 41.1086 17.3581 41.2745 17.5003 41.4873C17.6425 41.7002 17.7184 41.9504 17.7184 42.2064C17.7184 42.5497 17.5821 42.8789 17.3394 43.1216C17.0966 43.3643 16.7674 43.5007 16.4242 43.5007Z"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.7575 33.4045C41.5002 33.4114 41.2466 33.3413 41.0293 33.2034C40.8119 33.0654 40.6407 32.8657 40.5374 32.6299C40.4342 32.3941 40.4035 32.1328 40.4495 31.8795C40.4955 31.6263 40.616 31.3924 40.7955 31.2079C40.9751 31.0235 41.2056 30.8967 41.4575 30.8439C41.7095 30.7911 41.9715 30.8147 42.21 30.9115C42.4485 31.0084 42.6527 31.1742 42.7965 31.3877C42.9403 31.6012 43.0172 31.8528 43.0173 32.1102C43.0173 32.4535 42.8809 32.7827 42.6382 33.0254C42.3955 33.2681 42.0663 33.4045 41.723 33.4045"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.7575 18.219C41.5002 18.2258 41.2466 18.1558 41.0293 18.0178C40.8119 17.8798 40.6407 17.6802 40.5374 17.4444C40.4342 17.2085 40.4035 16.9473 40.4495 16.694C40.4955 16.4407 40.616 16.2069 40.7955 16.0224C40.9751 15.8379 41.2056 15.7112 41.4575 15.6584C41.7095 15.6056 41.9715 15.6291 42.21 15.726C42.4485 15.8228 42.6527 15.9886 42.7965 16.2022C42.9403 16.4157 43.0172 16.6672 43.0173 16.9247C43.0173 17.2679 42.8809 17.5971 42.6382 17.8399C42.3955 18.0826 42.0663 18.219 41.723 18.219"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.29428 33.405C6.03829 33.405 5.78806 33.3291 5.57522 33.1868C5.36237 33.0446 5.19648 32.8425 5.09852 32.606C5.00056 32.3695 4.97493 32.1093 5.02487 31.8582C5.07481 31.6071 5.19808 31.3765 5.37909 31.1955C5.56009 31.0145 5.79071 30.8912 6.04178 30.8413C6.29284 30.7913 6.55308 30.817 6.78958 30.9149C7.02608 31.0129 7.22821 31.1788 7.37043 31.3916C7.51265 31.6045 7.58856 31.8547 7.58856 32.1107C7.58856 32.4539 7.4522 32.7832 7.20947 33.0259C6.96675 33.2686 6.63754 33.405 6.29428 33.405"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.29428 18.2175C6.03829 18.2175 5.78806 18.1416 5.57522 17.9993C5.36237 17.8571 5.19648 17.655 5.09852 17.4185C5.00056 17.182 4.97493 16.9218 5.02487 16.6707C5.07481 16.4196 5.19808 16.189 5.37909 16.008C5.56009 15.827 5.79071 15.7037 6.04178 15.6538C6.29284 15.6038 6.55308 15.6295 6.78958 15.7274C7.02608 15.8254 7.22821 15.9913 7.37043 16.2041C7.51265 16.417 7.58856 16.6672 7.58856 16.9232C7.58856 17.2664 7.4522 17.5957 7.20947 17.8384C6.96675 18.0811 6.63754 18.2175 6.29428 18.2175"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.4857 34.7858C27.2297 34.7858 26.9795 34.7099 26.7666 34.5677C26.5538 34.4255 26.3879 34.2233 26.2899 33.9868C26.192 33.7503 26.1663 33.4901 26.2163 33.239C26.2662 32.988 26.3895 32.7574 26.5705 32.5764C26.7515 32.3953 26.9821 32.2721 27.2332 32.2221C27.4843 32.1722 27.7445 32.1978 27.981 32.2958C28.2175 32.3937 28.4196 32.5596 28.5618 32.7725C28.7041 32.9853 28.78 33.2356 28.78 33.4915C28.78 33.8348 28.6436 34.164 28.4009 34.4067C28.1582 34.6495 27.8289 34.7858 27.4857 34.7858"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.807 34.7858C19.551 34.7858 19.3008 34.7099 19.0879 34.5677C18.8751 34.4255 18.7092 34.2233 18.6112 33.9868C18.5133 33.7503 18.4876 33.4901 18.5376 33.239C18.5875 32.988 18.7108 32.7574 18.8918 32.5764C19.0728 32.3953 19.3034 32.2721 19.5545 32.2221C19.8055 32.1722 20.0658 32.1978 20.3023 32.2958C20.5388 32.3937 20.7409 32.5596 20.8831 32.7725C21.0253 32.9853 21.1013 33.2356 21.1013 33.4915C21.1013 33.8348 20.9649 34.164 20.7222 34.4067C20.4794 34.6495 20.1502 34.7858 19.807 34.7858"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8871 29.2975C32.6311 29.2975 32.3808 29.2216 32.168 29.0794C31.9551 28.9372 31.7893 28.7351 31.6913 28.4986C31.5933 28.2621 31.5677 28.0018 31.6176 27.7508C31.6676 27.4997 31.7909 27.2691 31.9719 27.0881C32.1529 26.9071 32.3835 26.7838 32.6346 26.7339C32.8856 26.6839 33.1459 26.7095 33.3824 26.8075C33.6189 26.9055 33.821 27.0714 33.9632 27.2842C34.1054 27.497 34.1813 27.7473 34.1813 28.0033C34.1813 28.3465 34.045 28.6757 33.8022 28.9185C33.5595 29.1612 33.2303 29.2975 32.8871 29.2975"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8871 21.7038C32.6311 21.7038 32.3808 21.6279 32.168 21.4857C31.9551 21.3434 31.7893 21.1413 31.6913 20.9048C31.5933 20.6683 31.5677 20.4081 31.6176 20.157C31.6676 19.9059 31.7909 19.6753 31.9719 19.4943C32.1529 19.3133 32.3835 19.19 32.6346 19.1401C32.8856 19.0902 33.1459 19.1158 33.3824 19.2138C33.6189 19.3117 33.821 19.4776 33.9632 19.6905C34.1054 19.9033 34.1813 20.1535 34.1813 20.4095C34.1813 20.7528 34.045 21.082 33.8022 21.3247C33.5595 21.5674 33.2303 21.7038 32.8871 21.7038"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3192 29.2975C14.0647 29.2974 13.8159 29.2223 13.6039 29.0816C13.3918 28.9408 13.226 28.7407 13.1271 28.5062C13.0282 28.2717 13.0006 28.0133 13.0479 27.7632C13.0951 27.5131 13.2149 27.2825 13.3925 27.1002C13.5701 26.9179 13.7975 26.792 14.0462 26.7382C14.295 26.6844 14.554 26.7052 14.7911 26.7979C15.0281 26.8906 15.2325 27.0511 15.3788 27.2593C15.5251 27.4676 15.6067 27.7143 15.6135 27.9687C15.6135 28.312 15.4771 28.6412 15.2344 28.8839C14.9917 29.1267 14.6625 29.263 14.3192 29.263"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3187 21.7038C14.0627 21.7038 13.8125 21.6279 13.5996 21.4857C13.3868 21.3434 13.2209 21.1413 13.1229 20.9048C13.025 20.6683 12.9993 20.4081 13.0493 20.157C13.0992 19.9059 13.2225 19.6753 13.4035 19.4943C13.5845 19.3133 13.8151 19.19 14.0662 19.1401C14.3173 19.0902 14.5775 19.1158 14.814 19.2138C15.0505 19.3117 15.2526 19.4776 15.3948 19.6905C15.5371 19.9033 15.613 20.1535 15.613 20.4095C15.613 20.7528 15.4766 21.082 15.2339 21.3247C14.9912 21.5674 14.662 21.7038 14.3187 21.7038"
      stroke="#2F3135"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
