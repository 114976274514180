interface GetPage {
  currentPage: number
  pageSize: number
  data: unknown[]
}

export const getPageToDisplay = ({
  currentPage,
  pageSize,
  data
}: GetPage): unknown[] => {
  const startIndex = currentPage === 1 ? 0 : (currentPage - 1) * pageSize
  const maxIndex = startIndex + pageSize
  const endIndex = maxIndex > data.length ? data.length : maxIndex

  const page = data.slice(startIndex, endIndex)
  return page
}
