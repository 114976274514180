import React from 'react'
import { Trans } from '@lingui/macro'

import styles from './GameScheduleButtons.module.scss'

export const NoBroadcastLabel = () => (
  <div className={styles.NoBroadcastLabel} data-testid="no-broadcast-label">
    <Trans>No broadcast</Trans>
  </div>
)
