export const getPagesToDisplay = (
  currentPage: number,
  total: number,
  maxToDisplay: number
): { page: number; isEllipses: boolean }[] => {
  const pages = Array.from({ length: total }, (v, k) => k + 1)

  if (total <= maxToDisplay) {
    return pages.map((page) => ({ page, isEllipses: false }))
  }
  const groupPages = Math.floor(maxToDisplay / 3) + 1

  // render the last x pages
  const lastGroup = pages
    .slice(total - groupPages, total)
    .map((page) => ({ page, isEllipses: false }))

  const isInLastPageGroup = lastGroup.find((page) => page.page === currentPage)
  // render the first x pages
  const firstGroup = pages
    .slice(0, groupPages)
    .map((page) => ({ page, isEllipses: false }))

  const isInFirstPageGroup = firstGroup.find(
    (page) => page.page === currentPage
  )
  // render two sets of elipses
  const isInMiddle = !isInLastPageGroup && !isInFirstPageGroup

  if (isInMiddle) {
    const firstPageOfLastGroup = lastGroup[0].page

    const endPageIndex = Math.min(
      currentPage - 1 + maxToDisplay - 4,
      firstPageOfLastGroup - 1
    )
    const startPageIndex =
      maxToDisplay === 7 ? endPageIndex - 3 : endPageIndex - 5

    const middleGroup = pages
      .slice(startPageIndex, endPageIndex)
      .map((page) => ({ page, isEllipses: false }))

    return [
      ...firstGroup.slice(0, 1),
      {
        page: 2,
        isEllipses: true
      },
      ...middleGroup,
      {
        page: pages.length - 2,
        isEllipses: true
      },
      ...lastGroup.slice(lastGroup.length - 1)
    ]
  } else {
    return [
      ...firstGroup,
      {
        page: isInFirstPageGroup ? groupPages + 1 : total - groupPages - 1,
        isEllipses: true
      },
      ...lastGroup
    ]
  }
}
