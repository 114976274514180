import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import {
  sportsErrorSelector,
  sportsLoadingSelector,
  top10SportsListSelector
} from '../../../redux-store/sports/sports.selectors'
import TallImagePreloader from '../../../components/preloaders/TallImagePreloader'
import TallThumbnail from '../../../components/cards/TallThumbnail/TallThumbnail'
import { Sport } from '../../../components/cards/TallThumbnail/cardTypes'
import ContentSlider from '../../../components/sliders/ContentSlider'
import ThumbnailSectionHeader from '../../../components/Headers/ThumbnailSectionHeader'
import styles from './SportSection.module.scss'

const SportsSection: FC = () => {
  const sportList = useSelector(top10SportsListSelector)
  const isLoadingSportData = useSelector(sportsLoadingSelector)
  const isSportDataError = useSelector(sportsErrorSelector)
  return (
    <div className={styles.SportSection} data-testid="sports-section-container">
      <ThumbnailSectionHeader
        label={t`Our Sports`}
        linkPath="/link"
        linkLabel={t`All sports`}
      />
      <div>
        {isLoadingSportData ? (
          <TallImagePreloader />
        ) : (
          sportList &&
          sportList.length > 0 &&
          !isSportDataError && (
            <div>
              <ContentSlider leftPadding>
                {sportList.map((card: Sport) => (
                  <TallThumbnail content={card as Sport} key={card.id} />
                ))}
              </ContentSlider>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default memo(SportsSection)
