import React, { FC } from 'react'
import { Field } from 'formik'
import { t, Trans } from '@lingui/macro'
import { TextInput } from '../../../../components/Forms/TextInputField/TextInput'
import { TimezoneDropdown } from './TimezoneDropdown'

import styles from './OverviewFormFields.module.scss'

interface Props {
  formik: any
}

export const OverviewFormFields: FC<Props> = ({ formik }) => (
  <div className={styles.OverviewFormFieldsContainer}>
    <h4>
      <Trans>Information</Trans>
    </h4>
    <div className={styles.InformationContainer}>
      <Field
        component={TextInput}
        id="title"
        name="title"
        label={t`Channel title`}
        placeholder={t`Channel title`}
        onChange={formik.handleChange}
        textValue={formik.values.title}
        isValid={true}
        validationMessage={formik.errors.title}
        isRequired
      />
      <Field
        component={TextInput}
        id="url"
        name="url"
        label={t`Channel url`}
        placeholder={t`Channel url`}
        onChange={formik.handleChange}
        textValue={formik.values.url}
        isValid={true}
        validationMessage={formik.errors.url}
        readonly
      />
      <Field
        component={TimezoneDropdown}
        id="timezone"
        name="timezone"
        label={t`Channel timezone`}
        placeholder={t`please select the channel timezone`}
        textValue={formik.values.timezone}
        isValid={!formik.errors.timezone}
        validationMessage={formik.errors.timezone}
        isRequired
        value={formik.values.timezone}
        type="dark"
        onChange={(id: string) => {
          formik.setFieldValue('timezone', id)
        }}
      />
    </div>
  </div>
)
