export interface ProductTypeInterface {
  label: string
  id: string
}
export enum ProductTypeLabel {
  Default = 'Default',
  PoweredBy = 'PoweredBy'
}
export enum ProductTypeID {
  Default = '0',
  PoweredBy = '2'
}
export const ProductTypeList: ProductTypeInterface[] = [
  {
    label: ProductTypeLabel.Default,
    id: ProductTypeID.Default
  },
  {
    label: ProductTypeLabel.PoweredBy,
    id: ProductTypeID.PoweredBy
  }
]
