import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { getClubsForAssociation } from '../../../api/channel/getClubsForAssociation'
import { getClubsForDistrict } from '../../../api/channel/getClubsForDistrict'
import { ChannelListItem } from '../../../components/lists/ChannelListItem/ChannelListItem'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { useChannelPage } from '../useChannelPage'
import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { getChannelLinkMapper } from '../../../components/lists/ChannelListItem/getChannelLink'
import { ChannelTypes } from '../channelType'

interface Props {
  channelSlug: string
  isAdmin: boolean
  channelType: ChannelTypes
}
const Clubs: FC<Props> = ({ channelSlug, isAdmin, channelType }) => {
  const [clubs, setClubs] = useState<AssociationClubEventDistrict[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const PER_PAGE_COUNT = 30
  const [page, setPage] = useState(1)
  const getClubsApiCall =
    channelType === ChannelTypes.association
      ? getClubsForAssociation
      : getClubsForDistrict

  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getClubsApiCall,
    onData: (data: any) => setClubs((prev) => [...prev, ...data]),
    setTotalPageCount: (total: number) => setTotalPages(total / PER_PAGE_COUNT)
  })

  const isEmpty = !clubs?.length
  const emptyMessage = t`There are no clubs currently registered for this association`
  const channelLinkMapper = getChannelLinkMapper('clubs', channelType)
  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }
  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {clubs?.map((club: AssociationClubEventDistrict) => (
        <ChannelListItem
          key={club?.subdomain}
          channelLink={channelLinkMapper(club)}
          showAdmin={isAdmin}
        />
      ))}
    </ChannelPageList>
  )
}

export default Clubs
