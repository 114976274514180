import React, { FC } from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { TextInput } from 'components/Forms/TextInputField/TextInput'
import { t } from '@lingui/macro'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import styles from './Form.module.scss'
import { step1ValidationSchema } from './SignupValidationSchemas'
interface SignupStep1Values {
  firstName: string
  lastName: string
  email: string
  confirmEmail: string
}
interface Props {
  initialValues: SignupStep1Values
  onSubmitForm: (firstName: string, lastName: string, email: string) => void
}

export const SignupStep1Form: FC<Props> = ({ initialValues, onSubmitForm }) => {
  const onSubmit = async (
    values: SignupStep1Values,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    const firstName = values.firstName
    const lastName = values.lastName
    const email = values.email
    onSubmitForm(firstName, lastName, email)
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={step1ValidationSchema}
    >
      {(formik: FormikProps<any>) => {
        return (
          <Form>
            <div className={styles.FormContainer}>
              <div className={styles.FormFields}>
                <Field
                  component={TextInput}
                  id="firstName"
                  data-testid="first-name-input"
                  name="firstName"
                  placeholder={t`First name`}
                  onChange={formik.handleChange}
                  textValue={formik.values.firstName}
                  isValid={!formik.errors.firstName}
                  validationMessage={formik.errors.firstName}
                />
                <Field
                  component={TextInput}
                  id="lastName"
                  data-testid="last-name-input"
                  name="lastName"
                  placeholder={t`Last name`}
                  onChange={formik.handleChange}
                  textValue={formik.values.lastName}
                  isValid={!formik.errors.lastName}
                  validationMessage={formik.errors.lastName}
                />
                <Field
                  component={TextInput}
                  id="email"
                  data-testid="email-input"
                  name="email"
                  placeholder={t`Email`}
                  onChange={formik.handleChange}
                  textValue={formik.values.email}
                  isValid={!formik.errors.email}
                  validationMessage={formik.errors.email}
                />
                <Field
                  component={TextInput}
                  id="confirmEmail"
                  data-testid="email-input"
                  name="confirmEmail"
                  placeholder={t`Confirm email`}
                  onChange={formik.handleChange}
                  textValue={formik.values.confirmEmail}
                  isValid={!formik.errors.confirmEmail}
                  validationMessage={formik.errors.confirmEmail}
                />
              </div>
              <SubmitButton
                type="secondary"
                customStyle={styles.SubmitButton}
                disabled={formik.isSubmitting || !formik.isValid}
                label={t`Next`}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
