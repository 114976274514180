import { breadcrumbLinks } from './breadcrumbLinks'

export const getBreadcrumbSubroutes = (
  pathname: string,
  channelSlug: string
) => {
  const bases: string[] = pathname
    .split('/')
    .slice(1)
    .filter((path: string) => path !== channelSlug)
  const paths = bases.length ? bases.map((base: string) => `/${base}`) : ['/']
  const subroutes = paths
    .map((path: string) =>
      breadcrumbLinks.find(
        (link) => link.link === path && link.showInBreadcrumb
      )
    )
    .filter(Boolean)

  return subroutes
}
