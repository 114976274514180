import { t } from '@lingui/macro'
import { ProductType } from '../../ProductType'
import { SubscriptionType } from '../../SubscriptionType'

export const getProductTitle = (
  subscriptionType: SubscriptionType,
  productType: ProductType,
  customTitle: string
) => {
  if ([ProductType.MonthlySupporter].includes(productType)) {
    switch (subscriptionType) {
      case SubscriptionType.Club:
        return t`Club supporter`
      case SubscriptionType.Team:
        return t`Team supporter`
      case SubscriptionType.League:
        return t`League supporter`
      default:
        return t`Supporter`
    }
  }
  return customTitle
}
