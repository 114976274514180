import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import PlayFilledIcon from '../PlayFilledIcon'
import getDateText from '../getDateText'
import styles from '../ThumbnailLabel.module.scss'

interface Props {
  startAt: number
}
const ReplayLabel: FC<Props> = ({ startAt }) => {
  const { getDateString } = getDateText(startAt)
  return (
    <div className={styles.Default} data-testid="replay-thumbnail-label">
      <div className={styles.ReplayContainer}>
        <div className={styles.Icon}>
          <PlayFilledIcon />
        </div>
        <Trans>Replay</Trans>
        {` ${getDateString()}`}
      </div>
    </div>
  )
}
export default ReplayLabel
