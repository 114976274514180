/* eslint-disable @typescript-eslint/indent */
import { useParams } from 'react-router-dom'

export default function useCompanyParam(initialValue = undefined) {
  const { company } = useParams<{ company: string }>()
  const routesWithoutCompany = [
    'watch',
    'login',
    'sign_up',
    'password',
    'reset_password',
    'user',
    '404',
    'calendar',
    'terms-of-use',
    'videos',
    'highlights',
    'livestreams',
    'replays',
    'search',
    'select_account',
    'sport'
  ]
  if (!routesWithoutCompany.includes(company)) {
    return company || initialValue
  }
  return undefined
}
