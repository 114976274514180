enum PAYMENTS {
  OPEN_PAYMENT = 'OPEN_PAYMENT',
  CLOSE_PAYMENT = 'CLOSE_PAYMENT',
  RETRY_PAYMENT = 'RETRY_PAYMENT',
  VOUCHER_OPEN = 'VOUCHER_OPEN',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_ONGOING = 'PAYMENT_ONGOING',
  ADDED_CARD = 'ADDED_CARD',
  ALREADY_PURCHASED = 'ALREADY_PURCHASED',
  HAS_GIFT_CARD = 'HAS_GIFT_CARD',
  TYPE_PPV = 'TYPE_PPV',
  TYPE_SUBSCRIPTION = 'TYPE_SUBSCRIPTION',
  TYPE_ADD_CARD = 'TYPE_ADD_CARD',
  TYPE_REDIRECT_RESULT = 'TYPE_REDIRECT_RESULT'
}

export default PAYMENTS
