import LANGUAGE from './language.constants'

const setLanguage = (language: string) => ({
  type: LANGUAGE.SET_LANGUAGE,
  language
})

const languageActions = {
  setLanguage
}

export default languageActions
