import React, { FC } from 'react'

export const RemoveIcon: FC = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="1.25"
      width="46.5"
      height="46.5"
      rx="23.25"
      fill="white"
    />
    <path
      d="M23.9183 15.9902H29.3115C29.8317 15.9902 30.3305 16.1559 30.6983 16.4507C31.0661 16.7455 31.2727 17.1454 31.2727 17.5623V19.5275"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0817 15.9902H18.6885C18.1683 15.9902 17.6695 16.1559 17.3017 16.4507C16.9339 16.7455 16.7273 17.1454 16.7273 17.5623V19.5275"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0908 14.5002L24.9089 14.5"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 20.625H34"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M31.7273 27V33.25C31.7273 33.5815 31.5836 33.8994 31.3279 34.1339C31.0722 34.3683 30.7253 34.5 30.3637 34.5H18.0909C17.7293 34.5 17.3824 34.3683 17.1267 34.1339C16.871 33.8994 16.7273 33.5815 16.7273 33.25V23.25"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3635 32.3516V23.25M23.9999 32.3516V23.25M27.6363 32.3516V23.25"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="1.25"
      width="46.5"
      height="46.5"
      rx="23.25"
      stroke="#EAEAEA"
      strokeWidth="1.5"
    />
  </svg>
)
