import React, { FC } from 'react'
import { SearchField, ColorType } from 'components/Searchfield/SearchField'
import styles from './MobileSearchField.module.scss'
interface Props {
  onSearch: (searchInput: string) => void
  searchFieldIsFocused: boolean
  onBackArrowClick: () => void
  placeholder: string
}

export const MobileSearchField: FC<Props> = ({
  onSearch,
  searchFieldIsFocused,
  onBackArrowClick,
  placeholder
}) => (
  <div className={styles.SearchFieldContainer}>
    <SearchField
      type={ColorType.Light}
      onSearch={onSearch}
      isFocused={searchFieldIsFocused}
      showBackArrow={true}
      placeholder={placeholder}
      onBackArrowClick={onBackArrowClick}
    />
  </div>
)
