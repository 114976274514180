import { t } from '@lingui/macro'
import { ChannelTypes } from '../../../../pages/ChannelPage/channelType'

export const getHeaderDescription = (
  channelType: ChannelTypes,
  useSelectedTeamFromClub: boolean,
  teamName: string
) => {
  switch (channelType) {
    case ChannelTypes.league:
      return t`By adding an competition to this channel all the games from that competition will be added.`
    case ChannelTypes.club:
    case ChannelTypes.team:
      if (!useSelectedTeamFromClub) {
        return t`By adding the competition your team is participating in to your
          channel, all the games will be added from that competition.`
      }
      return t`By adding the competition ${teamName} is participating in to your
        channel, all the games will be added from that competition.`
    default:
      return t`By adding the competition your team is participating in to your
      channel, all the games will be added from that competition.`
  }
}
