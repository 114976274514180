import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface HideMessageProps {
  messageId: number
  mediaObjectId: string
}
export const hideChatMessage = async (message: HideMessageProps) => {
  try {
    const response: ApiResponse = await ApiEndpoint.call({
      path: `chat/${message.mediaObjectId}/message/${message.messageId}/hide`,
      method: ApiEndpoint.METHODS.PUT,
      authenticatable: true
    })
    const responseData = await response
    return responseData
  } catch (error: any) {
    throw new Error('hide chat message')
  }
}
