import { FontThemeSettings } from '../../../pages/ChannelPage/ChannelSettings/Branding/Fonts/fontThemeSettings'
import ApiEndpoint from '../../../network/ApiEndpoint'

interface ChannelFontThemeSettingsRequestApi {
  company: string
  channel_heading_font_family: string
  channel_submenu_font_family: string
  channel_text_transformation: string
}

interface ChannelFontThemeSettingsResponse {
  hasError: boolean
  errors?: any
}

export const patchFontTheme = async (
  channelFontThemeSettings: FontThemeSettings
): Promise<ChannelFontThemeSettingsResponse> => {
  const { headingFont, submenuFont, textTransformation, channelSlug } =
    channelFontThemeSettings
  const params: ChannelFontThemeSettingsRequestApi = {
    company: channelSlug,
    channel_heading_font_family: headingFont || '',
    channel_submenu_font_family: submenuFont || '',
    channel_text_transformation: textTransformation || ''
  }
  try {
    await ApiEndpoint.call({
      // /play_v1/session/companies/{company}/theme_fonts
      path: `/companies/${channelFontThemeSettings.channelSlug}/theme_fonts`,
      method: ApiEndpoint.METHODS.PATCH,
      params,
      authenticatable: true
    })

    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: []
    }
  }
}
