import React, { FC } from 'react'
import { LineupItem, LineupItemProps } from '../LineupItem/LineupItem'
import styles from './PlayerList.module.scss'
interface Props {
  players: LineupItemProps[]
}

export const PlayerList: FC<Props> = ({ players }) => (
  <div className={styles.PlayerList}>
    {players.map((player: LineupItemProps) => (
      <LineupItem
        key={player.number}
        number={player.number}
        name={player.name}
        isCaptain={player.isCaptain}
        isHighlightedPosition={player.isHighlightedPosition}
        positionAbbreviation={player.positionAbbreviation}
        isCoach={false}
      />
    ))}
  </div>
)
