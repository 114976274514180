import React, { FC } from 'react'
import { t } from '@lingui/macro'

import { TitleThumbnailSection } from 'components/content/TitleThumbnailSection/TitleThumbnailSection'

import styles from './Start.module.scss'

interface Props {
  showUpcomingStreamsSection: boolean
  upcomingStreamsRef: any
  upcomingStreamsInView: boolean
  getUpcomingStreamsScrollRef: any
  upcomingStreamsData: any
  thumbnailSize: 'small' | 'large'
  isLoading: boolean
}

export const UpcomingStreamsSection: FC<Props> = ({
  showUpcomingStreamsSection,
  upcomingStreamsRef,
  upcomingStreamsInView,
  getUpcomingStreamsScrollRef,
  upcomingStreamsData,
  thumbnailSize,
  isLoading
}) =>
  showUpcomingStreamsSection ? (
    <div
      className={styles.UpcomingStreamsContainer}
      data-testid="upcoming-streams-container"
      ref={upcomingStreamsRef}
    >
      {upcomingStreamsInView && (
        <TitleThumbnailSection
          getInfiniteScrollRef={getUpcomingStreamsScrollRef}
          thumbnailContent={upcomingStreamsData}
          sectionTitle={t`Upcoming broadcasts`}
          showAllLinkPath={undefined}
          linkLabel={t`All`}
          thumbnailsCardIsShared={false}
          size={thumbnailSize}
          isLoading={isLoading}
          useLeftPadding
        />
      )}
    </div>
  ) : null
