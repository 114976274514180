import React, { FC } from 'react'
import { Lineup } from './TeamLineups'
import { TeamLineup } from '../TeamLineup/TeamLineup'

interface Props {
  homeTeamLineup: Lineup
  awayTeamLineup: Lineup
  showHomeTeam: boolean
  gameHasBeenPlayed: boolean
}

export const MobileLineups: FC<Props> = ({
  homeTeamLineup,
  awayTeamLineup,
  showHomeTeam,
  gameHasBeenPlayed
}) => {
  const lineupData = showHomeTeam ? homeTeamLineup : awayTeamLineup
  return (
    <TeamLineup
      playerList={lineupData.playerList}
      coachList={lineupData.coachList}
      teamLogo={lineupData.teamLogo}
      teamName={lineupData.teamName}
      isHometeam={showHomeTeam}
      gameHasBeenPlayed={gameHasBeenPlayed}
    />
  )
}
