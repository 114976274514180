import React, { FC, useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Loader } from '../../../../components/Loader/Loader'

import useCurrentUser from '../../../../hooks/CurrentUser'

import authenticationActions from '../../../../redux-store/authentication/authentication.actions'
import paymentActions from '../../../../redux-store/payments/payments.actions'
import PAYMENTS from '../../../../redux-store/payments/payments.constants'
import { PurchaseOptionCard } from '../PurchaseOptions/PurchaseOptionCard'

import { SubscriptionType } from '../SubscriptionType'
import { preferredCurrencySelector } from '../../../../redux-store/user/user.selectors'
import { getSubscriptionType } from './getSubscriptionType'
import { getProductTitle } from './CardContent/getProductTitle'
import { getSubscriptionPrice } from './getSubscriptionPrice'
import { getIsPurchased } from './getIsPurchased'
import { Subscription } from '../../../../interfaces'
import { getCardSubscriptionType } from './getCardSubscriptionType'
import { getProductPeriodCost } from './CardContent/getProductPeriodCost'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { hasCompanionApp } from '../../../../helpers/companionApps'
import { useDataApi } from 'api/useDataApi'
import { getSubscriptionFromIdent } from 'api/channel/purchases/getSubscriptionFromIdent'
import { useHistory, useParams } from 'react-router-dom'

import { paymentsSelector } from 'redux-store/payments/payments.selectors'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import styles from './purchaseContainer.module.scss'

interface Props {
  apiCall: any
  children?: React.ReactNode
  channelSlug: string
}

export const PurchaseContainer: FC<Props> = ({
  apiCall,
  children,
  channelSlug
}) => {
  const channel = useSelector(channelSelector)
  const channelType = channel.data.type
  const currentUser = useCurrentUser()
  const history = useHistory()
  const { ident } = useParams<{
    ident?: string
  }>()

  const dispatch = useDispatch()
  const baseUrl = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}`
  const rootChannelSubdomain = channel?.data?.root_channel?.subdomain
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const companionAppIsAvailable = hasCompanionApp(rootChannelSubdomain)
  const payment = useSelector(paymentsSelector)
  const preferredCurrency = useSelector(preferredCurrencySelector)
  const now = Date.now() / 1000
  const { data, isLoading } = useDataApi({
    apiCall: apiCall,
    parameters: useMemo(
      () => ({
        slug: channelSlug
      }),
      [channelSlug, currentUser]
    )
  })
  const openPayment = (subscription: Subscription) => {
    const price = getSubscriptionPrice(subscription.price, preferredCurrency)
    dispatch(
      paymentActions.openPayment({
        type: PAYMENTS.TYPE_SUBSCRIPTION,
        subscription,
        value: price.amount,
        currency: price.currency
      })
    )
  }
  const subscribeFromIdent = async (ident: string) => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
    } else {
      const response = await getSubscriptionFromIdent({
        channelSlug,
        ident: ident
      })

      const subscription = response?.data
      if (subscription && subscription.id) {
        openPayment(subscription)
      }
    }
  }

  const defaultLink = [
    ChannelTypes.association,
    ChannelTypes.district,
    ChannelTypes.league,
    ChannelTypes.event
  ].includes(channelType)
    ? `/${channelSlug}/access`
    : `/${channelSlug}/subscriptions`

  useEffect(() => {
    if (payment.closeIconClicked) {
      history.push(defaultLink)
    }
  }, [payment])

  useEffect(() => {
    if (ident) {
      subscribeFromIdent(ident)
    }
  }, [currentUser?.email, ident])

  if (isLoading || !data) {
    return (
      <div className={styles.LoadingContainer}>
        <Loader />
      </div>
    )
  }

  return data ? (
    <div className={styles.PurchaseContainer}>
      {children}
      <div
        className={classNames(styles.PurchaseOptionsContainer, {
          [styles.MoreThanOneCard]: data?.data?.length > 1
        })}
      >
        {data?.data &&
          data.data.map((card: Subscription) => {
            const cardPrice = getSubscriptionPrice(
              card.price,
              preferredCurrency
            )

            const productPeriodCost = getProductPeriodCost(
              card.auto_renew,
              cardPrice.displayPrice,
              card.timeframe
            )

            const subscriptionType: SubscriptionType = getSubscriptionType(card)
            const productTitle = getProductTitle(
              subscriptionType,
              getCardSubscriptionType(card),
              card.title
            )
            const isPurchased = getIsPurchased(card, now)
            const handleOnPurchaseClick = (ident: string) => {
              if (isPurchased) {
                window.open(`${baseUrl}/user/profile/purchases/subscriptions`)
              } else {
                history.push(`${defaultLink}/${ident}`)
              }
            }
            return (
              <div key={card.id} className={styles.PurchaseOptionCardBorder}>
                <PurchaseOptionCard
                  id={card.id.toString()}
                  isPurchased={isPurchased}
                  productType={getCardSubscriptionType(card)}
                  productTitle={productTitle}
                  productPeriodCost={productPeriodCost}
                  onPurchaseClick={() => handleOnPurchaseClick(card.ident)}
                  description={card.body}
                  expirationDate={card.expires_at}
                  subscriptionType={subscriptionType}
                  periodicity={card.timeframe}
                  appIsAvailable={companionAppIsAvailable}
                  rootChannelSubdomain={rootChannelSubdomain}
                  isRootOrChildChannels={isRootOrChildChannel}
                  productChannelName={card.company.name}
                />
              </div>
            )
          })}
      </div>
    </div>
  ) : null
}
