import React, { FC } from 'react'
import classNames from 'classnames'

import { Trans } from '@lingui/macro'
import styles from './TeamsListHeader.module.scss'

export const TeamListHeader: FC = () => {
  return (
    <div className={styles.ListHeader}>
      <div className={classNames(styles.ListHeaderItem, styles.TeamName)}>
        <Trans>Team name</Trans>
      </div>
      <div
        className={classNames(
          styles.ListHeaderItem,
          styles.ConnectedCompetitions
        )}
      >
        <Trans>Connected to</Trans>
      </div>
      <div className={classNames(styles.ListHeaderItem, styles.Status)}>
        <Trans>Status</Trans>
      </div>
      <div className={styles.PlaceholderForIcon} />
    </div>
  )
}
