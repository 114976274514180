/* eslint-disable react/no-array-index-key */
import classNames from 'classnames'
import React from 'react'
import styles from './MenuPreloader.module.scss'

interface MenuPreloaderProps {
  isThemed?: boolean
  loaderCount?: number
}
export const MenuPreloader: React.FC<MenuPreloaderProps> = ({
  isThemed,
  loaderCount
}) => {
  return (
    <div className={styles.MenuPreloader}>
      <ul className={styles.MenuLoaderContainer}>
        {[...Array(loaderCount)].map((e, index) => (
          <li
            key={index}
            className={classNames(
              styles.Loader,
              isThemed ? styles.ThemedLoader : styles.NormalLoader
            )}
          />
        ))}
      </ul>
    </div>
  )
}

MenuPreloader.defaultProps = {
  isThemed: false,
  loaderCount: 4
}
