import React, { FC } from 'react'
// @ts-ignore
import { ITimezoneOption, useTimezoneSelect } from 'react-timezone-select'

import { DropdownList } from '../../../../components/Forms/DropdownListField/DropdownList'
import { DropDownListItem } from '../../../../components/Forms/DropdownListField/DropdownItemList/DropdownItemList'
import { timezones } from './TimezoneSelector'

interface Props {
  onChange: (id: string) => void
  value: string | undefined
  id: string
  label: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  type: 'dark' | 'light'
}

export const TimezoneDropdown: FC<Props> = (props) => {
  const labelStyle = 'original'
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones
  })

  const dropdownListData: DropDownListItem[] = options.map(
    (option: ITimezoneOption) => ({
      id: option.value,
      label: option.label
    })
  )

  return (
    <DropdownList
      {...props}
      dropdownListData={dropdownListData}
      onChange={(timeZone: string) => {
        props.onChange(parseTimezone(timeZone).value)
      }}
    />
  )
}
