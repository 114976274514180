import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
import { mapChannelEventsResponse } from './mapChannelEventsResponse'

export interface EventApi {
  slug: string
  id: number
  name: string
  channel_slug: string
  status: string
  start_date: number
}

enum DateFilterType {
  Past = 'past',
  FutureAndActive = 'future_and_active'
}

interface GetChannelEventsProps {
  channelSlug: string
}

export const getPastChannelEvents = async ({
  channelSlug
}: GetChannelEventsProps) => {
  try {
    const events = await getAllChannelEvents({
      channelSlug: channelSlug,
      dateFilter: DateFilterType.Past
    })
    return events
  } catch (e: any) {
    throw new Error(e.message)
  }
}

export const getFutureAndActiveChannelEvents = async ({
  channelSlug
}: GetChannelEventsProps) => {
  try {
    const events = await getAllChannelEvents({
      channelSlug: channelSlug,
      dateFilter: DateFilterType.FutureAndActive
    })
    return events
  } catch (e: any) {
    throw new Error(e.message)
  }
}

export const getAllChannelEvents = async ({ channelSlug, dateFilter }: any) => {
  try {
    const params = {
      company: channelSlug,
      ...(dateFilter && { date_filter: dateFilter })
    }
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'events',
      params
    })
    const responseData = await resultResponse
    const map = mapChannelEventsResponse(responseData.data)
    return map
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
