import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './PageLink.module.scss'

interface Props {
  pageNumber: string
  isEllipses: boolean
  onPageSelected: () => void
  isCurrentPage: boolean
}

export const PageLink: FC<Props> = ({
  pageNumber,
  isEllipses,
  onPageSelected,
  isCurrentPage
}) => (
  <div
    tabIndex={0}
    onKeyDown={onPageSelected}
    role="button"
    onClick={onPageSelected}
    className={classNames(styles.PageLinkContainer, {
      [styles.CurrentPage]: isCurrentPage
    })}
  >
    <div className={styles.PageNumber}>{isEllipses ? '...' : pageNumber}</div>
  </div>
)
