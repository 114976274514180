import React, { FC } from 'react'
import styles from '../RowCell.module.scss'
import classNames from 'classnames'
import { CellType } from './DefaultRowCell'
interface Props {
  playerName: string
  teamName: string
  isEmpty: boolean
  type?: CellType
}

export const PlayerRowCell: FC<Props> = ({
  playerName,
  teamName,
  isEmpty,
  type = CellType.DefaultTable
}) =>
  !isEmpty ? (
    <div
      className={classNames(styles.RowCell, styles.PlayerRowCell, {
        [styles.DefaultPlayerRowCell]: type === CellType.DefaultTable,
        [styles.CategoryLeaderPlayerRowCell]: type === CellType.CategoryLeader
      })}
    >
      <div className={styles.Name}> {playerName}</div>
      <div className={styles.TeamName}>{teamName}</div>
    </div>
  ) : (
    <div className={classNames(styles.RowCell, styles.PlayerRowCell)}>-</div>
  )
