import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface Params {
  page: number
  per_page: number
}

export const getChatMessages = async (
  mediaObjectIdent: string,
  params: Params
) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `chat/${mediaObjectIdent}/messages`,
      method: ApiEndpoint.METHODS.GET,
      params: params
    })
    return resultResponse.data
  } catch (e: any) {
    throw new Error('get chat messages')
  }
}
