import { t } from '@lingui/macro'
import { ChannelTypes } from 'pages/ChannelPage/channelType'

export const getSubTextForPhoneNumberForm = (
  channelType: ChannelTypes,
  isRootOrChildChannel: boolean,
  rootChannelName?: string
) => {
  switch (channelType) {
    case ChannelTypes.club:
      return isRootOrChildChannel
        ? t`Add your phone number to enable notifications when your club has the next stream on ${rootChannelName}.`
        : t`Add your phone number to enable notifications when your club has the next stream on Solidsport.`
    case ChannelTypes.team:
      return isRootOrChildChannel
        ? t`Add your phone number to enable notifications when your team has the next stream on ${rootChannelName}.`
        : t`Add your phone number to enable notifications when your team has the next stream on Solidsport.`

    default:
      return t`Add your phone number to enable notifications for upcoming streams.`
  }
}
