import React, { FC } from 'react'
import { Lineup } from './TeamLineups'
import { TeamLineup } from '../TeamLineup/TeamLineup'

interface Props {
  homeTeamLineup: Lineup
  awayTeamLineup: Lineup
  gameHasBeenPlayed: boolean
}

export const DesktopLineups: FC<Props> = ({
  homeTeamLineup,
  awayTeamLineup,
  gameHasBeenPlayed
}) => (
  <>
    <TeamLineup
      playerList={homeTeamLineup.playerList}
      coachList={homeTeamLineup.coachList}
      teamLogo={homeTeamLineup.teamLogo}
      teamName={homeTeamLineup.teamName}
      isHometeam={true}
      gameHasBeenPlayed={gameHasBeenPlayed}
    />
    <TeamLineup
      playerList={awayTeamLineup.playerList}
      coachList={awayTeamLineup.coachList}
      teamLogo={awayTeamLineup.teamLogo}
      teamName={awayTeamLineup.teamName}
      isHometeam={false}
      gameHasBeenPlayed={gameHasBeenPlayed}
    />
  </>
)
