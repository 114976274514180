import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export interface GameScheduleFilterResponseApi {
  channel_name: string
  channel_subdomain: string
  district_id: number
  district_name: string
  end_at: number
  games_count: number
  id: number
  name: string
  slug: string
  start_at: number
  status: string
  teams_count: number
}

export interface FilteredGameSchedule {
  id: number
  channelSlug: string
  title: string
  groupName: string
  groupId: number
  startAt: number
  endAt: number
  totalGames: number
}

export interface FilteredGameScheduleRequest {
  searchText: string
  groupId: string
  channelSlug: string
  page: number
  perPageCount: number
}

export const getFilteredGameScheduleList = async (
  request: FilteredGameScheduleRequest
) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${request.channelSlug}/competitions/available`,
      params: {
        search: request.searchText,
        district_id: request.groupId,
        page: request.page,
        per_page: request.perPageCount
      },
      authenticatable: true
    })
    const responseData = await resultResponse
    const mapped: FilteredGameSchedule[] = mapFilteredGameScheduleList(
      responseData.data
    )
    return { data: mapped, totalCount: responseData.totalCount }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}

const mapFilteredGameScheduleList = (
  gameScheduleList: GameScheduleFilterResponseApi[]
) => {
  try {
    return gameScheduleList.map(
      (gameSchedule: GameScheduleFilterResponseApi) => ({
        title: gameSchedule.name,
        channelSlug: gameSchedule.channel_subdomain,
        groupName: gameSchedule.district_name,
        groupId: gameSchedule.district_id,
        startAt: gameSchedule.start_at,
        endAt: gameSchedule.end_at,
        id: gameSchedule.id,
        totalGames: gameSchedule.games_count ? gameSchedule.games_count : 0
      })
    )
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
