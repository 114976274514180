import { RelatedChannelType } from './RelatedChannel.interface'
import { Channel } from '../../../api/channel/getChannel'

export const doDetermineRelatedLeagues = (
  channelData: Channel
): RelatedChannelType[] => {
  const relations = channelData?.meta_data?.misc?.relations
  const relatedLeagues = relations?.leagues || []
  return relatedLeagues
    .map((league) => ({
      ...league,
      start_at: league.start_at || 0,
      end_at: league.end_at || 0
    }))
    .sort((a, b) => b.end_at - a.end_at)
}
