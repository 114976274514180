import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './ProgressBar.module.scss'

interface Props {
  percentageCompleted: number
}
const ProgressBar: FC<Props> = ({ percentageCompleted }) => {
  const isCompleted = percentageCompleted >= 100
  const percentage = percentageCompleted >= 100 ? 100 : percentageCompleted
  return (
    <div className={styles.ProgressBarContainer}>
      <div
        className={styles.PercentageText}
        data-testid="progress-percentage"
      >{`${percentage}%`}</div>
      <div className={styles.ProgressBar}>
        <div
          className={classNames(styles.Progress, {
            [styles.Completed]: isCompleted
          })}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  )
}
export default ProgressBar
