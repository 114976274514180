export const computeStateChange = (prevState: any, nextState: any) => {
  return Object.entries(prevState).reduce(
    (acc, [key, value]) => acc || value !== nextState[key],
    false
  )
}

export const combineSubscribers = (store: any, subscribers: any[]) => {
  subscribers.forEach((subscriber: any) => {
    store.subscribe(() => subscriber(store))
  })
}
