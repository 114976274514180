import React, { FC } from 'react'
import classNames from 'classnames'

import { LeftArrow } from './LeftArrow'
import { RightArrow } from './RightArrow'

import styles from './PaginationArrowButton.module.scss'

export enum ArrowDirection {
  left = 'left',
  right = 'right'
}

export enum PaginationButtonType {
  dark = 'dark',
  default = 'default'
}

interface Props {
  onClick: () => void
  disabled: boolean
  direction: ArrowDirection
  type?: PaginationButtonType
}

export const PaginationArrowButton: FC<Props> = ({
  onClick,
  disabled,
  direction,
  type = PaginationButtonType.default
}) => {
  const button =
    direction === ArrowDirection.left ? <LeftArrow /> : <RightArrow />
  return (
    <div
      tabIndex={0}
      onKeyPress={() => null}
      role="button"
      onClick={onClick}
      className={classNames(styles.ArrowContainer, {
        // default styling
        [styles.ArrowLightBack]:
          type === PaginationButtonType.default && !disabled,
        [styles.ArrowDarkForeground]:
          type === PaginationButtonType.default && !disabled,

        // dark styling
        [styles.ArrowDarkBack]: type === PaginationButtonType.dark && !disabled,
        [styles.ArrowLightForeground]:
          type === PaginationButtonType.dark && !disabled,

        [styles.Disabled]: disabled
      })}
    >
      {button}
    </div>
  )
}
