import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { PaymentPropsType } from '../../../viewerPage.interfaces'
import BuyOptionBannerFactory, {
  BUY_OPTIONS
} from '../BuyOptionBanner/BuyOptionBannerFactory'
import styles from './BuyBannerView.module.scss'
import usePriceFromPreference from 'hooks/PriceFromPreference'

interface VoucherProps {
  payPerView: PaymentPropsType
  defaultCurrency: string
  onSelect: () => void
}

const VoucherView: FC<VoucherProps> = ({
  payPerView,
  defaultCurrency,
  onSelect
}) => {
  const { price } = payPerView
  const ppvPrice = usePriceFromPreference(price!)

  return (
    <div className={styles.BuyViewContainer}>
      <div
        data-testid="buy-banner-voucher-heading"
        className={styles.BannerHeading}
      >{t`Use a voucher code`}</div>
      <BuyOptionBannerFactory
        option={BUY_OPTIONS.VOUCHER}
        onSelect={onSelect}
        amount={0}
        currency={ppvPrice ? ppvPrice.currency : defaultCurrency}
      />
    </div>
  )
}

export default VoucherView
