import React, { FC } from 'react'
import DefaultShield from '../../../../../../assets/images/shield_2_256x256.png'

import styles from './TeamBadge.module.scss'
import classNames from 'classnames'

interface Props {
  name?: string
  logoUrl?: string
  externalStyle?: string | null
}

export const TeamBadge: FC<Props> = ({ name, logoUrl, externalStyle }) => {
  return (
    <div className={classNames(styles.Container, externalStyle)}>
      {logoUrl ? (
        <img src={logoUrl} alt={name || 'team badge'} />
      ) : (
        <img src={DefaultShield} alt="Default shield for team" />
      )}
    </div>
  )
}
