import React, { FC, ReactNode } from 'react'

import { OpenCloseArrow } from '../../OpenCloseArrow/OpenCloseArrow'
import classNames from 'classnames'
import { InformationButton } from './InformationButton/InformationButton'
import TagManager from 'react-gtm-module'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { AccordionItemLink } from './AccordionItemLink'
import styles from './AccordionItem.module.scss'

export enum AccordionTheme {
  White = 'White',
  Grey = 'Grey'
}
export interface AccordionItem {
  icon?: FC
  headerLink?: string
  headerText: string
  children: ReactNode | undefined
  href?: string
  hrefTarget?: LinkTarget
  id: string
  showInformationButton?: boolean
}
export interface Props extends AccordionItem {
  theme: AccordionTheme
  isOpen: boolean
  toggleOpen: (id: string) => void
  onInformationClick?: (id: string) => void
}

export const AccordionItem: FC<Props> = ({
  children,
  headerText,
  showInformationButton,
  isOpen,
  theme,
  icon: Icon,
  id,
  toggleOpen,
  onInformationClick,
  headerLink,
  href,
  hrefTarget
}) => {
  const triggerGTMOnAccordionClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'accordion_item_clicked',
        title: headerText,
        link: headerLink || 'does not have a link',
        is_collapsible: !headerLink
      }
    })
  }
  const handleOnHeaderClick = () => {
    triggerGTMOnAccordionClick()
    !headerLink && toggleOpen(id)
  }
  return (
    <div data-testid="accordion-item" className={styles.AccordionItemContainer}>
      <div className={styles.HeaderContainer}>
        {showInformationButton && onInformationClick && (
          <InformationButton
            onClick={() => onInformationClick(id)}
            theme={theme}
          />
        )}
        <div
          className={classNames(styles.Header, styles[theme])}
          role="button"
          onClick={handleOnHeaderClick}
          onKeyUp={handleOnHeaderClick}
          tabIndex={0}
        >
          <div
            className={classNames(styles.Title, { [styles.WithIcon]: Icon })}
          >
            {Icon && (
              <div className={styles.Icon}>
                <Icon />
              </div>
            )}

            <AccordionItemLink
              headerText={headerText}
              headerLink={headerLink}
              hrefTarget={hrefTarget}
              href={href}
            />
          </div>
          {!headerLink && <OpenCloseArrow isOpen={isOpen} />}
        </div>
      </div>
      {isOpen && children}
    </div>
  )
}
