import React, { FC } from 'react'
import { LoginForm } from './LoginForm/LoginForm'
import styles from './LoginContent.module.scss'
import { OtherLoginOptions } from './OtherLoginOptions/OtherLoginOptions'
import { TopTextSection } from 'components/Modals/Sections/TopTextSection/TopTextSection'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { CompanyInfo } from 'components/page/footer/themedFooter/topSection/CompanyInfo'
import SolidSportLogo from 'components/page/footer/defaultFooter/SolidSportLogo'
import { ClickableTextButton } from 'components/buttons/ClickableTextButton/ClickableTextButton'
interface Props {
  onForgotPasswordClick: () => void
  onSignupClick: () => void
  onFormSuccess: () => void
}
export const LoginContent: FC<Props> = ({
  onForgotPasswordClick,
  onSignupClick,
  onFormSuccess
}) => {
  const channel = useSelector(channelSelector)
  const isChildOfRootChannel = channel?.data?.isChildOfRootChannel
  const isRootChannel = channel?.data?.isRootChannel
  const isRootOrChildChannel = isRootChannel || isChildOfRootChannel
  const rootChannelName = isRootChannel
    ? channel?.data?.name
    : channel?.data?.root_channel?.name

  const title =
    isRootOrChildChannel && rootChannelName
      ? t`Welcome to ${rootChannelName}`
      : t`Welcome to Solidsport`
  const subTitle =
    isRootOrChildChannel && rootChannelName
      ? t`Sign in to ${rootChannelName} with your account handled by Solidsport.`
      : t`Sign in to Solidsport with your account.`

  return (
    <div className={styles.LoginContent}>
      <TopTextSection title={title} subText={subTitle} />
      <LoginForm
        onForgotPasswordClick={onForgotPasswordClick}
        onFormSuccess={onFormSuccess}
      />
      <OtherLoginOptions showDivider />
      <ClickableTextButton
        onClick={onSignupClick}
        defaultText={t`Ready to join?`}
        clickableText={t`Sign up here!`}
      />
      <div className={styles.BottomLogo}>
        {isRootOrChildChannel ? (
          <CompanyInfo />
        ) : (
          <div className={styles.Logo}>
            <SolidSportLogo />
          </div>
        )}
      </div>
    </div>
  )
}
