import React from 'react'

export const LogosIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 0.746002H5.25C4.42157 0.746002 3.75 1.41758 3.75 2.246V18.746C3.75 19.5744 4.42157 20.246 5.25 20.246H21.75C22.5784 20.246 23.25 19.5744 23.25 18.746V2.246C23.25 1.41758 22.5784 0.746002 21.75 0.746002Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 23.246H2.25C1.85218 23.246 1.47064 23.088 1.18934 22.8067C0.908035 22.5254 0.75 22.1438 0.75 21.746V3.746"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.624 6.746C8.54987 6.7462 8.47747 6.76836 8.41593 6.80969C8.3544 6.85101 8.30649 6.90965 8.27826 6.97819C8.25003 7.04673 8.24275 7.1221 8.25733 7.19477C8.27191 7.26745 8.3077 7.33417 8.36019 7.38652C8.41267 7.43886 8.47949 7.47448 8.55221 7.48887C8.62492 7.50326 8.70027 7.49577 8.76874 7.46736C8.8372 7.43895 8.89571 7.39088 8.93687 7.32924C8.97803 7.26759 9 7.19513 9 7.121C9 7.02155 8.96049 6.92616 8.89017 6.85584C8.81984 6.78551 8.72446 6.746 8.625 6.746"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 15.746L16.38 9.91C16.3121 9.80859 16.2205 9.7253 16.113 9.66738C16.0056 9.60947 15.8857 9.57868 15.7636 9.57769C15.6416 9.57671 15.5212 9.60555 15.4128 9.66173C15.3045 9.7179 15.2115 9.7997 15.142 9.9L13.147 12.75L11.91 11.761C11.8288 11.6962 11.735 11.6489 11.6346 11.6222C11.5342 11.5955 11.4293 11.59 11.3266 11.606C11.224 11.622 11.1257 11.6591 11.0382 11.7151C10.9506 11.771 10.8756 11.8445 10.818 11.931L8.272 15.746H20.25Z"
      stroke="#2F3135"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
