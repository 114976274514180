import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Honeybadger } from '@honeybadger-io/react'
import useExternalScript from '../../../hooks/ExternalScript'
import { getFullCurrentLocale } from '../../../translations/i18n'
import { FacebookResponse } from './FacebookResponse'
import { FacebookSDK } from './FacebookSDK'
import { FacebookLoginState } from './FacebookLoginState'
import FacebookAuthStatus from './FacebookAuthStatus'
import authenticationActions from '../../../redux-store/authentication/authentication.actions'
import useCompanyParam from '../../../hooks/CompanyParam'
import ApiEndpoint, { ApiError } from '../../../network/ApiEndpoint'
import userActions from '../../../redux-store/user/user.actions'
import { userIsUpdatingSelector } from '../../../redux-store/user/user.selectors'
import { getFacebookLoginId } from '../../session/getIsLoginAvailable'

const DATA_ON_LOGIN_CALLBACK_NAME = 'NATIVE_FACEBOOK_LOGIN'

export default function useFacebookAuth() {
  const facebookSdkScriptStatus = useExternalScript(
    `https://connect.facebook.net/${getFullCurrentLocale()}/sdk.js`
  )
  const [loginState, setLoginState] = useState<FacebookLoginState>({
    status: FacebookAuthStatus.NOT_LOADED,
    message: 'Init Facebook login'
  })

  const backendLoggingIn = useSelector(
    (state: any) => state.authentication.loggingIn
  )
  const backendLoginError = useSelector(
    (state: any) => state.authentication.error
  )
  const updatingUser = useSelector(userIsUpdatingSelector)

  const company = useCompanyParam()
  const dispatch = useDispatch()

  const fbsdk = (): Promise<FacebookSDK> => {
    if ((window as any).FB) {
      return Promise.resolve((window as any).FB)
    }
    setLoginState({
      status: FacebookAuthStatus.NOT_LOADED,
      message: 'Init Facebook login'
    })
    return Promise.reject(new Error('Facebook SDK not loaded.'))
  }

  const setupFacebookSdk = () => {
    const facebookLoginId = getFacebookLoginId()
    const initOptions = {
      appId: facebookLoginId,
      cookie: false,
      xfbml: true,
      version: 'v16.0'
    }

    fbsdk()
      .then((fb: FacebookSDK) => {
        fb?.init(initOptions)
        setLoginState({ status: FacebookAuthStatus.READY, message: 'Ready.' })
      })
      .catch(() => {
        ;(window as any).fbAsyncInit = () => setupFacebookSdk()
      })
  }

  // Should be used if we create a custom button.
  const login = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      facebookLogin()
        .then((data) => {
          backendLoginRequest(data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const facebookAuthCheckRequest = (): Promise<FacebookResponse> => {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      if (loginState.status !== FacebookAuthStatus.READY) {
        return reject(
          new Error('Facebook SDK not ready or already logging in.')
        )
      }
      setLoginState({
        status: FacebookAuthStatus.FACEBOOK_ME_REQUEST,
        message: 'Logging in...'
      })
      fbsdk()
        .then((fb: FacebookSDK) => {
          fb.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
              fb.api('/me?fields=email,first_name,last_name', (user: any) => {
                const data = {
                  user,
                  authResponse: response.authResponse
                }
                setLoginState({
                  status: FacebookAuthStatus.FACEBOOK_SUCCESS,
                  message: 'Success.'
                })
                return resolve(data)
              })
            } else {
              setLoginState({
                status: FacebookAuthStatus.FACEBOOK_FAILED,
                message: 'Failed to login.'
              })
              return reject(new Error('Failed to login.'))
            }
          })
        })
        .catch((error) => {
          console.error(error)
          return reject(error)
        })
    })
  }

  const facebookLogin = (): Promise<FacebookResponse> => {
    return new Promise((resolve, reject) => {
      setLoginState({
        status: FacebookAuthStatus.FACEBOOK_REDIRECTED_LOGIN,
        message: 'Logging in...'
      })
      fbsdk().then((fb: FacebookSDK) => {
        fb.login((response: any) => {
          if (response.status === 'connected') {
            facebookAuthCheckRequest().then((data: FacebookResponse) => {
              resolve(data)
            })
          } else {
            setLoginState({
              status: FacebookAuthStatus.FACEBOOK_FAILED,
              message: 'Failed to login to facebook.'
            })
            reject(new Error('Failed to login to facebook.'))
          }
        })
      })
    })
  }

  const backendLoginRequest = (response: FacebookResponse | undefined) => {
    if (response && response.user && !backendLoggingIn) {
      setLoginState({
        status: FacebookAuthStatus.BACKEND_REQUEST,
        message: 'Login in to backend...'
      })
      dispatch(
        authenticationActions.facebookLogin(
          response.user.id,
          response.user.email,
          response.user.first_name,
          response.user.last_name,
          response.authResponse.accessToken,
          company
        )
      )
    }
  }

  const facebookLogout = (): Promise<void> => {
    return new Promise((resolve) => {
      fbsdk().then((fb: FacebookSDK) => {
        fb.logout()
      })
      resolve()
    })
  }

  const backendConnectFacebook = () => {
    facebookLogin()
      .then((data: FacebookResponse) => {
        setLoginState({
          status: FacebookAuthStatus.BACKEND_REQUEST,
          message: 'Connecting account to backend...'
        })
        ApiEndpoint.call({
          path: 'users/edit/providers/connect',
          params: {
            provider: 'facebook',
            uid: data.authResponse.userID,
            token: data.authResponse.accessToken
          },
          method: ApiEndpoint.METHODS.POST,
          authenticatable: true
        })
          .then(() => {
            dispatch(userActions.updateUser())
          })
          .catch((error: ApiError) => {
            setLoginState({
              status: FacebookAuthStatus.BACKEND_FAILED,
              message: error.message
            })
          })
      })
      .catch((error: ApiError) => {
        console.log(error)
      })
  }

  const backendDisconnectFacebook = () => {
    facebookLogout().finally(() => {
      setLoginState({
        status: FacebookAuthStatus.BACKEND_REQUEST,
        message: 'Disconnecting account from backend...'
      })
      ApiEndpoint.call({
        path: 'users/edit/providers/disconnect',
        method: ApiEndpoint.METHODS.PUT,
        authenticatable: true
      })
        .then(() => {
          dispatch(userActions.updateUser())
        })
        .catch((error: ApiError) => {
          setLoginState({
            status: FacebookAuthStatus.BACKEND_FAILED,
            message: 'Failed to disconnect account from backend.'
          })
          console.error(error)
        })
    })
  }

  const isLoggingIn = () => {
    switch (loginState.status) {
      case FacebookAuthStatus.FACEBOOK_REDIRECTED_LOGIN:
      case FacebookAuthStatus.FACEBOOK_ME_REQUEST:
      case FacebookAuthStatus.FACEBOOK_SUCCESS:
      case FacebookAuthStatus.BACKEND_REQUEST:
        return true
      default:
        return false
    }
  }

  // Handle the authentication redux states.
  useEffect(() => {
    if (loginState.status === FacebookAuthStatus.READY) {
      return
    }

    if (backendLoggingIn || updatingUser) {
      setLoginState({
        status: FacebookAuthStatus.BACKEND_REQUEST,
        message: 'Logging in...'
      })
    } else if (backendLoginError) {
      setLoginState({
        status: FacebookAuthStatus.BACKEND_FAILED,
        message: backendLoginError
      })
    } else {
      setLoginState({ status: FacebookAuthStatus.READY, message: 'Ready.' })
    }
  }, [backendLoggingIn, backendLoginError, updatingUser])

  // Setup the Facebook SDK and native button login callback on script load.
  useEffect(() => {
    if (facebookSdkScriptStatus.code === 200) {
      setupFacebookSdk()
    }
  }, [facebookSdkScriptStatus])

  useEffect(() => {
    switch (loginState.status) {
      case FacebookAuthStatus.FACEBOOK_FAILED:
        Honeybadger.notify(loginState, {
          name: 'Facebook Login Error',
          message: 'Failed to login to Facebook.'
        })
        break
      case FacebookAuthStatus.BACKEND_FAILED:
        Honeybadger.notify(loginState, {
          name: 'Facebook Backend Login Error',
          message: 'Failed to login to the backend.'
        })
        break
      default:
        break
    }

    // Keep the facebook login callback updated with the loginState.
    ;(window as any)[DATA_ON_LOGIN_CALLBACK_NAME] = () => {
      facebookAuthCheckRequest()
        .then(backendLoginRequest)
        .catch((error) => {
          console.log(error)
        })
    }
  }, [loginState.status])

  return {
    state: loginState,
    dataOnLoginCallbackName: DATA_ON_LOGIN_CALLBACK_NAME,
    sdk: fbsdk,
    login,
    isLoggingIn,
    facebookLogin,
    facebookLogout,
    connectUserToBackend: backendConnectFacebook,
    disconnectUserFromBackend: backendDisconnectFacebook
  }
}
